<template>
  <div>
    <div class="vel-table-action" v-if="(exportable || filterable)">
      <vel-row :gutter="10">
        <vel-col :span="16" v-if="filterable">
          <vel-table-filter :placeholder="filterPlaceholder" v-model="inputFilter" />
        </vel-col>
        <vel-col :span="exportable && filterable ? 4 : 8" v-if="showRowsLength">
          <div class="vel-table-action__rows-length">
            <div class="vel-table-action__rows-length__value">{{ `${rows.length} / ${initialLength}` }}</div>
          </div>
        </vel-col>
        <vel-col :span="filterable && showRowsLength ? 4 : 8" v-if="exportable">
          <vel-table-export
            class="vel-table-action__export"
            :columns="exportableColumns"
            :rows="exportableRows"
            :metas="exportableMetas"
            :external-export="externalExport"
            @exportXls="$emit('exportXls')"
          />
        </vel-col>
      </vel-row>
    </div>

    <div class="vel-table-wrapper">
      <div class="vel-table__loader" v-if="loading">
        <vel-spinner class="vel-table__spinner" />
      </div>
      <table class="vel-table" :class="classes" :aria-busy="ariaBusy" :aria-colcount="this.computedColumns.length">
        <vel-table-header
          class="vel-table__header"
          :columns="computedColumns"
          :bordered="bordered"
          :sortBy="sortBy"
          :direction="direction"
          @click="handleColumnClick"
        />
        <tbody class="vel-table__body" role="rowgroup">
          <tr class="vel-table__row" v-if="isEmpty" role="row">
            <td class="vel-table__cell vel-table__cell_empty" role="cell" :colspan="this.computedColumns.length">
              <slot name="empty">{{ defaultEmptyMessage }}</slot>
            </td>
          </tr>
          <tr
            class="vel-table__row"
            v-else
            v-for="(row, rindex) in computedRows"
            :key="rindex"
            @click="handleRowClick(originalRow(row))"
          >
            <td
              class="vel-table__cell"
              role="cell"
              v-for="(cell, cindex) in filterRow(row)"
              :key="cindex"
              :aria-colindex="cindex + 1"
              :class="cell.class"
            >
              <slot
                :name="`${cell.key}-column`"
                :cell="cell"
                :cellIndex="cindex"
                :row="originalRow(row)"
                :rowIndex="row.__$index"
              >
                {{ cell.value }}
              </slot>
            </td>
          </tr>
          <tr class="vel-table__row vel-table__total" v-if="showSummary && rows.length">
            <td v-for="(col, i) in visibleColumns" :key="i" class="vel-table__cell" role="cell">
              {{ getSummaries(col) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelCol from '@/components/x-layout/VelCol';
import VelRow from '@/components/x-layout/VelRow';
import VelSpinner from '@/components/spinner/VelSpinner';
import VelTableExport from './VelTableExport';
import VelTableFilter from './VelTableFilter';
import VelTableHeader from './VelTableHeader';
import debounce from 'lodash.debounce';
import formatMoney from '@/filters/format-money';

export default {
  name: 'vel-table',
  components: {
    VelSpinner,
    VelTableHeader,
    VelTableFilter,
    VelTableExport,
    VelRow,
    VelCol
  },
  mixins: [DeviceMixin],
  props: {
    columns: {
      type: Object,
      default() {
        return {};
      }
    },
    rows: {
      type: Array,
      default() {
        return [];
      }
    },
    striped: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    defaultDirection: {
      type: String,
      default: null
    },
    defaultSort: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    hoverable: {
      type: Boolean,
      default: true
    },
    sortType: {
      type: String,
      default: null,
      validate(value) {
        return [null, 'user'].includes(value);
      }
    },
    exportable: {
      type: Boolean,
      default: false
    },
    exportableMetas: {
      type: Object,
      default: null
    },
    filterable: {
      type: Boolean,
      default: false
    },
    filterValue: {
      type: String,
      default: ''
    },
    filterPlaceholder: {
      type: String,
      default: null
    },
    showRowsLength: {
      type: Boolean,
      default: false
    },
    showSummary: {
      type: Boolean,
      default: false
    },
    summaryTotalText: {
      type: String,
      default: ''
    },
    initialLength: {
      type: Number,
      default: 0
    },
    externalExport: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sortBy: this.defaultSort,
      direction: this.defaultSort ? this.defaultDirection || 'asc' : null
    };
  },
  computed: {
    defaultEmptyMessage() {
      return this.$t('defaults.empty');
    },
    isEmpty() {
      return this.rows.length === 0;
    },
    computedColumns() {
      return Object.entries(this.columns).map(([key, column]) => ({
        key,
        sort: column.sort || null,
        title: column.title ? column.title : key,
        visible: column.visible !== false,
        sortable: column.sortable || false,
        width: column.width || false,
        exportable: column.exportable === undefined ? true : column.exportable
      }));
    },
    visibleColumns() {
      return this.computedColumns.filter(column => column.visible !== false);
    },
    exportableColumns() {
      return this.computedColumns.filter(column => column.exportable !== false);
    },
    indexedRows() {
      let i = 0;
      return this.rows.map(r => ({ ...r, __$index: i++ }));
    },
    sortedRows() {
      return this.sortType === 'user' ? this.indexedRows : this.indexedRows.slice(0).sort(this.sort);
    },
    computedRows() {
      return Object.values(this.sortedRows).map(row =>
        Object.assign(
          Object.entries(this.columns)
            .map(([key, column]) => {
              const value = row[key];
              return {
                key,
                value,
                column,
                class: column.class
              };
            })
            .filter(Boolean),
          { __$index: row.__$index }
        )
      );
    },
    exportableRows() {
      return this.computedRows.map(rows => rows.filter(cell => cell.column.exportable !== false));
    },
    classes() {
      const { name } = this.$options;
      return {
        [`${name}_empty`]: this.isEmpty,
        [`${name}_striped`]: this.striped,
        [`${name}_striped_bordered`]: this.striped && this.bordered,
        [`${name}_bordered`]: this.bordered,
        [`${name}_loading`]: this.loading,
        [`${name}_hoverable`]: this.hoverable
      };
    },
    ariaBusy() {
      return this.loading ? 'true' : 'false';
    },
    inputFilter: {
      get() {
        return this.filterValue;
      },
      set(value) {
        debounce(v => {
          this.$emit('filter-change', v);
        }, 500)(value);
      }
    }
  },
  methods: {
    originalRow(row) {
      return this.rows[row.__$index];
    },
    filterRow(row) {
      return row.filter(cell => cell.column.visible !== false);
    },
    sort(a, b) {
      const { sortBy, direction } = this;

      // Try sorting by custom sort first
      if (this.columns[sortBy] && this.columns[sortBy].sort) {
        let result = this.columns[sortBy].sort(a, b);
        result = direction !== 'asc' ? result * -1 : result;
        return result;
      }

      // If not, use natural sorting
      if (a[sortBy] < b[sortBy]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[sortBy] > b[sortBy]) {
        return direction === 'asc' ? 1 : -1;
      }

      return 0;
    },
    handleColumnClick(column) {
      if (this.sortBy === column.key && this.direction === 'desc') {
        this.sortBy = null;
        this.direction = 'asc';
      } else if (this.sortBy === column.key && this.direction === 'asc') {
        this.direction = 'desc';
      } else {
        this.sortBy = column.key;
        this.direction = 'asc';
      }
      const { direction } = this;
      this.$emit('column', { direction, ...column });
    },
    handleRowClick(row) {
      this.$emit('row', row);
    },
    getSummaries(column) {
      const { key } = column;

      if (key.toLowerCase() === 'name') {
        return this.summaryTotalText
          ? `Total (${this.rows.length} ${this.summaryTotalText})`
          : `Total (${this.rows.length})`;
      }

      const values = this.rows.map(item => Number(item[key]));

      let sum = 0;
      for (const v of values) {
        sum += v;
      }

      //@todo: find a better way for extendability (not future-proof at all)
      if (key.toLowerCase().includes(['sales'])) {
        return formatMoney(sum, this.rows.length && this.rows[0].currency);
      }

      return sum;
    }
  }
};
</script>

<style lang="scss" src="./VelTable.scss" />
