import { render, staticRenderFns } from "./VelFileUpload.vue?vue&type=template&id=b546a2c4&scoped=true&"
import script from "./VelFileUpload.vue?vue&type=script&lang=js&"
export * from "./VelFileUpload.vue?vue&type=script&lang=js&"
import style0 from "./VelFileUpload.vue?vue&type=style&index=0&id=b546a2c4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b546a2c4",
  null
  
)

export default component.exports