<template>
  <div class="selectList">
    <div v-if="multiple && valueOptions.length > 0" class="selectList__header">
      <a @click="selectAll">{{ $t('listSelector.selectAll') }}</a>
      <a @click="unselectAll">{{ $t('listSelector.deselectAll') }}</a>
    </div>
    <ul>
      <li
        v-for="option in (valueOptions || [])"
        :key="option.key"
        :class="{ selected: isItemSelected(option.key) }"
        @click="selectedItem(option.key)"
      >
        <b-icon icon="circle" v-if="!isItemSelected(option.key)"></b-icon>
        <b-icon icon="check2-circle" v-else></b-icon>
        <label>{{ option.label || '--' }}</label>
      </li>
    </ul>
    <div v-if="multiple && valueOptions.length > 0" class="selectList__footer">
      <a @click="cancel">{{ $t('listSelector.cancel') }}</a>
      <a @click="validate">{{ $t('listSelector.ok') }}</a>
    </div>
    <div v-if="valueOptions.length <= 0">{{ noDataLabel || $t('listSelector.noData') }}</div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'vel-list',
  props: {
    value: {
      type: [String, Array, Number],
      default: null
    },
    filterable: {
      type: Boolean,
      default: true
    },
    valueOptions: {
      type: Array,
      required: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    noDataLabel: {
      type: String,
      default: null
    },
    displayLabel: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      filter: '',
      currentSelection: null
    };
  },
  computed: {
    options() {
      return this.valueOptions || [];
    },
    modelValue: {
      get() {
        return cloneDeep(this.value);
      },
      set(value) {
        this.$emit('input', cloneDeep(value));
      }
    }
  },
  methods: {
    selectAll() {
      this.unselectAll();
      this.valueOptions.forEach(o => this.selectedItem(o.key));
    },
    unselectAll() {
      this.currentSelection = this.multiple ? [] : null;
    },
    selectedItem(key) {
      if (!this.multiple) {
        this.currentSelection = key;
        this.validate();
        return;
      }

      if (!this.currentSelection) {
        this.currentSelection = [key];
        return;
      }

      const index = this.currentSelection.indexOf(key);

      if (index < 0) {
        this.currentSelection.push(key);
      } else {
        this.currentSelection.splice(index, 1);
      }
    },
    isItemSelected(key) {
      if (!this.currentSelection && this.currentSelection !== 0) {
        return '';
      }
      if (this.multiple) {
        return this.currentSelection.includes(key);
      }

      return key === this.currentSelection;
    },
    validate() {
      this.modelValue = this.currentSelection;
      this.$emit('ok');
    },
    cancel() {
      this.$emit('cancel');
    }
  },
  beforeMount() {
    this.currentSelection = this.modelValue;
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

$contrast-color: #00a9e1;

.selectList {
  background: white;
  border-radius: 1em;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 12px #00000029;
  position: absolute;
  z-index: 100;
  padding: 15px;
  margin-top: 10px;
  font-size: 14px;
  color: $contrast-color;

  font-family: inherit;

  &__header,
  &__footer {
    display: flex;
    flex-direction: row;
    gap: 10px;

    & > a {
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__header {
    margin-bottom: 1em;
  }

  &__footer {
    justify-content: end;
    margin-top: 1em;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 14em;
    overflow-y: scroll;

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    li {
      height: 32px;
      line-height: 32px;
      padding: 0.1em 0.5em;
      color: #1a1a1a;
      cursor: pointer;

      label {
        padding-left: 1em;
        color: inherit;
      }

      & .b-icon.bi {
        color: #e6e6e6;
      }

      &.selected,
      &:hover {
        background: $contrast-color;
        color: white;
        width: 100%;
        border-radius: 0.5em;
      }

      &.selected .b-icon.bi {
        color: inherit;
      }
    }
  }
}
</style>
