const formatVersion = version => Number((version || '').toString().replace(/\./g, ''));

const filterVersionsByVersion = (versions, version): any[] =>
  (versions || []).filter(l => formatVersion(l.version) >= formatVersion(version));

const filterLocationsVersionByLocationsIds = (locationsVersion, selectedLocationIds) =>
  (locationsVersion || []).filter(lv => selectedLocationIds.indexOf(lv.locationId) !== -1);

const selectLocationsBySelectedVersion = (filteredVersions, version) =>
  (filteredVersions || [])
    .map(fv => ({ ...fv, versions: filterVersionsByVersion(fv.versions, version) }))
    .filter(l => l.versions.length !== 0);

const isOpeningDay = (v, date) => new Date(v.start).getTime() < new Date(date).getTime();

const isValidVersionsByDate = (versions, date) =>
  versions.filter(v => new Date(v.start).getTime() <= new Date(date).getTime() || isOpeningDay(v, date)).length !== 0;

const isValidVersion = (filteredVersions, version, date) => {
  const filteredList = selectLocationsBySelectedVersion(filteredVersions, version).filter(l =>
    isValidVersionsByDate(l.versions, date)
  );
  return Boolean(filteredList.length && filteredVersions.length && filteredList.length === filteredVersions.length);
};

export { isValidVersion, filterLocationsVersionByLocationsIds, formatVersion };
