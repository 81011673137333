<template>
  <vel-form
    ref="divisionForm"
    class="division-form"
    :model="divisionForm"
    :rules="divisionFormRules"
    :submit="handleSubmit"
    label-width="150px"
    :label-position="labelPosition"
  >
    <vel-form-item prop="remoteId" class="division-form-remoteId" :label="$t('divisionInfo.form.remoteId.label')">
      <vel-input disabled name="remoteId" class="name-input" type="text" :value="divisionForm.remoteId"></vel-input>
    </vel-form-item>

    <vel-form-item prop="name" class="division-form-name" :label="$t('divisionInfo.form.name.label')">
      <vel-input name="name" class="name-input" type="text" v-model="divisionForm.name" maxlength="24"></vel-input>
    </vel-form-item>

    <vel-form-item
      prop="alternativeName"
      class="division-form-alternative-name"
      :label="$t('divisionInfo.form.alternativeName.label')"
    >
      <vel-input
        name="alternativeName"
        class="name-input"
        type="text"
        v-model="divisionForm.alternativeName"
        maxlength="24"
      ></vel-input>
    </vel-form-item>

    <vel-form-item
      prop="bigDivisionName"
      class="division-form-big-division-name"
      :label="$t('divisionInfo.form.bigDivisionName.label')"
    >
      <vel-select v-model="divisionForm.bigDivisionId" value="divisionForm.bigDivisionName">
        <vel-option v-for="(option, i) in availableBigDivisions" :label="option.name" :value="option.id" :key="i" />
      </vel-select>
    </vel-form-item>

    <vel-form-item prop="isActive" class="division-form-is-active" :label="$t('divisionInfo.form.isActive.label')">
      <vel-switch v-model="divisionForm.isActive" />
    </vel-form-item>

    <vel-form-item class="division-form__actions">
      <vel-button type="primary" class="actions__submit" :loading="pending" @click="handleSubmit()">
        {{ $t('defaults.actions.save') }}
      </vel-button>

      <vel-button type="secondary" class="actions__cancel" :loading="pending" @click="handleCancel()">
        {{ $t('defaults.actions.cancel') }}
      </vel-button>
    </vel-form-item>
  </vel-form>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelButton from '@/components/button/VelButton';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelInput from '@/components/input/VelInput';
import VelOption from '@/components/x-option/VelOption';
import VelSelect from '@/components/x-select/VelSelect';
import VelSwitch from '@/components/switch/VelSwitch';

export default {
  name: 'division-form',
  mixins: [DeviceMixin],
  components: {
    VelButton,
    VelForm,
    VelFormItem,
    VelInput,
    VelOption,
    VelSwitch,
    VelSelect
  },
  props: {
    division: {
      type: Object,
      default: () => ({
        alternativeName: '',
        bigDivision: {},
        isActive: false,
        name: '',
        remoteId: '',
        licenceNumber: ''
      })
    },
    pending: {
      type: Boolean,
      default: false
    },
    availableBigDivisions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      divisionForm: {
        remoteId: this.division.remoteId,
        name: this.division.name,
        alternativeName: this.division.alternativeName,
        isActive: this.division.isActive,
        id: this.division.id,
        licenceNumber: this.division.licenceNumber,
        bigDivisionId: this.division.bigDivision && this.division.bigDivision.id
      }
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.divisionForm.validate(valid => {
        if (valid) {
          this.$emit('submit', this.divisionForm);
        }
      });
    },
    handleCancel() {
      this.$router.push({ path: '/divisions' });
    }
  },
  computed: {
    labelPosition() {
      return this.isMediumDown ? 'top' : 'left';
    },
    divisionFormRules() {
      return {
        name: [
          {
            required: false,
            message: this.$t('divisionInfo.form.name.isRequired')
          }
        ],
        alternativeName: [
          {
            required: false,
            message: this.$t('divisionInfo.form.alternativeName.isRequired')
          }
        ],
        isActive: [{ required: false }],
        bigDivisionName: [
          {
            required: false,
            message: this.$t('divisionInfo.form.bigDivisionName.isRequired')
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

.division-form {
  /deep/ {
    .vel-input-group {
      padding: 0;
    }

    .el-form--label-top .el-form-item__label {
      padding: 0 0.3125rem;
    }
  }

  /deep/ input:disabled {
    background-color: $athens-gray;
  }

  &-big-division-name {
    /deep/ input {
      font-size: $input-font-size;
    }
  }

  &-name,
  &-alternative-name,
  &-big-division-name {
    max-width: rem-calc(700);

    /deep/ .el-select {
      width: 100%;
    }
  }

  &-remoteId {
    max-width: rem-calc(300);
  }
}

.actions__cancel {
  margin-left: rem-calc(10);
}
</style>
