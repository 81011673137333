<template>
  <vel-page
    :title="$t('pages.reports.salesPerHourReport')"
    :multiple-locations="locationsList && locationsList.length > 1"
    :enable-all-currencies="true"
    :enable-date-range="true"
    :show-selector-button="true"
    :enable-currency="true"
    :show-crumbs="false"
    enable-toolbar
    class="sales-per-hour-report"
    :generatedTime="generatedTime"
  >
    <template v-slot:export>
      <vel-button
        :loading="generating"
        type="primary"
        @click="exportXLS"
        :icon="isMediumUp ? 'file-excel' : undefined"
        class="vel-button"
      >
        <vel-icon v-if="isMediumDown" name="file-download" />
        {{ isMediumUp ? $t('table.tableExport.button.value') : '' }}
      </vel-button>
    </template>
    <vel-spinner class="spinner" v-if="loading" />
    <vel-card v-if="!getLocationsSalesHourly.length && !loading">
      <div>{{ $t('defaults.empty') }}</div>
    </vel-card>
    <vel-card
      v-for="(location, i) in getLocationsSalesHourly.sort((a, b) =>
        (a.location.reportingDisplayName || a.location.name).localeCompare(
          b.location.reportingDisplayName || b.location.name
        )
      )"
      :key="i"
    >
      <template v-slot:title>
        <div class="title">
          {{ location.location.reportingDisplayName || location.location.name }}
        </div>
      </template>
      <div class="card-body" v-if="getLocationsSalesHourly.length">
        <div v-for="(date, day) in location.hourlySales" :key="day" class="sales-per-hour-table-wrapper">
          <table :class="day === 0 ? 'sales-per-hour-table-date' : 'sales-per-hour-table-date-range-selected'">
            <tr v-if="day === 0">
              <th>
                {{ $t('salesPerHourReport.table.headers.date') }}
              </th>
              <th>
                {{ $t('salesPerHourReport.table.headers.day') }}
              </th>
            </tr>
            <tr>
              <td>{{ getDate(location, day) }}</td>
              <td>{{ getDay(location, day) }}</td>
            </tr>
          </table>
          <table :class="day === 0 ? 'sales-per-hour-table-types' : 'sales-per-hour-table-types-range-selected'">
            <tr v-if="day === 0">
              <th>
                {{ $t('salesPerHourReport.table.headers.type') }}
              </th>
            </tr>
            <tr>
              <td>
                {{ $t('salesPerHourReport.table.headers.sales') }}
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('salesPerHourReport.table.headers.checkQty') }}
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('salesPerHourReport.table.headers.avgPerCheck') }}
              </td>
            </tr>
          </table>
          <table :class="day === 0 ? 'sales-per-hour-table-data' : 'sales-per-hour-table-data-range-selected'">
            <thead>
              <tr v-if="day === 0">
                <th class="sales-per-hour-table-data-header" colspan="24">
                  <div class="sales-per-hour-table-data-header-text">
                    {{ $t('salesPerHourReport.table.headers.businessHours') }}
                  </div>
                  <tr>
                    <td v-for="(_, h) in 24" :key="h">
                      <template v-if="h === 0">
                        {{ getTimeFormatByHour(location.location.dayChangeTime / 3600) }}
                      </template>
                      <template v-else-if="location.dayChangeTime / 3600 + h < 10">
                        {{ getTimeFormatByHour(location.location.dayChangeTime / 3600 + h) }}
                      </template>
                      <template v-else-if="location.location.dayChangeTime / 3600 + h < 24">
                        {{ getTimeFormatByHour(location.location.dayChangeTime / 3600 + h) }}
                      </template>
                      <template v-else>
                        {{ getTimeFormatByHour(location.location.dayChangeTime / 3600 + h - 24) }}
                      </template>
                    </td>
                  </tr>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-for="(sale, i) in getGrossSales(location, day)" :key="i">
                  <vel-amount :currency="location.location.detailedCurrency.currencySymbol" :amount="sale" />
                </td>
              </tr>
              <tr>
                <td v-for="(order, i) in getOrdersCount(location, day)" :key="i">{{ order }}</td>
              </tr>
              <tr>
                <td width="200" v-for="(avg, i) in getAveragePerChecks(location, day)" :key="i">
                  <vel-amount :currency="location.location.detailedCurrency.currencySymbol" :amount="avg" />
                </td>
              </tr>
            </tbody>
          </table>
          <table :class="day === 0 ? 'sales-per-hour-table-end' : 'sales-per-hour-table-end-range-selected'">
            <tr v-if="day === 0">
              <th>Total</th>
            </tr>
            <tr>
              <td>
                <vel-amount
                  :currency="location.location.detailedCurrency.currencySymbol"
                  :amount="getGrossSalesTotal(location, day)"
                />
              </td>
            </tr>
            <tr>
              <td>{{ getOrdersCountTotal(location, day) }}</td>
            </tr>
            <tr>
              <td>
                <vel-amount
                  :currency="location.location.detailedCurrency.currencySymbol"
                  :amount="getAveragePerChecksTotal(location, day)"
                />
              </td>
            </tr>
          </table>
        </div>
        <div class="sales-per-hour-total-wrapper" v-if="showTotal">
          <table class="sales-per-hour-total-date">
            <tr>
              <th>Total</th>
            </tr>
          </table>
          <table class="sales-per-hour-total-types">
            <tr>
              <td>
                {{ $t('salesPerHourReport.table.headers.sales') }}
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('salesPerHourReport.table.headers.checkQty') }}
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('salesPerHourReport.table.headers.avgPerCheck') }}
              </td>
            </tr>
          </table>
          <table class="sales-per-hour-total-data">
            <tbody>
              <tr>
                <td v-for="(sale, i) in grossSalesHourly(location)" :key="i">
                  <vel-amount :currency="location.location.detailedCurrency.currencySymbol" :amount="sale" />
                </td>
              </tr>
              <tr>
                <td v-for="(order, i) in ordersCountHourly(location)" :key="i">{{ order }}</td>
              </tr>
              <tr>
                <td v-for="(avg, i) in averagePerChecksHourly(location)" :key="i">
                  <vel-amount :currency="location.location.detailedCurrency.currencySymbol" :amount="avg" />
                </td>
              </tr>
            </tbody>
          </table>
          <table class="sales-per-hour-total-end">
            <tr>
              <td>
                <vel-amount :currency="location.location.detailedCurrency.currencySymbol" :amount="totalGrossSales" />
              </td>
            </tr>
            <tr>
              <td>{{ totalOrdersCount }}</td>
            </tr>
            <tr>
              <td>
                <vel-amount
                  :currency="location.location.detailedCurrency.currencySymbol"
                  :amount="totalAveragePerCheck"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </vel-card>
  </vel-page>
</template>

<script>
import { DateTime } from 'luxon';
import DeviceMixin from '@/mixins/device-mixin';
import GeneratedTime from '@/mixins/generated-time-mixin';
import router from '@/router';
import salesService from '@/services/sales.service';
import VelAmount from '@/components/amount/VelAmount';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelIcon from '@/components/icon/VelIcon';
import VelPage from '@/components/page/VelPage';
import VelSpinner from '@/components/spinner/VelSpinner';
import { getToken } from '@/helpers/token.helper';
import { getDateFormatFromUserConfig, getTimeFormatFromUserConfig, today } from '@/helpers/date.helpers';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { mapState as mapPiniaState } from 'pinia';
import { useUIDateRangeSelector } from '@/stores/ui/date-range-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { mapActions as mapPiniaActions } from 'pinia/dist/pinia';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataSalesHouylyLocations } from '@/stores/data/sales/hourly-locations-sales.module';

export default {
  name: 'sales-per-hour-report-page',
  mixins: [GeneratedTime, DeviceMixin],
  components: {
    VelAmount,
    VelButton,
    VelCard,
    VelPage,
    VelSpinner,
    VelIcon
  },
  data() {
    return {
      generating: false
    };
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, { locationsList: 'locations' }),
    ...mapPiniaState(useUILocationSelector, ['getLocationSelectorSelected']),
    ...mapPiniaState(useUIGroupSelector, ['getGroupSelectorSelected']),
    ...mapPiniaState(useUIDateRangeSelector, ['getDateSelectorSelectedRange']),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById']),
    ...mapPiniaState(useUILocationSelector, {
      locationCount: 'getLocationSelectorSelectedMultipleLength',
      locationListSelected: 'getLocationSelectorSelectedMultipleEntities',
      locationIds: 'getLocationSelectorSelectedMultipleIds'
    }),
    ...mapPiniaState(useDataSalesHouylyLocations, { loading: 'loading', getLocationsSalesHourly: 'selectAll' }),
    totalGrossSales() {
      return this.getLocationsSalesHourly.map(location =>
        location.hourlySales.map(hs => hs.grossSales.reduce((a, b) => a + b)).reduce((a, b) => a + b)
      )[0];
    },
    totalOrdersCount() {
      return this.getLocationsSalesHourly.map(location =>
        location.hourlySales.map(hs => hs.orders.reduce((a, b) => a + b)).reduce((a, b) => a + b)
      )[0];
    },
    totalAveragePerCheck() {
      return this.totalGrossSales / this.totalOrdersCount;
    },
    isLocation() {
      return this.getLocationSelectorSelected;
    },
    dateRange() {
      return new Date(this.getDateSelectorSelectedRange[1]) - new Date(this.getDateSelectorSelectedRange[0]);
    },
    today() {
      return today().startOf('day').toISO();
    },
    from() {
      return this.$router.currentRoute.query.from || this.today;
    },
    to() {
      return this.$router.currentRoute.query.to || this.from;
    },
    showTotal() {
      return this.getLocationsSalesHourly.length && this.dateRange > 1;
    },
    exportableRows() {
      return this.getLocationsSalesHourly;
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    ...mapPiniaActions(useDataSalesHouylyLocations, { loadLocationsSalesHourly: 'getLocationsSalesHourly' }),
    getTimeFormatByHour(h) {
      return DateTime.now().set({ hours: h, minute: 0, second: 0 }).toFormat(getTimeFormatFromUserConfig());
    },
    getDate(location, day) {
      return (
        location.hourlySales[day] &&
        DateTime.fromISO(location.hourlySales[day].date)
          .plus({ day: 1 })
          .toFormat(getDateFormatFromUserConfig())
          .toLocaleString()
      );
    },
    getDay(location, day) {
      return (
        location.hourlySales[day] && DateTime.fromISO(location.hourlySales[day].date).plus({ day: 1 }).weekdayShort
      );
    },
    getGrossSales(location, day) {
      return location.hourlySales[day] && location.hourlySales[day].grossSales;
    },
    getOrdersCount(location, day) {
      return location.hourlySales[day] && location.hourlySales[day].orders;
    },
    getAveragePerChecks(location, day) {
      return (
        location.hourlySales[day] &&
        location.hourlySales[day].grossSales.map((sale, i) => sale / location.hourlySales[day].orders[i] || 0)
      );
    },
    getGrossSalesTotal(location, day) {
      return location.hourlySales[day] && location.hourlySales[day].grossSales.reduce((a, b) => a + b).toFixed(2);
    },
    getOrdersCountTotal(location, day) {
      return location.hourlySales[day] && location.hourlySales[day].orders.reduce((a, b) => a + b);
    },
    getAveragePerChecksTotal(location, day) {
      return (
        location.hourlySales[day] &&
        (
          location.hourlySales[day].grossSales.reduce((a, b) => a + b) /
          location.hourlySales[day].orders.reduce((a, b) => a + b)
        ).toFixed(2)
      );
    },
    grossSalesHourly(location) {
      const grossSalesPerLoc = location.hourlySales.map(day => day.grossSales);
      const totals = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0
      };

      for (const hourlySales of grossSalesPerLoc) {
        hourlySales.forEach((hs, i) => {
          totals[i] += hs;
        });
      }

      return Object.values(totals);
    },
    ordersCountHourly(location) {
      const ordersPerLoc = location.hourlySales.map(day => day.orders);
      const totals = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0
      };

      for (const hourlySales of ordersPerLoc) {
        hourlySales.forEach((hs, i) => {
          totals[i] += hs;
        });
      }
      return Object.values(totals);
    },
    averagePerChecksHourly(location) {
      const totals = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0
      };
      return this.grossSalesHourly(location).map(
        (sale, i) => (totals[i] = sale / this.ordersCountHourly(location)[i] || 0)
      );
    },
    getDatesPerLocation(location) {
      return location.hourlySales;
    },
    getCurrentGroupName() {
      const selectedGroup = (this.getGroupsRows || []).filter(group => group.id === this.getGroupSelectorSelected);
      if (!selectedGroup.length) {
        return null;
      }
      return `${this.$t('exports.groupLabel')} ${selectedGroup[0].name}`;
    },
    getHoursArray(location) {
      const { dayChangeTime } = location;
      const dct = dayChangeTime / 3600;
      const hours = [];

      for (let i = 0; i < 24; i++) {
        if (i === 0) {
          hours.push(this.getTimeFormatByHour(dct + i));
        } else if (dct + i < 10) {
          hours.push(this.getTimeFormatByHour(dct + i));
        } else if (dct + i < 24) {
          hours.push(this.getTimeFormatByHour(dct + i));
        } else {
          hours.push(this.getTimeFormatByHour(dct + i - 24));
        }
      }
      return hours;
    },
    async exportXLS() {
      this.generating = true;
      this.$ga.event('report', 'download', this.$route.name);

      const toDay = today().startOf('day').toISO();
      const from = this.getDateSelectorSelectedRange[0];
      const to = this.getDateSelectorSelectedRange[1];

      const routeQuery = {
        locationIds: this.locationIds,
        groupId: this.getGroupSelectorSelected,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO()
      };

      await salesService.downloadLocationsSalesHourlyXlsx(getToken(), { ...routeQuery }).catch(e => {
        console.error(e);
        this.generating = false;
      });

      this.generating = false;
    }
  },
  beforeDestroy() {
    this.gen(false);
  },
  mounted() {
    this.loadLocationsSalesHourly();
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';
/* stylelint-disable */

.vel-button {
  background-color: $mountain-meadow;
  direction: rtl;
}

/deep/ {
  .vel-button__text {
    padding: 0 0.625rem;
  }

  .vel-button__icon {
    padding-left: 0;
  }
}

.vel-card {
  .title {
    color: #00a9e1;
  }
}

.card-body {
  overflow-x: scroll;
}

.sales-per-hour-table-wrapper,
.sales-per-hour-total-wrapper {
  display: flex;
}

.filler {
  border: none;
  display: inline-block;
  min-height: rem-calc(27);
}

.sales-per-hour-table {
  &-data-header {
    height: rem-calc(64);

    &-text {
      height: rem-calc(35);
      line-height: rem-calc(35);
      vertical-align: middle;
    }

    tr {
      height: rem-calc(28);

      td,
      td div {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        vertical-align: middle;
      }
    }
  }

  &-date,
  &-date-range-selected {
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    border-top: 1px solid #eee;
    border-collapse: collapse;
    max-width: rem-calc(200);
    min-width: rem-calc(200);
    height: rem-calc(175);

    th:not(:empty) {
      border-bottom: 1px solid #eee;
      font-weight: bold;
      height: rem-calc(64);
      text-align: left !important;
      padding: 0 rem-calc(12);
      vertical-align: middle;

      &:first-child {
        border-right: 1px solid #eee;
      }
    }

    td:not(:empty) {
      max-width: rem-calc(110);
      min-width: rem-calc(110);
      padding: rem-calc(15);

      &:first-child {
        max-width: rem-calc(130);
        min-width: rem-calc(130);
        border-right: 1px solid #eee;
      }
    }

    &-range-selected {
      border-top: 1px solid rgba(42, 49, 66, 0.5);
      height: rem-calc(110);
    }
  }

  &-types,
  &-types-range-selected {
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-top: 1px solid #eee;
    border-collapse: collapse;
    height: rem-calc(175);
    max-width: rem-calc(200);
    min-width: rem-calc(200);

    th:not(:empty) {
      border-bottom: 1px solid #eee;
      font-weight: bold;
      height: rem-calc(64);
      text-align: left !important;
      padding: 0 rem-calc(12);
      vertical-align: middle;
    }

    td:not(:empty) {
      border-bottom: 1px solid #eee;
      max-width: rem-calc(200);
      min-width: rem-calc(200);
      padding-left: rem-calc(5);
      padding-top: rem-calc(5);
    }

    &-range-selected {
      border-top: 1px solid rgba(42, 49, 66, 0.5);
      height: rem-calc(110);
    }
  }

  &-data,
  &-data-range-selected {
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    border-collapse: collapse;
    height: rem-calc(175);

    td:not(:empty) {
      border-bottom: 1px solid #eee;
      border-right: 1px solid #eee;
      max-width: rem-calc(115);
      min-width: rem-calc(115);
      padding: rem-calc(5);
      text-align: right;

      &:nth-child(1) {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }

    th:not(:empty) {
      font-weight: bold;

      td:not(:empty) {
        border-top: 1px solid #eee;
      }
    }

    &-header {
      padding: 0;

      &-text {
        margin-right: 40%;
      }
    }

    &-range-selected {
      border-top: 1px solid rgba(42, 49, 66, 0.5);
      height: rem-calc(110);
    }
  }

  &-end,
  &-end-range-selected {
    background: #ddd;
    border-collapse: collapse;
    font-weight: bold;
    height: rem-calc(175);
    text-align: right;

    th:not(:empty) {
      border-bottom: 1px solid #fff;
      font-weight: bold;
      height: rem-calc(64);
      line-height: rem-calc(64);
      padding-right: rem-calc(5);
    }

    td:not(:empty) {
      border-bottom: 1px solid #fff;
      max-width: rem-calc(200);
      min-width: rem-calc(200);
      padding: rem-calc(5);
    }

    &-range-selected {
      border-top: 1px solid rgba(42, 49, 66, 0.5);
      height: rem-calc(110);
    }
  }
}

.sales-per-hour-total {
  &-date {
    background: #ddd;
    border-right: 1px solid #fff;
    border-collapse: collapse;
    height: rem-calc(110);

    th:not(:empty) {
      font-weight: bold;
      text-align: left !important;
      max-width: rem-calc(240);
      min-width: rem-calc(240);
      padding-left: rem-calc(15);
    }
  }

  &-types {
    border-bottom: 1px solid #fff;
    background: #ddd;
    border-collapse: collapse;
    height: rem-calc(110);
    max-width: rem-calc(200);
    min-width: rem-calc(200);

    th:not(:empty) {
      font-weight: bold;
      max-width: rem-calc(200);
      min-width: rem-calc(200);
      padding: 0 rem-calc(5);
    }

    td:not(:empty) {
      border-top: 1px solid #fff;
      max-width: rem-calc(200);
      min-width: rem-calc(200);
      padding: rem-calc(5);
    }
  }

  &-data {
    background: #ddd;
    border-bottom: 1px solid #fff;
    border-collapse: collapse;
    height: rem-calc(110);

    td:not(:empty) {
      border: 1px solid #fff;
      max-width: rem-calc(115);
      min-width: rem-calc(115);
      padding: rem-calc(5);
      text-align: right;
    }

    th:not(:empty) {
      font-weight: bold;
      max-width: rem-calc(105);
      min-width: rem-calc(105);
      text-align: center !important;
    }
  }

  &-end {
    background: #ddd;
    border-collapse: collapse;
    font-weight: bold;
    height: rem-calc(110);
    text-align: right;

    th:not(:empty) {
      font-weight: bold;
      max-width: rem-calc(200);
      min-width: rem-calc(200);
      padding: rem-calc(15);
    }

    td:not(:empty) {
      border-bottom: 1px solid #fff;
      border-top: 1px solid #fff;
      max-width: rem-calc(200);
      min-width: rem-calc(200);
      padding: rem-calc(5);
    }
  }
}

.sales-per-hour-table-date,
.sales-per-hour-table-date-range-selected {
  max-width: rem-calc(240);
  min-width: rem-calc(240);
}

.sales-per-hour-table-data-range-selected td {
  width: 200px;
}

td {
  overflow: hidden;
}

td span {
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
