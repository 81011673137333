<template>
  <vel-page
    :multiple-locations="locationsList && locationsList.length > 1"
    :show-selector-button="true"
    :enable-all-currencies="true"
    class="report"
    v-bind="pageAttrs"
  >
    <template v-slot:export>
      <vel-button
        :disabled="loading"
        :loading="generating"
        type="primary"
        @click="exportXLS"
        :icon="isMediumUp ? 'file-excel' : undefined"
        class="vel-button"
      >
        <vel-icon v-if="isMediumDown" name="file-download" />
        {{ isMediumUp ? $t('table.tableExport.button.value') : '' }}
      </vel-button>
    </template>
    <vel-spinner v-if="loading" class="spinner" />
    <div v-if="invalidLocations" style="background-color: #fff2cc; margin-bottom: 1rem; padding: 1rem;">
      <template v-if="getLocationSelectorSelected">
        {{ $t('invalidVersions.requiredVersion') }}
      </template>
      <template v-else>
        {{ $t('invalidVersions.requiredVersionMultiLocations') }}
        <a href="javascript://" @click="showLocationsList = !showLocationsList">{{ $t('invalidVersions.collapse') }}</a>
        <ul v-if="showLocationsList" style="margin-top: 1rem;">
          <li v-for="location in invalidLocations" :key="location.id">
            {{ location.reportingDisplayName || location.name }}
          </li>
        </ul>
      </template>
    </div>
    <p
      v-if="showNotification && !isVersionLoading"
      style="background-color: #ffd2d2; margin-bottom: 1rem; padding: 1rem;"
    >
      {{ $t('invalidVersions.notice') }}
    </p>
    <template v-if="!loading">
      <div class="no-data" v-if="!Object.values(data).length">
        {{ $t('itemSalesCategory.noData') }}
      </div>
      <template v-else>
        <vel-card
          v-for="location in Object.values(data).sort((a, b) =>
            (a.reportingDisplayName || a.name).localeCompare(b.reportingDisplayName || b.name)
          )"
          :key="location.id"
        >
          <h5 style="color: #409eff;">{{ (location.reportingDisplayName || location.name).toUpperCase() }}</h5>
          <table>
            <thead>
              <tr>
                <th style="width: 160px;">{{ $t('giftCard.table.headers.date') }}</th>
                <th style="width: 120px;">{{ $t('giftCard.table.headers.time') }}</th>
                <th style="width: 200px;">{{ $t('giftCard.table.headers.cardNumber') }}</th>
                <th style="width: 200px;">{{ $t('giftCard.table.headers.description') }}</th>
                <th class="text-right" style="width: 200px;">{{ $t('giftCard.table.headers.funding') }}</th>
                <th class="text-right" style="width: 200px;">{{ $t('giftCard.table.headers.redeem') }}</th>
                <th class="text-right" style="width: 200px;">
                  {{ $t('giftCard.table.headers.check') }}
                </th>
                <th style="width: 200px;">{{ $t('giftCard.table.headers.waiter') }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="dates in location.dates.sort((a, b) => a.date.localeCompare(b.date))">
                <tr v-for="(tenderType, i) in dates.giftCards" :key="tenderType.id">
                  <td v-bind:class="{ whitecell: i, date: true }">
                    <span v-if="!i">{{ dates.date }}</span>
                  </td>
                  <td>
                    {{
                      tenderType.time.replace(/^2(4|5|6|7|8)/gi, matched => {
                        return '0' + (matched - 24).toString();
                      })
                    }}
                  </td>
                  <td>**** **** **** {{ tenderType.lastDigits }}</td>
                  <td>{{ $t('giftCard.description.' + tenderType.operation) }}</td>
                  <td class="text-right">
                    <vel-amount
                      v-if="[10, 11].includes(tenderType.operation)"
                      :amount="tenderType.amount"
                      :currency="location.currency"
                    ></vel-amount>
                  </td>
                  <td class="text-right">
                    <vel-amount
                      v-if="![10, 11].includes(tenderType.operation)"
                      :amount="tenderType.amount"
                      :currency="location.currency"
                    ></vel-amount>
                  </td>
                  <td class="text-right">{{ tenderType.checkNumber }}</td>
                  <td>{{ tenderType.employee.name }}</td>
                </tr>
                <tr class="subtotal" :key="dates.id">
                  <td style="background-color: white;"></td>
                  <td style="background-color: white;"></td>
                  <td style="background-color: white;"></td>
                  <td>
                    <b>{{ $t('giftCard.subtotal') }}</b>
                  </td>
                  <td class="text-right">
                    <b>
                      <vel-amount :amount="dates.totalFunding" :currency="location.currency"></vel-amount>
                    </b>
                  </td>
                  <td class="text-right">
                    <b>
                      <vel-amount :amount="dates.totalRedeem" :currency="location.currency"></vel-amount>
                    </b>
                  </td>
                  <td style="background-color: #e0e0e0;">
                    <b>{{ $t('giftCard.liability') }}</b>
                  </td>
                  <td style="background-color: #e0e0e0;" class="text-right">
                    <b>
                      <vel-amount
                        :amount="dates.totalFunding - dates.totalRedeem"
                        :currency="location.currency"
                      ></vel-amount>
                    </b>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th style="border-bottom: none; width: 160px;"></th>
                <th class="text-right" style="border-bottom: none; width: 160px;"></th>
                <th class="text-right" style="border-bottom: none; width: 160px;"></th>
                <th class="text-right" style="border-bottom: none; width: 200px;"></th>
                <th class="text-right" style="border-bottom: none; width: 200px;"></th>
                <th class="text-right" style="border-bottom: none; width: 200px;"></th>
                <th class="text-right" style="border-bottom: none; width: 200px;"></th>
                <th class="text-right" style="border-bottom: none; width: 200px;"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="subCategoryTotal">
                <td style="background-color: white; border: none;"></td>
                <td style="background-color: white; border: none;"></td>
                <td style="background-color: white; border: none;"></td>
                <td>
                  <b>{{ $t('giftCard.total') }}</b>
                </td>
                <td class="text-right">
                  <vel-amount :amount="location.allTotalFunding" :currency="location.currency"></vel-amount>
                </td>
                <td class="text-right">
                  <vel-amount :amount="location.allTotalRedeem" :currency="location.currency"></vel-amount>
                </td>
                <td style="background-color: #e0e0e0;">
                  <b>{{ $t('giftCard.liability') }}</b>
                </td>
                <td style="background-color: #e0e0e0; border-top: none;" class="text-right">
                  <vel-amount
                    :amount="location.allTotalFunding - location.allTotalRedeem"
                    :currency="location.currency"
                  ></vel-amount>
                </td>
              </tr>
            </tbody>
          </table>
        </vel-card>
      </template>
    </template>
  </vel-page>
</template>

<script>
import { DateTime } from 'luxon';
import DeviceMixin from '@/mixins/device-mixin';
import GeneratedTime from '@/mixins/generated-time-mixin';
import VelAmount from '@/components/amount/VelAmount';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelIcon from '@/components/icon/VelIcon';
import VelPage from '@/components/page/VelPage';
import VelSpinner from '@/components/spinner/VelSpinner';
import { environment } from '@/configs';
import { formatVersion } from '@/helpers/versions.module.helper';
import { getToken } from '@/helpers/token.helper';
import router from '@/router';
import salesService from '@/services/sales.service';
import { getDateFormatFromUserConfig, getTimeFormatFromUserConfig, today } from '@/helpers/date.helpers';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { mapState as mapPiniaState } from 'pinia';
import { useUICurrencySelector } from '@/stores/ui/currency-selector.module';
import { useUIDateRangeSelector } from '@/stores/ui/date-range-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { mapActions as mapPiniaActions, storeToRefs } from 'pinia/dist/pinia';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataSalesLocations } from '@/stores/data/sales/locations.module';
import { useDataSalesIndicators } from '@/stores/data/sales/indicators.module';
import { useDataLocationsVersionsStore } from '@/stores/data/locations/versions.module';
import { useDataConfig } from '@/stores/data/config.module';
import { useDataGroups } from '@/stores/data/groups.module';

export default {
  name: 'SummaryReportPage',
  components: {
    VelPage,
    VelButton,
    VelAmount,
    VelCard,
    VelSpinner,
    VelIcon
  },
  mixins: [DeviceMixin, GeneratedTime],
  data() {
    return {
      showLocationsList: false,
      loading: true,
      generating: false,
      isMultiDays: false,
      updateIndicators: 0,
      updateCharts: 1,
      data: {},
      diffValues: {},
      voids: {},
      corrections: {},
      refunds: [],
      expand: {
        category: {},
        subCategory: {}
      },
      previousIndicators: {
        itemCorrectionsAmount: 0,
        voidsAmount: 0,
        refundsAmount: 101
      }
    };
  },
  setup() {
    const locationsSalesStore = useDataSalesLocations();

    const { getLocationsSales } = storeToRefs(locationsSalesStore);

    return { getSales: getLocationsSales, locationsSalesStore };
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, { locationsList: 'locations' }),
    ...mapPiniaState(useDataLocationsVersionsStore, {
      isVersionValid: 'isVersionValid',
      isVersionLoading: 'loading'
    }),
    ...mapPiniaState(useUIGroupSelector, ['getGroupSelectorSelected']),
    ...mapPiniaState(useUILocationSelector, ['getLocationSelectorSelected', 'locations', 'getLocationById']),
    ...mapPiniaState(useUICurrencySelector, ['getCurrencySelectorSelected']),
    ...mapPiniaState(useUIDateRangeSelector, ['getDateSelectorSelectedRange']),
    ...mapPiniaState(useDataGroups, ['getGroupsRows']),
    ...mapPiniaState(useUILocationSelector, [
      'getLocationSelectorSelected',
      'locations',
      'getLocationSelectorSelectedMultiple'
    ]),
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useUILocationSelector, {
      locationCount: 'getLocationSelectorSelectedMultipleLength',
      locationListSelected: 'getLocationSelectorSelectedMultipleEntities',
      locationIds: 'getLocationSelectorSelectedMultipleIds'
    }),
    ...mapPiniaState(useDataLocationsStore, [
      'getLocationsById',
      'getLocationsIsLoading',
      'locationsBySelectedGroup',
      'multiLocationsBySelectedGroup'
    ]),
    ...mapPiniaState(useDataSalesIndicators, [
      'getIndicatorsVoids',
      'getIndicatorsVoidsAmount',
      'getIndicatorsRefundsAmount',
      'getIndicatorsSalesVolume',
      'getIndicatorsRefunds',
      'getIndicatorsItemCorrections',
      'getIndicatorsItemCorrectionsAmount'
    ]),
    chartAttrs() {
      return {
        locationId: this.getLocationSelectorSelected,
        currencyId: this.getCurrencySelectorSelected,
        from: this.getDateSelectorSelectedRange[0],
        to: this.getDateSelectorSelectedRange[1],
        limit: this.get.charts_size_limitation.value
      };
    },
    config() {
      return environment;
    },
    pageAttrs() {
      return {
        enableToolbar: true,
        enableCurrency: true,
        enableDateRange: true,
        title: this.$t('giftCard.title'),
        showCrumbs: false,
        failed: this.hasPageFailed,
        generatedTime: this.generatedTime,
        showDateCompare: this.showLocationsSales
      };
    },
    hasPageFailed() {
      return this.false;
    },
    isPageLoading() {
      return this.getLocationsIsLoading;
    },
    showLocationsSales() {
      return !this.getLocationSelectorSelected || this.locations.length === 1;
    },
    isAllInvalid() {
      const location = this.getLocationsById(this.getLocationSelectorSelected);
      if (this.getLocationSelectorSelected && formatVersion((location && location.version) || 0) < 9620) {
        return true;
      }
      const locations = (this.multiLocationsBySelectedGroup || [])
        .map(k => {
          const l = this.getLocationsById(k.value) || {};
          return { ...l, version: formatVersion(l.version) };
        })
        .filter(l => l.version < 9620);
      return locations.length === this.multiLocationsBySelectedGroup.length;
    },
    invalidLocations() {
      const location = this.getLocationsById(this.getLocationSelectorSelected);
      if (this.getLocationSelectorSelected && formatVersion((location && location.version) || 0) >= 9620) {
        return false;
      }
      const locations = (this.multiLocationsBySelectedGroup || [])
        .map(k => {
          const l = this.getLocationsById(k.value) || {};
          return { ...l, version: formatVersion(l.version) };
        })
        .filter(l => l.version < 9620);
      if (!locations.length) {
        return null;
      }
      return locations;
    },
    showNotification() {
      const isUpdated = this.getLocationSelectorSelected
        ? formatVersion(this.getLocationsById(this.getLocationSelectorSelected).version) >= 9620
        : !this.invalidLocations;
      const hasInvalidData = this.getLocationSelectorSelected
        ? !this.versionIsValid(this.getLocationSelectorSelected)
        : !!(this.multiLocationsBySelectedGroup || []).filter(x => !this.versionIsValid(x.value)).length;

      if (!this.getLocationSelectorSelected && !isUpdated) {
        const locations = (this.multiLocationsBySelectedGroup || [])
          .map(k => {
            const l = this.getLocationsById(k.value) || {};
            return { ...l, version: formatVersion(l.version) };
          })
          .filter(l => l.version >= 9620)
          .filter(l => !this.versionIsValid(l.id));

        if (locations.length) {
          return true;
        }
      }
      return isUpdated && hasInvalidData;
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    ...mapPiniaActions(useDataLocationsVersionsStore, ['getVersions']),

    versionIsValid(locationId = null) {
      // @todo refactor so that we no longer have to pass getters as arguments
      return (
        !this.isVersionLoading &&
        this.isVersionValid(
          '9.62.0',
          locationId ? locationId : this.getLocationSelectorSelected,
          locationId ? null : this.getGroupSelectorSelected,
          this.getDateSelectorSelectedRange[0]
        )
      );
    },
    async exportXLS() {
      this.generating = true;
      this.$ga.event('report', 'download', this.$route.name);

      const from = this.getDateSelectorSelectedRange[0];
      const to = this.getDateSelectorSelectedRange[1];

      const routeQuery = {
        locationIds: this.locationIds,
        groupId: this.getGroupSelectorSelected,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO()
      };

      await salesService.downloadLocationSalesAccountingGiftCardsXlsx(getToken(), { ...routeQuery }).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    },
    /* eslint-disable */
    async fetchData(orderBy = 'date', order = 'asc') {
      const toDay = today().startOf('day').toISO();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;
      const routeQuery = {
        ...router.currentRoute.query,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO()
      };
      const serviceVoidsData = await salesService
        .getLocationSalesAccountingGiftCards(getToken(), {
          ...routeQuery
        })
        .then(ld => {
          (ld.content || []).forEach(c => {
            let allTotalFunding = 0;
            let allTotalRedeem = 0;

            this.data[c.locationId] = {
              name: c.location && c.location.name,
              reportingDisplayName: c.location && c.location.reportingDisplayName,
              currency: c.location && c.location.detailedCurrency.currencySymbol,
              id: c.location.id,
              dates: (c.accountingDates || []).map(ad => {
                let totalFunding = 0;
                let totalRedeem = 0;

                const dateData = {
                  ...ad,
                  date: DateTime.fromISO(ad.date).plus({ day: 1 }).toFormat(getDateFormatFromUserConfig()),
                  totalRedeem: 0,
                  totalFunding: 0,
                  giftCards: (ad.giftCards || [])
                    .map(d => {
                      if (![10, 11].includes(d.operation)) {
                        totalRedeem += d.amount;
                        allTotalRedeem += d.amount;
                      } else {
                        d.amount = d.amount * -1;
                        totalFunding += d.amount;
                        allTotalFunding += d.amount;
                      }

                      return {
                        ...d,
                        time: DateTime.fromISO(d.eventTime).toFormat(getTimeFormatFromUserConfig())
                      };
                    })
                    .sort((a, b) => a.time.localeCompare(b.time))
                };
                dateData.totalRedeem = totalRedeem;
                dateData.totalFunding = totalFunding;
                return dateData;
              })
            };
            this.data[c.locationId]['allTotalRedeem'] = allTotalRedeem;
            this.data[c.locationId]['allTotalFunding'] = allTotalFunding;
          });
        })
        .catch(e => {
          this.loading = false;
          this.generating = false;
        });
      this.loading = false;
      this.generating = false;
    }
  },
  beforeDestroy() {
    this.gen(false);
  },
  async mounted() {
    await this.getVersions();
    await this.fetchData();
    // this.generateTimeAt('data/sales/indicators/getIndicatorsSuccess');
  }
};
</script>
<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

/deep/ .vel-card__body {
  overflow: auto;
}

table {
  border-spacing: 0;
  margin-top: 1rem;
  min-width: 1200px;
  table-layout: fixed;
  width: 100%;
}

/* stylelint-disable */
th {
  border-bottom: 1px solid #e5e5e5;
  font-size: 0.9375rem;
  font-weight: bold;
  padding: 0.2rem 0.5rem;
  text-align: left !important;
  text-overflow: ellipsis;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

td {
  border-top: 1px solid #e5e5e5;
  font-size: 0.9375rem;
  overflow: hidden;
  padding: 0.2rem 0.5rem;
  text-align: left !important;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  word-break: keep-all;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.arrow-right {
  /* Vector */
  background: #000;
  bottom: 25%;
  left: 35.79%;
  position: absolute;
  right: 33.33%;
  top: 25%;
}

.date {
  width: 120px;
}

.time {
  max-width: 100px;
}

.itemName {
  width: 360px;
}

.price {
  width: 120px;
}

.quantity {
  width: 90px;
}

.check {
  width: 120px;
}

.categoryName {
  width: 300px;
}

.employee {
  width: 300px;
}

.authorized {
  width: 300px;
}

.location {
  border: none;
  width: 200px;
}

.total {
  background-color: #d0d0d0 !important;
  font-weight: bold;
}

.subCategoryTotal td {
  background-color: #e0e0e0;
  font-weight: bold;
}

.itemTotal td {
  background-color: #f0f0f0;
  font-weight: bold;
}

.subtotal td {
  background-color: #f6f6f6;
  border-bottom: 1px solid #c0c0c0;

  &.location {
  }
}

.order-header {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;

  .sort {
    display: flex;
    flex-direction: column;
    margin-left: 5px;

    .order {
      height: 9px;
      width: 9px;

      &.active {
        color: #00a9e1;
        fill: #00a9e1;
      }
    }
  }
}

.multiple {
  cursor: pointer;
}

.subtotal + tr .location span {
  display: none;
}

.empty {
  border-top: 1px solid #fff;
}

tr {
  border: none;
}

.vel-button {
  @include noPrint;
  background-color: $mountain-meadow;
  border-color: $mountain-meadow;
  direction: rtl;

  &:hover {
    background-color: mix(black, $mountain-meadow, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}

strong {
  font-weight: 700;
}

td {
  font-size: 0.9375rem;
}

.expanded > td.qty span {
  display: none;
}

.expanded > td.amount span {
  display: none;
}

.no-data {
  font-size: 2rem;
  color: #4f4f4f;
  text-align: center;
  margin: 2rem;
}

.whitecell {
  border: none;
  border-right: 1px solid #e5e5e5;
}

.date {
  border-right: 1px solid #e5e5e5;
}
</style>
