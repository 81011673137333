import 'whatwg-fetch';
import { get, put, resolveCommonQuery } from '@/helpers/service.helper';
import pick from 'lodash.pick';

/**
 * Get a big division by its id
 * @link https://api.posveloce.com/docs/#operation/getBigDivisionByID
 */
const getBigDivision = (token, query) => {
  const { bigDivisionId, locationId } = query;

  return get(token, `/locations/${locationId}/bigDivisions/${bigDivisionId}`);
};

/**
 * Get big divisions
 * @link https://api.posveloce.com/docs#operation/getBigDivisions
 */
const getBigDivisions = (token, query) => {
  const params = pick(query, ['locationId', 'locationIds']);
  const url = resolveCommonQuery('/bigDivisions', params).toString();

  return get(token, url);
};

/**
 * Update (PUT) big divisions
 * @link https://api.posveloce.com/docs#operation/putBigDivisions
 */

const updateBigDivision = (token, bigDivision) => put(token, '/bigDivisions', bigDivision);

export default {
  getBigDivision,
  getBigDivisions,
  updateBigDivision
};
