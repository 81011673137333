<script>
import { Tree } from 'element-ui';

export default {
  name: 'vel-tree',
  extends: Tree,
  methods: {
    async expandAll() {
      await this.recursiveExpandAll(this.store.root);
    },
    async collapseAll() {
      await this.recursiveCollapseAll(this.store.root);
    },
    async recursiveExpandAll(node) {
      const nodes = node.childNodes || [];
      for (const key in nodes) {
        if (Object.prototype.hasOwnProperty.call(nodes, key)) {
          nodes[key].expand();
          await this.wait(1);
          await this.recursiveExpandAll(nodes[key]);
        }
      }
    },
    async recursiveCollapseAll(node) {
      const nodes = node.childNodes || [];
      for (const key in nodes) {
        if (Object.prototype.hasOwnProperty.call(nodes, key)) {
          nodes[key].expanded = false;
          await this.wait(1);
          await this.recursiveCollapseAll(nodes[key]);
        }
      }
    },
    wait(ms) {
      return new Promise(r => setTimeout(r, ms));
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

.el-tree-node__content:hover {
  /* stylelint-disable */
  background-color: $iris-blue !important;
  color: white;
}
</style>
