<template>
  <VelPage
    :multiple-locations="locations.length > 1"
    :show-selector-button="locations.length > 1"
    :show-selector-single-location-button="false"
    class="dashboard"
    v-bind="pageAttrs"
  >
    <template v-slot:customizer>
      <ConfigDashboardDisplay />
    </template>
    <Indicators :disable-range="true" :key="updateIndicators" :show-icon="false" secondary />
    <DashboardCharts :key="updateCharts" />
  </VelPage>
</template>
<script>
import ConfigDashboardDisplay from '@/private/components/config/appearance/dashboard/ConfigDashboardDisplay';
import DashboardCharts from '@/private/dashboard/components/DashboardCharts';
import GeneratedTime from '@/mixins/generated-time-mixin';
import Indicators from '@/private/shared/indicators/Indicators';
import VelPage from '@/components/page/VelPage';
import { mapActions as mapPiniaActions, mapState, storeToRefs } from 'pinia';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataLocationsVersionsStore } from '@/stores/data/locations/versions.module';
import { useDataSalesIndicators } from '@/stores/data/sales/indicators.module';

export default {
  name: 'DashboardPage',
  components: {
    DashboardCharts,
    Indicators,
    VelPage,
    ConfigDashboardDisplay
  },
  mixins: [GeneratedTime],
  setup() {
    const dataLocationsStore = useDataLocationsStore();
    const uiLocationsStore = useUILocationSelector();
    const dataLocationsVersionsStore = useDataLocationsVersionsStore();

    const { locations } = storeToRefs(dataLocationsStore);
    const { gen } = uiLocationsStore;
    return { locations, gen, getVersions: dataLocationsVersionsStore.getVersions };
  },
  computed: {
    ...mapState(useDataLocationsStore, ['getLocationsIsLoading']),
    pageAttrs() {
      return {
        enableToolbar: true,
        enableCurrency: true,
        title: this.$t('pages.dashboard'),
        showCrumbs: false,
        failed: this.hasPageFailed,
        loading: this.isPageLoading,
        generatedTime: this.generatedTime,
        showDateCompare: false,
        enableDatePicker: true
      };
    },
    isPageLoading() {
      return this.getLocationsIsLoading;
    },
    hasPageFailed() {
      return false;
    }
  },
  methods: {},
  data() {
    return {
      updateIndicators: 0,
      updateCharts: 1
    };
  },
  destroyed() {
    this.gen(false);
  },
  mounted() {
    this.getVersions();
    // this.generateTimeAt('data/sales/indicators/getIndicatorsSuccess');
    this.gen(true);
  }
};
</script>

<style lang="scss">
@import 'mixins.scss';
@import 'constants.scss';

/deep/ .vel-modal {
  max-width: 400px !important;
}
</style>
