import { defineStore } from 'pinia';
import router from '@/router';
import { useUIAlertStore } from '@/stores/ui/alert.module';
import { parameterizeDate, today } from '@/helpers/date.helpers';
import { DateTime } from 'luxon';

export const useUIDateSelector = defineStore('dateSelector', {
  state: () => ({
    selected: today().toString(),
    error: null
  }),
  getters: {
    getDateSelectorSelected: state => DateTime.fromISO(state.selected).toJSDate(),
    getDateSelectorSelectedRange: state => [state.selected, parameterizeDate(state.selected, true)]
  },
  actions: {
    async onChange(query: any = {}) {
      if (query && query.date) {
        this.updateSelectorDate({
          ...router.currentRoute.query,
          from: query.date,
          to: query.date
        });
      }
      if (!(query && query.disableRouteChange)) {
        try {
          await router.replace({
            name: router.currentRoute.name,
            query: {
              ...router.currentRoute.query,
              from: query.date,
              to: query.date
            }
          });
        } catch (error) {
          // @todo
        }
      }
    },
    routeChanged(query) {
      if (this.selected !== query.to.query.from) {
        this.selected = query.to.query.from || today().toString();
      }
    },
    async updateDate(date) {
      try {
        this.selected = date;

        await router.replace({
          name: router.currentRoute.name,
          query: {
            ...router.currentRoute.query,
            from: parameterizeDate(date),
            to: parameterizeDate(date)
          }
        });
        this.error = null;
      } catch (error) {
        this.error = error;
        const alertStore = useUIAlertStore();
        alertStore.newError(error);
      }
    },
    updateSelectorDate(payload) {
      this.selected = payload.from || today().toString();
    },

    updateSelectorDateSuccess() {
      this.error = null;
    },
    updateSelectorDateFailure(error) {
      this.error = error;
    }
  }
});
