<template>
  <div class="discount-availability" v-if="!loading">
    <div class="discount-availability-header">
      <div class="discount-availability-header-item" v-for="(d, i) in weekDays" :key="i">{{ d.title }}</div>
    </div>
    <div class="discount-availability-body">
      <div class="discount-availability-side">
        <div class="discount-availability-side-item" v-for="(m, i) in modes" :key="i">{{ m.name }}</div>
      </div>
      <div class="discount-availability-options">
        <div class="discount-availability-options-row" v-for="(m, i) in modes" :key="i">
          <div class="discount-availability-options-row-column" v-for="(d, j) in weekDays" :key="j">
            <vel-checkbox
              v-model="schedules[i][j]"
              @change="handleCheck"
              class="discount-availability-options-row-column-item"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <vel-button class="select-all" @click="handleCheckAll">
        {{ $t('discountInfo.form.actions.selectAll') }}
      </vel-button>
      <vel-button class="deselect-all" type="secondary" @click="handleCheckAll(false)">
        {{ $t('discountInfo.form.actions.deselectAll') }}
      </vel-button>
    </div>
  </div>
</template>

<script>
import VelButton from '@/components/button/VelButton';
import VelCheckbox from '@/components/checkbox/VelCheckbox';
import { hasWeekdayEnabled } from '@/helpers/date.helpers';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataDiscountsSchedule } from '@/stores/data/discounts-schedule.module';

export default {
  name: 'DiscountAvailability',
  components: { VelButton, VelCheckbox },
  props: {
    scheduleByModes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapPiniaState(useDataDiscountsSchedule, ['schedule', 'loading']),
    weekDays() {
      return [
        {
          title: this.$t('discountInfo.form.availability.weekDays.sun')
        },
        {
          title: this.$t('discountInfo.form.availability.weekDays.mon')
        },
        {
          title: this.$t('discountInfo.form.availability.weekDays.tue')
        },
        {
          title: this.$t('discountInfo.form.availability.weekDays.wed')
        },
        {
          title: this.$t('discountInfo.form.availability.weekDays.thu')
        },
        {
          title: this.$t('discountInfo.form.availability.weekDays.fri')
        },
        {
          title: this.$t('discountInfo.form.availability.weekDays.sat')
        }
      ];
    },
    modes() {
      return this.schedule.map(schedule => schedule.mode);
    },
    schedules() {
      return this.schedule.map(sbm => {
        const { schedule } = sbm;
        return {
          0: hasWeekdayEnabled(schedule, 1),
          1: hasWeekdayEnabled(schedule, 2),
          2: hasWeekdayEnabled(schedule, 3),
          3: hasWeekdayEnabled(schedule, 4),
          4: hasWeekdayEnabled(schedule, 5),
          5: hasWeekdayEnabled(schedule, 6),
          6: hasWeekdayEnabled(schedule, 7)
        };
      });
    }
  },
  methods: {
    ...mapPiniaActions(useDataDiscountsSchedule, ['getDiscountSchedule', 'updateDiscountScheduleCheckStatus']),
    handleCheck() {
      this.updateDiscountScheduleCheckStatus(
        this.schedule.map((s, i) => ({
          ...s,
          schedule:
            Object.values(this.schedules[i])
              .map(v => Number(v))
              // eslint-disable-next-line
              .reduce((prev, curr, j) => (prev += curr * Math.pow(2, j))) || 0
        }))
      );
    },
    handleCheckAll(checkAll) {
      this.updateDiscountScheduleCheckStatus(this.schedule.map(s => ({ ...s, schedule: checkAll ? 127 : 0 })));
    }
  },
  mounted() {
    this.getDiscountSchedule();
  }
};
</script>

<style lang="scss">
@import 'mixins.scss';

.discount-availability-header {
  display: flex;
  justify-content: space-evenly;
  margin-left: rem-calc(65);
}

.discount-availability-options {
  width: 100%;

  &-row {
    display: flex;
    justify-content: space-evenly;
  }
}

.discount-availability-side {
  width: rem-calc(75);
}

.discount-availability-body {
  display: flex;
}

.actions {
  .deselect-all {
    margin-left: rem-calc(10);
  }

  .select-all {
    @include breakpoint(large) {
      margin-left: rem-calc(325);
    }
  }
}
</style>
