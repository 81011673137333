<template>
  <vel-page :title="$t('resellersList.title')" class="resellers-page" :loading="loading">
    <vel-card>
      <resellers-list
        :data="resellers"
        :initialLength="selectTotal"
        :filterValue="filter"
        :filterCallback="filterCallback"
      />
    </vel-card>
  </vel-page>
</template>

<script>
import ResellersList from './ResellersList';
import VelCard from '@/components/card/VelCard';
import VelPage from '@/components/page/VelPage';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataResellers } from '@/stores/data/resellers.module';

export default {
  name: 'resellers-page',
  components: {
    ResellersList,
    VelCard,
    VelPage
  },
  methods: {
    ...mapPiniaActions(useDataResellers, ['getResellers', 'setFilter']),
    filterCallback(value) {
      this.setFilter(value);
    }
  },
  computed: {
    ...mapPiniaState(useDataResellers, ['loading', 'resellers', 'selectTotal']),
    ...mapPiniaState(useDataResellers, ['filter'])
  },
  async mounted() {
    await this.getResellers();
  },
  beforeDestroy() {
    this.setFilter('');
  }
};
</script>
