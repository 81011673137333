<template>
  <div>
    <b-modal
      v-model="showReactivate"
      :title="
        !employee.terminated
          ? $t('employee.deleteModal.employeeReactivate.title')
          : $t('employee.deleteModal.employeeDeactivate.title')
      "
      :static="true"
      :centered="true"
      :hide-header-close="true"
      :footer-bg-variant="'light'"
      :footer-text-variant="'dark'"
    >
      <b-container fluid>
        {{
          !employee.terminated
            ? $t('employee.deleteModal.employeeReactivate.body')
            : $t('employee.deleteModal.employeeDeactivate.body')
        }}
      </b-container>

      <template #modal-footer>
        <b-container fluid>
          <div class="w-100">
            <b-button
              :variant="!employee.terminated ? 'primary' : 'danger'"
              class="float-right ml-2"
              @click="
                showReactivate = false;
                showReactivateConfirmation = true;
              "
            >
              {{ !employee.terminated ? $t('employee.deleteModal.reactivate') : $t('employee.deleteModal.deactivate') }}
            </b-button>
            <b-button variant="link" class="float-right" @click="$emit('onCancel')">
              {{ $t('employee.deleteModal.cancel') }}
            </b-button>
          </div>
        </b-container>
      </template>
    </b-modal>
    <b-modal
      v-model="showReactivateConfirmation"
      :title="
        !employee.terminated
          ? $t('employee.deleteModal.employeeReactivate.title')
          : $t('employee.deleteModal.employeeDeactivate.title')
      "
      :static="true"
      :centered="true"
      hide-header-close="true"
      :footer-bg-variant="'light'"
      :footer-text-variant="'dark'"
    >
      <b-container fluid>
        <div style="border-bottom: 1px solid #ccc;" class="pb-5">
          <p>
            {{
              !employee.terminated
                ? $t('employee.deleteModal.employeeReactivate.body')
                : $t('employee.deleteModal.employeeDeactivate.body')
            }}
          </p>

          <div class="pl-2 mt-3 font-weight-bolder">{{ $t('employee.deleteModal.employeeReactivate.confirmed') }}</div>
        </div>
        <div>
          <h6 class="mt-4">
            <b>
              {{
                !employee.terminated
                  ? $t('employee.deleteModal.employeeReactivate.provideReason')
                  : $t('employee.deleteModal.employeeDeactivate.provideReason')
              }}
            </b>
          </h6>
          <b-textarea
            v-model.trim="reason"
            :placeholder="
              !employee.terminated
                ? $t('employee.deleteModal.employeeReactivate.provideReasonPlaceholder')
                : $t('employee.deleteModal.employeeDeactivate.provideReasonPlaceholder')
            "
          ></b-textarea>

          <div v-if="employee.terminated">
            <h6 class="mt-4">
              <b>
                {{ $t('employee.deleteModal.employeeDeactivate.rehirable') }}
              </b>
            </h6>
            <b-button-group>
              <b-button @click="employee.rehireable = true" :variant="employee.rehireable ? 'primary' : 'light'">
                {{ $t('defaults.confirmations.yes') }}
              </b-button>
              <b-button @click="employee.rehireable = false" :variant="!employee.rehireable ? 'primary' : 'light'">
                {{ $t('defaults.confirmations.no') }}
              </b-button>
            </b-button-group>
          </div>
        </div>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            :disabled="!reason || reason === ''"
            :variant="!reason || reason === '' ? 'secondary' : 'primary'"
            f
            class="float-right ml-2"
            @click="
              employee.terminatedReason = reason;
              showReactivateConfirmation = false;
              $emit('onSuccess');
            "
          >
            {{ $t('employee.deleteModal.save') }}
          </b-button>
          <b-button
            variant="link"
            f
            class="float-right"
            @click="
              showReactivateConfirmation = false;
              $emit('onCancel');
            "
          >
            {{ $t('employee.deleteModal.cancel') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'EmployeeReactivate',
  components: {},
  props: {
    employee: {
      type: Object,
      default: () => ({ terminatedReason: '' })
    }
  },

  data() {
    return {
      reason: '',
      showReactivate: true,
      showReactivateConfirmation: false
    };
  },
  computed: {},
  mounted() {
    this.employee.rehireable = true;
  }
};
</script>
