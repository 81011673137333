<template>
  <vel-form
    ref="userForm"
    class="user-form"
    :model="userForm"
    :rules="userFormRules"
    :submit="handleSubmit"
    label-position="left"
    :inline="false"
    label-width="200px"
  >
    <vel-input name="id" class="name-input" type="hidden" v-model="userForm.id"></vel-input>
    <vel-form-item prop="email" class="user-form-email" :label="$t('user.form.email.label')">
      <vel-input name="email" class="name-input" type="text" v-model="userForm.email"></vel-input>
    </vel-form-item>
    <vel-form-item prop="firstName" class="user-form-firstName" :label="$t('user.form.firstName.label')">
      <vel-input name="firstName" class="name-input" type="text" v-model="userForm.firstName"></vel-input>
    </vel-form-item>
    <vel-form-item prop="lastName" class="user-form-lastName" :label="$t('user.form.lastName.label')">
      <vel-input name="lastName" class="name-input" type="text" v-model="userForm.lastName"></vel-input>
    </vel-form-item>
    <vel-form-item class="user-form-phoneNumber" prop="phoneNumber" :label="$t('user.form.phoneNumber.label')">
      <vel-input name="phoneNumber" class="name-input" type="text" v-model="userForm.phoneNumber"></vel-input>
    </vel-form-item>
    <vel-form-item class="user-form-type" prop="type" :label="$t('user.form.type.label')">
      <vel-select v-model="userForm.type" @change="onTypeChange" :placeholder="$t('user.form.type.placeholder')">
        <vel-option :label="$t('user.propertyValue.type.0')" :value="0" :key="0"></vel-option>
        <vel-option :label="$t('user.propertyValue.type.128')" :value="128" :key="128"></vel-option>
        <vel-option :label="$t('user.propertyValue.type.256')" :value="256" :key="256"></vel-option>
      </vel-select>
    </vel-form-item>
    <vel-form-item class="reseller" prop="resellerId" :label="$t('user.form.resellerId.label')">
      <vel-select
        :disabled="userForm.type !== 128"
        v-model="userForm.resellerId"
        :placeholder="$t('user.form.resellerId.placeholder')"
      >
        <vel-option :label="$t('user.form.resellerNone')" :value="null"></vel-option>
        <vel-option
          v-for="reseller in resellers"
          :label="reseller.name"
          :value="reseller.id"
          :key="reseller.id"
        ></vel-option>
      </vel-select>
    </vel-form-item>
    <vel-form-item prop="enabled" class="user-form-enabled" :label="$t('user.form.enabled.label')">
      <vel-switch v-model="userForm.enabled" />
    </vel-form-item>
    <vel-form-item class="user-form__actions">
      <vel-button type="primary" class="actions__submit" :loading="pending" @click="handleSubmit()">
        {{ $t('defaults.actions.save') }}
      </vel-button>
    </vel-form-item>
  </vel-form>
</template>

<script>
import VelButton from '@/components/button/VelButton';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelInput from '@/components/input/VelInput';
import VelOption from '@/components/x-option/VelOption';
import VelSelect from '@/components/x-select/VelSelect';
import VelSwitch from '@/components/switch/VelSwitch';

export default {
  name: 'user-form',
  components: {
    VelButton,
    VelForm,
    VelFormItem,
    VelInput,
    VelOption,
    VelSelect,
    VelSwitch
  },
  props: {
    user: {
      type: Object,
      default: () => ({
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        enabled: null,
        phoneNumber: null,
        reseller: null,
        type: null
      })
    },
    resellers: {
      type: Array,
      default: () => []
    },
    pending: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userForm: {
        id: this.user.id,
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        enabled: this.user.enabled,
        phoneNumber: this.user.phoneNumber,
        type: this.user.type,
        resellerId: (this.user.reseller && this.user.reseller.id) || null
      }
    };
  },
  methods: {
    onTypeChange(type) {
      if (type !== 128) {
        this.userForm.resellerId = null;
      }
    },
    handleSubmit() {
      this.$refs.userForm.validate(valid => {
        if (valid) {
          this.$emit('submit', this.userForm);
        }
      });
    }
  },
  computed: {
    userFormRules() {
      return {
        email: [
          {
            required: true,
            message: this.$t('user.form.email.isRequired')
          }
        ],
        firstName: [
          {
            required: true,
            message: this.$t('user.form.firstName.isRequired')
          }
        ],
        lastName: [
          {
            required: true,
            message: this.$t('user.form.lastName.isRequired')
          }
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t('user.form.phoneNumber.isRequired')
          }
        ],
        enabled: [
          {
            required: true,
            message: this.$t('user.form.enabled.isRequired')
          }
        ],
        type: [
          {
            required: true,
            message: this.$t('user.form.type.isRequired')
          }
        ],
        resellerId: [
          {
            required: this.userForm.type === 128 || false,
            message: this.$t('user.form.resellerId.isRequired')
          }
        ],
        id: [
          {
            required: false,
            message: this.$t('user.form.enabled.isRequired')
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.user-form {
  /deep/ {
    .vel-input-group {
      padding-left: 0;
    }
  }
}

.user-form__actions {
  display: block;

  .actions__submit {
    margin-right: 1.25em;
  }
}

.name-input /deep/ input:disabled {
  background-color: #fffce0;
}
</style>
