<template>
  <indicator-quantity-amount
    :loading="loading"
    :previousAmount="previousAmount"
    :quantity="quantity"
    :amount="amount"
    :percent="percent"
    :currency="currency"
  >
    <template v-slot:title>
      {{ $t('dashboard.refunds.title') }}
    </template>
    <template v-slot:value>
      <vel-amount :currency="currency" :amount="amount" />
    </template>
  </indicator-quantity-amount>
</template>

<script>
import IndicatorQuantityAmount from '@/components/indicator/IndicatorQuantityAmount.vue';
import VelAmount from '@/components/amount/VelAmount';

export default {
  name: 'refunds',
  components: {
    IndicatorQuantityAmount,
    VelAmount
  },
  props: {
    amount: {
      default: 0
    },
    quantity: {
      default: 0
    },
    percent: {
      default: 0
    },
    previousAmount: {
      default: 0
    },
    currency: {
      default: ''
    },
    loading: {
      default: true
    }
  }
};
</script>
