<template>
  <div>
    <pie-line-chart
      :pending="isPending"
      :options="chartOptions"
      :series="getSeries"
      :show-table="true"
      :labels="getLabels"
      :currency="currencyId"
      :title="$t('sales.volumePerTenderTypes.title', { limit })"
      :type="get.default_chart_sales_per_order_mode.value"
      class="hourly-sales__chart"
    />
  </div>
</template>

<script>
import PieLineChart from '@/components/pie-line-chart/PieLineChart';
import { useDataSalesTenderTypes } from '@/stores/data/sales/tender-types.module';
import { storeToRefs } from 'pinia';
import { mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataConfig } from '@/stores/data/config.module';

export default {
  name: 'volume-per-tender-types__chart',
  components: {
    PieLineChart
  },
  props: {
    currencyId: {
      type: String,
      default: null
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  setup() {
    const tenderTypesSalesStore = useDataSalesTenderTypes();
    const { isPending } = storeToRefs(tenderTypesSalesStore);

    return { isPending, tenderTypesSalesStore };
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),
    getSeries() {
      return [
        {
          name: this.$t('sales.volumePerTenderTypes.series.title', { currency: this.currencyId }),
          data: this.tenderTypesSalesStore.getSeries(this.currencyId).slice(0, this.limit)
        }
      ];
    },
    getLabels() {
      return this.tenderTypesSalesStore.getLabels(this.currencyId).slice(0, this.limit);
    },
    getTotal() {
      return this.tenderTypesSalesStore.getTotal(this.currencyId);
    },
    chartOptions() {
      return {
        xaxis: {
          title: {
            text: this.$t('sales.volumePerTenderTypes.xaxis')
          }
        },
        yaxis: {
          title: {
            text: this.$t('sales.volumePerTenderTypes.yaxis')
          }
        }
      };
    }
  },
  methods: {},
  mounted() {
    this.tenderTypesSalesStore.getSalesByTenderTypes();
  }
};
</script>
