<template>
  <vel-page class="page__settings-page">
    <template v-slot:title>
      <div>{{ $t('pages.globalSettings') }}</div>
    </template>
    <vel-icon v-if="isMediumDown" class="menu" name="hamburger" size="medium" @click="isCollapse = !isCollapse" />
    <vel-row :gutter="20">
      <vel-col v-show="isCollapse || isMediumUp" :span="8">
        <vel-tree
          :data="tree"
          :props="defaultProps"
          @node-click="handleNodeClick"
          ref="tree"
          node-key="id"
          :default-expand-all="true"
          :expand-on-click-node="false"
          :default-expanded-keys="defaultExpandedKeys"
        ></vel-tree>
      </vel-col>
      <vel-col class="content-wrapper" :span="16">
        <div class="arian">
          <span v-for="link in getSelectedPath()" :key="link">{{ $t('settings.menu.' + link) }}</span>
        </div>

        <div class="content">
          <div v-if="parents[selectedNodeId]">
            <div class="group">
              <div v-for="e in parents[selectedNodeId]" :key="e.id" @click="setActivePath(e.id)">
                {{ $t('settings.menu.' + e.id) }}
              </div>
            </div>
          </div>

          <div v-for="opt in elements" :key="opt.title">
            <vel-divider content-position="left">{{ opt.title }}</vel-divider>
            <template v-for="el in opt.childs">
              <template v-if="el.kind === 'select'">
                <small class="label" :key="el.id">{{ el.label }}</small>
                <vel-select
                  v-model="config[el.id].value"
                  :key="'select' + el.id"
                  @change="update"
                  style="min-width: 320px;"
                >
                  <vel-option
                    v-for="opt in el.options"
                    :label="$t('editors.options.' + opt.key, { date: getDateByFormat(opt.value) })"
                    v-bind:key="opt.key"
                    :value="opt.value"
                  >
                    <vel-icon v-if="opt.icon" class="option_icon" :name="opt.icon"></vel-icon>
                    {{ $t('editors.options.' + opt.key, { date: getDateByFormat(opt.value) }) }}
                  </vel-option>
                </vel-select>
              </template>
              <vel-checkbox
                v-else
                :key="el.id"
                v-model="config[el.id].value"
                :label="el.label"
                @change="update"
              ></vel-checkbox>
            </template>
          </div>
        </div>
      </vel-col>
    </vel-row>
  </vel-page>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelCheckbox from '@/components/checkbox/VelCheckbox';
import VelCol from '@/components/x-layout/VelCol';
import VelDivider from '@/components/divider/VelDivider';
import VelIcon from '@/components/icon/VelIcon';
import VelOption from '@/components/x-option/VelOption';
import VelPage from '@/components/page/VelPage';
import VelRow from '@/components/row/VelRow';
import VelSelect from '@/components/x-select/VelSelect';
import VelTree from '@/components/tree/VelTree';
import Vue from 'vue';
import elements from '@/configs/elements.yaml';
import { environment } from '@/configs';
import menu from '@/configs/menu.yaml';
import { DateTime } from 'luxon';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataConfig } from '@/stores/data/config.module';

export default {
  name: 'SettingsPage',
  components: {
    VelTree,
    VelRow,
    VelCol,
    VelCheckbox,
    VelDivider,
    VelIcon,
    VelPage,
    VelOption,
    VelSelect
  },
  mixins: [DeviceMixin],
  data() {
    return {
      isCollapse: true,
      selectedNodeId: '',
      defaultExpandedKeys: [],
      parents: {},
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),
    config: {
      get() {
        return this.get;
      },
      set() {
        return true;
      }
    },
    elements() {
      const els = Object.keys(elements).filter(k => elements[k].menu === this.selectedNodeId);
      const elementList = {};
      els.forEach(e => {
        if (!elementList[elements[e].group]) {
          elementList[elements[e].group] = { title: this.$t(`editors.groups.${elements[e].group}`), childs: [] };
        }
        if (environment[e] !== false) {
          elementList[elements[e].group].childs.push({
            ...elements[e],
            id: e,
            label: this.$t(`editors.elements.${e}`, this.get)
          });
        }
      });
      return Object.values(elementList);
    },
    tree() {
      return this.list_to_tree(
        Object.keys(menu)
          .filter(k => k !== 'appearance_dashboard')
          .map(k => ({ ...menu[k], id: k }))
          .map(d => ({ ...d, label: this.$t(`settings.menu.${d.id}`) }))
      );
    }
  },
  methods: {
    ...mapPiniaActions(useDataConfig, ['patchConfig']),
    update() {
      this.patchConfig({ config: this.config });
    },
    getDateByFormat(format) {
      return DateTime.now().toFormat(format);
    },
    getSelectedPath() {
      const paths = [];
      paths.push(this.selectedNodeId);

      let currentPath = this.selectedNodeId;

      while (menu[currentPath]) {
        paths.push(menu[currentPath].parentId);
        currentPath = menu[currentPath].parentId;
      }
      paths.pop();

      return paths.reverse();
    },
    handleNodeClick(data) {
      this.selectedNodeId = data.id;
      this.isCollapse = false;
    },
    list_to_tree(list) {
      const map = {},
        roots = [];
      let node = null,
        i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        list[i].children = [];
      }
      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId !== 'root' && list[map[node.parentId]]) {
          list[map[node.parentId]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
    setActivePath(noeKey) {
      this.$refs.tree.setCurrentKey((this.selectedNodeId = noeKey));
      this.defaultExpandedKeys = ['appearance'];
      // this.$refs.tree.expand(null, noeKey)
    }
  },
  mounted() {
    Object.keys(menu).forEach(i => {
      if (!this.parents[menu[i].parentId]) {
        this.parents[menu[i].parentId] = [];
      }
      this.parents[menu[i].parentId].push({ ...menu[i], id: i });
    });
    Vue.nextTick(() => {
      this.setActivePath('appearance_locations');
    });
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

.page__settings-page {
  height: 100%;
  width: 100%;

  .menu {
    margin: 1rem 0 0 1rem;
  }

  .vel-page__content {
    padding: 0;
  }

  .el-col-8 {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    width: auto;
    z-index: 100;
  }

  .el-col-16 {
    width: 100%;
  }

  @include breakpoint(medium) {
    .arian {
      padding-left: 0;
    }

    .el-col-8 {
      position: static;
      width: 33.3333%;
    }

    .el-col-16 {
      width: 66.6666%;
    }
  }

  .el-row,
  .el-col,
  .el-tree {
    height: 100%;
  }

  .el-tree-node {
    .is-current {
      > .el-tree-node__content {
        background-color: $iris-blue;
        color: #fff;
      }

      .el-tree-node__children {
        background-color: #fff;
      }
    }
  }

  .arian {
    margin-bottom: 2rem;
    padding-left: 1rem;

    span {
      font-weight: bold;

      &:not(:last-child)::after {
        content: ' > ';
        display: inline-block;
        padding: 0 5px;
      }
    }
  }

  .el-tree {
    overflow: auto;
  }

  .content-wrapper,
  .el-tree {
    padding-top: 1rem;
  }

  .content {
    margin-left: 10px;
  }

  .content div div:first-child {
    .el-divider {
      margin-top: 12px;
    }
  }

  .el-divider__text {
    background-color: $wild-sand;
    padding: 0 10px 0 0;

    &.is-left {
      left: 0;
    }
  }

  .el-checkbox {
    display: block;
  }

  .group {
    div {
      color: #409eff;
      cursor: pointer;
    }
  }

  small {
    display: block;
    font-size: 0.8rem;
  }
}

.option_icon {
  padding: 4px 0;
  position: relative;
  top: 5px;
}

.label {
  margin-top: 1rem;
}
</style>
