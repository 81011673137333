<template>
  <vel-page :loading="loading" class="division-page">
    <template v-slot:title>{{ $t('divisionInfo.title') }}</template>
    <vel-card>
      <division-form @submit="handleSubmit" :division="selectSelected" :availableBigDivisions="getAllBigDivisions" />
    </vel-card>
  </vel-page>
</template>

<script>
import DivisionForm from './DivisionForm';
import VelCard from '@/components/card/VelCard';
import VelPage from '@/components/page/VelPage';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataBigDivisions } from '@/stores/data/big-divisions.module';
import { useDataDivisionsEdit } from '@/stores/data/divisions-edit.module';
import { useDataDivisions } from '@/stores/data/divisions.module';

export default {
  name: 'division-page',

  components: {
    DivisionForm,
    VelCard,
    VelPage
  },
  methods: {
    ...mapPiniaActions(useDataDivisionsEdit, ['clearMessage']),
    ...mapPiniaActions(useDataDivisionsEdit, ['updateDivision']),
    ...mapPiniaActions(useDataDivisions, ['getDivisionById']),
    handleSubmit(divisionEntity) {
      this.updateDivision(divisionEntity);
    }
  },
  computed: {
    ...mapPiniaState(useDataBigDivisions, ['getAllBigDivisions']),
    ...mapPiniaState(useDataDivisions, ['selectSelected', 'loading']),
    ...mapPiniaState(useDataDivisionsEdit, ['selectTotal']),
    showAlert() {
      return !!this.selectTotal;
    }
  },
  beforeDestroy() {
    this.clearMessage();
  },
  async mounted() {
    await this.getDivisionById();
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';

.success {
  margin-bottom: rem-calc(50);
}
</style>
