import { defineStore } from 'pinia';

export const useUIAlertStore = defineStore('alert', {
  state: () => ({
    error: null
  }),
  actions: {
    newError(error) {
      this.error = error;
    }
  }
});
