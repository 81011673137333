<template>
  <div>
    <b-row class="mt-4">
      <b-col cols="12" md="4" class="d-flex align-items-center mb-2 mb-md-0 pb-4">
        {{ $t('mealPlan.description.label') }}
      </b-col>
      <b-col>
        <b-form-input
          id="name"
          :state="enableSaveChanges"
          :placeholder="$t('mealPlan.description.placeholder')"
          class="required"
          :class="{ required: !form.name || form.name === '' }"
          v-model="form.name"
          aria-describedby="passwd-feedback"
        ></b-form-input>
        <b-form-text id="input-live-help">{{ $t('employeeCreate.required') }}</b-form-text>
        <b-form-invalid-feedback id="passwd-feedback">
          {{ $t('employeeCreate.required') }}
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <b-form-checkbox v-model="form.excludeBenefit" name="flavour-4a" inline>
          {{ $t('mealPlan.excludeBenefit.label') }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <h6>
      {{ $t('mealPlan.eligibility.title') }} (
      <span class="font-weight-light">Max. 5</span>
      )
    </h6>
    <b-row class="px-3 py-1">
      <b-col cols="4" class="pr-0">
        <div style="border: 1px solid #dfdfdf; border-radius: 3px; font-size: 0.8rem;" class="text-center p-1">
          {{ $t('mealPlan.eligibility.duration.label') }}
        </div>
      </b-col>
      <b-col cols="4">
        <div style="border: 1px solid #dfdfdf; border-radius: 3px; font-size: 0.8rem;" class="text-center p-1">
          {{ $t('mealPlan.eligibility.amount.label') }}
        </div>
      </b-col>
    </b-row>
    <b-row class="px-3 py-1" v-for="(eligibility, index) in form.eligibility" :key="index">
      <b-col cols="4" class="pr-0">
        <b-form-input
          id="name"
          :placeholder="$t('mealPlan.eligibility.duration.placeholder')"
          v-model="form.eligibility[index].duration"
          number
        ></b-form-input>
      </b-col>
      <b-col cols="4">
        <b-input-group prepend="$" :class="{ active: form.eligibility[index].amount }">
          <b-form-input
            id="name"
            :placeholder="$t('mealPlan.eligibility.amount.placeholder')"
            v-model="form.eligibility[index].amount"
            number
            :formatter="formatAmount"
            lazy-formatter
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="4" class="pl-0 d-flex align-items-center">
        <b-icon
          v-if="index > 0"
          icon="x-circle"
          font-scale="1.5"
          class="rounded mr-2"
          variant="danger"
          style="cursor: pointer;"
          @click="removeEligibility(index)"
        ></b-icon>
        <b-icon
          v-if="index < 4 && index == form.eligibility.length - 1"
          icon="plus-circle"
          font-scale="1.5"
          class="rounded"
          variant="primary"
          style="cursor: pointer;"
          @click="addEligibility"
        ></b-icon>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

export default {
  name: 'meal-plan-form',
  model: {},
  props: {
    licenceNumber: {
      type: Number,
      default: null
    },
    mealPlan: {
      type: Object,
      default: () => ({
        name: '',
        excludeBenefit: false,
        licenceNumber: 0,
        eligibility: [
          {
            duration: null,
            amount: null
          }
        ]
      })
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        name: '',
        excludeBenefit: false,
        licenceNumber: 0,
        eligibility: [
          {
            duration: null,
            amount: null
          }
        ]
      }
    };
  },
  watch: {
    mealPlan(value) {
      this.form = cloneDeep({ ...this.form, ...(value || {}) });
    }
  },
  computed: {
    enableSaveChanges() {
      if (!this.mealPlan) {
        return this.form.name === '' ? null : true;
      }
      return isEqual(this.mealPlan, this.form) ? null : this.form.name === '' ? null : true;
    }
  },
  methods: {
    reset(value = null) {
      this.form = cloneDeep(
        value || {
          name: '',
          excludeBenefit: false,
          licenceNumber: 0,
          eligibility: [
            {
              duration: null,
              amount: null
            }
          ]
        }
      );
    },
    formatAmount(value) {
      return isNaN(value) ? value : Number(value).toFixed(2);
    },
    addEligibility() {
      this.form.eligibility.push({
        duration: null,
        amount: null
      });
    },
    removeEligibility(index) {
      this.form.eligibility.splice(index);
    }
  }
};
</script>
<style land="scss" scoped>
/deep/ .input-group-text {
  background-color: #c9cacf;
  color: white;
}

.active .input-group-text {
  background-color: black;
  color: white;
}
</style>
