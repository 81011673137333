<template>
  <vel-page class="sales-items-page" :loading="loading">
    <template v-slot:title>
      {{ $t('salesItemsPage.title') }}
    </template>
    <div class="sales-items-page-location-selector-wrapper">
      <vel-location-selector
        class="sales-items-page-location-selector"
        :allLocationsLabel="$t('dashboard.locationSelector.allLocationsText')"
        :locationId="locationId"
        :locationsOptions="locations"
        :disabled="getLocationsIsLoading"
        @change="onLocationChange"
      />
    </div>
    <vel-card>
      <sales-items-list
        :data="getAllItemsFiltered"
        :initialLength="selectTotal"
        :filterValue="filter"
        :filterCallback="filterCallback"
      />
    </vel-card>
  </vel-page>
</template>

<script>
import SalesItemsList from './components/SalesItemsList';
import VelCard from '@/components/card/VelCard';
import VelLocationSelector from '@/components/location-selector/VelLocationSelector';
import VelPage from '@/components/page/VelPage';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataBigDivisions } from '@/stores/data/big-divisions.module';
import { useDataItems } from '@/stores/data/items.module';

export default {
  name: 'sales-items-page',
  components: { SalesItemsList, VelPage, VelCard, VelLocationSelector },
  methods: {
    ...mapPiniaActions(useDataItems, ['getItems', 'setFilter']),
    ...mapPiniaActions(useUILocationSelector, ['onChange']),
    onLocationChange(locationId) {
      this.onChange({ locationId });
    },
    filterCallback(value) {
      this.setFilter(value);
    }
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, ['locations', 'getLocationsIsLoading']),
    ...mapPiniaState(useDataItems, ['getAllItemsFiltered', 'getFilter', 'selectTotal', 'loading']),
    ...mapPiniaState(useUILocationSelector, {
      locationId: 'getLocationSelectorSelected'
    }),
    ...mapPiniaState(useDataBigDivisions, ['filter']),
    test() {
      return true;
    }
  },
  async mounted() {
    await this.getItems();
  },
  destroyed() {
    this.setFilter('');
  }
};
</script>

<style lang="scss">
@import 'mixins.scss';

.sales-items-page {
  &-location-selector-wrapper {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
  }

  &-location-selector {
    margin-bottom: rem-calc(20);
    width: rem-calc(300);
  }
}
</style>
