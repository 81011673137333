`
<template>
  <div style="background: #fff; padding: 24px 0 0 0;" class="w-100 d-flex">
    <b-row v-show="!alwaysActive" class="activation-row mx-0">
      <b-col>
        <b-form-group :label="index === 0 && $t('createMixMatch.activation.startDate')" label-align="top">
          <b-input-group>
            <b-form-input
              id="example-input"
              type="text"
              :placeholder="$t('employeeCreate.datePlaceholder')"
              v-mask="'99/99/9999'"
              :value="startDate"
              autocomplete="off"
              @change="setStartDate"
            ></b-form-input>
            <b-input-group-append style="border: 1px solid #ced4da;">
              <b-form-datepicker
                button-only
                :locale="'fr'"
                right
                button-variant="white"
                @input="formData.endDate = formData.startDate"
                @context="onSelectStartDate"
                :label-no-date-selected="$t('datePicker.noDate')"
                :label-calendar="$t('datePicker.calendar')"
                :label-close-button="$t('datePicker.close')"
                :label-current-month="$t('datePicker.currentMonth')"
                :label-help="$t('datePicker.help')"
                :label-nav="$t('datePicker.nav')"
                :label-next-decade="$t('datePicker.nextDecade')"
                :label-next-month="$t('datePicker.nextMonth')"
                :label-next-year="$t('datePicker.nextYear')"
                :label-prev-decade="$t('datePicker.previousDecade')"
                :label-prev-month="$t('datePicker.previousMonth')"
                :label-prev-year="$t('datePicker.previousYear')"
                :label-reset-button="$t('datePicker.reset')"
                :label-today="$t('datePicker.today')"
                :label-today-button="$t('datePicker.selectToday')"
                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                aria-controls="example-input"
                v-model="formData.startDate"
              >
                <template #button-content>
                  <font-awesome-icon icon="calendar-alt" />
                </template>
              </b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :label="index === 0 && $t('createMixMatch.activation.endDate')">
          <b-input-group>
            <b-form-input
              id="example-input"
              type="text"
              :placeholder="$t('employeeCreate.datePlaceholder')"
              v-mask="'99/99/9999'"
              :value="endDate"
              @change="setEndDate"
              autocomplete="off"
            ></b-form-input>
            <b-input-group-append style="border: 1px solid #ced4da;">
              <b-form-datepicker
                button-only
                :locale="'fr'"
                right
                button-variant="white"
                :label-no-date-selected="$t('datePicker.noDate')"
                :label-calendar="$t('datePicker.calendar')"
                :label-close-button="$t('datePicker.close')"
                :label-current-month="$t('datePicker.currentMonth')"
                :label-help="$t('datePicker.help')"
                :label-nav="$t('datePicker.nav')"
                :label-next-decade="$t('datePicker.nextDecade')"
                :label-next-month="$t('datePicker.nextMonth')"
                :label-next-year="$t('datePicker.nextYear')"
                :label-prev-decade="$t('datePicker.previousDecade')"
                :label-prev-month="$t('datePicker.previousMonth')"
                :label-prev-year="$t('datePicker.previousYear')"
                :label-reset-button="$t('datePicker.reset')"
                :label-today="$t('datePicker.today')"
                :label-today-button="$t('datePicker.selectToday')"
                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                aria-controls="example-input"
                v-model="formData.endDate"
                :min="new Date(new Date(formData.startDate).setDate(new Date(formData.startDate).getDate() + 1))"
                @context="onSelectEndDate"
              >
                <template #button-content>
                  <font-awesome-icon icon="calendar-alt" />
                </template>
              </b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :label="index === 0 && $t('createMixMatch.activation.weekDay')">
          <b-form-select
            v-model="formData.weekday"
            :options="availableWeekDays"
            value-field="value"
            text-field="day"
            aria-describedby="input-live-help input-live-feedback"
          >
            <template #first>
              <b-form-select-option disabled :value="''">
                {{ $t('createMixMatch.activation.weekDays.placeholder') }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :label="index === 0 && $t('createMixMatch.activation.startTime')">
          <b-form-select v-model="formData.startTime" :options="availableHours" value-field="value" text-field="time">
            <template #first>
              <b-form-select-option :value="''" disabled>HH:MM</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :label="index === 0 && $t('createMixMatch.activation.endTime')">
          <b-form-select v-model="formData.endTime" :options="availableHours" value-field="value" text-field="time">
            <template #first>
              <b-form-select-option :value="''" disabled>HH:MM</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-col cols="1" class="d-flex align-items-center">
      <b-icon
        v-if="showDeleteButton && !alwaysActive"
        icon="x-circle"
        font-scale="1.5"
        class="rounded mr-2"
        variant="danger"
        style="cursor: pointer;"
        @click="removeActivation"
      ></b-icon>
      <b-icon
        v-if="!alwaysActive && activations.length < 5"
        icon="plus-circle"
        font-scale="1.5"
        class="rounded"
        variant="primary"
        style="cursor: pointer;"
        @click="addActivation"
      ></b-icon>
    </b-col>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'MixMatchActivationForm',
  props: {
    activation: {
      type: Object,
      default: null
    },
    alwaysActive: {
      type: Boolean,
      default: false
    },
    availableHours: {
      type: Array,
      default: () => []
    },
    activations: {
      type: Array,
      default: () => []
    },
    availableWeekDays: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: 0
    },
    showLabels: {
      type: Boolean,
      default: false
    },
    showDeleteButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      formData: {
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        weekday: ''
      }
    };
  },
  methods: {
    setStartDate(val) {
      if (val.length === 10) {
        this.formData.startDate = DateTime.fromFormat(val, 'D').toISO();
        if (!this.activation.endDate) {
          this.formData.endDate = DateTime.fromFormat(val, 'D').toISO();
        }
      } else {
        this.formData.startDate = null;
      }
    },
    onSelectStartDate(ctx) {
      this.startDate = ctx.selectedFormatted;
    },
    setEndDate(val) {
      if (val.length === 10 && new Date(val) >= new Date(this.startDate)) {
        this.formData.endDate = DateTime.fromFormat(val, 'D').toISO();
      } else {
        this.formData.endDate = null;
      }
    },
    onSelectEndDate(ctx) {
      this.endDate = ctx.selectedFormatted;
    },
    addActivation() {
      this.$emit('addActivation');
    },
    removeActivation() {
      this.$emit('removeActivation');
    }
  },
  watch: {
    activation(newVal) {
      if (newVal) {
        this.formData = {
          startTime: newVal.startTime !== '' ? newVal.startTime : this.formData.startTime,
          endTime: newVal.endTime !== '' ? newVal.endTime : this.formData.endTime,
          weekday: newVal.weekday !== '' ? newVal.weekday : this.formData.weekday,
          startDate: newVal.startDate || this.formData.startDate || null,
          endDate: newVal.endDate || this.formData.endDate || null
        };
      }
    }
  },
  mounted() {
    this.formData = this.activation;
  }
};
</script>
