export default {
  computed: {
    isMediumDown() {
      return ['small', 'medium'].includes(this.$mq);
    },
    isMediumUp() {
      return ['large', 'xlarge', 'xxlarge'].includes(this.$mq);
    },
    isLargeUp() {
      return ['xlarge', 'xxlarge'].includes(this.$mq);
    },
    isXLargeUp() {
      return ['xxlarge'].includes(this.$mq);
    },
    isSmallOnly() {
      return this.$mq === 'small';
    },
    isMediumOnly() {
      return this.$mq === 'medium';
    },
    isLargeOnly() {
      return this.$mq === 'large';
    },
    isExtraLargeOnly() {
      return this.$mq === 'xlarge';
    },
    isDoubleExtraLargeOnly() {
      return this.$mq === 'xxlarge';
    }
  }
};
