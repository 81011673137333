<template>
  <div class="public-reset-password-page">
    <div class="public-reset-password-content__wrapper">
      <public-reset-password-form />
      <div class="public-reset-password-footer">
        <vel-button type="link" @click="toggleLanguage">
          <span>
            <vel-icon name="globe-solid" size="small" />
            {{ $t('resetPassword.switchLanguage') }}
          </span>
        </vel-button>
        <h6>{{ $t('rightsReserved', { currentYear }) }}</h6>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageMixin from '@/mixins/language-mixin';
import PublicResetPasswordForm from './components/PublicResetPasswordForm';
import VelButton from '@/components/button/VelButton';
import VelIcon from '@/components/icon/VelIcon';

export default {
  name: 'PublicResetPasswordPage',
  mixins: [LanguageMixin],
  components: {
    VelButton,
    VelIcon,
    PublicResetPasswordForm
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.public-reset-password-page {
  @include breakpoint(large) {
    background-attachment: fixed;
    background-image: url('./login-backgroung.jpg');
    background-position: right 0% bottom 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
  }

  .public-reset-password-content__wrapper {
    align-items: center;
    background-color: $wild-sand;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    @include breakpoint(large) {
      opacity: 0.96;
      width: 40%;
    }

    .public-reset-password-footer {
      margin-bottom: rem-calc(10);
      text-align: center;
    }

    .vel-button {
      font-size: rem-calc(13);
      height: rem-calc(25);
      width: 100%;
      @include noPrint;

      &:hover {
        background-color: $wild-sand;
      }
    }

    .vel-icon_small {
      height: rem-calc(10);
      width: rem-calc(10);
    }

    h6 {
      padding-top: rem-calc(100);
      @include breakpoint(xsmall) {
        margin: 0;
        padding-top: 0;
      }
    }

    @mixin breakpoint {
      justify-content: flex-start;
    }

    @media screen and (max-width: map-get($breakpoints, 'medium')) {
      @include breakpoint;
    }

    @media (min-width: map-get($breakpoints, 'medium')) and (max-width: map-get($breakpoints, 'large')) {
      @include breakpoint;
    }
  }
}
</style>
