import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import salesService from '@/services/sales.service';
import { useDataSalesIndicators } from '@/stores/data/sales/indicators.module';
import discountsModuleHelper from '@/stores/discounts.module.helper';
import groupsService from '@/services/groups.service';
import itemsService from '@/services/items.service';
import { addMany, createCollectionState, getSelectors, removeAll, setAll } from '@/helpers/stores.helpers';

const { selectAll, selectTotal } = getSelectors();

export const useDataItemsSchedulesItem = defineStore('itemsSchedulesItem', {
  state: () =>
    createCollectionState({
      loading: false,
      error: null
    }),
  getters: {
    hasError: state => !!state.error,
    total: selectTotal,
    schedules: selectAll
  },
  actions: {
    getSchedulesItem(id) {
      this.getSchedulesItemRequest();

      itemsService.getItemByIdSchedule(getToken(), { id }).then(
        locations => {
          this.getSchedulesItemSuccess(locations);
        },
        error => {
          this.getSchedulesItemFailure(error);
        }
      );
    },
    async updateItemScheduleById({ itemId, schedules }) {
      try {
        this.updateSchedulesItemRequest();
        const items = await itemsService.updateItemByIdSchedule(getToken(), itemId, schedules);
        this.updateSchedulesItemSuccess(items);
      } catch (error) {
        this.updateSchedulesItemFailure(error);
      }
    },
    getSchedulesItemRequest() {
      this.loading = true;
      this.error = null;
      this.data = removeAll(this.data);
    },
    getSchedulesItemSuccess(schedules) {
      this.loading = false;
      this.data = setAll(
        schedules.map(s => ({ ...s, modeId: s.mode.id })),
        this.data,
        'modeId'
      );
    },
    getSchedulesItemFailure(error) {
      this.loading = false;
      this.error = error;
    },
    updateSchedulesItemRequest() {
      this.loading = true;
      this.error = null;
    },
    updateSchedulesItemSuccess(schedules) {
      this.loading = false;
      this.data = setAll(
        schedules.map(s => ({ ...s, modeId: s.mode.id })),
        this.data,
        'modeId'
      );
    },
    updateSchedulesItemFailure(error) {
      this.loading = false;
      this.error = error;
    },
    selectAll(value) {
      this.loading = false;
      this.data = setAll(
        selectAll(this.state).map(s => ({ ...s, schedule: (value && 127) || 0 })),
        this.data,
        'modeId'
      );
    }
  }
});
