<template>
  <vel-page class="reseller-page" :loading="loading">
    <template v-slot:title>
      {{ getName || '' }}
    </template>
    <vel-card>
      <reseller-locations-list :resellerLocations="selectAll" :selectTotal="selectTotal" />
    </vel-card>
  </vel-page>
</template>
<script>
import ResellerLocationsList from './ResellerLocationsList';
import VelCard from '@/components/card/VelCard';
import VelPage from '@/components/page/VelPage';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataResellers } from '@/stores/data/resellers.module';

export default {
  name: 'ResellerPage',
  components: {
    ResellerLocationsList,
    VelCard,
    VelPage
  },
  computed: {
    ...mapPiniaState(useDataResellers, ['selectAll', 'selectTotal', 'loading']),
    getName() {
      return this.selectAll[0] && this.selectAll[0].reseller.name;
    }
  },
  methods: {
    ...mapPiniaActions(useDataResellers, ['getLocationsByResellerID'])
  },
  async mounted() {
    await this.getLocationsByResellerID();
  }
};
</script>
