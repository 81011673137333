<script>
import { Select } from 'element-ui';

export default {
  name: 'vel-select',
  extends: Select
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

$select-height: rem-calc(38);
$select-font-color: rgba(black, 0.6);
$select-font-color_disabled: $select-font-color;
$select-border-color: $link-water;
$select-border-color_focus: $primary-color;
$select-shadow-color_focus: rgba($maya-blue, 0.5);
$select-background-color: white;
$select-background-color_disabled: $athens-gray;
$select-tag-background-color: $wild-sand;
$select-tag-color: $iris-blue;
$select-tag-close-background-color: $ebony-clay;
$select-tag-close-color: white;
$select-dropdown-item-selected-color: $primary-color;
$select-dropdown-item-hover-background-color: $primary-color;
$select-dropdown-item-hover-color: white;

/deep/ .el-input__inner {
  -webkit-appearance: none;
  background-color: $select-background-color;
  border: 1px solid $select-border-color;
  border-radius: 4px;
  font-size: $input-font-size;
  height: $select-height;
  outline: none;
  width: 100%;
  @include animate;

  @include breakpoint(medium) {
    font-size: unset;
  }

  &:focus {
    font-size: $input-font-size;

    @include breakpoint(medium) {
      font-size: unset;
    }
  }
}

/deep/ .el-input.is-focus {
  .el-input__inner {
    border: 1px solid $select-border-color_focus;
    box-shadow: 0 0 0 3px $select-shadow-color_focus;
    @include animate;
  }
}

:root .el-select-dropdown {
  border-radius: 4px;

  /* stylelint-disable */
  /deep/ .el-select-dropdown__item {
    color: black;

    &.selected {
      color: $select-dropdown-item-selected-color;
    }

    &:hover,
    &.hover,
    &.selected.hover {
      background-color: $select-dropdown-item-hover-background-color;
      color: $select-dropdown-item-hover-color;
    }
  }
  /* stylelint-enable */
}

.el-select {
  .el-tag {
    background-color: $select-tag-background-color;

    .el-select__tags-text {
      color: $select-tag-color;
    }
  }

  /* stylelint-disable */
  /deep/ .el-icon-close {
    background-color: $select-tag-close-background-color !important;
    border: 1px solid white;

    &:hover {
      background-color: $select-tag-close-background-color !important;
      color: black;
    }
  }
  /* stylelint-enable */
}
</style>
