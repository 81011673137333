<template>
  <label class="vel-label" :class="classes" :for="name" v-text="text" />
</template>

<script>
export default {
  name: 'vel-label',
  props: {
    name: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      const { name } = this.$options;
      return [
        {
          [`${name}_required`]: this.required
        }
      ];
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

.vel-label {
  color: $fjord;
  font-weight: $font-semi-bold;
  margin-bottom: rem-calc(8);
  width: 100%;

  &_required::after {
    color: red;
    content: '*';
    margin-left: 5px;
  }
}
</style>
