<template>
  <div class="app">
    <router-view v-if="appStore.isReady" />
    <portal-target name="app" />
    <div class="version-notification" v-if="hasNewVersion">
      <div style="text-align: center;">
        {{ $t('app.version.message') }}
        <vel-button @click="refresh()">{{ $t('app.version.button') }}</vel-button>
      </div>
    </div>
    <!--
    <div class="maintenance">
      <h2>Maintenance en cours / Maintenance in progress</h2>

      <pre style="margin-top: 2rem;">
Chers marchands,

POS Veloce est en cours de maintenance. Nous nous attendons à être de retour dans quelques heures. Merci pour votre patience.
      </pre>
      <div style="width: 100%; height: 1px; border-bottom: 1px dotted #cccccc; margin-top: 2rem;"></div>

      <pre style="margin-top: 2rem;">
Dear merchants,

Veloce is currently down for maintenance. We expect to be back in a couple hours. Thanks for your patience.
      </pre>

    </div>-->
  </div>
</template>

<script>
import BusMixin from '@/mixins/bus-mixin';
import LanguageMixin from '@/mixins/language-mixin';
import RefreshTokenMixin from '@/mixins/refresh-token-mixin';
import VelButton from '@/components/button/VelButton';
import { mapStores } from 'pinia/dist/pinia';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useUIAppStore } from '@/stores/ui/app.module';
import { useUserSettingsStore } from '@/stores/data/users/settings.store';
import {useLocalStorage} from "@vueuse/core";
import router from '@/router';

export default {
  name: 'app',
  components: { VelButton },
  mixins: [BusMixin, LanguageMixin, RefreshTokenMixin],
  setup() {
    const userSetingStore = useUserSettingsStore();
    userSetingStore.hydrate();
  },
  data() {
    return {
      hasNewVersion: false,
      lastRouteName: '',
      isNext: useLocalStorage('isNext', false)
    };
  },
  computed: {
    ...mapStores(useUIAppStore, useDataLocationsStore)
  },
  methods: {
    refresh: () => {
      window.location.href = `${window.location.href}&time=${new Date().getTime()}`;
      // window.location.reload();
    }
  },
  watch: {
    $route(to) {
      if (to.name !== this.lastRouteName) {
        this.$ga.event('page', 'load', to.name, 1);
        this.lastRouteName = to.name;
      }
    },
    isNext(currentVal, previousVal) {
      if(currentVal === true && previousVal === false) {
        window.location.href = 'https://next.veloce.dev/app/dashboard?switchVersion=true'
      }
    }
  },
  created() {
    this.restoreLanguage();
    this.registerBusEvents();

    if (process.env.NODE_ENV !== 'production') {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      };
      setInterval(() => {
        fetch(`/version.json?time=${new Date().getTime()}`, requestOptions)
          .then(r => r.json())
          .then(x => {
            const version = require('@/version.json');
            if (x.version && x.version !== version.version) {
              this.hasNewVersion = true;
            } else {
              // this.hasNewVersion = false;
            }
          });
      }, 2000);
    }
  },
  beforeDestroy() {
    this.unregisterBusEvents();
  }
};
</script>

<style lang="scss">
@import 'normalize.scss';
@import 'mixins.scss';

html,
body {
  height: 100%;
  overflow: hidden;
  @include printableHeight;
}

.app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.el-loading-mask {
  z-index: 2;
}

.version-notification {
  align-content: center;
  align-items: center;
  align-self: center;
  background-color: rgb(255, 242, 204);
  bottom: 0;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  left: 0;
  padding: 1rem;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1000;
}

.maintenance {
  background-color: white;
  position: absolute;
  padding: 2rem;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  box-shadow: 0 0.5rem 1rem rgba(#000000, 0.15);
}
</style>
