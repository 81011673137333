import 'whatwg-fetch';
import { get, patch, post, remove, resolveCommonQuery } from '@/helpers/service.helper';
import pick from 'lodash.pick';

/**
 * Get all taxes
 * @link https://api.posveloce.com/docs/private#operation/getTaxes
 **/

const getTaxes = (token, query = {}) => {
  const params = pick(query, ['locationId', 'locationIds', 'groupId', 'ids']);
  const url = resolveCommonQuery('/taxes', params).toString();
  return get(token, url);
};

/**
 * Get tax
 * @link https://api.posveloce.com/docs/private#operation/getTaxById
 **/

const getTaxById = (token, id = {}) => {
  const url = resolveCommonQuery(`/taxes/${id}`, null).toString();
  return get(token, url);
};

/**
 * Edit tax
 * @link https://api.posveloce.com/docs/private#operation/patchTax
 **/

const editTax = (token, payload) => {
  const { id } = payload;
  const url = resolveCommonQuery(`/taxes/${id}`, null).toString();
  return patch(token, url, payload);
};

/**
 * Get all tax categories
 * @link https://api.posveloce.com/docs/private#operation/getTaxCategories
 **/

const getTaxCategories = (token, query = {}) => {
  const params = pick(query, ['locationId', 'locationIds', 'groupId', 'limit', 'offset']);
  const url = resolveCommonQuery('/taxes/categories', params).toString();
  return get(token, url);
};

/**
 * Create new tax
 * @link https://api.posveloce.com/docs/private#operation/postTax
 **/

const createTax = (token, body = {}) => {
  const url = resolveCommonQuery('/taxes', null).toString();
  return post(token, url, body);
};

/**
 * Create new tax category
 * @link https://api.posveloce.com/docs/private#operation/postTaxCategory
 **/

const createTaxCategory = (token, body = {}) => {
  const url = resolveCommonQuery('/taxes/categories', null).toString();
  return post(token, url, body);
};

/**
 * Edit tax category
 * @link https://api.posveloce.com/docs/private#operation/patchTaxCategory
 **/

const editTaxCategory = (token, payload) => {
  const { id } = payload;
  const body = { name: payload.name };
  const url = resolveCommonQuery(`/taxes/categories/${id}`, null).toString();
  return patch(token, url, body);
};

/**
 * Delete tax category
 * @link https://api.posveloce.com/docs/private#operation/deleteTaxCategory
 **/

const deleteTaxCategory = (token, id) => {
  const url = resolveCommonQuery(`/taxes/categories/${id}`, null).toString();
  return remove(token, url, null);
};

export default {
  getTaxes,
  getTaxById,
  editTax,
  createTax,
  getTaxCategories,
  createTaxCategory,
  editTaxCategory,
  deleteTaxCategory
};
