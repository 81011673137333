<template>
  <div v-loading="pending" class="pie-line-chart">
    <h5>{{ formattedTitle }}</h5>
    <div>
      <div class="chart__toolbar" v-if="getTotal">
        <apexchart
          v-if="!pending"
          ref="apex"
          type="treemap"
          :height="height"
          :options="chartOptions"
          :series="pieSeries"
          class="pie"
        />
      </div>
      <div class="chart__empty-state" v-else>
        <slot name="empty">{{ textEmpty || $t('pieLineChart.empty') }}</slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { showChartTooltipOnLegendEvent } from '@/helpers/charts.helpers';
import { mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataConfig } from '@/stores/data/config.module';

export default {
  name: 'pie-treemap-chart',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    pending: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: null
    },
    textEmpty: {
      type: String,
      default: null
    },
    series: {
      type: Array,
      default: null
    },
    height: {
      type: Number,
      default: 330
    },
    limit: {
      type: Number,
      default: 15
    }
  },
  computed: {
    getTotal() {
      return !!(this.series && this.series.length && this.series[0] && this.series[0].data);
    },
    pieSeries() {
      return this.series;
    },
    chartOptions() {
      return {
        responsive: [
          {
            breakpoint: 1024,
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }
        ],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px'
          },
          formatter(text, op) {
            return `${text} - ${op.value}`;
          }
        },
        chart: {
          events: {
            legendClick: showChartTooltipOnLegendEvent(this.$el)
          },
          toolbar: {
            show: false
          },
          height: 350,
          type: 'treemap'
        },
        colors: [
          '#008ffb',
          '#F7B844',
          '#ADD8C7',
          '#EC3C65',
          '#CDD7B6',
          '#C1F666',
          '#D43F97',
          '#1E5D8C',
          '#421243',
          '#7F94B0',
          '#EF6537',
          '#C0ADDB',
          '#F7B844',
          '#ADD8C7',
          '#EC3C65',
          '#CDD7B6',
          '#C1F666',
          '#D43F97',
          '#1E5D8C',
          '#421243',
          '#7F94B0',
          '#EF6537',
          '#C0ADDB',
          '#F7B844',
          '#ADD8C7',
          '#EC3C65',
          '#CDD7B6',
          '#C1F666',
          '#D43F97',
          '#1E5D8C',
          '#421243',
          '#7F94B0',
          '#EF6537',
          '#C0ADDB'
        ],
        plotOptions: {
          formatter(seriesName, opts) {
            return `${seriesName}:  ${opts.w.globals.series[opts.seriesIndex]}`;
          },
          treemap: {
            distributed: true,
            enableShades: false
          }
        }
      };
    },
    ...mapPiniaState(useDataConfig, ['get']),
    formattedTitle() {
      if (
        this.series &&
        this.series[0] &&
        this.series[0].data &&
        this.series[0].data.length >= this.get.charts_size_limitation.value
      ) {
        return this.title;
      }
      return this.title.replace(/\(TOP [0-9]+\)/gi, '');
    }
  }
};
</script>
