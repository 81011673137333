<template>
  <vel-page :loading="loading" class="big-division-page">
    <template v-slot:title>{{ $t('bigDivisionInfo.title') }}</template>
    <vel-card>
      <big-division-form :bigDivision="selectSelected" @submit="handleSubmit" />
    </vel-card>
  </vel-page>
</template>

<script>
import BigDivisionForm from './components/BigDivisionForm';
import VelCard from '@/components/card/VelCard';
import VelPage from '@/components/page/VelPage';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataBigDivisions } from '@/stores/data/big-divisions.module';
import { useDataBigDivisionsEdit } from '@/stores/data/big-divisions-edit.module';

export default {
  name: 'big-division-page',
  components: {
    BigDivisionForm,
    VelCard,
    VelPage
  },
  methods: {
    ...mapPiniaActions(useDataBigDivisionsEdit, ['clearMessage', 'reset']),
    ...mapPiniaActions(useDataBigDivisionsEdit, ['updateBigDivision']),
    ...mapPiniaActions(useDataBigDivisions, ['getBigDivisionById']),
    handleSubmit(bigDivisionEntity) {
      this.updateBigDivision(bigDivisionEntity);
    }
  },
  computed: {
    ...mapPiniaState(useDataBigDivisions, ['selectSelected', 'loading']),
    ...mapPiniaState(useDataBigDivisionsEdit, ['selectTotal']),
    showAlert() {
      return !!this.selectTotal;
    }
  },
  beforeDestroy() {
    this.clearMessage();
  },
  async mounted() {
    await this.getBigDivisionById();
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';

.success {
  margin-bottom: rem-calc(50);
}
</style>
