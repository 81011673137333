<template>
  <vel-backoffice-page
    class="backoffice-page bootstrap"
    :enableToolbar="false"
    :enable-date-range="false"
    :enable-date-picker="false"
    :enable-groups="false"
    :show-date-compare="false"
    :enable-all-locations="false"
    :scroll="false"
  >
    <template v-slot:header>
      <b-container fluid>
        <b-row class="d-flex flex-column flex-md-row pt-3">
          <b-col class="d-flex align-items-center">
            <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row v-if="isXLargeUp" class="mb-4 mt-4">
          <b-col>
            <h4>{{ $t('taxCategoriesList.title') }}</h4>
          </b-col>
          <b-col cols="2" class="pb-4">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="tableFilter"
                debounce="1"
                type="text"
                :placeholder="$t('taxCategoriesList.search')"
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col cols="2" class="w-fit-content text-right">
            <b-button @click="toggleDrawer(true, 'creation')" variant="primary">
              <b-icon style="text-transform: uppercase;" icon="plus" aria-hidden="true"></b-icon>
              {{ $t('taxCategoriesList.add-new') }}
            </b-button>
          </b-col>
        </b-row>
        <b-row v-else class="mb-4 mt-4">
          <b-col>
            <h4>{{ $t('taxCategoriesList.title') }}</h4>
          </b-col>
          <b-col cols="12" class="text-left">
            <b-button @click="toggleDrawer(true, 'creation')" variant="primary">
              <b-icon style="text-transform: uppercase;" icon="plus" aria-hidden="true"></b-icon>
              {{ $t('taxCategoriesList.add-new') }}
            </b-button>
          </b-col>
          <b-col cols="12" class="pt-4">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="tableFilter"
                debounce="1"
                type="text"
                :placeholder="$t('taxCategoriesList.search')"
              ></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <b-container fluid class="h-100">
      <b-table
        striped
        small
        hover
        bordered
        show-empty
        fixed
        primary-key="id"
        :empty-text="$t('defaults.empty')"
        :empty-filtered-text="$t('defaults.empty')"
        :busy="isLoading"
        :fields="fields"
        head-variant="info"
        :items="getTaxCategoriesRows"
        responsive="sm"
        ref="table"
        sort-by="name"
        sticky-header
      >
        <template #empty="scope">
          <div class="p-2">{{ scope.emptyText }}</div>
        </template>
        <template #emptyfiltered="scope">
          <div class="p-2">{{ scope.emptyFilteredText }}</div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner variant="primary" class="align-middle p-2 m-4"></b-spinner>
          </div>
        </template>
        <template #cell(name)="scope">
          <div class="w-100 justify-content-between d-flex align-items-center">
            {{ scope.item.name }}
          </div>
        </template>
        <template #cell(edit)="scope">
          <b-button variant="link" @click="toggleDrawer(true, 'edition', scope.item)">
            <vel-icon size="small" class="row-action text-primary p-0" name="xd-edit" />
            {{ $t('taxCategoriesList.table.edit') }}
          </b-button>
        </template>
        <template #cell(more)="scope">
          <tax-category-more @delete="handleDeleteModal(scope.item)" :id="scope.item.id" />
        </template>
      </b-table>
    </b-container>
    <div class="overlay" v-bind:class="{ toggled: isDrawerToggled }">
      <div v-if="creationMode" class="drawer" v-bind:class="{ toggled: isDrawerToggled }">
        <div class="header">{{ $t('taxCategoriesList.drawer.creationMode.header') }}</div>
        <div class="body">
          <b-form-group
            label-for="name"
            :label="$t('taxCategoriesList.drawer.creationMode.form.name.label')"
            :description="$t('taxCategoriesList.drawer.creationMode.form.name.required')"
          >
            <b-form-input
              id="name"
              :placeholder="$t('taxCategoriesList.drawer.creationMode.form.name.placeholder')"
              v-model="createForm.name"
              required
              maxlength="50"
              type="text"
              size="md"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="footer">
          <b-button :disabled="!createForm.name.length" @click="handleSave" variant="primary">
            {{ $t('defaults.actions.save') }}
          </b-button>
          <b-button variant="outline-secondary" @click="handleDiscardChanges">
            {{ $t('defaults.actions.cancel') }}
          </b-button>
        </div>
      </div>
      <div v-if="editionMode" class="drawer" v-bind:class="{ toggled: isDrawerToggled }">
        <div class="header">{{ $t('taxCategoriesList.drawer.editionMode.header') }}</div>
        <div class="body">
          <b-form-group
            label-for="name"
            :label="$t('taxCategoriesList.drawer.editionMode.form.name.label')"
            :description="$t('taxCategoriesList.drawer.editionMode.form.name.required')"
          >
            <b-form-input
              id="name"
              :placeholder="$t('taxCategoriesList.drawer.editionMode.form.name.placeholder')"
              v-model="editForm.name"
              required
              maxlength="50"
              type="text"
              size="md"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="footer">
          <b-button
            :disabled="!editForm.name.length || editForm.name === selected.name"
            @click="showModal.save = true"
            variant="primary"
          >
            {{ $t('defaults.actions.save') }}
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="editForm.name !== selected.name ? (showModal.cancel = true) : toggleDrawer(false)"
          >
            {{ $t('defaults.actions.cancel') }}
          </b-button>
        </div>
      </div>
    </div>
    <vel-alert
      style="font-size: 1.5rem;"
      class="alert"
      @dismiss="handleClearMessage"
      level="success"
      v-if="success && creationMode"
    >
      {{ $t('taxCategoriesList.success.created') }}
    </vel-alert>
    <vel-alert
      style="font-size: 1.5rem;"
      class="alert"
      @dismiss="handleClearMessage"
      level="success"
      v-else-if="success && editionMode"
    >
      {{ $t('taxCategoriesList.success.edited') }}
    </vel-alert>
    <vel-alert
      style="font-size: 1.5rem;"
      class="alert"
      @dismiss="handleClearMessage"
      level="success"
      v-else-if="success && deleteMode"
    >
      {{ $t('taxCategoriesList.success.deleted') }}
    </vel-alert>
    <vel-modal v-if="showModal.save">
      <template v-slot:header>
        {{ $t('taxCategoriesList.modal.save.header') }}
      </template>
      <template v-slot:default>
        <b-row class="mb-4">
          {{ $t('taxCategoriesList.modal.save.body') }}
        </b-row>
        <b-row class="d-flex justify-content-end">
          <b-button style="border: none; margin-right: 15px;" @click="handleDiscardChanges" variant="outline-primary">
            {{ $t('taxCategoriesList.modal.save.actions.discard') }}
          </b-button>
          <b-button @click="handleEditSave" variant="primary">
            {{ $t('taxCategoriesList.modal.save.actions.save') }}
          </b-button>
        </b-row>
      </template>
    </vel-modal>

    <vel-modal v-if="showModal.cancel">
      <template v-slot:header>
        {{ $t('taxCategoriesList.modal.cancel.header') }}
      </template>
      <template v-slot:default>
        <b-row class="mb-4">
          {{ $t('taxCategoriesList.modal.cancel.body') }}
        </b-row>
        <b-row class="d-flex justify-content-end">
          <b-button style="border: none; margin-right: 15px;" @click="handleDiscardChanges" variant="outline-primary">
            {{ $t('taxCategoriesList.modal.cancel.actions.discard') }}
          </b-button>
          <b-button @click="showModal.cancel = false" variant="primary">
            {{ $t('taxCategoriesList.modal.cancel.actions.continue') }}
          </b-button>
        </b-row>
      </template>
    </vel-modal>

    <vel-modal v-if="showModal.delete">
      <template v-slot:header>
        {{ $t('taxCategoriesList.modal.delete.header') }}
      </template>
      <template v-slot:default>
        <b-row class="mb-4">
          {{ $t('taxCategoriesList.modal.delete.body', { name: selected.name }) }}
        </b-row>
        <b-row class="d-flex justify-content-end">
          <b-button
            style="border: none; margin-right: 15px;"
            @click="showModal.delete = false"
            variant="outline-primary"
          >
            {{ $t('taxCategoriesList.modal.delete.actions.discard') }}
          </b-button>
          <b-button @click="handleDelete" variant="danger">
            {{ $t('taxCategoriesList.modal.delete.actions.delete') }}
          </b-button>
        </b-row>
      </template>
    </vel-modal>
    <tax-category-delete-error-modal
      :title="$t('taxCategoriesList.modal.errorDelete.header')"
      :body="$t('taxCategoriesList.modal.errorDelete.body', { name: selected.name, count: linkedTaxes.length })"
      :currentTaxCategory="selected"
      :locationName="locationName"
      :fileName="fileName"
      :fileIdentifier="fileIdentifier"
      :list="linkedTaxes"
      :show="!!error"
    />
  </vel-backoffice-page>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import TaxCategoryDeleteErrorModal from './components/TaxCategoryDeleteErrorModal';
import TaxCategoryMore from './components/TaxCategoryMore';
import VelAlert from '@/components/alert/VelAlert';
import VelBackofficePage from '@/components/page/VelBackofficePage';
import VelIcon from '@/components/icon/VelIcon';
import VelModal from '@/components/modal/VelModal';
import { bus } from '@/bus';
import { slugify } from '@/helpers/string.helpers';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataTaxCategories } from '@/stores/data/tax-categories.module';
import { useDataTaxes } from '@/stores/data/taxes.module';

export default {
  name: 'TaxCategoriesList',
  mixins: [DeviceMixin],
  components: {
    TaxCategoryDeleteErrorModal,
    TaxCategoryMore,
    VelAlert,
    VelBackofficePage,
    VelIcon,
    VelModal
  },
  data() {
    return {
      isDrawerToggled: false,
      showModal: {
        cancel: false,
        save: false,
        delete: false
      },
      creationMode: false,
      editionMode: false,
      deleteMode: false,
      createForm: {
        name: ''
      },
      editForm: {
        name: ''
      },
      selected: {}
    };
  },
  computed: {
    ...mapPiniaState(useDataTaxCategories, ['getTaxCategoriesRows', 'isLoading', 'success', 'error']),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById']),
    ...mapPiniaState(useUILocationSelector, ['getLocationSelectorSelected']),
    ...mapPiniaState(useDataTaxes, ['filter']),
    breadcrumbs() {
      return [
        {
          text: this.$t('taxCategoriesList.breadcrumbs.setup'),
          to: { name: 'setup-menu', query: { previous: 'taxes', ...this.$router.currentRoute.query } }
        },
        {
          text: this.$t('taxCategoriesList.breadcrumbs.tax-categories')
        }
      ];
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('taxCategoriesList.table.fields.name'),
          class: 'name',
          sortable: true
        },
        {
          key: 'edit',
          class: 'edit'
        },
        {
          key: 'more',
          class: 'more text-center'
        }
      ];
    },
    tableFilter: {
      get() {
        return this.filter;
      },
      set(val) {
        this.setFilter(val);
      }
    },
    linkedTaxes() {
      return this.error || [];
    },
    locationName() {
      const location = this.getLocationsById(this.getLocationSelectorSelected);
      return location && location.name;
    },
    fileName() {
      return slugify(this.$t('taxCategoriesList.title'));
    },
    fileIdentifier() {
      return slugify(this.locationName || '');
    }
  },
  methods: {
    ...mapPiniaActions(useDataTaxes, ['fetchTaxes']),
    ...mapPiniaActions(useDataTaxCategories, [
      'fetchTaxCategories',
      'createTaxCategory',
      'editTaxCategory',
      'setFilter',
      'clearSuccessMessage',
      'deleteTaxCategory'
    ]),
    toggleDrawer(toggleDrawer, drawerType = '', tax = {}) {
      if (drawerType === 'creation') {
        this.creationMode = true;
        this.editionMode = false;
      } else if (drawerType === 'edition') {
        this.creationMode = false;
        this.editionMode = true;
        this.selected = tax || {};
        this.editForm.name = tax.name || '';
      } else {
        this.creationMode = false;
        this.editionMode = false;
      }
      this.isDrawerToggled = toggleDrawer || !this.isDrawerToggled;
    },
    handleDocumentClick(e) {
      if (this.isDrawerToggled && e.target.className.includes('overlay')) {
        if (this.editionMode && this.selected.name !== this.editForm.name) {
          this.showModal.cancel = true;
        } else {
          this.toggleDrawer(false);
        }
      }
    },
    handleSave() {
      const { licenceNumber } = this.getLocationsById(this.getLocationSelectorSelected);
      this.createTaxCategory({ name: this.createForm.name, licenceNumber });
      this.createForm.name = '';
      this.toggleDrawer(false, 'creation');
    },
    handleEditSave() {
      this.editTaxCategory({ name: this.editForm.name, id: this.selected.id });
      this.showModal.save = false;
      this.editForm.name = '';
      this.toggleDrawer(false, 'edition');
    },
    handleDeleteModal(item) {
      this.showModal.delete = !this.showModal.delete;
      this.selected = item;
      this.$root.$emit('bv::hide::tooltip');
    },
    handleDelete() {
      this.deleteTaxCategory(this.selected.id);
      this.showModal.delete = false;
      this.deleteMode = true;
    },
    handleDiscardChanges() {
      this.showModal.cancel = false;
      this.showModal.save = false;
      this.editForm.name = '';
      this.createForm.name = '';
      this.toggleDrawer(false);
    },
    handleClearMessage() {
      this.clearSuccessMessage();
      this.creationMode = false;
      this.editionMode = false;
      this.deleteMode = false;
    }
  },
  async mounted() {
    bus.$on('document:click', this.handleDocumentClick);
    this.clearSuccessMessage();
    await this.fetchTaxCategories();
  },
  destroyed() {
    bus.$off('document:click', this.toggleDrawer(false));
    this.setFilter('');
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

/* stylelint-disable */
/deep/ {
  .input-group-sm > .form-control {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left-color: #fff;
  }
  .input-group-text {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: #fff !important;
    border-right: 1px solid #fff !important;
  }
  th {
    background-color: #eff9fd;
  }
  .page,
  .page .vel-page__content {
    overflow: hidden;
  }

  .vel-alert {
    align-self: center;
    bottom: 0;
    max-width: fit-content;
    position: absolute;
  }

  .w-fit-content {
    max-width: fit-content;
  }

  .overlay {
    background: rgba(#000000, 0.1);
    bottom: 0;
    height: calc(100vh - 60px);
    position: fixed;
    z-index: 99;

    &.toggled {
      width: 100vw;
    }

    .drawer {
      background: #fff;
      box-shadow: 0 0 0 1px rgba(#505f79, 0.05), 0 3px 3px 0 rgba(#505f79, 0.15);
      display: hidden;
      height: calc(100vh - 60px);
      padding: 0;
      position: fixed;
      right: -100%;
      transition: all 0.2s;
      width: 100%;

      @include breakpoint(large) {
        width: 25%;
      }

      &.toggled {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        right: 0;
      }
    }

    .header {
      font-weight: 500;
      justify-content: center;
    }

    .footer {
      justify-content: space-between;
      padding: 0 24px;
    }

    .header,
    .footer {
      align-items: center;
      background: #f4f4f4;
      display: flex;
      flex-direction: row;
      height: 64px;
      width: 100%;
    }

    .body {
      background: #fff;
      height: 100%;
      width: 100%;

      .form-group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 36px auto 0 24px;
        max-width: 330px;

        label,
        small {
          margin-left: 0.75rem;
        }
      }

      input {
        background: rgba(#00a9e1, 0.1);
        font-size: 16px;

        @include breakpoint(large) {
          max-width: 320px;
        }
      }
    }
  }

  .content,
  .vel-card__body,
  .b-table-sticky-header {
    height: 100%;
    max-height: 100%;
    padding-top: 0;
  }

  .btn-group {
    button {
      text-transform: uppercase;
      width: 160px;
    }
  }
  .pagination {
    height: calc(1.5em + 0.5rem + 2px);
    display: flex;
    align-items: center;
  }

  .thead-info {
    background-color: #eff9fd;
    font-weight: bold;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f6fbfe !important;
  }

  .table-hover tbody tr:hover {
    background-color: transparent !important;
  }

  .table-hover tbody tr:nth-of-type(odd):hover {
    background-color: #f6fbfe !important;
  }

  .name {
    @include breakpoint(large) {
      width: auto;
    }
  }

  .edit {
    width: 115px;

    .btn-sm {
      padding: 0;
      text-transform: none;
    }

    div {
      display: none;
    }
  }

  .more {
    width: 50px;

    .btn-sm {
      padding: 0;
    }

    div {
      display: none;
    }
  }

  th,
  td {
    font-size: 0.9rem;
  }

  th {
    user-select: none;
  }

  .content {
    margin-bottom: 0;
  }

  .row-action {
    color: #444444;
  }
  .page-item.active .page-link {
    background-color: #000 !important;
  }
  .page-link {
    border: none !important;
    color: #444444;
  }

  .toolbar__location-selector {
    max-width: 100%;

    @include breakpoint(large) {
      max-width: 360px;
    }
  }

  .vel-modal {
    max-width: 560px;

    &__body {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
}
</style>
