var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg-white p-4 mt-2"},[_c('b-form-group',{staticClass:"mr-5 w-100 mb-2 mb-md-0",attrs:{"label":this.$t('discount.edit.list.fields.couponItem'),"description":_vm.$t('itemProfile.required'),"label-for":"name"}},[_c('b-form-select',{staticClass:"form-text",class:{ required: !_vm.discountCoupon || !_vm.discountCoupon.itemId || _vm.discountCoupon.itemId === '' },attrs:{"options":[{ id: null, name: _vm.$t('itemProfile.emptyValue') }, ..._vm.items],"id":"supplierId","value-field":"itemId","text-field":"name","aria-describedby":"input-live-help input-live-feedback","disabled":_vm.discountCoupon.editIndex >= 0},model:{value:(_vm.discountCoupon.itemId),callback:function ($$v) {_vm.$set(_vm.discountCoupon, "itemId", $$v)},expression:"discountCoupon.itemId"}})],1),_c('b-form-group',{staticClass:"mr-5 w-100 mb-2 mb-md-0 mt-4",attrs:{"label":this.$t('discount.edit.list.fields.couponValue'),"description":_vm.$t('itemProfile.required'),"label-for":"name"}},[_c('b-form-input',{class:{
          required:
            !_vm.discountCoupon ||
            !_vm.discountCoupon.couponValue ||
            _vm.discountCoupon.couponValue === '' ||
            _vm.discountCoupon.couponValue === '0.00'
        },attrs:{"id":"deduction","no-wheel":"","lazy-formatter":"","formatter":_vm.formatAmount,"step":"0.01","type":"number","aria-describedby":"input-live-help input-live-feedback"},model:{value:(_vm.discountCoupon.couponValue),callback:function ($$v) {_vm.$set(_vm.discountCoupon, "couponValue", _vm._n($$v))},expression:"discountCoupon.couponValue"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }