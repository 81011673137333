import { accentFold } from '@/helpers/string.helpers';
import clonedeep from 'lodash.clonedeep';
import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import salesService from '@/services/sales.service';
import salesModuleHelper from '@/stores/sales.module.helper';

const applyFilter = (prop, filter) => element =>
  accentFold(element[prop].toLowerCase()).indexOf(accentFold(filter.toLowerCase())) >= 0;

export const useDataSalesEmployees = defineStore('salesEmployees', {
  state: () => ({
    sales: [],
    filter: '',
    status: {},
    error: {}
  }),
  getters: {
    areSalesLoading: (state: any) => state.status.isLoadingSales || false,
    getSales: (state: any) => (state.filter ? state.sales.filter(applyFilter('name', state.filter)) : state.sales),
    getSalesTree: (state: any) =>
      state.filter
        ? clonedeep(state.sales)
            .map(elem => {
              elem.children = elem.children.filter(applyFilter('label', state.filter));
              return elem;
            })
            .filter(elem => elem.children.length > 0)
        : state.sales,
    chartData: (state: any) => count => {
      if (!state.sales) {
        return [];
      }

      return state.sales.slice(0, count).map(({ label, salesAmount, quantity }) => ({
        label,
        salesAmount,
        quantity
      }));
    }
  },
  actions: {
    getSalesByEmployeeWithLevels(query = {}) {
      const toDay = today().startOf('day').toString();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;

      const routeQuery = {
        ...router.currentRoute.query,
        from,
        to,
        ...query
      };

      this.getSalesRequest();
      salesService.getSalesByEmployee(getToken(), routeQuery).then(
        sales => {
          this.getSalesSuccess(salesModuleHelper.formatEmployeeSalesByLevels(sales));
        },
        error => {
          this.getSalesFailure(error);
        }
      );
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    getSalesRequest() {
      this.status = { ...this.status, isLoadingSales: true };
      this.sales = [];
      this.filter = '';
      this.error = { ...this.error, getSalesError: null };
    },
    getSalesSuccess(sales) {
      this.status = { ...this.status, isLoadingSales: false };
      this.sales = sales;
      this.filter = '';
      this.error = { ...this.error, getSalesError: null };
    },
    getSalesFailure(error) {
      this.status = { ...this.status, isLoadingSales: false };
      this.sales = [];
      this.filter = '';
      this.error = { ...this.error, getSalesError: error };
    },
    setFilterValue(value) {
      this.filter = value;
    },
    reset() {
      Object.keys({
        sales: [],
        filter: '',
        status: {},
        error: {}
      }).forEach(key => {
        this[key] = {
          sales: [],
          filter: '',
          status: {},
          error: {}
        }[key];
      });
    }
  }
});
