import { defineStore } from 'pinia';
import router from '@/router';
import { useUIAlertStore } from '@/stores/ui/alert.module';
import { useDataLocationsStore } from '@/stores/data/locations.module';

export const useUICurrencySelector = defineStore('currencySelector', {
  state: () => ({
    selected: null,
    error: null
  }),
  getters: {
    getCurrencySelectorSelected: state => {
      const dataLocationsStore = useDataLocationsStore();
      return state.selected !== null ? state.selected : dataLocationsStore.getLocationsFirstCurrencySelectedGroup || '';
    },
    getCurrencySelectorSelectedSymbol: state => {
      const dataLocationsStore = useDataLocationsStore();
      return dataLocationsStore.getLocationsCurrenciesBySelectedGroup.filter(
        x => x.value === state.selected || !state.selected
      )[0]?.symbol;
    }
  },
  actions: {
    updateCurrency(currency) {
      if (currency) {
        this.selected = currency;
      }
    },
    updateCurrencySuccess() {
      this.error = null;
    },
    updateCurrencyFailure(error) {
      this.error = error;
    },
    async onChange(query: any = {}) {
      this.selected = query.currencyId;

      if (!(query && query.disableRouteChange)) {
        try {
          await router.replace({
            name: router.currentRoute.name,
            query: {
              ...router.currentRoute.query,
              currencyId: query.currencyId,
              locationId: null,
              locationIds: null,
              groupId: query.groupId || null
            }
          });
          this.error = null;
        } catch (error) {
          this.error = error;
          const alertStore = useUIAlertStore();
          alertStore.newError(error);
        }
      }
    }
  }
});
