<template>
  <nav class="vel-topbar" role="navigation">
    <div class="vel-topbar__left">
      <slot name="left" />
    </div>
    <div class="vel-topbar__right">
      <slot name="right" />
    </div>
  </nav>
</template>

<script>
export default {
  name: 'vel-topbar'
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';
@import 'layout.scss';

$topbar-background-color: $primary-color;
$topbar-font-color: white;
$topbar-height: $layout-topbar-height;

.vel-topbar {
  background-color: $topbar-background-color;
  color: $topbar-font-color;
  display: flex;
  flex-direction: row;
  height: $topbar-height;
  width: 100%;

  &__left {
    align-items: center;
    display: flex;
    flex: 1;
  }

  &__right {
    align-items: center;
    display: flex;
    height: 100%;
    margin-left: auto;
    padding-right: rem-calc(10);
  }
}
</style>
