<template>
  <vel-backoffice-page
    class="backoffice-page"
    :enableToolbar="false"
    :enable-date-range="false"
    :enable-date-picker="false"
    :enable-groups="false"
    :show-date-compare="false"
    :enable-all-locations="false"
    mode="edit"
    :loading="loading"
  >
    <template v-slot:footer>
      <combo-footer
        :combo="itemProfile"
        :pending="pending"
        :canSave="canSave"
        @onSave="updateItemProfile()"
        @onCancel="handleCancel(false)"
      ></combo-footer>
    </template>
    <template v-slot:header>
      <combo-header :combo.sync="itemProfile"></combo-header>
    </template>

    <h5 class="pt-4 px-4 pb-2">{{ $t('combo.edit.configuration') }}</h5>

    <b-container fluid class="content p-4">
      <div class="d-flex flex-column flex-md-row mb-md-2">
        <b-form-group
          :label="$t('combo.edit.fields.description.label')"
          :description="$t('combo.required')"
          label-for="name"
          class="mr-5 w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-2 p-0"
        >
          <b-form-input
            id="name"
            :state="!submited ? null : itemProfile.name !== '' && null"
            v-model="itemProfile.name"
            maxlength="50"
            aria-describedby="input-live-help input-live-feedback"
            :class="{ required: !itemProfile.name || itemProfile.name === '' }"
          ></b-form-input>
        </b-form-group>
      </div>
    </b-container>
    <h5 class="pt-4 px-4 pb-2">{{ $t('combo.edit.items') }}</h5>

    <b-container fluid class="content p-4">
      <b-form-group
        :label="$t('combo.edit.fields.allowToBeExchangedIntoItemId.label')"
        label-for="name"
        class="b-md-0 col-12 col-md-6 col-lg-2 p-0 mb-5"
      >
        <b-form-select
          :options="[{ id: null, name: $t('combo.emptyValue') }, ...items]"
          id="allowToBeExchangedIntoItemId"
          value-field="itemId"
          text-field="name"
          aria-describedby="input-live-help input-live-feedback"
          class="form-text"
          v-model="itemProfile.allowToBeExchangedIntoItemId"
        ></b-form-select>
      </b-form-group>
      <b-form-group>
        <span class="pr-4">{{ $t('combo.edit.fields.confirmationPrompt') }}</span>
        <b-form-checkbox
          class="d-inline-block"
          v-model="itemProfile.confirmationPrompt"
          name="check-button"
          switch
        ></b-form-checkbox>
      </b-form-group>
      <b-form-group id="taxesOnInvoiceType">
        <b-form-checkbox class="mt-4" name="keepOriginalItem" v-model="itemProfile.keepOriginalItem">
          {{ $t('combo.edit.fields.keepOriginalItem') }}
        </b-form-checkbox>
      </b-form-group>
      <div style="width: 100%; max-width: 800px; overflow: auto;">
        <b-row class="px-0 py-1 mt-4 mx-0" style="min-width: 800px;">
          <b-col cols="8" class="pr-0">
            <div style="font-size: 0.8rem;" class="p-1">
              {{ $t('combo.edit.fields.item') }}
            </div>
          </b-col>
          <b-col cols="2">
            <div style="font-size: 0.8rem;" class="p-1">
              {{ $t('combo.edit.fields.quantity') }}
            </div>
          </b-col>
        </b-row>
        <div class="items" style="min-width: 800px;">
          <b-row class="px-0 py-0 mx-0" v-for="(combo, index) in itemProfile.comboIncludedItems" :key="index">
            <template v-if="!combo.delete">
              <b-col cols="8" class="px-2 py-1 itemId">
                <b-form-select
                  :options="[{ id: null, name: $t('combo.selectItem') }, ...items]"
                  id="allowToBeExchangedIntoItemId"
                  value-field="itemId"
                  text-field="name"
                  aria-describedby="input-live-help input-live-feedback"
                  class="form-text"
                  v-model="itemProfile.comboIncludedItems[index].itemId"
                ></b-form-select>
              </b-col>
              <b-col cols="2" class="px-2 py-1 qty">
                <b-form-input
                  id="name"
                  :placeholder="$t('mealPlan.eligibility.amount.placeholder')"
                  v-model="itemProfile.comboIncludedItems[index].quantity"
                  number
                  :formatter="formatAmount"
                  lazy-formatter
                ></b-form-input>
              </b-col>
              <b-col cols="2" class="pl-4 d-flex align-items-center">
                <b-icon
                  v-if="
                    itemProfile.comboIncludedItems.filter(x => !x.delete).length > 1 ||
                    itemProfile.comboIncludedItems.filter(x => !x.delete)[0].itemId
                  "
                  icon="x-circle"
                  font-scale="1.5"
                  class="rounded mr-2"
                  variant="danger"
                  style="cursor: pointer;"
                  @click="removeComboItem(index)"
                ></b-icon>
                <b-icon
                  icon="plus-circle"
                  font-scale="1.5"
                  class="rounded"
                  variant="primary"
                  style="cursor: pointer;"
                  @click="addComboItem"
                ></b-icon>
              </b-col>
            </template>
          </b-row>
        </div>
      </div>
    </b-container>

    <b-modal
      v-model="showDiscardChanges"
      :title="$t('discardChange.title')"
      :static="true"
      :centered="true"
      :hide-header-close="true"
      :footer-bg-variant="'light'"
      :footer-text-variant="'dark'"
      @hidden="$emit('onCancel', true)"
    >
      {{ $t('discardChange.body') }}
      <template #modal-footer>
        <b-container fluid>
          <div class="w-100">
            <b-button variant="primary" class="float-right" @click="showDiscardChanges = false">
              {{ $t('discardChange.continue') }}
            </b-button>
            <b-button
              variant="link"
              class="float-right ml-2"
              @click="
                showDiscardChanges = false;
                handleCancel(true);
              "
            >
              {{ $t('discardChange.discard') }}
            </b-button>
          </div>
        </b-container>
      </template>
    </b-modal>
  </vel-backoffice-page>
</template>

<script>
import ComboFooter from './components/ComboFooter';
import ComboHeader from './components/ComboHeader';
import VelBackofficePage from '@/components/page/VelBackofficePage';
import { getToken } from '@/helpers/token.helper';
import itemsService from '@/services/items.service';
import locationService from '@/services/locations.service';
import clonedeep from 'lodash.clonedeep';
import { mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataLocationsStore } from '@/stores/data/locations.module';

export default {
  name: 'ComboCreatePage',
  components: {
    VelBackofficePage,
    ComboFooter,
    ComboHeader
  },
  props: {},
  methods: {
    formatAmount(value) {
      return Number(value).toFixed(2);
    },

    addComboItem() {
      this.itemProfile.comboIncludedItems.push({
        delete: false,
        itemId: null,
        quantity: 1
      });
    },
    removeComboItem(index) {
      this.itemProfile.comboIncludedItems[index].delete = true;
      this.itemProfile = clonedeep(this.itemProfile);
      if (this.itemProfile.comboIncludedItems.filter(x => !x.delete).length === 0) {
        this.addComboItem();
      }
    },
    toast(msg = 'toast.update', value = {}, type = 'success') {
      this.$toast.open({
        message: `${this.$t(msg, value)}`,
        position: 'bottom',
        type,
        queue: false,
        duration: 6000,
        dismissible: true
      });
    },

    onClickCancel() {
      this.showDiscardChanges = true;
    },

    async updateItemProfile() {
      this.pending = true;
      this.submited = true;
      this.isError = false;
      try {
        if (this.itemProfile.id) {
          await itemsService.updateComboIncludedItem(this.itemProfile.id, {
            ...this.itemProfile,
            comboIncludedItems: this.itemProfile.comboIncludedItems
          });
          this.toast('toast.comboUpdate', this.itemProfile);
        } else {
          await itemsService.postComboIncludedItems({
            ...this.itemProfile,
            comboIncludedItems: this.itemProfile.comboIncludedItems.filter(x => x.itemId && !x.delete)
          });

          this.toast('toast.comboCreate', this.itemProfile);
        }

        await this.$router.replace({ name: 'combos' }).catch(e => e);
      } catch (e) {
        this.toast('toast.error', this.itemProfile, 'error');
        console.error(e.message);
        this.isError = true;
      } finally {
        this.pending = false;
      }
      return null;
    },
    handleCancel(force = false) {
      if (JSON.stringify(this.itemProfileOrig) !== JSON.stringify(this.itemProfile) && !force) {
        this.showDiscardChanges = true;
      } else {
        this.$router.push({ name: 'combos' });
      }
    }
  },
  data() {
    return {
      deleteEntity: null,
      loading: true,
      isError: false,
      pending: false,
      locationId: null,
      items: [],
      comboItems: [],
      showDiscardChanges: false,
      itemProfileOrig: {
        name: '',
        licenceNumber: null,
        allowToBeExchangedIntoItemId: null,
        confirmationPrompt: true,
        keepOriginalItem: false,
        comboIncludedItems: [{ itemId: null, quantity: 1 }]
      },
      itemProfile: {
        name: '',
        licenceNumber: null,
        allowToBeExchangedIntoItemId: null,
        confirmationPrompt: true,
        keepOriginalItem: false,
        comboIncludedItems: [{ itemId: null, quantity: 1 }]
      },
      submited: false
    };
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById']),
    canSave() {
      if (
        !this.itemProfile.name ||
        this.itemProfile.name === '' ||
        JSON.stringify(this.itemProfileOrig) === JSON.stringify(this.itemProfile) ||
        (this.itemProfile.comboIncludedItems.length > 1 &&
          this.itemProfile.comboIncludedItems.filter(x => !x.itemId).length > 0)
      ) {
        return false;
      }
      return true;
    }
  },
  async mounted() {
    try {
      if (this.$route.params.id) {
        this.itemProfile = await itemsService.getComboIncludedItemById(getToken(), this.$route.params.id);
        this.itemProfile.comboIncludedItems = (this.itemProfile.comboIncludedItems.length &&
          this.itemProfile.comboIncludedItems) || [{ itemId: null, quantity: 1 }];
        this.itemProfileOrig = clonedeep(this.itemProfile);
      } else {
        this.itemProfile.licenceNumber = (
          this.getLocationsById(this.$route.query.locationIds) ||
          (await locationService.getLocation(getToken(), this.$route.query.locationIds))
        ).licenceNumber;
      }

      this.items = await itemsService.getItems(getToken(), { locationId: this.$route.query.locationIds });
      this.comboItems = await itemsService
        .getComboIncludedItems({ locationId: this.$route.query.locationIds })
        .then(r => r.content || []);

      this.loading = false;
    } catch (e) {
      console.error(e);
      this.isError = true;
      this.loading = false;
    }
    return true;
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

h5 {
  background-color: #f5f5f5;
  color: #2a3142;
  letter-spacing: 1px;
}

.backoffice-page {
  background-color: #f5f5f5;
}

.content {
  background-color: white;
}

/deep/ {
  label,
  .form-text {
    margin-bottom: 0;
    padding-left: 1rem;
  }
}

/deep/ .form-text {
  min-height: 18px;
}

input,
select {
  + .form-text + .invalid-feedback {
    visibility: hidden;
    display: none !important;
  }
}

input,
select {
  + .form-text {
    display: block !important;
  }
}

input[aria-invalid='true'],
select[aria-invalid='true'] {
  + .form-text + .invalid-feedback {
    visibility: visible;
    display: block !important;
  }
}

input[aria-invalid='true'],
select[aria-invalid='true'] {
  + .form-text {
    display: none !important;
  }
}

.invalid-feedback {
  display: block !important;
}

.vel-alert {
  align-self: center;
  max-width: fit-content;
}

/deep/ .form-group legend {
  padding-bottom: 0 !important;
}

/deep/ .input-group-text {
  background-color: #c9cacf;
  color: white;
}

.active .input-group-text {
  background-color: black;
  color: white;
}

.items > div:nth-child(odd) {
  .itemId,
  .qty {
    background-color: #00a9e114;
  }
}
</style>
