<template>
  <vel-table
    class="sales-detailed"
    :class="isLocationUnique ? 'unique-location' : ''"
    :defaultDirection="defaultDirection"
    :defaultSort="defaultSort"
    :rows="rows"
    :columns="columns"
    :loading="loading"
    :hoverable="hoverable"
    :exportable="true"
    :metas="exportableMetas"
    @row="handleRow"
  >
    <template v-slot:names-column="scope">
      <span class="item-name">{{ scope.row.itemName }}</span>
      <span class="division-name">{{ scope.row.divisionName }}</span>
      <span class="big-division-name">{{ scope.row.bigDivisionName }}</span>
      <span class="location-name" v-if="!isLocationUnique">{{ scope.row.locationName }}</span>
    </template>

    <template v-slot:salesAmount-column="scope">
      <vel-amount :amount="scope.row.salesAmount" :currency="scope.row.currency" />
    </template>

    <template v-slot:empty>
      {{ $t('sales.table.messages.noData') }}
    </template>
  </vel-table>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelAmount from '@/components/amount/VelAmount';
import VelTable from '@/components/table/VelTable';
import compareStringsInsensitive from '@/comparators/compare-strings-insensitive';
import formatQuantity from '@/filters/format-quantity';
import { oc } from 'ts-optchain';

export default {
  name: 'sales-detailed',
  components: {
    VelAmount,
    VelTable
  },
  mixins: [DeviceMixin],
  props: {
    defaultSort: {
      type: String,
      default: 'itemName'
    },
    defaultDirection: {
      type: String,
      default: 'asc'
    },
    loading: {
      type: Boolean,
      default: false
    },
    hoverable: {
      type: Boolean,
      default: true
    },
    sales: {
      type: Array,
      default: () => []
    },
    exportableMetas: {
      type: Object,
      default: null
    }
  },
  computed: {
    rows() {
      return this.sales.map(sale => ({
        ...sale,
        bigDivisionName: oc(sale.bigDivision).name(),
        divisionName: oc(sale.division).name(),
        itemName: oc(sale.item).name(),
        formattedQuantity: formatQuantity(sale.quantity)
      }));
    },
    isLocationUnique() {
      if (this.sales.length <= 0) {
        return true;
      }

      return this.sales.every(sale => sale.locationName === this.sales[0].locationName);
    },
    columns() {
      return {
        id: {
          visible: false,
          exportable: false
        },
        locationId: {
          visible: false,
          exportable: false
        },
        locationName: {
          class: 'location',
          title: this.$t('sales.table.columns.locationName'),
          sort: (s1, s2) => compareStringsInsensitive(s1.locationName, s2.locationName),
          sortable: true,
          visible: !this.isMediumDown && !this.isLocationUnique,
          width: this.isMediumUp ? '10%' : '15%'
        },
        bigDivisionName: {
          class: 'bigDivision',
          title: this.$t('sales.table.columns.bigDivisionName'),
          sort: (s1, s2) => compareStringsInsensitive(s1.bigDivisionName, s2.bigDivisionName),
          sortable: true,
          visible: !this.isMediumDown,
          width: this.isMediumUp ? '10%' : '15%'
        },
        divisionName: {
          class: 'division',
          title: this.$t('sales.table.columns.divisionName'),
          sort: (s1, s2) => compareStringsInsensitive(s1.divisionName, s2.divisionName),
          sortable: true,
          visible: !this.isMediumDown,
          width: this.isMediumUp ? '10%' : '15%'
        },
        itemName: {
          class: 'item',
          title: this.$t('sales.table.columns.itemName'),
          sort: (s1, s2) => compareStringsInsensitive(s1.itemName, s2.itemName),
          sortable: true,
          visible: !this.isMediumDown,
          width: this.isLocationUnique ? '20%' : '15%'
        },
        names: {
          class: 'names',
          title: this.$t('sales.table.columns.itemName'),
          sort: (s1, s2) => compareStringsInsensitive(s1.itemName, s2.itemName),
          exportable: false,
          sortable: true,
          visible: this.isMediumDown,
          width: '60%'
        },
        formattedQuantity: {
          class: 'quantity',
          title: this.$t('sales.table.columns.quantity'),
          sort: (s1, s2) => s1.quantity - s2.quantity,
          sortable: true,
          exportable: true,
          visible: true,
          width: this.isMediumUp ? '5%' : '10%'
        },
        salesAmount: {
          class: 'amount',
          title: this.$t('sales.table.columns.salesAmount'),
          sort: (s1, s2) => s1.salesAmount - s2.salesAmount,
          sortable: true,
          exportable: true,
          width: this.isMediumUp ? '10%' : '20%'
        },
        currency: {
          visible: false,
          exportable: true,
          title: this.$t('sales.table.columns.currency')
        }
      };
    }
  },
  methods: {
    handleRow(row) {
      this.$emit('row', row);
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.sales-detailed {
  /deep/ .vel-table__cell {
    vertical-align: top;

    &.amount,
    &.quantity {
      text-align: right;
      word-break: keep-all;
    }

    &.quantity {
      @include monospaced;
    }
  }

  .names {
    word-break: break-all;

    .item-name {
      font-weight: $font-bold;
      max-width: calc(50vw);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .item-name,
    .division-name,
    .big-division-name,
    .location-name {
      display: block;
    }
  }

  /* Since we show less columns on smaller viewports, we have to
     explicity define a style for them */
  @include breakpoint(small) {
    /deep/ .vel-table-header__cell:nth-child(1) {
      text-align: left;
    }

    /deep/ .vel-table-header__cell:nth-child(2),
    /deep/ .vel-table-header__cell:nth-child(3) {
      padding-right: 16px;
      text-align: right;
    }
  }

  @include breakpoint(medium) {
    /deep/ .vel-table-header__cell:nth-child(1),
    /deep/ .vel-table-header__cell:nth-child(2),
    /deep/ .vel-table-header__cell:nth-child(3),
    /deep/ .vel-table-header__cell:nth-child(4) {
      text-align: left;
    }

    /deep/ .vel-table-header__cell:nth-child(5),
    /deep/ .vel-table-header__cell:nth-child(6) {
      padding-right: 16px;
      text-align: right;
    }

    &.unique-location {
      /* stylelint-disable */
      /deep/ .vel-table-header__cell:nth-child(4) {
        padding-right: 16px;
        text-align: right;
      }
      /* stylelint-enable */
    }
  }
}
</style>
