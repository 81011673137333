import { get, resolveCommonQuery } from '@/helpers/service.helper';
import { getToken } from '@/helpers/token.helper';

/**
 * Get redirection tables
 * @link https://api.posveloce.com/docs/private#operation/getRedirectionTables
 */
export const getRedirectionTables = (params: {}) => {
  const url = resolveCommonQuery('/redirectionTables', params).toString();
  return get(getToken(), url);
};
