import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import groupsService from '@/services/groups.service';
import { oc } from 'ts-optchain';
import router from '@/router';
import { useUIAlertStore } from '@/stores/ui/alert.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';

const compareGroupLabel = (l1, l2) => oc(l1).label('').localeCompare(oc(l2).label(''));
const toLabelValue = group => ({ label: group.name, value: group.id });

export const useUIGroupSelector = defineStore('groupSelector', {
  state: () => ({
    groups: [],
    status: { isLoading: false },
    error: null,
    selected: ''
  }),
  getters: {
    isLoading: state => !!state.status.isLoading,
    hasError: state => state.error !== null,
    getGroupSelectorSelected(state) {
      return state.selected;
    },
    getSelectedGroup: state => {
      const selectedGroup = state.groups.filter(g => g.value === state.selected);
      if (!selectedGroup.length) {
        return null;
      }
      return selectedGroup[0];
    }
  },
  actions: {
    async onChange(query: any = {}) {
      const uiLocationSelectorStore = useUILocationSelector();
      uiLocationSelectorStore.ouGroupSelectorChange();
      let groupCurrency = null;
      let groupLocations = [];

      if (query.groupId) {
        // groupLocations = rootGetters['data/locations/getLocationsByGroupId'](query.groupId);
        groupLocations = [];
        if (groupLocations.length) {
          groupCurrency = groupLocations[0].currency;
        }
      }

      this.selected = query.groupId || '';

      if (!(query && query.disableRouteChange)) {
        try {
          await router.replace({
            name: router.currentRoute.name,
            query: {
              ...router.currentRoute.query,
              groupId: query.groupId,
              locationId: null,
              locationIds: null,
              currencyId: groupCurrency || router.currentRoute.query.currencyId || null
            }
          });
        } catch (error) {
          this.error = error;
          const alertStore = useUIAlertStore();
          alertStore.newError(error);
        }
      }

      const locationSelector = useUILocationSelector();

      locationSelector.updateSelectedLocation({});
      locationSelector.updateSelectedMultipleLocation({ value: [] });
    },

    routeChanged(query) {
      if (this.state.selected !== query.to.query.groupId) {
        this.selected = query.to.query.groupId || '';
      }
    },
    async loadGroups() {
      try {
        this.status = { isLoading: true };
        this.groups = [];
        this.error = null;

        const groups = await groupsService.getGroups(getToken());

        this.status = {};
        this.groups = groups.map(toLabelValue).sort(compareGroupLabel);
        this.error = null;
      } catch (error) {
        this.status = {};
        this.groups = [];
        this.error = error;
        const alertStore = useUIAlertStore();
        alertStore.newError(error);
      }
    },
    updateSelectedGroup(payload) {
      this.selected = payload.groupId || '';
    },
    updateSelectedGroupFailure(error) {
      this.error = error;
    },
    loadGroupsRequest() {
      this.status = { isLoading: true };
      this.groups = [];
      this.error = null;
    },
    loadGroupsSuccess(groups) {
      this.status = {};
      this.groups = groups;
      this.error = null;
    },
    loadGroupsFailure(error) {
      this.status = {};
      this.groups = [];
      this.error = error;
    }
  }
});
