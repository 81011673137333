import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import uniq from 'lodash.uniq';
import locationService from '@/services/locations.service';

const uniqueCurrencies = ({ locationsSales }) =>
  uniq([...(locationsSales[0] || []).map(ls => ls.currency), ...(locationsSales[1] || []).map(ls => ls.currency)]);

export const useDataSalesLocations = defineStore('salesLocations', {
  state: () => ({
    locationsSales: [[]],
    status: { isLoading: true },
    error: null
  }),
  getters: {
    isLocationSalesLoading: state => state.status.isLoading,
    getLocationsSalesCurrencies: state => uniqueCurrencies(state),
    getPreviousLocationsSales: state => state.locationsSales[1],
    hasLocationsSales: state => state.locationsSales.length && state.locationsSales[0].length,
    uniqueCurrencies(state) {
      return uniqueCurrencies(state);
    }
  },
  actions: {
    async getLocationsSales(payload: any = {}) {
      const toDay = today().startOf('day').toISO();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;
      const routeQuery = {
        ...router.currentRoute.query,
        from,
        to,
        ...payload
      };

      if (!requestAllocated(routeQuery)) {
        this.getLocationsSalesRequest();

        try {
          const requests = [locationService.getLocationsSales(getToken(), routeQuery)];

          (payload.periods || []).forEach(period => {
            requests.push(
              locationService.getLocationsSales(getToken(), {
                ...routeQuery,
                ...period
              })
            );
          });

          const sales = await Promise.all(requests);

          this.getLocationsSalesSuccess(sales);

          deallocateRequest(routeQuery);
        } catch (error) {
          this.getLocationsSalesFailure(error);
        }
      }
    },
    getLocationsSalesRequest() {
      this.status = { isLoading: true };
      this.locationsSales = [[]];
      this.error = null;
    },
    getLocationsSalesSuccess(sales) {
      this.locationsSales = sales || [[]];
      this.error = null;
      this.status = { isLoading: false };
    },
    getLocationsSalesFailure(error) {
      this.status = { isLoading: false };
      this.locationsSales = [[]];
      this.error = error;
    }
  }
});
