<template>
  <vel-page
    class="location"
    :enableToolbar="true"
    :enable-date-range="false"
    :enable-date-picker="false"
    :enable-groups="false"
    :show-date-compare="false"
    :enable-all-locations="false"
    :loading="isPageLoading"
  >
    <template v-slot:title>
      {{ $t('pages.location-configuration') }}
    </template>
    <vel-card v-loading="loading">
      <vel-alert @dismiss="clearMessage" class="alert" level="error" v-if="isError">
        {{ $t('locationConfiguration.form.failure') }}
      </vel-alert>
      <vel-alert @dismiss="clearMessage" class="success" level="success" v-if="isSuccess">
        {{ $t('locationConfiguration.form.success') }}
      </vel-alert>
      <vel-form
        ref="ruleForm"
        label-position="top"
        :model="locationConfigForm"
        :rules="locationConfigRules"
        :disabled="loading"
        label-width="120px"
      >
        <vel-form-item :label="$t('locationConfiguration.form.labels.startDayOfWeek')" prop="startDayOfWeek">
          <vel-select v-model="locationConfigForm.startDayOfWeek">
            <vel-option
              v-for="day in dayOfWeek"
              :label="$t('locationConfiguration.form.dayOfWeek.' + day)"
              :value="day"
              :key="day"
            ></vel-option>
          </vel-select>
        </vel-form-item>
        <vel-form-item :label="$t('locationConfiguration.form.labels.numberOfMenus')" prop="numberOfMenus">
          <vel-input-number v-model="locationConfigForm.numberOfMenus" :min="1" :max="10"></vel-input-number>
        </vel-form-item>

        <vel-form-item :label="$t('locationConfiguration.form.labels.endDayTime')" prop="endDayTime">
          <vel-time-picker
            v-model="locationConfigForm.endDayTime"
            :picker-options="{ selectableRange: '00:00:00 - 23:59:59' }"
          ></vel-time-picker>
        </vel-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">
          {{ $t('locationConfiguration.form.submitButton.text') }}
        </el-button>
      </vel-form>
    </vel-card>
  </vel-page>
</template>

<script>
import { Button } from 'element-ui';
import VelAlert from '@/components/alert/VelAlert';
import VelCard from '@/components/card/VelCard';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelInputNumber from '@/components/input/VelInputNumber';
import VelOption from '@/components/x-option/VelOption';
import VelPage from '@/components/page/VelPage';
import VelSelect from '@/components/x-select/VelSelect';
import VelTimePicker from '@/components/time-picker/VelTimePicker';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { useDataLocationsSettingsLocation } from '@/stores/data/locations/settings-location.module';
import { useDataLocationsStore } from '@/stores/data/locations.module';

export default {
  name: 'LocationConfigurationPage',
  components: {
    VelAlert,
    VelCard,
    VelForm,
    VelFormItem,
    VelInputNumber,
    VelTimePicker,
    VelOption,
    VelSelect,
    VelPage,
    'el-button': Button
  },
  props: {},
  methods: {
    ...mapPiniaActions(useDataLocationsSettingsLocation, ['getSettings', 'putSettings', 'clearMessage']),
    onLanguageChange(val) {
      if (!val) {
        this.locationConfigForm.mainLanguage =
          (this.locationConfigForm.supportedLanguages &&
            this.locationConfigForm.supportedLanguages.french &&
            'french') ||
          'english';
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.putSettings({ locationId: this.locationId, settings: this.locationConfigForm });
        }
      });
    }
  },
  data() {
    return {
      locationConfigForm: {
        startDayOfWeek: 'sunday',
        supportedLanguages: {
          french: true,
          english: true
        },
        mainLanguage: 'english',
        numberOfMenus: null,
        endDayTime: new Date(2020, 7, 10, 23, 59, 59)
      },
      dayOfWeek: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
      mainLanguages: ['english', 'french']
    };
  },
  watch: {
    locationId(val) {
      this.getSettings(val);
    },
    settings(val) {
      if (val && val.startDayOfWeek) {
        this.locationConfigForm = {
          ...val,
          supportedLanguages: { ...(val.supportedLanguages || {}) },
          endDayTime: new Date(val.endDayTime)
        };
      }
    }
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, ['getLocationsIsLoading']),
    ...mapPiniaState(useDataLocationsSettingsLocation, ['settings', 'loading', 'isError', 'isSuccess']),
    ...mapPiniaState(useUILocationSelector, {
      hasLocationsError: 'hasError',
      locationsLoading: 'isLoading',
      locationError: 'error',
      locationId: 'getLocationSelectorSelected'
    }),
    isPageLoading() {
      return this.getLocationsIsLoading;
    },
    locationConfigRules() {
      const validateSupportedLanguages = (rule, value, callback) => {
        if (!value.french && !value.english) {
          return callback(new Error());
        }
        return callback();
      };
      return {
        startDayOfWeek: [
          {
            required: true,
            message: this.$t('locationConfiguration.form.rules.startDayOfWeek.required'),
            trigger: 'change'
          }
        ],
        supportedLanguages: [
          {
            type: 'object',
            required: true,
            validator: validateSupportedLanguages,
            message: this.$t('locationConfiguration.form.rules.supportedLanguages.required'),
            trigger: 'change'
          }
        ],
        mainLanguage: [
          {
            type: 'string',
            required:
              this.locationConfigForm.supportedLanguages &&
              this.locationConfigForm.supportedLanguages.french &&
              this.locationConfigForm.supportedLanguages.english,
            message: this.$t('locationConfiguration.form.rules.mainLanguage.required'),
            trigger: 'change'
          }
        ],
        numberOfMenus: [
          {
            type: 'number',
            required: true,
            message: this.$t('locationConfiguration.form.rules.numberOfMenus.required'),
            trigger: 'change'
          }
        ],
        endDayTime: [
          {
            type: 'date',
            required: true,
            message: this.$t('locationConfiguration.form.rules.endDayTime.required'),
            trigger: 'change'
          }
        ]
      };
    }
  },
  mounted() {
    this.getSettings(this.locationId);
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

/deep/ label {
  font-weight: bold;
}

/deep/ {
  .el-select,
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    max-width: 220px;
    width: 100%;
  }
}
</style>
