<template>
  <ol class="vel-breadcrumb" v-if="breadcrumbsStore.total > 1">
    <li class="vel-breadcrumb__crumb" v-for="(crumb, index) in breadcrumbsStore.breadcrumbs" :key="crumb.name">
      <router-link
        class="vel-breadcrumb__link"
        active-class="vel-breadcrumb__link_active"
        exact-active-class="vel-breadcrumb__link_active_exact"
        v-if="index < total - 1"
        :to="crumb.path"
      >
        {{ $t(`pages`)[crumb.name] || crumb.name }}
      </router-link>
      <span v-else>{{ $t(`pages`)[crumb.name] || crumb.name }}</span>
    </li>
  </ol>
</template>

<script>
import { useUIBreadcrumbsStore } from '@/stores/ui/breadcrumbs.module';

export default {
  name: 'vel-breadcrumb',
  setup() {
    const breadcrumbsStore = useUIBreadcrumbsStore();
    return { breadcrumbsStore };
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$breadcrumb-separator-color: $pale-sky;

// link
$breadcrumb__link-font-color: '#4C5667';
$breadcrumb__link-font-color_hover: mix(black, $primary-color, 10%);
$breadcrumb__link-font-color_active: '#4C5667';
$breadcrumb__link-font-weight: $font-regular;

.vel-breadcrumb {
  align-content: center;
  display: flex;
  font-size: 14px;
  list-style: none;
  margin: 0;
  padding: 0;
  @include text-ellipsis;

  &__crumb {
    padding-left: rem-calc(8);

    &::before {
      color: $breadcrumb-separator-color;
      content: '/';
      display: inline-flex;
      padding-right: rem-calc(8);
    }

    &:first-child::before {
      display: none;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  &__crumb:last-child &__link {
    &,
    &:hover {
      color: $breadcrumb__link-font-color_active;
    }
  }

  &__link {
    color: $breadcrumb__link-font-color;
    font-weight: $breadcrumb__link-font-weight;
    outline: none;
    text-decoration: none;

    &:any-link {
      color: $breadcrumb__link-font-color;
      font-weight: $breadcrumb__link-font-weight;
      outline: none;
      text-decoration: none;
    }

    &:hover {
      color: $breadcrumb__link-font-color_hover;
    }

    &_active_exact,
    &_active_exact:hover {
      color: $breadcrumb__link-font-color_active;
    }
  }
}
</style>
