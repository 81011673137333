<template>
  <vel-input-group>
    <vel-label v-if="label" :name="name" v-text="label" :required="required" />
    <input
      class="vel-input"
      :class="classes"
      :aria-disabled="disabled"
      :disabled="disabled"
      :required="required"
      :aria-required="required"
      :type="type"
      :placeholder="placeholder"
      :aria-label="label"
      :value="value"
      :text="text"
      :name="name"
      :maxlength="maxlength"
      @input="handleInput"
    />
    <vel-form-error v-if="hasErrors" :error="errors.first(name)" />
  </vel-input-group>
</template>

<script>
import VelFormError from '../form/VelFormError.vue';
import VelInputGroup from './VelInputGroup.vue';
import VelLabel from './VelLabel';
import { oc } from 'ts-optchain';

export default {
  name: 'vel-input',
  components: {
    VelLabel,
    VelInputGroup,
    VelFormError
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    text: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    maxlength: {
      type: [String, Number]
    }
  },
  computed: {
    classes() {
      const { name } = this.$options;
      return [
        {
          [`${name}_disabled`]: this.disabled,
          [`${name}_error`]: this.errors
        }
      ];
    },
    hasErrors() {
      return oc(this).errors(new Map()).has(this.name) || false;
    }
  },
  methods: {
    handleInput(event) {
      this.$emit('input', oc(event).target.value());
    }
  }
};
</script>

<style lang="scss" src="./VelInput.scss" />
