<template>
  <vel-tooltip class="account-tooltip" :class="classes">
    <header class="account-tooltip__header">
      <h5 class="account-tooltip__title">
        {{ $t('topbar.accountTooltip.title', { firstname }) }}
      </h5>
    </header>
    <ul class="account-tooltip__actions">
      <li class="action-tooltip__action">
        <button class="account-tooltip__action-button" @click="toggleLanguage">
          <vel-icon class="account-tooltip__action-icon" name="hearth" />
          <span class="account-tooltip__action-text">
            {{ $t('topbar.accountTooltip.actions.switchLanguage') }}
          </span>
        </button>
      </li>
      <li class="account-tooltip__action">
        <router-link class="account-tooltip__action-link" :to="{ name: 'change-password' }">
          <vel-icon class="account-tooltip__action-icon" name="password-login" />
          <span class="account-tooltip__action-text">
            {{ $t('topbar.accountTooltip.actions.changePassword') }}
          </span>
        </router-link>
      </li>
      <li class="account-tooltip__action">
        <router-link class="account-tooltip__action-link" :to="{ name: 'logout' }">
          <vel-icon class="account-tooltip__action-icon" name="logout" />
          <span class="account-tooltip__action-text">
            {{ $t('topbar.accountTooltip.actions.logout') }}
          </span>
        </router-link>
      </li>
    </ul>
  </vel-tooltip>
</template>

<script>
import LanguageMixin from '@/mixins/language-mixin';
import VelIcon from '@/components/icon/VelIcon';
import VelTooltip from '@/components/tooltip/VelTooltip';

export default {
  name: 'account-tooltip',
  mixins: [LanguageMixin],
  components: {
    VelTooltip,
    VelIcon
  },
  props: {
    firstname: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      const { name } = this.$options;
      return {
        [`${name}_visible`]: this.visible
      };
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$account-tooltip-max-width: rem-calc(200);

// title
$account-tooltip__title-font-size: rem-calc(12);
$account-tooltip__title-font-weight: $font-regular;

// action-link
$account-tooltip__action-link-background-color_hover: $wild-sand;

// action-icon
$account-tooltip__action-icon-color: $shark;

// action-text
$account-tooltip__action-text-font-color: $shark;
$account-tooltip__action-text-font-size: rem-calc(14);
$account-tooltip__action-text-font-weight: $font-regular;

.account-tooltip {
  display: none;
  flex-direction: column;
  max-width: $account-tooltip-max-width;
  overflow: hidden;
  padding: rem-calc(4) 0;

  &_visible {
    display: flex;
  }

  &__header {
    padding: rem-calc(12) rem-calc(20);
  }

  &__title {
    font-size: $account-tooltip__title-font-size;
    font-weight: $account-tooltip__title-font-weight;
    user-select: none;
    @include text-ellipsis;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__action {
    display: flex;
    flex: 1;
  }

  &__action-link,
  &__action-button {
    align-items: center;
    display: flex;
    padding: rem-calc(4) rem-calc(20);
    width: 100%;

    &:hover {
      background-color: $account-tooltip__action-link-background-color_hover;
    }
  }

  &__action-link {
    &,
    &:link {
      text-decoration: none;
    }
  }

  &__action-button {
    -webkit-appearance: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }

  &__action-icon {
    margin-right: rem-calc(10);
    @include icon-path-fill($account-tooltip__action-icon-color);
  }

  &__action-text {
    color: $account-tooltip__action-text-font-color;
    font-size: $account-tooltip__action-text-font-size;
    font-weight: $account-tooltip__action-text-font-weight;
  }
}
</style>
