<template>
  <pie-line-chart
    :pending="isPending"
    :options="chartOptions"
    :series="series"
    :labels="labels"
    :title="$t('sales.modeCustomersChart.title', { limit })"
    :type="get.default_chart_customers_per_mode.value"
    class="hourly-sales__chart"
  />
</template>

<script>
import PieLineChart from '@/components/pie-line-chart/PieLineChart';
import { useDataSalesModes } from '@/stores/data/sales/modes.module';
import { storeToRefs } from 'pinia';
import { mapState as mapPiniaState } from 'pinia';
import { useDataConfig } from '@/stores/data/config.module';
import { mapActions } from 'pinia/dist/pinia';
import pinia from '@/pinia';
export default {
  name: 'mode-customers',
  components: {
    PieLineChart
  },
  props: {
    currencyId: {
      type: String,
      default: null
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  setup() {
    const storeModeSales = useDataSalesModes(pinia);

    const { isPending, getCustomersLabels, getCustomersSeries } = storeToRefs(storeModeSales);
    return { isPending, getCustomersLabels, getCustomersSeries, getSalesByMode: storeModeSales.getSalesByMode };
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),
    series() {
      return [
        {
          name: this.$t('sales.modeCustomersChart.series.title', { currency: this.currencyId }),
          data: this.getCustomersSeries.slice(0, this.limit)
        }
      ];
    },
    labels() {
      return this.getCustomersLabels.slice(0, this.limit);
    },
    chartOptions() {
      return {
        xaxis: {
          title: {
            text: this.$t('sales.modeCustomersChart.xaxis')
          }
        },
        yaxis: {
          title: {
            text: this.$t('sales.modeCustomersChart.yaxis')
          }
        }
      };
    }
  },
  mounted() {
    this.getSalesByMode();
  }
};
</script>
