<template>
  <vel-backoffice-page
    class="backoffice-page"
    :enableToolbar="false"
    :enable-date-range="false"
    :enable-date-picker="false"
    :enable-groups="false"
    :show-date-compare="false"
    :enable-all-locations="false"
    mode="edit"
    :loading="loading"
  >
    <template v-slot:footer>
      <discount-footer
        :disocunt="itemProfile"
        :pending="pending"
        :canSave="canSave"
        :can-continue="canContinue"
        :showCanContinue="activeTab < 2"
        @onSave="updateItemProfile()"
        @onContinue="activeTab++"
        @onCancel="handleCancel(false)"
      ></discount-footer>
    </template>
    <template v-slot:header>
      <discount-header :disocunt.sync="itemProfile"></discount-header>
    </template>
    <b-tabs active class="bg-grey" v-loading="pending" v-model="activeTab">
      <b-tab :title="$t('discount.edit.tabs.generalConfiguration')" title-item-class="col-sm-12 col-md-auto p-0 m-0">
        <h5 class="pt-4 px-4 pb-2">{{ $t('discount.edit.configuration') }}</h5>

        <b-container fluid class="content p-4">
          <div class="d-flex flex-column flex-md-row mb-md-2">
            <b-form-group
              :label="$t('discount.edit.fields.description.label')"
              :description="$t('discount.required')"
              label-for="name"
              class="mr-5 w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-2 p-0"
            >
              <b-form-input
                id="name"
                :state="!submited ? null : itemProfile.name !== '' && null"
                v-model="itemProfile.name"
                maxlength="50"
                aria-describedby="input-live-help input-live-feedback"
                :class="{ required: !itemProfile.name || itemProfile.name === '' }"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :label="$t('discount.edit.fields.alternativeName.label')"
              label-for="name"
              class="mr-5 w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-2 p-0"
            >
              <b-form-input
                id="name"
                v-model="itemProfile.alternativeName"
                maxlength="50"
                aria-describedby="input-live-help input-live-feedback"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :label="$t('discount.edit.fields.discountType.label')"
              label-for="name"
              class="mr-5 w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-2 p-0"
            >
              <b-form-select
                :options="[...discountTypes]"
                id="type"
                value-field="id"
                text-field="name"
                aria-describedby="input-live-help input-live-feedback"
                class="form-text"
                v-model="itemProfile.type"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              v-if="itemProfile.type !== null"
              :label="$t('discount.edit.fields.' + (itemProfile.type <= 2 ? 'amount' : 'percentage') + '.label')"
              class="mr-5 w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-2 p-0"
            >
              <b-input-group :prepend="itemProfile.type <= 2 ? '$' : '%'">
                <b-form-input
                  id="deduction"
                  v-model.number="itemProfile.amount"
                  no-wheel
                  lazy-formatter
                  :formatter="formatAmount"
                  step="0.01"
                  aria-describedby="input-live-help input-live-feedback"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">{{ $t('discount.edit.taxes') }}</h5>

        <b-container fluid class="content p-4">
          <b-form-group>
            <span class="pr-4">{{ $t('discount.edit.fields.taxesOnInvoice') }}</span>
            <b-form-checkbox
              class="d-inline-block"
              v-model="itemProfile.taxesOnInvoice"
              name="check-button"
              switch
            ></b-form-checkbox>
          </b-form-group>
          <b-form-group id="taxesOnInvoiceType">
            <b-form-radio class="mt-4" name="taxesOnInvoiceType" :value="1" v-model="itemProfile.taxesOnInvoiceType">
              {{ $t('discount.edit.fields.afterDiscount') }}
            </b-form-radio>
            <b-form-radio class="mt-4" name="taxesOnInvoiceType" :value="2" v-model="itemProfile.taxesOnInvoiceType">
              {{ $t('discount.edit.fields.beforeDiscount') }}
            </b-form-radio>
            <b-form-radio class="mt-4" name="taxesOnInvoiceType" :value="3" v-model="itemProfile.taxesOnInvoiceType">
              {{ $t('discount.edit.fields.taxExcluded') }}
            </b-form-radio>
            <b-form-radio class="mt-4" name="taxesOnInvoiceType" :value="4" v-model="itemProfile.taxesOnInvoiceType">
              {{ $t('discount.edit.fields.taxIncluded') }}
            </b-form-radio>
          </b-form-group>
          <b-form-group
            :label="$t('discount.edit.fields.removeTaxCategory.label')"
            label-for="name"
            class="b-md-0 col-12 col-md-6 col-lg-2 mt-4 p-0 mb-4"
          >
            <b-form-select
              :options="[...taxCategories]"
              id="discountType"
              value-field="id"
              text-field="name"
              aria-describedby="input-live-help input-live-feedback"
              class="form-text"
              v-model="itemProfile.removeTaxCategoryId"
            ></b-form-select>
          </b-form-group>
        </b-container>

        <h5 class="pt-4 px-4 pb-2">{{ $t('discount.edit.subCategory') }}</h5>
        <b-container fluid class="content p-4">
          <div class="d-flex flex-column flex-md-row mb-md-2">
            <b-form-group
              :label="$t('discount.edit.fields.subCategory.label')"
              label-for="name"
              class="w-100 mb-2 mb-md-0 col-12 col-md-6 col-lg-3 pl-md-0 p-0"
            >
              <multiselect
                :options="divisions"
                :multiple="true"
                :showLabels="false"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :value="selectedDivisionsId"
                placeholder=""
                label="name"
                :options-limit="100000"
                track-by="id"
                :max-height="600"
                @input="onDivisionChange"
              >
                <template slot="noResult">
                  {{ $t('dashboard.locationSelector.noResult') }}
                </template>
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span v-if="values.length > 0 && !isOpen" class="multiselect__single">
                    {{
                      $t('discount.edit.fields.subCategorySelected', {
                        count: values.length || 0
                      })
                    }}
                  </span>
                  <span v-if="values.length === 0 && !isOpen" class="multiselect__single">
                    {{ $t('discount.emptyValue') }}
                  </span>
                </template>
              </multiselect>
            </b-form-group>
          </div>
          <el-tag
            v-for="tag in itemProfile.discountDivisions"
            :key="tag.id"
            type="primary"
            closable
            style="margin-top: 1rem; margin-right: 1rem;"
            @close="
              (itemProfile.discountDivisions || []).splice(
                (itemProfile.discountDivisions || []).map(x => x.id).indexOf(tag.id),
                1
              );
              onDivisionChange(itemProfile.discountDivisions);
            "
          >
            {{ tag.division.name }}
          </el-tag>
          <div class="row d-flex mt-5 p-0 m-0">
            <template v-for="(discountDivision, di) in itemProfile.discountDivisions" class="mb-4">
              <div class="col-md-2 mb-4 pl-4 align-self-center">{{ discountDivision.division.name }}</div>
              <div class="col-md-2 mb-4">
                <b-input-group :prepend="itemProfile.type <= 2 ? '$' : '%'">
                  <b-form-input
                    id="deduction"
                    v-model.number="itemProfile.discountDivisions[di].value"
                    no-wheel
                    lazy-formatter
                    :formatter="formatAmount"
                    step="0.01"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                </b-input-group>
              </div>
            </template>
          </div>
        </b-container>
      </b-tab>
      <b-tab
        :title="$t('discount.edit.tabs.availability')"
        :disabled="!canContinue"
        title-item-class="col-sm-12 col-md-auto p-0 m-0"
      >
        <h5 class="pt-4 px-4 pb-2">{{ $t('discount.edit.misc') }}</h5>

        <b-container fluid class="content p-4">
          <b-form-checkbox name="unrestrictedAccess" v-model="itemProfile.unrestrictedAccess">
            {{ $t('discount.edit.fields.unrestrictedAccess') }}
          </b-form-checkbox>
          <b-form-group>
            <b-form-checkbox
              class="mt-4 d-inline-block"
              name="cashReconciliation"
              v-model="itemProfile.cashReconciliation"
            >
              {{ $t('discount.edit.fields.cashReconciliation') }}
            </b-form-checkbox>
            <div class="d-inline-block pl-5 ml-md-5 mt-2 mt-md-0" style="max-width: 300px; width: 100%;">
              <b-form-select
                :options="[{ id: 0, name: $t('discount.emptyValue') }, ...cashReconciliationTypes]"
                id="cashReconciliationType"
                value-field="id"
                text-field="name"
                aria-describedby="input-live-help input-live-feedback"
                class="form-text"
                v-model="itemProfile.cashReconciliationType"
                style="width: 100%;"
              ></b-form-select>
            </div>
          </b-form-group>
          <b-form-checkbox class="mt-4" name="qualityDiscount" v-model="itemProfile.qualityDiscount">
            {{ $t('discount.edit.fields.qualityDiscount') }}
          </b-form-checkbox>
          <b-form-checkbox class="mt-4" name="withReference" v-model="itemProfile.withReference">
            {{ $t('discount.edit.fields.withReference') }}
          </b-form-checkbox>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">{{ $t('discount.edit.availability') }}</h5>

        <b-container fluid class="content p-4">
          <div class="mb-md-4" v-loading="pending">
            <b-form-checkbox class="ml-0 mb-4" v-model="availableAllTime" @change="selectAll">
              {{ $t('employeeCreate.availability.form.selectAllTime') }}
            </b-form-checkbox>

            <template v-if="shifts.length">
              <div class="availability-grid" style="overflow-x: auto;">
                <b-row class="mb-4" :style="'width:' + (180 * shifts.length + 120) + 'px'">
                  <b-col style="width: 120px;" class="p0"></b-col>
                  <b-col class="p-0" v-for="shift of shifts" :key="shift.id" style="width: 180px;">
                    <b-button class="w-100 font-weight-normal btn-white" :variant="'light'">
                      {{ shift.name }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row
                  v-for="dayNumber in [0, 1, 2, 3, 4, 5, 6]"
                  :key="dayNumber"
                  class="mb-4"
                  :style="'width:' + (180 * shifts.length + 120) + 'px'"
                >
                  <b-col style="width: 120px;">
                    {{ $t(`employeeCreate.availability.dayOfWeek.${dayNumber}`) }}
                  </b-col>
                  <b-col class="p-0 day" v-for="shift of shifts" :key="shift.id" style="width: 180px;">
                    <b-button
                      class="w-100 font-weight-normal"
                      @click="toggle(shift.id, dayNumber)"
                      :variant="availabilityForm[shift.id][dayNumber] ? 'success' : 'light'"
                    >
                      {{
                        availabilityForm[shift.id][dayNumber]
                          ? $t('employeeCreate.availability.form.available')
                          : $t('employeeCreate.availability.form.notAvailable')
                      }}
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </template>
          </div>
        </b-container>
      </b-tab>
      <b-tab
        :title="$t('discount.edit.tabs.couponItems')"
        :disabled="!canContinue"
        title-item-class="col-sm-12 col-md-auto p-0 m-0"
      >
        <h5 class="pt-4 px-4 pb-2">{{ $t('discount.edit.itemList') }}</h5>
        <b-container fluid class="content p-4">
          <b-button
            :disabled="pending"
            variant="outline-primary"
            @click="discountCouponEdit = { itemId: null, couponValue: '0.00' }"
            v-b-toggle.itemForm-sidebar
          >
            <b-icon icon="plus" aria-hidden="true" scale="1.4"></b-icon>
            {{ $t('discount.edit.btns.addItem') }}
          </b-button>
          <b-table
            class="mt-5"
            striped
            bordered
            hover
            show-empty
            fixed
            primary-key="id"
            :empty-text="$t('employee.empty')"
            :empty-filtered-text="$t('employee.empty')"
            :busy="loading"
            :fields="fields"
            head-variant="info"
            :items="(itemProfile.discountCoupons || []).filter(i => !i.delete)"
            responsive="sm"
            ref="table"
            sticky-header
          >
            <template #empty="scope">
              <div class="p-2">{{ scope.emptyText }}</div>
            </template>
            <template #emptyfiltered="scope">
              <div class="p-2">{{ scope.emptyFilteredText }}</div>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner variant="primary" class="align-middle p-2 m-4"></b-spinner>
              </div>
            </template>
            <template #cell(index)="scope">
              {{ scope.index + 1 }}
            </template>
            <template #cell(couponValue)="scope">
              {{ scope.item.couponValue.toFixed(2) }}
            </template>
            <template #cell(edit)="scope">
              <b-button
                variant="link"
                class="p-0"
                @click="onEditItem(scope.item, scope.index)"
                v-b-toggle.itemForm-sidebar
              >
                <b-icon icon="pencil-fill" aria-label="Edit" class="row-action text-primary"></b-icon>
                {{ $t('employee.list.table.edit') }}
              </b-button>
            </template>
            <template #cell(delete)="scope">
              <b-button variant="link text-danger" @click="deleteEntity = scope.item">
                <b-icon icon="trash-fill" aria-label="Delete" class="row-action text-danger"></b-icon>
                {{ $t('mealPlan.delete') }}
              </b-button>
            </template>
          </b-table>
        </b-container>
      </b-tab>
    </b-tabs>
    <discount-item-sidebar
      v-if="discountCouponEdit"
      :items="items"
      :discount-coupon.sync="discountCouponEdit"
      :pending.sync="pending"
      @onCancel="onClickCancel"
      @onAdd="onClickAdd"
    ></discount-item-sidebar>
    <employee-delete-modal
      :title="$t('discount.edit.list.delete.confirm.title', { name: deleteEntity && deleteEntity.item.name })"
      :body="$t('discount.edit.list.delete.confirm.body', { name: deleteEntity && deleteEntity.item.name })"
      :show="!!deleteEntity"
      @onDelete="onDeleteItem(deleteEntity)"
      @onCancel="deleteEntity = null"
    ></employee-delete-modal>
    <b-modal
      v-model="showDiscardChanges"
      :title="$t('discardChange.title')"
      :static="true"
      :centered="true"
      :hide-header-close="true"
      :footer-bg-variant="'light'"
      :footer-text-variant="'dark'"
      @hidden="$emit('onCancel', true)"
    >
      {{ $t('discardChange.body') }}
      <template #modal-footer>
        <b-container fluid>
          <div class="w-100">
            <b-button variant="primary" class="float-right" @click="showDiscardChanges = false">
              {{ $t('discardChange.continue') }}
            </b-button>
            <b-button
              variant="link"
              class="float-right ml-2"
              @click="
                showDiscardChanges = false;
                handleCancel(true);
              "
            >
              {{ $t('discardChange.discard') }}
            </b-button>
          </div>
        </b-container>
      </template>
    </b-modal>
  </vel-backoffice-page>
</template>

<script>
import DiscountFooter from './components/DiscountFooter';
import DiscountHeader from './components/DiscountHeader';
import DiscountItemSidebar from './components/DiscountItemSidebar';
import VelBackofficePage from '@/components/page/VelBackofficePage';
import cloneDeep from 'lodash.clonedeep';
import discountsService, { patchDiscount, postDiscount } from '@/services/discounts.service';
import { getToken } from '@/helpers/token.helper';
import itemsService from '@/services/items.service';
import taxesService from '@/services/taxes.service';
import divisionsService from '@/services/divisions.service';
import locationService from '@/services/locations.service';
import Multiselect from 'vue-multiselect';
import { getTimeAttendanceShifts } from '@/services/timeAttendances';
import { hasWeekdayEnabled, toggleWeekDayFromSchedule } from '@/helpers/date.helpers';
import { getModes } from '@/services/modes';
import VelIcon from '@/components/icon/VelIcon';
import faker from 'faker';
import EmployeeDeleteModal from '@/private/components/employees/components/EmployeeDeleteModal';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataLocationsStore } from '@/stores/data/locations.module';

export default {
  name: 'DiscountCreatePage',
  components: {
    VelBackofficePage,
    DiscountFooter,
    DiscountHeader,
    Multiselect,
    DiscountItemSidebar,
    VelIcon,
    EmployeeDeleteModal
  },
  props: {},
  methods: {
    formatAmount(value) {
      return Number(value).toFixed(2);
    },
    toast(msg = 'toast.update', value = {}, type = 'success') {
      this.$toast.open({
        message: `${this.$t(msg, value)}`,
        position: 'bottom',
        type,
        queue: false,
        duration: 6000,
        dismissible: true
      });
    },
    onEditItem(item, index) {
      this.discountCouponEdit = { ...item, editIndex: index, couponValue: item.couponValue.toFixed(2) };
    },
    onDeleteItem(item) {
      const index = this.itemProfile.discountCoupons.findIndex(
        i => i && i.itemId === item.itemId && i.item.couponValue === item.item.couponValue
      );
      if (!item.id) {
        delete this.itemProfile.discountCoupons[index];
      } else {
        this.itemProfile.discountCoupons[index].delete = true;
      }

      this.deleteEntity = null;
      this.$forceUpdate();
    },
    onClickCancel() {
      this.showDiscardChanges = true;
    },
    onClickAdd(item) {
      if (item.editIndex >= 0) {
        const index = this.itemProfile.discountCoupons.findIndex(
          (i, idx) => i && i.itemId === item.itemId && idx === item.editIndex
        );
        this.itemProfile.discountCoupons[index] = item;
        this.itemProfile = cloneDeep(this.itemProfile);
      } else {
        !this.itemProfile.discountCoupons
          ? (this.itemProfile.discountCoupons = [item])
          : this.itemProfile.discountCoupons.push(item);
      }

      this.discountCouponEdit = null;
      this.$forceUpdate();
    },
    onDivisionChange(value) {
      this.itemProfile.discountDivisions = value.map(v => ({
        divisionId: v.id,
        division: {
          name: (v.division && v.division.name) || v.name || '--'
        },
        value: v.value || 0,
        delete: false
      }));
    },
    async updateItemProfile() {
      this.pending = true;
      this.submited = true;
      this.isError = false;
      try {
        if (this.itemProfile.id) {
          await discountsService.patchDiscount(this.itemProfile.id, this.itemProfile);
          this.toast('toast.discountUpdate', this.itemProfile);
        } else {
          await discountsService.postDiscount({
            ...this.itemProfile
          });

          this.toast('toast.discountCreate', this.itemProfile);
        }

        await this.$router.replace({ name: 'discounts' }).catch(e => e);
      } catch (e) {
        this.toast('toast.error', this.itemProfile, 'error');
        console.error(e.message);
        this.isError = true;
      } finally {
        this.pending = false;
      }
      return null;
    },
    handleCancel(force = false) {
      if (JSON.stringify(this.itemProfileOrig) !== JSON.stringify(this.itemProfile) && !force) {
        this.showDiscardChanges = true;
      } else {
        if (this.discountCouponEdit) {
          this.discountCouponEdit = null;
          this.showDiscardChanges = false;
        } else {
          this.$router.push({ name: 'discounts' });
        }
      }
    },
    toggle(shiftId, dayNumber) {
      this.availabilityForm = {
        ...this.availabilityForm,
        [shiftId]: {
          ...this.availabilityForm[shiftId],
          [dayNumber]: !this.availabilityForm[shiftId][dayNumber]
        }
      };

      this.itemProfile.discountSchedules = Object.keys(this.availabilityForm).map(key => ({
        modeId: key,
        schedule: Object.keys(this.availabilityForm[key]).reduce((a, b) => {
          let c = a;
          if (this.availabilityForm[key][b]) {
            c = toggleWeekDayFromSchedule(a, Number(b) + 1);
          }

          return c;
        }, 0)
      }));

      this.availableAllTime =
        (this.itemProfile.discountSchedules || []).length ===
        (this.itemProfile.discountSchedules || []).filter(d => d.schedule === 127).length;
    },

    selectAll(x) {
      this.availabilityForm = Object.keys(this.availabilityForm).reduce((a, b) => {
        a[b] = Object.keys(this.availabilityForm[b]).reduce((c, d) => {
          c[d] = x;
          return c;
        }, {});
        return a;
      }, {});
      this.itemProfile.discountSchedules = Object.keys(this.availabilityForm).map(key => ({
        modeId: key,
        schedule: Object.keys(this.availabilityForm[key]).reduce((a, b) => {
          let c = a;
          if (this.availabilityForm[key][b]) {
            c = toggleWeekDayFromSchedule(a, Number(b) + 1);
          }

          return c;
        }, 0)
      }));
    }
  },
  data() {
    return {
      deleteEntity: null,
      loading: true,
      isError: false,
      pending: false,
      locationId: null,
      isActive: true,
      activeTab: 0,
      availableAllTime: false,
      showActive: true,
      taxCategories: [],
      divisions: [],
      shifts: [],
      availabilityForm: {},
      items: [],
      showDiscardChanges: false,
      discountCouponEdit: null,
      itemProfileOrig: {
        name: '',
        alternativeName: '',
        type: 0,
        amount: 0,
        taxesOnInvoice: false,
        taxesOnInvoiceType: 1,
        removeTaxCategoryId: null,
        discountDivisions: [],
        discountCoupons: [],
        discountSchedules: [],
        withReference: true,
        qualityDiscount: true,
        unrestrictedAccess: true,
        cashReconciliation: true,
        cashReconciliationType: 0,
        licenceNumber: null,
        isActive: true
      },
      itemProfile: {
        name: '',
        alternativeName: '',
        type: 0,
        amount: 0,
        taxesOnInvoice: false,
        taxesOnInvoiceType: 1,
        removeTaxCategoryId: null,
        discountDivisions: [],
        discountCoupons: [],
        discountSchedules: [],
        withReference: true,
        qualityDiscount: true,
        unrestrictedAccess: true,
        cashReconciliation: true,
        cashReconciliationType: 0,
        licenceNumber: null,
        isActive: true
      },
      submited: false
    };
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById']),
    selectedDivisionsId() {
      return (this.itemProfile.discountDivisions || []).map(x => ({ ...x, id: x.divisionId }));
    },
    canSave() {
      if (!this.itemProfile.name || this.itemProfile.name === '') {
        return false;
      }
      return JSON.stringify(this.itemProfileOrig) !== JSON.stringify(this.itemProfile);
    },
    canContinue() {
      if (!this.itemProfile.name || this.itemProfile.name === '') {
        return false;
      }
      return true;
    },
    fields() {
      return [
        {
          key: 'item.remoteId',
          label: this.$t('discount.edit.list.table.headers.itemNumber'),
          class: 'name',
          sortable: true
        },
        {
          key: 'item.name',
          label: this.$t('discount.edit.list.table.headers.name'),
          class: 'name',
          sortable: true
        },
        {
          key: 'couponValue',
          label: this.$t('discount.edit.list.table.headers.couponValue'),
          class: 'name',
          sortable: true
        },
        {
          key: 'edit',
          label: '',
          class: 'edit'
        },
        {
          key: 'delete',
          label: '',
          class: 'edit'
        }
      ];
    },
    discountTypes() {
      return [
        { id: 0, name: this.$t('discount.edit.discountTypes.fixed_amount') },
        { id: 1, name: this.$t('discount.edit.discountTypes.open_amount') },
        { id: 2, name: this.$t('discount.edit.discountTypes.amount_per_coupon') },
        { id: 3, name: this.$t('discount.edit.discountTypes.fixed_percentage') },
        { id: 4, name: this.$t('discount.edit.discountTypes.open_percentage') },
        { id: 5, name: this.$t('discount.edit.discountTypes.fixed_percentage_per_division') },
        { id: 6, name: this.$t('discount.edit.discountTypes.fixed_percentage_per_item') },
        { id: 7, name: this.$t('discount.edit.discountTypes.fixed_percentage_per_item_per_division') },
        { id: 8, name: this.$t('discount.edit.discountTypes.open_capped_percentage_per_division') },
        { id: 9, name: this.$t('discount.edit.discountTypes.fixed_capped_percentage_per_division') }
      ];
    },
    cashReconciliationTypes() {
      return [
        { id: 1, name: this.$t('discount.edit.cashReconciliationTypes.courtesy') },
        { id: 2, name: this.$t('discount.edit.cashReconciliationTypes.employee_100') },
        { id: 3, name: this.$t('discount.edit.cashReconciliationTypes.employee_50') },
        { id: 4, name: this.$t('discount.edit.cashReconciliationTypes.promotion') },
        { id: 5, name: this.$t('discount.edit.cashReconciliationTypes.refunding') }
      ];
    }
  },
  async mounted() {
    try {
      if (this.$route.params.id) {
        this.itemProfile = await discountsService.getDiscountById(getToken(), this.$route.params.id);
        this.itemProfileOrig = cloneDeep(this.itemProfile);
      } else {
        this.itemProfile.licenceNumber = (
          this.getLocationsById(this.$route.query.locationIds) ||
          (await locationService.getLocation(getToken(), this.$route.query.locationIds))
        ).licenceNumber;
      }
      this.taxCategories = await taxesService
        .getTaxCategories(getToken(), {
          locationId: this.$route.query.locationIds
        })
        .then(x => x.content || []);
      this.divisions = await divisionsService.getDivisions(getToken(), { locationId: this.$route.query.locationIds });
      this.items = await itemsService.getItems(getToken(), { locationId: this.$route.query.locationIds });

      const results = await getModes({ locationId: this.$route.query.locationIds });
      this.shifts = (results || []).sort((a, b) => a.name.localeCompare(b.name));

      for (const s of this.shifts) {
        if (!this.availabilityForm[s.id]) {
          this.availabilityForm[s.id] = { 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false };
        }
      }

      for (const a of this.itemProfile.discountSchedules || []) {
        for (let i = 1; i < 8; i++) {
          if (hasWeekdayEnabled(a.schedule, i) || this.availableAllTime) {
            this.availabilityForm[a.modeId][i - 1] = true;
          }
        }
      }
      this.items = await itemsService.getItems(getToken(), { locationId: this.$route.query.locationIds });
      this.availableAllTime =
        (this.itemProfile.discountSchedules || []).length > 0 &&
        (this.itemProfile.discountSchedules || []).length ===
          (this.itemProfile.discountSchedules || []).filter(d => d.value === 127).length;

      // this.itemProfileOrig = cloneDeep(this.itemProfile);
      this.loading = false;
    } catch (e) {
      console.error(e);
      this.isError = true;
      this.loading = false;
    }
    return true;
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

h5 {
  background-color: #f5f5f5;
  color: #2a3142;
  letter-spacing: 1px;
}

.backoffice-page {
  background-color: #f5f5f5;
}

.content {
  background-color: white;
}

/deep/ {
  label,
  .form-text {
    margin-bottom: 0;
    padding-left: 1rem;
  }
}

/deep/ .form-text {
  min-height: 18px;
}

input,
select {
  + .form-text + .invalid-feedback {
    visibility: hidden;
    display: none !important;
  }
}

input,
select {
  + .form-text {
    display: block !important;
  }
}

input[aria-invalid='true'],
select[aria-invalid='true'] {
  + .form-text + .invalid-feedback {
    visibility: visible;
    display: block !important;
  }
}

input[aria-invalid='true'],
select[aria-invalid='true'] {
  + .form-text {
    display: none !important;
  }
}

.invalid-feedback {
  display: block !important;
}

.vel-alert {
  align-self: center;
  max-width: fit-content;
}

*:focus {
  box-shadow: none !important;
  outline: none !important;
}

.multiselect {
  border: none;
  margin-top: 4px;
  width: 100%;

  @include breakpoint(medium) {
    margin-top: 0;
    max-width: 300px !important;
  }
}

/deep/ .multiselect__option {
  border-left: 3px solid white;
  border-right: 3px solid white;
  font-family: Roboto, sans-serif;
  min-height: 32px;
  padding: 9px 16px;
}

/deep/ .multiselect__option--highlight {
  color: #444444;
  background-color: #00a7e115;
}

/deep/ .multiselect__option--selected {
  background-color: inherit;
}

/deep/ .multiselect__placeholder {
  display: none;
}

/deep/ .multiselect__select {
  font-family: element-icons !important;
  font-size: 14px;
  line-height: 38px;
  top: 1px;
  transform: rotateZ(180deg);
  transition: transform 0.3s;
  width: 25px;
}

/deep/ .multiselect__select:before {
  border: 0 !important;
  bottom: 2px;
  color: #c0c4cc;
  content: '\e6e1' !important;
  margin-top: unset !important;
  right: unset !important;
  top: unset;
}

/deep/ .multiselect--disabled .multiselect__single,
/deep/ .multiselect--disabled .multiselect__tags {
  background-color: #f6f7fa !important;
  color: #c0c4cc !important;
}

/deep/ .multiselect--disabled .multiselect__select {
  background-color: transparent;
}
/deep/ .multiselect__option--selected {
  background-color: #00a7e1 !important;
  color: white !important;
}

/deep/ .nav-tabs {
  background-color: white;
  margin-left: 0;

  .nav-link {
    color: #2a3142;
    font-size: 0.875rem;
    border-radius: 0 !important;

    svg {
      margin-bottom: 0.2rem;
    }
  }

  .text-success {
    color: #1a6a0f !important;
  }

  .active {
    background-color: #31a8e1 !important;
    border-color: #31a8e1;
    border-radius: 0 !important;
    color: white !important;
  }
}

/deep/ {
  .tab-content {
    border-top: 4px solid #f5f5f5;
  }

  .nav-link {
    padding: 1rem 2rem 1rem 1rem;

    &.active {
      transition: background-color 100ms linear;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.4);
    }
  }
}

/deep/ .b-table {
  max-width: 1200px;
  min-width: 1000px;
}

/deep/ .edit {
  width: 115px;
}

/deep/ .form-group legend {
  padding-bottom: 0 !important;
}

/deep/ .thead-info {
  background-color: #eff9fd;
  font-weight: bold;
}

.el-tag {
  color: #444;
}

.availability-grid .btn-white {
  background-color: white;
  border: 1px solid #ccc;
}
</style>
