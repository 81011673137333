<template>
  <vel-page
    :multiple-locations="false"
    :enable-date-range="true"
    :show-selector-button="true"
    class="employee__report"
    v-bind:class="{ modal: showConfig }"
    :showCrumbs="false"
    :enable-currency="false"
    :enable-toolbar="true"
    :enable-all-locations="false"
    :enable-groups="false"
    :generatedTime="generatedTime"
  >
    <template v-slot:export>
      <vel-button
          :disabled="areSalesLoading"
          :loading="generating"
          type="primary"
          @click="exportXLSX"
          :icon="isMediumUp ? 'file-excel' : undefined"
          class="vel-button"
      >
        <vel-icon v-if="isMediumDown" name="file-download" />
        {{ isMediumUp ? $t('table.tableExport.button.value') : '' }}
      </vel-button>
    </template>
    <template v-slot:title>
      {{ $t('pages.reports.employeesSales') }}
    </template>
    <vel-modal v-if="showConfig" dismissable @dismiss="showConfig = false">
      <template v-slot:header>
        {{ $t('editors.customize_link') }}
      </template>
      <config-employee-report-display @update="$forceUpdate()"></config-employee-report-display>
    </vel-modal>
    <template v-slot:toolbarMenu>
      <vel-elink type="info" @click="showConfig = true">{{ $t('editors.customize_link') }}</vel-elink>
    </template>

    <vel-card class="report__chart" v-if="areSalesAvailable && employeesSalesChartData.length > 1">
      <apexchart type="bar" :height="300" :options="chartOptions" :series="series" />
    </vel-card>

    <vel-card class="report__tree" style="width: 100%;">
      <employee-sales-report
        :sales="getSalesTree"
        :areSalesLoading="areSalesLoading"
        :multipleLocation="false"
        :hiddenColumns="hiddenColumns"
        @input="handleInput"
        style="width: 100%;"
      />
    </vel-card>
  </vel-page>
</template>

<script>
import ConfigEmployeeReportDisplay from '@/private/components/config/appearance/employee-report/ConfigEmployeeReportDisplay';
import EmployeeSalesReport from '@/private/components/sales/employee/EmployeeSalesReport.vue';
import GeneratedTime from '@/mixins/generated-time-mixin';
import SelectableDateRangeMixin from '@/mixins/selectable-date-range-mixin';
import SelectableLocationMixin from '@/mixins/selectable-location-mixin';
import VelCard from '@/components/card/VelCard';
import VelElink from '@/components/elink/VelElink';
import VelModal from '@/components/modal/VelModal';
import VelPage from '@/components/page/VelPage';
import VueApexCharts from 'vue-apexcharts';
import formatMoney from '@/filters/format-money';
import pickBy from 'lodash.pickby';
import {useUIGroupSelector} from "@/stores/ui/group-selector.module";
import { mapState as mapPiniaState } from 'pinia';
import {useUILocationSelector} from "@/stores/ui/location-selector.module";
import {mapActions as mapPiniaActions} from "pinia/dist/pinia";
import {useDataLocationsStore} from "@/stores/data/locations.module";
import {useDataSalesIndicators} from "@/stores/data/sales/indicators.module";
import {useDataSalesEmployees} from "@/stores/data/sales/employees.module";
import {useDataUsersUserPermissions} from "@/stores/data/users/user-permissions.module";
import {useDataConfig} from "@/stores/data/config.module";
import {useDataGroups} from "@/stores/data/groups.module";
import {useDataSales} from "@/stores/data/sales.module";
import VelIcon from "@/components/icon/VelIcon.vue";
import VelButton from "@/components/button/VelButton.vue";
import DeviceMixin from "@/mixins/device-mixin";
import router from "@/router";
import salesService from "@/services/sales.service";
import {DateTime} from "luxon";
import {useUIDateRangeSelector} from "@/stores/ui/date-range-selector.module";

const EMPLOYEES_TO_DISPLAY_IN_CHART = 10;

export default {
  name: 'EmployeeSalesReportPage',
  components: {
    VelButton, VelIcon,
    apexchart: VueApexCharts,
    ConfigEmployeeReportDisplay,
    EmployeeSalesReport,
    VelCard,
    VelPage,
    VelElink,
    VelModal
  },
  mixins: [SelectableDateRangeMixin, SelectableLocationMixin, GeneratedTime, DeviceMixin],
  data() {
    return {
      showConfig: false,
      generating: false
    };
  },
  updater: {
    interval: 5 * 60 * 1000
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, ['locations']),
    ...mapPiniaState(useDataSalesIndicators, ['indicators']),
    ...mapPiniaState(useDataSalesEmployees, ['filter']),
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useUILocationSelector, {
      locations: 'locations',
      hasLocationsError: 'hasError',
      locationsLoading: 'isLoading',
      getLocationSelectorSelected: 'getLocationSelectorSelected'
    }),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById', 'getLocationsIsLoading']),
    ...mapPiniaState(useDataSales, {
      sales: 'sales',
      areSalesLoading: 'areSalesLoading'
    }),
    ...mapPiniaState(useDataSalesEmployees, ['getSalesTree', 'areSalesLoading', 'chartData']),
    ...mapPiniaState(useDataUsersUserPermissions, ['isUserAdmin']),
    ...mapPiniaState(useUIGroupSelector, ['getGroupSelectorSelected']),
    ...mapPiniaState(useDataGroups, ['getGroupsRows']),
    ...mapPiniaState(useUIDateRangeSelector, ['getDateSelectorSelectedRange']),
    ...mapPiniaState(useUILocationSelector, {
      locationCount: 'getLocationSelectorSelectedMultipleLength',
      locationListSelected: 'getLocationSelectorSelectedMultipleEntities',
      locationIds: 'getLocationSelectorSelectedMultipleIds',
      firstLocation: 'getFirstLocation',
    }),
    currencyId() {
      return (this.areSalesAvailable && this.getSalesTree[0].currency) || '';
    },
    isPageLoading() {
      return this.getLocationsIsLoading;
    },
    areSalesAvailable() {
      return !!this.getSalesTree && this.getSalesTree.length > 0;
    },
    employeesSalesChartData() {
      const count = this.topCount || EMPLOYEES_TO_DISPLAY_IN_CHART;

      return this.chartData(count).map(sale => sale.salesAmount);
    },
    employeesSalesChartCategories() {
      const count = this.topCount || EMPLOYEES_TO_DISPLAY_IN_CHART;

      return this.chartData(count).map(sale => sale.label);
    },
    chartOptions() {
      return {
        chart: {
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: 0
        },
        fill: {
          type: 'fill',
          opacity: 0.85
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: this.$t('sales.employeesSalesChart.title', { limit: this.get.charts_size_limitation.value }),
          align: 'left'
        },
        labels: this.employeesSalesChartCategories.slice(0, this.get.charts_size_limitation.value),
        xaxis: {
          type: 'category',
          categories: this.employeesSalesChartCategories.slice(0, this.get.charts_size_limitation.value),
          labels: {
            hideOverlappingLabels: false
          }
        },
        yaxis: {
          labels: {
            formatter: val => formatMoney(val, this.currencyId)
          },
          opposite: false
        }
      };
    },
    series() {
      const employeesSales = {
        name: this.$t('sales.top10EmployeesChart.sales'),
        type: 'column',
        data: this.employeesSalesChartData.slice(0, this.get.charts_size_limitation.value)
      };

      return [employeesSales];
    },
    displayConfig() {
      return this.get;
    },
    hiddenColumns() {
      return {
        quantity: this.displayConfig.employees_column_quantity,
        salesPercent: this.displayConfig.employees_column_sales_percent,
        salesPercentOverall: this.displayConfig.employees_column_sales_percent_overall,
        salesAmount: this.displayConfig.employees_column_sales_amount
      };
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    ...mapPiniaActions(useDataSalesIndicators, ['getIndicators']),
    ...mapPiniaActions(useDataSalesEmployees, ['getSalesByEmployeeWithLevels', 'setFilter', 'reset']),
    fetchSales() {
      if (this.mutableLocationId) {
        this.getSalesByEmployeeWithLevels(
          pickBy(
            { from: this.mutableRange[0], to: this.mutableRange[1], locationIds: this.mutableLocationId },
            a => !!a
          )
        );
      }
    },
    async exportXLSX() {
      this.generating = true;
      const from = this.getDateSelectorSelectedRange[0];
      const to = this.getDateSelectorSelectedRange[1];

      const routeQuery = {
        locationId: this.getLocationSelectorSelected,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO()
      };

      await salesService.downloadSalesByEmployeeXlsx(routeQuery).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    },
    async fetchIndicators() {
      await this.getIndicators({
        includeDiscounts: true
      });
    },
    refresh() {
      this.fetchSales();
    },
    handlePeriodicUpdate() {
      if (!this.isUserAdmin) {
        this.updater = setTimeout(this.refresh, this.$options.updater.interval);
      }
    },
    handleInput(value) {
      this.setFilter(value);
    },
    getCurrentGroupName() {
      const selectedGroup = (this.getGroupsRows || []).filter(group => group.id === this.getGroupSelectorSelected);
      if (!selectedGroup.length) {
        return null;
      }
      return `${this.$t('exports.groupLabel')} ${selectedGroup[0].name}`;
    }
  },
  mounted() {
    this.fetchIndicators();
    this.fetchSales();
    this.handlePeriodicUpdate();
    // this.generateTimeAt('data/sales/employees/getSalesSuccess');
  },
  beforeDestroy() {
    this.gen(false);
    clearTimeout(this.updater);
    this.reset();
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

$charts-empty-text-color: $shuttle-gray;
$charts-title-text-color: $river-bed;

.report__chart {
  font-family: $base-font-family;

  > div {
    margin: -8px;
  }

  .chart__toolbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .chart__empty-state {
    color: $charts-empty-text-color;
  }
}

.apexcharts-title-text {
  color: $charts-title-text-color;
  font-size: rem-calc(13);
  font-weight: 700;
  text-transform: uppercase;
  @include breakpoint(medium) {
    font-size: rem-calc(15);
  }
}

.apexcharts-xaxistooltip {
  overflow: visible;
}

.apexcharts-xaxistooltip-text {
  /* stylelint-disable */
  min-width: 30px !important;
}

.report__tree {
  display: flex;
  flex-shrink: 0;
  overflow-x: scroll;

  @include breakpoint(large) {
    overflow-x: hidden;
  }
}

/deep/ .vel-page__toolbar {
  margin-bottom: 1.25rem;

  .report__location-selector {
    width: 100%;

    @include breakpoint(medium) {
      max-width: 380px;
    }
  }

  .report__date-range-selector {
    margin-top: 4px;
    max-width: 100%;
    min-width: 190px;
    width: 100%;

    @include breakpoint(medium) {
      margin-top: 0;
      max-width: 205px;
      width: initial;
    }
  }
}

.vel-button {
  @include noPrint;
  background-color: $mountain-meadow;
  border-color: $mountain-meadow;
  direction: rtl;

  &:hover {
    background-color: mix(black, $mountain-meadow, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}

// hack - fix stacking order on safari.
.modal /deep/ .vel-page__content {
  overflow-x: visible;
  overflow-y: visible;
}
</style>
