<template>
  <indicator
    color="blue"
    :amount="amount"
    :previous-amount="previousAmount"
    :loading="loading"
    iconName="no-sales"
    iconColor="blue"
  >
    <template v-slot:title>
      {{ $t('dashboard.noSales.title') }}
    </template>
    <template v-slot:tooltip>
      {{ $t('dashboard.noSales.description') }}
    </template>
    <template v-slot:value>
      {{ amount }}
    </template>
  </indicator>
</template>

<script>
import Indicator from '@/components/indicator/Indicator.vue';

export default {
  name: 'no-sales',
  components: {
    Indicator
  },
  props: {
    amount: {
      default: 0
    },
    previousAmount: {
      default: null
    },
    loading: {
      default: true
    }
  }
};
</script>
