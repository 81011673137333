import router, { PATH_HOME, PATH_LOGIN, PATH_USERS } from './router';

const toHome = () => router.push(PATH_HOME);

const toUser = userId => router.push({ name: 'user', params: { userId } });

const toUsers = () => router.push(PATH_USERS);

const toLogin = () => router.push(PATH_LOGIN);

export default {
  toLogin,
  toHome,
  toUser,
  toUsers
};
