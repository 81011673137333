<template>
  <div>
    <h5 class="px-4">{{ $t('coursing.form.generalConfig') }}</h5>
    <div class="bg-white p-4 mt-2">
      <b-form-group
        :label="$t('coursing.form.name.label')"
        :description="$t('coursing.required')"
        label-for="name"
        class="mr-5 w-100 mb-2 mb-md-0"
      >
        <b-form-input
          id="name"
          v-model.trim="coursing.name"
          maxlength="50"
          aria-describedby="input-live-help input-live-feedback"
          :class="{ required: !coursing || !coursing.name || coursing.name === '' }"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('coursing.form.automaticDelayMinutes.label')"
        label-for="automaticDelayMinutes"
        class="mr-5 w-100 mb-2 mb-md-0 mt-3"
      >
        <b-form-input
          id="automaticDelayMinutes"
          number
          v-model.number="coursing.automaticDelayMinutes"
          maxlength="50"
        ></b-form-input>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'meal-plan-form',
  model: {},
  props: {
    licenceNumber: {
      type: Number,
      default: null
    },
    coursing: {
      type: Object,
      default: () => ({
        name: '',
        automaticDelayMinutes: 0
      })
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      coursingOrig: null
    };
  },
  computed: {
    enableSaveChanges() {
      return (
        this.coursing &&
        this.coursing.name &&
        this.coursing.name !== '' &&
        JSON.stringify(this.coursingOrig) !== JSON.stringify(this.coursing)
      );
    },
    haveChanges() {
      return JSON.stringify(this.coursingOrig) !== JSON.stringify(this.coursing);
    }
  },
  methods: {
    reset(value = null) {
      this.form = cloneDeep(
        value || {
          name: ''
        }
      );
    }
  },
  mounted() {
    this.coursingOrig = cloneDeep(this.coursing);
  }
};
</script>
<style lang="scss" scoped>
/deep/ {
  label,
  .form-text {
    margin-bottom: 0;
    padding-left: 1rem;
  }
}

/deep/ .form-text {
  min-height: 18px;
}

.form-group {
  max-width: 328px;
}
</style>
