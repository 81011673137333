<template>
  <vel-card class="indicator_card" :class="classes">
    <component class="indicator__title" :is="titleLevel" v-if="$slots.title">
      <slot name="title" />
    </component>
    <div class="indicator">
      <div class="indicator__content">
        <span class="indicator__values">
          <div class="quantity">
            <div>{{ $t('indicatorQuantityAmount.labels.quantity') }}</div>
            <div>{{ formatQty(quantity) }}</div>
          </div>
          <div class="amount">
            <div>{{ $t('indicatorQuantityAmount.labels.amount') }}</div>
            <vel-amount :currency="currency" :amount="amount" />
          </div>
        </span>
        <span class="indicator__percent" v-if="previousAmount !== null && showPercent && quantity">
          <!--<span>{{ percent.toFixed(2).replace('-', '') }}%</span>-->
          <!--
          <span v-if="amount !== 0 && (amount || 0) > (previousAmount || 0)" class="arrow up">⭡</span>
          <span v-if="amount !== 0 && (amount || 0) < (previousAmount || 0)" class="arrow down">⭣</span>
          -->
        </span>
      </div>
    </div>
  </vel-card>
</template>

<script>
import { Tooltip } from 'element-ui';
import VelAmount from '@/components/amount/VelAmount';
import VelCard from '@/components/card/VelCard';
import VelIcon from '@/components/icon/VelIcon';
import VelSpinner from '@/components/spinner/VelSpinner';
import VelTooltip from '@/components/tooltip/VelTooltip';
import formatQuantity from '@/filters/format-quantity';

export default {
  name: 'indicator-quantity-amount',
  components: {
    VelAmount,
    VelCard,
    VelIcon,
    VelSpinner,
    VelTooltip,
    Tooltip
  },
  props: {
    titleLevel: {
      type: String,
      default: 'h3'
    },
    loading: {
      type: Boolean,
      default: false
    },
    showPercent: {
      type: Boolean,
      default: true
    },
    currency: {
      type: String,
      default: ''
    },
    previousAmount: {
      type: Number,
      default: null
    },
    quantity: {
      type: Number,
      default: 0
    },
    percent: {
      type: Number,
      default: 0
    },
    amount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    classes() {
      const { name } = this.$options;
      return [
        `${name}`,
        {
          [`${name}_loading`]: this.loading
        }
      ];
    }
  },
  methods: {
    formatQty(qty) {
      return formatQuantity(qty);
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

$indicator-min-height: rem-calc(100);

// title
$indicator__title-font-color: '#000';
$indicator__title-font-size: rem-calc(16);
$indicator__title-font-weight: $font-bold;

// value
$indicator__value-line-height: 1.8;
$indicator__value-font-size: rem-calc(24);
$indicator__value-font-weight: $font-semi-bold;
$indicator__value-font-color_black: $river-bed;
$indicator__value-font-color_green: $persian-green;
$indicator__value-font-color_red: $brilliant-rose;
$indicator__value-font-color_blue: $primary-color;
$indicator__icon--white-font-color: white;
$indicator__icon--green--background-color: $mountain-meadow;
$indicator__icon--pink--background-color: $persian-pink;
$indicator__icon--black--background-color: $raven;
$indicator__icon--blue--background-color: $primary-color;
$indicator__icon-width: rem-calc(40);
$indicator__icon-height: rem-calc(40);
// loader
$indicator__loader-background-color: white;
$indicator__loader-border-radius: $base-radius * 2;

.vel-card {
  position: relative;

  &.indicator_card {
    padding: 0;
  }

  /deep/ &__body {
    padding: 0;
  }
}

.el-tooltip__popper {
  max-width: 300px;
}

.arrow {
  font-size: 22px;
}

.indicator {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1rem;
  position: relative;

  .el-tooltip {
    color: $indicator__title-font-color;
  }

  &__percent {
    font-size: rem-calc(34);
    padding-right: rem-calc(16);
  }

  &__values {
    display: flex;
    flex-direction: column;
    width: 100%;

    > div {
      display: flex;
      flex-direction: row;

      > div:first-child {
        width: 80px;
      }
    }
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    position: relative;
    width: 100%;
  }

  &_loading {
    cursor: progress;
  }

  &__title {
    align-items: center;
    background-color: #fafafa;
    color: $indicator__title-font-color;
    display: flex;
    flex-basis: 100%;
    font-size: $indicator__title-font-size;
    font-weight: $indicator__title-font-weight;
    height: 45px;
    justify-content: flex-start;
    padding: 0 1rem;
    vertical-align: middle;
  }

  &__loader {
    align-items: center;
    background-color: $indicator__loader-background-color;
    border-radius: $indicator__loader-border-radius;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
</style>
