<template>
  <vel-card :class="classes">
    <div class="indicator">
      <div class="indicator__icon" :data-icon-name="iconName" :class="iconClasses">
        <vel-icon :size="computedIconSize" :name="iconName" v-if="iconName" />
      </div>
      <div class="indicator__content">
        <span class="indicator__value">
          <slot name="value" />
        </span>
        <span class="indicator__percent">
          <span>{{ ((amount && 100 - (previousAmount / amount) * 100) || 0).toFixed(2).replace('-', '') }}%</span>
          <span v-if="amount !== 0 && (amount || 0) > (previousAmount || 0)" class="arrow up pl-1">
            <font-awesome-icon icon="arrow-up" />
          </span>
          <span v-if="amount !== 0 && (amount || 0) < (previousAmount || 0)" class="arrow down pl-1">
            <font-awesome-icon icon="arrow-down" />
          </span>
        </span>
      </div>
      <component class="indicator__title" :is="titleLevel" v-if="$slots.title">
        <slot name="title" />
        <span class="indicator__tooltip" v-if="$slots.tooltip">
          <tooltip
            :content="$slots.tooltip[0].text"
            placement="top"
            effect="light"
            :hide-after="isMediumDown ? 3000 : 0"
          >
            <vel-icon name="question-circle" modifier="on-bright" size="small" />
          </tooltip>
        </span>
      </component>
    </div>
  </vel-card>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import { Tooltip } from 'element-ui';
import VelCard from '@/components/card/VelCard';
import VelIcon from '@/components/icon/VelIcon';
import VelSpinner from '@/components/spinner/VelSpinner';
import VelTooltip from '@/components/tooltip/VelTooltip';

export default {
  name: 'indicator',
  components: {
    VelCard,
    VelIcon,
    VelSpinner,
    VelTooltip,
    Tooltip
  },
  mixins: [DeviceMixin],
  props: {
    titleLevel: {
      type: String,
      default: 'h3'
    },
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'black',
      validate(value) {
        return ['black', 'green', 'red', 'blue'].includes(value);
      }
    },
    iconName: {
      type: String,
      default: null
    },
    iconSize: {
      type: String,
      default: 'large'
    },
    iconColor: {
      type: String,
      default: 'black',
      validate(value) {
        return ['black', 'green', 'pink', 'blue'].includes(value);
      }
    },
    previousAmount: {
      type: Number,
      default: null
    },
    amount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    classes() {
      const { name } = this.$options;
      return [
        `${name}_${this.color}`,
        {
          [`${name}_loading`]: this.loading
        }
      ];
    },
    iconClasses() {
      return [`indicator__icon_${this.iconColor}`];
    },
    computedIconSize() {
      if (this.isMediumDown && this.iconSize === 'large') {
        return 'medium';
      }
      return this.iconSize;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

$indicator-min-height: rem-calc(100);

// title
$indicator__title-font-color: $hit-gray;
$indicator__title-font-size: rem-calc(15);
$indicator__title-font-weight: $font-regular;

// value
$indicator__value-line-height: 1.8;
$indicator__value-font-size: 19px;
$indicator__value-font-weight: $font-semi-bold;
$indicator__value-font-color_black: $river-bed;
$indicator__value-font-color_green: $persian-green;
$indicator__value-font-color_red: $brilliant-rose;
$indicator__value-font-color_blue: $primary-color;
$indicator__icon--white-font-color: white;
$indicator__icon--green--background-color: $mountain-meadow;
$indicator__icon--pink--background-color: $persian-pink;
$indicator__icon--black--background-color: $raven;
$indicator__icon--blue--background-color: $primary-color;
$indicator__icon-width: rem-calc(40);
$indicator__icon-height: rem-calc(40);
// loader
$indicator__loader-background-color: white;
$indicator__loader-border-radius: $base-radius * 2;

.vel-card {
  position: relative;
}

.el-tooltip__popper {
  max-width: 300px;
}

.indicator {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;

  .el-tooltip {
    color: $indicator__title-font-color;
  }

  &_content {
    align-items: flex-end;
    display: flex;
    flex-direction: column-reverse;
    min-height: $indicator-min-height;
    padding: rem-calc(20);
    position: relative;
    text-align: right;
  }

  &_loading {
    cursor: auto;
  }

  &__tooltip {
    align-items: center;
    display: flex;
    padding-left: 4px;
  }

  &__title {
    color: $indicator__title-font-color;
    display: flex;
    flex-basis: 100%;
    font-size: $indicator__title-font-size;
    font-weight: $indicator__title-font-weight;
    justify-content: flex-end;
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__value {
    font-size: $indicator__value-font-size;
    font-weight: $indicator__value-font-weight;
    line-height: $indicator__value-line-height;
    @include monospaced;
  }

  &__percent {
    align-self: center;
    color: #6f6f6f;
    display: flex;
    font-size: 19px;
    font-weight: normal;
    line-height: $indicator__value-line-height;
    @include monospaced;
  }

  &__icon {
    height: $indicator__icon-height;
    left: 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $indicator__icon-width;

    .vel-icon {
      height: $indicator__icon-height;
      width: $indicator__icon-width;
    }
    @include breakpoint(medium) {
      height: 2rem;
      left: 0;
      top: 1.25rem;
      width: 2rem;

      .vel-icon {
        height: 2rem;
        width: 2rem;
      }
    }

    &_green {
      color: $indicator__icon--green--background-color;
    }

    &_pink {
      color: $indicator__icon--pink--background-color;
    }

    &_black {
      color: $indicator__icon--black--background-color;
    }

    &_blue {
      color: $indicator__icon--blue--background-color;
    }
  }

  &_black &__value {
    color: $indicator__value-font-color_black;
  }

  &_green &__value {
    color: $indicator__value-font-color_green;
  }

  &_red &__value {
    color: $indicator__value-font-color_red;
  }

  &_blue &__value {
    color: $indicator__value-font-color_blue;
  }

  &__loader {
    align-items: center;
    background-color: $indicator__loader-background-color;
    border-radius: $indicator__loader-border-radius;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.arrow {
  padding-left: 0.5rem;
}

.up {
  color: green;
}

.down {
  color: red;
}

/deep/ .vel-amount {
  font-size: 19px;
}
</style>
