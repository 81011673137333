<template>
  <vel-page class="bootstrap coupons" :enable-toolbar="true">
    <template v-slot:export>
      <vel-button
        :disabled="areActionsDisabled || !coupons.length"
        :loading="generating"
        type="primary"
        @click="exportXLS"
        :icon="isMediumUp ? 'file-excel' : undefined"
        class="vel-button xlsx-btn"
      >
        <vel-icon v-if="isMediumDown" name="file-download" />
        {{ isMediumUp ? $t('table.tableExport.button.value') : '' }}
      </vel-button>
    </template>
    <template v-slot:title>
      {{ $t('pages.coupons') }}
    </template>

    <div class="coupons__alerts">
      <vel-alert @dismiss="clearGetCouponsError" class="alert" level="error" v-if="hasGetCouponsError">
        {{ $t('coupons.errors.getCoupons') }}
      </vel-alert>

      <vel-alert @dismiss="clearLatestCreatedCoupon" class="alert" level="success" v-if="latestCreatedCoupon">
        {{ $t('coupons.form.success.createCoupon', { name: latestCreatedCoupon.name }) }}
      </vel-alert>

      <vel-alert @dismiss="clearLatestUpdatedCoupon" class="alert" level="success" v-if="latestUpdatedCoupon">
        {{ $t('coupons.form.success.updateCoupon', { name: latestUpdatedCoupon.name }) }}
      </vel-alert>

      <vel-alert @dismiss="clearLatestDeletedCoupon" class="alert" level="success" v-if="latestDeletedCoupon">
        {{ $t('coupons.form.success.deleteCoupon', { name: latestDeletedCoupon.name }) }}
      </vel-alert>

      <vel-alert @dismiss="clearLatestImportedCoupons" class="alert" level="success" v-if="latestImportedCoupons">
        {{ $t('coupons.form.success.importCoupons', { count: latestImportedCoupons.count }) }}
      </vel-alert>

      <vel-alert
        @dismiss="clearLatestBatchDeletedCoupons"
        class="alert"
        level="success"
        v-if="latestBatchDeletedCoupons"
      >
        {{ $t('coupons.form.success.batchDeleteCoupons', { count: latestBatchDeletedCoupons.count }) }}
      </vel-alert>
    </div>

    <template v-slot:toolbar>
      <vel-group-selector
        class="coupons__group-selector"
        v-if="!isGroupSelectorHidden"
        :disabled="groupsLoading"
        :groupId="mutableGroupId"
        :groupsOptions="groups"
        :showGlobalGroupOptionGroupLabel="false"
        @change="handleGroupChange"
      />
      <div class="toolbar-buttons">
        <vel-button
          class="coupons__action-add"
          :disabled="areActionsDisabled"
          type="primary"
          @click="handleAddCoupon()"
        >
          {{ $t('coupons.actions.create') }}
        </vel-button>
        <vel-button
          class="coupons__action-import"
          v-if="!isGroupActionButtonsHidden"
          :disabled="areActionsDisabled"
          type="primary"
          @click="handleImportCoupon()"
        >
          {{ $t('coupons.actions.import') }}
        </vel-button>
        <vel-button
          class="coupons__action-batchDelete"
          v-if="!isGroupActionButtonsHidden"
          :disabled="areActionsDisabled"
          type="critical"
          @click="handleBatchDeleteCoupon()"
        >
          {{ $t('coupons.actions.batchDelete') }}
        </vel-button>
      </div>
    </template>

    <template v-slot:footer>
      <b-container fluid>
        <div class="d-flex justify-content-end align-items-center">
          <b-form-group class="d-none d-md-block">
            {{ $t('employee.list.rowsPerPage') }}
            <b-form-select
              size="sm"
              v-model="perPage"
              :options="[10, 25, 50, 100]"
              style="width: 80px;"
              class="mx-3"
              :disabled="areActionsDisabled || !coupons.length"
            ></b-form-select>
          </b-form-group>
          <span class="d-none d-md-flex align-self-center mr-6" v-if="coupons.length">
            {{
              $t('pagination.format', {
                first: currentPage * perPage - perPage + 1,
                last: Math.min(currentPage * perPage, totalResults),
                total: totalResults
              })
            }}
          </span>
          <b-pagination
            size="sm"
            pills
            class="m-0 ml-2"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalResults"
            :limit="5"
            align="right"
            :disabled="areActionsDisabled || !coupons.length"
          ></b-pagination>
        </div>
      </b-container>
    </template>

    <div class="coupon__modals">
      <add-coupon-modal
        class="modals__add"
        @dismiss="handleAddCouponModalDismiss"
        @success="handleAddCouponModalSuccess"
        :currentGroupId="mutableGroupId"
        v-if="showAddCoupon"
      ></add-coupon-modal>

      <edit-coupon-modal
        class="modals__edit"
        @dismiss="handleEditCouponModalDismiss"
        @success="handleEditCouponModalSuccess"
        :coupon="editedCoupon"
        :currentGroupId="mutableGroupId"
        v-if="showEditCoupon && editedCoupon"
      ></edit-coupon-modal>

      <delete-coupon-modal
        class="modals__delete"
        @dismiss="handleDeleteCouponModalDismiss"
        @success="handleDeleteCouponModalSuccess"
        :coupon="deletedCoupon"
        v-if="showDeleteCoupon && deletedCoupon"
      ></delete-coupon-modal>

      <import-coupon-modal
        class="modals__import"
        @dismiss="handleImportCouponModalDismiss"
        @success="handleImportCouponModalSuccess"
        :currentGroupId="mutableGroupId"
        v-if="showImportCoupon"
      ></import-coupon-modal>

      <batch-delete-coupons-modal
        class="modals__import"
        @dismiss="handleBatchDeleteCouponModalDismiss"
        @success="handleBatchDeleteCouponModalSuccess"
        :currentGroupId="mutableGroupId"
        v-if="showBatchDeleteCoupon"
      ></batch-delete-coupons-modal>
    </div>

    <template v-if="isLargeUp">
      <vel-card class="coupons__table">
        <vel-table
          :rows="coupons"
          :columns="columns"
          :loading="areActionsDisabled"
          :filterValue="filter"
          :filterable="true"
          :hoverable="false"
          :filterPlaceholder="$t('defaults.placeholder.filter')"
          sort-type="user"
          :default-direction="sortDirection"
          :default-sort="sortBy"
          @column="handleSortChange"
          @filter-change="handleFilterChange"
        >
          <template v-slot:code-column="scope">
            <span class="coupons__code-text">{{ scope.cell.value }}</span>
          </template>
          <template v-slot:isActive-column="scope">
            {{ scope.row.isActive ? $t('defaults.status.active') : $t('defaults.status.inactive') }}
          </template>
          <template v-slot:couponType-column="scope">
            {{ $t(`coupons.couponTypes[${scope.row.couponType}]`) }}
          </template>
          <template v-slot:expires-column="scope">
            {{ formatDate(scope.row.expires) }}
          </template>
          <template v-slot:redeemed-column="scope">
            {{ scope.row.couponType == 0 ? '--' : formatDate(scope.row.redeemed) }}
          </template>
          <template v-slot:actions-column="scope">
            <div class="coupons__action">
              <vel-button class="action__edit-button" type="link" icon="edit" @click="handleEditCoupon(scope.row)" />
              <vel-button
                class="action__delete-button"
                type="link"
                icon="delete"
                @click="handleDeleteCoupon(scope.row)"
              />
            </div>
          </template>
          <template v-slot:empty>
            {{ mutableGroupId ? $t('coupons.errors.noData') : $t('coupons.errors.noGroupSelected') }}
          </template>
        </vel-table>
      </vel-card>
    </template>
    <template v-else>
      <vel-table-filter class="coupons__filter" v-model="inputFilter" />
      <vel-card class="coupons__info" title-tag="header" v-for="coupon in coupons" :key="coupon.code">
        <template v-slot:title>
          <div class="coupon__title">
            <div class="coupon-title__text">
              {{ coupon.name }}
            </div>
            <div class="coupon-title__action">
              <vel-button
                class="action__edit-button"
                type="link"
                icon="edit"
                @click="handleEditCoupon(coupon)"
              ></vel-button>
            </div>
            <div class="coupon-title__action">
              <vel-button
                class="action__delete-button"
                type="link"
                icon="delete"
                @click="handleDeleteCoupon(coupon)"
              ></vel-button>
            </div>
          </div>
        </template>

        <div class="coupon__info-details">
          <p class="property property__code">
            <span class="property__label">{{ $t('coupons.table.columns.code') }}</span>
            <span class="property__value">{{ coupon.code }}</span>
          </p>

          <p class="property property__isActive">
            <span class="property__label">{{ $t('coupons.table.columns.isActive') }}</span>
            <span class="property__value">{{ coupon.isActive }}</span>
          </p>

          <p class="property property__couponType">
            <span class="property__label">{{ $t('coupons.table.columns.couponType') }}</span>
            <span class="property__value">{{ $t(`coupons.couponTypes[${coupon.couponType}]`) }}</span>
          </p>

          <p class="property property__expires">
            <span class="property__label">{{ $t('coupons.table.columns.expires') }}</span>
            <span class="property__value">{{ formatDate(coupon.expires) }}</span>
          </p>

          <p class="property property__redeemed">
            <span class="property__label">{{ $t('coupons.table.columns.redeemed') }}</span>
            <span class="property__value">{{ coupon.couponType == 0 ? '--' : formatDate(coupon.redeemed) }}</span>
          </p>

          <p class="property property__plu1">
            <span class="property__label">{{ $t('coupons.table.columns.plu1') }}</span>
            <span class="property__value">{{ coupon.plu1 }}</span>
          </p>

          <p class="property property__plu1Amount">
            <span class="property__label">{{ $t('coupons.table.columns.plu1Amount') }}</span>
            <span class="property__value">{{ coupon.plu1Amount }}</span>
          </p>

          <p class="property property__plu2">
            <span class="property__label">{{ $t('coupons.table.columns.plu2') }}</span>
            <span class="property__value">{{ coupon.plu2 }}</span>
          </p>

          <p class="property property__plu2Amount">
            <span class="property__label">{{ $t('coupons.table.columns.plu2Amount') }}</span>
            <span class="property__value">{{ coupon.plu2Amount }}</span>
          </p>
        </div>
      </vel-card>
      <vel-card class="coupons__empty" title-tag="header" v-if="hasEmptyRows">
        <div>{{ mutableGroupId ? $t('coupons.errors.noData') : $t('coupons.errors.noGroupSelected') }}</div>
      </vel-card>
    </template>
  </vel-page>
</template>

<script>
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia';

import AddCouponModal from './components/modals/AddCouponModal.vue';
import { DateTime } from 'luxon';
import BatchDeleteCouponsModal from './components/modals/BatchDeleteCouponsModal.vue';
import DeleteCouponModal from './components/modals/DeleteCouponModal.vue';
import DeviceMixin from '@/mixins/device-mixin';
import EditCouponModal from './components/modals/EditCouponModal.vue';
import ImportCouponModal from './components/modals/ImportCouponModal.vue';
import SelectableGroupMixin from '@/mixins/selectable-group-mixin';
import VelAlert from '@/components/alert/VelAlert';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelGroupSelector from '@/components/group-selector/VelGroupSelector';
import VelIcon from '@/components/icon/VelIcon';
import VelPage from '@/components/page/VelPage';
import VelTable from '@/components/table/VelTable';
import VelTableFilter from '@/components/table/VelTableFilter';
import couponsService from '@/services/coupons.service';
import formatDateWithNoHours from '@/filters/format-date-with-no-hours';
import { getDateFormatFromUserConfig } from '@/helpers/date.helpers';
import pickBy from 'lodash.pickby';
import { useDataCoupons } from '@/stores/data/coupons.module';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';

export default {
  name: 'coupons-page',
  mixins: [DeviceMixin, SelectableGroupMixin],
  filters: {
    formatDateWithNoHours
  },
  components: {
    AddCouponModal,
    BatchDeleteCouponsModal,
    DeleteCouponModal,
    EditCouponModal,
    ImportCouponModal,
    VelAlert,
    VelButton,
    VelCard,
    VelGroupSelector,
    VelIcon,
    VelPage,
    VelTable,
    VelTableFilter
  },
  data() {
    return {
      showAddCoupon: false,
      showDeleteCoupon: false,
      showEditCoupon: false,
      showImportCoupon: false,
      showBatchDeleteCoupon: false,
      editedCoupon: null,
      perPage: window.localStorage.getItem('CouponsListRowsPerPage') || 25,
      totalResults: 0,
      currentPage: 1,
      coupons: [],
      filter: '',
      sortDirection: 'asc',
      sortBy: 'code',
      getCouponsLoading: false,
      generating: false
    };
  },
  methods: {
    ...mapPiniaActions(useUIGroupSelector, ['loadGroups']),
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    ...mapPiniaActions(useDataCoupons, [
      'clearLatestCreatedCoupon',
      'clearLatestDeletedCoupon',
      'clearLatestUpdatedCoupon',
      'clearLatestImportedCoupons',
      'clearLatestBatchDeletedCoupons',
      'setGetCouponsError',
      'clearGetCouponsError'
    ]),
    handleSortChange({ direction, key }) {
      this.sortDirection = direction;
      this.sortBy = key;

      this.fetchData();
    },
    handleFilterChange(value) {
      this.filter = value;
      this.fetchData();
    },
    handleAddCoupon() {
      this.showAddCoupon = true;
    },
    handleAddCouponModalDismiss() {
      this.showAddCoupon = false;
    },
    handleAddCouponModalSuccess() {
      this.showAddCoupon = false;
    },
    handleEditCoupon(coupon) {
      this.editedCoupon = { ...coupon };
      this.showEditCoupon = true;
    },
    handleEditCouponModalDismiss() {
      this.editedCoupon = null;
      this.showEditCoupon = false;
    },
    handleEditCouponModalSuccess() {
      this.editedCoupon = null;
      this.showEditCoupon = false;
    },
    handleDeleteCoupon(coupon) {
      this.deletedCoupon = { ...coupon };
      this.showDeleteCoupon = true;
    },
    handleDeleteCouponModalDismiss() {
      this.deletedCoupon = null;
      this.showDeleteCoupon = false;
    },
    handleDeleteCouponModalSuccess() {
      this.deletedCoupon = null;
      this.showDeleteCoupon = false;
    },
    handleImportCoupon() {
      this.showImportCoupon = true;
    },
    handleImportCouponModalDismiss() {
      this.showImportCoupon = false;
    },
    handleImportCouponModalSuccess() {
      this.showImportCoupon = false;
    },
    handleBatchDeleteCoupon() {
      this.showBatchDeleteCoupon = true;
    },
    handleBatchDeleteCouponModalDismiss() {
      this.showBatchDeleteCoupon = false;
    },
    handleBatchDeleteCouponModalSuccess() {
      this.showBatchDeleteCoupon = false;
    },
    changeRoute() {
      this.$router.replace({
        name: 'coupons',
        query: pickBy(
          {
            ...this.mutableGroupQuery
          },
          a => !!a
        )
      });
    },
    fetchData() {
      this.clearGetCouponsError();
      this.gen(false);

      if (this.mutableGroupId) {
        this.getCouponsLoading = true;
        couponsService
          .getCoupons(this.mutableGroupId, {
            pagination: true,
            offset: (this.currentPage - 1) * this.perPage,
            limit: this.perPage,
            search: this.filter,
            order: this.sortDirection,
            sort: this.sortBy
          })
          .then(result => {
            this.coupons = result.content;
            this.totalResults = result.total;
          })
          .catch(e => {
            this.setGetCouponsError(e);
          })
          .finally(() => {
            this.getCouponsLoading = false;
            this.gen(true);
          });
      }
    },
    formatDate(value) {
      if (!value) {
        return '--';
      }

      const date = DateTime.fromISO(value);

      return `${date.toFormat(getDateFormatFromUserConfig())}`;
    },
    async exportXLS() {
      this.$ga.event('report', 'download', this.$route.name);
      this.generating = true;

      await couponsService
        .downloadCouponsXlsx(this.mutableGroupId, {
          pagination: false,
          search: this.filter,
          order: this.sortDirection,
          sort: this.sortBy
        })
        .finally(() => {
          this.generating = false;
        });

      this.generating = false;
    }
  },
  computed: {
    ...mapPiniaState(useDataCoupons, [
      'getCouponsRows',
      'isLoading',
      'latestCreatedCoupon',
      'latestDeletedCoupon',
      'latestUpdatedCoupon',
      'latestImportedCoupons',
      'latestBatchDeletedCoupons',
      'hasGetCouponsError'
    ]),
    ...mapPiniaState(useUIGroupSelector, {
      groups: 'groups',
      groupsLoading: 'isLoading'
    }),
    hasEmptyRows() {
      return !this.coupons || !this.coupons.length;
    },
    columns() {
      return {
        code: {
          title: this.$t('coupons.table.columns.code'),
          sortable: true
        },
        name: {
          title: this.$t('coupons.table.columns.name'),
          sortable: true
        },
        plu1: {
          title: this.$t('coupons.table.columns.shortNames.plu1'),
          sortable: true
        },
        plu1Amount: {
          title: this.$t('coupons.table.columns.shortNames.plu1Amount'),
          sortable: true
        },
        plu2: {
          title: this.$t('coupons.table.columns.shortNames.plu2'),
          sortable: true
        },
        plu2Amount: {
          title: this.$t('coupons.table.columns.shortNames.plu2Amount'),
          sortable: true
        },
        isActive: {
          title: this.$t('coupons.table.columns.isActive'),
          sortable: true
        },
        couponType: {
          title: this.$t('coupons.table.columns.couponType'),
          sortable: true
        },
        expires: {
          title: this.$t('coupons.table.columns.shortNames.expires'),
          sortable: true
        },
        redeemed: {
          title: this.$t('coupons.table.columns.shortNames.redeemed'),
          sortable: true
        },
        actions: {
          title: this.$t('defaults.actions.title'),
          sortable: false,
          exportable: false,
          width: '10%'
        }
      };
    },
    inputFilter: {
      get() {
        return this.filter;
      },
      set(value) {
        this.filter = value;
        this.fetchData();
      }
    },
    areActionsDisabled() {
      return this.isLoading || this.getCouponsLoading || this.generating;
    },
    isGroupSelectorHidden() {
      return (this.groups && this.groups.length === 1) || this.groupsLoading;
    },
    isGroupActionButtonsHidden() {
      return !this.mutableGroupId;
    }
  },
  watch: {
    async currentPage() {
      await this.fetchData();
    },
    async perPage(val) {
      window.localStorage.setItem('CouponsListRowsPerPage', val);
      await this.fetchData();
    },
    latestCreatedCoupon(value) {
      if (value) {
        this.fetchData();
      }
    },
    latestUpdatedCoupon(value) {
      if (value) {
        this.fetchData();
      }
    },
    latestDeletedCoupon(value) {
      if (value) {
        this.fetchData();
      }
    },
    latestImportedCoupons(value) {
      if (value) {
        this.fetchData();
      }
    },
    latestBatchDeletedCoupons(value) {
      if (value) {
        this.fetchData();
      }
    }
  },
  created() {
    this.loadGroups().then(() => {
      if (!this.mutableGroupId && this.groups && this.groups.length === 1) {
        this.handleGroupChange(this.groups[0].value);
      }

      this.fetchData();
    });
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$coupons__code-font-weight: $font-bold;

.coupons {
  &__filter {
    margin-bottom: rem-calc(16);
  }

  .coupons__code-text {
    font-weight: $coupons__code-font-weight;
  }

  .toolbar-buttons {
    align-content: space-between;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include breakpoint(medium) {
      flex-direction: row;
      gap: rem-calc(8);
    }

    .coupons__action-add,
    .coupons__action-import,
    .coupons__action-batchDelete {
      margin-bottom: rem-calc(8);
      margin-left: 0;

      @include breakpoint(medium) {
        margin-bottom: rem-calc(16);
      }
    }
  }

  .vel-table__cell_empty {
    text-align: center;
  }

  .coupons__empty {
    text-align: center;
  }

  .coupons__action {
    .vel-button__icon {
      padding: 0 0.125rem;
    }
  }

  .coupons__group-selector {
    margin-bottom: rem-calc(16);
    margin-left: 0;

    .el-select-dropdown__item {
      max-width: 100%;
    }
  }

  @include breakpoint(medium) {
    &__filter {
      margin-bottom: rem-calc(20);
    }

    .coupons__group-selector {
      margin-left: auto;
    }
  }

  .coupons__info {
    .coupon__title {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .coupon-title__text {
        flex-grow: 1;
      }
    }
  }

  .alert {
    margin-bottom: rem-calc(16);
  }

  .property {
    margin-bottom: 8px;

    .property__label {
      color: $property__label-color;
    }

    .property__value {
      margin-left: 8px;
    }
  }
}

footer {
  background: #f5f5f5;
  /* stylelint-disable-next-line */
  border: 1px solid #f5f5f5;
  box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0.08), 0 -1px 8px 0 rgba(0, 0, 0, 0.06), 0 -3px 3px -2px rgba(0, 0, 0, 0.12);
  padding: 10px 0;
  z-index: 10;
}

.pagination {
  height: calc(1.5em + 0.5rem + 2px);
  display: flex;
  align-items: center;
}

.vel-button.xlsx-btn {
  @include noPrint;

  background-color: $mountain-meadow;
  border-color: $mountain-meadow;
  direction: rtl;

  &:hover {
    background-color: mix(black, $mountain-meadow, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  & > {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}

.page {
  overflow-y: unset !important;
}
</style>
