import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import groupsService from '@/services/groups.service';
import { accentFold } from '@/helpers/string.helpers';

export const useDataGroups = defineStore('groups', {
  state: () => ({
    groups: [],
    filter: '',
    status: {},
    error: {}
  }),
  getters: {
    getFilter: state => state.filter.toLowerCase(),
    getGroupsRows(state) {
      return this.getFilter
        ? state.groups.filter(group => {
            const value = `${group.name}`.toLowerCase();
            return accentFold(value).includes(accentFold(this.getFilter.trim()));
          })
        : state.groups;
    },
    isLoading: (state: any) => state.status.isLoading,
    isDeletingGroup: (state: any) => state.status.isDeletingGroup,
    isSavingGroup: (state: any) => state.status.isSavingGroup,
    deleteGroupError: (state: any) => state.error.deleteGroupError,
    hasGetGroupsError(_) {
      return !!this.error.getGroupsError;
    },
    hasCreateGroupError(_) {
      return !!this.error.createGroupError;
    },
    hasDeleteGroupError(_) {
      return !!this.error.deleteGroupError;
    },
    hasUpdateGroupError(_) {
      return !!this.error.updateGroupError;
    },
    latestCreatedGroup: (state: any) => state.status.latestCreatedGroup || null,
    latestDeletedGroup: (state: any) => state.status.latestDeletedGroup || null,
    latestUpdatedGroup: (state: any) => state.status.latestUpdatedGroup || null,
    getGroups: state =>
      (state.groups || []).map(g => ({ label: g.name, value: g.id })).sort((a, b) => a.label.localeCompare(b.label)),
    getGroupsAmount: state => state.groups.length
  },
  actions: {
    fetchGroups() {
      this.getGroupsRequest();

      groupsService.getGroups(getToken()).then(
        groups => {
          this.getGroupsSuccess(groups);
        },
        error => {
          this.getGroupsFailure(error);
        }
      );
    },
    createGroup(group) {
      this.createGroupRequest();

      groupsService.createGroup(getToken(), group).then(
        res => {
          this.createGroupSuccess(res);
        },
        error => {
          this.createGroupFailure(error);
        }
      );
    },
    deleteGroup(group) {
      this.deleteGroupRequest();

      groupsService.deleteGroup(getToken(), group.id).then(
        () => {
          this.deleteGroupSuccess(group);
        },
        error => {
          this.deleteGroupFailure(error);
        }
      );
    },
    updateGroup(group) {
      this.updateGroupRequest();

      groupsService.updateGroup(getToken(), group).then(
        updatedGroup => {
          this.updateGroupSuccess(updatedGroup);
        },
        error => {
          this.updateGroupFailure(error);
        }
      );
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    getGroupsRequest() {
      this.status = { ...this.status, isLoading: true };
      this.groups = [];
      this.error = { ...this.error, getGroupsError: null };
      this.filter = '';
    },
    getGroupsSuccess(groups) {
      this.status = { ...this.status, isLoading: false };
      this.groups = groups;
      this.error = { ...this.error, getGroupsError: null };
      this.filter = '';
    },
    getGroupsFailure(error) {
      this.status = { ...this.status, isLoading: false };
      this.groups = [];
      this.error = { ...this.error, getGroupsError: error };
    },
    createGroupRequest() {
      this.status = { ...this.status, isSavingGroup: true, latestCreatedGroup: null };
      this.error = { ...this.error, createGroupError: null };
    },
    createGroupSuccess(group) {
      this.groups = [group, ...this.groups];
      this.status = { ...this.status, isSavingGroup: false, latestCreatedGroup: group };
      this.error = { ...this.error, createGroupError: null };
    },
    createGroupFailure(error) {
      this.status = { ...this.status, isSavingGroup: false, latestCreatedGroup: null };
      this.error = { ...this.error, createGroupError: error };
    },
    deleteGroupRequest() {
      this.status = { ...this.status, isDeletingGroup: true, latestDeletedGroup: null };
      this.error = { ...this.error, deleteGroupError: null };
    },
    deleteGroupSuccess(group) {
      const updatedGroups = [...this.groups];
      updatedGroups.splice(
        updatedGroups.findIndex(updatedGroup => updatedGroup.id === group.id),
        1
      );
      this.groups = updatedGroups;
      this.status = { ...this.status, isDeletingGroup: false, latestDeletedGroup: group };
      this.error = { ...this.error, deleteGroupError: null };
    },
    deleteGroupFailure(error) {
      this.status = { ...this.status, isDeletingGroup: false, latestDeletedGroup: null };
      this.error = { ...this.error, deleteGroupError: error };
    },
    updateGroupRequest() {
      this.status = { ...this.status, isSavingGroup: true, latestUpdatedGroup: null };
      this.error = { ...this.error, updateGroupError: null };
    },
    updateGroupSuccess(group) {
      const groupIndex = this.groups.findIndex(existingGroup => existingGroup.id === group.id);
      const newGroupsArray = [...this.groups];
      newGroupsArray.splice(groupIndex, 1, group);
      this.groups = newGroupsArray;

      this.status = { ...this.status, isSavingGroup: false, latestUpdatedGroup: group };
      this.error = { ...this.error, updateGroupError: null };
    },
    updateGroupFailure(error) {
      this.status = { ...this.status, isSavingGroup: false, latestUpdatedGroup: null };
      this.error = { ...this.error, updateGroupError: error };
    },
    setFilterValue(value) {
      this.filter = value;
    },
    clearLatestCreatedGroup() {
      this.status = { ...this.status, latestCreatedGroup: null };
    },
    clearLatestDeletedGroup() {
      this.status = { ...this.status, latestDeletedGroup: null };
    },
    clearLatestUpdatedGroup() {
      this.status = { ...this.status, latestUpdatedGroup: null };
    },
    clearGetGroupsError() {
      this.error.getGroupsError = false;
    },
    clearCreateGroupError() {
      this.error.createGroupError = false;
    },
    clearDeleteGroupError() {
      this.error.deleteGroupError = false;
    },
    clearUpdateGroupError() {
      this.error.updateGroupError = false;
    }
  }
});
