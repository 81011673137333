<template>
  <vel-page :show-crumbs="false">
    <template slot="title">{{ $t('reportsMenuPage.title') }}</template>
    <div class="content">
      <vel-card shadowed>
        <template slot="title">{{ $t('reportsMenuPage.transaction.title') }}</template>
        <router-link v-for="link in links.transaction" :to="{ name: link.name }" :key="link.name">
          {{ $t(link.text) }}
        </router-link>
      </vel-card>
      <vel-card shadowed>
        <template slot="title">{{ $t('reportsMenuPage.financial.title') }}</template>
        <router-link v-for="link in links.financial" :to="{ name: link.name }" :key="link.name">
          {{ $t(link.text) }}
        </router-link>
      </vel-card>
      <vel-card shadowed>
        <template slot="title">{{ $t('reportsMenuPage.check.title') }}</template>
        <router-link v-for="link in links.check" :to="{ name: link.name }" :key="link.name">
          {{ $t(link.text) }}
        </router-link>
      </vel-card>
      <!-- <vel-card shadowed>
      <template slot="title">{{ $t('reportsMenuPage.system.title') }}</template>
      <router-link :to="{name: ''}">
        <vel-icon size="medium" name="account-circle" />
        {{ $t('reportsMenuPage.system.employee') }}
      </router-link>
      <router-link :to="{name: ''}">
        <vel-icon size="medium" name="account-circle" />
        {{ $t('reportsMenuPage.system.department') }}
      </router-link>
      <router-link :to="{name: ''}">
        <vel-icon size="medium" name="account-circle" />
        {{ $t('reportsMenuPage.system.menuItem') }}
      </router-link>
    </vel-card> -->
    </div>
  </vel-page>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelCard from '@/components/card/VelCard';
import VelIcon from '@/components/icon/VelIcon';
import VelPage from '@/components/page/VelPage';

export default {
  name: 'reports-menu',
  mixins: [DeviceMixin],
  components: {
    VelCard,
    VelIcon,
    VelPage
  },
  computed: {
    links() {
      return {
        transaction: [
          {
            name: 'salesPerHourReport',
            text: 'reportsMenuPage.transaction.sales'
          },
          {
            name: 'discountReport',
            text: 'reportsMenuPage.transaction.discounts'
          },
          {
            name: 'employeesSalesReport',
            text: 'reportsMenuPage.transaction.employeeSales'
          },
          {
            name: 'giftCardReport',
            text: 'reportsMenuPage.transaction.giftCards'
          },
          {
            name: 'itemsByCategoryPageReport',
            text: 'reportsMenuPage.transaction.categorySales'
          },
          {
            name: 'itemsReport',
            text: 'reportsMenuPage.financial.items'
          },
          {
            name: 'netSalesReport',
            text: 'reportsMenuPage.transaction.netSales'
          },
          {
            name: 'salesRevenuePageReport',
            text: 'reportsMenuPage.transaction.salesRevenue'
          },
          {
            name: 'voidsReport',
            text: 'reportsMenuPage.transaction.voids'
          }
        ].sort((a, b) => this.$t(a.text).localeCompare(this.$t(b.text))),
        financial: [
          {
            name: 'auditReport',
            text: 'reportsMenuPage.financial.audit'
          },
          {
            name: 'bigDivision',
            text: 'reportsMenuPage.financial.categorySales'
          },
          {
            name: 'locationReport',
            text: 'reportsMenuPage.financial.locationSales'
          },
          {
            name: 'summaryReport',
            text: 'reportsMenuPage.financial.summary'
          }
        ].sort((a, b) => this.$t(a.text).localeCompare(this.$t(b.text))),
        check: [
          {
            name: 'checksSummary',
            text: 'reportsMenuPage.check.summary'
          },
          {
            name: 'checkSearch',
            text: 'reportsMenuPage.check.search'
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';

/deep/ .vel-page {
  &__header {
    h1 {
      font-size: rem-calc(26);
    }
  }

  .content {
    display: grid;
    grid-template-columns: auto;
    height: 100%;

    @include breakpoint(large) {
      display: flex;
    }
  }
}

/deep/ .vel-card {
  border-radius: rem-calc(5);

  @include breakpoint(large) {
    padding-bottom: rem-calc(10);
    margin-bottom: auto;
  }

  padding: 0;

  @include breakpoint(large) {
    min-width: rem-calc(300);

    &:not(:first-child) {
      margin-left: rem-calc(15);
    }
  }

  &_shadowed {
    box-shadow: 0 0 0 1px rgba(#505f79, 0.05), 0 3px 3px 0 rgba(#505f79, 0.15);
  }

  &__title {
    background: #2a3142;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: rem-calc(16);
    font-weight: 500;
    padding: rem-calc(20);
    border-radius: rem-calc(5) rem-calc(5) 0px 0px;
  }

  &__body {
    padding-left: rem-calc(20);
  }

  .vel-icon {
    color: #2a3142;
    margin-right: rem-calc(5);
  }

  a {
    align-items: center;
    color: #0c68bf;
    cursor: pointer;
    display: flex;
    list-style: none;
    margin-bottom: rem-calc(16);
    text-decoration: none;
  }
}
</style>
