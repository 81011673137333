<template>
  <div>
    <b-row class="mb-4 mt-4">
      <b-col cols="12" md="4" class="d-flex align-items-center mb-2 mb-md-0">
        {{ $t('tipDistribution.add.label') }}
      </b-col>
      <b-col>
        <b-form-input
          id="name"
          :placeholder="$t('tipDistribution.add.placeholder')"
          v-model="name"
          :class="{ required: !name || name === '' }"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-button v-if="!isEdit" :disabled="name === ''" variant="primary" @click="addTipDistribution()">
      {{ $t('tipDistribution.add.btn') }}
    </b-button>
  </div>
</template>

<script>
import { postEmployeeTipDistribution } from '@/services/employees.service';

export default {
  name: 'tip-distribution-form',
  model: {},
  props: {
    licenceNumber: {
      type: Number,
      default: null
    },
    tipDistribution: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: '',
      enableSaveChanges: false
    };
  },
  watch: {
    tipDistribution(value) {
      this.name = (value && value.name) || '';
    },
    name(value) {
      this.enableSaveChanges = value !== this.tipDistribution.name;
    }
  },
  computed: {
    employeeTipDistribution() {
      return {
        name: this.name,
        licenceNumber: this.licenceNumber,
        remoteId: this.tipDistribution && this.tipDistribution.remoteId,
        id: this.tipDistribution && this.tipDistribution.remoteId
      };
    },
    isEdit() {
      return !!(this.tipDistribution && this.tipDistribution.id);
    }
  },
  methods: {
    async addTipDistribution() {
      try {
        this.$emit('update:pending', true);
        await postEmployeeTipDistribution(this.employeeTipDistribution);
        this.$emit('update:pending', false);
        this.$emit('success', this.employeeTipDistribution);
        this.name = '';
      } catch (e) {
        this.$emit('update:error', true);
        this.$emit('update:pending', false);
        this.$emit('failure', this.employeeTipDistribution);
      }
    }
  }
};
</script>
