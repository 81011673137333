<template>
  <div class="vel-modal-wrapper" @click.self="handleEscape">
    <div class="vel-modal" role="dialog" :class="classes" v-loading="loading">
      <header class="vel-modal__header" v-if="$slots.header">
        <button class="vel-modal__dismiss" aria-hidden="true" v-if="dismissable" @click="handleDismiss">×</button>
        <component class="vel-modal__title" :is="titleLevel">
          <slot name="header" />
        </component>
      </header>
      <div class="vel-modal__body">
        <slot />
      </div>
      <footer class="vel-modal__footer" v-if="$slots.footer">
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vel-modal',
  props: {
    dismissable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    autoDismissTime: {
      type: Number,
      default: 5000
    },
    autoDismiss: {
      type: Boolean,
      default: false
    },
    titleLevel: {
      type: String,
      default: 'h4'
    },
    size: {
      type: String,
      default: 'medium',
      validate(value) {
        return ['small', 'medium'].includes(value);
      }
    }
  },
  watch: {
    $route() {
      this.handleDismiss();
    }
  },
  computed: {
    classes() {
      const { name } = this.$options;
      return [`${name}_${this.size}`];
    }
  },
  methods: {
    handleEscape() {
      if (!this.dismissable) {
        return;
      }
      this.handleDismiss();
    },
    handleDismiss() {
      this.$emit('dismiss');
    },
    handleDocumentKeyUp(event) {
      if (event.key === 'Escape' || event.keyCode === 27) {
        this.handleEscape();
      }
    }
  },
  beforeMount() {
    document.addEventListener('keyup', this.handleDocumentKeyUp);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleDocumentKeyUp);
  },
  mounted() {
    if (this.autoDismissTime && this.autoDismiss) {
      setTimeout(() => {
        this.handleDismiss();
      }, this.autoDismissTime);
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$modal-background-color: white;
$modal-border-color: $alto;
$modal-border-radius: $base-radius;

// wrapper
$modal-wrapper-background-color: rgba(black, 0.6);

// header
$modal__header-border-color: $athens-gray;

// footer
$modal__footer-border-color: $modal__header-border-color;

// dismiss
$modal__dismiss-background-color: $ebony-clay;
$modal__dismiss-border-color: white;
$modal__dismiss-icon-color: white;
$modal__dismiss-icon-color_hover: black;
$modal__dismiss-icon-size: rem-calc(18);
$modal__dismiss-icon-thickness: $font-bold;
$modal__dismiss-icon-outer-size: rem-calc(48);
$modal__dismiss-icon-outer-size_medium-up: rem-calc(38);

.vel-modal {
  background-clip: padding-box;
  background-color: $modal-background-color;
  border: solid 1px $modal-border-color;
  border-radius: $modal-border-radius;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  margin: rem-calc(20);
  position: relative;
  z-index: 101;

  &_small {
    width: rem-calc(500);
  }

  &_medium {
    width: rem-calc(600);
  }

  &_large {
    width: rem-calc(700);
  }

  &-wrapper {
    align-items: center;
    background-color: $modal-wrapper-background-color;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    transition-property: background-color;
    width: 100vw;
    z-index: 100;
    @include animate;
  }

  &__header {
    align-items: flex-start;
    border-bottom: 2px solid $modal__header-border-color;
    border-top-left-radius: $modal-border-radius;
    border-top-right-radius: $modal-border-radius;
    display: flex;
    justify-content: space-between;
    padding: rem-calc(16);
  }

  &__body {
    max-height: 85vh;
    overflow: auto;
    padding: rem-calc(20);
  }

  &__dismiss {
    align-items: center;
    background-color: $modal__dismiss-background-color;
    border: 2px solid $modal__dismiss-border-color;
    border-radius: 50%;
    color: $modal__dismiss-icon-color;
    display: flex;
    font-size: $modal__dismiss-icon-size;
    font-weight: $modal__dismiss-icon-thickness;
    height: $modal__dismiss-icon-outer-size;
    justify-content: center;
    margin: -1rem -1rem -1rem auto;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition-property: opacity;
    width: $modal__dismiss-icon-outer-size;
    @include animate;

    &:hover {
      color: $modal__dismiss-icon-color_hover;
      opacity: 0.75;
    }
  }

  &__footer {
    align-items: center;
    border-top: 2px solid $modal__footer-border-color;
    display: flex;
    justify-content: flex-end;
    padding: rem-calc(16);

    & > :not(:first-child) {
      margin-left: rem-calc(4);
    }

    & > :not(:last-child) {
      margin-right: rem-calc(4);
    }
  }
  @include breakpoint(medium) {
    &__dismiss {
      height: $modal__dismiss-icon-outer-size_medium-up;
      width: $modal__dismiss-icon-outer-size_medium-up;
    }
  }
}
</style>
