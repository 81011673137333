<template>
  <div class="vel-feedback" id="int-doorbell"></div>
</template>

<script>
import postscribe from 'postscribe';
import { mapState } from 'pinia';
import { useDataAccount } from '@/stores/data/account.module';
import { storeToRefs } from 'pinia/dist/pinia';

const scriptContentTemplate = `
    window.doorbellOptions = {
        "id": "10858",
        "appKey": "6VvBNV0x6EveFqr1VNFWvN4ZS6IrmXRFNGZvAsikqUvaa00ePann3VnC5COAvuHV",
        "hideButton": true,
        "email": "#email#",
        "name": "#fullname#"
    };
    (function(w, d, t) {
        var hasLoaded = false;
        function l() { if (hasLoaded) { return; } hasLoaded = true; window.doorbellOptions.windowLoaded = true; var g = d.createElement(t);g.id = 'doorbellScript';g.type = 'text/javascript';g.async = true;g.src = 'https://embed.doorbell.io/button/'+window.doorbellOptions['id']+'?t='+(new Date().getTime());(d.getElementsByTagName('head')[0]||d.getElementsByTagName('body')[0]).appendChild(g); }
        if (w.attachEvent) { w.attachEvent('onload', l); } else if (w.addEventListener) { w.addEventListener('load', l, false); } else { l(); }
        if (d.readyState == 'complete') { l(); }
    }(window, document, 'script'));
`;

/* eslint-disable no-useless-escape */
export default {
  name: 'vel-feedback',

  setup() {
    const dataccount = useDataAccount();
    const { email, fullName } = storeToRefs(dataccount);

    return { email, fullName };
  },
  computed: {
    scriptContent() {
      return scriptContentTemplate.replace('#email#', this.email).replace('#fullname#', this.fullName);
    }
  },
  mounted() {
    postscribe('#int-doorbell', `<script type="text/javascript">${this.scriptContent}<\/script>`);
  },
  beforeDestroy() {
    window.doorbell.remove();
  }
};
/* eslint-enable no-useless-escape */
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

#doorbell-background {
  background-color: rgba(black, 0.6);
}

#doorbell-button {
  background-color: $ebony-clay;
  display: none;

  @include breakpoint(medium) {
    display: block;
  }
}

#doorbell-submit-button {
  background-color: $primary-color;
  border: 0;
  border-radius: $base-radius * 2;
}
</style>
