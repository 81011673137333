import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import { createCollectionState, getSelectors, removeAll, setAll, setOne } from '@/helpers/stores.helpers';
import countriesService from '@/services/countries.service';

const { selectAll } = getSelectors();

export const useDataCountries = defineStore('countries', {
  state: () =>
    createCollectionState({
      loxading: true,
      error: null,
      filter: ''
    }),
  getters: {
    selectAll
  },
  actions: {
    async getCountries() {
      try {
        this.getCountriesRequest();
        const countries = await countriesService.getCountries(getToken());
        this.getCountriesSuccess(countries);
      } catch (error) {
        this.getCountriesFailure(error);
      }
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    setSelected(value) {
      this.setSelectedValue(value);
    },
    getCountriesRequest() {
      this.loading = true;
      this.error = null;
    },
    getCountriesSuccess(countries) {
      this.data = setAll(countries, this.data);
      this.error = null;
      this.loading = false;
    },
    getCountriesFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    setFilterValue(value) {
      this.filter = value;
    },
    setSelectedValue(value) {
      this.data.selected = value;
    }
  }
});
