<template>
  <vel-select
    :autofocus="autofocus"
    :disabled="disabled"
    :filterable="isFilterable"
    :filter-method="filterOptions"
    :multiple="multiple"
    :no-data-text="$t('location.selector.nodata')"
    :no-match-text="$t('location.selector.nomatch')"
    :placeholder="$t('location.selector.placeholder')"
    :value="locationId"
    @change="handleChange"
    class="vel-select"
    @visible-change="filter = ''"
  >
    <vel-option-group
      v-if="allowGlobalLocationOption"
      :label="showGlobalLocationOptionGroupLabel ? $t('location.selector.options.groups.all.title') : ''"
    >
      <vel-option
        :label="allLocationsLabel || $t('location.selector.options.groups.all.defaultLabel')"
        :value="''"
      ></vel-option>
    </vel-option-group>

    <vel-option-group
      v-if="locationsOptions.length > 0"
      :label="allowGlobalLocationOption ? $t('location.selector.options.groups.locations.title') : ''"
    >
      <vel-option
        v-for="option in options"
        :label="option.label"
        :value="option.value"
        :key="option.value"
      ></vel-option>
    </vel-option-group>
  </vel-select>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelOption from '@/components/x-option/VelOption';
import VelOptionGroup from '@/components/x-option-group/VelOptionGroup';
import VelSelect from '@/components/x-select/VelSelect';
import { accentFold } from '@/helpers/string.helpers';

export default {
  name: 'vel-location-selector',
  components: {
    VelOption,
    VelOptionGroup,
    VelSelect
  },
  mixins: [DeviceMixin],
  props: {
    allLocationsLabel: {
      type: String,
      default: ''
    },
    allowGlobalLocationOption: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: true
    },
    locationsOptions: {
      type: Array,
      required: true
    },
    locationId: {
      type: [String, Array],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    showGlobalLocationOptionGroupLabel: {
      type: Boolean,
      default: true
    },
    selectFirst: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      filter: ''
    };
  },
  watch: {
    allowGlobalLocationOption: {
      handler() {
        this.forceFirstSelection();
      }
    },
    locationsOptions: {
      handler() {
        this.forceFirstSelection();
      }
    }
  },
  computed: {
    isFilterable() {
      return this.filterable;
    },
    options() {
      if (this.filter === '') {
        return this.locationsOptions;
      }
      return this.locationsOptions.filter(
        option => accentFold(option.label.toLowerCase()).indexOf(accentFold(this.filter.toLowerCase())) > -1
      );
    }
  },
  methods: {
    handleChange(e) {
      this.$emit('change', e);
    },
    forceFirstSelection() {
      if (
        this.selectFirst !== false &&
        !this.allowGlobalLocationOption &&
        this.locationsOptions.length &&
        (!this.locationId || this.locationId === '')
      ) {
        this.handleChange(this.multiple ? [this.locationsOptions[0].value] : this.locationsOptions[0].value);
      }
    },
    filterOptions(filterValue) {
      this.filter = filterValue.trim();
    }
  },
  mounted() {
    this.forceFirstSelection();
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

:root .el-select-dropdown__item {
  max-width: 17rem;
  @include text-ellipsis;

  @include breakpoint(small) {
    max-width: 20rem;
  }

  @include breakpoint(medium) {
    max-width: none;
  }
}

.vel-select {
  @include noPrint;
}
</style>
