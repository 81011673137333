import { defineStore } from 'pinia';
import usersService from '@/services/users.service';
import { getToken } from '@/helpers/token.helper';
import { oc } from 'ts-optchain';

export const transformPermissions = permissions => ({
  global: permissions.global.map(p => ({
    permissions: [p.permission]
  })),
  locations: permissions.locations.map(p => ({
    locationId: p.locationId,
    permissions: [p.permission]
  })),
  groups: permissions.groups.map(p => ({
    groupId: p.groupId,
    permissions: [p.permission]
  }))
});

const deletePermissionInPermissions = (permissions, permission) => ({
  global: permissions.global.filter(p => p.id !== permission.id),
  locations: permissions.locations.filter(p => p.id !== permission.id),
  groups: permissions.groups.filter(p => p.id !== permission.id)
});

const upsertPermissionsInPermissions = (existingPermissions, newPermissions) => {
  let allPermissions = existingPermissions;
  newPermissions.forEach(p => {
    allPermissions = upsertPermissionInPermissions(allPermissions, p);
  });

  return allPermissions;
};

const upsertPermissionInPermissions = (permissions, permission) => {
  let allPermissions = {
    global: [...permissions.global],
    locations: [...permissions.locations],
    groups: [...permissions.groups]
  };

  if (permission.id) {
    allPermissions.global = allPermissions.global.map(p => (p.id === permission.id ? permission : p));
    allPermissions.locations = allPermissions.locations.map(p => (p.id === permission.id ? permission : p));
    allPermissions.groups = allPermissions.groups.map(p => (p.id === permission.id ? permission : p));
  } else {
    allPermissions = insertPermissionInPermissions(allPermissions, permission);
  }

  return allPermissions;
};

const insertPermissionInPermissions = (permissions, permission) => {
  if (permission.locationId) {
    permissions.locations.push(permission);
  } else if (permission.groupId) {
    permissions.groups.push(permission);
  } else {
    permissions.global.push(permission);
  }

  return permissions;
};

export const useDataUsersUser = defineStore('usersUser', {
  state: () => ({ userData: null, permissions: [], status: { isLoadingUser: true }, errors: {} }),
  getters: {
    arePermissionsBeingChanged: (state: any) =>
      !!state.status.isSavingPermission || !!state.status.isDeletingPermission,
    isLoadingUser: (state: any) => state.status.isLoadingUser,
    isLoadingPermissions: (state: any) => !!oc(state).status.isLoadingPermissions(),
    isUserForbiddenToChangePermissions(state: any) {
      return this.isUserForbiddenToDeletePermissions || this.isUserForbiddenToUpsertPermissions;
    },
    isUserForbiddenToDeletePermissions: (state: any) => oc(state).errors.deletePermissionError.statusCode(0) === 403,
    isUserForbiddenToUpsertPermissions: (state: any) => oc(state).errors.upsertPermissionError.statusCode(0) === 403,
    isUserNotFound: (state: any) => [400, 404].includes(oc(state).errors.getUserError.statusCode(0)),
    fullName: (state: any) => `${oc(state).userData.firstName('')} ${oc(state).userData.lastName('')}`.trim(),
    globalPermissions: (state: any) => oc(state).permissions.global([]),
    locationsPermissions: (state: any) => oc(state).permissions.locations([]),
    groupsPermissions: (state: any) => oc(state).permissions.groups([]),
    user(state) {
      const { fullName } = this;
      return {
        fullName,
        ...state.userData
      };
    }
  },
  actions: {
    deletePermission(permission) {
      this.deletePermissionRequest();

      const query = {
        userId: this.userData.id,
        permissions: deletePermissionInPermissions(this.permissions, permission)
      };

      usersService.updateUserPermissions(getToken(), query.userId, transformPermissions(query.permissions)).then(
        () => {
          this.deletePermissionSuccess();
          this.getPermissions(query.userId);
        },
        error => {
          this.deletePermissionFailure(error);
        }
      );
    },

    upsertPermissions(permissions) {
      this.upsertPermissionRequest();

      const query = {
        userId: this.userData.id,
        permissions: upsertPermissionsInPermissions(this.permissions, permissions)
      };

      usersService.updateUserPermissions(getToken(), query.userId, transformPermissions(query.permissions)).then(
        () => {
          this.upsertPermissionSuccess();
          this.getPermissions(query.userId);
        },
        error => {
          this.upsertPermissionFailure(error);
        }
      );
    },

    getUser(userId) {
      this.getUserRequest();

      usersService.getUser(getToken(), userId).then(
        users => {
          this.getUserSuccess(users);
        },
        error => {
          this.getUserFailure(error);
        }
      );
    },

    getPermissions(userId) {
      this.getPermissionsRequest();

      usersService.getUserPermissions(getToken(), { userId, include: ['location', 'group'] }).then(
        permissions => {
          this.getPermissionsSuccess(permissions);
        },
        error => {
          this.getPermissionsFailure(error);
        }
      );
    },
    hydrateUser(user) {
      this.userData = user;
    },
    deletePermissionRequest() {
      this.status = { ...this.status, isDeletingPermission: true };
      this.errors = { ...this.errors, deletePermissionError: null };
    },
    deletePermissionSuccess() {
      this.status = { ...this.status, isDeletingPermission: false };
      this.errors = { ...this.errors, deletePermissionError: null };
    },
    deletePermissionFailure(error) {
      this.status = { ...this.status, isDeletingPermission: false };
      this.errors = { ...this.errors, deletePermissionError: error };
    },
    getUserRequest() {
      this.status = { ...this.status, isLoadingUser: true };
      this.userData = null;
      this.errors = { ...this.errors, getUserError: null };
    },
    getUserSuccess(user) {
      this.status = { ...this.status, isLoadingUser: false };
      this.userData = user;
      this.errors = { ...this.errors, getUserError: null };
    },
    getUserFailure(error) {
      this.status = { ...this.status, isLoadingUser: false };
      this.userData = null;
      this.errors = { ...this.errors, getUserError: error };
    },
    getPermissionsRequest() {
      this.status = { ...this.status, isLoadingPermissions: true };
      this.permissions = [];
      this.errors = { ...this.errors, getPermissionsError: null };
    },
    getPermissionsSuccess(permissions) {
      this.status = { ...this.status, isLoadingPermissions: false };
      this.permissions = permissions;
      this.errors = { ...this.errors, getPermissionsError: null };
    },
    getPermissionsFailure(error) {
      this.status = { ...this.status, isLoadingPermissions: false };
      this.permissions = [];
      this.errors = { ...this.errors, getPermissionsError: error };
    },
    reset() {
      this.userData = {};
      this.status = {};
      this.permissions = [];
      this.errors = {};
    },
    upsertPermissionRequest() {
      this.status = { ...this.status, isSavingPermission: true };
      this.errors = { ...this.errors, upsertPermissionError: null };
    },
    upsertPermissionSuccess() {
      this.status = { ...this.status, isSavingPermission: false };
      this.errors = { ...this.errors, upsertPermissionError: null };
    },
    upsertPermissionFailure(error) {
      this.status = { ...this.status, isSavingPermission: false };
      this.errors = { ...this.errors, upsertPermissionError: error };
    }
  }
});
