<template>
  <el-dropdown @command="handleCommand">
    <span class="el-dropdown-link">
      {{ $t('dateCompareSelector.label') }}
      <i class="el-icon-arrow-down el-icon--right"></i>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item :class="{ active: selected === 'day' }" command="day">
        {{ $t('dateCompareSelector.options.day') }}
      </el-dropdown-item>
      <el-dropdown-item :class="{ active: selected === 'week' }" command="week">
        {{ $t('dateCompareSelector.options.week') }}
      </el-dropdown-item>
      <el-dropdown-item :class="{ active: selected === 'month' }" command="month">
        {{ $t('dateCompareSelector.options.month') }}
      </el-dropdown-item>
      <el-dropdown-item :class="{ active: selected === 'year' }" command="year">
        {{ $t('dateCompareSelector.options.year') }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'vel-date-compare-selector',
  props: {
    selected: {
      type: String,
      default: null
    }
  },
  methods: {
    handleCommand(period) {
      this.$emit('change', period);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dropdown-link {
  color: #409eff;
  cursor: pointer;
  font-size: 0.75rem;
}

/deep/ .el-icon-arrow-down {
  font-size: 0.65rem;
}

.active {
  font-weight: bold;
}
</style>
