<template>
  <div class="vel-page-spinner">
    <div class="vel-page-spinner__inner">
      <div class="vel-page-spinner__line" />
      <div class="vel-page-spinner__line" />
      <div class="vel-page-spinner__line" />
      <logo class="vel-page-spinner__logo" />
    </div>
  </div>
</template>

<script>
import Logo from '@/assets/svg/logo.svg';

export default {
  name: 'vel-page-spinner',
  components: {
    Logo
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$spinner-size: rem-calc(60);
$spinner-z: 200;

// line
$page-spinner__line-animation-duration: 1s;
$page-spinner__line-color: mix(black, $primary-color, 5%);

// logo
$page-spinner__logo-color: $primary-color;
$page-spinner__logo-size: rem-calc(32);

.vel-page-spinner {
  height: $spinner-size;
  overflow: hidden;
  width: $spinner-size;
  z-index: $spinner-z;

  &__inner {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  &__line {
    animation-duration: $page-spinner__line-animation-duration;
    border-left-color: $page-spinner__line-color;
    border-left-style: solid;
    border-left-width: calc(#{$spinner-size} / 25);
    border-radius: 50%;
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: calc(#{$spinner-size} / 25);
    height: 100%;
    position: absolute;
    width: 100%;

    &:nth-child(1) {
      animation: vel-page-spinner-1 1s linear infinite;
      transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
    }

    &:nth-child(2) {
      animation: vel-page-spinner-2 1s linear infinite;
      transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
    }

    &:nth-child(3) {
      animation: vel-page-spinner-3 1s linear infinite;
      transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
    }
  }

  &__logo {
    @include icon-size($page-spinner__logo-size);
    @include icon-path-fill($page-spinner__logo-color);
  }
}

@keyframes vel-page-spinner-1 {
  100% {
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes vel-page-spinner-2 {
  100% {
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes vel-page-spinner-3 {
  100% {
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
  }
}
</style>
