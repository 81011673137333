<template>
  <svg
    class="vel-spinner icon icon_spinner"
    :class="classes"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <circle
      class="icon__path"
      :id="`spinner-${hash}`"
      fill="none"
      stroke-dasharray="100.531"
      stroke-width="2"
      stroke-linecap="round"
      cx="16"
      cy="16"
      r="14"
    />
    <animate
      v-bind="{ 'xlink:href': `#spinner-${hash}` }"
      attributeName="stroke-dashoffset"
      calcMode="spline"
      values="100.5309649149; 25.1327412287; 100.5309649149"
      keyTimes="0; 0.5; 1"
      keySplines=".4 .1 0 .2; .3 .1 .5 0"
      dur="1.5s"
      repeatCount="indefinite"
    />
    <animateTransform
      v-bind="{ 'xlink:href': `#spinner-${hash}` }"
      attributeName="transform"
      attributeType="XML"
      type="rotate"
      calcMode="spline"
      values="0 16 16; 135 16 16; 450 16 16"
      keyTimes="0; 0.5; 1"
      keySplines=".4 .1 0 .2; .3 .1 .5 0"
      dur="1.5s"
      repeatCount="indefinite"
    />
    <animateTransform
      v-bind="{ 'xlink:href': `#spinner-${hash}` }"
      attributeName="transform"
      attributeType="XML"
      type="rotate"
      additive="sum"
      calcMode="spline"
      from="0 16 16"
      to="270 16 16"
      keySplines=".4 .1 0 .2; .3 .1 .5 0"
      dur="1.5s"
      repeatCount="indefinite"
    />
  </svg>
</template>

<script>
import { randomBytes } from 'crypto';

export default {
  name: 'vel-spinner',
  props: {
    modifier: {
      type: String,
      default: 'on-bright',
      validate(value) {
        return ['on-dark', 'on-bright'].includes(value);
      }
    },
    size: {
      type: String,
      default: 'medium',
      validate(value) {
        return ['small', 'medium'].includes(value);
      }
    }
  },
  computed: {
    hash() {
      return randomBytes(3).toString('hex');
    },
    classes() {
      const { name } = this.$options;
      return [`${name}_${this.modifier}`, `${name}_${this.size}`];
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$spinner-color-on-bright: $secondary-color;
$spinner-color-on-dark: white;
$spinner-size_small: rem-calc(18);
$spinner-size_small_medium-up: rem-calc(16);
$spinner-size_medium: rem-calc(37);
$spinner-size_medium_mediup-up: rem-calc(32);

.vel-spinner {
  align-items: center;
  display: flex;
  justify-content: center;

  &_on-dark {
    @include icon-path-stroke($spinner-color-on-dark);
  }

  &_on-bright {
    @include icon-path-stroke($spinner-color-on-bright);
  }

  &_small {
    @include icon-size($spinner-size_small);
    @include icon-path-stroke-width(3px);
  }

  &_medium {
    @include icon-size($spinner-size_medium);
  }
  @include breakpoint(medium) {
    &_small {
      @include icon-size($spinner-size_small);
    }

    &_medium {
      @include icon-size($spinner-size_medium);
    }
  }
}
</style>
