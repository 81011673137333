import { defineStore } from 'pinia';
import invoicesService from '@/services/invoices.service';
import router from '@/router';

export const useDataCheckSummary = defineStore('checkSummary', {
  state: () => ({
    invoices: [],
    filters: {
      tenderTypes: {},
      departments: {},
      workstations: {},
      discounts: {},
      status: null
    },
    appliedFilters: {
      tenderTypes: [],
      employeeName: '',
      invoiceNumber: [],
      invoiceNumberRange: [],
      invoiceNumberString: '',
      tenderTypeString: [],
      tenderTypeIDs: [],
      status: null
    },
    sort: {
      by: 'check',
      direction: 'desc'
    },
    pagination: {
      page: 1,
      perPage: window.localStorage.getItem('ChecksRowsPerPage') || 25,
      totalResults: 0
    },
    haveSurcharge: false,
    pending: false,
    success: null,
    error: null
  }),
  getters: {
    isPending: state => state.pending,
    isError: state => !!state.error,
    getError: state => state.error,
    isSuccess: state => state.success,

    getHaveSurcharge: state => state.haveSurcharge,

    getSortBy: state => state.sort.by,
    getSortDirection: state => state.sort.direction,

    getCurrentPage: state => state.pagination.page,
    getPerPage: state => state.pagination.perPage,
    getTotalResults: state => state.pagination.totalResults,

    getInvoices: state => state.invoices || [],
    getFilters: state => state.filters || {},

    getAppliedFilterEmployeeName: state => state.appliedFilters.employeeName || '',
    getAppliedFilterTenderTypes: state => state.appliedFilters.tenderTypes || [],
    getAppliedFilterInvoiceNumbers: state => state.appliedFilters.invoiceNumberString || '',
    getAppliedFilterTenderType: state => state.appliedFilters.tenderTypeString || [],
    getAppliedFilterStatus: state => state.appliedFilters.status || null
  },
  actions: {
    getFilterQueryParams() {
      return {
        ...router.currentRoute.query,
        limit: this.getPerPage,
        offset: this.getPerPage * (this.getCurrentPage - 1),
        sort: this.getSortBy,
        order: this.getSortDirection,
        employeeName: this.appliedFilters.employeeName,
        invoiceNumberRange: this.appliedFilters.invoiceNumberRange,
        invoiceNumber: this.appliedFilters.invoiceNumber,
        tenderIDs: this.appliedFilters.tenderTypeIDs,
        status: this.appliedFilters.status
      };
    },
    resetFilters() {
      this.filters = {
        tenderTypes: {},
        departments: {},
        workstations: {},
        discounts: {},
        status: null
      };
    },
    async getCheckSummaryAction(payload = {}) {
      const routeQuery = {
        ...payload,
        ...this.getFilterQueryParams()
      };

      this.getCheckSummaryRequest();

      try {
        const checkSummary = await invoicesService.getInvoiceSummary(routeQuery);
        this.getCheckSummarySuccess({ checkSummary });
      } catch (error) {
        this.getCheckSummaryFailure(error);
      }
    },
    getCheckSummaryRequest() {
      this.pending = true;
      this.success = null;
      this.invoices = [];
      this.error = null;
      this.resetFilters();
    },
    getCheckSummarySuccess({ checkSummary }) {
      this.pending = false;
      this.success = true;
      this.invoices = checkSummary.invoices || [];
      this.error = null;
      this.resetFilters();

      this.haveSurcharge = checkSummary.surcharge;

      if (checkSummary.invoices !== null) {
        this.invoices = checkSummary.invoices.content || [];
        this.pagination.totalResults = checkSummary.invoices.total;
        this.invoices.map(r => {
          r.totalTaxAmount = 0;
          if (r.taxes && r.taxes.length > 0) {
            r.totalTaxAmount = r.taxes.reduce((taxesAmount, tax) => taxesAmount + tax.amount, 0);
          }
          return r;
        });

        //Filters
        ['tenderTypes', 'departments', 'workstations', 'discounts'].forEach(filter => {
          if (checkSummary.filters[filter]) {
            checkSummary.filters[filter].forEach(item => {
              if (!this.filters[filter][item.name]) {
                this.filters[filter][item.name] = [item.id];
              } else {
                if (!this.filters[filter][item.name].includes(item.id)) {
                  this.filters[filter][item.name].push(item.id);
                }
              }
            });
          }
        });
      }
    },
    getCheckSummaryFailure(error) {
      this.pending = false;
      this.success = false;
      this.checkSummary = null;
      this.error = error;
      this.resetFilters();
    },
    setSortBy(value) {
      this.sort.by = value;
    },
    setSortDirection(direction) {
      this.sort.direction = direction;
    },
    setCurrentPage(value) {
      this.pagination.page = value;
    },
    setPerPage(value) {
      window.localStorage.setItem('ChecksRowsPerPage', value);
      this.pagination.perPage = value;
    },
    setEmployeeNameFilter(value) {
      this.appliedFilters.employeeName = value;
    },
    setInvoiceNumberFilter(value) {
      this.appliedFilters.invoiceNumber = value;
    },
    setInvoiceNumberRangeFilter(value) {
      this.appliedFilters.invoiceNumberRange = value;
    },
    setInvoiceNumberStringFilter(value) {
      this.appliedFilters.invoiceNumberString = value;
    },
    setTenderTypeStringFilter(value) {
      this.appliedFilters.tenderTypeString = value;
    },
    setTenderTypeIDsFilter(value) {
      this.appliedFilters.tenderTypeIDs = value;
    },
    setStatusFilter(value) {
      this.appliedFilters.status = value;
    }
  }
});
