<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="600px"
    right
    id="timeAttendanceForm-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarTimeAttendanceForm"
    @hidden="
      timeAttendance = { code: null, name: '', payrollNumber: '' };
      $refs.form.reset(timeAttendance);
    "
  >
    <template #header>
      <h6 class="bg-gray-200 bg-light w-100 mb-0 text-center">
        {{
          $t(
            timeAttendance && timeAttendance.id
              ? 'employeeCreate.timeAttendance.title'
              : 'employeeCreate.timeAttendanceEdit.title'
          )
        }}
      </h6>
    </template>

    <template #footer="{ hide }">
      <div
        class="d-flex bg-light text-dark align-items-center justify-content-between px-3 py-2"
        style="border-top: 2px solid #d6d6d8;"
      >
        <b-button
          :disabled="!($refs.form && $refs.form.enableSaveChanges)"
          variant="primary"
          @click="updateTimeAttendance()"
        >
          {{ $t(timeAttendance && timeAttendance.id ? 'employeeCreate.btns.saveChanges' : 'employeeCreate.btns.save') }}
        </b-button>
        <b-button
          variant="outline-primary"
          @click="
            hide();
            $refs.form.reset(timeAttendance);
            $emit('update:timeAttendance', null);
          "
        >
          {{ $t('employeeCreate.btns.cancel') }}
        </b-button>
      </div>
    </template>
    <b-container fluid class="p-4" v-loading="pending">
      <time-attendance-form
        :licenceNumber="employee.licenceNumber"
        :time-attendance.sync="timeAttendance"
        ref="form"
      ></time-attendance-form>
    </b-container>
  </b-sidebar>
</template>
<script>
import { patchTimeAttendanceDepartment, postTimeAttendanceDepartment } from '@/services/timeAttendances';
import TimeAttendanceForm from '@/private/components/TimeAttendance/components/TimeAttendanceForm';

export default {
  name: 'employee-sidebar-form-time-attendance',
  components: {
    TimeAttendanceForm
  },
  props: {
    timeAttendance: {
      type: Object,
      default: () => ({ code: null, name: '', payrollNumber: '' })
    },
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    licenceNumber: {
      type: Number,
      default: 0
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    employeeTimeAttendance() {
      return {
        ...this.$refs.form.form,
        code: Number(this.$refs.form.form.code),
        licenceNumber: this.licenceNumber || this.employee.licenceNumber || null
      };
    }
  },
  methods: {
    async updateTimeAttendance() {
      try {
        this.$emit('update:pending', true);
        if (this.employeeTimeAttendance.id) {
          await patchTimeAttendanceDepartment(this.employeeTimeAttendance.id, this.employeeTimeAttendance);
        } else {
          await postTimeAttendanceDepartment(this.employeeTimeAttendance);
        }
        this.$emit('update:pending', false);
        this.$emit('success', this.employeeTimeAttendance);
        this.$refs.sidebarTimeAttendanceForm.hide();
        this.$refs.form.reset();
      } catch (e) {
        this.$emit('update:error', true);
        this.$emit('update:pending', false);
        this.$emit('failure', this.employeeTimeAttendance);
      }
    }
  }
};
</script>
