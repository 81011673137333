<template>
  <div>
    <b-row class="mb-4 mt-4">
      <b-col cols="12" md="4" class="d-flex align-items-center mb-2 mb-md-0">{{ $t('jobRoles.add.label') }}</b-col>
      <b-col>
        <b-form-input id="name" :placeholder="$t('jobRoles.add.placeholder')" v-model="roleName"></b-form-input>
      </b-col>
    </b-row>
    <b-button v-if="!isEdit" :disabled="roleName === ''" variant="primary" @click="addRole()">
      {{ $t('jobRoles.add.btn') }}
    </b-button>
  </div>
</template>

<script>
import { postEmployeeTask } from '@/services/employees.service';

export default {
  name: 'job-role-form',
  model: {},
  props: {
    licenceNumber: {
      type: Number,
      default: null
    },
    jobRole: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      roleName: '',
      enableSaveChanges: false
    };
  },
  watch: {
    jobRole(value) {
      this.roleName = (value && value.name) || '';
    },
    roleName(value) {
      this.enableSaveChanges = value !== this.jobRole.name;
    }
  },
  computed: {
    employeeTask() {
      return {
        name: this.roleName,
        licenceNumber: this.licenceNumber,
        remoteId: this.jobRole && this.jobRole.remoteId
      };
    },
    isEdit() {
      return !!(this.jobRole && this.jobRole.id);
    }
  },
  methods: {
    async addRole() {
      try {
        this.$emit('update:pending', true);
        await postEmployeeTask(this.employeeTask);
        this.$emit('update:pending', false);
        this.$emit('success', this.employeeTask);
        this.roleName = '';
      } catch (e) {
        this.$emit('update:error', true);
        this.$emit('update:pending', false);
        this.$emit('failure', this.employeeTask);
      }
    }
  }
};
</script>
