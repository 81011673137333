import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import salesService from '@/services/sales.service';
import { useDataSalesIndicators } from '@/stores/data/sales/indicators.module';
import discountsModuleHelper from '@/stores/discounts.module.helper';
import groupsService from '@/services/groups.service';
import { createCollectionState, getSelectors, removeAll, setAll } from '@/helpers/stores.helpers';

const { selectAll } = getSelectors();

const sortPermissions = permissionsWithFullName => {
  const sortedUsersPermissionsWithFullName = [...permissionsWithFullName];

  sortedUsersPermissionsWithFullName.forEach(userPermissions => {
    const sortedPermissions = [];

    userPermissions.permissions.groups.forEach(groupPermission => {
      sortedPermissions.push({
        permission: groupPermission,
        name: groupPermission.permission
      });
    });

    userPermissions.sortedPermissions = sortedPermissions;
  });

  return sortedUsersPermissionsWithFullName;
};

export const useDataGroupsPermissionsGroup = defineStore('groupsPermissionsGroup', {
  state: () =>
    createCollectionState({
      status: {},
      errors: {}
    }),
  getters: {
    getGroupPermissionsError(_) {
      return this.errors.getGroupPermissionsError || null;
    },
    isLoadingGroupPermissions: state => !!state.status.isLoadingPermissions,
    permissions: selectAll,
    hasPermissions(_) {
      return this.permissions.length > 0;
    },
    permissionsWithFullName(_) {
      return this.permissions.map(permission => ({
        ...permission,
        name: `${permission.firstName} ${permission.lastName}`
      }));
    },
    sortedPermissions(_) {
      return this.hasPermissions ? sortPermissions(this.permissionsWithFullName) : [];
    }
  },
  actions: {
    getPermissions(groupId) {
      this.getPermissionsRequest();

      groupsService.getGroupPermissions(getToken(), groupId).then(
        permissions => {
          this.getPermissionsSuccess(permissions);
        },
        error => {
          this.getPermissionsFailure(error);
        }
      );
    },
    resetGroupPermissions() {
      this.reset();
    },
    clearGetGroupPermissionsError() {
      this.errors = { ...this.errors, getPermissionsError: null };
    },
    getPermissionsRequest() {
      this.status = { ...this.status, isLoadingPermissions: true };
      this.data = removeAll(this.data);
      this.errors = { ...this.errors, getPermissionsError: null };
    },
    getPermissionsSuccess(permissions) {
      this.status = { ...this.status, isLoadingPermissions: false };
      this.data = setAll(permissions, this.data);
      this.errors = { ...this.errors, getPermissionsError: null };
    },
    getPermissionsFailure(error) {
      this.status = { ...this.status, isLoadingPermissions: false };
      this.data = removeAll(this.data);
      this.errors = { ...this.errors, getPermissionsError: error };
    },
    reset() {
      this.status = {};
      this.permissions = [];
      this.errors = {};
    }
  }
});
