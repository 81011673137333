<template>
  <el-upload
    class="vel-upload__uploader"
    ref="upload"
    :accept="accept"
    :action="action"
    :multiple="multiple"
    :limit="fileUploadLimit"
    :fileList="fileList"
    :auto-upload="autoUpload"
    :httpRequest="submitUpload"
    :onChange="handleSelectFile"
    :onExceed="handleExceed"
    :onRemove="handleRemove"
  >
    <vel-button class="uploader__button" slot="trigger" type="secondary">
      {{ $t('defaults.upload.selectAFile') }}
    </vel-button>
  </el-upload>
</template>

<script>
import FileUploadMixin from '@/mixins/file-upload-mixin';
import VelButton from '@/components/button/VelButton';

export default {
  name: 'vel-file-upload',
  components: {
    VelButton
  },
  mixins: [FileUploadMixin],
  props: {
    accept: {
      type: String
    },
    action: {
      type: String,
      default() {
        return '/';
      }
    },
    fileUploadLimit: {
      type: Number,
      default() {
        return 1;
      }
    },
    multiple: {
      type: Boolean,
      default() {
        return false;
      }
    },
    autoUpload: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-upload {
  width: 100%;

  .uploader__button {
    width: 100%;
  }
}
</style>
