import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import { createCollectionState, getSelectors, removeAll, setOne } from '@/helpers/stores.helpers';
import { useDataAccount } from '@/stores/data/account.module';

const { selectTotal } = getSelectors();

export const useDataUsersChangePassword = defineStore('usersChangePassword', {
  state: () =>
    createCollectionState({
      loading: true,
      pending: true,
      error: null,
      filter: ''
    }),
  getters: {
    selectTotal
  },
  actions: {
    async changePassword({ currentPassword, newPassword }) {
      try {
        this.changePasswordRequest();
        const accountStore = useDataAccount();
        const result = await usersService.changePassword(getToken(), currentPassword, newPassword, accountStore.userId);
        this.changePasswordSuccess(result);
      } catch (error) {
        this.changePasswordFailure(error);
      }
    },
    changePasswordRequest() {
      this.loading = true;
      this.error = null;
    },
    changePasswordSuccess(result) {
      this.data = setOne(result, this.data, 'status');
      this.error = null;
      this.loading = false;
    },
    changePasswordFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    clearMessage() {
      this.data = removeAll(this.data);
      this.error = null;
    }
  }
});
