<template>
  <vel-page
    :multiple-locations="locations && locations.length > 1"
    :show-selector-button="true"
    class="report"
    v-bind="pageAttrs"
  >
    <template v-slot:export>
      <vel-button
        v-if="!isDataLoading"
        type="primary"
        @click="exportXLS"
        :icon="isMediumUp ? 'file-excel' : undefined"
        class="vel-button"
      >
        <vel-icon v-if="isMediumDown" name="file-download" />
        {{ isMediumUp ? $t('table.tableExport.button.value') : '' }}
      </vel-button>
    </template>
    <div
      v-if="invalidLocations && !isVersionLoading && isGenerated"
      style="background-color: #fff2cc; margin-bottom: 1rem; padding: 1rem;"
    >
      <template v-if="getLocationSelectorSelected">
        {{ $t('invalidVersions.requiredVersion') }}
      </template>
      <template v-else>
        {{ $t('invalidVersions.requiredVersionMultiLocations') }}
        <a href="javascript://" @click="showLocationsList = !showLocationsList">{{ $t('invalidVersions.collapse') }}</a>
        <ul v-if="showLocationsList" style="margin-top: 1rem;">
          <li v-for="location in invalidLocations" :key="location.id">
            {{ location.name }}
          </li>
        </ul>
      </template>
    </div>
    <p
      v-if="showNotification && !isVersionLoading && isGenerated"
      style="background-color: #ffd2d2; margin-bottom: 1rem; padding: 1rem;"
    >
      {{ $t('invalidVersions.notice') }}
    </p>
    <template v-if="!isAllInvalid && !invalidLocations && !showNotification && !isVersionLoading && isGenerated">
      <indicators class="indicators" config-key="summary" :key="updateIndicators" :show-icon="false" secondary />
      <div class="graph">
        <vel-card v-can:show="'locations_report_show_locations_sales'">
          <locations-sales-graph v-bind="chartAttrs" :show-previous-series="true" ref="locationsSalesTop" />
        </vel-card>
        <vel-card v-can:show="'locations_report_show_item_sales_top10'">
          <items-sales-graph v-bind="chartAttrs" ref="itemsSales" />
        </vel-card>
      </div>
      <indicator-grid class="sub-indicators">
        <refunds
          :quantity="getIndicatorsRefunds"
          :amount="getIndicatorsRefundsAmount"
          :previous-amount="getIndicatorsSalesVolume"
          :percent="getIndicatorsRefundsPercent"
          :currency="getCurrencySelectorSelectedSymbol"
          ref="refunds"
        ></refunds>
        <voids
          :quantity="getIndicatorsVoids"
          :amount="getIndicatorsVoidsAmount"
          :previous-amount="getIndicatorsSalesVolume"
          :percent="getIndicatorsVoidsPercent"
          :currency="getCurrencySelectorSelectedSymbol"
          ref="voids"
        ></voids>
        <item-correction
          :quantity="getIndicatorsItemCorrections"
          :amount="getIndicatorsItemCorrectionsAmount"
          :previous-amount="getIndicatorsSalesVolume"
          :currency="getCurrencySelectorSelectedSymbol"
          ref="itemCorrection"
        ></item-correction>
      </indicator-grid>
      <div class="graph">
        <vel-card v-can:show="'locations_report_show_volume_per_tender_types'">
          <volume-per-tender-types v-bind="chartAttrs" ref="tenderTypes" />
        </vel-card>
        <vel-card v-if="hasSingleLocation" v-can:show="'locations_report_show_employees_sales_top10'">
          <employee-graph v-bind="chartAttrs" />
        </vel-card>
        <vel-card v-can:show="'locations_report_show_gross_sales_per_order_mode'">
          <zone-sales v-bind="chartAttrs" ref="zoneSales" />
        </vel-card>
        <vel-card v-can:show="'locations_report_show_workstations_sales'">
          <workstations-sales v-bind="chartAttrs" ref="workstationSales" />
        </vel-card>
        <vel-card v-can:show="'locations_report_show_gross_sales_per_department'">
          <department-sales v-bind="chartAttrs" ref="departmentSales" />
        </vel-card>
        <vel-card v-can:show="'locations_report_show_gross_sales_per_mode'">
          <mode-sales v-bind="chartAttrs" ref="modeSales" />
        </vel-card>
        <vel-card v-can:show="'locations_report_show_mode_sales_per_customers'">
          <mode-sales-per-customers v-bind="chartAttrs" ref="modeSalesPerCustomers" />
        </vel-card>
        <vel-card v-can:show="'locations_report_show_customers_per_mode'">
          <mode-customers v-bind="chartAttrs" ref="modeCustomers" />
        </vel-card>
        <vel-card v-can:show="'locations_report_show_gross_sales_per_big_division'">
          <big-division-sales-graph v-bind="chartAttrs" ref="bigDivisionSales" />
        </vel-card>
        <vel-card v-if="config.dashboard.enable_pie_payment">
          <paymode-sales v-bind="chartAttrs" />
        </vel-card>
      </div>
      <vel-card v-can:show="'locations_report_show_discounts_table'">
        <discounts-table ref="discountSales" />
      </vel-card>
    </template>
  </vel-page>
</template>

<script>
import BigDivisionSalesGraph from '@/private/components/sales/BigDivisionSalesGraph';
import { DateTime } from 'luxon';
import DepartmentSales from '@/private/components/sales/DepartmentSales';
import DeviceMixin from '@/mixins/device-mixin';
import DiscountsTable from '@/private/components/discounts/DiscountsTable';
import EmployeeGraph from '@/private/components/sales/employee/EmployeeGraph';
import GeneratedTime from '@/mixins/generated-time-mixin';
import IndicatorGrid from '@/components/indicator/IndicatorGrid';
import Indicators from '@/private/shared/indicators/Indicators';
import ItemCorrection from '@/private/shared/indicators/indicator/ItemCorrection';
import ItemsSalesGraph from '@/private/components/sales/items/ItemTop10Sales';
import LocationsSalesGraph from '@/private/components/locations/LocationsSalesGraph';
import ModeCustomers from '@/private/components/sales/ModeCustomers';
import ModeSales from '@/private/components/sales/ModeSales';
import ModeSalesPerCustomers from '@/private/components/sales/ModeSalesPerCustomers';
import PaymodeSales from '@/private/components/sales/PaymodeSales';
import Refunds from '@/private/shared/indicators/indicator/Refunds';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelIcon from '@/components/icon/VelIcon';
import VelPage from '@/components/page/VelPage';
import Voids from '@/private/shared/indicators/indicator/Voids';
import VolumePerTenderTypes from '@/private/components/sales/VolumePerTenderTypes';
import WorkstationsSales from '@/private/components/sales/WorkstationsSales';
import XLSX from 'xlsx';
import ZoneSales from '@/private/components/sales/ZoneSales';
import { environment } from '@/configs';
import { formatVersion } from '@/helpers/versions.module.helper';
import { getToken } from '@/helpers/token.helper';
import router from '@/router';
import { getDateFormatFromUserConfig, today } from '@/helpers/date.helpers';
import transactionsService from '@/services/transactions.service';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { mapState as mapPiniaState } from 'pinia';
import { useUICurrencySelector } from '@/stores/ui/currency-selector.module';
import { useUIDateRangeSelector } from '@/stores/ui/date-range-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { mapActions as mapPiniaActions } from 'pinia/dist/pinia';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataSalesZones } from '@/stores/data/sales/zones.module';
import { useDataSalesModes } from '@/stores/data/sales/modes.module';
import { useDataSalesWorkstations } from '@/stores/data/sales/workstations.module';
import { useDataSalesTenderTypes } from '@/stores/data/sales/tender-types.module';
import { useDataSalesProducts } from '@/stores/data/sales/products.module';
import { useDataSalesLocations } from '@/stores/data/sales/locations.module';
import { getIndicatorsByCurrency, useDataSalesIndicators } from '@/stores/data/sales/indicators.module';
import { useDataSalesDepartments } from '@/stores/data/sales/departments.module';
import { useDataSalesBigDivisions } from '@/stores/data/sales/big-divisions.module';
import { useDataSalesHourly } from '@/stores/data/sales/hourly-sales.module';
import { useDataLocationsVersionsStore } from '@/stores/data/locations/versions.module';
import { useDataConfig } from '@/stores/data/config.module';

export default {
  name: 'SummaryReportPage',
  components: {
    Indicators,
    VelButton,
    VelPage,
    Refunds,
    Voids,
    IndicatorGrid,
    DepartmentSales,
    ZoneSales,
    LocationsSalesGraph,
    ModeSalesPerCustomers,
    ItemsSalesGraph,
    PaymodeSales,
    BigDivisionSalesGraph,
    DiscountsTable,
    ModeSales,
    ModeCustomers,
    VelCard,
    WorkstationsSales,
    ItemCorrection,
    VolumePerTenderTypes,
    VelIcon,
    EmployeeGraph
  },
  mixins: [GeneratedTime, DeviceMixin],
  data() {
    return {
      updateIndicators: 0,
      updateCharts: 1,
      showLocationsList: false,
      previousIndicators: {
        itemCorrectionsAmount: 0,
        voidsAmount: 0,
        refundsAmount: 101
      }
    };
  },
  setup() {
    const salesZonesStore = useDataSalesZones();
    const salesModesStore = useDataSalesModes();
    const salesWorkstationsStore = useDataSalesWorkstations();
    const salesTenderTypesStore = useDataSalesTenderTypes();
    const salesProductsStore = useDataSalesProducts();
    const salesLocationsStore = useDataSalesLocations();
    const salesIndicators = useDataSalesIndicators();
    const salesDepartments = useDataSalesDepartments();
    const salesBigDivisions = useDataSalesBigDivisions();
    const salesHourly = useDataSalesHourly();
    const locationsVersions = useDataLocationsVersionsStore();
    return {
      isPendingZone: salesZonesStore.isPending,
      isPendingMode: salesModesStore.isPending,
      isPendingWorkstation: salesWorkstationsStore.isPending,
      isPendingTenderType: salesTenderTypesStore.isPending,
      isPendingProduct: salesProductsStore.isItemSalesLoading,
      isPendingLocation: useDataSalesLocations.isLocationSalesLoading,
      isPendingIndicators: salesIndicators.isIndicatorsLoading,
      salesIndicators,
      isPendingDepartments: salesDepartments.isPending,
      isPendingBigDivisions: salesBigDivisions.isPending,
      isPendingHourly: salesHourly.areSalesLoading,
      isPendingVersion: locationsVersions.isLoading
    };
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, ['locations']),
    ...mapPiniaState(useUIGroupSelector, ['getGroupSelectorSelected']),
    ...mapPiniaState(useUILocationSelector, ['isGenerated']),
    ...mapPiniaState(useUILocationSelector, [
      'getLocationSelectorSelected',
      'hasSingleLocation',
      'getLocationSelectorSelectedMultiple'
    ]),
    ...mapPiniaState(useUICurrencySelector, ['getCurrencySelectorSelectedSymbol']),
    ...mapPiniaState(useUIDateRangeSelector, ['getDateSelectorSelectedRange']),
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useDataLocationsVersionsStore, {
      isVersionValid: 'isVersionValid',
      isVersionLoading: 'loading'
    }),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById', 'getLocationsIsLoading', 'locationsBySelectedGroup']),
    ...mapPiniaState(useDataSalesIndicators, [
      'getIndicatorsVoids',
      'getIndicatorsVoidsAmount',
      'getIndicatorsRefundsAmount',
      'getIndicatorsSalesVolume',
      'getIndicatorsRefunds',
      'getIndicatorsItemCorrections',
      'getIndicatorsItemCorrectionsAmount',
      'getIndicatorsRefundsPercent',
      'getIndicatorsVoidsPercent'
    ]),
    isDataLoading() {
      return (
        this.isPendingVersion ||
        this.isPendingIndicators ||
        this.isPendingHourly ||
        this.isPendingLocation ||
        this.isPendingProduct ||
        this.isPendingTenderType ||
        this.isPendingZone ||
        this.isPendingWorkstation ||
        this.isPendingDepartments ||
        this.isPendingMode ||
        this.isPendingBigDivisions
      );
    },
    chartAttrs() {
      return {
        locationId: this.getLocationSelectorSelected,
        currencyId: this.getCurrencySelectorSelectedSymbol,
        from: this.getDateSelectorSelectedRange[0],
        to: this.getDateSelectorSelectedRange[1],
        limit: this.get.charts_size_limitation.value
      };
    },
    config() {
      return environment;
    },
    pageAttrs() {
      return {
        enableToolbar: true,
        enableCurrency: true,
        enableDateRange: true,
        title: this.$t('pages.reports.summary'),
        showCrumbs: false,
        failed: this.hasPageFailed,
        loading: this.isPageLoading,
        generatedTime: this.generatedTime,
        showDateCompare: this.showLocationsSales
      };
    },
    hasPageFailed() {
      return this.false;
    },
    isPageLoading() {
      return this.getLocationsIsLoading;
    },
    showLocationsSales() {
      return !this.getLocationSelectorSelected || (this.locations && this.locations.length) === 1;
    },
    isAllInvalid() {
      const location = this.getLocationsById(this.getLocationSelectorSelected);
      if (this.getLocationSelectorSelected && formatVersion((location && location.version) || 0) < 9620) {
        return true;
      }
      const locations = (this.getLocationSelectorSelectedMultiple || [])
        .map(k => {
          const l = this.getLocationsById(k.value) || {};
          return { ...l, version: formatVersion(l.version) };
        })
        .filter(l => l.version < 9620);
      return (
        locations.length === this.getLocationSelectorSelectedMultiple && this.getLocationSelectorSelectedMultiple.length
      );
    },
    invalidLocations() {
      const location = this.getLocationsById(this.getLocationSelectorSelected);
      if (this.getLocationSelectorSelected && formatVersion((location && location.version) || 0) >= 9620) {
        return false;
      }
      const locations = (this.getLocationSelectorSelectedMultiple || [])
        .map(k => {
          const l = this.getLocationsById(k.value) || {};
          return { ...l, version: formatVersion(l.version) };
        })
        .filter(l => l.version < 9620);
      if (!locations.length) {
        return null;
      }
      return locations;
    },
    showNotification() {
      const isUpdated = this.getLocationSelectorSelected
        ? formatVersion(this.getLocationsById(this.getLocationSelectorSelected).version) >= 9620
        : !this.invalidLocations;
      const hasInvalidData = this.getLocationSelectorSelected
        ? !this.versionIsValid(this.getLocationSelectorSelected)
        : !!(this.getLocationSelectorSelectedMultiple || []).filter(x => !this.versionIsValid(x.value)).length;

      if (!this.getLocationSelectorSelected && !isUpdated) {
        const locations = (this.getLocationSelectorSelectedMultiple || [])
          .map(k => {
            const l = this.getLocationsById(k.value) || {};
            return { ...l, version: formatVersion(l.version) };
          })
          .filter(l => l.version >= 9620)
          .filter(l => !this.versionIsValid(l.id));

        if (locations.length) {
          return true;
        }
      }
      return isUpdated && hasInvalidData;
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    ...mapPiniaActions(useDataLocationsVersionsStore, ['getVersions']),

    versionIsValid(locationId = null, minimalVersion = '9.62.0') {
      // @todo refactor so that we no longer have to pass getters as arguments
      return (
        !this.isVersionLoading &&
        this.isVersionValid(
          minimalVersion,
          locationId ? locationId : this.getLocationSelectorSelected,
          locationId ? null : this.getGroupSelectorSelected,
          this.getDateSelectorSelectedRange[0]
        )
      );
    },
    autoUpdate() {
      this.updateIndicators += 2;
      this.updateCharts += 2;
    },

    getCurrentGroupName() {
      const selectedGroup = (this.getGroupsRows || []).filter(group => group.id === this.getGroupSelectorSelected);
      if (!selectedGroup.length) {
        return null;
      }
      return `${this.$t('exports.groupLabel')} ${selectedGroup[0].name}`;
    },
    /* eslint-disable */
    exportXLS() {
      this.$ga.event('report', 'download', this.$route.name);
      const workBook = XLSX.utils.book_new();
      const workSheet = XLSX.utils.json_to_sheet([], { skipHeader: true });
      const workSheetZoneSales = XLSX.utils.json_to_sheet([], { skipHeader: true });
      let data = [[], [], [], [], [], [], []];
      data.push(['', this.$t('summary.table.headers.amount'), this.$t('summary.table.headers.qty')]);
      data.push([
        this.$t('dashboard.salesVolume.title'),
        Number(this.salesIndicators.getIndicatorsSalesVolume.toFixed(2)),
        ''
      ]);
      data.push([
        this.$t('dashboard.discountsVolume.title'),
        Number(this.salesIndicators.getIndicatorsDiscountsVolume.toFixed(2)),
        ''
      ]);
      data.push([
        this.$t('dashboard.netSalesVolume.title'),
        Number(this.salesIndicators.getIndicatorsNetSalesVolume.toFixed(2)),
        ''
      ]);
      data.push([
        this.$t('dashboard.taxesVolume.title'),
        Number(this.salesIndicators.getIndicatorsTaxesVolume.toFixed(2)),
        ''
      ]);
      data.push([
        this.$t('dashboard.averageSales.title'),
        Number(this.salesIndicators.getIndicatorsAverageSales.toFixed(2)),
        ''
      ]);
      data.push([
        this.$t('dashboard.cashDeposit.title'),
        Number((this.salesIndicators.getIndicatorsCashDeposit || 0).toFixed(2)),
        ''
      ]);
      data.push([
        this.$t('dashboard.paidInsAmount.title'),
        Number(this.salesIndicators.getIndicatorsPaidInsAmount.toFixed(2)),
        ''
      ]);
      data.push([
        this.$t('dashboard.netCashAmount.title'),
        Number((this.salesIndicators.getIndicatorsNetCashAmount || 0).toFixed(2)),
        ''
      ]);
      data.push([
        this.$t('dashboard.tipsTotalAmount.title'),
        Number(this.salesIndicators.getIndicatorTipsTotalAmount.toFixed(2)),
        ''
      ]);
      if (this.salesIndicators.getIndicatorsCashDiscounts !== 0) {
        data.push([
          this.$t('dashboard.cashDiscounts.title'),
          Number(this.salesIndicators.getIndicatorsCashDiscounts.toFixed(2)),
          ''
        ]);
      }
      if (this.salesIndicators.getIndicatorsSurchargeAmount !== 0) {
        data.push([
          this.$t('dashboard.surcharge.title'),
          Number(this.salesIndicators.getIndicatorsSurchargeAmount.toFixed(2)),
          ''
        ]);
      }

      if (this.versionIsValid(null, '9.67.0')) {
        data.push([
          this.$t('dashboard.paidInsAmount.title'),
          Number(this.salesIndicators.getIndicatorsPaidInsAmount.toFixed(2)),
          ''
        ]);
        data.push([
          this.$t('dashboard.netCashAmount.title'),
          Number((this.salesIndicators.getIndicatorsNetCashAmount || 0).toFixed(2)),
          ''
        ]);
      }

      if (this.versionIsValid(null, '9.53.1')) {
        data.push([
          this.$t('dashboard.tipsTotalAmount.title'),
          Number(this.salesIndicators.getIndicatorTipsTotalAmount.toFixed(2)),
          ''
        ]);
      }
      data.push([]);
      data.push([this.$t('dashboard.mealCount.title'), '', this.salesIndicators.getIndicatorsMeals]);
      data.push([this.$t('dashboard.orderCount.title'), '', this.salesIndicators.getIndicatorsOrders]);
      data.push([this.$t('dashboard.customersCount.title'), '', this.salesIndicators.getIndicatorsCustomers]);
      data.push([this.$t('dashboard.noSales.title'), '', this.salesIndicators.getIndicatorsNoSales]);
      data.push([]);
      data.push([
        this.$t('dashboard.refunds.title'),
        Number(this.$refs.refunds.amount, ''),
        this.$refs.refunds.quantity
      ]);
      data.push([
        this.$t('dashboard.voids.title'),
        Number(this.$refs.voids.amount.toFixed(2)),
        this.$refs.voids.quantity
      ]);
      data.push([
        this.$t('dashboard.itemCorrection.title'),
        Number(this.$refs.itemCorrection.amount.toFixed(2)),
        this.$refs.itemCorrection.quantity
      ]);
      data = [...data, []];

      data = [...data, [], []];
      data.push([
        this.$t('locations.sales.graph.title').replace('(TOP {limit})', ''),
        this.$t('summary.table.headers.amount')
      ]);
      data = [...data, []];

      this.$refs.locationsSalesTop.labels.forEach((v, k) =>
        data.push([v, Number(this.$refs.locationsSalesTop.series[0].data[k].toFixed(2))])
      );

      data = [...data, [], []];
      data.push([
        this.$t('items.sales.graph.title', { limit: this.get.charts_size_limitation.value }),
        this.$t('summary.table.headers.amount')
      ]);
      data = [...data, []];

      this.$refs.itemsSales.labels.forEach((v, k) =>
        data.push([v, Number(this.$refs.itemsSales.series[0].data[k].toFixed(2))])
      );

      data = [...data, [], []];
      data.push([
        this.$t('sales.volumePerTenderTypes.title').replace('(TOP {limit})', ''),
        this.$t('summary.table.headers.amount')
      ]);
      data = [...data, []];

      this.$refs.tenderTypes.getLabels.forEach((v, k) =>
        data.push([v, Number(this.$refs.tenderTypes.getSeries[0].data[k].toFixed(2))])
      );

      data = [...data, [], []];
      data.push([
        this.$t('sales.zoneChart.title').replace('(TOP {limit})', ''),
        this.$t('summary.table.headers.amount')
      ]);
      data = [...data, []];

      this.$refs.zoneSales.getLabels.forEach((v, k) =>
        data.push([v, Number(this.$refs.zoneSales.getSeries[0].data[k].toFixed(2))])
      );

      data = [...data, [], []];
      data.push([
        this.$t('sales.workstationsSalesChart.title').replace('(TOP {limit})', ''),
        this.$t('summary.table.headers.amount')
      ]);
      data = [...data, []];

      this.$refs.workstationSales.labels.forEach((v, k) =>
        data.push([v, Number(this.$refs.workstationSales.series[0].data[k].toFixed(2))])
      );

      data = [...data, [], []];
      data.push([
        this.$t('sales.departmentChart.title').replace('(TOP {limit})', ''),
        this.$t('summary.table.headers.amount')
      ]);
      data = [...data, []];

      this.$refs.departmentSales.getLabels.forEach((v, k) =>
        data.push([v, Number(this.$refs.departmentSales.getSeries[0].data[k].toFixed(2))])
      );

      data = [...data, [], []];
      data.push([
        this.$t('sales.modeChart.title').replace('(TOP {limit})', ''),
        this.$t('summary.table.headers.amount')
      ]);
      data = [...data, []];

      this.$refs.modeSales.getLabels.forEach((v, k) =>
        data.push([v, Number((this.$refs.modeSales.getSeries[0].data[k] || 0).toFixed(2))])
      );

      data = [...data, [], []];
      data.push([
        this.$t('sales.modeSalesPerCustomersChart.title').replace('(TOP {limit})', ''),
        this.$t('summary.table.headers.amount')
      ]);
      data = [...data, []];

      this.$refs.modeSalesPerCustomers.labels.forEach((v, k) =>
        data.push([v, Number(this.$refs.modeSalesPerCustomers.series[0].data[k].toFixed(2))])
      );

      data = [...data, [], []];
      data.push([
        this.$t('sales.modeCustomersChart.title').replace('(TOP {limit})', ''),
        this.$t('summary.table.headers.amount')
      ]);
      data = [...data, []];

      this.$refs.modeCustomers.getLabels.forEach((v, k) =>
        data.push([v, Number(this.$refs.modeCustomers.getSeries[0].data[k].toFixed(2))])
      );

      data = [...data, [], []];
      data.push([
        this.$t('sales.bigDivisionChart.title').replace('(TOP {limit})', ''),
        this.$t('summary.table.headers.amount')
      ]);
      data = [...data, []];

      this.$refs.bigDivisionSales.getLabels.forEach((v, k) =>
        data.push([v, Number(this.$refs.bigDivisionSales.getSeries[0].data[k].toFixed(2))])
      );

      data = [...data, [], []];
      const regularDiscounts = this.$refs.discountSales.discountsData.filter(x => x.salesDiscountsOrigin !== '2.00');

      const cashDiscounts = this.$refs.discountSales.discountsData.filter(x => x.salesDiscountsOrigin === '2.00');

      if (regularDiscounts.length) {
        data.push([
          this.$t('discountsTable.title'),
          this.$t('summary.table.headers.amount'),
          this.$t('summary.table.headers.qty'),
          this.$t('discountsTable.columns.percentage.title')
        ]);
        data = [...data, []];
      }

      regularDiscounts.forEach((v, k) =>
        data.push([v.name, Number(Number(v.discountsAmount).toFixed(2)), v.quantity, Number(v.percentage)])
      );

      data.push([[]]);

      if (this.salesIndicators.getIndicatorsCashDiscounts !== 0) {
        const subTotalAmount = regularDiscounts.reduce((a, b) => a + Number(b.discountsAmount), 0);
        const totalQuantity = this.$refs.discountSales.discountsData.reduce((a, b) => a + Number(b.quantity), 0);
        const totalPercent = this.$refs.discountSales.discountsData.reduce((a, b) => a + Number(b.percentage), 0);
        const subTotalQuantity = regularDiscounts.reduce((a, b) => a + Number(b.quantity), 0);
        const cashDiscountQuantity = cashDiscounts.reduce((a, b) => a + Number(b.quantity), 0);

        if (this.salesIndicators.getIndicatorsCashDiscounts > 0 && regularDiscounts.length) {
          data.push([this.$t('discount.discounts.subtotal'), Number(subTotalAmount.toFixed(2)), subTotalQuantity]);
          data.push([[]]);
        }

        cashDiscounts.forEach((v, k) =>
          data.push([
            this.$t('dashboard.cashDiscounts.title'),
            Number(Number(v.discountsAmount).toFixed(2)),
            v.quantity,
            Number(v.percentage)
          ])
        );

        data.push([[]]);

        data.push([
          this.$t('giftCard.total'),
          Number((this.salesIndicators.getIndicatorsCashDiscounts + subTotalAmount).toFixed(2)),
          totalQuantity,
          totalPercent
        ]);
      }

      XLSX.utils.sheet_add_aoa(workSheet, data);

      console.log(this.$refs.zoneSales.getLabels);

      const title = this.$t('pages.reports.summary');
      const i18n = this.$t('export');
      const toDay = today().startOf('day').toISO();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;
      const locationTitle = this.getLocationSelectorSelected
        ? this.getLocationsById(this.getLocationSelectorSelected) &&
          (this.getLocationsById(this.getLocationSelectorSelected).reportingDisplayName ||
            this.getLocationsById(this.getLocationSelectorSelected).name)
        : this.getCurrentGroupName() || this.$t('exports.allLocationsTitle');
      if (title || (from && to)) {
        const diff = DateTime.fromISO(to).plus({ day: 1 }).diff(DateTime.fromISO(from), ['days', 'hours']).toObject();

        const dateRange = [
          [DateTime.fromISO(from).toFormat(getDateFormatFromUserConfig())],
          [i18n.to],
          [DateTime.fromISO(to).toFormat(getDateFormatFromUserConfig())],
          [diff.days],
          [i18n.day]
        ];

        const ws_data = [
          [],
          [],
          [title.toUpperCase()],
          [locationTitle],
          [dateRange[0], dateRange[1], dateRange[2], dateRange[3], dateRange[4]]
        ];

        XLSX.utils.sheet_add_aoa(workSheet, ws_data);

        workSheet['!merges'] = [
          { s: { c: 0, r: 2 }, e: { c: 8, r: 2 } },
          { s: { c: 0, r: 3 }, e: { c: 8, r: 3 } }
        ];
      }

      XLSX.utils.book_append_sheet(workBook, workSheet, this.$t('pages.reports.summary'));
      workSheet['!cols'] = [{ wch: 50 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
      //      XLSX.utils.book_append_sheet(workBook, workSheetRefunds, this.$t('voids.refundsItems.title'));
      //      XLSX.utils.book_append_sheet(workBook, workSheetCorrections, this.$t('voids.itemCorrections.title'));

      XLSX.writeFile(workBook, this.$t('summary.reportFileName'));
    }
  },
  beforeDestroy() {
    this.gen(false);
  },
  async mounted() {
    await this.getVersions();
    const toDay = today().startOf('day').toISO();
    const from = router.currentRoute.query.from || toDay;
    const to = router.currentRoute.query.to || from || toDay;

    const routeQuery = {
      ...router.currentRoute.query,
      from: DateTime.fromISO(from).toISO(),
      to: DateTime.fromISO(to).toISO()
    };
    await transactionsService
      .getTransactionsIndicators(getToken(), routeQuery)
      .then(res => (routeQuery.currencyId ? getIndicatorsByCurrency(res, routeQuery.currencyId) : res[0]));

    // this.generateTimeAt('data/sales/indicators/getIndicatorsSuccess');
  }
};
</script>
<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

.indicators {
  margin-top: 1rem;
}

/*
/deep/ .indicator-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  grid-template: none;
  justify-content: space-between;

  > div:nth-child(-n + 7) {
    flex: 0 0 calc(20% - 1.25rem);
    width: 20%;
  }

  > div {
    margin-bottom: 1.25rem;
    width: auto;
  }
}
*/

.sub-indicators.indicator-grid {
  @include breakpoint(medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(large) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include breakpoint(xlarge) {
    grid-gap: 4.25rem;
    grid-template-columns: repeat(3, 1fr);
  }
}

.vel-button {
  @include noPrint;

  background-color: $mountain-meadow;
  border-color: $mountain-meadow;
  direction: rtl;

  &:hover {
    background-color: mix(black, $mountain-meadow, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}
</style>
