<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="70%"
    right
    id="history-sidebar"
    title="Sidebar"
    shadow
    backdrop
    :v-loading="loading"
  >
    <template #header="{ hide }">
      <div class="bg-gray-200 bg-light w-100">
        <b-button variant="outline-primary" @click="hide">
          <b-icon icon="chevron-compact-left" aria-hidden="true"></b-icon>
          {{ $t('jobRoles.back') }}
        </b-button>
        <span style="margin-left: calc(50% - 100px);" class="bg-light">{{ $t('employeeCreate.history.title') }}</span>
      </div>
    </template>
    <template #footer="{ hide }">
      <div class="d-flex bg-light text-dark align-items-center px-3 py-2" style="border-top: 2px solid #d6d6d8;">
        <b-button variant="primary" @click="hide">{{ $t('jobRoles.close') }}</b-button>
      </div>
    </template>
    <b-table
      style="margin: 1.5rem 1rem;"
      thead-class="bg-info"
      striped
      small
      hover
      bordered
      fixed
      primary-key="id"
      :items="history"
      :fields="fields"
      responsive="sm"
      ref="table"
    >
      <template #cell(field)="scope">
        {{ $t('employeeCreate.history.fields.' + camelCase(scope.item.field)) }}
        <br />
        {{ $t('employeeCreate.history.actions.' + getModificationType(scope.item)) }}
        <br />
      </template>
      <template #cell(eventDate)="scope">
        {{ getDate(scope.item.eventDate) }}
        <br />
        {{ getTime(scope.item.eventDate) }}
        <br />
      </template>
      <template #cell(previousValue)="scope">
        {{ scope.item.previousValue === '' ? '--' : scope.item.previousValue }}
      </template>
      <template #cell(newValue)="scope">
        {{ scope.item.newValue === '' ? '--' : scope.item.newValue }}
      </template>
    </b-table>
  </b-sidebar>
</template>
<script>
import { DateTime } from 'luxon';
import { camelCase } from 'eslint-plugin-vue/lib/utils/casing';
import { getEmployeeHistory } from '@/services/employees.service';
import { getDateFormatFromUserConfig } from '@/helpers/date.helpers';

export default {
  name: 'EmployeeHistory',
  components: {},
  props: {
    employee: {
      type: Object,
      default: () => ({ id: null })
    }
  },

  data() {
    return {
      loading: false,
      error: false,
      history: [],
      fields: [
        { key: 'eventDate', label: this.$t('employeeCreate.history.headers.eventDate') },
        { key: 'field', label: this.$t('employeeCreate.history.headers.field') },
        { key: 'previousValue', label: this.$t('employeeCreate.history.headers.previousValue') },
        { key: 'newValue', label: this.$t('employeeCreate.history.headers.newValue') },
        { key: 'userId', label: this.$t('employeeCreate.history.headers.userId') },
        { key: 'notes', label: this.$t('employeeCreate.history.headers.notes') }
      ]
    };
  },
  methods: {
    getDate(date) {
      return DateTime.fromISO(date).toFormat(getDateFormatFromUserConfig());
    },
    getTime(date) {
      return DateTime.fromISO(date).toFormat('hh:mm a');
    },
    camelCase(val) {
      return camelCase(val);
    },
    getModificationType(item) {
      if (item.newValue !== '' && item.previousValue !== '') {
        return 'update';
      }
      if (item.newValue !== '' && item.previousValue === '') {
        return 'create';
      }
      if (item.newValue === '' && item.previousValue !== '') {
        return 'remove';
      }
      return 'test';
    }
  },
  computed: {},
  async mounted() {
    try {
      this.loading = true;
      this.error = false;
      this.history = await getEmployeeHistory(this.employee.id, { limit: 250 }).then(x => x.content);
    } catch (w) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }
};
</script>
