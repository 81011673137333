import 'whatwg-fetch';
import { get, resolveCommonQuery } from '@/helpers/service.helper';
import pick from 'lodash.pick';

/**
 * Get resellers
 * @link https://api.posveloce.com/docs/private#operation/getResellers
 */

const getResellers = token => {
  const url = resolveCommonQuery('/resellers?isActive=true', null).toString();

  return get(token, url);
};

/**
 * Get reseller by id
 * @link https://api.posveloce.com/docs/private#operation/getResellerByID
 */

const getResellerByID = (token, query) => {
  const { resellerID } = query;
  const url = resolveCommonQuery(`/resellers/${resellerID}`, null).toString();

  return get(token, url);
};

/**
 * Get reseller locations by reseller id
 * @link https://api.posveloce.com/docs/private#operation/getResellerByID
 */

const getLocationsByResellerID = (token, query) => {
  const params = pick(query, ['offset']);
  const { resellerID } = query;
  const url = resolveCommonQuery(`/resellers/${resellerID}/locations`, params).toString();
  return get(token, url);
};

export default {
  getResellers,
  getResellerByID,
  getLocationsByResellerID
};
