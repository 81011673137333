import { get, resolveCommonQuery } from '@/helpers/service.helper';

/**
 * Get cpuntries
 * @link https://api.posveloce.com/docs/private#operation/getCountries
 */
const getCountries = token => {
  const url = resolveCommonQuery('/countries', null).toString();
  return get(token, url);
};

export default {
  getCountries
};
