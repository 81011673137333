import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import { createCollectionState, getSelectors, removeAll, setAll, setOne } from '@/helpers/stores.helpers';
import router from '@/router';
import itemsService from '@/services/items.service';
import i18n from '@/plugins/i18n';

const { selectAll, selectAllFiltered, selectSelected, selectTotal } = getSelectors();
import cloneDeep from 'lodash.clonedeep';
export const useDataItems = defineStore('items', {
  state: () =>
    createCollectionState({
      loading: false,
      pending: true,
      error: null,
      filter: ''
    }),
  getters: {
    getAllItemsFiltered(state) {
      return selectAllFiltered(['remoteId', 'name', 'alternativeName', 'divisionName', 'itemType'])(state);
    },
    getAllItems: selectAll,
    selectTotal,
    selectSelected,
    getFilter: state => state.filter
  },
  actions: {
    async getItems() {
      try {
        const routeQuery = {
          ...router.currentRoute.query,
          isActive: true,
          kind: '0'
        };
        this.getItemsRequest();
        const items = await itemsService.getItems(getToken(), routeQuery);
        this.getItemsSuccess(items);
      } catch (error) {
        this.getItemsFailure(error);
      }
    },
    async getItemById(itemId) {
      try {
        const routeQuery = {
          id: itemId
        };
        this.getItemByIdRequest();
        const items = await itemsService.getItemById(getToken(), routeQuery);
        this.getItemByIdSuccess(items);
      } catch (error) {
        this.getItemByIdFailure(error);
      }
    },
    async updateItemById(item) {
      try {
        this.updateItemByIdRequest();
        const items = await itemsService.updateItemById(getToken(), item.id, item);
        this.updateItemByIdSuccess(items);
      } catch (error) {
        this.updateItemByIdFailure(error);
      }
    },

    setFilter(value) {
      this.setFilterValue(value);
    },
    getItemByIdRequest() {
      this.error = null;
      this.pending = true;
    },
    getItemByIdSuccess(item) {
      this.data = setOne(
        {
          ...item,
          remoteId: item.remoteId + 1,
          divisionName: item.division.name,
          itemScreen: (item.screen && item.screen.name) || Math.ceil((item.remoteId + 1) / 40)
        },
        this.data
      );
      this.data.selected = item.id;
      this.error = null;
      this.pending = false;
    },
    getItemByIdFailure(error) {
      this.error = error;
      this.pending = false;
    },
    updateItemByIdRequest() {
      this.error = null;
      this.pending = true;
    },
    updateItemByIdSuccess(item) {
      this.data = setOne(
        {
          ...item,
          remoteId: item.remoteId + 1,
          divisionName: item.division.name,
          itemScreen: (item.screen && item.screen.name) || Math.ceil((item.remoteId + 1) / 40)
        },
        this.data
      );
      this.data.selected = item.id;
      this.error = null;
      this.pending = false;
    },
    updateItemByIdFailure(error) {
      this.error = error;
      this.pending = false;
    },
    getItemsRequest() {
      this.loading = true;
      this.error = null;
      this.pending = true;
    },
    getItemsSuccess(Items) {
      this.data = setAll(
        Items.map(s => ({
          ...s,
          remoteId: s.remoteId + 1,
          divisionName: s.division.name,
          itemScreen: (s.screen && s.screen.name) || Math.ceil((s.remoteId + 1) / 40)
        })),
        this.data
      );
      this.error = null;
      this.loading = false;
      this.pending = false;
    },
    getItemsFailure(error) {
      this.data = removeAll(this.data);
      this.data.selected = null;
      this.error = error;
      this.loading = false;
      this.pending = false;
    },
    setFilterValue(value) {
      this.filter = value;
    },
    setSelected(value) {
      this.data.selected = value;
    }
  }
});
