<template>
  <span class="vel-amount">
    {{ formattedQuantity }}
  </span>
</template>

<script>
import formatQuantity from '@/filters/format-quantity';

export default {
  name: 'vel-amount',
  props: {
    qty: {
      type: Number
    },
    precision: {
      type: Number
    }
  },
  computed: {
    formattedQuantity() {
      return formatQuantity(this.qty, this.precision);
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';

.vel-amount {
  @include monospaced;
}
</style>
