import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import salesService from '@/services/sales.service';
import { createCollectionState, getSelectors, removeAll, setAll } from '@/helpers/stores.helpers';

const { selectAll } = getSelectors();

export const useDataSalesHouylyLocations = defineStore('salesHourlyLocations', {
  state: () =>
    createCollectionState({
      loading: false,
      error: null
    }),
  getters: {
    selectAll: state => selectAll(state).sort((a, b) => a.location.name.localeCompare(b.location.name))
  },
  actions: {
    async getLocationsSalesHourly(payload: any = {}) {
      try {
        const from = router.currentRoute.query.from || today().startOf('day').toISO();
        const to = router.currentRoute.query.to || from;
        const routeQuery = {
          ...router.currentRoute.query,
          ...payload,
          from,
          to
        };
        this.getLocationsSalesHourlyRequest();
        const hourlySales = await salesService.getLocationsSalesHourly(getToken(), routeQuery);
        this.getLocationsSalesHourlySuccess(hourlySales);
      } catch (error) {
        this.getLocationsSalesHourlyFailure(error);
      }
    },
    getLocationsSalesHourlyRequest() {
      this.loading = true;
      this.data = removeAll(this.data);
      this.error = null;
    },
    getLocationsSalesHourlySuccess(sales) {
      this.loading = false;
      this.data = setAll(sales.content, this.data, 'locationId');
      this.error = null;
    },
    getLocationsSalesHourlyFailure(error) {
      this.loading = false;
      this.data = removeAll(this.data);
      this.error = error;
    }
  }
});
