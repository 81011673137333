import { defineStore } from 'pinia';
import locationsService from '@/services/locations.service';
import { getToken } from '@/helpers/token.helper';
import { useDataLocationsLocation } from '@/stores/data/locations/location.module';

export const useDataLocationsLocationsEdit = defineStore('locationsLocationsEdit', {
  state: () => ({ location: null, pending: false, error: null, success: null }),
  getters: {
    isError: state => !!state.error,
    isPending: state => state.pending,
    isSuccess: state => state.success,
    getLocation: state => state.location || {},
    hasLocation: state => !!state.location,
    getError: state => state.error || {}
  },
  actions: {
    patchLocation(locationEntity) {
      this.patchLocationRequest();

      locationsService.patchLocation(getToken(), locationEntity).then(
        res => {
          this.patchLocationSuccess(res);
          const locationsLocationStore = useDataLocationsLocation();
          locationsLocationStore.hydrateLocation(res);
        },
        error => {
          this.patchLocationFailure(error);
        }
      );
    },
    resetLocation() {
      this.reset();
    },
    patchLocationRequest() {
      this.pending = true;
      this.location = null;
      this.error = null;
      this.success = null;
    },
    patchLocationSuccess(location) {
      this.pending = false;
      this.location = location;
      this.error = null;
      this.success = true;
    },
    patchLocationFailure(error) {
      this.pending = false;
      this.location = null;
      this.error = error;
      this.success = false;
    },
    reset() {
      this.location = null;
      this.pending = false;
      this.error = null;
      this.success = null;
    },
    clearMessage() {
      this.error = null;
      this.success = null;
    }
  }
});
