import 'whatwg-fetch';
import { get, resolveCommonQuery } from '@/helpers/service.helper';
import pick from 'lodash.pick';

/**
 * Get a partner by its id
 * @link https://api.posveloce.com/docs/private#operation/getPartnerById
 */
const getPartner = (token, query) => {
  const { partnerID } = query;

  return get(token, `/partner/${partnerID}`);
};

/**
 * Get all divisions for a give location or group
 * @link https://api.posveloce.com/docs/private#operation/get
 */

const getPartners = (token, query) => {
  const params = pick(query, ['isActive', 'isGlobal', 'limit', 'offset']);
  const url = resolveCommonQuery('/partners', params).toString();
  return get(token, url);
};

export default {
  getPartner,
  getPartners
};
