<template>
  <transition name="slide-fade" v-if="dismissable">
    <div class="vel-alert" :class="classes">
      <div>
        <vel-icon v-if="level === 'success'" name="checkmark" />
        <slot />
      </div>
      <button class="vel-alert__dismiss" aria-hidden="true" @click="handleDismiss">×</button>
    </div>
  </transition>
  <div class="vel-alert" :class="classes" v-else>
    <vel-icon v-if="level === 'success'" name="checkmark" />
    <slot />
  </div>
</template>

<script>
import VelIcon from '@/components/icon/VelIcon';

export default {
  name: 'vel-alert',
  components: {
    VelIcon
  },
  props: {
    level: {
      type: String,
      default: 'notice',
      validate(value) {
        return ['notice', 'success', 'warning', 'error'].includes(value);
      }
    },
    dismissable: {
      type: Boolean,
      default: true
    },
    autoDismissTime: {
      type: Number,
      default: 5000
    }
  },
  computed: {
    classes() {
      const { name } = this.$options;
      return [`${name}_${this.level}`];
    }
  },
  methods: {
    handleDismiss() {
      this.$emit('dismiss');
    }
  },
  mounted() {
    if (this.autoDismissTime && this.dismissable) {
      setTimeout(() => {
        this.handleDismiss();
      }, this.autoDismissTime);
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$alert-border-radius: $base-radius * 2;
$alert-color: $athens-gray;
$alert-color_notice: $primary-color;
$alert-color_success: $persian-green;
$alert-color_warning: $web-orange;
$alert-color_error: $brilliant-rose;
$alert-font-size: rem-calc(16);
$alert-font-size_medium-up: rem-calc(15);
$alert-font-weight: $font-medium;
$alert-min-height: rem-calc(48);

// dismiss
$alert__dismiss-background-color: transparent;
$alert__dismiss-icon-color: $primary-color;
$alert__dismiss-icon-color_hover: #fff;
$alert__dismiss-icon-size: rem-calc(24);
$alert__dismiss-icon-thickness: $font-bold;
$alert__dismiss-icon-outer-size: rem-calc(48);
$alert__dismiss-icon-outer-size_medium-up: rem-calc(38);

@mixin vel-alert-theme($color) {
  background-color: rgba($color, 0.1);
  border: 1px solid $color;
  color: mix(black, $color, 40%);
}

.vel-alert {
  align-items: center;
  border-radius: $alert-border-radius;
  display: flex;
  flex-direction: row;
  font-size: $alert-font-size;
  font-weight: $alert-font-weight;
  justify-content: space-between;
  min-height: $alert-min-height;
  padding: 0 0 0 rem-calc(16);
  text-align: justify;

  @include vel-alert-theme($alert-color);

  &_notice {
    @include vel-alert-theme($alert-color_notice);
  }

  &_success {
    background: rgba(#2a3142, 0.87);
    color: #fff;
    justify-content: space-between;

    div {
      align-items: center;
      display: flex;
      height: 100%;
    }

    .vel-icon {
      background: green;
      border-radius: 50%;
      margin-right: rem-calc(10);
    }
  }

  &_warning {
    @include vel-alert-theme($alert-color_warning);
  }

  &_error {
    @include vel-alert-theme($alert-color_error);
  }

  &__dismiss {
    background-color: $alert__dismiss-background-color;
    color: $alert__dismiss-icon-color;
    flex-grow: 0;
    font-size: $alert__dismiss-icon-size;
    font-weight: $alert__dismiss-icon-thickness;
    height: $alert__dismiss-icon-outer-size;
    opacity: 1;
    padding: 0;
    transition-property: color;
    width: $alert__dismiss-icon-outer-size;
    @include animate;

    &:hover {
      color: $alert__dismiss-icon-color_hover;
      opacity: 0.75;
    }
  }

  @include breakpoint(medium) {
    font-size: $alert-font-size_medium-up;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(100px);
}
</style>
