<template>
  <vel-card class="user__edit" v-if="!isLoadingUser && user">
    <template v-slot:title>
      {{ $t('user.info.editTitle') }}
    </template>
    <vel-alert @dismiss="clearMessage" class="alert" level="error" v-if="isError">
      {{ $t('user.errors.patchUser') }}
    </vel-alert>
    <vel-alert @dismiss="clearMessage" class="success" level="success" v-if="isSuccess">
      {{ $t('user.success.patchUser') }}
    </vel-alert>
    <user-form :user="user" :resellers="resellers" :pending="isPending" @submit="submit"></user-form>
  </vel-card>
</template>

<script>
import UserForm from '@/private/users/components/form/UserForm';
import VelAlert from '../../../components/alert/VelAlert';
import VelCard from '@/components/card/VelCard';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataUserUserEdit } from '@/stores/data/user/user-edit.module';
import { useDataResellers } from '@/stores/data/resellers.module';
import { useDataUsersUser } from '@/stores/data/users/user.module';

export default {
  name: 'user-edit-container',
  components: {
    UserForm,
    VelAlert,
    VelCard
  },
  props: {},
  watch: {
    isSuccess(val) {
      if (val) {
        this.$emit('success');
      }
    }
  },
  methods: {
    ...mapPiniaActions(useDataResellers, ['getResellers']),
    ...mapPiniaActions(useDataUserUserEdit, ['patchUser', 'clearMessage', 'resetUser']),
    submit(userEntity) {
      this.patchUser(userEntity);
    }
  },
  computed: {
    ...mapPiniaState(useDataResellers, ['resellers']),
    ...mapPiniaState(useDataUsersUser, ['user', 'isLoadingUser']),
    ...mapPiniaState(useDataUserUserEdit, ['isPending', 'isError', 'isSuccess'])
  },
  mounted() {
    this.getResellers();
  },
  beforeMount() {
    this.resetUser();
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.user-form__actions {
  .actions__submit {
    margin-right: 1.25em;
  }
}

.user-form {
  max-width: 800px;
}
</style>
