<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="600px"
    right
    id="jobRoles-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarJobRole"
  >
    <template #header="{ hide }">
      <div class="bg-gray-200 bg-light w-100">
        <b-button variant="outline-primary" @click="hide">
          <b-icon icon="chevron-compact-left" aria-hidden="true"></b-icon>
          {{ $t('jobRoles.back') }}
        </b-button>
      </div>
    </template>

    <template #footer="{ hide }">
      <div class="d-flex bg-light text-dark align-items-center px-3 py-2" style="border-top: 2px solid #d6d6d8;">
        <b-button variant="primary" @click="hide">{{ $t('jobRoles.close') }}</b-button>
      </div>
    </template>
    <b-container fluid class="p-4" v-loading="pending">
      <h5 class="font-weight-bold">{{ $t('jobRoles.list.title') }}</h5>

      <job-role-form
        :licence-number="employee.licenceNumber"
        ref="form"
        @success="$emit('success', $event)"
      ></job-role-form>
      <job-role-list
        :job-roles="jobRoles"
        @onModify="openEditJobRole($event)"
        @onDelete="$emit('onDelete', $event)"
      ></job-role-list>
    </b-container>
  </b-sidebar>
</template>
<script>
import JobRoleForm from '@/private/components/job-roles/components/JobRoleForm';
import JobRoleList from '@/private/components/job-roles/components/JobRoleList';

export default {
  name: 'employee-sidebar-add-job-role',
  components: {
    JobRoleForm,
    JobRoleList
  },
  props: {
    jobRole: {
      type: Object,
      default: () => ({ name: '' })
    },
    jobRoles: {
      type: Array,
      default: () => []
    },
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  computed: {},
  methods: {
    openEditJobRole(jobRole) {
      this.$emit('update:jobRole', jobRole.item);
    }
  }
};
</script>
