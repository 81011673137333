import { parameterizeDate, parseRange, today } from '../helpers/date.helpers';

export default {
  props: {
    from: {
      type: String,
      default() {
        return today().toString();
      }
    },
    to: {
      type: String,
      default() {
        return today().toString();
      }
    }
  },
  data() {
    return {
      mutableRange: parseRange(this.from, this.to)
    };
  },
  methods: {
    handleDateRangeChange(range) {
      if (!range) {
        this.mutableRange = [today().toJSDate(), today().toJSDate()];
      } else if (range[0] && range[1]) {
        this.mutableRange = range;
      }

      this.$router.replace({
        name: this.$router.history.current.name,
        query: {
          ...this.$router.history.current.query,
          from: parameterizeDate(this.mutableRange[0]),
          to: parameterizeDate(this.mutableRange[1], true)
        }
      });
    }
  }
};
