import { defineStore } from 'pinia';
import { useUserSettingsStore } from '@/stores/data/users/settings.store';
import { useDataUsersUserPermissions } from '@/stores/data/users/user-permissions.module';

export const useMultiSelectorStore = defineStore('multi-selector', {
  state: () => ({
    isSelected: { value: false }
  }),
  getters: {
    enableMultiSelection() {
      return this.isSelected.value;
    }
  },
  actions: {
    async toggleMultiSelection($event) {
      this.isSelected = { value: $event };

      const userSettingsStore = useUserSettingsStore();

      await userSettingsStore.set({ enableMultiSelection: { value: $event } });
    },
    hydrate() {
      const userSettingsStore = useUserSettingsStore();
      const userPermissionsStore = useDataUsersUserPermissions();
      const enableMultiSelection = userSettingsStore.get('enableMultiSelection');

      if (userPermissionsStore.isUserAdmin && enableMultiSelection === null) {
        this.isSelected = { value: true };
      }

      this.isSelected = enableMultiSelection;
    }
  }
});
