import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import salesService from '@/services/sales.service';
import { useDataSalesIndicators } from '@/stores/data/sales/indicators.module';
import discountsModuleHelper from '@/stores/discounts.module.helper';
import groupsService from '@/services/groups.service';

export const useDataGroupsGroup = defineStore('groupsGroup', {
  state: () => ({
    group: null,
    status: {},
    errors: {}
  }),
  getters: {
    getGroupError() {
      return (this.errors && this.errors.getGroupError) || {};
    },
    isGroupNotFound() {
      return [400, 404].includes(this.getGroupError.statusCode) || false;
    },
    isLoadingGroup: (state: any) => !!state.status.isLoadingGroup
  },
  actions: {
    getGroup(groupId) {
      this.getGroupRequest();

      groupsService.getGroup(getToken(), groupId).then(
        group => {
          this.getGroupSuccess(group);
        },
        error => {
          this.getGroupFailure(error);
        }
      );
    },
    resetGroup() {
      this.reset();
    },
    getGroupRequest() {
      this.status = { ...this.status, isLoadingGroup: true };
      this.group = null;
      this.errors = { ...this.errors, getGroupError: null };
    },
    getGroupSuccess(group) {
      this.status = { ...this.status, isLoadingGroup: false };
      this.group = group;
      this.errors = { ...this.errors, getGroupError: null };
    },
    getGroupFailure(error) {
      this.status = { ...this.status, isLoadingGroup: false };
      this.group = null;
      this.errors = { ...this.errors, getGroupError: error };
    },
    reset() {
      this.group = null;
      this.status = {};
      this.errors = {};
    }
  }
});
