<template>
  <div class="vel-card" :class="classes">
    <component
      :is="titleTag"
      class="vel-card__title"
      :class="{ 'vel-card__title_capitalized': this.capitalized }"
      v-if="$slots.title"
    >
      <slot name="title" />
    </component>
    <div class="vel-card__body">
      <slot name="default" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'vel-card',
  props: {
    titleTag: {
      type: String,
      default: 'h5'
    },
    shadowed: {
      type: Boolean,
      default: false
    },
    capitalized: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      const { name } = this.$options;
      return [
        {
          [`${name}_shadowed`]: this.shadowed
        }
      ];
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$card-background-color: white;
$card-shadow-color: $blue-bayoux;
$card-border-radius: 0;

// title
$card__title-font-color: $boulder;

.vel-card {
  background-clip: border-box;
  background-color: $card-background-color;
  margin-bottom: rem-calc(20);
  min-width: 0;
  padding: rem-calc(12);
  word-wrap: break-word;

  @include breakpoint(medium) {
    padding: rem-calc(20);
  }

  &_shadowed {
    box-shadow: 0 0 0 1px rgba($card-shadow-color, 0.05), 0 1px 3px 0 rgba($card-shadow-color, 0.15);
  }

  &__title {
    color: $card__title-font-color;
    margin-bottom: rem-calc(25);

    &_capitalized {
      text-transform: uppercase;
    }
  }

  &__body {
    padding: 0 rem-calc(0);
    width: 100%;

    @include breakpoint(medium) {
      padding: 0 rem-calc(0);
    }
  }
}
</style>
