import { defineStore } from 'pinia';
import locationsService from '@/services/locations.service';
import { getToken } from '@/helpers/token.helper';
import { createCollectionState, getSelectors, removeAll, setAll } from '@/helpers/stores.helpers';

const sortPermissions = permissionsWithFullName => {
  const sortedUsersPermissionsWithFullName = [...permissionsWithFullName];

  sortedUsersPermissionsWithFullName.forEach(userPermissions => {
    const sortedPermissions = [];

    userPermissions.permissions.locations.forEach(locationPermission => {
      sortedPermissions.push({
        permission: locationPermission,
        name: locationPermission.permission,
        originType: 'location'
      });
    });

    userPermissions.permissions.groups.forEach(groupPermission => {
      sortedPermissions.push({
        permission: groupPermission,
        name: groupPermission.permission,
        originType: 'group',
        originName: groupPermission.group.name,
        originId: groupPermission.group.id
      });
    });

    userPermissions.sortedPermissions = sortedPermissions;
  });

  return sortedUsersPermissionsWithFullName;
};

const { selectAll } = getSelectors();

export const useDataLocationsPermissionsLocation = defineStore('locationsPermissionsLocation', {
  state: () =>
    createCollectionState({
      status: {},
      errors: {}
    }),
  getters: {
    getLocationPermissionsError(_) {
      return this.errors.getLocationPermissionsError || null;
    },
    isLoadingLocationPermissions: state => !!state.status.isLoadingPermissions,
    permissions: selectAll,
    hasPermissions(_) {
      return !!this.permissions.length;
    },
    permissionsWithFullName(_) {
      return this.permissions.map(permission => ({
        ...permission,
        name: `${permission.firstName} ${permission.lastName}`
      }));
    },
    sortedPermissions(_) {
      return this.hasPermissions ? sortPermissions(this.permissionsWithFullName) : [];
    }
  },
  actions: {
    getPermissions(locationId) {
      this.getPermissionsRequest();

      locationsService.getLocationPermissions(getToken(), { locationId, include: ['group'] }).then(
        permissions => {
          this.getPermissionsSuccess(permissions);
        },
        error => {
          this.getPermissionsFailure(error);
        }
      );
    },
    resetLocationPermissions() {
      this.reset();
    },
    getPermissionsRequest() {
      this.status = { ...this.status, isLoadingPermissions: true };
      this.data = removeAll(this.data);
      this.errors = { ...this.errors, getPermissionsError: null };
    },
    getPermissionsSuccess(permissions) {
      this.status = { ...this.status, isLoadingPermissions: false };
      this.data = setAll(permissions, this.data);
      this.errors = { ...this.errors, getPermissionsError: null };
    },
    getPermissionsFailure(error) {
      this.status = { ...this.status, isLoadingPermissions: false };
      this.data = removeAll(this.data);
      this.errors = { ...this.errors, getPermissionsError: error };
    },
    clearGetLocationPermissionsError() {
      this.errors = { ...this.errors, getPermissionsError: null };
    },
    reset() {
      this.status = {};
      this.data = removeAll(this.data);
      this.errors = {};
    }
  }
});
