<template>
  <indicator
    color="blue"
    :amount="amount"
    :previous-amount="previousAmount"
    :loading="loading"
    iconName="paid-outs"
    iconColor="black"
  >
    <template v-slot:title>
      {{ $t('dashboard.paidOuts.title') }}
    </template>
    <template v-slot:tooltip>
      {{ $t('dashboard.paidOuts.description') }}
    </template>
    <template v-slot:value v-if="versionIsValid">
      <vel-amount :currency="currency" :amount="amount" />
    </template>
    <template v-slot:value v-else-if="!loading">
      <tooltip
        placement="top"
        effect="light"
        :content="$t('dashboard.tipsTotalAmount.versionError.multiple')"
        v-if="isMulti"
      >
        <vel-icon name="exclamation-thick" modifier="on-bright" size="medium" />
      </tooltip>
      <tooltip placement="top" effect="light" :content="$t('dashboard.tipsTotalAmount.versionError.single')" v-else>
        <vel-icon name="exclamation-thick" size="medium" />
      </tooltip>
    </template>
  </indicator>
</template>

<script>
import Indicator from '@/components/indicator/Indicator.vue';
import { Tooltip } from 'element-ui';
import VelAmount from '@/components/amount/VelAmount';
import VelIcon from '@/components/icon/VelIcon';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { useUIDateSelector } from '@/stores/ui/date-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';

export default {
  name: 'paid-outs',
  components: {
    Indicator,
    Tooltip,
    VelAmount,
    VelIcon
  },
  props: {
    amount: {
      default: 0
    },
    previousAmount: {
      default: null
    },
    currency: {
      default: ''
    },
    loading: {
      default: true
    },
    versionIsValid: {
      default: false
    }
  },
  setup() {
    return {
      getLocationSelectorSelected: useUILocationSelector().getLocationSelectorSelected,
      getGroupSelectorSelected: useUIGroupSelector().getGroupSelectorSelected,
      getDateSelectorSelected: useUIDateSelector().getDateSelectorSelected
    };
  },
  computed: {
    isMulti() {
      if (
        (this.getGroupSelectorSelected.length && !this.getLocationSelectorSelected.length) ||
        (!this.getGroupSelectorSelected.length && !this.getLocationSelectorSelected.length)
      ) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.vel-icon {
  color: red;
}
</style>
