<template>
  <tax-form
    :taxCategories="getTaxCategoriesRows"
    :isLoading="isLoading"
    :errorLabel="'taxForm.errors.create'"
    @submit="handleSubmit"
  />
</template>

<script>
import TaxForm from './components/TaxForm';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataTaxCategories } from '@/stores/data/tax-categories.module';
import { useDataTaxes } from '@/stores/data/taxes.module';

export default {
  name: 'create-tax',
  components: {
    TaxForm
  },
  methods: {
    ...mapPiniaActions(useDataTaxCategories, ['fetchTaxCategories']),
    ...mapPiniaActions(useDataTaxes, ['createTax']),
    async handleSubmit(obj) {
      const { licenceNumber } = this.getLocationsById(this.getLocationSelectorSelected);

      await this.createTax({ ...obj, licenceNumber });
      if (this.success) {
        await this.$router.replace({ name: 'taxes-setup' });
      }
    }
  },
  computed: {
    ...mapPiniaState(useDataTaxCategories, ['getTaxCategoriesRows', 'isLoading']),
    ...mapPiniaState(useDataTaxes, ['success']),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById']),
    ...mapPiniaState(useUILocationSelector, ['getLocationSelectorSelected'])
  },
  async mounted() {
    await this.fetchTaxCategories();
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

/deep/ .vel-page {
  &__content {
    color: #2a3142;
    -ms-overflow-style: none;
    overflow-y: hidden;
    padding: 0;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .footer {
      display: none;
    }
  }
}
</style>
