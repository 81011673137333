import { accentFold } from '@/helpers/string.helpers';

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
export const objectToSearchableString = obj =>
  accentFold(
    Object.keys(obj)
      .map(k => (k !== '_id' && obj[k]) || null)
      .join(' ')
      .toLowerCase()
  );

export default {
  getKeyByValue
};

export const getObjectTypeValues = obj =>
  Object.keys(obj).reduce((a, b) => {
    a[b] = null;
    if (obj[b] === 'true' || obj[b] === 'false') {
      a[b] = obj[b] === 'true';
    } else if (!isNaN(obj[b])) {
      a[b] = Number(obj[b]);
    } else {
      try {
        a[b] = JSON.parse(obj[b]);
      } catch {
        a[b] = obj[b].toString();
      }
    }
    return a;
  }, {});

export const getObjectStringValues = obj =>
  Object.keys(obj).reduce((a, b) => {
    if (typeof obj[b] === 'object') {
      a[b] = JSON.stringify(obj[b]);
    } else {
      a[b] = obj[b].toString();
    }
    return a;
  }, {});
