<template>
  <b-button @click="handleToggle" href="#" tabindex="0" :id="id" variant="link" class="p-0">
    <b-icon :icon="isToggled ? 'x' : 'three-dots-vertical'" style="fill: #00a9e1;" class="row-action"></b-icon>
    <b-tooltip :show.sync="isToggled" triggers="click blur" placement="bottom" :target="id">
      <div class="tooltip-wrapper">
        <b-button @click="handleDelete">
          {{ $t('defaults.actions.delete') }}
        </b-button>
      </div>
    </b-tooltip>
  </b-button>
</template>

<script>
export default {
  name: 'TaxCategoryMore',
  data() {
    return {
      isToggled: false
    };
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleDelete() {
      this.handleToggle();
      this.$emit('delete');
    },
    handleToggle() {
      this.isToggled = !this.isToggled;
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip-wrapper {
  position: relative;
  right: 126px;
  top: 7.5px;

  button {
    background: #fff;
    border: 1px solid rgba(#000, 0.1);
    color: red;
    min-height: 43.05px;
    min-width: 131.125px;
    text-transform: none;
    width: 162px;
  }
}
</style>
