<template>
  <div>
    <b-modal
      v-model="showDeleteModal"
      :title="title"
      :static="true"
      :centered="true"
      :hide-header-close="true"
      :footer-bg-variant="'light'"
      :footer-text-variant="'dark'"
      @hidden="$emit('onCancel', true)"
    >
      {{ body }}

      <template #modal-footer>
        <b-container fluid>
          <div class="w-100">
            <b-button variant="primary" class="float-right ml-2" @click="$emit('onDelete', true)">
              {{ $t(yesActionLabel) }}
            </b-button>
            <b-button variant="link" class="float-right" @click="showDeleteModal = false">
              {{ $t(noActionLabel) }}
            </b-button>
          </div>
        </b-container>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'EmployeeDeleteModal',
  components: {},
  props: {
    yesActionLabel: {
      type: String,
      default: 'employee.deleteModal.delete'
    },
    noActionLabel: {
      type: String,
      default: 'employee.deleteModal.cancel'
    },
    title: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    show(value) {
      this.showDeleteModal = value;
    }
  },
  data() {
    return {
      showDeleteModal: false
    };
  },
  computed: {}
};
</script>
<style lang="scss" scoped>
/deep/ {
  .modal-header {
    border: none;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
  }

  .modal-body {
    padding: 0 1.5rem 1.5rem;
  }
}
</style>
