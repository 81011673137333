<template>
  <div>
    <b-alert :show="error" variant="danger" class="mb-4">
      {{ $t('employeeCreate.updateError') }}
    </b-alert>
    <b-container v-if="employee.terminated" fluid class="content px-4 pt-4 pb-0" style="background-color: #f5f5f5;">
      <div class="bg-white p-3">
        <strong style="font-size: 1rem;">{{ $t('employeeCreate.personnalInformation.deactivationDetails') }}</strong>
        <p class="d-block mt-3">{{ $t('employeeCreate.personnalInformation.note') }}</p>
        <p class="d-block mt-1" style="font-size: 1rem;">{{ employee.terminatedReason || '--' }}</p>
        <div class="d-flex flex-column flex-md-row justify-content-md-between" style="max-width: 600px;">
          <div>
            <p class="d-block mt-3">{{ $t('employeeCreate.personnalInformation.deactivatedBy') }}</p>
            <p class="d-block mt-1" style="font-size: 1rem;">
              {{ employee.terminatedBy.firstName }} {{ employee.terminatedBy.lastName }}
            </p>
          </div>
          <div>
            <p class="d-block mt-3">Date:</p>
            <p class="d-block mt-1" style="font-size: 1rem;">{{ formatDate(employee.terminatedAt) }}</p>
          </div>
          <div>
            <p class="d-block mt-3">
              {{ $t('employeeCreate.personnalInformation.rehireable.label') }}
            </p>
            <p class="d-block mt-1" style="font-size: 1rem;">
              {{ $t('employeeCreate.personnalInformation.rehireable.' + ((employee.rehireable && 'yes') || 'no')) }}
            </p>
          </div>
        </div>
      </div>
    </b-container>
    <h5 class="pt-4 px-4 pb-2">{{ $t('employeeCreate.personnalInformation.title') }}</h5>
    <b-container fluid class="content p-4">
      <div class="d-flex flex-column flex-md-row mb-md-4 justify-content-between">
        <b-form-group
          :label="$t('employeeCreate.personnalInformation.fields.fullName')"
          :description="$t('employeeCreate.required')"
          label-for="name"
          class="mr-5 w-100 mb-2 mb-md-0"
        >
          <b-form-input
            id="name"
            :formatter="formatName"
            :state="!submited ? null : employee.name !== '' && null"
            v-model="employee.name"
            maxlength="50"
            aria-describedby="input-live-help input-live-feedback"
            :class="{ required: !employee.name || employee.name === '' }"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('employeeCreate.personnalInformation.fields.publicName')"
          :description="$t('employeeCreate.required')"
          label-for="publicName"
          class="mr-5 ml-md-5 mb-2 mb-md-0 w-100"
        >
          <b-form-input
            id="publicName"
            :formatter="formatName"
            :state="!submited ? null : employee.publicName !== '' && null"
            maxlength="50"
            v-model="employee.publicName"
            aria-describedby="input-live-help input-live-feedback"
            :class="{ required: !employee.publicName || employee.publicName === '' }"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('employeeCreate.personnalInformation.fields.address')"
          :description="$t('employeeCreate.required')"
          label-for="address"
          class="mr-5 ml-md-5 mb-2 mb-md-0 w-100"
        >
          <b-form-input
            id="address"
            v-model="employee.address"
            :state="!submited ? null : employee.address !== '' && null"
            maxlength="512"
            aria-describedby="input-live-help input-live-feedback"
            :class="{ required: !employee.address || employee.address === '' }"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('employeeCreate.personnalInformation.fields.dob')"
          label-for="birthDate"
          class="mr-5 ml-md-5 mb-2 mb-md-0 w-100"
        >
          <b-input-group>
            <b-form-input
              id="example-input"
              type="text"
              :placeholder="$t('employeeCreate.datePlaceholder')"
              v-mask="'99/99/9999'"
              :value="birthDate"
              autocomplete="off"
              @change="setBirthDate"
            ></b-form-input>
            <b-input-group-append style="border: 1px solid #ced4da;">
              <b-form-datepicker
                button-only
                :locale="$i18n.locale"
                right
                button-variant="white"
                :label-no-date-selected="$t('datePicker.noDate')"
                :label-calendar="$t('datePicker.calendar')"
                :label-close-button="$t('datePicker.close')"
                :label-current-month="$t('datePicker.currentMonth')"
                :label-help="$t('datePicker.help')"
                :label-nav="$t('datePicker.nav')"
                :label-next-decade="$t('datePicker.nextDecade')"
                :label-next-month="$t('datePicker.nextMonth')"
                :label-next-year="$t('datePicker.nextYear')"
                :label-prev-decade="$t('datePicker.previousDecade')"
                :label-prev-month="$t('datePicker.previousMonth')"
                :label-prev-year="$t('datePicker.previousYear')"
                :label-reset-button="$t('datePicker.reset')"
                :label-today="$t('datePicker.today')"
                :label-today-button="$t('datePicker.selectToday')"
                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                aria-controls="example-input"
                v-model="employee.birthDate"
                @context="onSelectBirthDate"
              >
                <template #button-content>
                  <font-awesome-icon icon="calendar-alt" />
                </template>
              </b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="d-flex flex-column flex-md-row mb-4 justify-content-between">
        <b-form-group
          :label="$t('employeeCreate.personnalInformation.fields.hiringDate')"
          label-for="hiringDate"
          class="mr-5 w-100"
        >
          <b-input-group>
            <b-form-input
              id="example-input"
              type="text"
              :placeholder="$t('employeeCreate.datePlaceholder')"
              v-mask="'99/99/9999'"
              :value="hiringDate"
              autocomplete="off"
              @change="setHiringDate"
            ></b-form-input>
            <b-input-group-append style="border: 1px solid #ced4da;">
              <b-form-datepicker
                button-only
                right
                button-variant="white"
                :locale="$i18n.locale"
                :label-no-date-selected="$t('datePicker.noDate')"
                :label-calendar="$t('datePicker.calendar')"
                :label-close-button="$t('datePicker.close')"
                :label-current-month="$t('datePicker.currentMonth')"
                :label-help="$t('datePicker.help')"
                :label-nav="$t('datePicker.nav')"
                :label-next-decade="$t('datePicker.nextDecade')"
                :label-next-month="$t('datePicker.nextMonth')"
                :label-next-year="$t('datePicker.nextYear')"
                :label-prev-decade="$t('datePicker.previousDecade')"
                :label-prev-month="$t('datePicker.previousMonth')"
                :label-prev-year="$t('datePicker.previousYear')"
                :label-reset-button="$t('datePicker.reset')"
                :label-today="$t('datePicker.today')"
                :label-today-button="$t('datePicker.selectToday')"
                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                aria-controls="example-input"
                v-model="employee.hiringDate"
                @context="onSelectHiringDate"
              >
                <template #button-content>
                  <font-awesome-icon icon="calendar-alt" />
                </template>
              </b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <div class="w-100 mr-5 ml-md-5">
          <b-form-group
            :label="$t('employeeCreate.personnalInformation.fields.language.title')"
            label-for="name"
            class="mr-5 w-100 mb-2 mb-md-0"
            v-if="languages && languages.length"
          >
            <b-button-group>
              <b-button
                v-for="lang in languages"
                :key="lang.id"
                @click="employee.languageId = lang.id"
                :variant="employee.languageId == lang.id ? 'primary' : 'white'"
                class="border-input"
              >
                {{ $t('employeeCreate.personnalInformation.fields.language.' + lang.language) }}
              </b-button>
            </b-button-group>
          </b-form-group>
        </div>
        <div class="w-100 mr-5 ml-md-5"></div>
        <div class="w-100 mr-5 ml-md-5"></div>
      </div>
    </b-container>
    <h5 class="pt-4 px-4 pb-2">{{ $t('employeeCreate.contactInformation.title') }}</h5>

    <b-container fluid class="content p-4">
      <div class="d-flex flex-column flex-md-row mb-2 justify-content-between">
        <b-form-group
          :label="$t('employeeCreate.contactInformation.fields.cellPhone')"
          label-for="mobilePhone"
          class="mr-5 mb-2 mb-md-0 w-100"
        >
          <b-form-input
            id="telephone"
            v-mask="'(999) 999-9999'"
            v-model="employee.phone.mobile"
            placeholder="(___) ___-____"
            aria-describedby="cell-phone-feedback"
            type="text"
            @focus="checkMask('cell-phone', '###-###-####')"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('employeeCreate.contactInformation.fields.homePhone')"
          label-for="homePhone"
          class="mr-5 mb-2 mb-md-0 ml-md-5 w-100"
        >
          <b-form-input
            id="homePhone"
            v-mask="'(999) 999-9999'"
            v-model="employee.phone.home"
            placeholder="(___) ___-____"
            aria-describedby="cell-phone-feedback"
            type="text"
            @focus="checkMask('cell-phone', '###-###-####')"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('employeeCreate.contactInformation.fields.email')"
          :description="$t('employeeCreate.required')"
          label-for="homePhone"
          class="mr-5 ml-md-5 w-100"
        >
          <b-form-input
            type="email"
            id="email"
            v-model="employee.emailAddress"
            :state="!submited ? null : employee.emailAddress !== '' && null"
            maxlength="128"
            aria-describedby="input-live-help email-feedback"
            :class="{ required: !employee.emailAddress || employee.emailAddress === '' }"
          ></b-form-input>
        </b-form-group>
        <div class="w-100 mr-5 ml-md-5"></div>
      </div>
    </b-container>
    <h5 class="pt-4 px-4 pb-2">{{ $t('employeeCreate.posAccess.title') }}</h5>

    <b-container fluid class="content p-4">
      <div class="d-flex flex-column flex-md-row mb-2 justify-content-between">
        <b-form-group
          :label="$t('employeeCreate.posAccess.fields.accessCode')"
          :description="$t('employeeCreate.requiredCode')"
          label-for="accessCode"
          class="mr-5 mb-2 mb-md-0 w-100"
        >
          <b-form-input
            id="accessCode"
            v-model="employee.code"
            :formatter="formatCode"
            maxlength="128"
            aria-describedby="access-code-feedback"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('employeeCreate.posAccess.fields.password')"
          :description="$t('employeeCreate.requiredPassword')"
          label-for="passwd"
          class="mr-5 ml-md-5 w-100"
        >
          <b-form-input
            id="passwd"
            maxlength="128"
            v-model="employee.password"
            :formatter="formatCode"
            aria-describedby="input-live-help input-live-feedback"
          ></b-form-input>
        </b-form-group>
        <div class="mr-5 ml-md-5 w-100"></div>
        <div class="mr-5 ml-md-5 w-100"></div>
      </div>
    </b-container>
    <h5 class="pt-4 px-4 pb-2">{{ $t('employeeCreate.integration.title') }}</h5>
    <b-container fluid class="content p-4">
      <div class="d-flex flex-column flex-md-row mb-4 justify-content-between">
        <b-form-group
          :label="$t('employeeCreate.integration.fields.code')"
          label-for="employee-code"
          class="mr-5 w-100"
        >
          <b-form-input
            id="employee-code"
            v-model="employee.integrationCode"
            maxlength="128"
            aria-describedby="employee-code-feedback"
          ></b-form-input>
        </b-form-group>
        <div class="w-100 mr-5 ml-md-5"></div>
        <div class="w-100 mr-5 ml-md-5"></div>
        <div class="w-100 mr-5 ml-md-5"></div>
      </div>
    </b-container>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import { getDateFormatFromUserConfig } from '@/helpers/date.helpers';

export default {
  name: 'employee-form-personal-information',
  components: {},
  props: {
    employee: {
      type: Object,
      default: () => ({ name: '', birthDate: '', phone: { mobile: '', home: '' } })
    },
    languages: {
      type: Array,
      default: () => []
    },
    pending: {
      type: Boolean,
      default: false
    },
    submited: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      birthDate: '',
      hiringDate: ''
    };
  },
  computed: {},
  methods: {
    setBirthDate(val) {
      if (val === '') {
        this.employee.birthDate = null;
      }
      if (val.length === 10) {
        this.employee.birthDate = DateTime.fromFormat(val, 'D').toISO();
      }
    },
    setHiringDate(val) {
      if (val === '') {
        this.employee.hiringDate = null;
      }
      if (val.length === 10) {
        this.employee.hiringDate = DateTime.fromFormat(val, 'D').toISO();
      }
    },
    onSelectBirthDate(ctx) {
      this.birthDate = ctx.selectedFormatted;
    },
    onSelectHiringDate(ctx) {
      this.hiringDate = ctx.selectedFormatted;
    },
    formatName(value) {
      return value.replace(/[^a-zA-Z0-9_\u00C0-\u024F\u1E00-\u1EFF .-]/g, '');
    },
    formatCode(value) {
      return value.replace(/[^0-9]/g, '');
    },
    formatDate(value) {
      if (!value) {
        return '--';
      }
      return DateTime.fromISO(value).toFormat(getDateFormatFromUserConfig());
    },
    formatDatePicker(value) {
      return (
        (DateTime.fromISO(value).toFormat(getDateFormatFromUserConfig()) === 'Invalid DateTime' && value) ||
        DateTime.fromISO(value).toFormat(getDateFormatFromUserConfig())
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.datepicker /deep/ button {
  background-color: white;
  border-bottom: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
}

h5 {
  background-color: #f5f5f5;
}

/deep/ {
  label,
  .form-text {
    margin-bottom: 0;
    padding-left: 1rem;
  }
}

/deep/ .form-text {
  min-height: 18px;
}

.datepicker .input-group-append {
  margin-left: -35px;
}
</style>
