<template>
  <vel-select
    :autofocus="autofocus"
    :disabled="disabled"
    :filterable="isFilterable"
    :multiple="multiple"
    :no-data-text="$t('currency.selector.nodata')"
    :no-match-text="$t('currency.selector.nomatch')"
    :placeholder="$t('currency.selector.placeholder')"
    :value="currencyId"
    @change="handleChange"
    class="vel-select"
  >
    <vel-option-group
      v-if="allowGlobalCurrencyOption"
      :label="showGlobalCurrencyOptionGroupLabel ? $t('currency.selector.options.groups.all.title') : ''"
    >
      <vel-option
        :label="allCurrenciesLabel || $t('currency.selector.options.groups.all.defaultLabel')"
        :value="''"
      ></vel-option>
    </vel-option-group>

    <vel-option-group
      v-if="currenciesOptions.length > 0"
      :label="allowGlobalCurrencyOption ? $t('currency.selector.options.groups.locations.title') : ''"
    >
      <vel-option
        v-for="option in currenciesOptions"
        :label="option.label"
        :value="option.value"
        :key="option.value"
      ></vel-option>
    </vel-option-group>
  </vel-select>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelOption from '@/components/x-option/VelOption';
import VelOptionGroup from '@/components/x-option-group/VelOptionGroup';
import VelSelect from '@/components/x-select/VelSelect';

export default {
  name: 'vel-currency-selector',
  components: {
    VelOption,
    VelOptionGroup,
    VelSelect
  },
  mixins: [DeviceMixin],
  props: {
    allCurrenciesLabel: {
      type: String,
      default: ''
    },
    allowGlobalCurrencyOption: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: true
    },
    currenciesOptions: {
      type: Array,
      required: true
    },
    currencyId: {
      type: [String, Array],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    selectFirst: {
      type: Boolean,
      default: true
    },
    showGlobalCurrencyOptionGroupLabel: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isFilterable() {
      return this.isMediumDown ? false : this.filterable;
    }
  },
  methods: {
    handleChange(e) {
      this.$emit('change', e);
    }
  },
  beforeUpdate() {
    if (this.selectFirst && this.currencyId === null && this.currenciesOptions.length) {
      this.handleChange(this.currenciesOptions[0].value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

:root .el-select-dropdown__item {
  max-width: 17rem;
  @include text-ellipsis;

  @include breakpoint(small) {
    max-width: 20rem;
  }

  @include breakpoint(medium) {
    max-width: none;
  }
}

.vel-select {
  @include noPrint;
}
</style>
