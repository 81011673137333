<template>
  <div class="config_dashboard_display bootstrap">
    <div>
      <div class="w-100 text-right">
        <span style="cursor: pointer;" @click="showConfirmDialog = true">
          {{ $t('customizer.dashboard.reset.linkText') }}
        </span>
      </div>
      <div v-for="(opt, i) in list" :key="opt.text">
        <vel-divider content-position="left">{{ opt.title }}</vel-divider>
        <draggable @change="update" v-model="opt.elements" :group="i" :options="{ delayOnTouchOnly: true, delay: 800 }">
          <transition-group type="transition" name="flip-list">
            <div
              class="row my-2 mx-0 py-1 item"
              style="border: 1px solid #dddddd; border-radius: 5px;"
              v-for="el in opt.elements"
              :key="el.text"
            >
              <div class="col-10 d-flex align-items-center" v-if="isAllowed(el.model)">
                <!--<span class="material-icons">&#xe945;</span>-->
                <DragIndicator class="drag-icon" scale="0.5" />
                {{ $t(el.text) }}
              </div>
              <div class="col-2 text-right" v-if="isAllowed(el.model)">
                <b-form-checkbox
                  v-model="config[el.model].value"
                  @change="update"
                  name="check-button"
                  switch
                ></b-form-checkbox>
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
      <employee-delete-modal
        :title="$t('customizer.dashboard.reset.title')"
        :body="$t('customizer.dashboard.reset.body')"
        :yes-action-label="'customizer.dashboard.reset.yes'"
        :no-action-label="'customizer.dashboard.reset.no'"
        :show="showConfirmDialog"
        @onDelete="reset()"
        @onCancel="showConfirmDialog = false"
      ></employee-delete-modal>
    </div>
  </div>
</template>
<script>
import DragIndicator from '@/assets/svg/icons/drag_indicator.svg';
import Draggable from 'vuedraggable';
import EmployeeDeleteModal from '@/private/components/employees/components/EmployeeDeleteModal';
import VelDivider from '@/components/divider/VelDivider';
import { environment } from '@/configs';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataConfig } from '@/stores/data/config.module';

export default {
  name: 'config-dashboard-display',
  components: {
    VelDivider,
    Draggable,
    EmployeeDeleteModal,
    DragIndicator
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get', 'getPending']),
    config: {
      get() {
        return this.get;
      },
      set() {
        return true;
      }
    },
    list() {
      return this.options;
    }
  },
  data() {
    return {
      options: [],
      showConfirmDialog: false
    };
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.updateOptions();
      }
    }
  },
  mounted() {
    this.updateOptions();
  },
  methods: {
    ...mapPiniaActions(useDataConfig, ['patchConfig']),
    updateOptions(reset = false) {
      this.options = [
        {
          title: this.$t('editors.groups.indicators'),
          elements: [
            {
              model: 'dashboard_show_gross_sales_volume',
              text: 'dashboard.salesVolume.title',
              order: !reset ? this.getOrder('dashboard_show_gross_sales_volume', 1) : 1
            },
            {
              model: 'dashboard_show_discounts_volume',
              text: 'dashboard.discountsVolume.title',
              order: !reset ? this.getOrder('dashboard_show_discounts_volume', 2) : 2
            },
            {
              model: 'dashboard_show_cash_discounts',
              text: 'dashboard.cashDiscounts.title',
              order: !reset ? this.getOrder('dashboard_show_cash_discounts', 3) : 3
            },
            {
              model: 'dashboard_show_surcharge',
              text: 'dashboard.surcharge.title',
              order: !reset ? this.getOrder('dashboard_show_surcharge', 4) : 4
            },
            {
              model: 'dashboard_show_net_sales_volume',
              text: 'dashboard.netSalesVolume.title',
              order: !reset ? this.getOrder('dashboard_show_net_sales_volume', 5) : 5
            },
            {
              model: 'dashboard_show_taxe_volume',
              text: 'dashboard.taxesVolume.title',
              order: !reset ? this.getOrder('dashboard_show_taxe_volume', 6) : 6
            },
            {
              model: 'dashboard_show_net_sales_volume_taxes',
              text: 'dashboard.netSalesWithTaxesVolume.title',
              order: !reset ? this.getOrder('dashboard_show_net_sales_volume_taxes', 7) : 7
            },
            {
              model: 'dashboard_show_tips_total_amount',
              text: 'dashboard.tipsTotalAmount.title',
              order: !reset ? this.getOrder('dashboard_show_tips_total_amount', 8) : 8
            },
            {
              model: 'dashboard_show_total_with_tips_amount',
              text: 'dashboard.totalWithTipsAmount.title',
              order: !reset ? this.getOrder('dashboard_show_total_with_tips_amount', 9) : 9
            },
            {
              model: 'dashboard_show_customer_count',
              text: 'dashboard.customersCount.title',
              order: !reset ? this.getOrder('dashboard_show_customer_count', 10) : 10
            },
            {
              model: 'dashboard_show_discounts_percentage',
              text: 'dashboard.discountsPercentage.title',
              order: !reset ? this.getOrder('dashboard_show_discounts_percentage', 11) : 11
            },
            {
              model: 'dashboard_show_meal_count',
              text: 'dashboard.mealCount.title',
              order: !reset ? this.getOrder('dashboard_show_meal_count', 12) : 12
            },
            {
              model: 'dashboard_show_paid_ins',
              text: 'dashboard.paidInsAmount.title',
              order: !reset ? this.getOrder('dashboard_show_paid_ins', 13) : 13
            },
            {
              model: 'dashboard_show_paid_outs',
              text: 'dashboard.paidOuts.title',
              order: !reset ? this.getOrder('dashboard_show_paid_outs', 14) : 14
            },
            {
              model: 'dashboard_show_net_cash',
              text: 'dashboard.netCashAmount.title',
              order: !reset ? this.getOrder('dashboard_show_net_cash', 15) : 15
            },
            {
              model: 'dashboard_show_gross_sales_per_order',
              text: 'dashboard.salesPerOrder.title',
              order: !reset ? this.getOrder('dashboard_show_gross_sales_per_order', 16) : 16
            },
            {
              model: 'dashboard_show_gross_sales_per_customer',
              text: 'dashboard.salesPerCustomer.title',
              order: !reset ? this.getOrder('dashboard_show_gross_sales_per_customer', 17) : 17
            },
            {
              model: 'dashboard_show_net_income_per_customer',
              text: 'dashboard.netIncomeCustomer.title',
              order: !reset ? this.getOrder('dashboard_show_net_income_per_customer', 18) : 18
            },
            {
              model: 'dashboard_show_income_per_order',
              text: 'dashboard.incomeOrder.title',
              order: !reset ? this.getOrder('dashboard_show_income_per_order', 19) : 19
            },
            {
              model: 'dashboard_show_order_count',
              text: 'dashboard.orderCount.title',
              order: !reset ? this.getOrder('dashboard_show_order_count', 20) : 20
            },
            {
              model: 'dashboard_show_net_income_meal',
              text: 'dashboard.netIncomePerMeal.title',
              order: !reset ? this.getOrder('dashboard_show_net_income_meal', 21) : 21
            },
            {
              model: 'dashboard_show_gross_income_meal',
              text: 'dashboard.grossIncomePerMeal.title',
              order: !reset ? this.getOrder('dashboard_show_gross_income_meal', 22) : 22
            }
          ]
            .sort((a, b) => a.order - b.order)
            .map(x => {
              if (!this.config[x.model]) {
                this.config[x.model] = { value: true, order: 0 };
              }
              return x;
            })
        },
        {
          title: this.$t('editors.groups.charts'),
          elements: [
            {
              model: 'dashboard_show_gross_sales_per_hour',
              text: 'dashboard.salesPerHourChart.title',
              order: !reset ? this.getOrder('dashboard_show_gross_sales_per_hour', 1) : 1
            },
            {
              model: 'dashboard_cumulative_gross_sales_per_hour',
              text: 'dashboard.salesCumulativeLineChart.title',
              order: !reset ? this.getOrder('dashboard_cumulative_gross_sales_per_hour', 2) : 2
            },
            {
              model: 'dashboard_show_locations_sales',
              text: 'locations.sales.graph.config.title',
              order: !reset ? this.getOrder('dashboard_show_locations_sales', 3) : 3
            },
            {
              model: 'dashboard_show_item_sales_top10',
              text: 'items.sales.graph.config.title',
              order: !reset ? this.getOrder('dashboard_show_item_sales_top10', 4) : 4
            },
            {
              model: 'dashboard_show_employees_sales_top10',
              text: 'employees.sales.graph.config.title',
              order: !reset ? this.getOrder('dashboard_show_employees_sales_top10', 5) : 5
            },
            {
              model: 'dashboard_show_gross_sales_per_order_mode',
              text: 'sales.zoneChart.config.title',
              order: !reset ? this.getOrder('dashboard_show_gross_sales_per_order_mode', 6) : 6
            },
            {
              model: 'dashboard_show_gross_sales_per_department',
              text: 'sales.departmentChart.config.title',
              order: !reset ? this.getOrder('dashboard_show_gross_sales_per_department', 7) : 7
            },
            {
              model: 'dashboard_show_gross_sales_per_mode',
              text: 'sales.modeChart.config.title',
              order: !reset ? this.getOrder('dashboard_show_gross_sales_per_mode', 8) : 8
            },
            {
              model: 'dashboard_show_customers_per_mode',
              text: 'sales.modeCustomersChart.config.title',
              order: !reset ? this.getOrder('dashboard_show_customers_per_mode', 9) : 9
            },
            {
              model: 'dashboard_show_mode_sales_per_customers',
              text: 'sales.modeSalesPerCustomersChart.config.title',
              order: !reset ? this.getOrder('dashboard_show_mode_sales_per_customers', 10) : 10
            },
            {
              model: 'dashboard_show_workstations_sales',
              text: 'sales.workstationsSalesChart.config.title',
              order: !reset ? this.getOrder('dashboard_show_workstations_sales', 11) : 11
            },
            {
              model: 'dashboard_show_gross_sales_per_big_division',
              text: 'sales.bigDivisionChart.config.title',
              order: !reset ? this.getOrder('dashboard_show_gross_sales_per_big_division', 12) : 12
            },
            {
              model: 'dashboard_show_discounts_table',
              text: 'discountsTable.title',
              order: !reset ? this.getOrder('dashboard_show_discounts_table', 13) : 13
            }
          ]
            .sort((a, b) => a.order - b.order)
            .map(x => {
              if (!this.config[x.model]) {
                this.config[x.model] = { value: true, order: 0 };
              }
              return x;
            })
        }
      ];
    },
    getOrder(key, defaultOrder = 0) {
      return (this.config && this.config[key] && this.config[key].order) || defaultOrder;
    },
    reset() {
      this.showConfirmDialog = false;
      setTimeout(() => {
        this.$nextTick(() => {
          this.updateOptions(true);
          this.options.forEach(g => {
            g.elements.forEach((s, newOrder) => {
              if (this.config[s.model]) {
                this.config[s.model].value = true;
                this.config[s.model].order = newOrder + 1;
              }
            });
          });
          this.patchConfig({ config: this.config, reset: true });
        });
      }, 200);
    },
    update() {
      this.options.forEach(g => {
        g.elements.forEach((s, newOrder) => {
          if (this.config[s.model]) {
            this.config[s.model].order = newOrder + 1;
          }
        });
      });
      this.$emit('update', this.config);
      this.patchConfig({ config: this.config });
    },
    isAllowed(value) {
      return environment[value] !== false;
    }
  }
};
</script>

<style lang="scss">
@import 'mixins.scss';
@import 'constants.scss';

$page__header-shadow-color: rgba(black, 0.03);

.fa.fa-grip:before {
  content: '\f142 \202F \f142 \202F \f142';
}

.sortable-chosen {
  background-color: #2a3142 !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: white !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
}

.sortable-ghost,
.sortable-drag {
  opacity: 1 !important;
  -moz-opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.drag-icon {
  color: #cccccc;
}
.col-10 {
  padding-left: 0.5rem !important;
}

.item {
  box-shadow: 0 2px 2px $page__header-shadow-color, 0 1px 0 $page__header-shadow-color;
  cursor: grab;
}

.config_dashboard_display {
  display: block;

  .el-checkbox {
    display: block;
  }

  .el-divider__text {
    background-color: $wild-sand;
    padding: 0 10px 0 0;

    &.is-left {
      left: 0;
    }
  }
}
</style>
