<template>
  <div v-loading="isLoading" class="users-commulative__chart">
    <h5>{{ $t('analytics.usersCummulativeMonth.title') }}</h5>
    <div v-if="!isLoading">
      <apexchart v-if="!loading" type="area" height="330" :options="chartOptions" :series="series" />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import VueApexCharts from 'vue-apexcharts';
import { mapState as mapPiniaState } from 'pinia/dist/pinia.esm-browser';
import { useDataUsers } from '@/stores/data/users.module';
import { mapActions as mapPiniaActions } from 'pinia/dist/pinia';

export default {
  name: 'users-cummulative-month',
  components: {
    apexchart: VueApexCharts
  },
  computed: {
    ...mapPiniaState(useDataUsers, {
      getUsersAll: 'getUsersAll',
      isLoading: 'isLoading'
    }),
    chartOptions() {
      return {
        chart: {
          type: 'line',
          dropShadow: {
            enabled: false,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        stroke: {
          curve: 'smooth',
          width: [6, 3, 3, 3],
          dashArray: [0, 0, 0, 0]
        },
        fill: {
          type: ['gradient', 'gradient', 'gradient', 'gradient'],
          opacity: [0.85, 0.65, 0.65, 0.65],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: [0, 0, 0, 0],
            opacityTo: [0.25, 0.25, 0.25, 0.25],
            stops: [100, 100, 100, 100]
          }
        },
        colors: [
          '#ADD8C7',
          '#F7B844',
          '#008ffb',
          '#EC3C65',
          '#CDD7B6',
          '#C1F666',
          '#D43F97',
          '#1E5D8C',
          '#421243',
          '#7F94B0',
          '#EF6537',
          '#C0ADDB'
        ],
        dataLabels: {
          enabled: true
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        markers: {
          size: 1
        },
        xaxis: {
          title: {
            text: this.$t('analytics.usersCummulativeMonth.xaxis')
          },
          categories: this.labels()
        },
        yaxis: {
          opposite: false,
          title: {
            text: this.$t('analytics.usersCummulativeMonth.yaxis')
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'right',
          floating: true,
          offsetY: 8,
          offsetX: 5,
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          }
        }
      };
    },
    series() {
      const data = {};

      Object.keys(this.values).forEach(k => {
        const year = new Date(k).getUTCFullYear();
        const month = new Date(k).getUTCMonth();
        if (!data[year]) {
          data[year] = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 };

          if (year === new Date().getUTCFullYear()) {
            for (let i = new Date().getUTCMonth() + 1; i <= 12; i++) {
              data[year][i] = null;
            }
          }
        }
        data[year][month + 1] = this.values[k];
      });
      return Object.keys(data).map(k => ({
        name: k,
        data: Object.values(data[k])
      }));
    },
    values() {
      let total = 1;
      return (this.getUsersAll || [])
        .sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())
        .reduce((a, b) => {
          const aggregateValue = `${b.created.substr(0, 7)}-01`;
          a[aggregateValue] = total++;
          return a;
        }, {});
    }
  },
  // work arround for translation problems with apex
  watch: {
    '$i18n.locale'() {
      this.loading = true;
      this.$nextTick(() => {
        this.loading = false;
      });
    }
  },
  data() {
    return {
      loading: false,
      labels() {
        return [
          '2021-01-01',
          '2021-02-01',
          '2021-03-01',
          '2021-04-01',
          '2021-05-01',
          '2021-06-01',
          '2027-07-01',
          '2021-08-01',
          '2021-09-01',
          '2021-10-01',
          '2021-11-01',
          '2021-12-01'
        ].map(d => DateTime.fromISO(d).toFormat('LLL'));
      }
    };
  },
  methods: {
    ...mapPiniaActions(useDataUsers, ['getUsers'])
  },
  mounted() {
    this.getUsers();
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

$charts-empty-text-color: $shuttle-gray;
$charts-title-text-color: $river-bed;

.users-commulative__chart {
  font-family: $base-font-family;

  .chart__empty-state {
    color: $charts-empty-text-color;
  }

  .apexcharts-xaxis-texts-g text:nth-child(2n):not(:last-child) {
    visibility: hidden;
  }

  h5 {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
}

.apexcharts-title-text {
  color: $charts-title-text-color;
  font-size: rem-calc(13);
  font-weight: 700;
  text-transform: uppercase;
  @include breakpoint(medium) {
    font-size: rem-calc(15);
  }
}

.apexcharts-xaxistooltip {
  overflow: visible;
}

.apexcharts-xaxistooltip-text {
  /* stylelint-disable */
  min-width: 30px !important;
}

.apexcharts-legend-text,
.apexcharts-tooltip-text-label {
  display: inline-block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}
</style>
