<template>
  <portal to="app">
    <vel-modal class="coupon-import-csv__modal" dismissable @dismiss="handleCancel">
      <template v-slot:header>
        {{ $t('coupons.modals.batchDelete.title') }}
      </template>
      <template v-slot:default>
        <vel-alert
          @dismiss="clearBatchDeletedCouponsError"
          class="alert import-csv-coupon__error"
          level="error"
          :autoDismissTime="0"
          v-if="hasBatchDeleteCouponsError"
        >
          <span v-html="$t('coupons.errors.batchDeleteCouponHtml', { errorText: batchDeleteCouponsError.text })" />
        </vel-alert>

        <div class="import-csv__info">
          <p class="info__text" v-html="$t('coupons.modals.batchDelete.textHtml')" />
        </div>

        <div class="import-csv__upload">
          <h4>{{ $t('defaults.upload.selectAFile') }}</h4>

          <vel-file-upload
            ref="velUpload"
            accept="text/csv, text/x-csv"
            :onSubmit="analyzeFile"
            :additionalData="uploadFormData"
            @select="onFileSelected"
          />
        </div>

        <div class="import-csv__requirements">
          <h4 class="requirements__title">
            {{ $t('coupons.modals.batchDelete.requirementsTitle') }}
          </h4>
          <ul class="requirements__text" v-html="$t('coupons.modals.batchDelete.requirementsHtml')" />
        </div>
      </template>
      <template v-slot:footer>
        <vel-button
          class="import-csv__close-button"
          type="secondary"
          @click="handleCancel"
          :disabled="isLoading || isSavingCoupon"
        >
          {{ $t('defaults.actions.close') }}
        </vel-button>
        <vel-button
          class="import-csv__confirm-button"
          :disabled="isFileListEmpty"
          :loading="isLoading || isSavingCoupon"
          @click="handleSubmit"
        >
          {{ $t('coupons.modals.batchDelete.button.text') }}
        </vel-button>
      </template>
    </vel-modal>
    <batch-delete-coupons-confirm-modal
      v-show="showConfirm"
      @dismiss="handleConfirmCancel"
      @confirm="handleConfirmSubmit"
      :count="numberOfCodesInFile"
      :data="formData"
    ></batch-delete-coupons-confirm-modal>
  </portal>
</template>

<script>
import BatchDeleteCouponsConfirmModal from './BatchDeleteCouponsConfirmModal';
import VelAlert from '@/components/alert/VelAlert';
import VelButton from '@/components/button/VelButton';
import VelFileUpload from '@/components/file-upload/VelFileUpload';
import VelModal from '@/components/modal/VelModal';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia';
import { useDataCoupons } from '@/stores/data/coupons.module';

export default {
  name: 'batch-delete-coupons-modal',
  components: {
    BatchDeleteCouponsConfirmModal,
    VelButton,
    VelModal,
    VelAlert,
    VelFileUpload
  },
  props: {
    currentGroupId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      fileList: [],
      isFileListEmpty: true,
      showConfirm: false,
      numberOfCodesInFile: 0,
      formData: null
    };
  },
  methods: {
    ...mapPiniaActions(useDataCoupons, [
      'clearLatestBatchDeletedCoupons',
      'batchDeleteCoupons',
      'clearBatchDeletedCouponsError'
    ]),
    handleConfirmCancel() {
      this.showConfirm = false;
    },
    handleConfirmSubmit() {
      this.showConfirm = false;
      this.batchDeleteCoupons(this.formData);
    },
    handleCancel() {
      this.$emit('dismiss');
    },
    handleSubmit() {
      this.$refs.velUpload.submit();
    },
    onFileSelected(fileList) {
      this.isFileListEmpty = !fileList || fileList.length === 0;
    },
    analyzeFile(data) {
      this.formData = data;
      const file = data.get('file');
      const reader = new FileReader();
      reader.onload = this.countLines;
      reader.readAsText(file);
    },
    countLines(e) {
      this.numberOfCodesInFile = 0;

      const lines = e.target.result.replace('\r', '').split('\n');
      let size = 0;
      lines.forEach(line => {
        if (line !== '') {
          size++;
        }
      });

      if (size > 0) {
        size--; //Remove header
      }

      this.numberOfCodesInFile = size;
      this.showConfirm = true;
    }
  },
  watch: {
    latestBatchDeletedCoupons(imported) {
      console.log(imported);
      if (imported) {
        this.$emit('dismiss');
      }
    }
  },
  computed: {
    ...mapPiniaState(useDataCoupons, [
      'hasBatchDeleteCouponsError',
      'latestBatchDeletedCoupons',
      'isLoading',
      'isSavingCoupon',
      'batchDeleteCouponsError'
    ]),
    uploadFormData() {
      return [
        {
          name: 'groupId',
          value: this.currentGroupId
        }
      ];
    }
  },
  created() {
    this.clearLatestBatchDeletedCoupons();
    this.clearBatchDeletedCouponsError();
  }
};
</script>
<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.import-csv-coupon__error {
  margin-bottom: rem-calc(16);

  /deep/ p {
    margin-top: rem-calc(16);
  }
}

.import-csv__requirements {
  background-color: $gallery;
  display: flex;
  flex-direction: column;
  margin-top: rem-calc(16);
  padding: rem-calc(16) rem-calc(10);

  .requirements__text {
    margin-left: 0;
  }
}

.import-csv__upload {
  margin-top: rem-calc(16);

  .vel-upload__uploader {
    margin-top: rem-calc(16);
    width: 100%;
  }
}
</style>
