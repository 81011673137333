import { get, resolveCommonQuery } from '@/helpers/service.helper';
import { getToken } from '@/helpers/token.helper';

/**
 * Get currencies
 * @link https://api.posveloce.com/docs/private#operation/getCurrencies
 */
export const getCurrencies = (params: {} = {}) => {
  const url = resolveCommonQuery('/currencies', params).toString();
  return get(getToken(), url);
};
