<template>
  <vel-backoffice-page
    class="backoffice-page bootstrap"
    :enableToolbar="false"
    :enable-date-range="false"
    :enable-date-picker="false"
    :enable-groups="false"
    :show-date-compare="false"
    :enable-all-locations="false"
    :scroll="true"
  >
    <h4
      data-v-1bae3c7b=""
      class="p-3 bg-white w-100"
      style="border-bottom: 1px solid rgba(76, 86, 103, 0.2); border-top: 1px solid rgba(76, 86, 103, 0.2);"
    >
      {{ formData.name || '--' }}
    </h4>
    <h5 class="pt-4 px-4 pb-2">{{ $t('createMixMatch.generalConfiguration.title') }}</h5>
    <b-container fluid class="content px-4 pt-4 pb-0">
      <div class="d-flex flex-column flex-md-row mb-md-4 justify-content-between">
        <b-form-group
          :description="$t('createMixMatch.generalConfiguration.input.required')"
          :label="$t('createMixMatch.generalConfiguration.input.label')"
          label-for="name"
        >
          <b-form-input
            id="name"
            maxlength="50"
            v-model="formData.name"
            trim
            :class="{ required: !formData.name || formData.name === '' }"
          ></b-form-input>
        </b-form-group>
      </div>
    </b-container>
    <h5 class="pt-4 px-4 pb-2">{{ $t('createMixMatch.activation.title') }}</h5>
    <b-container fluid class="content p-4">
      <b-form-checkbox
        id="alwaysActive"
        name="alwaysActive"
        @change="handleIsAlwaysActive"
        v-model="formData.alwaysActive"
        :class="{ 'mb-4': !formData.alwaysActive }"
      >
        {{ $t('createMixMatch.activation.checkboxLabel') }}
      </b-form-checkbox>

      <div style="background: #fff; -ms-overflow-style: none; scrollbar-width: none;">
        <mix-match-activation-form
          v-for="(activation, index) in formData.activations"
          :activation="activation"
          :activations="formData.activations"
          @addActivation="addActivation"
          :always-active="formData.alwaysActive"
          :available-hours="availableHours"
          :available-week-days="availableWeekDays"
          :index="index"
          :key="`activations-${index}`"
          :show-delete-button="canShowDeleteButton(formData.activations)"
          @removeActivation="handleRemoveActivation(index)"
        />
      </div>
    </b-container>

    <h5 class="pt-4 px-4 pb-2">{{ $t('createMixMatch.priceConfiguration.title') }}</h5>
    <b-container fluid class="content p-4">
      <div>
        <div style="background: #fff; -ms-overflow-style: none; scrollbar-width: none;">
          <div
            style="padding: 24px 0 0 0;"
            class="w-100 d-flex"
            v-for="(priceConfig, index) in formData.priceConfigurations"
            :key="`priceConfig-${index}`"
          >
            <b-row class="mx-0 price-configuration">
              <b-col cols="4">
                <b-form-group :label="index === 0 && $t('createMixMatch.priceConfiguration.qtyLabel')">
                  <b-form-input
                    v-model.number="formData.priceConfigurations[index].quantity"
                    :formatter="formatQty"
                    :placeholder="$t('createMixMatch.priceConfiguration.quantity')"
                    maxlength="10"
                    type="number"
                    number
                    min="0"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group :label="index === 0 && $t('createMixMatch.priceConfiguration.priceLabel')">
                  <b-input-group prepend="$">
                    <b-form-input
                      id="name"
                      v-model.number="formData.priceConfigurations[index].amount"
                      no-wheel
                      step="0.01"
                      lazy-formatter
                      :formatter="formatAmount"
                      type="number"
                      aria-describedby="input-live-help input-live-feedback"
                      maxlength="10"
                      min="0"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="d-flex align-items-center justify-content-center">
                <b-form-group :label="index === 0 && $t('createMixMatch.priceConfiguration.totalLabel')">
                  <b-checkbox v-model="formData.priceConfigurations[index].totalPrices"></b-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col class="d-flex align-items-center">
              <b-icon
                v-if="canShowDeleteButton(formData.priceConfigurations)"
                icon="x-circle"
                font-scale="1.5"
                class="rounded mr-2"
                variant="danger"
                style="cursor: pointer;"
                @click="handleRemovePriceConfig(index)"
              ></b-icon>
              <b-icon
                v-if="formData.priceConfigurations.length < 10"
                icon="plus-circle"
                font-scale="1.5"
                class="rounded"
                variant="primary"
                style="cursor: pointer;"
                @click="addPriceConfig"
              ></b-icon>
            </b-col>
          </div>
        </div>
      </div>
    </b-container>
    <template v-slot:footer>
      <b-button :disabled="!canSave" @click="handleCreateMixMatch" variant="primary">
        {{ $t('defaults.actions.save') }}
      </b-button>
      <b-button @click="handleShowCancelModal" variant="outline-primary">
        {{ $t('defaults.actions.cancel') }}
      </b-button>
    </template>
    <b-modal
      v-model="showModal"
      :title="$t('createMixMatch.modals.cancel.title')"
      :static="true"
      :centered="true"
      :hide-header-close="true"
      :footer-bg-variant="'light'"
      :footer-text-variant="'dark'"
    >
      <b-row class="mb-4">
        {{ $t('createMixMatch.modals.cancel.body') }}
      </b-row>
      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end;">
          <b-button style="border: none; margin-right: 15px;" @click="handleDiscardChanges" variant="outline-primary">
            {{ $t('createMixMatch.modals.cancel.discard') }}
          </b-button>
          <b-button @click="showModal = false" variant="primary">
            {{ $t('createMixMatch.modals.cancel.continue') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </vel-backoffice-page>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import MixMatchActivationForm from './components/MixMatchActivationForm';
import VelBackofficePage from '@/components/page/VelBackofficePage';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import locationService from '@/services/locations.service';
import { getToken } from '@/helpers/token.helper';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataMixMatch } from '@/stores/data/mix-match.module';

export default {
  name: 'CreateMixMatchPage',
  mixins: [DeviceMixin],
  components: {
    MixMatchActivationForm,
    VelBackofficePage
  },
  data() {
    return {
      initialForm: null,
      latestForm: {},
      showModal: false,
      formData: {
        name: '',
        licenceNumber: 0,
        activations: [{ startDate: null, endDate: null, startTime: '', endTime: '', weekday: '' }],
        priceConfigurations: [{ quantity: '', amount: '', totalPrices: false }],
        alwaysActive: false
      }
    };
  },
  computed: {
    ...mapPiniaState(useDataMixMatch, ['selectSelected']),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById']),
    availableHours() {
      const hours = [];
      for (let i = 0; i < 24; i += 0.5) {
        const isHalfHour = i.toString().includes('.5');
        const isAfterNoon = i >= 13;
        hours.push({
          time: `${
            isHalfHour
              ? `${Math.floor(isAfterNoon ? i - 12 : i)}:30 ${i >= 12 ? 'PM' : 'AM'}`
              : `${Math.floor(isAfterNoon ? i - 12 : i)}:00  ${i >= 12 ? 'PM' : 'AM'}`
          }`,
          value: `${
            isHalfHour
              ? `${isAfterNoon ? Math.floor(i) : `0${Math.floor(i)}`}:30:00`
              : `${isAfterNoon ? i : `0${i}`}:00:00`
          }`
        });
      }
      return hours;
    },
    availableWeekDays() {
      return [
        {
          day: this.$t('createMixMatch.activation.weekDays.mon'),
          value: 0
        },
        {
          day: this.$t('createMixMatch.activation.weekDays.tue'),
          value: 1
        },
        {
          day: this.$t('createMixMatch.activation.weekDays.wed'),
          value: 2
        },
        {
          day: this.$t('createMixMatch.activation.weekDays.thu'),
          value: 3
        },
        {
          day: this.$t('createMixMatch.activation.weekDays.fri'),
          value: 4
        },
        {
          day: this.$t('createMixMatch.activation.weekDays.sat'),
          value: 5
        },
        {
          day: this.$t('createMixMatch.activation.weekDays.sun'),
          value: 6
        },
        {
          day: this.$t('createMixMatch.activation.weekDays.all'),
          value: 7
        }
      ];
    },
    getLicenceNumber() {
      return (
        this.getLocationsById(this.$router.currentRoute.query.locationId) &&
        this.getLocationsById(this.$router.currentRoute.query.locationId).licenceNumber
      );
    },
    canSave() {
      return this.areActivationsValid && this.arePriceConfigsValid && this.isNameValid && this.hasFormChanged;
    },
    isNameValid() {
      return this.formData.name.length > 0 && this.formData.name.length <= 50;
    },
    areActivationsValid() {
      return (
        this.formData.alwaysActive ||
        (this.formData.activations || []).every(
          x => x.startDate && x.endDate && x.startTime && x.endTime && x.weekday !== ''
        )
      );
    },
    arePriceConfigsValid() {
      return this.formData.priceConfigurations.every(x => x.quantity && x.amount);
    },
    hasFormChanged() {
      return !isEqual(this.initialForm, this.formData);
    }
  },
  methods: {
    ...mapPiniaActions(useDataMixMatch, ['setSelected', 'createItemMixMatch', 'getItemsMixMatch']),
    toast(msg = 'toast.update', value = {}) {
      this.$toast.open({
        message: `${this.$t(msg, value)}`,
        position: 'bottom',
        type: 'success',
        queue: false,
        duration: 6000,
        dismissible: true
      });
    },
    addActivation() {
      if (this.formData.activations.length < 5) {
        this.formData.activations.push({ startDate: null, endDate: null, startTime: '', endTime: '', weekday: '' });
      }
    },
    handleRemoveActivation(index) {
      this.formData.activations.splice(index, 1);
    },
    addPriceConfig() {
      if (this.formData.priceConfigurations.length < 10) {
        this.formData.priceConfigurations.push({ quantity: null, amount: null, totalPrices: false });
      }
    },
    handleRemovePriceConfig(index) {
      this.formData.priceConfigurations.splice(index, 1);
    },
    handleIsAlwaysActive() {
      if (!this.latestForm.activations) {
        this.latestForm.activations = cloneDeep(this.formData.activations);
      }
      if (this.formData.alwaysActive) {
        this.formData.activations = null;
      } else {
        this.formData.activations = this.latestForm.activations || [
          { startDate: null, endDate: null, startTime: '', endTime: '', weekday: '' }
        ];
      }
    },
    formatQty(value) {
      return isNaN(value) ? value : Number(value).toFixed(0);
    },
    formatAmount(value) {
      return isNaN(value) ? value : Number(value).toFixed(2);
    },
    async handleCreateMixMatch() {
      try {
        if (this.canSave) {
          this.formData.licenceNumber = this.getLicenceNumber;

          const activationsFmt = (this.formData.activations || []).map(x => ({
            ...x,
            startDate: new Date(new Date(x.startDate).setUTCHours(5, 59, 59, 999)).toISOString(),
            endDate: new Date(new Date(x.endDate).setUTCHours(23, 59, 59, 999)).toISOString()
          }));
          const priceConfigurationsFmt = (this.formData.priceConfigurations || []).map(x => ({
            ...x,
            quantity: Number(x.quantity),
            amount: Number(x.amount)
          }));

          const licenceNumber = (
            this.getLocationsById(this.$route.query.locationIds) ||
            (await locationService.getLocation(getToken(), this.$route.query.locationIds))
          ).licenceNumber;

          await this.createItemMixMatch({
            ...this.formData,
            activations: activationsFmt,
            priceConfigurations: priceConfigurationsFmt,
            licenceNumber
          });
          this.toast(this.$t('itemsMixMatchList.messages.created', { name: this.formData.name }));
          await this.$router.push({ name: 'mix-match-default' });
        }
      } catch (e) {}
    },
    handleShowCancelModal() {
      if (!this.hasFormChanged) {
        this.handleDiscardChanges();
      }
      this.showModal = true;
    },
    handleDiscardChanges() {
      this.$router.push({ name: 'mix-match-default' });
    },
    canShowDeleteButton(arr) {
      return arr.length > 1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

/* stylelint-disable */

.datepicker /deep/ button {
  background-color: white;
  border-bottom: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
}

h5 {
  background-color: #f5f5f5;
}

/deep/ {
  label,
  .form-text {
    margin-bottom: 0;
    padding-left: 1rem;
  }
}

/deep/ .form-text {
  min-height: 18px;
}

.datepicker .input-group-append {
  margin-left: -35px;
}

/deep/ {
  .custom-control-label::before {
    background: transparent;
    border: 2px solid black;
  }

  .row {
    &.activation {
      &-top {
        .form-group {
          div {
            display: flex;
            flex-direction: row;

            small {
              bottom: 3px;
              position: relative;
            }
          }
        }
      }

      &-row {
        background: #00a9e114;
        min-width: 800px;
        padding: 12px 0;

        .input-group {
          min-width: 150px;
        }

        legend {
          bottom: 42.5px;
          color: #000000;
          font-size: 13px;
          font-weight: 500;
          position: absolute;
        }
      }
    }

    &.price-configuration {
      background: #00a9e114;
      margin-left: 16px;
      margin-right: 16px;
      min-width: 800px;
      padding: 12px 0;

      legend {
        bottom: 42.5px;
        color: #000000;
        font-size: 13px;
        font-weight: 500;
        position: absolute;
      }
    }
  }

  .b-calendar {
    .row {
      padding: 0 24px;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;

    button {
      &:first-child {
        margin-left: 16px;
      }

      &:last-child {
        margin-right: 16px;
      }
    }
  }
}

/deep/ {
  .modal-header {
    border: none;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
  }

  .modal-body {
    padding: 0 1.5rem 1.5rem !important;

    .row {
      margin: 0 !important;
    }
  }
}
</style>
