<script>
import { FormItem } from 'element-ui';

export default {
  name: 'vel-form-item',
  extends: FormItem
};
</script>
<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

.el-form-item {
  margin-bottom: rem-calc(20);
  margin-right: rem-calc(0);

  &__label {
    line-height: rem-calc(38);
    padding-bottom: 6px;
  }
}

.el-form--inline {
  .el-form-item {
    margin-right: rem-calc(25);

    &__label {
      line-height: 1;
      padding-bottom: 6px;
    }
  }
}
</style>
