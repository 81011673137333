<template>
  <div class="reseller-locations-list">
    <div class="reseller-locations-list-header">
      {{ $t('resellerInfo.table.header') }}
      <span class="reseller-locations-list-header-count">{{ selectTotal }}</span>
    </div>
    <vel-table :rows="rows" :columns="columns" :showRowsLength="true" :initialLength="selectTotal">
      <template v-slot:name-column="scope">
        <div class="reseller-locations-list-name">
          <vel-avatar class="reseller-locations-list-name-avatar" :fullname="scope.row.name" />
          <span class="reseller-locations-list-name-avatar-text">{{ scope.row.name }}</span>
        </div>
      </template>
    </vel-table>
  </div>
</template>

<script>
import VelAvatar from '@/components/avatar/VelAvatar';
import VelTable from '@/components/table/VelTable';

export default {
  name: 'reseller-locations-list',
  components: {
    VelAvatar,
    VelTable
  },
  props: {
    resellerLocations: {
      type: Array,
      default: () => []
    },
    selectTotal: {
      type: Number,
      default: 0
    },
    filterValue: {
      type: String,
      default: ''
    },
    filterCallback: {
      type: Function
    }
  },
  computed: {
    columns() {
      return {
        name: {
          title: `${this.$t('resellerInfo.table.columns.location')} (${this.selectTotal})`,
          sortable: true,
          width: '200px'
        }
      };
    },
    rows() {
      return this.resellerLocations;
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

.reseller-locations-list {
  &-header {
    display: flex;
    margin-bottom: rem-calc(25);

    &-count {
      background: $primary-color;
      border-radius: rem-calc(5);
      color: #fff;
      display: flex;
      font-weight: bold;
      justify-content: center;
      margin-left: rem-calc(25);
      width: rem-calc(50);
    }
  }

  &-name {
    display: flex;

    &-avatar {
      &-text {
        margin-left: rem-calc(25);
        margin-top: rem-calc(7.5);
      }
    }
  }
}
</style>
