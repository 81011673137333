<template>
  <vel-modal>
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:default>
      <p>{{ message }}</p>
    </template>
    <template v-slot:footer>
      <vel-button type="critical" class="btn-login" @click="accept">
        {{ $t('msgboxConfirm.button.accept.text') }}
      </vel-button>
      <vel-button type="secondary" class="btn-login" @click="cancel">
        {{ $t('msgboxConfirm.button.cancel.text') }}
      </vel-button>
    </template>
  </vel-modal>
</template>

<script>
import VelButton from '@/components/button/VelButton';
import VelModal from '@/components/modal/VelModal';

export default {
  name: 'msgbox-confirm',
  components: {
    VelModal,
    VelButton
  },
  props: {
    message: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    accept() {
      this.$emit('accept');
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>
<style scoped>
/deep/ .vel-modal {
  max-width: 380px;
}
</style>
