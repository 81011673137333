import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import authService from '@/services/authentication.service';
import navigate from '@/navigation';
import { useDataUsersUserPermissions } from '@/stores/data/users/user-permissions.module';
import { oc } from 'ts-optchain';
import { resetAllStores } from '@/pinia';

const initialUser = JSON.parse(localStorage.getItem('user'));

export const useDataAccount = defineStore('account', {
  state: () =>
    initialUser
      ? {
          status: { loggedIn: true, isRefreshingUser: false, saving: false },
          user: initialUser,
          newUser: null,
          error: null,
          latestCreatedUser: null
        }
      : {
          status: { loggedIn: true, isRefreshingUser: false, saving: false },
          user: null,
          newUser: null,
          error: null,
          latestCreatedUser: null
        },
  getters: {
    fullName(state) {
      return `${oc(state).user.firstName('')} ${oc(state).user.lastName('')}`.trim();
    },
    email(state) {
      return oc(state).user.email('');
    },
    pending(state) {
      return state.status.saving || false;
    },
    account(state) {
      return {
        fullName: this.fullName,
        ...(state.user || {})
      };
    },

    userId(state) {
      return oc(state).user.id(null);
    },
    isRefreshingUser: state => oc(state).status.isRefreshingUser(null)
  },
  actions: {
    login({ email, password }) {
      this.loginRequest({ email });
      const userPermissions = useDataUsersUserPermissions();
      userPermissions.reset();

      authService.login(email, password).then(
        loggedUser => {
          this.loginSuccess(loggedUser);
          navigate.toHome();
        },
        error => {
          this.loginFailure(error);
        }
      );
    },

    logout() {
      this.status = {};
      this.user = null;
      this.error = null;
      const userPermissions = useDataUsersUserPermissions();
      userPermissions.reset();
      authService.logout();

      resetAllStores();

      navigate.toLogin();
    },
    createUser(user) {
      this.createUserRequest(user);

      usersService.createUser(getToken(), user).then(
        newUser => {
          this.createUserSuccess(newUser);
        },
        error => {
          this.createUserFailure(error);
        }
      );
    },
    refreshUser(refreshUserPermissions = true) {
      this.refreshUserRequest();

      authService.refreshToken().then(
        () => {
          this.refreshUserSuccess();
          if (refreshUserPermissions) {
            const userPermissions = useDataUsersUserPermissions();
            userPermissions.getCurrentUserPermissions();
          }
        },
        error => {
          this.refreshUserFailure(error);
        }
      );
    },
    init() {
      this.error = null;
    },
    loginRequest(user) {
      this.status = { ...this.status, loggedIn: false, loggingIn: true };
      this.user = user;
      this.error = null;
    },
    loginSuccess(user) {
      this.status = { ...this.status, loggedIn: true, loggingIn: false };
      this.user = user;
      this.error = null;
    },
    loginFailure(error) {
      this.status = { ...this.status, loggingIn: false, loggedIn: false };
      this.user = null;
      this.error = error;
    },
    createUserRequest(user) {
      this.status = { ...this.status, saving: true };
      this.newUser = user;
      this.latestCreatedUser = null;
      this.error = null;
    },
    createUserSuccess(user) {
      this.status = { ...this.status, saving: false };
      this.newUser = user;
      this.latestCreatedUser = this.newUser;
      this.error = null;
    },
    createUserFailure(error) {
      this.status = { ...this.status, saving: false };
      this.newUser = null;
      this.latestCreatedUser = null;
      this.error = error;
    },
    refreshUserRequest() {
      this.status = { ...this.status, isRefreshingUser: true };
      this.error = null;
    },
    refreshUserSuccess() {
      this.status = { ...this.status, isRefreshingUser: false };
      this.error = null;
    },
    refreshUserFailure(error) {
      this.status = { ...this.status, isRefreshingUser: false };
      this.error = error;
    },
    clearLatestCreatedUser() {
      this.latestCreatedUser = null;
    }
  }
});
