export default {
  props: {
    groupId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      mutableGroupId: this.groupId
    };
  },
  methods: {
    handleGroupChange(groupId) {
      this.mutableGroupId = groupId;
      this.$router.replace({
        name: this.$router.history.current.name,
        query: {
          ...this.$router.history.current.query,
          groupId,
          locationId: null
        }
      });
    }
  }
};
