<template>
  <vel-card class="report__sales">
    <sales-breadcrumb :crumbs="crumbs" @click="handleBreadcrumb" />
    <sales
      :defaultDirection="defaultSalesDirection"
      :defaultSort="defaultSalesSort"
      :hoverable="false"
      :loading="areSalesLoading"
      :sales="sales"
      :hiddenColumns="hiddenColumns"
      :salesOverall="(getIndicatorsData && getIndicatorsData.grossSalesAmount) || 0"
      :showQuantity="showSalesQuantity"
      :exportableMetas="exportableMetas"
      :filterable="false"
      ref="sales"
    />
  </vel-card>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import Sales from '@/private/components/sales/Sales';
import SalesBreadcrumb from '@/private/components/sales/SalesBreadcrumb';
import SelectableCurrencyMixin from '@/mixins/selectable-currency-mixin';
import SelectableDateRangeMixin from '@/mixins/selectable-date-range-mixin';
import SelectableGroupMixin from '@/mixins/selectable-group-mixin';
import SelectableLocationMixin from '@/mixins/selectable-location-mixin';
import VelCard from '@/components/card/VelCard';
import { oc } from 'ts-optchain';
import { slugify } from '@/helpers/string.helpers';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { useUICurrencySelector } from '@/stores/ui/currency-selector.module';
import { useUIDateRangeSelector } from '@/stores/ui/date-range-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataSalesIndicators } from '@/stores/data/sales/indicators.module';
import { useDataUsersUserPermissions } from '@/stores/data/users/user-permissions.module';
import { useDataConfig } from '@/stores/data/config.module';
import { useDataGroups } from '@/stores/data/groups.module';
import { useDataSales } from '@/stores/data/sales.module';

const LEVEL_ALL = 'all';
const LEVEL_BIG_DIVISION = 'bigdivision';
const LEVEL_DIVISION = 'division';
const LEVEL_ITEM = 'item';
const LEVEL_LOCATION = 'location';
const LEVEL_GROUP = 'group';

export default {
  name: 'LocationsSalesData',
  components: {
    VelCard,
    SalesBreadcrumb,
    Sales
  },
  mixins: [
    SelectableGroupMixin,
    SelectableDateRangeMixin,
    SelectableCurrencyMixin,
    DeviceMixin,
    SelectableLocationMixin
  ],
  data() {
    return {
      mutableBigDivision: null,
      mutableDivision: null
    };
  },
  computed: {
    ...mapPiniaState(useUICurrencySelector, ['getCurrencySelectorSelected']),
    ...mapPiniaState(useUILocationSelector, {
      locations: 'locations',
      hasLocationsError: 'hasError',
      locationsLoading: 'isLoading',
      getLocationSelectorSelected: 'getLocationSelectorSelected'
    }),
    ...mapPiniaState(useDataGroups, ['getGroupsRows']),
    ...mapPiniaState(useUIGroupSelector, ['getGroupSelectorSelected']),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById']),
    ...mapPiniaState(useUIDateRangeSelector, ['getDateSelectorSelectedRange']),
    ...mapPiniaState(useDataUsersUserPermissions, ['isUserAdmin']),
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useDataSalesIndicators, ['isIndicatorsLoading', 'getIndicatorsData']),
    ...mapPiniaState(useUIGroupSelector, ['getGroupSelectorSelected']),
    ...mapPiniaState(useDataSales, {
      areSalesLoading: 'areSalesLoading',
      salesLocation: 'location',
      filter: 'filter'
    }),
    ...mapPiniaState(useDataSales, ['getSalesByCurrency']),
    defaultSalesDirection() {
      return this.level === LEVEL_ALL ? 'desc' : 'asc';
    },
    exportableMetas() {
      const [from, to] = this.getDateSelectorSelectedRange;
      return {
        title: this.$t('exports.title.locations'),
        locationTitle: this.getLocationSelectorSelected
          ? this.getLocationsById(this.getLocationSelectorSelected) &&
            (this.getLocationsById(this.getLocationSelectorSelected).reportingDisplayName ||
              this.getLocationsById(this.getLocationSelectorSelected).name)
          : this.getCurrentGroupName() || this.$t('exports.allLocationsTitle'),
        fileName: slugify(this.$t('exports.title.locations')),
        from,
        to,
        i18n: this.$t('export')
      };
    },
    displayConfig() {
      return this.get;
    },
    hiddenColumns() {
      return {
        name: this.displayConfig.column_name,
        customerCount: this.displayConfig.locations_report_column_customer_count,
        quantity: this.displayConfig.locations_report_column_quantity,
        salesPercent: this.displayConfig.locations_report_column_gross_sales_volume_percent,
        salesPercentOverall: this.displayConfig.locations_report_column_gross_sales_volume_percent_overall,
        salesAmount: this.displayConfig.locations_report_column_gross_sales_volume,
        discountsAmount: this.displayConfig.locations_report_column_discount_volume,
        netSales: this.displayConfig.locations_report_column_net_sales_volume
      };
    },
    showSalesQuantity() {
      return this.level !== LEVEL_ALL;
    },
    level() {
      return this.detectLevel();
    },
    crumbsAllLevelLabel() {
      return this.$t('sales.allLocations');
    },
    hasReachedLeaf() {
      return this.level === LEVEL_ITEM;
    },
    crumbs() {
      let secondaryLevel = {};
      const filtered = (this.getGroupsRows || []).filter(group => group.id === this.getGroupSelectorSelected);
      const filteredGroup = filtered && filtered[0];
      if (filteredGroup) {
        secondaryLevel = { name: filteredGroup.name, level: LEVEL_GROUP };
      } else if (oc(this.salesLocation).name()) {
        secondaryLevel = { name: oc(this.salesLocation).name(), level: LEVEL_LOCATION };
      }

      const crumbsData = [
        { name: this.crumbsAllLevelLabel, level: LEVEL_ALL },
        secondaryLevel,
        this.mutableBigDivision,
        this.mutableDivision
      ];
      return crumbsData.filter(Boolean);
    },
    defaultSalesSort() {
      return this.level === LEVEL_ALL ? 'salesAmount' : 'name';
    },
    sales() {
      return this.getSalesByCurrency(this.getCurrencySelectorSelected);
    }
  },
  methods: {
    ...mapPiniaActions(useDataSales, [
      'getLocation',
      'getSalesByBigDivision',
      'getSalesByDivision',
      'getSalesByItem',
      'getSalesByLocation',
      'setFilter'
    ]),
    ...mapPiniaActions(useDataSales, ['reset']),
    filterChangeHandler(value) {
      this.setFilter(value);
    },
    handleBreadcrumb(crumb) {
      switch (crumb.level) {
        case LEVEL_ALL:
          this.handleLocationChange('');
          break;
        case LEVEL_LOCATION:
          this.mutableBigDivision = null;
          this.mutableDivision = null;
          break;
        case LEVEL_BIG_DIVISION:
          this.mutableDivision = null;
          break;
      }
      this.fetchSales();
    },
    handleRowClick(row) {
      const { level } = this;

      switch (level) {
        case LEVEL_ALL:
          this.handleLocationChange(row.id);
          // We don't want to fetch sales at this level, it causes a recurring problem
          return;
        case LEVEL_BIG_DIVISION:
          this.mutableBigDivision = { ...row, level };
          break;
        case LEVEL_DIVISION:
          this.mutableDivision = { ...row, level };
          break;
        default:
          return;
      }

      this.fetchSales();
    },
    detectLevel() {
      if (!this.getLocationSelectorSelected) {
        return LEVEL_ALL;
      } else if (!this.mutableBigDivision && !this.mutableDivision) {
        return LEVEL_BIG_DIVISION;
      } else if (this.mutableBigDivision && !this.mutableDivision) {
        return LEVEL_DIVISION;
      } else if (this.getGroupSelectorSelected) {
        return LEVEL_GROUP;
      }
      return LEVEL_ITEM;
    },
    async fetchSales() {
      const [from, to] = this.getDateSelectorSelectedRange;

      switch (this.detectLevel()) {
        case LEVEL_BIG_DIVISION:
          await this.getSalesByBigDivision({
            locationId: this.getLocationSelectorSelected,
            from,
            to
          });
          break;
        case LEVEL_DIVISION:
          await this.getSalesByDivision({
            bigDivisionId: this.mutableBigDivision.id,
            locationId: this.getLocationSelectorSelected,
            from,
            to
          });
          break;
        case LEVEL_ITEM:
          await this.getSalesByItem({
            bigDivisionId: this.mutableBigDivision.id,
            divisionId: this.mutableDivision.id,
            locationId: this.getLocationSelectorSelected,
            from,
            to
          });
          break;
        default:
          await this.getSalesByLocation({ from, to });
      }
    },
    getCurrentGroupName() {
      const selectedGroup = (this.getGroupsRows || []).filter(group => group.id === this.getGroupSelectorSelected);
      if (!selectedGroup.length) {
        return null;
      }
      return `${this.$t('exports.groupLabel')} ${selectedGroup[0].name}`;
    }
  },
  beforeDestroy() {
    this.reset();
  },
  mounted() {
    if (this.getLocationSelectorSelected) {
      this.getLocation(this.getLocationSelectorSelected);
    }
    this.fetchSales();
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

$indicator-grid-column-gap: rem-calc(20);

.graph {
  display: grid;
  grid-template-columns: 1fr;

  @include breakpoint(medium) {
    grid-gap: $indicator-grid-column-gap;
    grid-template-columns: 1fr;
    margin-bottom: rem-calc(20);
  }
  @include breakpoint(large) {
    grid-template-columns: 1fr 1fr;
  }

  > * {
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}
</style>
