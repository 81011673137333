<template>
  <div class="permission-delete-modal">
    <vel-modal dismissable @dismiss="handleDismiss">
      <template v-slot:header>
        {{ $t('user.permissions.modals.delete.title') }}
      </template>

      <template v-slot:default>
        <div class="modal__confirmation-text">
          <span
            v-if="permission.location"
            v-html="
              $t('user.permissions.modals.delete.locationMessage', {
                permission: permission.permission,
                locationName: permission.location.name
              })
            "
          ></span>

          <span
            v-else-if="permission.group"
            v-html="
              $t('user.permissions.modals.delete.groupMessage', {
                permission: permission.permission,
                groupName: permission.group.name
              })
            "
          ></span>

          <span
            v-else
            v-html="$t('user.permissions.modals.delete.globalMessage', { permission: permission.permission })"
          ></span>
        </div>
      </template>

      <template v-slot:footer>
        <vel-button class="actions__cancel" type="secondary" @click="handleDismiss">
          {{ $t('defaults.confirmations.no') }}
        </vel-button>
        <vel-button class="actions__confirm" type="critical" @click="handleSuccess">
          {{ $t('defaults.confirmations.yes') }}
        </vel-button>
      </template>
    </vel-modal>
  </div>
</template>

<script>
import VelButton from '@/components/button/VelButton';
import VelModal from '@/components/modal/VelModal';

export default {
  name: 'delete-permission-modal',
  components: {
    VelButton,
    VelModal
  },
  props: {
    permission: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleDismiss() {
      this.$emit('dismiss');
    },
    handleSuccess() {
      this.$emit('success', this.permission);
    }
  }
};
</script>
