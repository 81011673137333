import { get, resolveCommonQuery } from '@/helpers/service.helper';
import { getToken } from '@/helpers/token.helper';

/**
 * Get table sections
 * @link https://api.posveloce.com/docs/private#tag/tables/paths/~1tables~1sections/get
 */
export const getTablesSections = (params: {}) => {
  const url = resolveCommonQuery('/tables/sections', params).toString();
  return get(getToken(), url);
};
