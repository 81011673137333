import { downloadPdfFile, downloadXlsxFile, get, resolveCommonQuery } from '@/helpers/service.helper';
import { getCurrentTimeForReport } from '@/helpers/date.helpers';
import { getToken } from '@/helpers/token.helper';
import pick from 'lodash.pick';

/**
 * Get inventory localisations
 * @link https://api.posveloce.com/docs/private#operation/getInvoiceSummary
 */
const getInvoiceSummary = query => {
  const params = pick(query, [
    'from',
    'to',
    'limit',
    'offset',
    'locationId',
    'locationIds',
    'groupId',
    'sort',
    'order',
    'tenderIDs',
    'departmentIDs',
    'workstationIDs',
    'discountIDs',
    'employeeName',
    'invoiceNumber',
    'invoiceNumberRange',
    'training',
    'timeSource',
    'refund',
    'pagination',
    'itemRefunds',
    'invoiceNumber',
    'status'
  ]);
  const url = resolveCommonQuery('/invoices/summary', params).toString();
  return get(getToken(), url);
};

const downloadInvoiceSummaryXlsx = (token, query) => {
  const params = pick(query, [
    'from',
    'to',
    'limit',
    'offset',
    'locationId',
    'locationIds',
    'groupId',
    'sort',
    'order',
    'tenderIDs',
    'departmentIDs',
    'workstationIDs',
    'discountIDs',
    'employeeName',
    'invoiceNumber',
    'invoiceNumberRange',
    'status'
  ]);
  const url = resolveCommonQuery('/invoices/summary', params).toString();
  return downloadXlsxFile(token, url, `Check summary Report_${getCurrentTimeForReport()}.xlsx`);
};

const getInvoices = (query = {}) => {
  const params = pick(query, [
    'detailedTaxes',
    'include',
    'groupIds',
    'locationIds',
    'licenceNumber',
    'from',
    'to',
    'limit',
    'offset',
    'training',
    'timeSource',
    'refund',
    'pagination',
    'itemRefunds',
    'invoiceNumber'
  ]);
  const url = resolveCommonQuery('/invoices', params).toString();
  return get(getToken(), url);
};

const downloadInvoicesPDF = (query = {}) => {
  const params = pick(query, [
    'detailedTaxes',
    'include',
    'groupIds',
    'locationIds',
    'licenceNumber',
    'from',
    'to',
    'limit',
    'offset',
    'training',
    'timeSource',
    'refund',
    'pagination',
    'itemRefunds',
    'invoiceNumber'
  ]);
  const url = resolveCommonQuery('/invoices', params).toString();
  return downloadPdfFile(getToken(), url, `Invoices Report_${getCurrentTimeForReport()}.pdf`);
};

const getInvoiceById = (id = 0, query = {}) => {
  const params = pick(query, ['detailedTaxes', 'include']);
  const url = resolveCommonQuery(
    `/invoices/${id}?detailedTaxes=true&include=discount&include=location&include=sellingEmployee&include=items&include=tags&include=payments&include=workstation&include=carrierEmployee&include=department`,
    params
  ).toString();
  return get(getToken(), url);
};

const downloadInvoiceByIdPDF = (id = 0, query = {}) => {
  const params = pick(query, ['detailedTaxes', 'include', 'lang']);
  const url = resolveCommonQuery(
    `/invoices/${id}?detailedTaxes=true&include=discount&include=location&include=sellingEmployee&include=items&include=tags&include=payments&include=workstation&include=carrierEmployee&include=department`,
    params
  ).toString();
  return downloadPdfFile(getToken(), url, `Check Report_${getCurrentTimeForReport()}.pdf`);
};

const getInvoicesSearch = (query = {}) => {
  const params = pick(query, [
    'groupIds',
    'locationIds',
    'from',
    'to',
    'limit',
    'offset',
    'invoiceNumber',
    'invoiceNumberRange',
    'tenderTypeIDs',
    'departmentIDs',
    'workstationIDs',
    'discountIDs',
    'employeeName',
    'employeeNumber',
    'status'
  ]);
  const url = resolveCommonQuery('/invoices/search?detailedTaxes=true', params).toString();
  return get(getToken(), url);
};

const downloadInvoicesSearchPDF = (query = {}) => {
  const params = pick(query, [
    'groupIds',
    'locationIds',
    'from',
    'to',
    'invoiceNumber',
    'invoiceNumberRange',
    'tenderTypeIDs',
    'departmentIDs',
    'workstationIDs',
    'discountIDs',
    'employeeName',
    'employeeNumber',
    'lang',
    'status'
  ]);
  const url = resolveCommonQuery('/invoices/search', params).toString();
  return downloadPdfFile(getToken(), url, `Invoices Report_${getCurrentTimeForReport()}.pdf`);
};

export default {
  getInvoiceSummary,
  downloadInvoiceByIdPDF,
  downloadInvoicesPDF,
  downloadInvoiceSummaryXlsx,
  getInvoices,
  getInvoiceById,
  getInvoicesSearch,
  downloadInvoicesSearchPDF
};
