<template>
  <pie-line-chart
    :pending="isPending"
    :options="chartOptions"
    :series="series"
    :labels="labels"
    :currency="currencyId"
    :title="$t('sales.departmentChart.title', { limit })"
    :type="get.default_chart_sales_per_department.value"
    class="hourly-sales__chart"
  />
</template>

<script>
import PieLineChart from '@/components/pie-line-chart/PieLineChart';
import { useDataSalesDepartments } from '@/stores/data/sales/departments.module';
import { mapActions as mapPiniaActions, mapState as mapPiniaState, storeToRefs } from 'pinia';
import { useDataConfig } from '@/stores/data/config.module';

export default {
  name: 'department-sales__chart',
  components: {
    PieLineChart
  },
  props: {
    currencyId: {
      type: String,
      default: null
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  setup() {
    const departmentsStores = useDataSalesDepartments();

    const { getSeries, getLabels, getTotal, isPending, departmentSales } = storeToRefs(departmentsStores);
    return {
      getSeries,
      getLabels,
      getTotal,
      isPending,
      departmentSales
    };
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),
    series() {
      return [
        {
          name: this.$t('sales.departmentChart.series.title', { currency: this.currencyId }),
          data: this.getSeries(this.currencyId).slice(0, this.limit)
        }
      ];
    },
    labels() {
      return this.getLabels(this.currencyId)
        .map(l => (l === '' ? this.$t('charts.emptyString') : l))
        .slice(0, this.limit);
    },
    total() {
      return this.getTotal(this.currencyId);
    },
    chartOptions() {
      return {
        xaxis: {
          title: {
            text: this.$t('sales.departmentChart.xaxis')
          }
        },
        yaxis: {
          title: {
            text: this.$t('sales.departmentChart.yaxis')
          }
        }
      };
    }
  },
  methods: {
    ...mapPiniaActions(useDataSalesDepartments, ['getSalesByDepartment'])
  },
  mounted() {
    this.getSalesByDepartment();
  }
};
</script>
