import { get, resolveCommonQuery } from '@/helpers/service.helper';
import { getToken } from '@/helpers/token.helper';

/**
 * Get number of locations for each country
 * @link https://api.posveloce.com/docs/private#operation/getTotalLocationsByCountries
 */
export const getTotalLocationsByCountries = (params: {} = {}) => {
  const url = resolveCommonQuery('/analytics/locations/countries', params).toString();
  return get(getToken(), url);
};

/**
 * Get new monthly gross sales
 * @link https://api.posveloce.com/docs/private#operation/getGrossSalesMonthly
 */
export const getGrossSalesMonthly = (params: {} = {}) => {
  const url = resolveCommonQuery('/analytics/grossSales/monthly', params).toString();
  return get(getToken(), url);
};
