<template>
  <vel-form ref="groupForm" class="group-form" :model="groupForm" :rules="groupFormRules" label-width="150px">
    <vel-form-item prop="name" :label="$t('groups.form.name.placeholder')">
      <vel-input class="name-input" autofocus type="text" v-model="groupForm.name"></vel-input>
    </vel-form-item>

    <vel-form-item class="group-form__kind" prop="kind" :label="$t('groups.form.kind.label')" required>
      <vel-select v-model="groupForm.kind" :placeholder="$t('groups.form.kind.placeholder')">
        <vel-option v-for="option in groupKindOptions" :label="option" :value="option" :key="option"></vel-option>
      </vel-select>
    </vel-form-item>

    <vel-form-item class="group-form__actions">
      <vel-button type="primary" class="actions__submit" @click="handleSubmit()" :loading="isLoading">
        {{ $t('defaults.actions.save') }}
      </vel-button>
      <vel-button type="secondary" class="actions__cancel" @click="handleCancel()" :disabled="isLoading">
        {{ $t('defaults.actions.cancel') }}
      </vel-button>
    </vel-form-item>
  </vel-form>
</template>

<script>
import VelButton from '@/components/button/VelButton';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelInput from '@/components/x-input/VelInput';
import VelOption from '@/components/x-option/VelOption';
import VelSelect from '@/components/x-select/VelSelect';
import groupKind from '@/models/groups/group-kind';
import { mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataGroups } from '@/stores/data/groups.module';

export default {
  name: 'group-form',
  components: {
    VelButton,
    VelForm,
    VelFormItem,
    VelInput,
    VelOption,
    VelSelect
  },
  props: {
    group: {
      type: Object,
      default: () => ({
        name: null,
        kind: null
      })
    },
    groupKindOptions: {
      type: Array,
      default: () => Object.keys(groupKind)
    }
  },
  data() {
    return {
      groupForm: {
        id: this.group.id,
        name: this.group.name,
        kind: this.group.kind
      },
      groupFormRules: {
        name: [
          {
            required: true,
            message: this.$t('groups.form.name.isRequired'),
            trigger: 'change'
          },
          {
            max: 40,
            message: this.$t('groups.form.name.maxCharacters'),
            trigger: 'change'
          }
        ],
        kind: [
          {
            required: true,
            message: this.$t('groups.form.kind.isRequired'),
            trigger: 'change'
          }
        ]
      }
    };
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleSubmit() {
      this.$refs.groupForm.validate(valid => {
        if (valid) {
          this.$emit('submit', this.groupForm);
        }
      });
    }
  },
  computed: {
    ...mapPiniaState(useDataGroups, ['isSavingGroup']),
    isLoading() {
      return this.isSavingGroup;
    }
  }
};
</script>

<style lang="scss" scoped>
.group-form__actions {
  .actions__submit {
    margin-right: 1.25em;
  }
}
</style>
