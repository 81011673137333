<template>
  <vel-table
    :rows="rows"
    :columns="columns"
    :loading="loading"
    :hoverable="true"
    defaultDirection="asc"
    defaultSort="group"
  >
    <template v-slot:empty>
      {{ $t('groups.errors.noData') }}
    </template>

    <template v-slot:label-column="scope">
      <div class="group__name">
        <vel-avatar class="group__name-avatar" :fullname="scope.cell.value" />
        <span class="group__name-text">{{ scope.cell.value }}</span>
      </div>
    </template>

    <template v-slot:actions-column="scope">
      <div class="actions">
        <vel-button
          type="link"
          class="actions__goto"
          :disabled="disableActions"
          icon="external-link"
          @click="handleGotoGroup(groups[scope.rowIndex])"
        />
        <vel-button
          type="link"
          class="actions__delete"
          :disabled="disableActions"
          icon="delete"
          @click="handleDelete(groups[scope.rowIndex])"
        />
      </div>
    </template>
  </vel-table>
</template>

<script>
import VelAvatar from '@/components/avatar/VelAvatar';
import VelButton from '@/components/button/VelButton';
import VelTable from '@/components/table/VelTable';
import compareStringsInsensitive from '@/comparators/compare-strings-insensitive';
import { oc } from 'ts-optchain';

export default {
  name: 'groups-list',
  components: {
    VelAvatar,
    VelButton,
    VelTable
  },
  props: {
    disableActions: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    groupTitle: {
      type: String
    },
    groups: {
      type: Array
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    rows() {
      return this.groups;
    },
    columns() {
      return {
        label: {
          key: 'group',
          title: this.$t('location.groups.table.columns.group'),
          sort: (p1, p2) => compareStringsInsensitive(oc(p1.group).label(), oc(p2.group).label()),
          sortable: true
        },
        actions: {
          key: 'actions',
          title: 'Actions',
          sortable: false,
          visible: this.showActions,
          width: '20%'
        }
      };
    }
  },
  methods: {
    handleGotoGroup(group) {
      this.$emit('goToGroup', group);
    },
    handleDelete(group) {
      this.$emit('delete', group);
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$groups__name-font-weight: $font-medium;

.group {
  @include breakpoint(small) {
    &__name-avatar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    &__name {
      align-items: center;
      display: inline-flex;
    }

    &__name-text {
      font-weight: $groups__name-font-weight;
      @include text-ellipsis;
    }

    &__name-avatar {
      display: inline;
      margin-right: rem-calc(8);
    }
  }
}

.actions {
  .vel-button {
    .vel-button__icon {
      padding: 0.25rem;
    }

    &.actions__goto {
      .vel-button__icon {
        width: rem-calc(27);
      }
    }
  }
}
</style>
