import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import {
  addOne,
  createCollectionState,
  getSelectors,
  removeAll,
  removeOne,
  setAll,
  updateOne
} from '@/helpers/stores.helpers';
import couponsService from '@/services/coupons.service';
import { accentFold } from '@/helpers/string.helpers';

const { selectAll } = getSelectors();

export const useDataCoupons = defineStore('coupons', {
  state: () =>
    createCollectionState({
      filter: '',
      status: {},
      error: {}
    }),
  getters: {
    getCoupons: selectAll,
    getFilter: state => state.filter.toLowerCase(),
    getCouponsRows(state) {
      return this.getFilter
        ? this.getCoupons.filter(coupon => {
            const value = `${coupon.name} ${coupon.code} ${coupon.plu1} ${coupon.plu2} ${coupon.plu1Amount} ${coupon.plu2Amount}`.toLowerCase();
            return accentFold(value).includes(accentFold(this.getFilter));
          })
        : this.getCoupons;
    },
    isLoading: (state: any) => state.status.isLoading,
    isSavingCoupon: (state: any) => state.status.isSavingCoupon,
    getError: state => state.error || {},
    hasGetCouponsError(_) {
      return !!this.getError.getCouponsError;
    },
    hasCreateCouponError(_) {
      return !!this.getError.createCouponError;
    },
    hasUpdateCouponError(_) {
      return !!this.getError.updateCouponError;
    },
    hasDeleteCouponError(_) {
      return !!this.getError.deleteCouponError;
    },
    hasImportCouponsError(_) {
      return !!this.getError.importCouponsError;
    },
    hasBatchDeleteCouponsError(_) {
      return !!this.getError.batchDeleteCouponsError;
    },
    getCouponsError(_) {
      return this.getError.getCouponsError || {};
    },
    createCouponError(_) {
      return this.getError.createCouponError || {};
    },
    updateCouponError(_) {
      return this.getError.updateCouponError || {};
    },
    deleteCouponError(_) {
      return this.getError.deleteCouponError || {};
    },
    importCouponsError(_) {
      return this.getError.importCouponsError || {};
    },
    batchDeleteCouponsError(_) {
      return this.getError.batchDeleteCouponsError || {};
    },
    hasCreateConflictError(_) {
      return this.createCouponError.statusCode === 409;
    },
    latestCreatedCoupon: state => state.status.latestCreatedCoupon || null,
    latestUpdatedCoupon: state => state.status.latestUpdatedCoupon || null,
    latestDeletedCoupon: state => state.status.latestDeletedCoupon || null,
    latestImportedCoupons: state => state.status.latestImportedCoupons || null,
    latestBatchDeletedCoupons: state => state.status.latestBatchDeletedCoupons || null
  },
  actions: {
    getCouponsAction(query) {
      this.getCouponsRequest();

      couponsService.getCoupons(query.groupId, query).then(
        coupons => {
          const couponsList = query.pagination ? coupons.content : coupons;
          this.getCouponsSuccess(couponsList);
        },
        error => {
          this.getCouponsFailure(error);
        }
      );
    },
    createCoupon(coupon) {
      this.createCouponRequest();

      couponsService.createCoupon(getToken(), coupon).then(
        res => {
          this.createCouponSuccess(res);
        },
        error => {
          this.createCouponFailure(error);
        }
      );
    },
    updateCoupon(coupon) {
      this.updateCouponRequest();

      couponsService.updateCoupon(getToken(), coupon).then(
        updatedCoupon => {
          this.updateCouponSuccess(updatedCoupon);
        },
        error => {
          this.updateCouponFailure(error);
        }
      );
    },
    deleteCoupon(coupon) {
      this.deleteCouponRequest();

      couponsService.deleteCoupon(getToken(), coupon).then(
        () => {
          this.deleteCouponSuccess(coupon);
        },
        error => {
          this.deleteCouponFailure(error);
        }
      );
    },
    importCoupons(formData) {
      this.importCouponsRequest();

      couponsService.importCoupons(getToken(), formData).then(
        addedCouponsCount => {
          this.importCouponsSuccess(addedCouponsCount);
        },
        error => {
          this.importCouponsFailure(error);
        }
      );
    },
    batchDeleteCoupons(formData) {
      this.batchDeleteCouponsRequest();

      couponsService.batchDeleteCoupons(getToken(), formData).then(
        deletedCouponsCount => {
          this.batchDeleteCouponsSuccess(deletedCouponsCount);
        },
        error => {
          this.batchDeleteCouponsFailure(error);
        }
      );
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    getCouponsRequest() {
      this.status = { ...this.status, isLoading: true };
      this.data = removeAll(this.data);
      this.error = { ...this.error, getCouponsError: null };
      this.filter = '';
    },
    getCouponsSuccess(coupons) {
      this.status = { ...this.status, isLoading: false };
      this.data = setAll(coupons, this.data, 'code');
      this.error = { ...this.error, getCouponsError: null };
      this.filter = '';
    },
    getCouponsFailure(error) {
      this.status = { ...this.status, isLoading: false };
      this.data = removeAll(this.data);
      this.error = { ...this.error, getCouponsError: error };
    },
    createCouponRequest() {
      this.status = { ...this.status, isSavingCoupon: true, latestCreatedCoupon: null };
      this.error = { ...this.error, createCouponError: null };
    },
    createCouponSuccess(coupon) {
      this.data = addOne(coupon, this.data, 'code');
      this.status = { ...this.status, isSavingCoupon: false, latestCreatedCoupon: coupon };
      this.error = { ...this.error, createCouponError: null };
    },
    createCouponFailure(error) {
      this.status = { ...this.status, isSavingCoupon: false, latestCreatedCoupon: null };
      this.error = { ...this.error, createCouponError: error };
    },
    updateCouponRequest() {
      this.status = { ...this.status, isSavingCoupon: true, latestUpdatedCoupon: null };
      this.error = { ...this.error, updateCouponError: null };
    },
    updateCouponSuccess(coupon) {
      this.data = updateOne(coupon, this.data, 'code');
      this.status = { ...this.status, isSavingCoupon: false, latestUpdatedCoupon: coupon };
      this.error = { ...this.error, updateCouponError: null };
    },
    updateCouponFailure(error) {
      this.status = { ...this.status, isSavingCoupon: false, latestUpdatedCoupon: null };
      this.error = { ...this.error, updateCouponError: error };
    },
    deleteCouponRequest() {
      this.status = { ...this.status, isSavingCoupon: true, latestDeletedCoupon: null };
      this.error = { ...this.error, deleteCouponError: null };
    },
    deleteCouponSuccess(coupon) {
      this.data = removeOne(coupon.code, this.data);
      this.status = { ...this.status, isSavingCoupon: false, latestDeletedCoupon: coupon };
      this.error = { ...this.error, deleteCouponError: null };
    },
    deleteCouponFailure(error) {
      this.status = { ...this.status, isSavingCoupon: false, latestDeletedCoupon: null };
      this.error = { ...this.error, deleteCouponError: error };
    },
    importCouponsRequest() {
      this.status = { ...this.status, isSavingCoupon: true, latestImportedCoupons: null };
      this.error = { ...this.error, importCouponsError: null };
    },
    importCouponsSuccess(couponsCount) {
      this.status = { ...this.status, isSavingCoupon: false, latestImportedCoupons: couponsCount };
      this.error = { ...this.error, importCouponsError: null };
    },
    importCouponsFailure(error) {
      this.status = { ...this.status, isSavingCoupon: false, latestImportedCoupons: null };
      this.error = { ...this.error, importCouponsError: error };
    },
    batchDeleteCouponsRequest() {
      this.status = { ...this.status, isSavingCoupon: true, latestBatchDeletedCoupons: null };
      this.error = { ...this.error, batchDeleteCouponsError: null };
    },
    batchDeleteCouponsSuccess(couponsCount) {
      this.status = { ...this.status, isSavingCoupon: false, latestBatchDeletedCoupons: couponsCount };
      this.error = { ...this.error, batchDeleteCouponsError: null };
    },
    batchDeleteCouponsFailure(error) {
      this.status = { ...this.status, isSavingCoupon: false, latestBatchDeletedCoupons: null };
      this.error = { ...this.error, batchDeleteCouponsError: error };
    },
    setFilterValue(value) {
      this.filter = value;
    },
    clearLatestCreatedCoupon() {
      this.status = { ...this.status, latestCreatedCoupon: null };
    },
    clearLatestUpdatedCoupon() {
      this.status = { ...this.status, latestUpdatedCoupon: null };
    },
    clearLatestDeletedCoupon() {
      this.status = { ...this.status, latestDeletedCoupon: null };
    },
    clearLatestImportedCoupons() {
      this.status = { ...this.status, latestImportedCoupons: null };
    },
    clearLatestBatchDeletedCoupons() {
      this.status = { ...this.status, latestBatchDeletedCoupons: null };
    },
    clearGetCouponsError() {
      this.error.getCouponsError = null;
    },
    clearCreateCouponError() {
      this.error.createCouponError = null;
    },
    clearUpdateCouponError() {
      this.error.updateCouponError = null;
    },
    clearDeleteCouponError() {
      this.error.deleteCouponError = null;
    },
    clearImportCouponsError() {
      this.error.importCouponsError = false;
    },
    clearBatchDeletedCouponsError() {
      this.error.batchDeleteCouponsError = null;
    },
    setGetCouponsError(error) {
      this.error = { ...this.error, getCouponsError: error };
    }
  }
});
