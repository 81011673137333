<template>
  <vel-form ref="couponForm" class="coupon-form" :model="couponForm" :rules="couponFormRules" label-width="35%">
    <vel-form-item prop="code" :label="$t('coupons.form.code.placeholder')">
      <vel-input class="code-input" autofocus type="text" :disabled="isEditMode" v-model="couponForm.code"></vel-input>
    </vel-form-item>

    <vel-form-item prop="name" :label="$t('coupons.form.name.placeholder')">
      <vel-input class="name-input" type="text" v-model="couponForm.name"></vel-input>
    </vel-form-item>

    <vel-form-item
      class="group-input"
      v-if="!isGroupSelectorHidden"
      prop="group"
      :label="$t('coupons.form.group.placeholder')"
    >
      <vel-select v-model="couponForm.groupId" :placeholder="$t('coupons.form.group.placeholder')">
        <vel-option v-for="group in groups" :label="group.label" :value="group.value" :key="group.value"></vel-option>
      </vel-select>
    </vel-form-item>

    <vel-form-item prop="expires" class="expires-input" :label="$t('coupons.form.expires.placeholder')">
      <vel-datepicker
        align="center"
        type="date"
        v-model="couponForm.expires"
        :clearable="false"
        :format="getDateFormat()"
      />
    </vel-form-item>

    <vel-form-item class="group-input" prop="couponType" :label="$t('coupons.form.type.label')">
      <vel-select v-model="couponForm.couponType" :placeholder="$t('coupons.form.type.placeholder')">
        <vel-option
          v-for="i in 2"
          :label="$t(`coupons.form.type.values[${i - 1}]`)"
          :value="i - 1"
          :key="i"
        ></vel-option>
      </vel-select>
    </vel-form-item>

    <vel-form-item prop="isActive" :label="$t('coupons.form.isActive.placeholder')">
      <vel-switch class="isActive-input" v-model="couponForm.isActive"></vel-switch>
    </vel-form-item>

    <vel-form-item prop="plu1" :label="$t('coupons.form.plu1.placeholder')">
      <vel-input class="plu1-input" type="text" v-model="couponForm.plu1"></vel-input>
    </vel-form-item>

    <vel-form-item prop="plu1Amount" :label="$t('coupons.form.plu1Amount.placeholder')">
      <vel-input class="plu1Amount-input" v-model="couponForm.plu1Amount"></vel-input>
    </vel-form-item>

    <vel-form-item prop="plu2" :label="$t('coupons.form.plu2.placeholder')">
      <vel-input class="plu2-input" type="text" v-model="couponForm.plu2"></vel-input>
    </vel-form-item>

    <vel-form-item prop="plu2Amount" :label="$t('coupons.form.plu2Amount.placeholder')">
      <vel-input class="plu2Amount-input" v-model="couponForm.plu2Amount"></vel-input>
    </vel-form-item>

    <vel-form-item
      prop="redeemed"
      :label="$t('coupons.form.redeemed.label')"
      class="coupon-form__redeemed"
      v-if="isEditMode && coupon.couponType == 1"
    >
      <vel-switch class="redeemed-switch" v-model="isRedeemCanceled" :disabled="coupon.redeemed === null"></vel-switch>
      <vel-input
        class="redeemed-input"
        :value="formatDateTime(coupon.redeemed)"
        readonly="readonly"
        v-if="isRedeemCanceled"
      ></vel-input>
    </vel-form-item>

    <div v-if="coupon.redeemCanceled" class="coupon-form__additional-message">
      {{ redeemCanceledByMessage }}
    </div>
    <div v-if="coupon.redeemFromLocation" class="coupon-form__additional-message">
      {{ $t('coupons.form.redeemedFromLocation', { location: coupon.redeemFromLocation.name }) }}
    </div>

    <vel-form-item class="coupon-form__actions">
      <vel-button type="primary" class="actions__submit" @click="handleSubmit()" :loading="isLoading">
        {{ $t('defaults.actions.save') }}
      </vel-button>
      <vel-button type="secondary" class="actions__cancel" @click="handleCancel()" :disabled="isLoading">
        {{ $t('defaults.actions.cancel') }}
      </vel-button>
    </vel-form-item>
  </vel-form>
</template>

<script>
import { DateTime } from 'luxon';
import SelectableGroupMixin from '@/mixins/selectable-group-mixin';
import VelButton from '@/components/button/VelButton';
import VelDatepicker from '@/components/datepicker/VelDatepicker';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelInput from '@/components/x-input/VelInput';
import VelOption from '@/components/x-option/VelOption';
import VelSelect from '@/components/x-select/VelSelect';
import VelSwitch from '@/components/switch/VelSwitch';
import { getDateFormatFromUserConfig } from '@/helpers/date.helpers';
import { mapState as mapPiniaState } from 'pinia';
import { useDataConfig } from '@/stores/data/config.module';
import { useDataCoupons } from '@/stores/data/coupons.module';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';

export default {
  name: 'coupon-form',
  mixins: [SelectableGroupMixin],
  components: {
    VelButton,
    VelDatepicker,
    VelForm,
    VelFormItem,
    VelInput,
    VelOption,
    VelSelect,
    VelSwitch
  },
  props: {
    coupon: {
      type: Object,
      default: () => ({
        code: null,
        name: null,
        isActive: false,
        expires: null,
        plu1: null,
        plu2: null,
        plu1Amount: null,
        plu2Amount: null,
        groupId: null,
        couponType: null,
        redeemed: null,
        redeemCanceledBy: null,
        redeemCanceled: null
      })
    },
    currentGroupId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dateFormat: 'yyyy-MM-dd',
      couponForm: {
        code: this.coupon.code,
        name: this.coupon.name,
        groupId: this.coupon.group || this.currentGroupId,
        isActive: this.coupon.isActive,
        expires: this.coupon.expires,
        plu1: this.coupon.plu1,
        plu2: this.coupon.plu2,
        plu1Amount: this.coupon.plu1Amount,
        plu2Amount: this.coupon.plu2Amount,
        couponType: this.coupon.couponType
      },
      cancelRedeem: false
    };
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleSubmit() {
      this.$refs.couponForm.validate(valid => {
        if (valid) {
          let safeForm = this.sanitizedForm();

          if (this.cancelRedeem) {
            safeForm = { ...safeForm, cancelRedeem: true };
          }

          this.$emit('submit', safeForm);
        }
      });
    },
    sanitizedForm() {
      const toFloatOrNull = f => (isNaN(parseFloat(f)) ? null : parseFloat(f));
      return {
        ...this.couponForm,
        plu1: !this.couponForm.plu1 ? null : this.couponForm.plu1,
        plu1Amount: toFloatOrNull(this.couponForm.plu1Amount),
        plu2: !this.couponForm.plu2 ? null : this.couponForm.plu2,
        plu2Amount: toFloatOrNull(this.couponForm.plu2Amount)
      };
    },
    formatDateTime(value) {
      if (!value) {
        return '--';
      }

      const use24h = this.getConfig.enable_military_time_format.value;
      const date = DateTime.fromISO(value);

      if (use24h) {
        return `${date.toFormat(this.getDateFormat())} - ${date.toFormat('HH:mm')}`;
      }
      return `${date.toFormat(this.getDateFormat())} - ${date.toFormat('hh:mm a')}`;
    },
    getDateFormat() {
      return getDateFormatFromUserConfig();
    }
  },
  computed: {
    ...mapPiniaState(useDataConfig, { getConfig: 'get' }),
    ...mapPiniaState(useDataCoupons, ['isSavingCoupon']),
    ...mapPiniaState(useUIGroupSelector, {
      groups: 'groups',
      groupsLoading: 'isLoading'
    }),
    redeemCanceledByMessage() {
      if (!this.coupon.redeemCanceled) {
        return '';
      }

      const canceledDate = DateTime.fromISO(this.coupon.redeemCanceled).toFormat(getDateFormatFromUserConfig());
      const canceledBy = this.coupon.redeemCanceledBy
        ? `${this.coupon.redeemCanceledBy.firstName} ${this.coupon.redeemCanceledBy.lastName}`
        : '--';

      return this.$t('coupons.form.redeemedCanceledBy', { date: canceledDate, user: canceledBy });
    },
    isRedeemCanceled: {
      get() {
        return this.coupon.redeemed !== null && !this.cancelRedeem;
      },
      set(value) {
        if (!value) {
          this.couponForm.redeemed = null;
        }

        this.cancelRedeem = !value;
      }
    },
    isLoading() {
      return this.isSavingCoupon;
    },
    isEditMode() {
      return !!this.coupon.code;
    },
    isGroupSelectorHidden() {
      return this.isEditMode || (this.groups && this.groups.length === 1);
    },
    couponFormRules() {
      return {
        name: [
          {
            required: true,
            message: this.$t('coupons.form.name.isRequired'),
            trigger: 'change'
          },
          {
            max: 64,
            message: this.$t('coupons.form.name.maxCharacters', { characters: 64 }),
            trigger: 'change'
          }
        ],
        code: [
          {
            required: true,
            message: this.$t('coupons.form.code.isRequired'),
            trigger: 'change'
          },
          {
            max: 12,
            message: this.$t('coupons.form.code.maxCharacters', { characters: 12 }),
            trigger: 'change'
          }
        ],
        plu1: [
          {
            required: true,
            message: this.$t('coupons.form.plu1.isRequired'),
            trigger: 'change'
          },
          {
            max: 20,
            message: this.$t('coupons.form.plu1.maxCharacters', { characters: 20 }),
            trigger: 'change'
          }
        ],
        plu2: [
          {
            required: !!this.couponForm.plu2Amount,
            message: this.$t('coupons.form.plu2.requiredDueToAmountFilled'),
            trigger: 'change'
          },
          {
            max: 20,
            message: this.$t('coupons.form.plu2.maxCharacters', { characters: 20 }),
            trigger: 'change'
          }
        ],
        groupId: [
          {
            required: true,
            message: this.$t('coupons.form.group.isRequired'),
            trigger: 'change'
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.coupon-form {
  .group-input {
    /deep/ .el-select {
      width: 100%;
    }
  }

  .expires-input {
    /deep/ .el-date-editor {
      border: none;
      width: 100%;
    }
  }
}

.coupon-form {
  &__actions {
    .actions__submit {
      margin-right: 1.25em;
    }
  }

  /deep/ &__redeemed {
    & > .el-form-item__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 2.375rem;

      & > .redeemed-input,
      & > .redeemed-switch {
        width: auto;
      }

      & > .redeemed-input {
        flex-grow: 1;
        margin-left: 5px;
      }
    }
  }

  &__additional-message {
    text-align: center;
    margin-bottom: 0.5rem;
  }
}
</style>
