<template>
  <vel-form ref="groupForm" class="group-form" :model="groupForm" :rules="groupFormRules" label-width="150px">
    <vel-form-item class="group-form__group" prop="groupId" :label="$t('location.groups.form.group.label')" required>
      <vel-group-selector
        :groupId="groupForm.groupId"
        :groupsOptions="groupsOptions"
        :multiple="allowMultipleGroups"
        autofocus
        filterable
        @change="handleGroupChange"
      />
    </vel-form-item>

    <vel-form-item class="group-form__actions">
      <vel-button type="primary" class="actions__submit" :loading="isSubmitDisabled" @click="handleSubmit()">
        {{ $t('defaults.actions.save') }}
      </vel-button>
      <vel-button type="secondary" class="actions__cancel" @click="handleCancel()">
        {{ $t('defaults.actions.cancel') }}
      </vel-button>
    </vel-form-item>
  </vel-form>
</template>

<script>
import VelButton from '@/components/button/VelButton';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelGroupSelector from '@/components/group-selector/VelGroupSelector';

export default {
  name: 'group-form',
  components: {
    VelButton,
    VelForm,
    VelFormItem,
    VelGroupSelector
  },
  props: {
    allowMultipleGroups: {
      type: Boolean,
      default: false
    },
    groupsOptions: {
      type: Array,
      default: () => []
    },
    isSavingGroups: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      groupForm: {
        groupId: null
      }
    };
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleSubmit() {
      this.$refs.groupForm.validate(valid => {
        if (valid) {
          this.$emit('submit', this.groupForm.groupId);
        }
      });
    },
    handleGroupChange(group) {
      this.groupForm.groupId = group;
    }
  },
  computed: {
    groupFormRules() {
      return {
        groupId: [
          {
            required: true,
            message: this.$t('location.groups.form.group.isRequired')
          }
        ]
      };
    },
    isSubmitDisabled() {
      return this.isSavingGroups;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.group-form__actions {
  .actions__submit {
    margin-right: 1.25em;
  }
}
</style>
