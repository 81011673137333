<template>
  <vel-backoffice-page
    class="backoffice-page bootstrap"
    :enableToolbar="false"
    :enable-date-range="false"
    :enable-date-picker="false"
    :enable-groups="false"
    :show-date-compare="false"
    :enable-all-locations="false"
    :scroll="false"
  >
    <template v-slot:header>
      <b-container fluid>
        <b-row class="d-flex flex-column flex-md-row pt-3">
          <b-col class="d-flex align-items-center">
            <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row class="mt-4 mb-6">
          <b-col cols="12" md="6" class="d-flex flex-row justify-content-between">
            <h4>{{ $t('taxesList.title') }}</h4>
            <b-button variant="primary" class="ml-4 d-flex align-items-center d-md-none">
              <b-icon icon="plus" aria-hidden="true" scale="1.4"></b-icon>
              <span class="d-none d-md-block">{{ $t('taxesList.add-new') }}</span>
            </b-button>
          </b-col>
          <b-col cols="12" md="6" class="d-flex mt-4 mt-md-0 flex-row justify-content-md-end flex-md-row">
            <b-input-group style="max-width: 250px;" class="align-content-end align-self-end">
              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="tableFilter"
                debounce="1"
                type="text"
                :placeholder="$t('taxesList.search')"
              ></b-form-input>
            </b-input-group>
            <b-button variant="primary" class="ml-4 d-md-flex align-items-center d-none" :to="{ name: 'tax-create' }">
              <b-icon icon="plus" aria-hidden="true" scale="1.4"></b-icon>
              {{ $t('taxesList.add-new') }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <b-container fluid class="h-100">
      <b-table
        striped
        bordered
        hover
        show-empty
        fixed
        primary-key="id"
        :empty-text="$t('defaults.empty')"
        :empty-filtered-text="$t('defaults.empty')"
        :busy="isTaxLoading"
        :fields="fields"
        head-variant="info"
        :items="getTaxesRows"
        responsive="sm"
        ref="table"
        sort-by="name"
        sticky-header
      >
        <template #empty="scope">
          <div class="p-2">{{ scope.emptyText }}</div>
        </template>
        <template #emptyfiltered="scope">
          <div class="p-2">{{ scope.emptyFilteredText }}</div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner variant="primary" class="align-middle p-2 m-4"></b-spinner>
          </div>
        </template>
        <template #cell(name)="scope">
          <div class="w-100 justify-content-between d-flex align-items-center">
            {{ scope.item.name }}
          </div>
        </template>
        <template #cell(taxCategory)="scope">
          <div class="w-100 justify-content-between d-flex align-items-center">
            {{ scope.item.taxCategory ? scope.item.taxCategory.name : '' }}
          </div>
        </template>
        <template #cell(edit)="scope">
          <b-button variant="link" size="sm" :to="{ name: 'tax-edit', params: { id: scope.item.id } }">
            <vel-icon size="small" class="row-action text-primary" name="xd-edit" />
            {{ $t('taxesList.table.edit') }}
          </b-button>
        </template>
        <template #cell(more)="scope">
          <tax-more :id="scope.item.id" />
        </template>
      </b-table>
    </b-container>
    <vel-alert style="font-size: 1.5rem;" class="alert" @dismiss="resetState" level="success" v-if="createSucceeded">
      {{ $t('taxForm.succeed.create') }}
    </vel-alert>
    <vel-alert style="font-size: 1.5rem;" class="alert" @dismiss="resetState" level="success" v-else-if="editSucceeded">
      {{ $t('taxForm.succeed.edit') }}
    </vel-alert>
  </vel-backoffice-page>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import TaxMore from './components/TaxMore';
import VelAlert from '@/components/alert/VelAlert';
import VelBackofficePage from '@/components/page/VelBackofficePage';
import VelIcon from '@/components/icon/VelIcon';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataTaxes } from '@/stores/data/taxes.module';

export default {
  name: 'TaxesList',
  mixins: [DeviceMixin],
  components: {
    TaxMore,
    VelBackofficePage,
    VelIcon,
    VelAlert
  },
  computed: {
    ...mapPiniaState(useDataTaxes, ['getTaxesRows', 'isTaxLoading', 'editSucceeded', 'createSucceeded']),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById']),
    ...mapPiniaState(useUILocationSelector, ['getLocationSelectorSelected']),
    ...mapPiniaState(useDataTaxes, ['filter']),
    breadcrumbs() {
      return [
        {
          text: this.$t('taxesList.breadcrumbs.setup'),
          to: { name: 'setup-menu', query: { previous: 'taxes', ...this.$router.currentRoute.query } }
        },
        {
          text: this.$t('taxesList.breadcrumbs.taxes')
        }
      ];
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('taxesList.table.fields.name'),
          class: 'name',
          sortable: true
        },
        {
          key: 'taxCategory',
          label: this.$t('taxesList.table.fields.taxCategory'),
          class: 'taxCategory-name',
          sortable: true
        },
        {
          key: 'edit',
          class: 'edit'
        },
        {
          key: 'more',
          class: 'more text-center'
        }
      ];
    },
    tableFilter: {
      get() {
        return this.filter;
      },
      set(val) {
        this.setFilter(val);
      }
    }
  },
  methods: {
    ...mapPiniaActions(useDataTaxes, ['fetchTaxes', 'setFilter', 'resetState'])
  },
  async mounted() {
    await this.fetchTaxes();
  },
  destroyed() {
    this.setFilter('');
    this.resetState();
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.bootstrap .container-fluid {
  padding-left: 24px;
  padding-right: 24px;
}

/* stylelint-disable */
/deep/ {
  .input-group > .form-control {
    border-left-color: #fff;
    padding-left: 0;
  }
  .input-group-text {
    background-color: #fff !important;
    border-right: 1px solid #fff !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  th {
    background-color: #eff9fd;
  }
  .page,
  .page .vel-page__content {
    overflow: hidden;
  }

  .content,
  .vel-card__body,
  .b-table-sticky-header {
    height: 100%;
    max-height: 100%;
    padding-top: 0;
  }

  .btn-group {
    button {
      text-transform: uppercase;
      width: 90px;
    }
  }

  .thead-info {
    background-color: #eff9fd;
    font-weight: bold;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f6fbfe !important;
  }

  .table-hover tbody tr:hover {
    background-color: transparent !important;
  }

  .table-hover tbody tr:nth-of-type(odd):hover {
    background-color: #f6fbfe !important;
  }

  .name {
    width: 40%;
    min-width: 300px;
  }

  .taxCategory-name {
    width: 300px;
    @include breakpoint(medium) {
      width: auto;
    }
  }

  .edit {
    width: 108px;

    .btn-sm {
      padding: 0;
    }

    div {
      display: none;
    }
  }

  .more {
    width: 50px;

    .btn-sm {
      padding: 0;
    }

    div {
      display: none;
    }
  }

  .row-action {
    color: #444444;
  }

  .breadcrumbs {
    padding-top: 16px;
  }

  /deep/ .breadcrumb-item {
    font-size: 0.9rem;
  }

  /deep/ .input-group-sm > .form-control:not(textarea) {
    height: calc(1.5em + 0.5rem + 7px);
  }

  /deep/ table td {
    border-color: #2a31420d !important;
    border-top: none !important;
    padding: 8px 16px !important;
  }

  /deep/ table th {
    padding: 8px 16px !important;
  }

  /deep/ .vel-icon_small {
    height: 1.15rem;
    width: 1.15rem;
  }

  .breadcrumb {
    height: 38px;
    align-items: center;
  }

  .vel-alert {
    align-self: center;
    bottom: 0;
    max-width: fit-content;
    position: absolute;
    z-index: 1;
  }
}
</style>
