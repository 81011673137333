<template>
  <div>
    <b-alert :show="error" variant="danger" class="mb-4">
      {{ $t('employeeCreate.updateError') }}
    </b-alert>
    <h5 class="pt-4 px-4 pb-2">{{ $t('employeeCreate.jobRole.posLayout') }}</h5>

    <b-container fluid class="content p-4">
      <div class="d-flex flex-column flex-md-row justify-content-between">
        <b-form-group
          :label="$t('employeeCreate.jobRole.screenLayout.title')"
          label-for="name"
          class="mr-5 w-100 mb-2 mb-md-0"
        >
          <b-button-group>
            <b-button
              @click="employee.isRightHanded = false"
              :variant="!this.employee.isRightHanded ? 'primary' : 'white'"
              :disabled="loading"
              class="border-input"
            >
              {{ $t('employeeCreate.jobRole.screenLayout.left') }}
            </b-button>
            <b-button
              @click="employee.isRightHanded = true"
              :variant="this.employee.isRightHanded ? 'primary' : 'white'"
              :disabled="loading"
              class="border-input"
            >
              {{ $t('employeeCreate.jobRole.screenLayout.right') }}
            </b-button>
          </b-button-group>
        </b-form-group>
      </div>
    </b-container>

    <h5 class="pt-4 px-4 pb-2">{{ $t('employeeCreate.jobRole.payroll') }}</h5>
    <b-container fluid class="content p-4">
      <div class="d-flex flex-column flex-md-row justify-content-between">
        <b-form-group
          :label="$t('employeeCreate.jobRole.payrollCode')"
          label-for="payrollCode"
          class="mr-5 w-100 mb-2 mb-md-0"
        >
          <b-form-input
            id="payrollCode"
            v-model.number="employee.payrollCode"
            maxlength="8"
            type="number"
            number
            aria-describedby="input-live-help input-live-feedback"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('employeeCreate.jobRole.deduction')" class="mr-5 w-100 mb-2 mb-md-0">
          <b-input-group prepend="$">
            <b-form-input
              id="deduction"
              v-model.number="employee.deduction"
              no-wheel
              lazy-formatter
              @update="update"
              :formatter="formatAmount"
              step="0.01"
              type="number"
              aria-describedby="input-live-help input-live-feedback"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group class="mr-5 w-100 mb-2 mb-md-0">
          <label for="mealPlan" class="d-flex align-items-center">
            {{ $t('employeeCreate.mealPlan.title') }}

            <span class="material-icons mr-1 pointer text-primary medium ml-2" v-b-toggle.mealPlan-sidebar>
              create
            </span>
          </label>
          <b-form-select
            :options="[...mealPlans, { id: null, name: $t('employeeCreate.emptyValue') }]"
            id="test"
            value-field="id"
            name="mealPlan"
            text-field="name"
            aria-describedby="input-live-help input-live-feedback"
            class="form-text"
            v-model="employee.mealPlanId"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          :label="$t('employeeCreate.jobRole.fringe')"
          label-for="payrollCode"
          class="mr-5 w-100 mb-2 mb-md-0"
        >
          <b-input-group prepend="$">
            <b-form-input
              id="name"
              v-model="employee.fringeBenefit"
              no-wheel
              lazy-formatter
              @update="update"
              :formatter="formatAmount"
              step="0.01"
              type="number"
              aria-describedby="input-live-help input-live-feedback"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
    </b-container>
    <h5 class="pt-4 px-4 pb-2">{{ $t('employeeCreate.jobRole.roleConfig') }}</h5>
    <b-container fluid class="content p-4">
      <div class="mb-md-2 px-3">
        <employee-delete-modal
          :title="$t('employeeCreate.jobRole.deleteModal.title')"
          :body="$t('employeeCreate.jobRole.deleteModal.body')"
          :show="showDelete !== null"
          @onDelete="onDelete(showDelete)"
          @onCancel="showDelete = null"
        ></employee-delete-modal>
        <b-row>
          <b-col cols="12" md="2" class="border mb-4">
            <b-row class="border-bottom head rgh">
              <b-col class="border-right align-center d-flex align-items-center py-3">
                Description
              </b-col>
            </b-row>
            <b-row class="rgh">
              <b-col class="py-2 d-flex align-items-center border-right">
                {{ $t('employeeCreate.jobRole.title') }}
                <span class="material-icons mr-1 pointer text-primary medium ml-2" v-b-toggle.accessTypes-sidebar>
                  create
                </span>
              </b-col>
            </b-row>
            <h6 class="mt-2 mb-0">{{ $t('employeeCreate.jobRole.payInfo') }}</h6>
            <b-row class="rgh">
              <b-col class="py-2 d-flex align-items-center border-right">
                {{ $t('employeeCreate.timeAttendance.title') }}
                <span class="material-icons mr-1 pointer text-primary medium ml-2" v-b-toggle.timeAttendance-sidebar>
                  create
                </span>
              </b-col>
            </b-row>
            <b-row class="rgh">
              <b-col class="py-2 d-flex align-items-center border-right">
                {{ $t('employeeCreate.jobRole.regularPay') }}
              </b-col>
            </b-row>
            <b-row class="rgh">
              <b-col class="py-2 d-flex align-items-center border-right">
                {{ $t('employeeCreate.jobRole.overtimePay') }}
              </b-col>
            </b-row>
            <b-row class="rgh">
              <b-col class="py-2 d-flex align-items-center border-right">
                {{ $t('employeeCreate.tipDistribution.title') }}
                <span class="material-icons mr-1 pointer text-primary medium ml-2" v-b-toggle.tipDistribution-sidebar>
                  create
                </span>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            md="2"
            :class="{ deleted: userJobRole.delete === true, border: true, 'mb-4': true }"
            v-for="(userJobRole, index) in (employee.jobRoles || [])"
            :key="index"
          >
            <template v-if="userJobRole.delete !== true">
              <b-row class="border-bottom head rgh">
                <b-col class="text-center border-right justify-content-center align-center d-flex align-items-center">
                  <b-button
                    v-if="(employee.jobRoles || []).filter(jr => jr.delete !== true).length > 1"
                    variant="link"
                    class="text-danger d-flex align-items-center text-decoration-none font-weight-bold"
                    @click="showDelete = index"
                  >
                    <span class="material-icons-outlined mr-1">delete</span>
                    {{ $t('employeeCreate.jobRole.delete') }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="rgh">
                <b-col class="text-left d-flex flex-column justify-content-end">
                  <b-form-select
                    :options="[...accessTypes, { id: null, name: $t('employeeCreate.emptyValue') }]"
                    id="test"
                    value-field="id"
                    text-field="name"
                    aria-describedby="input-live-help input-live-feedback"
                    class="form-text"
                    :class="{ required: !employee.jobRoles[index].functionId }"
                    v-model="employee.jobRoles[index].functionId"
                  ></b-form-select>
                  <b-form-text id="input-live-help">{{ $t('employeeCreate.required') }}</b-form-text>
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{ $t('employeeCreate.required') }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <h5 class="mt-2 mb-0" style="visibility: hidden;">{{ $t('employeeCreate.jobRole.payInfo') }}</h5>
              <b-row class="rgh">
                <b-col class="text-left d-flex flex-column justify-content-center">
                  <b-form-select
                    :options="[...timeAttendances, { id: null, name: $t('employeeCreate.emptyValue') }]"
                    id="test"
                    value-field="id"
                    text-field="name"
                    aria-describedby="input-live-help input-live-feedback"
                    v-model="employee.jobRoles[index].timeAttendanceDepartmentId"
                    class="form-text"
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row class="rgh">
                <b-col class="text-left d-flex flex-column justify-content-center">
                  <b-input-group prepend="$">
                    <b-form-input
                      id="name"
                      v-model.number="employee.jobRoles[index].regularPay"
                      step="0.01"
                      type="number"
                      lazy-formatter
                      no-wheel
                      :formatter="formatAmount"
                      aria-describedby="input-live-help input-live-feedback"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row class="rgh">
                <b-col class="text-left d-flex flex-column justify-content-center">
                  <b-input-group prepend="$">
                    <b-form-input
                      id="name"
                      v-model.number.lazy="employee.jobRoles[index].overtimePay"
                      step="0.01"
                      lazy-formatter
                      no-wheel
                      :formatter="formatAmount"
                      type="number"
                      aria-describedby="input-live-help input-live-feedback"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row class="rgh">
                <b-col class="text-left d-flex flex-column justify-content-center">
                  <b-form-select
                    :options="[...tipDistributions, { id: null, name: $t('employeeCreate.emptyValue') }]"
                    id="test"
                    value-field="id"
                    text-field="name"
                    aria-describedby="input-live-help input-live-feedback"
                    class="form-text"
                    v-model="employee.jobRoles[index].tipDistributionId"
                  ></b-form-select>
                </b-col>
              </b-row>
            </template>
          </b-col>

          <b-col
            v-if="(employee.jobRoles || []).filter(jr => jr.delete !== true).length < 4"
            cols="12"
            md="2"
            class="mb-4"
          >
            <b-row class="head rgh">
              <b-col cols="12" class="py-2 d-flex align-items-center align-center">
                <b-button
                  variant="primary"
                  @click="
                    (employee.jobRoles || []).push({
                      delete: false,
                      functionId: null,
                      timeAttendanceDepartmentId: null,
                      tipDistributionId: null,
                      function: {
                        name: ''
                      }
                    })
                  "
                >
                  <b-icon icon="plus"></b-icon>
                  {{ $t('employeeCreate.jobRole.new') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row class="rgh">
              <b-col class="text-left d-flex flex-column justify-content-end"></b-col>
            </b-row>
            <b-row class="rgh">
              <b-col class="text-left d-flex flex-column justify-content-end"></b-col>
            </b-row>
            <b-row class="rgh">
              <b-col class="text-left d-flex flex-column justify-content-end"></b-col>
            </b-row>
            <b-row class="rgh">
              <b-col class="text-left d-flex flex-column justify-content-end"></b-col>
            </b-row>
            <b-row class="rgh">
              <b-col class="text-left d-flex flex-column justify-content-end"></b-col>
            </b-row>
            <b-row class="rgh">
              <b-col class="text-left d-flex flex-column justify-content-end"></b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
import EmployeeDeleteModal from '@/private/components/employees/components/EmployeeDeleteModal';

export default {
  name: 'employee-form-job-role',
  components: {
    EmployeeDeleteModal
  },
  props: {
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    jobRoles: {
      type: Array,
      default: () => []
    },
    timeAttendances: {
      type: Array,
      default: () => []
    },
    tipDistributions: {
      type: Array,
      default: () => []
    },
    accessTypes: {
      type: Array,
      default: () => []
    },
    mealPlans: {
      type: Array,
      default: () => []
    },
    pending: {
      type: Boolean,
      default: false
    },
    submited: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {
    onDelete(index) {
      this.employee.jobRoles[index].delete = true;
      this.showDelete = null;
    },
    update(val) {
      /* eslint-disable-next-line no-console */
    },
    setActive(val) {
      this.showActive = val;
      this.employee.isRightHanded = !val;
    },
    formatPayrollCode(value) {
      if (Number(value) > 99999999) {
        return 99999999;
      }
      return value;
    },
    formatAmount(value) {
      return Number(value).toFixed(2);
    }
  },
  data() {
    return {
      userJobRoles: [
        {
          jobRoleId: null,
          accessTypeId: null,
          timeAttendanceDepartmentId: null,
          regularPay: 0.0,
          overtimePay: 0.0,
          tipDistributionId: null
        }
      ],
      showActive: false,
      showDelete: null
    };
  }
};
</script>
<style lang="scss" scoped>
.rgh {
  height: 80px;
}

.head.rgh {
  height: 53px;
}

/deep/ *:active {
  outline: none;
}

/deep/ *:focus {
  outline: none;
}

.deleted {
  display: none;
}

h5 {
  background-color: #f5f5f5;
}

/deep/ {
  label,
  .form-text {
    margin-bottom: 0;
    padding-left: 1rem;
  }

  .col-form-label {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-left: 1rem;
  }
}

/deep/ .form-text {
  min-height: 18px;
}

.datepicker .input-group-append {
  margin-left: -35px;
}
</style>
