import { get, resolveCommonQuery } from '@/helpers/service.helper';
import { getToken } from '@/helpers/token.helper';

/**
 * Get inventory localisations
 * @link https://api.posveloce.com/docs/private#operation/getInventoryLocalisations
 */
export const getInventoryLocalisations = (params: {}) => {
  const url = resolveCommonQuery('/inventory/localisations', params).toString();
  return get(getToken(), url);
};

/**
 * Get inventory requisition indexes
 * @link https://api.posveloce.com/docs/private#operation/getInventoryRequisitionIndexes
 */
export const getInventoryRequisitionIndexes = (params: {}) => {
  const url = resolveCommonQuery('/inventory/requisitionIndexes', params).toString();
  return get(getToken(), url);
};
