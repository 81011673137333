<template>
  <portal to="app">
    <div class="permission-add-modal">
      <vel-modal dismissable @dismiss="handleCancel">
        <template v-slot:header>
          {{ $t('user.permissions.modals.add.title') }}
        </template>

        <template v-slot:default>
          <permission-form
            class="permission-add-modal__form"
            :locationsOptions="locations"
            :groupsOptions="groups"
            :permission="permission"
            :allowMultipleLocations="true"
            :allowMultipleGroups="true"
            @submit="handleSuccess"
            @cancel="handleCancel"
          ></permission-form>
        </template>
      </vel-modal>
    </div>
  </portal>
</template>

<script>
import PermissionForm from '../permission-form/PermissionForm.vue';
import VelModal from '@/components/modal/VelModal';

export default {
  name: 'add-permission-modal',
  components: {
    PermissionForm,
    VelModal
  },
  data() {
    return {
      permission: {}
    };
  },
  props: {
    locations: {
      type: Array,
      default: () => []
    },
    groups: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleCancel() {
      this.$emit('dismiss');
    },
    handleSuccess(permission) {
      let permissions;
      if (Array.isArray(permission.locationId)) {
        permissions = permission.locationId.map(locationId => ({
          permission: permission.permission,
          locationId
        }));
      } else if (Array.isArray(permission.groupId)) {
        permissions = permission.groupId.map(groupId => ({
          permission: permission.permission,
          groupId
        }));
      } else {
        permissions = [permission];
      }

      this.$emit('success', permissions);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .permission-form__location .el-select,
/deep/ .permission-form__group .el-select,
/deep/ .permission-form__permission .el-select {
  width: 100%;
}
</style>
