<template>
  <div class="main-content">
    <slot name="top" />
    <main class="main-content__body" role="main">
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  name: 'main-content'
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';
@import 'layout.scss';

$main-content-z: $layout-main-content-z;
$main-content-background-color: $wild-sand;

.main-content {
  background-color: $main-content-background-color;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 1px;
  position: relative;
  z-index: $main-content-z;

  &__body {
    display: flex;
    flex: 1;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
