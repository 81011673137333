import 'whatwg-fetch';
import { get, patch, post, remove, resolveCommonQuery } from '@/helpers/service.helper';
import { getToken } from '@/helpers/token.helper';
import pick from 'lodash.pick';

/**
 * Get all departments
 * @link https://api.posveloce.com/docs/private#operation/getDepartments
 **/

const getDepartments = (token, query = {}) => {
  const params = pick(query, ['locationId', 'groupId', 'isActive', 'offset', 'limit']);
  const url = resolveCommonQuery('/departments', params).toString();
  return get(token, url);
};

/**
 * Get department by id
 * @link https://api.posveloce.com/docs/private#operation/getDepartmentByID
 **/

const getDepartmentById = (token, id = {}) => {
  const url = resolveCommonQuery(`/departments/${id}`, null).toString();
  return get(token, url);
};

/**
 * Edit department
 * @link https://api.posveloce.com/docs/private#operation/patchDepartment
 **/

const editDepartment = payload => {
  const { id } = payload;
  const url = resolveCommonQuery(`/departments/${id}`, null).toString();
  return patch(getToken(), url, payload);
};

/**
 * Delete department
 * @link https://api.posveloce.com/docs/private#operation/deleteDepartment
 **/

const deleteDepartment = (token, id) => {
  const url = resolveCommonQuery(`/departments/${id}`, null).toString();
  return remove(token, url, null);
};

/**
 * Create new department
 * @link https://api.posveloce.com/docs/private#operation/postDepartment
 **/

const createDepartment = (body = {}) => {
  const url = resolveCommonQuery('/departments', null).toString();
  return post(getToken(), url, body);
};

export default {
  getDepartments,
  getDepartmentById,
  editDepartment,
  deleteDepartment,
  createDepartment
};
