import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import { createCollectionState, getSelectors, removeAll, setAll } from '@/helpers/stores.helpers';

const { selectAll, selectTotal, selectAllFiltered } = getSelectors();

export const useDataUsers = defineStore('users', {
  state: () =>
    createCollectionState({
      filter: '',
      status: {},
      error: null
    }),
  getters: {
    getUsersAll: selectAll,
    getUsersRows: selectAllFiltered(),
    getUsersAmount: selectTotal,
    isLoading: state => !!state.status.isLoading
  },
  actions: {
    getUsers() {
      this.getUsersRequest();

      usersService.getUsers(getToken()).then(
        users => {
          this.getUsersSuccess(users);
        },
        error => {
          this.getUsersFailure(error);
        }
      );
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    getUsersRequest() {
      this.status = { isLoading: true };
      this.data = removeAll(this.data);
      this.error = null;
      this.filter = '';
    },
    getUsersSuccess(users) {
      this.status = {};
      this.data = setAll(users, this.data);
      this.error = null;
      this.filter = '';
    },
    getUsersFailure(error) {
      this.status = {};
      this.data = removeAll(this.data);
      this.error = error;
    },
    setFilterValue(value) {
      this.filter = value;
    }
  }
});
