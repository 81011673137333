<template>
  <span class="vel-amount">
    {{ formattedAmount }}
  </span>
</template>

<script>
import formatMoney from '@/filters/format-money';

export default {
  name: 'vel-amount',
  props: {
    amount: {
      type: Number
    },
    currency: {
      type: String,
      default: ''
    }
  },
  computed: {
    formattedAmount() {
      return formatMoney(this.amount, this.currency);
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';

.vel-amount {
  @include monospaced;
}
</style>
