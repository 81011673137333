<template>
  <b-container fluid>
    <b-row class="d-flex flex-row is-justify-space-between">
      <b-col
        cols="6"
        sm="4"
        class="d-flex flex-column flex-md-row align-content-between justify-content-between justify-content-md-start mb-md-0"
      >
        <b-button
          :disabled="!canSave || pending"
          :variant="!canSave ? 'outline-primary' : 'primary'"
          @click="$emit('onSave')"
          class="mt-4 mt-md-0 d-md-block"
        >
          {{ combo.id ? $t('combo.edit.btns.edit') : $t('combo.edit.btns.save') }}
        </b-button>
      </b-col>
      <b-col class="align-self-end text-right" cols="6" sm="8">
        <b-button :disabled="pending" variant="outline-secondary" @click="$emit('onCancel')">
          {{ $t('combo.edit.btns.cancel') }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'combo-footer',
  components: {},
  props: {
    combo: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    submited: {
      type: Boolean,
      default: false
    },
    canSave: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {}
};
</script>
