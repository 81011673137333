<template>
  <div>
    <VelList
      v-model="currentSelection"
      :value-options="valueOptions"
      :multiple="true"
      @cancel="cancel"
      @ok="validate"
      ref="list"
    />
  </div>
</template>

<script>
import VelList from './VelList';
import { bus } from '@/bus';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'vel-list-popup',
  components: {
    VelList
  },
  props: {
    value: {
      type: [String, Array],
      default: null
    },
    show: {
      type: Boolean,
      default: false
    },
    valueOptions: {
      type: [String, Array],
      default: null
    }
  },
  data() {
    return {
      filter: '',
      openList: false,
      listFocus: false,
      currentSelection: []
    };
  },
  computed: {
    modelValue: {
      get() {
        return cloneDeep(this.value);
      },
      set(value) {
        this.$emit('input', cloneDeep(value));
      }
    }
  },
  methods: {
    validate() {
      this.modelValue = this.currentSelection;
      this.$emit('close');
    },
    cancel() {
      this.currentSelection = null;
      this.$emit('close');
    }
  },
  beforeMount() {
    this.currentSelection = this.modelValue;
  },
  mounted() {
    bus.$on('document:click', evt => {
      if (!(this.$el === evt.target || this.$el.contains(evt.target))) {
        if (this.$refs.list) {
          this.$refs.list.validate();
        }
        this.cancel();
      }
    });
  },
  beforeDestroy() {
    bus.$off('document:click', this.cancel);
  }
};
</script>
