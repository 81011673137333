import 'whatwg-fetch';
import { downloadPdfFile, downloadXlsxFile, get, resolveCommonQuery } from '@/helpers/service.helper';
import { getCurrentTimeForReport } from '@/helpers/date.helpers';
import pick from 'lodash.pick';
import { getToken } from '@/helpers/token.helper';

const LOCATIONS_PATH = '/locations';

/**
 * Get sales by locations
 * @link https://api.posveloce.com/docs/#operation/getLocationSalesSummary
 */
const getSalesByLocation = (token, query) => {
  const params = pick(query, [
    'from',
    'to',
    'currencyId',
    'limit',
    'offset',
    'locationId',
    'locationIds',
    'groupId',
    'currencyId'
  ]);
  const url = resolveCommonQuery(`/sales/locations`, params).toString();
  return get(token, url);
};

const downloadSalesByLocationXlsx = query => {
  const params = pick(query, ['from', 'to', 'currencyId', 'locationId', 'locationIds', 'groupId', 'currencyId', 'consolidated', 'groupByDate']);
  const url = resolveCommonQuery(`/sales/locations`, params).toString();
  return downloadXlsxFile(getToken(), url, `Net Sales Report_${getCurrentTimeForReport()}.xlsx`);
};

/**
 * Get workstation sales
 * @link https://api.posveloce.com/docs/#tag/sales/paths/~1sales~1workstations/get
 */
export const getWorkstationsSales = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/workstations`, params).toString();
  return get(token, url);
};

/**
 * Get discount sales
 * @link https://api.posveloce.com/docs/#tag/sales/paths/~1sales~1discounts/get
 */
export const getDiscounts = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/discounts`, params).toString();
  return get(token, url);
};

/**
 * Get big division sales
 * @link https://api.posveloce.com/docs/#operation/getSalesSummariesBrokenDownByBigDivisions
 * @deprecated
 */
const getBigDivisionSales = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/${query.locationId}/bigDivisions/sales`, params).toString();
  return get(token, url);
};

/**
 * Get big division sales
 * @link https://api.posveloce.com/docs/#tag/sales/paths/~1locations~1bigDivisions~1sales/get
 */
const getBigDivisionSalesV2 = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/bigDivisions`, params).toString();
  return get(token, url);
};

const downloadBigDivisionSalesXlsx = query => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/bigDivisions`, params).toString();
  return downloadXlsxFile(getToken(), url, `Sales Categories Report_${getCurrentTimeForReport()}.xlsx`);
};

/**
 * Get mode sales
 * @link https://api.posveloce.com/docs/#tag/sales/paths/~1locations~1modes~1sales/get
 */
const getModeSales = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/modes/sales`, params).toString();
  return get(token, url);
};

/**
 * Get product sales
 * @link https://api.posveloce.com/docs/#tag/sales/paths/~1locations~1products~1sales/get
 */
const getTop10ItemsSales = (token, query) => {
  const params = pick(query, [
    'from',
    'to',
    'locationId',
    'locationIds',
    'groupId',
    'limit',
    'offset',
    'groupByName',
    'currencyId'
  ]);
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/products/sales`, params).toString();
  return get(token, url);
};

/**
 * Get paymode sales
 * @link https://api.posveloce.com/docs/#tag/sales/paths/~1locations~1paymodes~1sales/get
 */
const getPaymodeSales = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/paymodes/sales`, params).toString();
  return get(token, url);
};

/**
 * Get department sales
 * @link https://api.posveloce.com/docs/#tag/sales/paths/~1locations~1departments~1sales/get
 */
const getDepartmentSales = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/departments/sales`, params).toString();
  return get(token, url);
};

/**
 * Get zone sales
 * @link https://api.posveloce.com/docs/#tag/sales/paths/~1locations~1zones~1sales/get
 */
const getZoneSales = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/zones/sales`, params).toString();
  return get(token, url);
};

/**
 * Get detailed employee sales and quantities
 * @url https://api.posveloce.com/docs/#operation/getEmployeesSales
 */
const getSalesByEmployee = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'currencyId']);
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/${query.locationIds}/employees/sales`, params).toString();
  return get(token, url);
};

const downloadSalesByEmployeeXlsx = query => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'currencyId']);
  const url = resolveCommonQuery(`${LOCATIONS_PATH}/${params.locationId}/employees/sales`, params).toString();
  return downloadXlsxFile(getToken(), url, `Employees Sales Report_${getCurrentTimeForReport()}.xlsx`);
};

/**
 * Get detailed sales and quantities
 * @url https://api.posveloce.com/docs/#operation/getSales
 */
export const getDetailedSales = (token, query) => {
  const params = pick(query, [
    'from',
    'to',
    'limit',
    'offset',
    'locationId',
    'locationIds',
    'groupId',
    'useAccountingTime',
    'timeAggregation',
    'currencyId'
  ]);
  const url = resolveCommonQuery(`/sales`, params).toString();
  return get(token, url);
};

/**
 * Get hourly sales
 * @link https://api.posveloce.com/docs/#operation/getHourlySales
 */
export const getSalesHourly = (token, query) => {
  const params = pick(query, ['day', 'groupId', 'locationId', 'locationIds', 'currencyId']);
  const url = resolveCommonQuery(`/sales/hourly`, params).toString();
  return get(token, url);
};

/**
 * Get hourly sales per location
 * @link https://api.posveloce.com/docs/#operation/getHourlySales
 */
export const getLocationsSalesHourly = (token, query) => {
  const params = pick(query, ['groupId', 'locationId', 'locationIds', 'from', 'to', 'currencyId']);
  const url = resolveCommonQuery(`/sales/locations/hourly?include=location`, params).toString();
  return get(token, url);
};

export const downloadLocationsSalesHourlyXlsx = (token, query) => {
  const params = pick(query, ['groupId', 'locationId', 'locationIds', 'from', 'to', 'currencyId']);
  const url = resolveCommonQuery(`/sales/locations/hourly`, params).toString();
  return downloadXlsxFile(token, url, `Sales Per Hour Report_${getCurrentTimeForReport()}.xlsx`);
};

/**
 * Get indicators
 * @link https://api.posveloce.com/docs/#operation/getIndicatorsSales
 */
export const getIndicators = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/indicators`, params).toString();
  return get(token, url);
};

/**
 * Get sales tender types
 * @link https://api.posveloce.com/docs/private#tag/sales/paths/~1sales~1tenderTypes/get
 */
export const getSalesTenderTypes = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/tenderTypes`, params).toString();
  return get(token, url);
};

/**
 * Get sales by a location's big division broken down by divisions
 * @url https://api.posveloce.com/docs/#operation/getSalesSummariesBrokenDownByDivisions
 */
const getSalesByDivision = (token, query) => {
  const params = pick(query, ['from', 'to', 'limit', 'offset', 'currencyId']);
  const url = resolveCommonQuery(
    `${LOCATIONS_PATH}/${query.locationId}/bigDivisions/${query.bigDivisionId}/divisions/sales`,
    params
  ).toString();
  return get(token, url);
};

/**
 * Get sales by a location's big division's division broken down by invoice items
 * @url https://api.posveloce.com/docs/#operation/getSalesSummariesBrokenDownByInvoiceItems
 */
const getSalesByItem = (token, query) => {
  const params = pick(query, ['from', 'to', 'limit', 'offset', 'currencyId']);
  const url = resolveCommonQuery(
    `${LOCATIONS_PATH}/${query.locationId}/bigDivisions/${query.bigDivisionId}/divisions/${query.divisionId}/items/sales`,
    params
  ).toString();
  return get(token, url);
};

/**
 * Returns summarized sales for each location, broken down by big division > divisions > items > modes. If no locationIDs and no groupIDs are specified, sales for all locations will be returned.
 * @url https://api.posveloce.com/docs/private#operation/getLocationSalesPerItemPerModeSummary
 */
const getLocationsSalesPerModes = (token, query) => {
  const params = pick(query, ['from', 'to', 'limit', 'offset', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(
    `/sales/locations/products/modes?include=location&include=mode&include=product&include=bigDivision&include=division`,
    params
  ).toString();
  return get(token, url);
};

const downloadLocationsSalesPerModesXlsx = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId']);
  const url = resolveCommonQuery(`/sales/locations/accounting/products/modes`, params).toString();
  return downloadXlsxFile(token, url, `Items Sales By Category Report_${getCurrentTimeForReport()}.xlsx`);
};

/**
 * Returns summarized sales for each location, broken down by items and then modes. If no locationIDs and no groupIDs are specified, sales for all locations will be returned.
 * @url https://api.posveloce.com/docs/private#operation/getLocationSalesPerItemSummary
 */
const getLocationsSalesPerItems = (token, query) => {
  const params = pick(query, [
    'from',
    'to',
    'limit',
    'offset',
    'locationId',
    'locationIds',
    'groupId',
    'sort',
    'order',
    'currencyId'
  ]);
  const url = resolveCommonQuery(
    `/sales/locations/products?include=location&include=mode&include=product`,
    params
  ).toString();
  return get(token, url);
};

const downloadItemSalesXlsx = query => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/locations/products`, params).toString();
  return downloadXlsxFile(getToken(), url, `Items Sales Report_${getCurrentTimeForReport()}.xlsx`);
};

/**
 * Get discount sales per location and accounting date
 * @url https://api.posveloce.com/docs/private#operation/getLocationSalesAccountingDiscounts
 */
const getLocationSalesAccountingDiscounts = (token, query) => {
  const params = pick(query, ['from', 'to', 'limit', 'offset', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(
    `/sales/locations/accounting/discounts?include=location&include=discount&include=employee&include=item`,
    params
  ).toString();
  return get(token, url);
};

const downloadLocationSalesAccountingDiscountsXlsx = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/locations/accounting/discounts`, params).toString();
  return downloadXlsxFile(token, url, `Discount Report_${getCurrentTimeForReport()}.xlsx`);
};

/**
 * Get sales accounting discount
 * @url https://api.posveloce.com/docs/private#operation/getSalesAccountingDiscounts
 */
const getSalesAccountingDiscounts = (token, query) => {
  const params = pick(query, ['from', 'to', 'limit', 'offset', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/accounting/discounts`, params).toString();
  return get(token, url);
};

/**
 * Get gift card sales per location and accounting date
 * @url https://api.posveloce.com/sales/locations/accounting/giftCards
 */
const getLocationSalesAccountingGiftCards = (token, query) => {
  const params = pick(query, ['from', 'to', 'limit', 'offset', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(
    `/sales/locations/accounting/giftCards?include=location&include=employee`,
    params
  ).toString();
  return get(token, url);
};

const downloadLocationSalesAccountingGiftCardsXlsx = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/locations/accounting/giftCards`, params).toString();
  return downloadXlsxFile(token, url, `Gift Card Report_${getCurrentTimeForReport()}.xlsx`);
};

/**
 * Get location sales per divisions
 * @url https://api.posveloce.com/sales/locations/divisions
 */
const getLocationSalesPerDivision = (token, query) => {
  const params = pick(query, ['from', 'to', 'limit', 'offset', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(
    `/sales/locations/divisions?include=location&include=division&include=bigDivision`,
    params
  ).toString();
  return get(token, url);
};

/**
 * Get tax summary on sales per locations
 * @url hhttps://api.posveloce.com/sales/locations/taxes
 */
const GetSalesLocationsTaxes = (token, query) => {
  const params = pick(query, ['from', 'to', 'limit', 'offset', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/locations/taxes`, params).toString();
  return get(token, url);
};

/**
 * Get discount sales per location
 * @url https://api.posveloce.com/sales/locations/discounts
 */
const getDiscountSalesPerLocation = (token, query) => {
  const params = pick(query, ['from', 'to', 'limit', 'offset', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/locations/discounts?include=location`, params).toString();
  return get(token, url);
};

/**
 * Get sales by tender types per location
 * @url https://api.posveloce.com/sales/locations/tenderTypes
 */
const getLocationsTenderTypesSummary = (token, query) => {
  const params = pick(query, ['from', 'to', 'limit', 'offset', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/locations/tenderTypes?include=location&include=tenderType`, params).toString();
  return get(token, url);
};

/**
 * Get audit sales report
 * @url https://api.posveloce.com/sales/audit
 */
const getAuditSales = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/audit?include=location`, params).toString();
  return get(token, url);
};

const downloadAuditSalesXlsx = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/audit`, params).toString();
  return downloadXlsxFile(token, url, `Audit Sales Report_${getCurrentTimeForReport()}.xlsx`);
};

/**
 * Get audit sales report consolidated
 * @url https://api.posveloce.com/docs/private#operation/getAuditSalesConsolidated
 */
const getAuditSalesConsolidated = (token, query) => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/audit/consolidated`, params).toString();
  return get(token, url);
};

const getSalesAndRevenue = query => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'currencyId']);
  const url = resolveCommonQuery(`/sales/locations/revenue`, params).toString();
  return get(getToken(), url);
};

const downloadSalesAndRevenuePdf = query => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'lang', 'currencyId']);
  const url = resolveCommonQuery(`/sales/locations/revenue`, params).toString();
  return downloadPdfFile(getToken(), url, `Sales And Revenue Report_${getCurrentTimeForReport()}.pdf`);
};

const downloadSalesAndRevenueXlsx = query => {
  const params = pick(query, ['from', 'to', 'locationId', 'locationIds', 'groupId', 'lang', 'currencyId']);
  const url = resolveCommonQuery(`/sales/locations/revenue`, params).toString();
  return downloadXlsxFile(getToken(), url, `Sales And Revenue Report_${getCurrentTimeForReport()}.xlsx`);
};

export default {
  getBigDivisionSalesV2,
  getIndicators,
  getSalesByDivision,
  getSalesByItem,
  getSalesByLocation,
  getSalesByEmployee,
  getSalesTenderTypes,
  getPaymodeSales,
  getZoneSales,
  getDepartmentSales,
  getTop10ItemsSales,
  getModeSales,
  getBigDivisionSales,
  getLocationsSalesPerModes,
  downloadLocationsSalesPerModesXlsx,
  getLocationsSalesPerItems,
  getDiscounts,
  getWorkstationsSales,
  getDetailedSales,
  getSalesHourly,
  getLocationsSalesHourly,
  downloadLocationsSalesHourlyXlsx,
  getLocationSalesAccountingDiscounts,
  downloadLocationSalesAccountingDiscountsXlsx,
  getLocationSalesAccountingGiftCards,
  downloadLocationSalesAccountingGiftCardsXlsx,
  getLocationSalesPerDivision,
  GetSalesLocationsTaxes,
  getDiscountSalesPerLocation,
  getLocationsTenderTypesSummary,
  getAuditSales,
  downloadAuditSalesXlsx,
  getAuditSalesConsolidated,
  getSalesAccountingDiscounts,
  downloadSalesByLocationXlsx,
  downloadBigDivisionSalesXlsx,
  downloadItemSalesXlsx,
  downloadSalesByEmployeeXlsx,
  getSalesAndRevenue,
  downloadSalesAndRevenuePdf,
  downloadSalesAndRevenueXlsx,
};
