const sortBySalesAmountDesc = (a, b) => b.salesAmount - a.salesAmount;

Map.prototype.extractSortedArray = function (predicate) {
  return Array.from(this.values()).sort(predicate);
};

const recurseExtractChildrenArray = category => {
  const hasChildrenSomeSubChildren = !!category.children.values().next().value.children;

  return {
    ...category,
    children: !hasChildrenSomeSubChildren
      ? category.children.extractSortedArray(sortBySalesAmountDesc)
      : category.children.extractSortedArray(sortBySalesAmountDesc).map(recurseExtractChildrenArray)
  };
};

const formatItemsByLevels = sales =>
  sales
    .filter(s => s.isDiscount === false)
    .reduce((divisions, c) => {
      const {
        salesAmount,
        quantity,
        division: { name: divisionName },
        locationName,
        reportingDisplayName,
        item: { name: itemName, id: itemId, remoteId: itemRemoteId }
      } = c;
      const currency = (c.detailedCurrency && c.detailedCurrency.currencySymbol) || c.currency;
      const currencyCode = (c.detailedCurrency && c.detailedCurrency.currencyCode) || c.currency;

      const { salesAmount: currentDivSalesAmount = 0, quantity: currentDivQuantity = 0, children: items = new Map() } =
        divisions.get(divisionName) || {};

      const {
        salesAmount: currentItemSalesAmount = 0,
        quantity: currentItemSalesQuantity = 0,
        children: locations = new Map()
      } = items.get(itemName) || {};

      const { salesAmount: currentLocationSalesAmount = 0, quantity: currentLocationSalesQuantity = 0 } =
        locations.get(locationName) || {};

      locations.set(locationName, {
        label: reportingDisplayName || locationName,
        salesAmount: salesAmount + currentLocationSalesAmount,
        quantity: quantity + currentLocationSalesQuantity,
        currency,
        currencyCode,
        remoteId: itemRemoteId
      });

      items.set(itemName, {
        id: itemId,
        label: itemName,
        salesAmount: salesAmount + currentItemSalesAmount,
        quantity: quantity + currentItemSalesQuantity,
        currency,
        currencyCode,
        children: locations
      });

      divisions.set(divisionName, {
        label: divisionName,
        salesAmount: salesAmount + currentDivSalesAmount,
        quantity: quantity + currentDivQuantity,
        currency,
        currencyCode,
        children: items
      });

      return divisions;
    }, new Map())
    .extractSortedArray(sortBySalesAmountDesc)
    .map(recurseExtractChildrenArray);

const formatEmployeeSalesByLevels = sales =>
  sales
    .reduce((employees, c) => {
      const {
        salesAmount,
        quantity,
        bigDivision: { name: bigDivisionName },
        division: { name: divisionName },
        employeeName,
        employeeId,
        item: { name: itemName, id: itemId, remoteId: itemRemoteId }
      } = c;
      const currency = (c.detailedCurrency && c.detailedCurrency.currencySymbol) || c.currency;
      const currencyCode = (c.detailedCurrency && c.detailedCurrency.currencyCode) || c.currency;

      const {
        salesAmount: currentEmployeeAmount = 0,
        quantity: currentEmployeeQuantity = 0,
        children: bigDivisions = new Map()
      } = employees.get(employeeName) || {};

      const {
        salesAmount: currentBigDivAmount = 0,
        quantity: currentBigDivQuantity = 0,
        children: divisions = new Map()
      } = bigDivisions.get(bigDivisionName) || {};

      const { salesAmount: currentDivAmount = 0, quantity: currentDivQuantity = 0, children: items = new Map() } =
        divisions.get(divisionName) || {};

      const { salesAmount: currentItemAmount = 0, quantity: currentItemQuantity = 0 } = items.get(itemName) || {};

      items.set(itemName, {
        id: itemId,
        label: itemName,
        remoteId: itemRemoteId,
        salesAmount: salesAmount + currentItemAmount,
        quantity: quantity + currentItemQuantity,
        currency,
        currencyCode
      });

      divisions.set(divisionName, {
        label: divisionName,
        salesAmount: salesAmount + currentDivAmount,
        quantity: quantity + currentDivQuantity,
        currency,
        currencyCode,
        children: items
      });

      bigDivisions.set(bigDivisionName, {
        label: bigDivisionName,
        salesAmount: salesAmount + currentBigDivAmount,
        quantity: quantity + currentBigDivQuantity,
        currency,
        currencyCode,
        children: divisions
      });

      employees.set(employeeName, {
        id: employeeId,
        label: employeeName,
        salesAmount: salesAmount + currentEmployeeAmount,
        quantity: quantity + currentEmployeeQuantity,
        currency,
        currencyCode,
        children: bigDivisions
      });

      return employees;
    }, new Map())
    .extractSortedArray(sortBySalesAmountDesc)
    .map(recurseExtractChildrenArray);

const formatSales = sales => {
  const totalSales = (sales || []).reduce((result, s) => result + s.salesAmount, 0);

  return (sales || []).map(s => ({
    ...s,
    name: s.reportingDisplayName || s.name,
    salesPercent: ((s.salesAmount / totalSales) * 100 || 0).toFixed(2)
  }));
};

const formatBigDivisionByLevels = sales =>
  sales
    .filter(s => s.isDiscount === false)
    .reduce((result, c) => {
      const {
        salesAmount,
        quantity,
        locationName,
        reportingDisplayName,
        bigDivision: { name }
      } = c;
      const currency = (c.detailedCurrency && c.detailedCurrency.currencySymbol) || c.currency;
      const currencyCode = (c.detailedCurrency && c.detailedCurrency.currencyCode) || c.currency;

      const { salesAmount: currentDivSalesAmount = 0, quantity: currentDivQuantity = 0, children = new Map() } =
        result.get(name) || {};
      const { salesAmount: currentLocationSalesAmount = 0, quantity: currentLocationSalesQuantity = 0 } =
        children.get(locationName) || {};

      children.set(locationName, {
        label: reportingDisplayName || locationName,
        salesAmount: salesAmount + currentLocationSalesAmount,
        quantity: quantity + currentLocationSalesQuantity,
        currency,
        currencyCode
      });

      result.set(name, {
        label: name,
        salesAmount: salesAmount + currentDivSalesAmount,
        quantity: quantity + currentDivQuantity,
        currency,
        currencyCode,
        children
      });
      return result;
    }, new Map())
    .extractSortedArray(sortBySalesAmountDesc)
    .map(recurseExtractChildrenArray);

export default {
  formatItemsByLevels,
  formatEmployeeSalesByLevels,
  formatBigDivisionByLevels,
  formatSales
};
