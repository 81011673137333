import { get, patch, post, remove, resolveCommonQuery } from '@/helpers/service.helper';
import { getToken } from '@/helpers/token.helper';

/**
 * Get time attendance departments
 * @link https://api.posveloce.com/docs/private#operation/getTimeAttendanceDepartments
 */
export const getTimeAttendanceDepartments = (params: {}) => {
  const url = resolveCommonQuery('/timeAttendance/departments', params).toString();
  return get(getToken(), url);
};

/**
 * Create a time attendance department
 * @link https://api.posveloce.com/docs/private#operation/postTimeAttendanceDepartment
 */
export const postTimeAttendanceDepartment = (body: {}) => post(getToken(), `/timeAttendance/departments`, body);

/**
 * Create a time attendance department
 * @link https://api.posveloce.com/docs/private#operation/postTimeAttendanceDepartment
 */
export const patchTimeAttendanceDepartment = (id, body: {}) =>
  patch(getToken(), `/timeAttendance/departments/${id}`, body);

/**
 * Delete a time attendance department
 * @link https://api.posveloce.com/docs/private#operation/deleteTimeAttendanceDepartment
 */
export const deleteTimeAttendanceDepartment = id => remove(getToken(), `/timeAttendance/departments/${id}`);

/**
 * Get time attendance shifts
 * @link https://api.posveloce.com/docs/private#operation/getTimeAttendanceShifts
 */
export const getTimeAttendanceShifts = (params: {}) => {
  const url = resolveCommonQuery('/timeAttendance/shifts', params).toString();
  return get(getToken(), url);
};
