<template>
  <vel-select
    :autofocus="autofocus"
    :disabled="disabled"
    :filterable="isFilterable"
    :multiple="multiple"
    :no-data-text="$t('group.selector.nodata')"
    :no-match-text="$t('group.selector.nomatch')"
    :placeholder="$t('group.selector.placeholder')"
    :value="groupId"
    @change="handleChange"
    class="vel-select"
  >
    <vel-option-group
      v-if="allowGlobalGroupOption"
      :label="showGlobalGroupOptionGroupLabel ? $t('group.selector.options.groups.all.title') : ''"
    >
      <vel-option
        :label="allGroupsLabel || $t('group.selector.options.groups.all.defaultLabel')"
        :value="''"
      ></vel-option>
    </vel-option-group>

    <vel-option-group
      v-if="groupsOptions.length > 0"
      :label="allowGlobalGroupOption ? $t('group.selector.options.groups.groups.title') : ''"
    >
      <vel-option
        v-for="option in groupsOptions"
        :label="option.label"
        :value="option.value"
        :key="option.value"
      ></vel-option>
    </vel-option-group>
  </vel-select>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelOption from '@/components/x-option/VelOption';
import VelOptionGroup from '@/components/x-option-group/VelOptionGroup';
import VelSelect from '@/components/x-select/VelSelect';
import { mapState } from 'pinia';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';

export default {
  name: 'vel-group-selector',
  components: {
    VelOption,
    VelOptionGroup,
    VelSelect
  },
  mixins: [DeviceMixin],
  props: {
    allGroupsLabel: {
      type: String,
      default: ''
    },
    allowGlobalGroupOption: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: true
    },
    groupsOptions: {
      type: Array,
      required: true
    },
    groupId: {
      type: [String, Array],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    showGlobalGroupOptionGroupLabel: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(useUIGroupSelector, ['getGroupSelectorSelected']),
    isFilterable() {
      return this.filterable;
    }
  },
  methods: {
    handleChange(e) {
      this.$emit('change', e);
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

:root .el-select-dropdown__item {
  max-width: 17rem;
  @include text-ellipsis;

  @include breakpoint(small) {
    max-width: 20rem;
  }

  @include breakpoint(medium) {
    max-width: none;
  }
}

.vel-select {
  @include noPrint;
}
</style>
