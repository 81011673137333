import { get, resolveCommonQuery } from '@/helpers/service.helper';
import { getToken } from '@/helpers/token.helper';

/**
 * Get key groups
 * @link https://api.posveloce.com/docs/private#operation/patchKeyGroup
 */
export const getKeyGroups = (params: {} = {}) => {
  const url = resolveCommonQuery('/keyGroups', params).toString();
  return get(getToken(), url);
};
