import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { createCollectionState, getSelectors, removeAll, setAll, setOne } from '@/helpers/stores.helpers';
import router from '@/router';
import discountsService from '@/services/discounts.service';
import { useDataDiscountsSchedule } from '@/stores/data/discounts-schedule.module';

const { selectAllFiltered, selectSelected, selectTotal } = getSelectors();

export const useDataDiscounts = defineStore('discounts', {
  state: () =>
    createCollectionState({
      loading: true,
      pending: true,
      error: null,
      filter: ''
    }),
  getters: {
    discounts: selectAllFiltered(['name', 'alternativeName', 'isActive', 'type', 'amount', 'rate', 'couponValue']),
    selectTotal,
    selectSelected
  },
  actions: {
    async getDiscounts() {
      try {
        const routeQuery = {
          ...router.currentRoute.query
        };
        this.getDiscountsRequest();
        const discounts = await discountsService.getDiscounts(getToken(), routeQuery);
        this.getDiscountsSuccess(discounts);
      } catch (error) {
        this.getDiscountsFailure(error);
      }
    },
    async getDiscountById() {
      try {
        const routeQuery = {
          ...router.currentRoute.query
        };
        this.getDiscountByIdRequest();
        const discount = await discountsService.getDiscountById(getToken(), routeQuery);
        this.getDiscountByIdSuccess(discount);
      } catch (error) {
        this.getDiscountByIdFailure(error);
      }
    },
    async setDiscountById(discount) {
      try {
        const routeQuery = {
          ...router.currentRoute.query
        };
        this.setDiscountByIdRequest();

        const discountsScheduleStore = useDataDiscountsSchedule();
        discountsScheduleStore.setDiscountSchedule();
        const response = await discountsService.setDiscountById(getToken(), discount, routeQuery);
        this.setDiscountByIdSuccess(response);
      } catch (error) {
        this.setDiscountByIdFailure(error);
      }
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    getDiscountsRequest() {
      this.loading = true;
      this.error = null;
    },
    getDiscountsSuccess(discounts) {
      this.data = setAll(
        discounts.map(s => ({ ...s, rate: s.rate * 100 })),
        this.data
      );
      this.error = null;
      this.loading = false;
    },
    getDiscountsFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    getDiscountByIdRequest() {
      this.error = null;
      this.loading = true;
    },
    getDiscountByIdSuccess(discount) {
      this.data = setOne(discount, this.data);
      this.data.selected = discount.id;
      this.loading = false;
      this.error = null;
    },
    getDiscountByIdFailure(error) {
      this.data = removeAll(this.data);
      this.loading = false;
      this.error = error;
    },
    setDiscountByIdRequest() {
      this.error = null;
      this.loading = true;
    },
    setDiscountByIdSuccess(discount) {
      this.data = removeAll(this.data);
      this.data = setOne(discount, this.data);
      this.data.selected = discount.id;
      this.loading = false;
      this.error = null;
    },
    setDiscountByIdFailure(error) {
      this.error = error;
      this.loading = false;
    },
    setFilterValue(value) {
      this.filter = value;
    },
    setSelected(value) {
      this.data.selected = value;
    }
  }
});
