import 'whatwg-fetch';
import { get, put, resolveCommonQuery } from '@/helpers/service.helper';
import pick from 'lodash.pick';

/**
 * Get a division by its id
 * @link https://api.posveloce.com/docs/#operation/getDivisionByID
 */
const getDivision = (token, query) => {
  const { bigDivisionId, divisionId, locationId, locationIds } = query;

  return get(token, `/locations/${locationId || locationIds}/bigDivisions/${bigDivisionId}/divisions/${divisionId}`);
};

/**
 * Get all divisions for a give location or group
 * @link https://api.posveloce.com/docs/#operation/getDivisions
 */

const getDivisions = (token, query) => {
  const params = pick(query, ['locationId', 'locationIds', 'groupId', 'limit', 'offset']);
  const url = resolveCommonQuery('/divisions', params).toString();
  return get(token, url);
};

/**
 *
 * Update (PUT) divisions
 * @link https://api.posveloce.com/docs/#operation/putDivisions
 */

const updateDivision = (token, division) => put(token, '/divisions', division);

export default {
  getDivision,
  getDivisions,
  updateDivision
};
