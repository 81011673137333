import 'whatwg-fetch';
import { get, patch, post, remove, resolveCommonQuery } from '@/helpers/service.helper';
import pick from 'lodash.pick';

/**
 * Get all items mix and match
 * @link https://api.posveloce.com/docs/private#operation/getItemsMixMatch
 */
const getItemsMixMatch = (token, query) => {
  const params = pick(query, ['limit', 'offset', 'locationId', 'locationIds', 'groupId']);
  const url = resolveCommonQuery('/items/mixMatch', params).toString();
  return get(token, url);
};

/**
 * Create new mix match
 * @link https://api.posveloce.com/docs/private#operation/postItemMixMatch
 */
const createMixMatch = (token, body) => {
  const url = resolveCommonQuery('/items/mixMatch', null).toString();
  return post(token, url, body);
};

/**
 * Get mix match by id
 * @link https://api.posveloce.com/docs/private#operation/getItemMixMatchById
 */
const getItemMixMatchById = (token, id) => {
  const url = resolveCommonQuery(`/items/mixMatch/${id}`, null).toString();
  return get(token, url);
};

/**
 * Edit mix and match
 * @link https://api.posveloce.com/docs/private#operation/patchItemMixMatch
 */
const editItemMixMatch = (token, body) => {
  const { id } = body;
  const url = resolveCommonQuery(`/items/mixMatch/${id}`, null).toString();
  return patch(token, url, body);
};

/**
 * Delete mix and match
 * @link https://api.posveloce.com/docs/private#operation/patchItemMixMatch
 */
const deleteItemMixMatch = (token, id) => {
  const url = resolveCommonQuery(`/items/mixMatch/${id}`, null).toString();
  return remove(token, url, null);
};

export default {
  getItemsMixMatch,
  createMixMatch,
  getItemMixMatchById,
  editItemMixMatch,
  deleteItemMixMatch
};
