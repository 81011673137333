<template>
  <vel-form
    ref="locationForm"
    class="location-form"
    :model="locationForm"
    :rules="locationFormRules"
    label-width="150px"
  >
    <vel-form-item
      class="location-form__location"
      prop="locationIds"
      :label="$t('group.locations.form.location.label')"
      required
    >
      <vel-location-selector
        :locationId="locationForm.locationIds"
        :locationsOptions="locationsOptions"
        :multiple="allowMultipleLocations"
        autofocus
        filterable
        @change="handleLocationChange"
      />
    </vel-form-item>

    <vel-form-item class="location-form__actions">
      <vel-button type="primary" class="actions__submit" :loading="isSubmitDisabled" @click="handleSubmit()">
        {{ $t('defaults.actions.save') }}
      </vel-button>
      <vel-button type="secondary" class="actions__cancel" @click="handleCancel()">
        {{ $t('defaults.actions.cancel') }}
      </vel-button>
    </vel-form-item>
  </vel-form>
</template>

<script>
import VelButton from '@/components/button/VelButton';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelLocationSelector from '@/components/location-selector/VelLocationSelector';

export default {
  name: 'location-form',
  components: {
    VelButton,
    VelForm,
    VelFormItem,
    VelLocationSelector
  },
  props: {
    allowMultipleLocations: {
      type: Boolean,
      default: false
    },
    locationsOptions: {
      type: Array,
      default: () => []
    },
    isSavingLocations: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      locationForm: {
        locationIds: []
      }
    };
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleSubmit() {
      this.$refs.locationForm.validate(valid => {
        if (valid) {
          this.$emit('submit', this.locationForm);
        }
      });
    },
    handleLocationChange(locations) {
      this.locationForm.locationIds = locations;
    }
  },
  computed: {
    locationFormRules() {
      return {
        locationIds: [
          {
            required: true,
            message: this.$t('group.locations.form.location.isRequired')
          }
        ]
      };
    },
    isSubmitDisabled() {
      return this.isSavingLocations;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.location-form__actions {
  .actions__submit {
    margin-right: 1.25em;
  }
}
</style>
