const LABEL_SELECTOR = '.pie .apexcharts-tooltip-text-label';
const VALUE_SELECTOR = '.pie .apexcharts-tooltip-text-value';
const TOOLTIP_SELECTOR = '.pie .apexcharts-tooltip';
const SERIES_SELECTOR = '.pie .apexcharts-tooltip-series-group';

const disableTooltip = el => {
  if (el.querySelectorAll(TOOLTIP_SELECTOR) && el.querySelectorAll(TOOLTIP_SELECTOR)[0]) {
    el.querySelectorAll(TOOLTIP_SELECTOR)[0].classList.remove('apexcharts-active');
  }
  if (el.querySelectorAll(SERIES_SELECTOR) && el.querySelectorAll(SERIES_SELECTOR)[0]) {
    el.querySelectorAll(SERIES_SELECTOR)[0].classList.remove('apexcharts-active');
  }

  updateTooltip(el, '', '');
};

const disablePreviousTooltip = () => {
  document.querySelectorAll(TOOLTIP_SELECTOR).forEach(e => {
    e.classList.remove('active');
  });
  document.querySelectorAll(LABEL_SELECTOR).forEach(e => {
    e.innerHTML = '';
  });
};

const enableTooltip = (el, bgColor) => {
  el.querySelector('.apexcharts-tooltip-marker').setAttribute('style', 'display: none;');
  el.querySelectorAll(TOOLTIP_SELECTOR)[0].classList.add('apexcharts-active');
  if (el.querySelector('.apexcharts-svg.apexcharts-tooltip')) {
    el.querySelector('.apexcharts-svg.apexcharts-tooltip').classList.add('apexcharts-active');
  }
  el.querySelectorAll(SERIES_SELECTOR)[0].setAttribute('style', `display: flex;${bgColor}`);
  el.querySelectorAll(SERIES_SELECTOR)[0].classList.add('apexcharts-active');
};

const updateTooltip = (el, label, serie) => {
  if (el.querySelectorAll(LABEL_SELECTOR) && el.querySelectorAll(LABEL_SELECTOR)[0]) {
    el.querySelectorAll(LABEL_SELECTOR)[0].innerHTML = label;
    el.querySelectorAll(VALUE_SELECTOR)[0].innerHTML = serie;
  }
};

export const showChartTooltipOnLegendEvent = el => (chartContext, seriesIndex, config) => {
  const label = `${config.config.labels[seriesIndex]}:`;
  let serie = config.config.series[seriesIndex];

  if (config.config.yaxis[0].labels.formatter) {
    serie = config.config.yaxis[0].labels.formatter(serie);
  }

  if (el) {
    const [bgColor] = el
      .querySelectorAll('.apexcharts-legend-series')
      [seriesIndex].querySelector('.apexcharts-legend-marker')
      .getAttribute('style')
      .split(';');

    if (el.querySelector(LABEL_SELECTOR) && el.querySelector(LABEL_SELECTOR).innerHTML !== label) {
      disablePreviousTooltip();
      enableTooltip(el, bgColor);
      updateTooltip(el, label, serie);
    } else {
      //disableTooltip(el);
    }
  }
};

export const showChartTooltipOnLegendHover = el => (chartContext, config) => {
  setTimeout((): any => {
    el.querySelectorAll('.apexcharts-legend div').forEach(input => {
      input.addEventListener('mouseover', () => {
        showChartTooltipOnLegendEvent(el)(chartContext, input.getAttribute('rel') - 1, config);
      });
      input.addEventListener('mouseout', () => disableTooltip(el));
    });
  }, 800);
};
