export default {
  props: {
    currencyId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      mutableCurrencyId: this.currencyId
    };
  },
  methods: {
    handleCurrencyChange(currencyId) {
      this.mutableCurrencyId = currencyId;
      this.$router.replace({
        name: this.$router.history.current.name,
        query: {
          ...this.$router.history.current.query,
          currencyId: this.mutableCurrencyId,
          locationId: null,
          groupId: null
        }
      });
    }
  }
};
