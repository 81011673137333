<template>
  <vel-page
    :multiple-locations="locations && locations.length > 1"
    :enable-date-range="true"
    enable-toolbar
    :show-selector-button="true"
    :enable-currency="true"
    :show-crumbs="false"
    :generated-time="generatedTime"
    class="report"
  >
    <template v-slot:title>{{ $t('pages.reports.netSales') }}</template>
    <template v-slot:export>
      <vel-export
        v-if="hasData"
        :disabled="areSalesLoading || !hasData || !columns"
        :generating="generating"
        :enable-xls="true"
        :xls-sub-level="xlsSubSections"
        @exportXLS="onExportXLS"
      ></vel-export>
    </template>
    <template v-slot:toolbar-options>
      <div
        style="padding: 8px 1rem 0 1rem;"
        v-if="(!locationCount || locationCount > 1) && getCurrencySelectorSelected !== ''"
      >
        <vel-checkbox v-model="isConsolidated" @change="onConsolidate()">
          {{ $t('toolbar.selectors.consolidate') }}
        </vel-checkbox>
      </div>
    </template>
    <vel-card>
      <template v-slot:title>{{ $t('netSalesReport.title') }}</template>
      <vel-spinner class="spinner" v-if="areSalesLoading" />
      <tr class="vel-table__row" v-if="!hasData && !areSalesLoading" role="row">
        <td class="vel-table__cell vel-table__cell_empty" role="cell">
          {{ $t('defaults.empty') }}
        </td>
      </tr>
      <div v-else class="vel-table-wrapper">
        <table class="vel-table vel-table_hoverable">
          <thead class="vel-table-header">
            <tr class="vel-table__row">
              <th v-for="(col, cindex) in columns" :key="cindex" @click="handleColumnClick(cindex)">
                <div class="container">
                  <div>{{ col.title }}</div>
                  <div class="sort-icon">
                    <vel-icon
                      :class="`vel-table-header__sort-icon vel-table-header__sort${
                        hasSort(cindex) && hasDirection('asc') ? '-icon_up' : ''
                      }`"
                      name="order-asc"
                    />
                    <vel-icon
                      :class="` vel-table-header__sort-icon vel-table-header__sort${
                        hasSort(cindex) && hasDirection('desc') ? '-icon_down' : ''
                      }`"
                      name="order-desc"
                    ></vel-icon>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="vel-table__body">
            <tr class="vel-table__row" v-for="(row, rindex) in computedRows" :key="rindex">
              <td class="vel-table__cell" v-for="(cell, cindex) in filterRow(row)" :key="cindex">
                <div class="content" v-if="cindex === 0">{{ cell.value }}</div>
                <vel-amount :amount="cell.value" :currency="sales[0].currency" v-else-if="cindex === 4" />
                <div v-else>{{ formatAmount(Math.round(cell.value)) }}</div>
              </td>
            </tr>
            <tr class="vel-table__row vel-table__total" v-if="hasData && !isConsolidated">
              <td v-for="(col, cindex) in columns" :key="cindex" class="vel-table__cell">
                {{ getSummaries(cindex) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </vel-card>
  </vel-page>
</template>

<script>
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia';
import DeviceMixin from '@/mixins/device-mixin';
import GeneratedTime from '@/mixins/generated-time-mixin';
import VelAmount from '@/components/amount/VelAmount';
import VelCard from '@/components/card/VelCard';
import VelCheckbox from '@/components/checkbox/VelCheckbox';
import VelExport from '@/components/export/VelExport';
import VelIcon from '@/components/icon/VelIcon';
import VelPage from '@/components/page/VelPage';
import VelSpinner from '@/components/spinner/VelSpinner';
import compareStringsInsensitive from '@/comparators/compare-strings-insensitive';
import formatMoney from '@/filters/format-money';
import getUserLocale from 'get-user-locale';
import router from '@/router';
import salesService from '@/services/sales.service';
import { today } from '@/helpers/date.helpers';
import { useDataGroups } from '@/stores/data/groups.module';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataSales } from '@/stores/data/sales.module';
import { useUICurrencySelector } from '@/stores/ui/currency-selector.module';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import {DateTime} from "luxon";
import {useUIDateRangeSelector} from "@/stores/ui/date-range-selector.module";

const ExportType = {
  Summary: 0,
  Detailed: 1
};

export default {
  name: 'net-sales-report-page',
  components: {
    VelAmount,
    VelCard,
    VelIcon,
    VelPage,
    VelSpinner,
    VelCheckbox,
    VelExport
  },
  mixins: [DeviceMixin, GeneratedTime],
  data() {
    return {
      sortBy: 'name',
      direction: 'asc' || null,
      isConsolidated: localStorage.getItem('isConsolidated') === 'true',
      generating: false,
      xlsSubSections: [
        {
          id: ExportType.Summary,
          title: 'netSalesReport.export.summary.title',
          description: 'netSalesReport.export.summary.description',
          icon: ''
        },
        {
          id: ExportType.Detailed,
          title: 'netSalesReport.export.detailed.title',
          description: 'netSalesReport.export.detailed.description',
          icon: ''
        }
      ]
    };
  },
  computed: {
    ...mapPiniaState(useUICurrencySelector, ['getCurrencySelectorSelected']),
    ...mapPiniaState(useDataLocationsStore, ['locations']),
    ...mapPiniaState(useDataSales, ['sales', 'areSalesLoading']),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById', 'locationsBySelectedGroup']),
    ...mapPiniaState(useDataGroups, ['getGroupsRows']),
    ...mapPiniaState(useUILocationSelector, {
      locationCount: 'getLocationSelectorSelectedMultipleLength',
      locationList: 'getLocationSelectorSelectedMultipleEntities',
      getLocationSelectorSelected: 'getLocationSelectorSelected',
      locationIds: 'getLocationSelectorSelectedMultipleIds'
    }),
    ...mapPiniaState(useUIDateRangeSelector, ['getDateSelectorSelectedRange']),
    ...mapPiniaState(useUIGroupSelector, ['getGroupSelectorSelected']),
    dataLength() {
      return (this.sales || []).length;
    },
    hasData() {
      return this.dataLength > 0;
    },
    canShowTotalCount() {
      return this.dataLength > 1;
    },
    day() {
      return today().startOf('day').toISO();
    },
    from() {
      return router.currentRoute.query.from || this.day;
    },
    to() {
      return router.currentRoute.query.to || this.from;
    },
    columns() {
      return {
        name: {
          title: this.$t('netSalesReport.table.columns.name'),
          sortable: true,
          sort: (c1, c2) => compareStringsInsensitive(c1.name, c2.name)
        },
        mealCount: {
          title: this.$t('netSalesReport.table.columns.mealCount'),
          sortable: true
        },
        orderCount: {
          title: this.$t('netSalesReport.table.columns.orderCount'),
          sortable: true
        },
        customerCount: {
          title: this.$t('netSalesReport.table.columns.customerCount'),
          sortable: true
        },
        netSales: {
          title: this.$t('netSalesReport.table.columns.netSales'),
          sortable: true
        }
      };
    },
    computedRows() {
      return Object.values(this.sortedRows).map(row =>
        Object.assign(
          Object.entries(this.columns)
            .map(([key, column]) => {
              const value = row[key];
              return {
                key,
                value,
                column,
                class: column.class
              };
            })
            .filter(Boolean),
          { __$index: row.__$index }
        )
      );
    },
    indexedRows() {
      const data = this.sales || [];
      if (this.isConsolidated && data.length > 1) {
        const allLocations = {
          ...data[0],
          name: !this.locationCount
            ? this.$t('dashboard.locationSelector.allLocationsText')
            : this.$t('dashboard.locationSelector.locationsSelected', {
                count: this.locationCount,
                total: this.locationsBySelectedGroup.length
              })
        };
        data
          .filter((_, i) => i > 0)
          .forEach(d => {
            allLocations.cashDiscountAmount += d.cashDiscountAmount;
            allLocations.mealCount += d.mealCount;
            allLocations.orderCount += d.orderCount;
            allLocations.customerCount += d.customerCount;
            allLocations.netSales += d.netSales;
          });
        return [allLocations];
      }
      let i = 0;
      return data.map(r => ({ ...r, __$index: i++ }));
    },
    sortedRows() {
      return this.indexedRows.slice(0).sort(this.sort);
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    ...mapPiniaActions(useDataSales, ['getSalesByLocation']),
    onConsolidate() {
      this.gen(false);
      localStorage.setItem('isConsolidated', this.isConsolidated);
    },
    async onExportXLS(type = 0) {
      this.generating = true;
      this.$ga.event('report', 'download', this.$route.name);

      const from = this.getDateSelectorSelectedRange[0];
      const to = this.getDateSelectorSelectedRange[1];

      const routeQuery = {
        ...router.currentRoute.query,
        locationIds: this.locationIds,
        groupId: this.getGroupSelectorSelected,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO(),
        consolidated: this.isConsolidated && type === ExportType.Summary,
        groupByDate: type === ExportType.Detailed ? true : false
      };

      await salesService.downloadSalesByLocationXlsx(routeQuery).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    },
    filterRow(row) {
      return row.filter(cell => cell.column.visible !== false);
    },
    getSummaries(column) {
      if (column.toLowerCase() === 'name') {
        return this.canShowTotalCount
          ? `Total (${this.dataLength} ${this.$t('netSalesReport.table.summaryTotalText.locations')})`
          : `Total`;
      }

      const values = (this.sales || []).map(item => Number(item[column]));

      let sum = 0;
      for (const v of values) {
        sum += v;
      }

      //@todo: find a better way for extendability (not future-proof at all)
      if (column.toLowerCase().includes(['sales'])) {
        return `${this.hasData ? formatMoney(sum, this.sales[0].currency) : ''}`;
      }

      return this.formatAmount(Math.round(sum));
    },
    sort(a, b) {
      const { sortBy, direction } = this;

      if (this.columns[sortBy] && this.columns[sortBy].sort) {
        let result = this.columns[sortBy].sort(a, b);
        result = direction !== 'asc' ? result * -1 : result;
        return result;
      }

      if (a[sortBy] < b[sortBy]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[sortBy] > b[sortBy]) {
        return direction === 'asc' ? 1 : -1;
      }

      return 0;
    },
    hasSort(key) {
      return this.sortBy === key;
    },
    hasDirection(direction) {
      return this.direction === direction;
    },
    handleColumnClick(column) {
      if (this.sortBy === column && this.direction === 'desc') {
        this.sortBy = null;
        this.direction = 'asc';
      } else if (this.sortBy === column && this.direction === 'asc') {
        this.direction = 'desc';
      } else {
        this.sortBy = column;
        this.direction = 'asc';
      }
      const { direction } = this;
      this.$emit('column', { direction, ...column });
    },
    formatAmount(amount) {
      const formattedAmount = new Intl.NumberFormat(getUserLocale(), {
        maximumFractionDigits: 0,
        useGrouping: true
      }).format(Number(amount) || 0);
      return `${formattedAmount}`;
    },
    numerizeAmount(amount) {
      if (getUserLocale().includes('fr')) {
        return Number(
          amount
            .split(/[\u202F\u00A0]/)
            .join('')
            .replace(',', '.')
        );
      }
      return Number(amount.split(',').join(''));
    }
  },
  beforeDestroy() {
    this.gen(false);
  },
  mounted() {
    const { from, to } = this;
    this.getSalesByLocation({ from, to });
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

$table-font-color: $river-bed;
$table-font-size: rem-calc(16);
$table-font-size_medium: rem-calc(15);
$table-font-size_small: rem-calc(13);
$table-background-color: white;
$table-background-color_striped: $concrete;
$table-border-color: $concrete;
$table-border-spacing: 2px;

// header
$table__header-height: rem-calc(20);
$table__header-font-color: $river-bed;

// cell
$table__cell-height: rem-calc(48);
$table__cell-background-color: $table-background-color;
$table__cell-background-color_hoverable: $gallery;
$table__cell-border-color: $table-border-color;
$table__cell-border-color_striped_bordered: $table-background-color;

// loader
$table__loader-background-color: $table-background-color;
$table__loader-z: 1;

.vel-button {
  background-color: $mountain-meadow;
  direction: rtl;
}

/deep/ {
  .vel-button__text {
    padding: 0 0.625rem;
  }

  .vel-button__icon {
    padding-left: 0;
  }
}

.vel-table {
  $component: &;

  background-color: $table-background-color;
  border-collapse: collapse;
  border-color: $table-border-color;
  border-spacing: $table-border-spacing;
  color: $table-font-color;
  font-size: $table-font-size;
  height: 100%;
  margin-bottom: rem-calc(10);
  min-width: rem-calc(400);
  overflow: hidden;
  table-layout: fixed;
  transition-property: background-color;
  word-break: break-word;

  &_loading {
    cursor: progress;
  }

  &_hoverable:not(&_empty) &__row:hover {
    #{$component}__cell {
      background-color: $table__cell-background-color_hoverable;
      color: $table-font-color;
      cursor: pointer;
      @include animate;
    }
  }

  &-wrapper {
    overflow: hidden;
    overflow-x: auto;
    position: relative;
    width: 100%;
  }

  &-header {
    border-bottom: 1px solid #eee;
    color: $table__header-font-color;
    font-size: $table-font-size_small;
    font-weight: bold;

    @include breakpoint(medium) {
      font-size: $table-font-size_medium;
    }

    .vel-table__row {
      th {
        width: rem-calc(150);

        .container {
          align-items: center;
          cursor: pointer;
          display: flex;
          justify-content: flex-end;
          user-select: none;
          word-break: keep-all;
        }

        &:nth-child(1) {
          width: rem-calc(200);

          .container {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            padding-left: rem-calc(12);
          }
        }

        &:nth-child(5) {
          .container {
            padding-right: rem-calc(12);
          }
        }
      }
    }

    .sort-icon {
      display: flex;
      flex-direction: column;
      margin-left: rem-calc(5);
    }
  }

  &__loader {
    align-items: center;
    background-color: $table__loader-background-color;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $table__loader-z;
  }

  &__cell {
    background-color: $table__cell-background-color;
    border-bottom: 1px solid $table__cell-border-color;
    box-sizing: border-box;
    font-size: $table-font-size_small;
    height: $table__cell-height;
    min-width: 0;
    padding: 0;
    text-align: left;
    text-overflow: ellipsis;
    vertical-align: middle;
    @include breakpoint(medium) {
      font-size: $table-font-size_medium;
      padding: 0;
    }

    &:nth-child(1),
    &:nth-child(5) {
      padding: rem-calc(12);
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      text-align: right;

      .content {
        text-align: right;
      }
    }
  }
  @include breakpoint(medium) {
    font-size: $table-font-size_medium;
  }

  &__total {
    td {
      background: $gallery;
    }

    font-weight: bold;
  }
}
</style>
