<template>
  <div class="vel-page-reloader">
    <oh-snap class="vel-page-reloader__picto" />
    <h1 class="vel-page-reloader__title" v-t="'defaults.page.failed.title'" />
    <p class="vel-page-reloader__explanation" v-html="$t('defaults.page.failed.explanationHtml')" />
    <vel-button class="vel-page-reloader__action" icon="refresh" @click="handleReload">
      {{ $t('defaults.page.failed.action') }}
    </vel-button>
  </div>
</template>

<script>
import OhSnap from '@/assets/svg/oh-snap.svg';
import VelButton from '@/components/button/VelButton';

export default {
  name: 'vel-page-reloader',
  components: {
    OhSnap,
    VelButton
  },
  methods: {
    handleReload() {
      this.$router.go();
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

// title
$reloader__title-font-size: rem-calc(32);
$reloader__title-font-size-medium-up: rem-calc(48);
$reloader__title-font-weight: $font-semi-bold;

// picto
$reloader__picto-color: $river-bed;
$reloader__picto-size: rem-calc(80);
$reloader__picto-size_medium-up: rem-calc(100);

.vel-page-reloader {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: rem-calc(20);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;

  &__picto {
    margin-bottom: rem-calc(10);
    width: $reloader__picto-size;
    @include icon-path-fill($reloader__picto-color);
  }

  &__title {
    font-size: $reloader__title-font-size;
    font-weight: $reloader__title-font-weight;
    margin-bottom: rem-calc(20);
  }

  &__explanation {
    margin-bottom: rem-calc(30);
    text-align: center;
  }

  @include breakpoint(medium) {
    &__picto {
      margin-bottom: rem-calc(10);
      width: $reloader__picto-size_medium-up;
    }

    &__title {
      font-size: $reloader__title-font-size-medium-up;
      margin-bottom: rem-calc(40);
    }

    &__explanation {
      margin-bottom: rem-calc(40);
    }
  }
}
</style>
