import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import { createCollectionState, getSelectors, removeAll, setOne } from '@/helpers/stores.helpers';
import bigDivisionsService from '@/services/bigDivisions.service';
import router from '@/router';

const { selectTotal } = getSelectors();

export const useDataBigDivisionsEdit = defineStore('bigDivisionsEdit', {
  state: () =>
    createCollectionState({
      loading: true,
      pending: true,
      error: null
    }),
  getters: {
    selectTotal
  },
  actions: {
    async updateBigDivision(bigDivision) {
      try {
        this.updateBigDivisionRequest();
        const res = await bigDivisionsService.updateBigDivision(getToken(), [
          {
            ...bigDivision,
            remoteId: bigDivision.remoteId - 1,
            locationId: router.currentRoute.query.locationId
          }
        ]);
        await router.replace({
          name: 'bigdivisions'
        });

        this.updateBigDivisionSuccess(res);
      } catch (error) {
        this.updateBigDivisionFailure(error);
      }
    },
    updateBigDivisionRequest() {
      this.loading = true;
      this.error = null;
    },
    updateBigDivisionSuccess(bigDivision) {
      this.data = setOne(bigDivision, this.data);
      this.error = null;
      this.loading = false;
    },
    updateBigDivisionFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    clearMessage() {
      this.data = removeAll(this.data);
    }
  }
});
