import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { createCollectionState, getSelectors, removeAll, setAll, updateMany } from '@/helpers/stores.helpers';
import router from '@/router';
import discountsService from '@/services/discounts.service';

const { selectAll } = getSelectors();

export const useDataDiscountsSchedule = defineStore('discountsSchedule', {
  state: () =>
    createCollectionState({
      loading: true,
      pending: true,
      error: null
    }),
  getters: {
    schedule: state => selectAll(state)
  },
  actions: {
    async getDiscountSchedule() {
      try {
        const routeQuery = {
          ...router.currentRoute.query
        };
        this.getDiscountScheduleRequest();
        const schedule = await discountsService.getDiscountSchedule(getToken(), routeQuery);
        this.getDiscountScheduleSuccess(schedule);
      } catch (error) {
        this.getDiscountScheduleFailure(error);
      }
    },
    async setDiscountSchedule() {
      try {
        const routeQuery = {
          ...router.currentRoute.query
        };
        this.setDiscountScheduleRequest();
        const body = (this.schedule || []).map(s => ({
          id: s.id,
          licenceNumber: s.mode.licenceNumber,
          modeId: s.mode.id,
          schedule: s.schedule
        }));
        const newSchedule = await discountsService.setDiscountSchedule(getToken(), body, routeQuery);
        this.setDiscountScheduleSuccess(newSchedule);
      } catch (error) {
        this.setDiscountScheduleFailure(error);
      }
    },
    getDiscountScheduleRequest() {
      this.error = null;
      this.loading = true;
    },
    getDiscountScheduleSuccess(schedule) {
      this.data = setAll(schedule, this.data);
      this.loading = false;
      this.error = null;
    },
    getDiscountScheduleFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    setDiscountScheduleRequest() {
      this.error = null;
      this.loading = true;
    },
    setDiscountScheduleSuccess(schedule) {
      this.data = setAll(schedule, this.data);
      this.loading = false;
      this.error = null;
    },
    setDiscountScheduleFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    updateDiscountScheduleCheckStatus(schedule) {
      this.data = updateMany(schedule, this.data);
      this.loading = false;
      this.error = null;
    }
  }
});
