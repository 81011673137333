<script>
import { Switch } from 'element-ui';

export default {
  name: 'vel-switch',
  extends: Switch
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

.el-switch {
  width: 100%;

  .el-switch__core {
    background-color: $hit-gray;
    border-color: $hit-gray;
  }

  &__label {
    width: 100%;

    &.is-active span {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &.is-checked {
    .el-switch__core {
      background-color: $iris-blue;
      border-color: $iris-blue;
    }
  }
}
</style>
