<template>
  <div>
    <div class="bg-white p-4 mt-2">
      <b-form-group
        :label="$t('bigDepartments.form.name.label')"
        :description="$t('bigDepartments.form.required')"
        label-for="name"
        class="mr-5 w-100 mb-2 mb-md-0"
      >
        <b-form-input
          id="name"
          v-model.trim="bigDepartment.name"
          maxlength="50"
          aria-describedby="input-live-help input-live-feedback"
          :class="{ required: !bigDepartment || !bigDepartment.name || bigDepartment.name === '' }"
        ></b-form-input>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'big-department-form',
  model: {},
  props: {
    licenceNumber: {
      type: Number,
      default: null
    },
    bigDepartment: {
      type: Object,
      default: () => ({
        name: ''
      })
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bigDepartmentOrig: null
    };
  },
  computed: {
    enableSaveChanges() {
      return (
        this.bigDepartment &&
        this.bigDepartment.name &&
        this.bigDepartment.name !== '' &&
        JSON.stringify(this.bigDepartmentOrig) !== JSON.stringify(this.bigDepartment)
      );
    },
    haveChanges() {
      return JSON.stringify(this.bigDepartmentOrig) !== JSON.stringify(this.bigDepartment);
    }
  },
  methods: {
    reset(value = null) {
      this.bigDepartmentOrig = cloneDeep(
        value || {
          name: ''
        }
      );
    }
  },
  mounted() {
    this.bigDepartmentOrig = cloneDeep(this.bigDepartment);
  }
};
</script>
<style lang="scss" scoped>
/deep/ {
  label,
  .form-text {
    margin-bottom: 0;
    padding-left: 1rem;
  }
}

/deep/ .form-text {
  min-height: 18px;
}

.form-group {
  max-width: 328px;
}
</style>
