<template>
  <div class="page">
    <vel-page-reloader class="vel-page__reloader" v-if="failed"/>
    <div class="vel-page__loader" v-if="loading">
      <vel-page-spinner class="vel-page__spinner"/>
    </div>
    <vel-modal
      class="configModal"
      v-if="!loading && $slots.customizer"
      v-show="showConfig"
      :loading="getPending"
      dismissable
      @dismiss="showConfig = false"
    >
      <template v-slot:header>
        {{ $t('editors.customize_link') }}
      </template>
      <slot name="customizer"/>
    </vel-modal>
    <section class="vel-page" :class="classes">
      <header class="vel-page__header">
        <h1 class="vel-page__title w-100 d-flex justify-content-between align-items-center">
          <slot v-if="$slots.title" name="title"/>
          <template v-else>
            {{ title }}
            <el-popover
              v-can:show="'enable_period_selector'"
              v-if="showStickyToolbar"
              placement="bottom"
              :trigger="isMediumUp ? 'hover' : 'click'"
            >
              <div class="toolbar">
                <toolbar-selectors
                  :enable-date-range="enableDateRange"
                  :enable-all-currencies="enableAllCurrencies"
                  :enable-group="enableGroups"
                  :enable-currency="enableCurrency"
                  :enable-date-picker="enableDatePicker"
                  :enable-all-locations="enableAllLocations"
                  :enable-button="showSelectorButton"
                  :enable-button-on-single-selection="showSelectorSingleLocationButton"
                  :loading="loading"
                ></toolbar-selectors>
              </div>
              <vel-icon slot="reference" :size="computedIconSize" name="cog"/>
            </el-popover>
          </template>
        </h1>
        <div class="versionSwitch flex flex-column" style="margin-left: auto; margin-right: 1rem">
          <p>{{ $t('Try the new Veloce Analytics look') }}</p>

          <vel-switch slot="reference" v-model="isNext"/>
          <div
            placement="bottom"
            class="newversionpopover el-popover"
            :class="{invisible: hideNewVersionPopup}"

          >
            <div class="switchPopup flex" style="display: flex; flex-direction: column">
              <img src="/IMG_Screenshot.png" style="width: 100%" alt="">
              <h5>{{ $t('New Veloce Analytics Look') }}</h5>
              <h6 style="white-space: wrap; word-break: normal">
                {{ $t('Click on this toggle to enable the new Veloce Analytics UI design.') }}</h6>
              <VelButton style="margin-top: 10px; margin-left: auto !important; margin-bottom: 8px; margin-right: 8px" class="actions__add "
                         type="primary" @click="disableNewVersion()">{{ $t('Remind me later') }}
              </VelButton>
            </div>
          </div>
        </div>
        <div>
          <slot name="export"></slot>
        </div>
        <vel-elink v-if="$slots.customizer" type="info" @click="showConfig = true">
          <span :title="$t('editors.customize_link')">
            <vel-icon style="width: 24px; height: 24px" name="dashboard_customize"/>
          </span>
        </vel-elink>
      </header>
      <vel-breadcrumb class="vel-page__breadcrumb" v-if="showCrumbs"/>
      <div v-on:scroll.passive="handleDebouncedScroll" ref="page" class="vel-page__content" v-if="!loading">
        <div class="vel-page__alert" v-if="$slots.alert">
          <slot name="alert"/>
        </div>
        <div class="vel-page__toolbar" ref="toolbar" v-if="enableToolbar">
          <slot v-if="$slots.toolbar" name="toolbar"/>
          <toolbar-selectors
            :enable-date-compare="showDateCompare"
            :enable-all-currencies="enableAllCurrencies"
            :enable-date-range="enableDateRange"
            :enable-datetime-range="enableDatetimeRange"
            :enable-currency="enableCurrency"
            :enable-date-picker="enableDatePicker"
            :enable-group="enableGroups"
            :enable-all-locations="enableAllLocations"
            :enable-button="showSelectorButton"
            :enable-button-on-single-selection="showSelectorSingleLocationButton"
            :loading="loading"
            :multiple-locations="multipleLocations"
            :showLabels="showToolbarLabels"
            v-else
          >
            <slot name="toolbar-options"></slot>
          </toolbar-selectors>
        </div>
        <slot v-if="!showSelectorButton || isGenerated"/>
        <div v-if="!showSelectorButton || isGenerated" class="footer">
          <div v-if="generatedTime" class="vel-page__generated-time">
            {{ $t('defaults.page.generatedTime', {date: dateFormat(generatedTime)}) }}
          </div>
        </div>
      </div>
      <footer v-if="$slots.footer && !loading">
        <slot name="footer"></slot>
      </footer>
    </section>
  </div>
</template>

<script>
import {DateTime} from 'luxon';
import DeviceMixin from '@/mixins/device-mixin';
import ToolbarSelectors from '@/private/shared/toolbar/ToolbarSelectors';
import VelBreadcrumb from '@/components/breadcrumb/VelBreadcrumb';
import VelElink from '@/components/elink/VelElink';
import VelIcon from '@/components/icon/VelIcon';
import VelModal from '@/components/modal/VelModal';
import VelPageReloader from './VelPageReloader';
import VelPageSpinner from './VelPageSpinner';
import debounce from 'lodash.debounce';
import {mapState as mapPiniaState} from 'pinia/dist/pinia';
import {useUILocationSelector} from '@/stores/ui/location-selector.module';
import {useDataConfig} from '@/stores/data/config.module';
import VelSwitch from "@/components/switch/VelSwitch.vue";
import {useLocalStorage} from "@vueuse/core";
import VelButton from "@/components/button/VelButton.vue";

export default {
  name: 'vel-page',
  components: {
    VelButton,
    VelSwitch,
    VelPageSpinner,
    VelPageReloader,
    VelBreadcrumb,
    ToolbarSelectors,
    VelElink,
    VelModal,
    VelIcon
  },
  mixins: [DeviceMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    multipleLocations: {
      type: Boolean,
      default: false
    },
    generation: {
      type: Boolean,
      default: true
    },
    showSelectorButton: {
      type: Boolean,
      default: false
    },
    failed: {
      type: Boolean,
      default: false
    },
    showCrumbs: {
      type: Boolean,
      default: true
    },
    showDateCompare: {
      type: Boolean,
      default: false
    },
    enableToolbar: {
      type: Boolean,
      default: false
    },
    enableDatePicker: {
      type: Boolean,
      default: false
    },
    enableAllLocations: {
      type: Boolean,
      default: true
    },
    enableAllCurrencies: {
      type: Boolean,
      default: false
    },
    enableDateRange: {
      type: Boolean,
      default: false
    },
    enableCurrency: {
      type: Boolean,
      default: false
    },
    enableGroups: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    generatedTime: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get', 'getPending']),
    ...mapPiniaState(useUILocationSelector, ['isGenerated']),
    classes() {
      const {name} = this.$options;
      return {
        [`${name}_loading`]: this.loading
      };
    },
    computedIconSize() {
      if (this.isMediumUp) {
        return 'medium';
      }
      return 'small';
    },
    hideNewVersionPopup() {
      if (!this.closedTime) {
        return false;
      }
      if ((new Date().getTime() - new Date(this.closedTime).getTime()) < 86400000) {
        return true;
      }
      return false;
    }
  },
  methods: {
    dateFormat(date) {
      let timeFormat = 'TTTT';

      if (!this.get.enable_military_time_format.value) {
        timeFormat = 'hh:mm:ss a ZZZZ';
      }
      const locale = localStorage.getItem('locale') || 'en-US';

      return DateTime.fromISO(date, {
        locale
      }).toFormat(locale === 'fr' ? `DD à ${timeFormat}` : `DD, ${timeFormat}`);
    },
    disableNewVersion() {
      const closedTime = useLocalStorage('closeNewVersionPopup', true)
      closedTime.value = new Date().toISOString();
    },
    handleScroll() {
      this.showStickyToolbar =
        this.$refs.toolbar && Number(this.$refs.toolbar.clientHeight) < Number(this.$refs.page.scrollTop);
    }
  },
  data() {
    return {
      closedTime: useLocalStorage('closeNewVersionPopup', null),
      showConfig: false,
      handleDebouncedScroll: null,
      showStickyToolbar: false,
      isNext: useLocalStorage('isNext', false)
    };
  },
  created() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 50);
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

// header
$page__header-height: rem-calc(66);
$page__header-background-color: white;
$page__header-shadow-color: rgba(black, 0.03);
$page__header-z: 3;

// title
$page__title-font-size: rem-calc(24);
$page__title-font-weight: $font-medium;

.newversionpopover {
  position: absolute;
  top: 50px;
  right: 50px;
}

.el-popover {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #00000029;
  border-radius: 16px;
  padding: 4px;
  width: 260px !important;

  h5 {
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0px;
    color: #404040;
    margin: 14px 0 3px 0;
    padding: 0 12px 0 12px;
  }

  h6 {
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: #808080;
    margin: 0 0 6px 0;
    padding: 0 12px 0 12px;
  }
}

.versionSwitch {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  flex-direction: row;

  p {

    flex-wrap: nowrap;
    display: flex;
    flex: 1 1 auto;
    white-space: nowrap;
    padding-right: 1rem;
  }
}

.configModal {
  justify-content: end !important;
  right: 0px !important;
}

.configModal .vel-modal {
  max-width: 400px !important;
}

.page {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  min-width: 1px;
  overflow-y: auto;

  .vel-page {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    min-width: 1px;
    position: relative;

    .vel-page__toolbar {
      margin-bottom: 1.25rem;
    }

    &_loading {
      cursor: progress;
    }

    &__alert {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__toolbar_menu {
      //      display: flex;
      //      flex-direction: column;
      margin-bottom: rem-calc(15);
      margin-top: rem-calc(-15);
      width: 100%;

      & > :first-child {
        margin-left: auto;
      }

      @include breakpoint(medium) {
        flex-direction: row;

        & > :not(:first-child) {
          margin-left: rem-calc(4);
        }

        & > :not(:last-child) {
          margin-right: rem-calc(4);
        }
      }
    }

    &__toolbar {
      //      display: flex;

      > div > div {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include breakpoint(medium) {
          flex-direction: row;

          & > :not(:first-child) {
            margin-left: rem-calc(4);
          }

          & > :not(:last-child) {
            margin-right: rem-calc(4);
          }
        }
      }
    }

    &__header {
      align-items: center;
      background-color: $page__header-background-color;
      box-shadow: 0 2px 2px $page__header-shadow-color, 0 1px 0 $page__header-shadow-color;
      display: flex;
      flex-direction: row;
      justify-content: center;
      min-height: $page__header-height;
      padding: 0.8rem rem-calc(24);
      width: 100%;
      z-index: $page__header-z;
    }

    &__title {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-size: $page__title-font-size;
      font-weight: $page__title-font-weight;
      justify-content: space-between;
      user-select: none;
      width: auto;
    }

    &__breadcrumb {
      align-items: center;
      display: flex;
      flex-direction: row;
      min-height: $page__header-height;
      padding: 0 rem-calc(24);

      + .vel-page__content {
        padding-top: 0;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      overflow-y: scroll;
      padding: rem-calc(24) rem-calc(24) rem-calc(24) rem-calc(24);
    }

    &__loader {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.toolbar {
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(medium) {
      flex-direction: row;

      & > :not(:first-child) {
        margin-left: rem-calc(4);
      }

      & > :not(:last-child) {
        margin-right: rem-calc(4);
      }
    }
  }
}

.footer {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 100%;

  div:last-child {
    align-self: flex-end;
    margin-left: auto;
  }

  @include breakpoint(medium) {
    flex-direction: row;
    text-align: center;
  }
}

.invisible {
  visibility: hidden;
}

footer {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0.08), 0 -1px 8px 0 rgba(0, 0, 0, 0.06), 0 -3px 3px -2px rgba(0, 0, 0, 0.12);
  z-index: 10;
}
</style>
