import { defineStore } from 'pinia';

export const useUIBreadcrumbsStore = defineStore('breadcrumbs', {
  state: () => ({
    breadcrumbs: []
  }),
  getters: {
    isTest: state => state.breadcrumbs
  },
  actions: {
    updateFromCurrentRoute(currentRoute) {
      const breadcrumbs = (currentRoute.matched || [])
        .filter(route => !route.meta.isDefault && route.name !== 'home' && route.name !== 'backOffice')
        .map(route => {
          let path = route.path;

          for (const [param, value] of Object.entries(currentRoute.params)) {
            path = path.replace(`:${param}`, value);
          }
          return { name: route.name, path };
        });
      this.breadcrumbs = breadcrumbs;
    },
    updateBreadcrumbs(breadcrumbs) {
      this.breadcrumbs = breadcrumbs;
    },
    addBreadcrumb(breadcrumb) {
      this.breadcrumbs = [...this.breadcrumbs, breadcrumb];
    },
    replaceLastBreadcrumb(breadcrumb) {
      this.breadcrumbs.pop();
      this.breadcrumbs = [...this.breadcrumbs, breadcrumb];
    }
  }
});
