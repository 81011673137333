import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import elements from '@/configs/elements.yaml';
import configService from '@/services/config.service';

export const useDataConfig = defineStore('config', {
  state: () => ({
    config: {},
    selectedKey: '',
    pending: true,
    error: null
  }),
  getters: {
    getError: state => state.error,
    getPending: state => state.pending,
    get: state => {
      const config = {};

      Object.keys(elements).forEach(e => {
        const defaultValue = elements[e].default !== undefined ? elements[e].default : true;
        config[e] =
          state.config[e] === undefined
            ? { value: defaultValue, order: 0 }
            : typeof state.config[e] === 'object'
            ? state.config[e]
            : { value: state.config[e] || true, order: 0 };
      });

      return config;
    },
    getSelectedPath: state => state.config
  },
  actions: {
    getConfig() {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.getConfigRequest();
      configService.getConfig(getToken(), currentUser.id).then(
        res => {
          localStorage.setItem('config', JSON.stringify(res));
          this.getConfigSuccess(res);
        },
        error => {
          this.getConfigFailure(error);
        }
      );
    },
    patchConfig({ config, reset }: any) {
      const currentUser = JSON.parse(localStorage.getItem('user'));

      this.patchConfigRequest(reset);

      configService.setConfig(getToken(), currentUser.id, config).then(
        res => {
          localStorage.setItem('config', JSON.stringify(res));
          this.patchConfigSuccess(res);
        },
        error => {
          this.patchConfigFailure(error);
        }
      );
    },
    getConfigRequest() {
      this.pending = true;
      this.error = null;
    },
    getConfigSuccess(config) {
      this.config = config || {};
      this.pending = false;
      this.error = null;
    },
    getConfigFailure(error) {
      this.pending = false;
      this.error = error;
    },
    setActiveKey(key) {
      this.pending = false;
      this.selectedKey = key;
    },
    patchConfigRequest(isReset = false) {
      this.pending = !!isReset;
      this.error = null;
    },
    patchConfigSuccess(config) {
      this.config = config || {};
      this.pending = false;
      this.error = null;
    },
    patchConfigFailure(error) {
      this.pending = false;
      this.error = error;
    }
  }
});
