<template>
  <div class="resellers-list">
    <vel-table
      :rows="data"
      defaultDirection="desc"
      defaultSort="locations"
      :columns="columns"
      filterable
      :filterValue="filterValue"
      :filterPlaceholder="$t('defaults.placeholder.filter')"
      @filter-change="filterCallback"
      :showRowsLength="true"
      :initialLength="initialLength"
    >
      <template v-slot:title>{{ $('resellersList.table.title') }}</template>
      <template v-slot:actions-column="scope">
        <vel-button type="link" icon="edit" @click="handleGoToReseller(scope.row.id)" />
      </template>
    </vel-table>
  </div>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelButton from '@/components/button/VelButton';
import VelTable from '@/components/table/VelTable';

export default {
  name: 'resellers-list',
  components: {
    VelButton,
    VelTable
  },
  mixins: [DeviceMixin],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    filterValue: {
      type: String,
      default: ''
    },
    initialLength: {
      type: Number,
      default: 0
    },
    filterCallback: {
      type: Function,
      default: () => null
    }
  },
  computed: {
    columns() {
      return {
        name: {
          title: this.$t('resellersList.table.columns.name'),
          sortable: true,
          width: this.isMediumDown ? '200px' : 'auto'
        },
        locations: {
          title: this.$t('resellersList.table.columns.linkedLocationsCount'),
          sortable: true,
          width: this.isMediumDown ? '200px' : 'auto'
        },
        actions: {
          title: this.$t('resellersList.table.columns.actions'),
          width: '80px'
        }
      };
    }
  },
  methods: {
    handleGoToReseller(id) {
      this.$router.push({
        name: 'reseller',
        params: { resellerID: id }
      });
    }
  },
  mounted() {
    console.log(this.data);
  }
};
</script>
