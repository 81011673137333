import Vue from 'vue';
import VueI18n from 'vue-i18n';
import elementEnLocale from 'element-ui/lib/locale/lang/en';
import elementFrLocale from 'element-ui/lib/locale/lang/fr';

Vue.use(VueI18n);

function loadLocaleMessages() {
  if (process.env.NODE_ENV === 'test') {
    return {};
  }
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/iu);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./iu);
    if (matched && matched.length > 1) {
      // eslint-disable-next-line prefer-destructuring
      const locale = matched[1];
      const elementLocale = locale === 'fr' ? elementFrLocale : elementEnLocale;
      messages[locale] = {
        ...locales(key),
        ...elementLocale
      };
    }
  });

  return messages;
}

export default new VueI18n({
  locale: window.localStorage.lang || navigator.language || process.env.VUE_APP_I18N_LOCALE || 'fr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
});
