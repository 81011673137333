<template>
  <b-table
    thead-class="bg-info"
    striped
    small
    hover
    bordered
    show-empty
    fixed
    primary-key="id"
    :items="tipDistributions"
    :fields="fields"
    responsive="sm"
    ref="table"
    :empty-text="$t('tipDistribution.empty')"
    :empty-filtered-text="$t('tipDistribution.empty')"
    class="mt-4"
    style="min-width: 500px;"
  >
    <template #empty="scope">
      <div class="p-2">{{ scope && scope.emptyText }}</div>
    </template>
    <template #emptyfiltered="scope">
      <div class="p-2">{{ scope && scope.emptyFilteredText }}</div>
    </template>
    <template #cell(edit)="scope">
      <b-button variant="link" @click="$emit('onModify', scope)" v-b-toggle.tipDistributionEdit-sidebar>
        <b-icon icon="pencil-fill" aria-label="Edit" class="row-action text-primary"></b-icon>
        {{ $t('tipDistribution.edit') }}
      </b-button>
    </template>
    <template #cell(delete)="scope">
      <b-button variant="link text-danger" @click="$emit('onDelete', scope.item)">
        <b-icon icon="trash-fill" aria-label="Delete" class="row-action text-danger"></b-icon>
        {{ $t('mealPlan.delete') }}
      </b-button>
    </template>
  </b-table>
</template>

<script>
import { postEmployeeTask } from '@/services/employees.service';

export default {
  name: 'tip-distribution-list',
  model: {},
  props: {
    tipDistributions: {
      type: Array,
      default: () => []
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      roleName: ''
    };
  },
  watch: {
    tipDistribution(value) {
      this.roleName = (value && value.name) || '';
    },
    tipName(value) {
      this.$emit('change', value);
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('tipDistribution.list.table.headers.name'),
          class: 'name vertical-align-center'
        },
        {
          key: 'edit',
          label: '',
          class: 'edit'
        },
        {
          key: 'delete',
          label: '',
          class: 'edit'
        }
      ];
    },
    tipDistribution() {
      return { id: this.id, name: this.name, licenceNumber: this.licenceNumber };
    },
    isEdit() {
      return !!this.jobRole;
    }
  },
  methods: {
    async addRole() {
      try {
        this.$emit('update:pending', true);
        await postEmployeeTask(this.tipDistribution);
        this.$emit('update:pending', false);
        this.$emit('success', this.tipDistribution);
        this.roleName = '';
      } catch (e) {
        this.$emit('update:error', true);
        this.$emit('failure', this.tipDistribution);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit {
  width: 115px;
}
</style>
