<template>
  <div class="bootstrap">
    <div :class="'align_' + position">
      <vel-group-selector
        allowGlobalGroupOption
        class="toolbar__group-selector"
        :allGroupsLabel="$t('dashboard.groupSelector.allGroupsText')"
        :groupId="groupId"
        :groupsOptions="groups"
        :disabled="getLocationsIsLoading"
        :multiple="false"
        autofocus
        filterable
        :showGlobalGroupOptionGroupLabel="false"
        @change="onGroupChange"
        v-if="isGroupSelectable"
      ></vel-group-selector>
      <vel-currency-selector
        :allowGlobalCurrencyOption="enableAllCurrencies"
        :select-first="!locationId"
        class="toolbar__currency-selector"
        :disabled="getLocationsIsLoading || isCurrencyDisabled"
        :currencyId="getCurrencySelectorSelected"
        :currenciesOptions="getLocationsCurrenciesBySelectedGroup"
        :showGlobalCurrencyOptionGroupLabel="false"
        v-if="isCurrencySelectable"
        @change="onCurrencyChange"
      ></vel-currency-selector>
      <vel-location-selector
        v-if="!multipleLocations"
        :allowGlobalLocationOption="locations.length > 1 && enableAllLocations"
        class="toolbar__location-selector"
        :allLocationsLabel="$t('dashboard.locationSelector.allLocationsText')"
        :disabled="getLocationsIsLoading"
        :locationId="locationId"
        :locationsOptions="locationsBySelectedGroup"
        :showGlobalLocationOptionGroupLabel="false"
        @change="onLocationChange"
      ></vel-location-selector>
      <multiselect
        v-if="multipleLocations"
        :options="getLocationsWithVerticalOrder"
        :multiple="true"
        :showLabels="false"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        :value="getLocationIds"
        placeholder=""
        label="label"
        :options-limit="100000"
        track-by="value"
        :max-height="600"
        style="max-width: 300px;"
        :class="getClassByLocationCount"
        :disabled="getLocationsIsLoading"
        ref="test"
        @input="onLocationMultipleChange"
      >
        <template slot="noResult">
          {{ $t('dashboard.locationSelector.noResult') }}
        </template>
        <template slot="beforeList">
          <div style="display: flex; flex-direction: row; justify-items: center; padding: 9px 16px; width: 100%;">
            <img
              style="cursor: pointer; width: 28px; height: 16px; margin-right: 0.5rem;"
              :src="enableMultiSelection ? '/toggle-on.png' : '/toggle-off.png'"
              alt=""
              @click="onToggleMultiSelection(!enableMultiSelection)"
            />
            {{ $t('dashboard.locationSelector.multi') }}
          </div>
          <div
            v-if="locationsBySelectedGroup.length > 1"
            class="bootstrap allloc"
            style="display: flex; flex-direction: row; padding: 9px 16px; width: 100%;"
          >
            <div v-if="!(getLocationIds.length === 0)" style="width: 120px;">
              <span @click="onLocationMultipleChange([])">
                {{ $t('dashboard.locationSelector.allLocationsTextLabel') }}
              </span>
            </div>
            <div v-if="getLocationIds.length === 0" style="font-weight: bold; width: 120px;">
              <span @click="onLocationMultipleChange([])">
                {{ $t('dashboard.locationSelector.allLocationsTextLabel') }}
              </span>
            </div>
            <div v-if="enableMultiSelection" style="flex-grow: 1; padding-left: 2rem; text-align: right; width: auto;">
              <div style="text-align: right; width: 100%;">
                <span
                  style="color: #00a7e1; cursor: pointer; white-space: nowrap;"
                  @click="
                    onLocationMultipleChange(
                      getLocationIds.length !== locationsBySelectedGroup.length ? locationsBySelectedGroup : []
                    )
                  "
                >
                  {{ $t('dashboard.locationSelector.selectAll') }}
                </span>
                <span
                  v-if="getLocationIds.length > 0"
                  style="color: #00a7e1; cursor: pointer; padding-left: 1rem; white-space: nowrap;"
                  @click="onLocationMultipleChange([])"
                >
                  {{ $t('dashboard.locationSelector.unselectAll') }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">{{ props.option.label || '---' }}</span>
          </div>
        </template>
        <template slot="selection" slot-scope="{ values, search, isOpen }">
          <span
            class="multiselect__single"
            v-if="locationsBySelectedGroup.length > 1 && values.length === 0 && !isOpen"
          >
            {{ $t('dashboard.locationSelector.allLocationsText') }}
          </span>
          <span
            class="multiselect__single"
            v-if="locationsBySelectedGroup.length > 1 && values.length && values.length > 1 && !isOpen"
          >
            {{
              $t('dashboard.locationSelector.locationsSelected', {
                count: values.length || 0,
                total: locationsBySelectedGroup.length || 0
              })
            }}
          </span>
          <span
            class="multiselect__single"
            style="overflow: hidden; text-overflow: fade(5%); text-wrap: none; white-space: nowrap;"
            v-if="!isOpen && (locationsBySelectedGroup.length === 1 || (values.length && values.length == 1))"
          >
            {{ (getSelectedLocation && (getSelectedLocation.reportingDisplayName || getSelectedLocation.name)) }}
          </span>
          <div></div>
        </template>
      </multiselect>
      <div v-if="!enableDateRange">
        <vel-datepicker
          class="toolbar__date-selector"
          :disabled="getLocationsIsLoading"
          :editable="isDateEditable"
          :date="selectedDate"
          v-model="selectedDate"
          align="center"
          type="date"
          :clearable="false"
          :format="getDateFormat"
          v-if="enableDatePicker"
        ></vel-datepicker>
        <vel-date-compare-selector
          v-can:show="'enable_period_selector'"
          v-if="enableDateCompare"
          :selected="getDateCompareSelectorSelected"
          :disabled="getLocationsIsLoading"
          :format="getDateFormat"
          @change="onComparatorChange"
        ></vel-date-compare-selector>
      </div>
      <div v-else>
        <date-range-selector
          class="report__date-range-selector"
          :range="selectedDateRange"
          :disabled="getLocationsIsLoading"
          :format="getDateFormat"
          @change="onDateRangeChange"
        ></date-range-selector>
        <vel-date-compare-selector
          v-can:show="'enable_period_selector'"
          v-if="enableDateCompare"
          :selected="getDateCompareSelectorSelected"
          :disabled="getLocationsIsLoading"
          :format="getDateFormat"
          @change="onComparatorChange"
        ></vel-date-compare-selector>
      </div>
      <div>
        <slot></slot>
      </div>
      <span
        v-b-tooltip.top.hover
        :title="getLocationsIsLoading || isGenerated ? $t('toolbar.selectors.generateLabelTooltip') : ''"
        class="generate d-flex"
      >
        <vel-button
          :disabled="getLocationsIsLoading || isGenerated"
          v-if="enableButton && (isUserAdmin || this.enableButtonOnSingleSelection || this.enableMultiSelection)"
          class="generate d-flex"
          @click="generate()"
        >
          {{ $t('toolbar.selectors.generateLabel') }}
        </vel-button>
      </span>
      <div
        @click="showLocations = !showLocations"
        v-if="
          isGenerated &&
          multipleLocations &&
          (this.enableButtonOnSingleSelection || this.enableMultiSelection) &&
          this.enableMultiSelection &&
          enableButton &&
          (locationsBySelectedGroup || []).filter(x => getLocationIds.map(x => x.value).includes(x.value)).length > 0
        "
        style="align-self: center; color: #00a7e1; cursor: pointer; margin-left: 2rem; vertical-align: middle;"
      >
        {{
          $t(showLocations ? 'toolbar.selectors.hideLocation' : 'toolbar.selectors.showLocation', {
            count: (locationsBySelectedGroup || []).filter(x => getLocationIds.map(x => x.value).includes(x.value))
              .length
          })
        }}
      </div>
    </div>
    <div
      v-if="multipleLocations && showLocations"
      :class="{ generated: isGenerated, tagsList: true }"
      style="flex-wrap: wrap;"
    >
      <el-tag
        v-for="tag in (locationsBySelectedGroup || []).filter(x => getLocationIds.map(x => x.value).includes(x.value))"
        :key="tag.value"
        type="primary"
        closable
        style="margin-top: 1rem;"
        @close="
          getLocationIds.splice(getLocationIds.map(x => x.value).indexOf(tag.value), 1);
          onLocationMultipleChange(getLocationIds);
        "
      >
        {{ tag.label }}
      </el-tag>
    </div>
  </div>
</template>

<script>
import DateRangeSelector from '@/private/components/date-range-selector/DateRangeSelector.vue';
import { DateTime } from 'luxon';
import Multiselect from 'vue-multiselect';
import VelButton from '@/components/button/VelButton';
import VelCurrencySelector from '@/components/currency-selector/VelCurrencySelector';
import VelDateCompareSelector from '@/components/date-compare-selector/VelDateCompareSelector';
import VelDatepicker from '@/components/datepicker/VelDatepicker';
import VelGroupSelector from '@/components/group-selector/VelGroupSelector';
import VelLocationSelector from '@/components/location-selector/VelLocationSelector';
import Vue from 'vue';
import chunk from 'lodash.chunk';
import { environment } from '@/configs';
import { getDateFormatFromUserConfig } from '@/helpers/date.helpers';
import router from '@/router';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { useUICurrencySelector } from '@/stores/ui/currency-selector.module';
import { useUIDateSelector } from '@/stores/ui/date-selector.module';
import { useUIDateRangeSelector } from '@/stores/ui/date-range-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { useUIDateCompare } from '@/stores/ui/date-compare.module';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { storeToRefs } from 'pinia';
import { useDataSalesIndicators } from '@/stores/data/sales/indicators.module';
import { useDataUsersUserPermissions } from '@/stores/data/users/user-permissions.module';
import { useDataGroups } from '@/stores/data/groups.module';
import { useMultiSelectorStore } from '@/stores/ui/multi-selector.store';
import { useUserSettingsStore } from '@/stores/data/users/settings.store';

// register globally
Vue.component('multiselect', Multiselect);

export default {
  name: 'page__toolbar',
  props: {
    enableDateRange: {
      type: Boolean,
      default: false
    },
    multipleLocations: {
      type: Boolean,
      default: false
    },
    enableDateCompare: {
      type: Boolean,
      default: false
    },
    enableButton: {
      type: Boolean,
      default: false
    },
    enableButtonOnSingleSelection: {
      type: Boolean,
      default: true
    },
    enableGroup: {
      type: Boolean,
      default: true
    },
    enableDatePicker: {
      type: Boolean,
      default: true
    },
    enableAllLocations: {
      type: Boolean,
      default: true
    },
    enableAllCurrencies: {
      type: Boolean,
      default: false
    },
    enableCurrency: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'left'
    },
    disabledSelectors: {
      type: [Array, String],
      default: () => []
    }
  },
  components: {
    VelButton,
    VelCurrencySelector,
    VelDatepicker,
    VelLocationSelector,
    VelGroupSelector,
    DateRangeSelector,
    VelDateCompareSelector,
    Multiselect
  },
  setup(props) {
    const uiGroupSelectorStore = useUIGroupSelector();
    const uiDateSelectorStore = useUIDateSelector();
    const uiCurrencySelectorStore = useUICurrencySelector();
    const uiDateCompareStore = useUIDateCompare();
    const uiLocationSelectorStore = useUILocationSelector();
    const uiDateRangeSelectorStore = useUIDateRangeSelector();
    const dataLocationsStore = useDataLocationsStore();
    const dataIndicatorsStore = useDataSalesIndicators();

    const {
      getLocationsIsLoading,
      locations,
      locationsBySelectedGroup,
      getLocationsAll,
      getLocationsCurrenciesBySelectedGroup,
      getLocationsCurrencies
    } = storeToRefs(dataLocationsStore);
    const { getGroupSelectorSelected } = storeToRefs(uiGroupSelectorStore);
    const {
      hasError,
      isLoading,
      error,
      getLocationSelectorSelected,
      getLocationSelectorSelectedMultiple,
      isGenerated,
      getSelectedLocation
    } = storeToRefs(uiLocationSelectorStore);
    const { getDateSelectorSelectedRange } = storeToRefs(uiDateRangeSelectorStore);
    const { getDateSelectorSelected, selected } = storeToRefs(uiDateSelectorStore);
    const { getCurrencySelectorSelected } = storeToRefs(uiCurrencySelectorStore);
    const { getDateCompareSelectorSelected } = storeToRefs(uiDateCompareStore);
    const userSettingsStore = useUserSettingsStore();

    const multiSelectionStore = useMultiSelectorStore();

    const { enableMultiSelection } = storeToRefs(multiSelectionStore);

    return {
      enableMultiSelection,
      isLoadingGroups: uiGroupSelectorStore.isLoading,
      getDateCompareSelectorSelected,
      getCurrencySelectorSelected,
      getSelectedLocation,
      isGenerated,
      locationIds: getLocationSelectorSelectedMultiple,
      locationId: getLocationSelectorSelected,
      hasLocationsError: hasError,
      locationsLoading: isLoading,
      locationError: error,
      dateRange: getDateSelectorSelectedRange,
      date: getDateSelectorSelected,
      groupId: getGroupSelectorSelected,
      getLocationsIsLoading,
      locations,
      locationsBySelectedGroup: !props.enableCurrency && !props.enableGroup ? locations : locationsBySelectedGroup,
      getLocationsCurrenciesBySelectedGroup,
      getLocationsCurrencies,
      onDateSelectorChange: uiDateSelectorStore.onChange,
      onGroupSelectorChange: uiGroupSelectorStore.onChange,
      dateSelector: selected,
      reset: dataIndicatorsStore.reset,
      uiGroupSelectorStore,
      getLocationsByGroupId: dataLocationsStore.getLocationsByGroupId,
      userSettingsStore
    };
  },
  computed: {
    ...mapPiniaState(useDataGroups, {
      groups: 'getGroups'
    }),
    ...mapPiniaState(useDataSalesIndicators, ['getIndicatorsError']),
    ...mapPiniaState(useDataUsersUserPermissions, ['isUserAdmin']),
    getLocationIds() {
      return this.enableMultiSelection
        ? this.locationIds || []
        : (this.locationIds && this.locationIds[0] && [this.locationIds[0]]) || [];
    },
    getDateFormat() {
      return getDateFormatFromUserConfig();
    },
    getLocationsWithVerticalOrder() {
      const locationList = this.locationsBySelectedGroup;

      if (this.getClassByLocationCount.largeMenu) {
        const newLocationList = [];
        const chunks = chunk(locationList, Math.ceil(locationList.length / 2));

        for (let i = 0; i < chunks[0].length; i++) {
          newLocationList.push(chunks[0][i]);
          if (chunks[1][i]) {
            newLocationList.push(chunks[1][i]);
          }
        }
        return newLocationList;
      }

      if (this.getClassByLocationCount.xlargeMenu) {
        const newLocationList = [];
        const chunks = chunk(locationList, Math.ceil(locationList.length / 3));

        for (let i = 0; i < chunks[0].length; i++) {
          newLocationList.push(chunks[0][i]);
          if (chunks[1][i]) {
            newLocationList.push(chunks[1][i]);
          }
          if (chunks[2][i]) {
            newLocationList.push(chunks[2][i]);
          }
        }
        return newLocationList;
      }

      return locationList;
    },
    getClassByLocationCount() {
      const elementsCount = (this.locationsBySelectedGroup || []).length;

      if (elementsCount < 15) {
        return { smallMenu: true };
      }
      if (elementsCount < 30) {
        return { mediumMenu: true };
      }
      if (elementsCount < 45) {
        return { largeMenu: true };
      }
      if (elementsCount < 60) {
        return { xlargeMenu: true };
      }

      return { xlargeMenu: true };
    },
    selectedDate: {
      get() {
        return this.date;
      },
      set(value) {
        this.onDateChange(value);
      }
    },
    selectedDateRange: {
      get() {
        return this.dateRange;
      },
      set(value) {
        this.onDateRangeChange(value);
      }
    },
    config() {
      return environment;
    },
    isCurrencySelectable() {
      return (
        this.enableCurrency &&
        ((this.getLocationsCurrenciesBySelectedGroup || []).length > 1 ||
          (this.getLocationsCurrencies || []).length > 1)
      );
    },
    isCurrencyDisabled() {
      return (this.getLocationsCurrenciesBySelectedGroup || []).length === 1;
    },
    isGroupSelectable() {
      return (this.groups || []).length > 1 && this.enableGroup && !this.disabledSelectors.includes('group');
    },
    isDateEditable() {
      return !this.isMediumDown;
    }
  },
  data() {
    return {
      from: null,
      to: null,
      options: ['list', 'of', 'options'],
      value: [],
      showLocations: false
    };
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    ...mapPiniaState(useUILocationSelector, ['isLoading']),
    ...mapPiniaActions(useUIGroupSelector, ['onChange']),

    ...mapPiniaActions(useMultiSelectorStore, ['toggleMultiSelection', 'hydrate']),
    ...mapPiniaActions(useUICurrencySelector, {
      onCurrencySelectorChange: 'onChange'
    }),

    ...mapPiniaActions(useUIDateCompare, {
      onDateCompareChange: 'onChange'
    }),
    ...mapPiniaActions(useUIDateRangeSelector, {
      onDateRangSelectoreChange: 'onChange'
    }),
    ...mapPiniaActions(useUILocationSelector, {
      onLocationSelectorChange: 'onChange',
      onChangeMultiple: 'onChangeMultiple'
    }),
    onGroupChange(groupId) {
      this.$emit('onGroupChange', groupId);
      let groupLocations = [];
      let groupCurrency = null;

      groupLocations = this.getLocationsByGroupId(groupId);

      if (groupLocations.length) {
        groupCurrency = groupLocations[0].detailedCurrency && groupLocations[0].detailedCurrency.currencyCode;
        console.log('onGroupChange');
        this.onCurrencyChange(groupCurrency);
      }

      this.onGroupSelectorChange({
        groupId,
        locationId: null,
        locationIds: null,
        currencyId: groupCurrency || this.getCurrencySelectorSelected,
        disableRouteChange:
          this.enableButton && (this.isUserAdmin || this.enableButtonOnSingleSelection || this.enableMultiSelection)
      });

      if (this.locationsBySelectedGroup.length === 1) {
        this.onLocationMultipleChange([this.locationsBySelectedGroup[0]]);
      }

      this.gen(false);
    },
    onLocationChange(locationId) {
      this.$emit('onLocationChange', locationId);

      this.onLocationSelectorChange({
        locationId,
        groupId: this.groupId,
        disableRouteChange:
          this.enableButton && (this.isUserAdmin || this.enableButtonOnSingleSelection || this.enableMultiSelection)
      });
      this.gen(false);
    },
    onToggleMultiSelection($event) {
      if (!$event && this.locationIds.length > 1) {
        this.onLocationMultipleChange([this.locationIds[0]]);
        this.generate(true);
        this.gen(true);
      }

      this.toggleMultiSelection($event);
    },
    onLocationMultipleChange(selectedValues) {
      let value = selectedValues;
      if (!this.enableMultiSelection && selectedValues.length) {
        value = [selectedValues.at(-1)];
      }

      let values = (value || []).length ? value : [];

      if (values.length > 1) {
        values = values.filter(x => x.value !== '');
      }

      const lastSelectedLocation = typeof value === 'object' ? 0 : value[value.length - 1];

      if (value.length && lastSelectedLocation.value === '') {
        values = [];
      }

      const locationIds = values.map(x => x.value);

      this.$emit('onLocationMultipleChange', locationIds);

      this.onChangeMultiple({
        value: values,
        groupId: this.groupId,
        locationIds,
        disableRouteChange:
          this.enableButton && (this.isUserAdmin || this.enableButtonOnSingleSelection || this.enableMultiSelection)
      });
      this.gen(false);
    },
    onComparatorChange(dateCompare) {
      this.$emit('onComparatorChange', dateCompare);
      this.onDateCompareChange({
        dateCompare,
        disableRouteChange:
          this.enableButton && (this.isUserAdmin || this.enableButtonOnSingleSelection || this.enableMultiSelection)
      });
      this.gen(false);
    },
    onDateChange(date) {
      this.$emit('onDateChange', DateTime.fromJSDate(date).toISO());

      this.from = DateTime.fromJSDate(date).toISO();
      this.to = DateTime.fromJSDate(date).toISO();

      this.onDateSelectorChange({
        date: DateTime.fromJSDate(date).toISO(),
        disableRouteChange:
          this.enableButton && (this.isUserAdmin || this.enableButtonOnSingleSelection || this.enableMultiSelection)
      });
      this.gen(false);
    },
    onDateRangeChange(date) {
      if (
        this.selectedDateRange[0] === DateTime.fromJSDate(date[0]).toISO() &&
        this.selectedDateRange[1] === DateTime.fromJSDate(date[1]).toISO()
      ) {
        return;
      }
      this.$emit('onDateRangeChange', [DateTime.fromJSDate(date[0]).toISO(), DateTime.fromJSDate(date[1]).toISO()]);
      this.onDateRangSelectoreChange({
        range: [DateTime.fromJSDate(date[0]).toISO(), DateTime.fromJSDate(date[1]).toISO()],
        disableRouteChange:
          this.enableButton && (this.isUserAdmin || this.enableButtonOnSingleSelection || this.enableMultiSelection)
      });

      this.from = DateTime.fromJSDate(date[0]).toISO();
      this.to = DateTime.fromJSDate(date[1]).toISO();

      this.gen(false);
    },
    onCurrencyChange(currencyId) {
      console.log('----', currencyId);
      this.onLocationMultipleChange([]);

      this.onCurrencySelectorChange({
        currencyId,
        groupId: this.groupId,
        locationId: null,
        locationIds: null,
        disableRouteChange:
          this.enableButton && (this.isUserAdmin || this.enableButtonOnSingleSelection || this.enableMultiSelection)
      });

      if (this.locationsBySelectedGroup.length === 1) {
        this.onLocationMultipleChange([this.locationsBySelectedGroup[0]]);
      }
      this.gen(false);
    },
    async generate(refreshUi = true) {
      try {
        this.$ga.event('report', 'generate', this.$route.name);

        this.reset();
        let groupLocations = [];
        let groupCurrency = null;

        if (this.uiGroupSelectorStore.selected) {
          groupLocations = this.getLocationsByGroupId(this.uiGroupSelectorStore.selected);

          if (groupLocations.length) {
            groupCurrency = groupLocations[0].detailedCurrency && groupLocations[0].detailedCurrency.currencyCode;
          }
        }

        const locationStore = useDataLocationsStore();
        const dateRangeSelector = useUIDateRangeSelector();

        await router.replace({
          name: router.currentRoute.name,
          query: {
            ...router.currentRoute.query,
            groupId: this.uiGroupSelectorStore.selected,
            currencyId: this.locationId
              ? locationStore.data.entities[this.locationId].detailedCurrency &&
                locationStore.data.entities[this.locationId].detailedCurrency.currencyCode
              : this.getCurrencySelectorSelected !== null
              ? this.getCurrencySelectorSelected
              : groupCurrency || null,
            ...(!this.multipleLocations ? { locationId: this.locationId } : { locationId: null }),
            locationIds: this.locationIds.map(x => x.value),
            from: this.enableDateRange ? dateRangeSelector.range[0] : this.dateSelector,
            to: this.enableDateRange ? dateRangeSelector.range[1] : this.dateSelector
          }
        });

        this.gen(refreshUi);
      } catch (e) {
        console.error(e);
        this.gen(refreshUi);
      }
    }
  },
  mounted() {
    this.hydrate();
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

/deep/ .tooltip-inner {
  background-color: #fff;
  border: 1px solid #6f6f6f;
  border-radius: 0.25rem;
  color: #666;
  font-size: 0.8rem;
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  text-align: center;
}

/* stylelint-disable */
/deep/ .vel-page__toolbar {
  margin-bottom: 1.25rem;
}

/deep/ .multiselect__input,
/deep/ .multiselect__single {
  color: #5f5f5f;
  font-size: 14px !important;
  white-space: nowrap;
}

/deep/ .multiselect__tags {
  border: 1px solid #ced4da !important;
  max-height: 2.375rem;
  min-height: 2.375rem;
}

/deep/ .multiselect__content-wrapper {
  border: 1px solid #ced4da;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-top: 12px;
  overflow: hidden auto;

  @include breakpoint(small) {
    width: 100%;
  }
  @include breakpoint(medium) {
    width: 100%;
  }
  @include breakpoint(large) {
    width: 200%;
  }
  @include breakpoint(xlarge) {
    width: 300%;
  }
  @include breakpoint(xxlarge) {
    width: 400%;
  }
}

/deep/ .multiselect__element {
  @include breakpoint(small) {
    width: 100%;
  }
  @include breakpoint(medium) {
    width: 100%;
  }
  @include breakpoint(large) {
    width: 50%;
  }
  @include breakpoint(xlarge) {
    width: 33.33333%;
  }
  @include breakpoint(xxlarge) {
    width: 25%;
  }
}

.smallMenu,
.mediumMenu {
  /deep/ .multiselect__content-wrapper {
    width: 100%;
  }

  /deep/ .multiselect__element {
    width: 100%;
  }

  .allloc {
    max-width: 290px !important;
  }
}

.generate {
  width: 100%;
  @include breakpoint(medium) {
    width: auto;
  }
}

.largeMenu {
  /deep/ .multiselect__content-wrapper {
    @include breakpoint(xlarge) {
      width: 200%;
    }
    @include breakpoint(xxlarge) {
      width: 200%;
    }
  }

  /deep/ .multiselect__element {
    @include breakpoint(xlarge) {
      width: 50%;
    }
    @include breakpoint(xxlarge) {
      width: 50%;
    }
  }
}

.xlargeMenu {
  /deep/ .multiselect__content-wrapper {
    @include breakpoint(xxlarge) {
      width: 300%;
    }
  }

  /deep/ .multiselect__element {
    @include breakpoint(xxlarge) {
      width: 33.33333%;
    }
  }
}

@include breakpoint(medium) {
  /deep/ .multiselect__element {
    display: inline-block;
    overflow: hidden;
  }
}

/deep/ .multiselect__option {
  border-left: 3px solid white;
  border-right: 3px solid white;
  font-family: Roboto, sans-serif;
  min-height: 32px;
  padding: 9px 16px;
}

/deep/ .multiselect__option--highlight {
  color: #444444;
  background-color: #00a7e115;
}

/deep/ .multiselect__option--selected {
  background-color: inherit;
}

/deep/ .el-tag {
  color: #444;
}

.toolbar__location-selector {
  border: none;
  margin-top: 4px;
  max-width: 100%;
  width: 100%;
  @include breakpoint(medium) {
    margin-top: 0;
    max-width: 380px;
    width: initial;
  }
}

.toolbar__currency-selector {
  border: none;
  margin-top: 4px;
  max-width: 100%;
  width: 100%;
  @include breakpoint(medium) {
    margin-top: 0;
    max-width: 150px;
    width: initial;
  }
}

.toolbar__group-selector {
  border: none;
  margin-top: 4px;
  max-width: 100%;
  width: 100%;
  @include breakpoint(medium) {
    margin-top: 0;
    max-width: 240px;
    width: initial;
  }
}

.multiselect {
  border: none;
  margin-top: 4px;
  max-width: 100% !important;
  width: 100%;

  @include breakpoint(medium) {
    margin-top: 0;
    max-width: 300px !important;
    width: 300px;
  }
}

.report__date-range-selector {
  :hover {
    cursor: pointer;
  }

  &.el-range-editor {
    border: 1px solid $link-water;
    height: 38px;
    margin-top: 4px;
    max-width: 100%;
    width: 100%;

    @include breakpoint(medium) {
      margin-top: 0;
      min-width: 240px;
      max-width: rem-calc(200);
      width: initial;
    }
  }
}

.el-dropdown {
  display: block;
  text-align: right;
  width: 100%;
}

.toolbar__date-selector {
  :hover {
    cursor: pointer;
  }

  &.el-date-editor.el-input {
    border: none;
    margin-top: 4px;
    max-width: 100%;
    width: 100%;
    @include breakpoint(medium) {
      margin-top: 0;
      max-width: rem-calc(150);
      width: initial;
    }

    /deep/ .el-icon-date {
      display: block !important;
    }

    /deep/ .el-input__inner {
      height: 38px;
      padding-left: 2.5em;
      padding-right: rem-calc(16);
      border: 1px solid $link-water;
    }
  }
}

/deep/ .multiselect__select {
  font-family: element-icons !important;
  font-size: 14px;
  line-height: 38px;
  top: 1px;
  transform: rotateZ(180deg);
  transition: transform 0.3s;
  width: 25px;
}

/deep/ .multiselect__select:before {
  border: 0 !important;
  bottom: 2px;
  color: #c0c4cc;
  content: '\e6e1' !important;
  margin-top: unset !important;
  right: unset !important;
  top: unset;
}

/deep/ .multiselect--disabled .multiselect__single,
/deep/ .multiselect--disabled .multiselect__tags {
  background-color: #f6f7fa !important;
  color: #c0c4cc !important;
}

/deep/ .multiselect--disabled .multiselect__select {
  background-color: transparent;
}

.align_right {
  justify-content: flex-end;
}

.align_left {
  justify-content: flex-start;
}

.align_center {
  justify-content: center;
}

.generate {
  margin-top: 4px;

  @include breakpoint(medium) {
    margin-top: 0;
  }
}

.tagsList.generated {
  max-height: 150px;
  overflow: hidden auto;
}

/deep/ .multiselect__option--selected {
  background-color: #00a7e1 !important;
  color: white !important;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
