import { DateTime } from 'luxon';
import XLSX from 'xlsx';
import { getDateFormatFromUserConfig } from '@/helpers/date.helpers';

export function formatRow(columns, row, isConsolidated = false) {
  const formattedRow = {};
  row.forEach(cell => {
    for (const column of columns) {
      if (column.key === cell.key) {
        const formatDate = cell.column && cell.column.formatDate;
        formattedRow[column.title] = formatDate instanceof Function ? formatDate(cell.value) : cell.value;
        break;
      }
    }
  });

  return formattedRow;
}

export default {
  methods: {
    exportToXLS({
      columns,
      rows,
      fileName = 'Dataset',
      xlsx = false,
      from = null,
      to = null,
      locationTitle = null,
      isConsolidated = false,
      locationsList = [],
      title = null,
      enableHeader = true,
      totalRow = [],
      i18n = {},
      filter = {}
    }: any = {}) {
      const extension = xlsx ? 'xlsx' : 'xls';
      const header = columns.map(a => ({ key: a.key, value: a.title }));

      const metasSpace = enableHeader ? [[], [], [], [], [], [], []] : [];

      const data = [...metasSpace, [...header], ...rows, ...totalRow].map(row =>
        formatRow(columns, row, isConsolidated)
      );

      if (isConsolidated && (locationsList || []).length > 1) {
        const firstColumnTitle = columns[0].title;
        data.push({ [firstColumnTitle]: '' });
        data.push({ [firstColumnTitle]: i18n.locationsList });

        locationsList.forEach(l => {
          data.push({ [firstColumnTitle]: l.label || l.reportingDisplayName || l.name });
        });
      }

      const workBook = XLSX.utils.book_new();
      const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: true });

      workBook.Props = {
        Title: fileName,
        CreatedDate: new Date()
      };

      if (title || (from && to)) {
        const diff = DateTime.fromISO(to).plus({ day: 1 }).diff(DateTime.fromISO(from), ['days', 'hours']).toObject();

        const dateRange = [
          [DateTime.fromISO(from).toFormat(getDateFormatFromUserConfig())],
          [i18n.to],
          [DateTime.fromISO(to).toFormat(getDateFormatFromUserConfig())],
          [diff.days],
          [i18n.day]
        ];

        if (enableHeader) {
          const ws_data = [
            [],
            [],
            [title.toUpperCase()],
            [locationTitle],
            [dateRange[0], dateRange[1], dateRange[2], dateRange[3], dateRange[4]]
          ];

          if (filter.value) {
            ws_data.push([`${filter.label}: ${filter.value}`]);
          }

          XLSX.utils.sheet_add_aoa(workSheet, ws_data);

          workSheet['!merges'] = [
            { s: { c: 0, r: 2 }, e: { c: 8, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 8, r: 3 } }
          ];
        }
      }

      XLSX.utils.book_append_sheet(workBook, workSheet, 'Dataset');
      workSheet['!cols'] = this.fitToColumn(
        columns.map(col => col.title),
        data
      ); // Will only work on XLSX

      XLSX.writeFile(workBook, `${fileName}.${extension}`);
    },
    fitToColumn(columnsHeader, dataArray) {
      return columnsHeader.map(title => ({
        wch: Math.max(
          title.toString().length + 8,
          ...dataArray.map(row => (row[title] && row[title].toString().length + 8) || 0)
        )
      }));
    }
  }
};
