export default {
  props: {
    onSubmit: {
      type: Function
    },
    fileFormDataName: {
      type: String,
      default() {
        return 'file';
      }
    },
    additionalData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      fileList: [],
      isFileListEmpty: true
    };
  },
  methods: {
    submit() {
      this.$refs.upload.submit();
    },
    submitUpload(uploadedData) {
      const { file } = uploadedData;
      const formData = new FormData();

      formData.append(this.fileFormDataName, file, file.name);

      this.additionalData.forEach(additionalFormData => {
        formData.append(additionalFormData.name, additionalFormData.value);
      });

      this.onSubmit(formData);
    },
    handleSelectFile(file) {
      this.fileList = [file];
    },
    handleExceed(file, list) {
      this.fileList = [
        {
          ...list[0],
          size: file[0].size,
          name: file[0].name,
          raw: file[0],
          status: 'ready'
        }
      ];
    },
    handleRemove() {
      this.fileList = [];
    }
  },
  watch: {
    fileList() {
      this.$emit('select', this.fileList);
    }
  }
};
