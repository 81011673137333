<template>
  <portal to="app">
    <vel-modal class="group-delete__modal" dismissable @dismiss="handleCancel">
      <template v-slot:header>
        {{ $t('location.groups.modals.delete.title') }}
      </template>
      <template v-slot:default>
        <vel-alert
          @dismiss="clearDeleteLocationFromGroupError"
          class="alert delete-group__error"
          level="error"
          v-if="deleteLocationFromGroupError"
        >
          {{ $t('group.errors.deleteLocation') }}
        </vel-alert>

        <span
          class="group-delete__confirmation"
          v-html="$t('location.groups.modals.delete.textHtml', { name: group.label })"
        />
      </template>
      <template v-slot:footer>
        <vel-button class="group-delete__close-button" type="secondary" @click="handleCancel">
          {{ $t('defaults.actions.close') }}
        </vel-button>
        <vel-button
          class="group-delete__confirm-button"
          type="critical"
          :loading="isSavingGroups"
          @click="handleDelete"
        >
          {{ $t('location.groups.modals.delete.button.text') }}
        </vel-button>
      </template>
    </vel-modal>
  </portal>
</template>

<script>
import VelAlert from '@/components/alert/VelAlert';
import VelButton from '@/components/button/VelButton';
import VelModal from '@/components/modal/VelModal';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataLocationsGroupsLocation } from '@/stores/data/locations/groups-location';

export default {
  name: 'delete-group-modal',
  components: {
    VelButton,
    VelModal,
    VelAlert
  },
  props: {
    locationId: {
      type: String,
      required: true
    },
    group: {
      type: Object,
      default: () => ({
        value: null,
        label: null
      })
    }
  },
  data() {
    return {
      currentGroup: { ...this.group }
    };
  },
  methods: {
    ...mapPiniaActions(useDataLocationsGroupsLocation, [
      'clearLatestDeletedLocationFromGroup',
      'deleteLocationFromGroup',
      'clearDeleteLocationFromGroupError'
    ]),
    handleCancel() {
      this.$emit('dismiss');
    },
    handleDelete() {
      this.deleteLocationFromGroup({ locationId: this.locationId, group: this.currentGroup });
    }
  },
  watch: {
    latestDeletedLocationFromGroup(deletedLocation) {
      if (deletedLocation && this.currentGroup.value === deletedLocation.value) {
        this.$emit('dismiss');
      }
    }
  },
  computed: {
    ...mapPiniaState(useDataLocationsGroupsLocation, [
      'deleteLocationFromGroupError',
      'latestDeletedLocationFromGroup',
      'isSavingGroups'
    ])
  },
  created() {
    this.clearLatestDeletedLocationFromGroup();
    this.clearDeleteLocationFromGroupError();
  }
};
</script>
<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.delete-group__error {
  margin-bottom: rem-calc(16);
}
</style>
