<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="600px"
    right
    id="tipDistribution-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarTipDistribution"
  >
    <template #header="{ hide }">
      <div class="bg-gray-200 bg-light w-100">
        <b-button variant="outline-primary" @click="hide">
          <b-icon icon="chevron-compact-left" aria-hidden="true"></b-icon>
          {{ $t('tipDistribution.back') }}
        </b-button>
      </div>
    </template>

    <template #footer="{ hide }">
      <div class="d-flex bg-light text-dark align-items-center px-3 py-2" style="border-top: 2px solid #d6d6d8;">
        <b-button variant="primary" @click="hide">{{ $t('tipDistribution.close') }}</b-button>
      </div>
    </template>
    <b-container fluid class="p-4" v-loading="pending">
      <h5 class="font-weight-bold">{{ $t('tipDistribution.list.title') }}</h5>
      <tip-distribution-form
        :licence-number="employee.licenceNumber"
        ref="form"
        @success="$emit('success', $event)"
      ></tip-distribution-form>
      <tip-distribution-list
        :tip-distributions="tipDistributions"
        @onModify="openEditTipDistribution($event)"
        @onDelete="$emit('onDelete', $event)"
      ></tip-distribution-list>
    </b-container>
  </b-sidebar>
</template>
<script>
import TipDistributionForm from '@/private/components/tip-distribution/components/TipDistributionForm';
import TipDistributionList from '@/private/components/tip-distribution/components/TipDistributionList';

export default {
  name: 'employee-sidebar-add-tip-distribution',
  components: {
    TipDistributionForm,
    TipDistributionList
  },
  props: {
    tipDistribution: {
      type: Object,
      default: () => ({ name: '' })
    },
    tipDistributions: {
      type: Array,
      default: () => []
    },
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  computed: {},
  methods: {
    openEditTipDistribution(tipDistribution) {
      this.$emit('update:tipDistribution', tipDistribution.item);
    }
  }
};
</script>
