<template>
  <vel-table
    class="sales"
    :defaultDirection="defaultDirection"
    :defaultSort="defaultSort"
    :rows="rows"
    :columns="columns"
    :loading="loading"
    :hoverable="hoverable"
    :exportable="true"
    :exportableMetas="exportableMetas"
    :filterable="filterable"
    :filterValue="filterValue"
    :filterPlaceholder="$t('defaults.placeholder.filter')"
    @filter-change="filterChangeHandler"
    @row="handleRow"
  >
    <template v-slot:salesPercent-column="scope">{{ scope.row.salesPercent }}%</template>
    <template v-slot:salesOverallPercent-column="scope">{{ scope.row.salesOverallPercent }}%</template>
    <template v-slot:salesAmount-column="scope">
      <vel-amount :amount="scope.row.salesAmount" :currency="scope.row.currency" />
    </template>
    <template v-slot:netSales-column="scope">
      <vel-amount :amount="scope.row.netSales" :currency="scope.row.currency" />
    </template>
    <template v-slot:discountsAmount-column="scope">
      <vel-amount :amount="scope.row.discountsAmount" :currency="scope.row.currency" />
    </template>

    <template v-slot:empty>
      {{ $t('sales.table.messages.noData') }}
    </template>
  </vel-table>
</template>

<script>
import VelAmount from '@/components/amount/VelAmount';
import VelTable from '@/components/table/VelTable';
import compareStringsInsensitive from '@/comparators/compare-strings-insensitive';
import formatQuantity from '@/filters/format-quantity';

export default {
  name: 'sales',
  components: {
    VelAmount,
    VelTable
  },
  props: {
    defaultSort: {
      type: String,
      default: 'name'
    },
    defaultDirection: {
      type: String,
      default: 'asc'
    },
    loading: {
      type: Boolean,
      default: false
    },
    hoverable: {
      type: Boolean,
      default: true
    },
    sales: {
      type: Array,
      default: () => []
    },
    hiddenColumns: {
      type: Object,
      default: null
    },
    exportableMetas: {
      type: Object,
      default: null
    },
    salesOverall: {
      type: Number,
      default: 0
    },
    showQuantity: {
      type: Boolean,
      default: true
    },
    filterable: {
      type: Boolean,
      default: false
    },
    filterValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    rows() {
      return this.sales.map(sale => {
        sale.formattedQuantity = formatQuantity(sale.quantity);
        sale.salesOverallPercent = ((sale.salesAmount / this.salesOverall) * 100).toFixed(2);

        return sale;
      });
    },
    columns() {
      return {
        id: {
          visible: false,
          exportable: false
        },
        name: {
          title: this.$t('sales.table.columns.name'),
          sort: (s1, s2) => compareStringsInsensitive(s1.name, s2.name),
          sortable: true,
          visible: this.hiddenColumns.name
        },
        customerCount: {
          class: 'quantity',
          title: this.$t('sales.table.columns.customerCount'),
          sort: (s1, s2) => s1.customerCount - s2.customerCount,
          sortable: true,
          exportable: !this.showQuantity,
          visible: !this.showQuantity && this.hiddenColumns.customerCount
        },
        formattedQuantity: {
          class: 'quantity',
          title: this.$t('sales.table.columns.quantity'),
          sortable: true,
          exportable: this.showQuantity,
          visible: this.showQuantity && this.hiddenColumns.quantity,
          width: '15%'
        },
        salesOverallPercent: {
          class: 'quantity',
          title: this.$t('sales.table.columns.salesOverallPercent'),
          sort: (s1, s2) => s1.salesOverallPercent - s2.salesOverallPercent,
          sortable: true,
          visible: this.hiddenColumns.salesPercentOverall
        },
        salesPercent: {
          class: 'quantity',
          title: this.$t('sales.table.columns.salesPercent'),
          sort: (s1, s2) => s1.salesPercent - s2.salesPercent,
          sortable: true,
          visible: this.hiddenColumns.salesPercent
        },
        salesAmount: {
          class: 'amount',
          title: this.$t('sales.table.columns.salesAmount', {
            currency: (this.sales && this.sales[0] && this.sales[0].currency) || '$'
          }),
          sort: (s1, s2) => s1.salesAmount - s2.salesAmount,
          sortable: true,
          visible: this.hiddenColumns.salesAmount
        },
        discountsAmount: {
          class: 'amount',
          title: this.$t('sales.table.columns.discountsAmount'),
          sort: (s1, s2) => s1.discountsAmount - s2.discountsAmount,
          sortable: true,
          exportable: !this.showQuantity,
          visible: !this.showQuantity && this.hiddenColumns.discountsAmount
        },
        netSales: {
          class: 'amount',
          title: this.$t('sales.table.columns.netAmount'),
          sort: (s1, s2) => s1.netSales - s2.netSales,
          sortable: true,
          exportable: !this.showQuantity,
          visible: !this.showQuantity && this.hiddenColumns.netSales
        },
        currency: {
          visible: false,
          exportable: true,
          title: this.$t('sales.table.columns.currency')
        }
      };
    }
  },
  methods: {
    handleRow(row) {
      this.$emit('row', row);
    },
    filterChangeHandler(value) {
      this.$emit('filter-change', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.sales {
  &__title {
    text-transform: uppercase;
  }

  /deep/ .vel-table__cell {
    vertical-align: top;

    &.amount,
    &.quantity {
      text-align: right;
      word-break: keep-all;
    }

    &.quantity {
      @include monospaced;
    }
  }

  /deep/ .el-row .el-col {
    width: 100%;
  }

  /deep/ .vel-table-wrapper {
    overflow-x: scroll;

    @include breakpoint(large) {
      overflow-x: hidden;
    }

    .vel-table__row td:first-child {
      min-width: 180px;
      width: 100%;
    }
  }

  /deep/ .vel-table-header__cell:nth-child(2),
  /deep/ .vel-table-header__cell:nth-child(3),
  /deep/ .vel-table-header__cell:nth-child(4),
  /deep/ .vel-table-header__cell:nth-child(5),
  /deep/ .vel-table-header__cell:nth-child(6),
  /deep/ .vel-table-header__cell:nth-child(7),
  /deep/ .vel-table-header__cell:nth-child(8),
  /deep/ .vel-table-header__cell:nth-child(9) {
    padding-right: 16px;
    text-align: right;
  }
}
</style>
