<template>
  <div class="permission-edit-modal">
    <portal to="app">
      <vel-modal class="modals__edit" dismissable @dismiss="handleCancel">
        <template v-slot:header>
          {{ $t('user.permissions.modals.edit.title') }}
        </template>

        <template v-slot:default>
          <permission-form
            class="permission-edit-modal__form"
            :locationsOptions="!permission.locationId ? [] : locations"
            :groupsOptions="!permission.groupId ? [] : groups"
            :permission="permission"
            @submit="handleSuccess"
            @cancel="handleCancel"
          ></permission-form>
        </template>
      </vel-modal>
    </portal>
  </div>
</template>

<script>
import PermissionForm from '../permission-form/PermissionForm.vue';
import VelModal from '@/components/modal/VelModal';

export default {
  name: 'edit-permission-modal',
  components: {
    PermissionForm,
    VelModal
  },
  props: {
    locations: {
      type: Array,
      default: () => []
    },
    groups: {
      type: Array,
      default: () => []
    },
    permission: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleCancel() {
      this.$emit('dismiss');
    },
    handleSuccess(permission) {
      this.$emit('success', permission);
    }
  }
};
</script>
