<template>
  <div class="vel-tooltip">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'vel-tooltip'
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$tooltip-background-color: white;
$tooltip-font-color: $shark;
$tooltip-shadow-color: rgba(black, 0.26);

.vel-tooltip {
  background-color: $tooltip-background-color;
  border-radius: $base-radius * 2;
  box-shadow: 0 2px 5px 0 $tooltip-shadow-color;
  color: $tooltip-font-color;
  font-size: rem-calc(16);
  list-style: none;
  margin: rem-calc(2) 0 0;
  min-width: rem-calc(160);
  padding: 0 rem-calc(4);
  text-align: left;
  z-index: 200;
}
</style>
