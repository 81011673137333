<template>
  <ol class="sales-breadcrumb">
    <li class="sales-breadcrumb__crumb sales-breadcrumb__crumb_root">
      {{ $t('sales.title') }}
    </li>
  </ol>
</template>

<script>
export default {
  name: 'sales-breadcrumb',
  props: {
    crumbs: {
      type: Array
    }
  },
  methods: {
    handleClick(crumb) {
      this.$emit('click', crumb);
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$breadcrumb-separator-color: $pale-sky;

// link
$breadcrumb__link-font-color: $primary-color;
$breadcrumb__link-font-color_hover: mix(black, $primary-color, 10%);
$breadcrumb__link-font-color_active: $pale-sky;
$breadcrumb__link-font-weight: $font-regular;

.sales-breadcrumb {
  align-content: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  &__crumb {
    padding-left: rem-calc(8);
    text-transform: uppercase;
    @include text-ellipsis;

    &_root {
      font-weight: $font-bold;
    }
  }

  &__crumb:last-child &__link {
    &,
    &:hover {
      color: $breadcrumb__link-font-color_active;
      cursor: inherit;
      pointer-events: none;
    }
  }

  &__link {
    color: $breadcrumb__link-font-color;
    font-weight: $breadcrumb__link-font-weight;
    outline: none;
    text-decoration: none;

    &:any-link {
      color: $breadcrumb__link-font-color;
      font-weight: $breadcrumb__link-font-weight;
      outline: none;
      text-decoration: none;
    }

    &:hover {
      color: $breadcrumb__link-font-color_hover;
    }

    &_active_exact,
    &_active_exact:hover {
      color: $breadcrumb__link-font-color_active;
    }
  }

  @include breakpoint(medium) {
    flex-direction: row;
    list-style: none;

    &__crumb {
      &::before {
        color: $breadcrumb-separator-color;
        content: '/';
        display: inline-flex;
        padding-right: rem-calc(8);
      }

      &:first-child::before {
        display: none;
      }
    }
  }
}
</style>
