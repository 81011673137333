<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="600px"
    right
    id="mealPlan-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarMealPlan"
  >
    <template #header="{ hide }">
      <div class="bg-gray-200 bg-light w-100">
        <b-button variant="outline-primary" @click="hide">
          <b-icon icon="chevron-compact-left" aria-hidden="true"></b-icon>
          {{ $t('mealPlan.back') }}
        </b-button>
      </div>
    </template>

    <template #footer="{ hide }">
      <div class="d-flex bg-light text-dark align-items-center px-3 py-2" style="border-top: 2px solid #d6d6d8;">
        <b-button variant="primary" @click="hide">{{ $t('mealPlan.close') }}</b-button>
      </div>
    </template>
    <b-container fluid class="p-4" v-loading="pending">
      <div class="d-flex justify-content-between align-content-between">
        <h5>{{ $t('mealPlan.list.title') }}</h5>
        <b-button variant="primary" v-b-toggle.mealPlanForm-sidebar>
          <b-icon icon="plus"></b-icon>
          {{ $t('mealPlan.add.btn') }}
        </b-button>
      </div>

      <meal-plan-list
        :meal-plans="mealPlans"
        @onModify="openEditMealPlan($event)"
        @onDelete="$emit('onDelete', $event)"
      ></meal-plan-list>
    </b-container>
  </b-sidebar>
</template>
<script>
import MealPlanList from '@/private/components/meal-plan/components/MealPlanList';

export default {
  name: 'employee-sidebar-meal-plan',
  components: {
    MealPlanList
  },
  props: {
    mealPlan: {
      type: Object,
      default: () => ({
        name: '',
        excludeBenefit: false,
        licenceNumber: 0,
        eligibility: [
          {
            duration: null,
            amount: null
          }
        ]
      })
    },
    mealPlans: {
      type: Array,
      default: () => []
    },
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  computed: {},
  methods: {
    openEditMealPlan(mealPlan) {
      this.$emit('update:mealPlan', mealPlan.item);
    }
  }
};
</script>
