<template>
  <Content class="public-content">
    <router-view></router-view>
  </Content>
</template>

<script>
export default {
  name: 'public-page'
};
</script>

<style lang="scss" scoped>
.public-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.brand {
  margin-bottom: 48px;
}
</style>
