<template>
  <div class="filters">
    <div class="filters__fields">
      <b-form-group v-if="enabledFilters.includes('licenceNumbers')">
        <label>{{ $t('locationStatus.filters.licenceNumber.label') }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <span class="input-group-text">
              <b-icon icon="search" />
            </span>
          </b-input-group-prepend>
          <b-form-input
            :disabled="disabled"
            :state="licenceNumbersValid ? null : false"
            size="sm"
            :placeholder="$t('locationStatus.filters.licenceNumber.placeholder')"
            v-model="licenceNumbers"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group v-if="enabledFilters.includes('location')">
        <label>{{ $t('locationStatus.filters.location.label') }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <span class="input-group-text">
              <b-icon icon="search" />
            </span>
          </b-input-group-prepend>
          <b-form-input
            :disabled="disabled"
            size="sm"
            :placeholder="$t('locationStatus.filters.location.placeholder')"
            v-model="selectedFilters.location"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group v-if="enabledFilters.includes('status')">
        <label>{{ $t('locationStatus.filters.status.label') }}</label>
        <vel-multiple-selector
          :value-options="this.statusList"
          :multiple="true"
          :filterable="true"
          :disabled="disabled"
          v-model="selectedFilters.status"
          :all-results-label="'locationStatus.filters.status.all'"
          :multiples-results-label="'locationStatus.filters.status.filtered'"
        ></vel-multiple-selector>
      </b-form-group>
      <!-- Disable pos type for now
      <b-form-group v-if="enabledFilters.includes('posType')">
        <label>{{ $t('locationStatus.filters.posType.label') }}</label>
        <vel-multiple-selector
          :value-options="this.posTypeList"
          :multiple="true"
          :filterable="true"
          :disabled="disabled"
          v-model="selectedFilters.posType"
          :all-results-label="'locationStatus.filters.posType.all'"
          :multiples-results-label="'locationStatus.filters.posType.filtered'"
        ></vel-multiple-selector>
      </b-form-group>
      -->
      <b-form-group v-if="enabledFilters.includes('posVersion')">
        <label>{{ $t('locationStatus.filters.posVersion.label') }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <span class="input-group-text">
              <b-icon icon="search" />
            </span>
          </b-input-group-prepend>
          <b-form-input
            :disabled="disabled"
            :state="posVersionValid ? null : false"
            size="sm"
            :placeholder="$t('locationStatus.filters.posVersion.placeholder')"
            v-model="posVersion"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <div style="display: flex; align-items: end;">
        <div>
          <b-button
            variant="secondary"
            :disabled="disabled"
            class="d-flex justify-content-between align-items-center"
            @click="showFiltersPopup = true"
          >
            <b-icon icon="plus-circle" aria-hidden="true" scale="1.4"></b-icon>
            <span class="d-none d-md-block ml-2">{{ $t('locationStatus.filters.add') }}</span>
          </b-button>
          <VelListPopup
            v-if="showFiltersPopup"
            v-model="enabledFilters"
            :value-options="filtersOptions"
            @close="showFiltersPopup = false"
          />
        </div>
      </div>
    </div>
    <div class="filters__buttons">
      <b-button variant="primary" :disabled="disabled" @click="applyFilters">
        {{ $t('locationStatus.filters.apply') }}
      </b-button>
      <b-button variant="secondary" :disabled="disabled" @click="clearFilters">
        {{ $t('locationStatus.filters.clear') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import VelListPopup from '@/components/list/VelListPopup';
import VelMultipleSelector from '@/components/list/VelMultipleSelector';

export default {
  name: 'CheckSummaryFilters',
  components: {
    VelMultipleSelector,
    VelListPopup
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    filtersOptions() {
      return [
        { key: 'licenceNumbers', label: this.$t('locationStatus.filters.licenceNumber.label') },
        { key: 'location', label: this.$t('locationStatus.filters.location.label') },
        { key: 'status', label: this.$t('locationStatus.filters.status.label') },
        // { key: 'posType', label: this.$t('locationStatus.filters.posType.label') },
        { key: 'posVersion', label: this.$t('locationStatus.filters.posVersion.label') }
      ];
    }
  },
  data() {
    return {
      showFiltersPopup: false,
      enabledFilters: JSON.parse(window.localStorage.getItem('locationStatus.filters') || '[]'),
      selectedFilters: {
        licenceNumbers: [],
        location: '',
        status: [],
        posType: [],
        posVersion: ''
      },
      licenceNumbers: '',
      licenceNumbersValid: true,
      posVersion: '',
      posVersionValid: true,
      statusList: [
        { value: 0, key: 0, label: this.$t('locationState.states[0]') },
        { value: 1, key: 1, label: this.$t('locationState.states[1]') },
        { value: 2, key: 2, label: this.$t('locationState.states[2]') }
      ],
      posTypeList: [
        { value: 0, key: 0, label: 'Veloce' },
        { value: 1, key: 1, label: "Maitre'D" }
      ]
    };
  },
  watch: {
    enabledFilters(value) {
      if (!value.includes('licenceNumbers')) {
        this.licenceNumbers = '';
        this.licenceNumbersValid = true;
        this.selectedFilters.licenceNumbers = [];
      }
      if (!value.includes('location')) {
        this.selectedFilters.location = '';
      }
      if (!value.includes('status')) {
        this.selectedFilters.status = '';
      }
      if (!value.includes('posType')) {
        this.selectedFilters.posType = [];
      }
      if (!value.includes('posVersion')) {
        this.selectedFilters.posVersion = '';
      }

      window.localStorage.setItem('locationStatus.filters', JSON.stringify(value));
    }
  },
  methods: {
    reset() {
      this.selectedFilters = {
        licenceNumbers: [],
        location: '',
        status: [],
        posType: [],
        posVersion: ''
      };
      this.licenceNumbers = '';
      this.licenceNumbersValid = true;
      this.posVersion = '';
      this.posVersionValid = true;
    },
    clearFilters() {
      this.reset();
      this.$emit('filtersChanged');
    },
    applyFilters() {
      this.licenceNumbersValid = this.extractLicenceNumbers();
      this.posVersionValid = this.validatePOSVersion();
      if (this.licenceNumbersValid && this.posVersionValid) {
        this.selectedFilters.posVersion = this.posVersion;
        this.$emit('filtersChanged');
      }
    },
    getAppliedFilters() {
      return {
        licenceNumber: this.selectedFilters.licenceNumbers,
        status: this.selectedFilters.status,
        posType: this.selectedFilters.posType,
        name: this.selectedFilters.location || null,
        version: this.selectedFilters.posVersion || null
      };
    },
    validatePOSVersion() {
      this.selectedFilters.posVersion = '';

      if (this.posVersion.length === 0) {
        return true;
      }

      const chunks = this.posVersion.replaceAll(' ', '').split('.');
      for (const chunk of chunks) {
        if (isNaN(chunk)) {
          return false;
        }
      }

      return true;
    },
    extractLicenceNumbers() {
      this.selectedFilters.licenceNumbers = [];

      console.log(this.licenceNumbers);
      if (this.licenceNumbers.length === 0) {
        return true;
      }

      const chunks = this.licenceNumbers.replaceAll(' ', '').split(',');

      for (const chunk of chunks) {
        if (isNaN(chunk)) {
          return false;
        }

        this.selectedFilters.licenceNumbers.push(chunk);
      }

      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

$contrast-color: #00a9e1;

* {
  font-size: rem-calc(12) !important;
}

.filters {
  align-items: flex-end;
  display: flex;

  .btn-secondary {
    background: #f5f5f5;
    color: $contrast-color;
    border: none;
  }

  &__fields {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    gap: 5px;
    padding-right: rem-calc(16);

    .el-select,
    /deep/ .el-select__tags,
    /deep/ .el-input,
    /deep/ .el-select__input,
    /deep/ .el-input__inner,
    /deep/ .el-input__suffix {
      max-height: 32px;
      height: 32px;
    }

    /deep/ .el-input__icon {
      line-height: 32px;
      color: $contrast-color;
    }

    input.form-control {
      height: 34px;
    }

    .form-group {
      max-width: 150px;

      & > div > label {
        color: #808080;
        min-height: unset;
        margin: 0;
      }

      .input-group-text {
        width: 24px;
        border-right: none;
        background-color: transparent !important;
        color: #d9d9d9;
        padding: 0;

        svg {
          margin-left: 4px;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
    border-left: 1px solid #dbdbdb;
    padding-left: rem-calc(16);
  }
}
</style>
