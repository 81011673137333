<template>
  <section class="notfound">
    <h1 class="notfound__title" v-t="'notfound.title'" />
    <p class="notfound__text" v-t="'notfound.text'" />
    <vel-link :to="{ name: 'home' }" class="notfound__back-button" v-t="'notfound.return'" />
  </section>
</template>

<script>
import VelLink from '@/components/link/VelLink';

export default {
  name: 'notfound-page',
  components: {
    VelLink
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$notfound-height: 100vh;
$notfound-width: 100vw;

// title
$notfound__title-font-size: rem-calc(40);
$notfound__title-font-weight: $font-semi-bold;

.notfound {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: $notfound-height;
  justify-content: center;
  width: $notfound-width;

  &__title {
    font-size: $notfound__title-font-size;
    font-weight: $notfound__title-font-weight;
    margin-bottom: rem-calc(20);
  }

  &__text {
    margin-bottom: rem-calc(40);
  }
}
</style>
