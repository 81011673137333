<template>
  <vel-backoffice-page class="backoffice-page bootstrap">
    <template v-slot:title>
      {{ $t('pages.location-configuration') }}
    </template>
    <vel-card class="content">
      <h3 class="mb-4">Back-Office</h3>

      <b-card class="back-card" bg-variant="dark" text-variant="white" no-body>
        <b-card-header header-bg-variant="dark" text-variant="white">
          Employee Management
        </b-card-header>

        <b-card-body body-bg-variant="white" body-text-variant="dark">
          <b-button variant="link" :to="{ name: 'employees' }">
            <b-icon icon="file-person-fill"></b-icon>
            Employee Profiles
          </b-button>
        </b-card-body>
      </b-card>
    </vel-card>
  </vel-backoffice-page>
</template>

<script>
import VelBackofficePage from '@/components/page/VelBackofficePage';
import VelCard from '@/components/card/VelCard';

export default {
  name: 'BackOfficeHomePage',
  components: {
    VelCard,
    VelBackofficePage
  },
  data() {
    return {};
  },
  props: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.content {
  height: 100%;
}

.back-card {
  max-width: 360px;
}
</style>
