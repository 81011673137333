<template>
  <vel-form
    ref="bigDivisionForm"
    class="big-division-form"
    :model="bigDivisionForm"
    :rules="bigDivisionFormRules"
    :submit="handleSubmit"
    label-width="150px"
    :label-position="labelPosition"
  >
    <vel-form-item
      prop="remoteId"
      class="big-division-form-remoteId"
      :label="$t('bigDivisionInfo.form.remoteId.label')"
    >
      <vel-input disabled name="remoteId" class="name-input" type="text" :value="bigDivisionForm.remoteId"></vel-input>
    </vel-form-item>
    <vel-form-item prop="name" class="big-division-form-name" :label="$t('bigDivisionInfo.form.name.label')">
      <vel-input name="name" class="name-input" type="text" v-model="bigDivisionForm.name" maxlength="24"></vel-input>
    </vel-form-item>
    <vel-form-item
      prop="alternativeName"
      class="big-division-form-alternative-name"
      :label="$t('bigDivisionInfo.form.alternativeName.label')"
    >
      <vel-input
        name="alternativeName"
        class="name-input"
        type="text"
        v-model="bigDivisionForm.alternativeName"
        maxlength="24"
      ></vel-input>
    </vel-form-item>
    <vel-form-item
      prop="operationAssessment"
      class="big-division-form-operation-assessment"
      :label="$t('bigDivisionInfo.form.operationAssessment.label')"
    >
      <vel-switch v-model="bigDivisionForm.operationAssessment" />
    </vel-form-item>
    <vel-form-item class="big-division-form__actions">
      <vel-button type="primary" class="actions__submit" :loading="pending" @click="handleSubmit()">
        {{ $t('defaults.actions.save') }}
      </vel-button>
      <vel-button type="secondary" class="actions__cancel" :loading="pending" @click="handleCancel()">
        {{ $t('defaults.actions.cancel') }}
      </vel-button>
    </vel-form-item>
  </vel-form>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelButton from '@/components/button/VelButton';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelInput from '@/components/input/VelInput';
import VelSwitch from '@/components/switch/VelSwitch';

export default {
  name: 'big-division-form',
  components: {
    VelButton,
    VelForm,
    VelFormItem,
    VelInput,
    VelSwitch
  },
  mixins: [DeviceMixin],
  props: {
    bigDivision: {
      type: Object,
      default: () => ({
        remoteId: '',
        name: '',
        alternativeName: '',
        operationAssessment: false
      })
    },
    pending: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bigDivisionForm: {
        remoteId: this.bigDivision.remoteId,
        name: this.bigDivision.name,
        alternativeName: this.bigDivision.alternativeName,
        operationAssessment: this.bigDivision.operationAssessment,
        id: this.bigDivision.id,
        licenceNumber: this.bigDivision.licenceNumber
      }
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.bigDivisionForm.validate(valid => {
        if (valid) {
          this.$emit('submit', this.bigDivisionForm);
        }
      });
    },
    handleCancel() {
      this.$router.push({ path: '/bigdivisions' });
    }
  },
  computed: {
    labelPosition() {
      return this.isMediumDown ? 'top' : 'left';
    },
    bigDivisionFormRules() {
      return {
        name: [
          { max: 24, message: this.$t('bigDivisionInfo.form.name.error.length') },
          {
            required: false,
            message: this.$t('bigDivisionInfo.form.name.isRequired')
          }
        ],
        alternativeName: [
          {
            required: false,
            message: this.$t('bigDivisionInfo.form.alternativeName.isRequired')
          },
          { max: 24, message: this.$t('bigDivisionInfo.form.alternativeName.error.length') }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

.big-division-form {
  /deep/ {
    .vel-input-group {
      padding-left: 0;
    }

    .el-form--label-top .el-form-item__label {
      padding: 0 0.3125rem;
    }
  }

  /deep/ input:disabled {
    background-color: $athens-gray;
  }

  &-name,
  &-alternative-name {
    max-width: rem-calc(700);
  }

  &-remoteId {
    max-width: rem-calc(300);
  }
}

.actions__cancel {
  margin-left: rem-calc(10);
}
</style>
