<template>
  <b-container fluid>
    <b-row class="d-flex flex-row is-justify-space-between">
      <b-col
        cols="6"
        sm="4"
        class="d-flex flex-column flex-md-row align-content-between justify-content-between justify-content-md-start mb-md-0"
      >
        <b-button
          v-if="activeTab !== 3"
          :disabled="pending || activeTab == 3 || !canContinue"
          variant="primary"
          @click="next()"
          class="mr-0 mr-md-3 mb-2 mb-md-0"
        >
          {{ $t('employeeCreate.btns.continue') }}
        </b-button>
        <b-button :disabled="!canSave || pending" variant="outline-primary" @click="$emit('onSave')" class="">
          {{ $t('employeeCreate.btns.save') }}
        </b-button>
      </b-col>
      <b-col class="align-self-end text-right" cols="6" sm="8">
        <b-button :disabled="pending" variant="outline-secondary" :to="{ name: 'employees' }">
          {{ $t('employeeCreate.btns.cancel') }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'employee-footer',
  components: {},
  props: {
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    canContinue: {
      type: Boolean,
      default: false
    },
    submited: {
      type: Boolean,
      default: false
    },
    canSave: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: Number,
      default: 0
    }
  },
  computed: {},
  methods: {
    next() {
      if (!this.canContinue) {
        this.$emit('update:submited', true);
      } else {
        this.$emit('update:activeTab', this.activeTab + 1);
        this.submited = false;
      }
    }
  }
};
</script>
