<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="600px"
    right
    id="counterForm-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarCounterForm"
    v-model="isSidebarVisible"
    :no-close-on-backdrop="$refs.form && $refs.form.haveChanges"
    :no-close-on-esc="$refs.form && $refs.form.haveChanges"
    @hidden="onHide"
  >
    <template #header>
      <h6 class="bg-gray-200 bg-light w-100 mb-0 text-center py-2">
        {{ $t(counter && counter.id ? 'counters.form.edit.title' : 'counters.form.create.title') }}
      </h6>
    </template>
    <template #footer="{ hide }">
      <div
        class="d-flex bg-light text-dark align-items-center justify-content-between px-3 py-2"
        style="border-top: 2px solid #d6d6d8;"
      >
        <b-button :disabled="!($refs.form && $refs.form.enableSaveChanges)" variant="primary" @click="updateCounter()">
          {{ $t(counter && counter.id ? 'counters.form.edit.save' : 'counters.form.create.save') }}
        </b-button>
        <b-button variant="outline-primary" @click="onCancel(hide)">
          {{ $t('counters.form.cancel') }}
        </b-button>
      </div>
    </template>
    <b-container style="background-color: #f4f4f4;" fluid class="py-4 px-0" v-loading="pending">
      <counter-form :licenceNumber="licenceNumber" :counter.sync="counter" ref="form"></counter-form>
    </b-container>
  </b-sidebar>
</template>
<script>
import { patchItemCounter, postItemCounter } from '@/services/items.service';
import CounterForm from '@/private/components/counters/CounterForm';
import { bus } from '@/bus';

export default {
  name: 'counter-sidebar-form-counter',
  components: {
    CounterForm
  },
  props: {
    counter: {
      type: Object,
      default: () => ({
        id: null,
        name: ''
      })
    },
    pending: {
      type: Boolean,
      default: false
    },
    licenceNumber: {
      type: Number,
      default: 0
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSidebarVisible: false
    };
  },
  methods: {
    toast(msg = 'toast.update', value = {}) {
      this.$toast.open({
        message: `${this.$t(msg, value)}`,
        position: 'bottom',
        type: 'success',
        queue: false,
        duration: 6000,
        dismissible: true
      });
    },
    onCancel(hide) {
      if (this.$refs.form.haveChanges) {
        this.$emit('onCancel', null);
      } else {
        hide();
      }
    },
    onHide() {
      this.counter = null;
      this.$emit('update:counter', null);
      this.$forceUpdate();
      return true;
    },
    async updateCounter() {
      try {
        this.$emit('update:pending', true);
        if (this.counter.id) {
          await patchItemCounter(this.counter.id, this.counter);
          this.toast('toast.update');
        } else {
          await postItemCounter({ ...this.counter, licenceNumber: this.licenceNumber });
          this.toast('toast.counterCreate', this.counter);
        }
        this.$emit('update:pending', false);
        this.$emit('success', this.counter);
        this.$refs.sidebarCounterForm.hide();
      } catch (e) {
        this.$emit('update:error', true);
        this.$emit('update:pending', false);
        this.$emit('failure', this.counter);
        console.error(e);
      }
    }
  },
  mounted() {
    bus.$on('document:click', evt => {
      if (evt.target.className.includes('b-sidebar-backdrop') && this.$refs.form.haveChanges) {
        this.$emit('onCancel', null);
      }
    });
  },
  destroyed() {
    bus.$off('document:click');
  }
};
</script>
<style scoped lang="scss">
/deep/ .b-sidebar-header {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

/deep/ .b-sidebar-body {
  background-color: #f4f4f4;
}
</style>
