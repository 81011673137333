<script>
import { Link } from 'element-ui';

export default {
  name: 'vel-elink',
  extends: Link
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';
</style>
