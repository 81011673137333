import { get, resolveCommonQuery } from '@/helpers/service.helper';
import { getToken } from '@/helpers/token.helper';

/**
 * Get modes
 * @link https://api.posveloce.com/docs/private#tag/modes/paths/~1modes/get
 */
export const getModes = (params: {}) => {
  const url = resolveCommonQuery('/modes', params).toString();
  return get(getToken(), url);
};
