import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import salesService from '@/services/sales.service';
import { useDataSalesIndicators } from '@/stores/data/sales/indicators.module';
import discountsModuleHelper from '@/stores/discounts.module.helper';

export const useDataSalesDiscounts = defineStore('salesDiscounts', {
  state: () => ({
    status: {},
    discountsRows: [],
    error: null
  }),
  getters: {
    getDiscountsByCurrency: state => currencyId => state.discountsRows
  },
  actions: {
    async getDiscounts(payload = {}) {
      try {
        const toDay = today().startOf('day').toISO();
        const from = router.currentRoute.query.from || toDay;
        const to = router.currentRoute.query.to || from || toDay;

        const routeQuery: any = {
          ...router.currentRoute.query,
          from,
          to,
          ...payload
        };
        if (!routeQuery.to && routeQuery.from) {
          routeQuery.to = routeQuery.from;
        }

        this.getDiscountsRequest();

        const discounts = await salesService.getDiscounts(getToken(), routeQuery);
        const indicatorsStore = useDataSalesIndicators();
        const sales = indicatorsStore.getIndicatorsSalesVolume;

        this.getDiscountsSuccess({
          discountsRows: discountsModuleHelper.formatDiscounts(discounts, sales)
        });
      } catch (error) {
        this.getDiscountsFailure(error);
      }
    },
    getDiscountsRequest() {
      this.status = { ...this.status, loading: true };
      this.discountsRows = [];
      this.error = null;
    },
    getDiscountsSuccess({ discountsRows }) {
      this.status = {};
      this.discountsRows = discountsRows || [];
      this.error = null;
    },
    getDiscountsFailure(error) {
      this.status = {};
      this.discountsRows = [];
      this.error = error;
    }
  }
});
