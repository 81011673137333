<template>
  <vel-table
    :rows="rows"
    :columns="columns"
    :loading="loading"
    :hoverable="true"
    defaultDirection="asc"
    defaultSort="location"
  >
    <template v-slot:empty>
      {{ $t('locations.errors.noData') }}
    </template>

    <template v-slot:label-column="scope">
      <div class="location__name">
        <vel-avatar class="location__name-avatar" :fullname="scope.row.name" />
        <span class="location__name-text">{{ scope.row.name }}</span>
      </div>
    </template>

    <template v-slot:veloceVersion-column="scope">
      {{ validateVersion(scope.row.version) }}
    </template>

    <template v-slot:actions-column="scope">
      <div class="actions">
        <vel-button
          type="link"
          class="actions__goto"
          :disabled="disableActions"
          icon="external-link"
          @click="handleGotoLocation(locations[scope.rowIndex])"
        />
        <vel-button
          type="link"
          class="actions__delete"
          :disabled="disableActions"
          icon="delete"
          @click="handleDelete(locations[scope.rowIndex])"
        />
      </div>
    </template>
  </vel-table>
</template>

<script>
import VelAvatar from '@/components/avatar/VelAvatar';
import VelButton from '@/components/button/VelButton';
import VelTable from '@/components/table/VelTable';
import compareStringsInsensitive from '@/comparators/compare-strings-insensitive';
import { oc } from 'ts-optchain';

export default {
  name: 'locations-list',
  components: {
    VelAvatar,
    VelButton,
    VelTable
  },
  props: {
    disableActions: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    locationTitle: {
      type: String
    },
    locations: {
      type: Array
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    rows() {
      return this.locations;
    },
    columns() {
      return {
        label: {
          key: 'location',
          title: this.$t('group.locations.table.columns.location'),
          sort: (p1, p2) => compareStringsInsensitive(oc(p1.location).label(), oc(p2.location).label()),
          sortable: true
        },
        veloceVersion: {
          key: 'veloceVersion',
          title: this.$t('locations.veloceVersion.text'),
          width: '5%'
        },
        actions: {
          key: 'actions',
          title: 'Actions',
          sortable: false,
          visible: this.showActions,
          width: '15%'
        }
      };
    }
  },
  methods: {
    handleGotoLocation(location) {
      this.$emit('goToLocation', location);
    },
    handleDelete(location) {
      this.$emit('delete', location);
    },
    validateVersion(version) {
      if (parseInt(version) <= 0 || !version) {
        return '-';
      }
      return version;
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$locations__name-font-weight: $font-medium;

.location {
  @include breakpoint(small) {
    &__name-avatar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    &__name {
      align-items: center;
      display: inline-flex;
    }

    &__name-text {
      font-weight: $locations__name-font-weight;
      @include text-ellipsis;
    }

    &__name-avatar {
      display: inline;
      margin-right: rem-calc(8);
    }
  }
}

.group__locations {
  .vel-table {
    &-header__cell:nth-child(2),
    &-header__cell:last-child {
      text-align: right;
    }

    &__cell:nth-child(2),
    &__cell:last-child {
      text-align: right;
    }
  }
}

.actions {
  .vel-button {
    .vel-button__icon {
      padding: 0.25rem;
    }

    &.actions__goto {
      .vel-button__icon {
        width: rem-calc(27);
      }
    }
  }
}
</style>
