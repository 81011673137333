<template>
  <vel-page class="changelog">
    <h2>{{ lastCommitDate }}</h2>
    <ul v-for="merge in merges" :key="merge.id">
      <li>{{ merge.message }}</li>
      <li>{{ merge.date }}</li>
      <li>
        <b>{{ merge.author }}</b>
      </li>
    </ul>
  </vel-page>
</template>

<script>
import VelPage from '@/components/page/VelPage';
import changelog from '@/../CHANGELOG';

export default {
  name: 'private-page',
  components: {
    VelPage
  },
  data() {
    return {
      lastCommitDate: new Date(changelog[0].date).toLocaleDateString(),
      merges: [...changelog[0].commits, ...changelog[0].merges]
        // ignore old commits
        .filter(c => c.message.match(/^VL.[0-9]+/iu))
        .map(c => ({
          ...c,
          message: c.message.replace(/:.*?:/gu, ''),
          date: new Date(c.date || c.commit.date).toLocaleDateString()
        }))
    };
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.changelog {
  h2 {
    margin-bottom: 2rem;
  }

  ul {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    clear: both;
    list-style: none;
    margin: 0;
    padding: 10px 0 10px 0;

    li {
      display: block;
      padding: 0 0.5rem;

      @include breakpoint(medium) {
        float: left;
      }

      &:first-child {
        width: 100%;
        @include breakpoint(medium) {
          width: 70%;
        }
      }
    }
  }
}
</style>
