import { DateTime } from 'luxon';

export default date => {
  if (!date) {
    return '-';
  }

  return (date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date)).toLocaleString(
    DateTime.DATETIME_MED
  );
};
