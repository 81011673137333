<template>
  <vel-page :show-crumbs="false">
    <template slot="title">{{ $t('configMenuPage.title') }}</template>
    <template slot="export">
      <!-- <span class="toolbar" v-if="isMediumUp">
        <template>{{ $t('configMenuPage.toolbarLabel') }}</template>
        <toolbar-selectors :enable-group="false" :enable-date-picker="false" />
      </span> -->
    </template>
    <!-- <span class="toolbar mobile" v-if="isMediumDown">
      <toolbar-selectors :enable-group="false" :enable-date-picker="false" />
    </span> -->
    <div class="content">
      <!--
      <vel-card shadowed>
        <template slot="title">{{ $t('configMenuPage.employees.title') }}</template>
        <router-link :to="{ name: 'employees' }">
          {{ $t('configMenuPage.employees.employees') }}
        </router-link>
       <router-link :to="{ name: 'employee-create' }">
          {{ $t('configMenuPage.employees.createNew') }}
        </router-link>-->
      <!--
      </vel-card>-->
      <!-- <vel-card shadowed>
        <template slot="title">{{ $t('configMenuPage.menuItem.title') }}</template>
        <router-link :to="{ name: '' }">
          {{ $t('configMenuPage.menuItem.menuItems') }}
        </router-link>
        <router-link :to="{ name: '' }">
          {{ $t('configMenuPage.menuItem.createNew') }}
        </router-link>
      </vel-card> -->
      <vel-card shadowed>
        <template slot="title">{{ $t('configMenuPage.users.title') }}</template>
        <router-link :to="{ name: 'users' }">
          {{ $t('configMenuPage.users.users') }}
        </router-link>
        <router-link :to="{ name: 'createUser' }">
          {{ $t('configMenuPage.users.createNew') }}
        </router-link>
      </vel-card>
    </div>
  </vel-page>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
// import ToolbarSelectors from '@/private/shared/toolbar/ToolbarSelectors';
import VelCard from '@/components/card/VelCard';
import VelIcon from '@/components/icon/VelIcon';
import VelPage from '@/components/page/VelPage';

export default {
  name: 'configuration-menu',
  mixins: [DeviceMixin],
  components: {
    // ToolbarSelectors,
    VelCard,
    VelIcon,
    VelPage
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

/deep/ .vel-page {
  .toolbar {
    align-items: center;
    display: flex;
    flex-direction: row;

    .align_left {
      margin-left: rem-calc(20);

      div {
        margin: 0;
      }
    }

    &.mobile {
      .align_left {
        margin: 0 rem-calc(20);
      }

      margin-bottom: rem-calc(25);
      width: 100%;

      input {
        margin: 0;
      }
    }
  }

  &__header {
    box-shadow: none;
  }

  &__content {
    background: #fff;
  }

  .content {
    display: grid;
    grid-template-columns: auto;
    height: 100%;

    @include breakpoint(large) {
      display: flex;
    }
  }
}

/deep/ .vel-card {
  border-radius: rem-calc(4);

  @include breakpoint(large) {
    height: rem-calc(450);
  }

  padding: 0;

  @include breakpoint(large) {
    min-width: rem-calc(300);

    &:not(:first-child) {
      margin-left: rem-calc(15);
    }
  }

  &_shadowed {
    box-shadow: 0 0 0 1px rgba(#505f79, 0.05), 0 3px 3px 0 rgba(#505f79, 0.15);
  }

  &__title {
    background: #2a3142;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: rem-calc(16);
    font-weight: 500;
    padding: rem-calc(20);
  }

  &__body {
    padding-left: rem-calc(20);
  }

  .vel-icon {
    color: #2a3142;
    margin-right: rem-calc(5);
  }

  a {
    align-items: center;
    color: #0c68bf;
    cursor: pointer;
    display: flex;
    list-style: none;
    margin-bottom: rem-calc(16);
    text-decoration: none;
  }
}
</style>
