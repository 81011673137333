<template>
  <section class="underMaintenance">
    <logo class="underMaintenance__logo" />
    <h1 class="underMaintenance__title" v-t="'maintenance.title'" />
    <p class="underMaintenance__text" v-t="'maintenance.text'" />
    <vel-button @click="reload" class="underMaintenance__back-button" v-t="'maintenance.return'" />
  </section>
</template>

<script>
import Logo from '@/assets/svg/logo.svg';
import Navigate from '@/navigation';
import VelButton from '@/components/button/VelButton';
import { environment } from '@/configs';

export default {
  name: 'maintenance-page',
  components: {
    Logo,
    VelButton
  },
  methods: {
    reload() {
      window.location = '/';
    }
  },
  mounted() {
    if (!environment.enable_maintenance) {
      Navigate.toHome();
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$underMaintenance-height: 100vh;
$underMaintenance-width: 100vw;

// title
$underMaintenance__title-font-size: rem-calc(40);
$underMaintenance__title-font-weight: $font-semi-bold;

.underMaintenance {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: $underMaintenance-height;
  justify-content: center;
  width: $underMaintenance-width;

  &__title {
    font-size: $underMaintenance__title-font-size;
    font-weight: $underMaintenance__title-font-weight;
    margin-bottom: rem-calc(20);
  }

  &__text {
    margin-bottom: rem-calc(40);
  }

  &__logo {
    margin-bottom: 2rem;
    width: rem-calc(120);

    path {
      fill: $primary-color;
    }
    @media screen and (max-width: map-get($breakpoints, 'medium')) {
      max-height: rem-calc(80);
    }
  }
}
</style>
