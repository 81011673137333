<template>
  <vel-page class="user">
    <template v-slot:title v-if="user">
      {{ user.fullName }}
    </template>

    <user-edit-container v-if="isUserAdmin && user"></user-edit-container>

    <vel-entity-information-card
      :isLoading="isLoadingUser"
      :entity="userData.entity"
      :properties="userData.properties"
      avatar="fullName"
      translationPrefix="user.info"
    ></vel-entity-information-card>

    <vel-card class="user__permissions">
      <template v-slot:title>
        <div class="user__permissions-title">
          {{ $t('user.permissions.title') }}
          <div class="user__permissions__list-length">
            {{ permissionsTotalCount }}
          </div>
        </div>
      </template>

      <vel-alert
        class="permissions__forbidden-changes"
        level="error"
        :dismissable="false"
        v-if="isUserForbiddenToChangePermissions"
      >
        {{ $t('user.permissions.errors.forbiddenChange') }}
      </vel-alert>

      <div v-if="!isLoadingPermissions">
        <div class="permissions__actions">
          <vel-button class="actions__add" :disabled="areActionsDisabled" type="primary" @click="handlePermissionAdd()">
            {{ $t('user.permissions.actions.add') }}
          </vel-button>
        </div>

        <div v-if="hasPermissions">
          <permissions-matrix
            class="permissions__global"
            :disableActions="areActionsDisabled"
            :permissionTitle="`${$t('user.permissions.global')} ${
              this.multiplePermissions ? `(${this.globalPermissions.length})` : ''
            }`"
            :permissions="globalPermissions"
            :multiplePermissions="multiplePermissions"
            @edit="handlePermissionEdit"
            @delete="handlePermissionDelete"
            v-if="hasGlobalPermissions"
          />

          <permissions-matrix
            class="permissions__groups"
            :disableActions="areActionsDisabled"
            :multiplePermissions="multiplePermissions"
            :permissions="groupsPermissions"
            :showGroup="true"
            @edit="handlePermissionEdit"
            @delete="handlePermissionDelete"
            v-if="hasGroupsPermissions"
          />

          <permissions-matrix
            class="permissions__locations"
            :disableActions="areActionsDisabled"
            :multiplePermissions="multiplePermissions"
            :permissions="locationsPermissions"
            :showLocation="true"
            @edit="handlePermissionEdit"
            @delete="handlePermissionDelete"
            v-if="hasLocationsPermissions"
          />
        </div>
        <vel-alert :dismissable="false" class="permissions__none" level="notice" v-else>
          {{ $t('user.permissions.none') }}
        </vel-alert>

        <div class="permission__modals">
          <add-permission-modal
            class="modals__add"
            :locations="locationsForAddingPermission"
            :groups="groupsForAddingPermission"
            @dismiss="handleAddModalDismiss"
            @success="handleAddModalSuccess"
            v-if="showAddPermission"
          ></add-permission-modal>

          <edit-permission-modal
            class="modals__edit"
            :locations="locationsForEditingPermission"
            :groups="groupsForEditingPermission"
            :permission="editedPermission"
            @dismiss="handleEditModalDismiss"
            @success="handleEditModalSuccess"
            v-if="editedPermission"
          ></edit-permission-modal>

          <delete-permission-modal
            class="modals__delete"
            :permission="deletedPermission"
            @dismiss="handleDeleteModalDismiss"
            @success="handleDeleteModalSuccess"
            v-if="deletedPermission"
          ></delete-permission-modal>
        </div>
      </div>
      <vel-spinner v-else />
    </vel-card>
  </vel-page>
</template>

<script>
import AddPermissionModal from './components/modals/AddPermissionModal.vue';
import DeletePermissionModal from './components/modals/DeletePermissionModal.vue';
import EditPermissionModal from './components/modals/EditPermissionModal.vue';
import PermissionsMatrix from '@/private/components/permissions-matrix/PermissionsMatrix.vue';
import UserEditContainer from '@/private/users/containers/UserEditContainer';
import VelAlert from '@/components/alert/VelAlert';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelEntityInformationCard from '@/components/entity-information-card/VelEntityInformationCard';
import VelPage from '@/components/page/VelPage';
import VelSpinner from '@/components/spinner/VelSpinner';
import { environment } from '@/configs';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useUIBreadcrumbsStore } from '@/stores/ui/breadcrumbs.module';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataUsersUser } from '@/stores/data/users/user.module';
import { useDataUsersUserPermissions } from '@/stores/data/users/user-permissions.module';
import { useDataGroups } from '@/stores/data/groups.module';

export default {
  name: 'UserPage',
  components: {
    AddPermissionModal,
    EditPermissionModal,
    DeletePermissionModal,
    PermissionsMatrix,
    VelAlert,
    VelButton,
    VelCard,
    VelEntityInformationCard,
    VelPage,
    VelSpinner,
    UserEditContainer
  },
  data() {
    return {
      showAddPermission: false,
      editedPermission: null,
      deletedPermission: null
    };
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapPiniaActions(useDataUsersUser, ['getUser', 'deletePermission', 'getPermissions', 'upsertPermissions']),
    ...mapPiniaActions(useDataUsersUser, ['reset']),
    ...mapPiniaActions(useUIBreadcrumbsStore, ['replaceLastBreadcrumb']),

    handlePermissionAdd() {
      this.showAddPermission = true;
    },

    handlePermissionEdit(permission) {
      this.editedPermission = permission;
    },

    handlePermissionDelete(permission) {
      this.deletedPermission = permission;
    },

    handleAddModalDismiss() {
      this.showAddPermission = false;
    },

    handleAddModalSuccess(permissions) {
      this.upsertPermissions(permissions);
      this.showAddPermission = false;
    },

    handleEditModalDismiss() {
      this.editedPermission = null;
    },

    handleEditModalSuccess(permission) {
      this.upsertPermissions([
        {
          id: permission.id,
          permission: permission.permission,
          locationId: permission.locationId,
          groupId: permission.groupId
        }
      ]);
      this.editedPermission = null;
    },

    handleDeleteModalDismiss() {
      this.deletedPermission = null;
    },

    handleDeleteModalSuccess(permission) {
      this.deletePermission(permission);
      this.deletedPermission = null;
    }
  },
  computed: {
    ...mapPiniaState(useDataUsersUser, [
      'arePermissionsBeingChanged',
      'globalPermissions',
      'groupsPermissions',
      'isLoadingUser',
      'isLoadingPermissions',
      'isUserForbiddenToChangePermissions',
      'isUserNotFound',
      'locationsPermissions',
      'user'
    ]),
    ...mapPiniaState(useDataUsersUserPermissions, ['isUserAdmin']),
    ...mapPiniaState(useDataLocationsStore, {
      locations: 'locations'
    }),
    ...mapPiniaState(useDataGroups, {
      groups: 'getGroups'
    }),
    config() {
      return environment.admin.user;
    },
    areActionsDisabled() {
      return this.arePermissionsBeingChanged || this.isUserForbiddenToChangePermissions;
    },
    hasPermissions() {
      return this.hasGlobalPermissions || this.hasLocationsPermissions || this.hasGroupsPermissions;
    },
    hasGlobalPermissions() {
      return (this.globalPermissions || []).length > 0;
    },
    hasGroupsPermissions() {
      return (this.groupsPermissions || []).length > 0;
    },
    hasLocationsPermissions() {
      return (this.locationsPermissions || []).length > 0;
    },
    locationsForAddingPermission() {
      return this.locations.filter(location => !this.locationsPermissions.some(p => p.locationId === location.value));
    },
    locationsForEditingPermission() {
      return this.locations.filter(
        location =>
          !this.locationsPermissions.some(p => p.locationId === location.value) ||
          location.value === this.editedPermission.locationId
      );
    },
    groupsForAddingPermission() {
      return this.groups.filter(group => !this.groupsPermissions.some(p => p.groupId === group.value));
    },
    groupsForEditingPermission() {
      return this.groups.filter(
        group =>
          !this.groupsPermissions.some(p => p.groupId === group.value) || group.value === this.editedPermission.groupId
      );
    },
    permissionsTotalCount() {
      return (
        this.globalPermissions &&
        this.groupsPermissions &&
        this.locationsPermissions &&
        this.globalPermissions.length + this.groupsPermissions.length + this.locationsPermissions.length
      );
    },
    multiplePermissions() {
      let permissionCount = 0;
      if (this.hasGroupsPermissions) {
        permissionCount++;
      }
      if (this.hasLocationsPermissions) {
        permissionCount++;
      }
      if (this.hasGlobalPermissions) {
        permissionCount++;
      }
      return permissionCount > 1;
    },
    userData() {
      if (!this.user) {
        return {};
      }
      const properties = ['fullName', 'email', 'phoneNumber', 'type', 'enabled'];
      const entity = {
        ...this.user,
        enabled: this.$t(`user.propertyValue.enabled.${this.user.enabled}`),
        type: this.$t(`user.propertyValue.type.${this.user.type}`)
      };

      if (this.user.type === 128 && this.user.reseller) {
        properties.push('resellerName');
        entity.resellerName = entity.reseller.name;
      }

      return {
        entity,
        properties
      };
    }
  },
  watch: {
    isUserNotFound(newValue) {
      if (newValue) {
        this.$router.replace({ name: 'notfound' });
      }
    },
    user(newValue) {
      if (newValue && newValue.fullName !== '') {
        // It should be enabled in an other task
        // this.replaceLastBreadcrumb({ name: newValue.fullName, path: this.$router.currentRoute.path });
      }
    }
  },
  created() {
    this.reset();
    this.getUser(this.userId);
    this.getPermissions(this.userId);
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.permissions__actions {
  margin-bottom: 8px;
  text-align: right;
  width: 100%;
}

.permissions__forbidden-changes {
  margin-bottom: 8px;
}

.permissions__locations {
  margin-top: 8px;
}

.user__permissions {
  &-title {
    align-items: center;
    display: flex;
    height: rem-calc(25);
  }

  &__list-length {
    background: $primary-color;
    border-radius: 5px;
    color: #fff;
    display: flex;
    font-size: $small-font-size;
    font-weight: bold;
    justify-content: center;
    margin-left: rem-calc(15);
    padding: rem-calc(5) 0 rem-calc(5) 0;
    width: rem-calc(40);
  }
}
</style>
