<template>
  <vel-topbar class="topbar">
    <template v-slot:left>
      <div v-if="isLargeUp" @click="$router.push({ name: 'dashboard' })" class="top">
        <logo class="logo" />
        <brand class="brand" />
      </div>
      <vel-button v-else class="topbar__action" type="primary" @click="handleToggle">
        <vel-icon name="hamburger" size="medium" modifier="on-dark" />
      </vel-button>
      <div class="announcements__action" type="primary">
        {{ $t('topbar.whatsnew') }}
      </div>
    </template>
    <template v-slot:right>
      <!--<vel-button class="topbar__feedback" type="primary" @click="handleFeedback()">Feedback</vel-button>-->
      <vel-button
        class="topbar__action"
        type="primary"
        ref="accountPopoverTrigger"
        v-if="account.fullName"
        @click="toggleAccountTooltip"
      >
        <vel-avatar :fullname="account.fullName" />
      </vel-button>
      <portal to="app">
        <account-tooltip
          class="topbar__popover"
          ref="accountPopover"
          :firstname="account.firstName"
          :visible="appStore.isPopoverActive('topbar:account')"
        />
      </portal>
    </template>
  </vel-topbar>
</template>

<script>
import AccountTooltip from '@/private/components/topbar/AccountTooltip';
import Brand from '@/assets/svg/brand.svg';
import DeviceMixin from '@/mixins/device-mixin';
import Logo from '@/assets/svg/logo.svg';
import PopoverMixin from '@/mixins/popover-mixin';
import VelAvatar from '@/components/avatar/VelAvatar';
import VelButton from '@/components/button/VelButton';
import VelIcon from '@/components/icon/VelIcon';
import VelTopbar from '@/components/topbar/VelTopbar';
import { bus } from '@/bus';

export default {
  name: 'topbar',
  mixins: [DeviceMixin, PopoverMixin],
  components: {
    Brand,
    Logo,
    VelTopbar,
    VelIcon,
    VelButton,
    VelAvatar,
    AccountTooltip
  },
  props: {
    account: {
      type: Object,
      required: true
    },
    handleToggle: {
      type: Function,
      default: () => null
    }
  },
  methods: {
    toggleAccountTooltip() {
      this.togglePopover('topbar:account');
    },
    handleFeedback() {
      window.doorbell.show();
    },
    handleDocumentClick(event) {
      this.dismissPopover('topbar:account', {
        target: event.target,
        popover: this.$refs.accountPopover,
        trigger: this.$refs.accountPopoverTrigger
      });
    }
  },
  mounted() {
    bus.$on('document:click', this.handleDocumentClick);
  },
  beforeDestroy() {
    bus.$off('document:click', this.handleDocumentClick);
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';
@import 'layout.scss';

$topbar-z: $layout-topbar-z;

// action
$topbar__action-min-width: rem-calc(60);

// popover
$topbar__popover-z: 100;

.topbar {
  z-index: $topbar-z;
  @include noPrint;

  &__feedback {
    font-size: 16px;
    font-weight: 400;
  }

  &__action {
    align-items: center;
    -webkit-appearance: none;
    display: flex;
    height: 100%;
    justify-content: center;
    min-width: $topbar__action-min-width;
    padding: 0;
    -webkit-tap-highlight-color: transparent;

    &:focus {
      box-shadow: none;
    }
  }

  &__popover {
    position: absolute;
    right: rem-calc(10);
    top: rem-calc(72);
    z-index: $topbar__popover-z;
  }

  .top {
    align-items: center;
    background: #00a9e1;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: rem-calc(104);
    padding: 0 rem-calc(2) 0 rem-calc(2);

    .logo {
      height: rem-calc(30);
      width: rem-calc(36);
    }

    .brand {
      height: rem-calc(26);
      margin-left: rem-calc(10);
    }
  }
}

.announcements__action {
  margin-left: 10px;
  padding: 10px;
}
</style>
