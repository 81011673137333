import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { addOne, createCollectionState, getSelectors, removeAll, setAll, setOne } from '@/helpers/stores.helpers';
import departmentsService from '@/services/departments.service';

const { selectAll, selectAllFiltered, selectSelected } = getSelectors();

enum DepartmentsState {
  UNDEFINED,
  EDIT_SUCCEEDED,
  CREATE_SUCCEEDED,
  DELETE_SUCCEEDED
}
/* eslint-enable no-unused-vars */

const defaultStatus = {
  success: false,
  isLoading: false,
  state: DepartmentsState.UNDEFINED
};

export const useDataDepartments = defineStore('departments', {
  state: () =>
    createCollectionState({
      filter: '',
      status: defaultStatus,
      error: null
    }),
  getters: {
    getDepartments: selectAll,
    getDepartmentsRows: selectAllFiltered(['name']),
    getSelectedDepartment: selectSelected,
    isLoading: state => !!state.status.isLoading,
    success: state => !!state.status.success,
    editSucceeded: state => state.status.state === DepartmentsState.EDIT_SUCCEEDED,
    createSucceeded: state => state.status.state === DepartmentsState.CREATE_SUCCEEDED,
    deleteSucceeded: state => state.status.state === DepartmentsState.DELETE_SUCCEEDED
  },
  actions: {
    async fetchDepartments(params) {
      try {
        this.getDepartmentsRequest();
        const taxes = await departmentsService.getDepartments(getToken(), params);
        this.getDepartmentsSuccess(taxes);
      } catch (error) {
        this.getDepartmentsFailure(error);
      }
    },
    async createDepartment(payload) {
      try {
        this.createDepartmentRequest();
        const department = await departmentsService.createDepartment(payload);
        this.createDepartmentSuccess(department);
      } catch (error) {
        this.createDepartmentFailure(error);
      }
    },
    async getDepartmentById(payload) {
      try {
        const tax = await departmentsService.getDepartmentById(getToken(), payload);
        this.getDepartmentByIdSuccess(tax);
        this.setSelected(tax.id);
      } catch (error) {
        this.getDepartmentByIdFailure(error);
      }
    },
    async editDepartment(payload) {
      try {
        this.editDepartmentRequest();
        const department = await departmentsService.editDepartment(payload);
        this.editDepartmentSuccess(department);
      } catch (error) {
        this.editDepartmentFailure(error);
      }
    },
    async deleteDepartment(id) {
      try {
        this.deleteDepartmentRequest();
        const department = await departmentsService.deleteDepartment(getToken(), id);
        this.deleteDepartmentSuccess(department);
      } catch (error) {
        this.deleteDepartmentFailure(error);
      }
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    setSelected(value) {
      this.setSelectedValue(value);
    },
    getDepartmentsRequest() {
      this.status.isLoading = true;
      this.data = removeAll(this.data);
      this.error = null;
    },
    getDepartmentsSuccess(taxes) {
      this.status.isLoading = false;
      this.data = setAll(taxes, this.data);
      this.error = null;
    },
    getDepartmentsFailure(error) {
      this.status = defaultStatus;
      this.data = removeAll(this.data);
      this.error = error;
    },
    getDepartmentByIdSuccess(department) {
      this.status.isLoading = false;
      this.data = removeAll(this.data);
      this.data = setOne(department, this.data);
      this.error = null;
    },
    getDepartmentByIdFailure(error) {
      this.status = defaultStatus;
      this.data = removeAll(this.data);
      this.error = error;
    },
    createDepartmentRequest() {
      this.status = { ...this.status, isLoading: true, state: DepartmentsState.UNDEFINED };
      this.data = removeAll(this.data);
      this.error = null;
    },
    createDepartmentSuccess(tax) {
      this.status = { ...this.status, isLoading: false, success: true, state: DepartmentsState.CREATE_SUCCEEDED };
      this.data = addOne(tax, this.data);
      this.error = null;
    },
    createDepartmentFailure(error) {
      this.status = { ...this.status, success: false, state: DepartmentsState.UNDEFINED };
      this.data = removeAll(this.data);
      this.error = error;
    },
    editDepartmentRequest() {
      this.status = { ...this.status, isLoading: true };
      this.data = removeAll(this.data);
      this.error = null;
    },
    editDepartmentSuccess(department) {
      this.status = { ...this.status, isLoading: false, success: true, state: DepartmentsState.EDIT_SUCCEEDED };
      this.data = addOne(department, this.data);
      this.error = null;
    },
    editDepartmentFailure(error) {
      this.status = { ...this.status, success: false, state: DepartmentsState.UNDEFINED };
      this.data = removeAll(this.data);
      this.error = error;
    },
    deleteDepartmentRequest() {
      this.status = { ...this.status, isLoading: true };
      this.data = removeAll(this.data);
      this.error = null;
    },
    deleteDepartmentSuccess(department) {
      this.status = { ...this.status, isLoading: false, success: true, state: DepartmentsState.DELETE_SUCCEEDED };
      this.data = addOne(department, this.data);
      this.error = null;
    },
    deleteDepartmentFailure(error) {
      this.status = { ...this.status, isLoading: false, success: false, state: DepartmentsState.UNDEFINED };
      this.error = error;
    },
    setFilterValue(value) {
      this.filter = value;
    },
    clearError(state) {
      this.error = null;
    },
    setSelectedValue(value) {
      this.data.selected = value;
    },
    resetState() {
      this.status.state = DepartmentsState.UNDEFINED;
    }
  }
});
