import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import { addOne, createCollectionState, getSelectors, removeAll, setAll, setOne } from '@/helpers/stores.helpers';
import taxesService from '@/services/taxes.service';
import router from '@/router';

const { selectAll, selectAllFiltered, selectSelected } = getSelectors();

enum TaxState {
  UNDEFINED,
  EDIT_SUCCEEDED,
  CREATE_SUCCEEDED
}
/* eslint-enable no-unused-vars */

const defaultStatus = {
  success: false,
  isLoading: false,
  state: TaxState.UNDEFINED
};

const initialState = createCollectionState({
  filter: '',
  status: defaultStatus,
  error: null
});

export const useDataTaxes = defineStore('taxes', {
  state: () => initialState,
  getters: {
    getTaxes: selectAll,
    getTaxesRows: selectAllFiltered(['name', 'taxCategory.name']),
    getSelectedTax: selectSelected,
    isTaxLoading: state => !!state.status.isLoading,
    success: state => !!state.status.success,
    hasTaxError: state => !!state.error,
    editSucceeded: state => state.status.state === TaxState.EDIT_SUCCEEDED,
    createSucceeded: state => state.status.state === TaxState.CREATE_SUCCEEDED
  },
  actions: {
    async fetchTaxes(ids = []) {
      try {
        this.getTaxesRequest();
        const taxes = await taxesService.getTaxes(getToken(), { ...router.currentRoute.query, ids });
        this.getTaxesSuccess(taxes);
      } catch (error) {
        this.getTaxesFailure(error);
      }
    },
    async createTax(payload) {
      try {
        this.createTaxRequest();
        const tax = await taxesService.createTax(getToken(), payload);
        this.createTaxSuccess(tax);
      } catch (error) {
        this.createTaxFailure(error);
      }
    },
    async getTaxById(payload) {
      try {
        this.getTaxByIdRequest();
        const tax = await taxesService.getTaxById(getToken(), payload);
        this.getTaxByIdSuccess(tax);
        this.setSelected(tax.id);
      } catch (error) {
        this.getTaxByIdFailure(error);
      }
    },
    async editTax(payload) {
      try {
        this.editTaxRequest();
        const tax = await taxesService.editTax(getToken(), payload);
        this.editTaxSuccess(tax);
      } catch (error) {
        this.editTaxFailure(error);
      }
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    clearSuccessMessage() {
      this.clearMessage();
    },
    setSelected(value) {
      this.setSelectedValue(value);
    },
    getTaxesRequest() {
      this.status.isLoading = true;
      this.data = removeAll(this.data);
      this.error = null;
    },
    getTaxesSuccess(taxes) {
      this.status.isLoading = false;
      this.data = setAll(taxes, this.data);
      this.error = null;
    },
    getTaxesFailure(error) {
      this.status = defaultStatus;
      this.data = removeAll(this.data);
      this.error = error;
    },
    getTaxByIdSuccess(taxe) {
      this.status.isLoading = false;
      this.data = removeAll(this.data);
      this.data = setOne(taxe, this.data);
      this.error = null;
    },
    getTaxByIdFailure(error) {
      this.status = defaultStatus;
      this.data = removeAll(this.data);
      this.error = error;
    },
    createTaxRequest() {
      this.status = { ...this.status, isLoading: true, state: TaxState.UNDEFINED };
      this.data = removeAll(this.data);
      this.error = null;
    },
    createTaxSuccess(tax) {
      this.status = { ...this.status, success: true, state: TaxState.CREATE_SUCCEEDED };
      this.data = addOne(tax, this.data);
      this.error = null;
    },
    createTaxFailure(error) {
      this.status = { ...this.status, success: false, state: TaxState.UNDEFINED };
      this.data = removeAll(this.data);
      this.error = error;
    },
    editTaxRequest() {
      this.status = { ...this.status, isLoading: true };
      this.data = removeAll(this.data);
      this.error = null;
    },
    editTaxSuccess(tax) {
      this.status = { ...this.status, success: true, state: TaxState.EDIT_SUCCEEDED };
      this.data = addOne(tax, this.data);
      this.error = null;
    },
    editTaxFailure(error) {
      this.status = { ...this.status, success: false, state: TaxState.UNDEFINED };
      this.data = removeAll(this.data);
      this.error = error;
    },
    setFilterValue(value) {
      this.filter = value;
    },
    clearMessage() {
      this.status.success = null;
    },
    clearError() {
      this.error = null;
    },
    setSelectedValue(value) {
      this.data.selected = value;
    },
    resetState() {
      this.status.state = TaxState.UNDEFINED;
    }
  }
});
