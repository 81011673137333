import { Settings as LuxonSettings } from 'luxon';
import getUserLocale from 'get-user-locale';

export default {
  methods: {
    isActiveLanguage(locale) {
      return this.$i18n.locale.startsWith(locale);
    },
    setLanguage(locale) {
      localStorage.setItem('locale', locale);
      this.$i18n.locale = locale;

      LuxonSettings.defaultLocale = locale;
    },
    getLanguage() {
      return localStorage.getItem('locale') || 'en';
    },
    restoreLanguage() {
      const locale = localStorage.getItem('locale');
      if (!locale) {
        if (navigator.language && navigator.language.substr(0, 2) !== 'fr') {
          this.setLanguage('en');
        }
      } else {
        this.setLanguage(locale);
      }
    },
    toggleLanguage() {
      this.setLanguage(this.isActiveLanguage('en') ? 'fr' : 'en');
    }
  }
};
