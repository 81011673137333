<template>
  <vel-backoffice-page
    class="backoffice-page bootstrap"
    :enableToolbar="false"
    :enable-date-range="false"
    :enable-date-picker="false"
    :enable-groups="false"
    :show-date-compare="false"
    :enable-all-locations="false"
    :scroll="false"
  >
    <template v-slot:header>
      <b-container fluid class="mb-4">
        <b-row class="d-flex flex-column flex-md-row pt-3">
          <b-col class="d-flex align-items-center">
            <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
          </b-col>
        </b-row>
        <b-row class="my-4">
          <b-col cols="12" md="6" class="d-flex flex-row justify-content-between">
            <h4>{{ $t('coursing.list.title') }}</h4>
            <b-button
              variant="primary"
              class="ml-4 d-flex align-items-center d-md-none"
              @click="coursingEdit = { automaticDelayMinutes: 0 }"
              v-b-toggle.coursingForm-sidebar
            >
              <b-icon icon="plus" aria-hidden="true" scale="1.4"></b-icon>
              <span class="d-none d-md-block">{{ $t('coursing.list.buttons.new') }}</span>
            </b-button>
          </b-col>
          <b-col cols="12" md="6" class="d-flex mt-4 mt-md-0 flex-row justify-content-md-end flex-md-row">
            <b-input-group style="max-width: 250px;" class="align-content-end align-self-end">
              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="filter"
                debounce="1"
                type="text"
                :placeholder="$t('coursing.list.searchPlaceholder')"
              ></b-form-input>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-4 d-md-flex align-items-center d-none"
              @click="coursingEdit = { automaticDelayMinutes: 0 }"
              v-b-toggle.coursingForm-sidebar
            >
              <b-icon icon="plus" aria-hidden="true" scale="1.4"></b-icon>
              {{ $t('coursing.list.buttons.new') }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <b-container fluid class="h-100" id="table-container">
      <b-table
        striped
        bordered
        hover
        show-empty
        fixed
        :filter="filter"
        primary-key="id"
        :empty-text="$t('coursing.empty')"
        :empty-filtered-text="$t('coursing.empty')"
        :filter-included-fields="filterOn"
        :per-page="perPage"
        :current-page="currentPage"
        :filter-debounce="800"
        :busy="loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :fields="fields"
        head-variant="info"
        :items="myProvider"
        responsive="sm"
        ref="table"
        sticky-header
      >
        <template #empty="scope">
          <div class="p-2">{{ scope && scope.emptyText }}</div>
        </template>
        <template #emptyfiltered="scope">
          <div class="p-2">{{ scope && scope.emptyFilteredText }}</div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner variant="primary" class="align-middle p-2 m-4"></b-spinner>
          </div>
        </template>
        <template #cell(name)="scope">
          <div class="w-100 d-flex align-items-center bootstrap">
            {{ scope.item.name }}
          </div>
        </template>
        <template #cell(edit)="scope">
          <b-button variant="link" @click="onClickEdit(scope.item)" v-b-toggle.coursingForm-sidebar>
            <b-icon icon="pencil-fill" aria-label="Edit" class="row-action text-primary"></b-icon>
            {{ $t('coursing.list.table.edit') }}
          </b-button>
        </template>
        <template #cell(delete)="scope">
          <b-button
            size="sm"
            variant="link"
            class="p-0 text-danger d-flex align-items-center"
            @click="onClickDelete(scope.item)"
            @mouseover="isDeleteHovering = scope.item.id"
            @mouseout="isDeleteHovering = null"
          >
            <span
              :class="{
                'material-icons-outlined': isDeleteHovering !== scope.item.id,
                'material-icons': isDeleteHovering === scope.item.id
              }"
            >
              delete
            </span>
            {{ $t('mealPlan.delete') }}
          </b-button>
        </template>
      </b-table>
    </b-container>
    <coursing-sidebar-form-coursing
      v-if="coursingEdit"
      :coursing.sync="coursingEdit"
      :pending.sync="pending"
      :licence-number="currentLocation.licenceNumber"
      @onCancel="onClickCancel"
      @success="onSuccess"
    ></coursing-sidebar-form-coursing>
    <b-modal
      v-model="showDiscardChanges"
      :title="$t('discardChange.title')"
      :static="true"
      :centered="true"
      :hide-header-close="true"
      :footer-bg-variant="'light'"
      :footer-text-variant="'dark'"
      @hidden="$emit('onCancel', true)"
    >
      {{ $t('discardChange.body') }}
      <template #modal-footer>
        <b-container fluid>
          <div class="w-100">
            <b-button variant="primary" class="float-right" @click="showDiscardChanges = false">
              {{ $t('discardChange.continue') }}
            </b-button>
            <b-button
              variant="link"
              class="float-right ml-2"
              @click="
                showDiscardChanges = false;
                coursingEdit = null;
              "
            >
              {{ $t('discardChange.discard') }}
            </b-button>
          </div>
        </b-container>
      </template>
    </b-modal>
    <employee-delete-modal
      :title="$t('coursing.delete.confirm.title', deleteEntity)"
      :body="$t('coursing.delete.confirm.body', deleteEntity)"
      :show="!!deleteEntity"
      @onDelete="onDelete(deleteEntity)"
      @onCancel="deleteEntity = null"
    ></employee-delete-modal>
  </vel-backoffice-page>
</template>

<script>
import * as JsSearch from 'js-search';
import { deleteItemService, getItemServices } from '@/services/items.service';
import CoursingSidebarFormCoursing from '@/private/coursing/components/CoursingSidebarFormCoursing';
import EmployeeDeleteModal from '@/private/components/employees/components/EmployeeDeleteModal';
import VelBackofficePage from '@/components/page/VelBackofficePage';
import cloneDeep from 'lodash.clonedeep';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';

export default {
  name: 'CoursingListPage',
  components: {
    VelBackofficePage,
    CoursingSidebarFormCoursing,
    EmployeeDeleteModal
  },
  data() {
    return {
      showActive: true,
      totalRows: 0,
      filter: null,
      filterOn: ['name'],
      perPage: window.localStorage.getItem('coursingListRowsPerPage') || 25,
      currentPage: 1,
      results: [],
      loading: false,
      clearMessage: true,
      isError: false,
      coursing: [],
      items: [],
      sortBy: 'name',
      sortDesc: false,
      coursingEdit: null,
      showDiscardChanges: false,
      deleteEntity: null,
      isDeleteHovering: null
    };
  },
  props: {},
  computed: {
    ...mapPiniaState(useUILocationSelector, {
      locationId: 'getLocationSelectorSelected',
      currentLocation: 'getSelectedLocation'
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t('setupMenuPage.title'),
          active: false,
          to: { name: 'setup-menu', query: { previous: 'menu', locationIds: this.locationId } }
        },
        {
          text: this.$t('coursing.list.title'),
          active: true
        }
      ];
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('coursing.list.table.headers.name'),
          class: 'name',
          sortable: true
        },
        {
          key: 'edit',
          class: 'edit'
        },
        {
          key: 'delete',
          class: 'delete'
        }
      ];
    }
  },
  watch: {
    perPage(val) {
      window.localStorage.setItem('coursingListRowsPerPage', val);
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['onChange']),
    toast(msg = 'toast.update', value = {}) {
      this.$toast.open({
        message: `${this.$t(msg, value)}`,
        position: 'bottom',
        type: 'success',
        queue: false,
        duration: 6000,
        dismissible: true
      });
    },
    onClickDelete(item) {
      this.deleteEntity = item;
    },
    async onDelete(item) {
      try {
        await deleteItemService(item.id);
        this.results = [];
        this.$refs.table.refresh();

        this.toast('toast.coursingDelete', this.deleteEntity);
        this.deleteEntity = null;
      } catch (_) {
        this.deleteEntity = null;
      }
    },
    onSuccess() {
      this.results = [];
      this.$refs.table.refresh();
    },
    onClickCancel() {
      this.showDiscardChanges = true;
    },
    onClickEdit(item) {
      this.coursingEdit = cloneDeep(item);
    },
    onLocationChange(locationId) {
      this.onChange({
        locationId
      });
    },
    async myProvider(ctx) {
      try {
        this.loading = true;
        const startAt = ctx.currentPage === 1 ? 0 : (ctx.currentPage - 1) * ctx.perPage;
        if (!this.results.length || ctx.force) {
          this.results = (
            (await getItemServices({ locationId: this.locationId }).then(x => x.content || [])) || []
          ).map(r => ({
            ...r,
            name: r.name.trim() === '' ? '---' : r.name
          }));
        }

        let results = this.results;

        if (ctx.filter) {
          const search = new JsSearch.Search('id');
          search.addIndex('name');
          search.addDocuments(results);
          results = search.search(ctx.filter);
        }

        if (ctx.sortBy !== '') {
          results = results.sort((a, b) =>
            ctx.sortDesc ? b[ctx.sortBy].localeCompare(a[ctx.sortBy]) : a[ctx.sortBy].localeCompare(b[ctx.sortBy])
          );
        }

        this.totalRows = results.length;
        this.coursing = results.slice(startAt, startAt + ctx.perPage);
        this.loading = false;
        return this.coursing || [];
      } catch (e) {
        this.loading = false;
        this.isError = true;
        return [];
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.bootstrap .container-fluid {
  padding-left: 24px;
  padding-right: 24px;
}

/* stylelint-disable */
/deep/ {
  .input-group > .form-control {
    border-left-color: #fff;
    padding-left: 0;
  }
  .input-group-text {
    background-color: #fff !important;
    border-right: 1px solid #fff !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  th {
    background-color: #eff9fd;
  }
  .page,
  .page .vel-page__content {
    overflow: hidden;
  }

  .content,
  .vel-card__body,
  .b-table-sticky-header {
    height: 100%;
    max-height: 100%;
    padding-top: 0;
  }

  .btn-group {
    button {
      text-transform: uppercase;
      width: 90px;
    }
  }
  .pagination {
    height: calc(1.5em + 0.5rem + 2px);
    display: flex;
    align-items: center;
  }

  .thead-info {
    background-color: #eff9fd;
    font-weight: bold;
  }

  .table {
    min-width: 600px;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f6fbfe !important;
  }

  .table-hover tbody tr:hover {
    background-color: transparent !important;
  }

  .table-hover tbody tr:nth-of-type(odd):hover {
    background-color: #f6fbfe !important;
  }

  .name {
    min-width: 400px;
  }

  .delete {
    width: 125px;
  }

  .edit {
    width: 115px;

    .btn-sm {
      padding: 0;
    }

    div {
      display: none;
    }
  }

  th.delete div {
    display: none;
  }

  .content {
    margin-bottom: 0;
  }

  .row-action {
    color: #444444;
  }
  .page-item.active .page-link {
    background-color: #000 !important;
  }
  .page-link {
    border: none !important;
    color: #444444;
  }

  .toolbar__location-selector {
    max-width: 100%;

    @include breakpoint(medium) {
      max-width: 360px;
    }
  }

  .breadcrumbs {
    padding-top: 16px;
  }

  /deep/ .breadcrumb-item {
    font-size: 0.9rem;
  }

  /deep/ .input-group-sm > .form-control:not(textarea) {
    height: calc(1.5em + 0.5rem + 7px);
  }

  /deep/ table td {
    border-color: #2a31420d !important;
    border-top: none !important;
    padding: 8px 16px !important;
  }

  /deep/ table th {
    padding: 8px 16px !important;
  }

  /deep/ .vel-icon_small {
    height: 1.15rem;
    width: 1.15rem;
  }
}

/deep/ {
  .modal-header {
    border: none;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    word-break: break-all;
  }

  .modal-body {
    padding: 0 1.5rem 1.5rem;
  }
}

.page > /deep/ header {
  margin-bottom: 1.5rem;
}

/deep/ .dropdown-menu {
  padding: 0;
  top: 7px !important;

  a {
    color: inherit;
    padding: 0.57143rem;
  }
}

.btn:hover {
  text-decoration: none;
}
</style>
