<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="660px"
    right
    id="timeOffForm-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarTimeOffForm"
    @hidden="clear()"
  >
    <template #header="{}">
      <h6 class="bg-gray-200 bg-light w-100 mb-0 text-center py-1">
        {{
          $t(
            timeOff && (timeOff.id || timeOff.id === null)
              ? 'employeeCreate.timeOffEdit.title'
              : 'employeeCreate.timeOff.title'
          )
        }}
      </h6>
    </template>
    <template #footer="{ hide }">
      <div
        class="d-flex bg-light text-dark align-items-center justify-content-between px-3 py-2"
        style="border-top: 2px solid #d6d6d8;"
      >
        <b-button
          :disabled="!($refs.form && $refs.form.enableSaveChanges)"
          variant="primary"
          f
          @click="updateTimeOff()"
        >
          {{ $t(timeOff && timeOff.id ? 'employeeCreate.btns.saveChanges' : 'employeeCreate.btns.save') }}
        </b-button>
        <b-button variant="outline-primary" @click="clear(hide)">
          {{ $t('employeeCreate.btns.cancel') }}
        </b-button>
      </div>
    </template>
    <b-container fluid class="p-4" v-loading="pending">
      <time-off-form :licenceNumber="employee.licenceNumber" :time-off.sync="timeOff" ref="form"></time-off-form>
    </b-container>
  </b-sidebar>
</template>
<script>
import TimeOffForm from '@/private/components/time-offs/TimeOffForm';

export default {
  name: 'employee-sidebar-form-time-off',
  components: {
    TimeOffForm
  },
  props: {
    timeOff: {
      type: Object,
      default: () => ({
        description: '',
        startDate: null,
        endDate: null,
        id: null
      })
    },
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    licenceNumber: {
      type: Number,
      default: 0
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    employeeTimeOff() {
      return {
        ...((this.$refs.form && this.$refs.form.form) || {})
      };
    }
  },
  methods: {
    clear(hide = null) {
      if (hide) {
        hide();
      }
      this.timeOff = null;
      this.$refs.form.reset(null);
      this.$emit('update:timeOff', null);
    },
    updateTimeOff() {
      try {
        this.$emit('update:pending', true);
        this.$emit('onUpdate', this.employeeTimeOff);
        /*
        if (this.employeeTimeOff.id) {
          await patchEmployeeTimeOff(this.employeeTimeOff.id, {
            ...this.employeeTimeOff,
            startDate: new Date(new Date(this.employeeTimeOff.startDate).setUTCHours(5, 59, 59, 999)).toISOString(),
            endDate: new Date(new Date(this.employeeTimeOff.endDate).setUTCHours(23, 59, 59, 999)).toISOString()
          });

          this.$toast.open({
            message: `<div class="bootstrap"><b-icon icon="pencil-fill"></b-icon>${this.$t(
              'toast.update'
            )}</div>`,
            position: 'bottom',
            type: 'success',
            duration: 5000,
            dismissible: true
          });
        } else {
          await postEmployeeTimeOff(this.employee.id, [
            {
              ...this.employeeTimeOff,
              startDate: new Date(new Date(this.employeeTimeOff.startDate).setUTCHours(5, 59, 59, 999)).toISOString(),
              endDate: new Date(new Date(this.employeeTimeOff.endDate).setUTCHours(23, 59, 59, 999)).toISOString()
            }
          ]);
        }*/
        this.$emit('update:pending', false);
        this.$emit('success', this.employeeTimeOff);
        this.$refs.sidebarTimeOffForm.hide();
        this.$refs.form.reset();
      } catch (e) {
        this.$emit('update:error', true);
        this.$emit('update:pending', false);
        this.$emit('failure', this.employeeTimeOff);
      }
    }
  }
};
</script>
