import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import salesService from '@/services/sales.service';

export const useDataSalesZones = defineStore('salesZones', {
  state: () => ({
    zoneSales: null,
    pending: true,
    success: null,
    error: null
  }),
  getters: {
    isPending: state => state.pending,
    getZoneSales: state => state.zoneSales,
    getZoneSalesByCurrency: state => currencyId =>
      (state.zoneSales || []).filter(zs => zs.salesAmount > 0).sort((a, b) => b.salesAmount - a.salesAmount),
    isError: state => !!state.error,
    getError: state => state.error,
    isSuccess: state => state.success,
    getSeries(state) {
      return currencyId => this.getZoneSalesByCurrency(currencyId).map(zs => zs.salesAmount);
    },
    getLabels(state) {
      return currencyId => this.getZoneSalesByCurrency(currencyId).map(zs => zs.name);
    },
    getTotal(state) {
      return currencyId => this.getZoneSalesByCurrency(currencyId).length;
    }
  },
  actions: {
    async getSalesByZone(payload) {
      const toDay = today().startOf('day').toString();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;

      const routeQuery = {
        currency: '$',
        ...router.currentRoute.query,
        from,
        to,
        ...payload
      };

      if (!requestAllocated(routeQuery)) {
        this.getZoneSalesRequest();

        try {
          const zoneSales = await salesService.getZoneSales(getToken(), routeQuery);
          this.getZoneSalesSuccess({ zoneSales });
          deallocateRequest(routeQuery);
        } catch (error) {
          this.getZoneSalesFailure(error);
        }
      }
    },
    getZoneSalesRequest() {
      this.pending = true;
      this.success = null;
      this.zoneSales = null;
      this.error = null;
    },
    getZoneSalesSuccess({ zoneSales }) {
      this.pending = false;
      this.success = true;
      this.zoneSales = zoneSales;
      this.error = null;
    },
    getZoneSalesFailure(error) {
      this.pending = false;
      this.success = false;
      this.zoneSales = null;
      this.error = error;
    }
  }
});
