<template>
  <vel-page
    :multiple-locations="locations && locations.length > 1"
    :enable-date-range="true"
    :show-selector-button="true"
    class="big-division__report"
    v-bind:class="{ modal: showConfig }"
    :showCrumbs="false"
    :enable-toolbar="true"
    :generatedTime="generatedTime"
    :enable-currency="true"
  >
    <template v-slot:export>
      <vel-button
        :disabled="areSalesLoading"
        :loading="generating"
        type="primary"
        @click="exportXLSX"
        :icon="isMediumUp ? 'file-excel' : undefined"
        class="vel-button"
      >
        <vel-icon v-if="isMediumDown" name="file-download" />
        {{ isMediumUp ? $t('table.tableExport.button.value') : '' }}
      </vel-button>
    </template>
    <template v-slot:title>
      {{ $t('pages.reports.bigDivision') }}
    </template>
    <vel-modal v-if="showConfig" dismissable @dismiss="showConfig = false">
      <template v-slot:header>
        {{ $t('editors.customize_link') }}
      </template>
      <config-big-division-report-display @update="$forceUpdate()"></config-big-division-report-display>
    </vel-modal>
    <template v-slot:toolbarMenu>
      <vel-elink type="info" @click="showConfig = true">{{ $t('editors.customize_link') }}</vel-elink>
    </template>
    <vel-card class="report__bigDivision">
      <big-divisions-sales-report
        :sales="salesWithConsolidatation"
        :locationCount="locationCount"
        :locationTotal="(locationsBySelectedGroup && locationsBySelectedGroup.length) || 0"
        :multipleLocation="mutableMultipleLocation"
        :areSalesLoading="areSalesLoading"
        :hiddenColumns="hiddenColumns"
      />
    </vel-card>
  </vel-page>
</template>

<script>
import BigDivisionsSalesReport from '@/private/components/sales/bigDivision/BigDivisionsSalesReport';
import ConfigBigDivisionReportDisplay from '@/private/components/config/appearance/big-division-report/ConfigBigDivisionReportDisplay';
import GeneratedTime from '@/mixins/generated-time-mixin';
import SelectableDateRangeMixin from '@/mixins/selectable-date-range-mixin';
import SelectableLocationMixin from '@/mixins/selectable-location-mixin';
import VelCard from '@/components/card/VelCard.vue';
import VelElink from '@/components/elink/VelElink';
import VelModal from '@/components/modal/VelModal';
import VelPage from '@/components/page/VelPage.vue';
import pickBy from 'lodash.pickby';
import { slugify } from '@/helpers/string.helpers';
import { mapState as mapPiniaState } from 'pinia';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { useUICurrencySelector } from '@/stores/ui/currency-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { mapActions as mapPiniaActions } from 'pinia/dist/pinia';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataSalesIndicators } from '@/stores/data/sales/indicators.module';
import { useDataUsersUserPermissions } from '@/stores/data/users/user-permissions.module';
import { useDataConfig } from '@/stores/data/config.module';
import { useDataGroups } from '@/stores/data/groups.module';
import { useDataSales } from '@/stores/data/sales.module';
import router from '@/router';
import salesService from '@/services/sales.service';
import { DateTime } from 'luxon';
import { useUIDateRangeSelector } from '@/stores/ui/date-range-selector.module';
import VelIcon from '@/components/icon/VelIcon.vue';
import VelButton from '@/components/button/VelButton.vue';
import DeviceMixin from '@/mixins/device-mixin';

export default {
  name: 'BigDivisionReportPage',
  components: {
    VelButton,
    VelIcon,
    BigDivisionsSalesReport,
    ConfigBigDivisionReportDisplay,
    VelCard,
    VelPage,
    VelElink,
    VelModal
  },
  mixins: [SelectableDateRangeMixin, SelectableLocationMixin, GeneratedTime, DeviceMixin],
  data() {
    return {
      showConfig: false,
      isConsolidated: localStorage.getItem('isConsolidated') === 'true',
      generating: false
    };
  },
  updater: {
    interval: 5 * 60 * 1000
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, { locations: 'locations' }),
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useDataSalesIndicators, ['indicators']),
    ...mapPiniaState(useUILocationSelector, {
      hasLocationsError: 'hasError',
      locationsLoading: 'isLoading',
      locationCount: 'getLocationSelectorSelectedMultipleLength',
      locationList: 'getLocationSelectorSelectedMultipleEntities',
      getLocationSelectorSelected: 'getLocationSelectorSelected'
    }),
    ...mapPiniaState(useUICurrencySelector, ['getCurrencySelectorSelected']),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById', 'locationsBySelectedGroup']),
    ...mapPiniaState(useDataSales, ['getSalesByCurrency', 'areSalesLoading']),
    ...mapPiniaState(useDataUsersUserPermissions, ['isUserAdmin']),
    ...mapPiniaState(useDataGroups, ['getGroupsRows']),
    ...mapPiniaState(useUIGroupSelector, ['getGroupSelectorSelected']),
    ...mapPiniaState(useUIDateRangeSelector, ['getDateSelectorSelectedRange']),
    ...mapPiniaState(useUILocationSelector, {
      locationCount: 'getLocationSelectorSelectedMultipleLength',
      locationListSelected: 'getLocationSelectorSelectedMultipleEntities',
      locationIds: 'getLocationSelectorSelectedMultipleIds'
    }),
    mutableMultipleLocation() {
      return !this.mutableLocationId;
    },
    displayConfig() {
      return this.get;
    },
    hiddenColumns() {
      return {
        quantity: this.displayConfig.big_divisions_column_quantity,
        salesPercent: this.displayConfig.big_divisions_column_sales_percent,
        salesPercentOverall: this.displayConfig.big_divisions_column_sales_percent_overall,
        salesAmount: this.displayConfig.big_divisions_column_sales_amount,
        location: true
      };
    },
    sales() {
      return this.getSalesByCurrency(this.getCurrencySelectorSelected);
    },
    salesWithConsolidatation() {
      return (this.sales || []).map(x => x);
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    ...mapPiniaActions(useDataSales, ['getLocation', 'getSalesByBigDivisionsReportWithLevels']),
    ...mapPiniaActions(useDataSales, ['reset']),
    ...mapPiniaActions(useDataSalesIndicators, ['getIndicators']),

    async exportXLSX() {
      this.generating = true;
      const from = this.getDateSelectorSelectedRange[0];
      const to = this.getDateSelectorSelectedRange[1];

      const routeQuery = {
        locationIds: this.locationIds,
        groupId: this.getGroupSelectorSelected,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO()
      };
      await salesService.downloadBigDivisionSalesXlsx(routeQuery).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    },
    onConsolidate() {
      this.gen(false);
      localStorage.setItem('isConsolidated', this.isConsolidated);
    },
    fetchSales() {
      this.getSalesByBigDivisionsReportWithLevels(
        pickBy({ from: this.mutableRange[0], to: this.mutableRange[1], locationId: this.mutableLocationId }, a => !!a)
      );
    },
    getCurrentGroupName() {
      if (this.getGroupsRows) {
        const selectedGroup = (this.getGroupsRows || []).filter(group => group.id === this.getGroupSelectorSelected);
        if (!selectedGroup.length) {
          return null;
        }
        return `${this.$t('exports.groupLabel')} ${selectedGroup[0].name}`;
      }
      return null;
    },
    refresh() {
      this.fetchSales();
    },
    handlePeriodicUpdate() {
      if (!this.isUserAdmin) {
        this.updater = setTimeout(this.refresh, this.$options.updater.interval);
      }
    }
  },
  mounted() {
    this.getIndicators();
    this.fetchSales();
    this.handlePeriodicUpdate();
    // this.generateTimeAt('data/sales/indicators/getIndicatorsSuccess');
  },
  beforeDestroy() {
    this.gen(false);
    clearTimeout(this.updater);
    this.reset();
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

/deep/ .vel-page__toolbar {
  margin-bottom: 1.25rem;
}

.report__bigDivision {
  overflow-x: scroll;

  @include breakpoint(large) {
    overflow-x: hidden;
  }
}

// hack - fix stacking order on safari.
.modal /deep/ .vel-page__content {
  overflow-x: visible;
  overflow-y: visible;
}

.vel-button {
  @include noPrint;
  background-color: $mountain-meadow;
  border-color: $mountain-meadow;
  direction: rtl;

  &:hover {
    background-color: mix(black, $mountain-meadow, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}
</style>
