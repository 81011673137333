<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="1400px"
    right
    id="accessTypeForm-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarAccessTypeForm"
    @hidden="
      accessType = null;
      $refs.form.reset();
    "
  >
    <template #header>
      <h6 class="bg-gray-200 bg-light w-100 mb-0 text-center">
        {{
          $t(accessType && accessType.id ? 'employeeCreate.accessType.titleEdit' : 'employeeCreate.accessType.title')
        }}
      </h6>
    </template>
    <template #footer="{ hide }">
      <div
        class="d-flex bg-light text-dark align-items-center justify-content-between px-3 py-2"
        style="border-top: 2px solid #d6d6d8;"
      >
        <div>
          <b-button
            :disabled="!($refs.form && $refs.form.enableContinue) || $refs.form.selectedTab === 5"
            variant="primary"
            f
            @click="$refs.form.selectedTab++"
          >
            {{ $t('employeeCreate.btns.continue') }}
          </b-button>
          <b-button
            :disabled="!($refs.form && $refs.form.enableSaveChanges)"
            variant="outline-primary"
            f
            @click="updateAccessType()"
            class="ml-3"
          >
            {{ $t(accessType && accessType.id ? 'employeeCreate.btns.saveChanges' : 'employeeCreate.btns.save') }}
          </b-button>
        </div>
        <b-button
          variant="outline-primary"
          f
          @click="
            hide();
            $refs.form.reset(null);
            $emit('update:accessType', null);
          "
        >
          {{ $t('employeeCreate.btns.cancel') }}
        </b-button>
      </div>
    </template>
    <b-container fluid v-loading="pending">
      <access-type-form
        :licenceNumber="this.employee.licenceNumber || this.licenceNumber"
        :job-roles="jobRoles"
        :tables-sections="tablesSections"
        :key-groups="keyGroups"
        :modes="modes"
        :requisition-indexes="requisitionIndexes"
        :access-type.sync="accessType"
        ref="form"
      ></access-type-form>
    </b-container>
  </b-sidebar>
</template>
<script>
import { patchEmployeeFunction, postEmployeeFunction } from '@/services/employees.service';
import AccessTypeForm from '@/private/components/access-type/components/AccessTypeForm';

export default {
  name: 'employee-sidebar-form-access-type',
  components: {
    AccessTypeForm
  },
  props: {
    accessType: {
      type: Object,
      default: () => null
    },
    jobRoles: {
      type: Array,
      default: () => []
    },
    keyGroups: {
      type: Array,
      default: () => []
    },
    tablesSections: {
      type: Array,
      default: () => []
    },
    requisitionIndexes: {
      type: Array,
      default: () => []
    },
    modes: {
      type: Array,
      default: () => []
    },
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    licenceNumber: {
      type: Number,
      default: 0
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    employeeAccessType() {
      return {
        ...this.$refs.form.form,
        licenceNumber: this.licenceNumber || this.employee.licenceNumber || null
      };
    }
  },
  methods: {
    toast(msg = 'toast.update', value = {}) {
      this.$toast.open({
        message: `${this.$t(msg, value)}`,
        position: 'bottom',
        type: 'success',
        queue: false,
        duration: 6000,
        dismissible: true
      });
    },
    async updateAccessType() {
      const employeeAccessType = {
        ...this.employeeAccessType,
        accessTypes: {
          ...this.employeeAccessType.accessTypes,
          misc: {
            ...this.employeeAccessType.accessTypes.misc,
            transfersMaxAmountBeforeForcedDepositAmount: Number(
              this.employeeAccessType.accessTypes.misc.transfersMaxAmountBeforeForcedDepositAmount
            ),
            transfersWarnAmountBeforeDepositAmount: Number(
              this.employeeAccessType.accessTypes.misc.transfersWarnAmountBeforeDepositAmount
            )
          }
        }
      };

      try {
        this.$emit('update:pending', true);

        if (this.employeeAccessType.id) {
          await patchEmployeeFunction(this.employeeAccessType.id, {
            ...employeeAccessType,
            accessTypes: {
              ...employeeAccessType.accessTypes,
              diningBtn: employeeAccessType.accessTypes.diningBtn.filter(x => !!x.keyGroupId)
            }
          });
          this.toast('toast.jobRoleUpdate');
        } else {
          await postEmployeeFunction({
            ...employeeAccessType,
            accessTypes: {
              ...employeeAccessType.accessTypes,
              diningBtn: employeeAccessType.accessTypes.diningBtn.filter(x => !!x.keyGroupId)
            }
          });
          this.toast('toast.jobRoleCreate');
        }
        /**/
        this.$emit('update:pending', false);
        this.$emit('success', employeeAccessType);
        this.$refs.sidebarAccessTypeForm.hide();
        this.$refs.form.reset();
      } catch (e) {
        console.error(e);
        this.$emit('update:error', true);
        this.$emit('update:pending', false);
        this.$emit('failure', employeeAccessType);
      }
    }
  }
};
</script>
