export default {
  props: {
    locationId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      mutableLocationId: this.locationId
    };
  },
  methods: {
    handleLocationChange(locationId) {
      this.mutableLocationId = locationId;
      this.$router.replace({
        name: this.$router.history.current.name,
        query: {
          ...this.$router.history.current.query,
          groupId: null,
          locationId
        }
      });
    }
  }
};
