import { get, patch, post, put, remove } from '@/helpers/service.helper';

const GROUPS_PATH = '/groups';

/**
 * Get groups
 * @link https://api.posveloce.com/docs/#operation/getGroups
 */
const getGroups = token => get(token, `${GROUPS_PATH}`);

/**
 * Create group
 * @link https://api.posveloce.com/docs/#operation/createGroup
 */
const createGroup = (token, group) => post(token, `${GROUPS_PATH}`, group);

/**
 * Get group by id
 * @link https://api.posveloce.com/docs/#operation/getGroupById
 */
const getGroup = (token, groupId) => get(token, `${GROUPS_PATH}/${groupId}`);

/**
 * Update group by id
 * @link https://api.posveloce.com/docs/#operation/updateGroup
 */
const updateGroup = (token, group) => put(token, `${GROUPS_PATH}/${group.id}`, group);

/**
 * Delete group by id
 * @link https://api.posveloce.com/docs/#operation/deleteGroup
 */
const deleteGroup = (token, groupId) => remove(token, `${GROUPS_PATH}/${groupId}`);

/**
 * Get locations attached to a group
 * @link https://api.posveloce.com/docs/#operation/getLocationsByGroupId
 */
const getGroupLocations = (token, groupId) => get(token, `${GROUPS_PATH}/${groupId}/locations`);

/**
 * Attach locations to a group
 * @link https://api.posveloce.com/docs/#operation/addLocationsToGroup
 */
const addGroupLocations = (token, groupId, locationIds) =>
  patch(token, `${GROUPS_PATH}/${groupId}/locations`, locationIds);

/**
 * Detach a location from a group
 * @link https://api.posveloce.com/docs/#operation/removeLocationsFromGroup
 */
const deleteGroupLocation = (token, groupId, locationId) =>
  remove(token, `${GROUPS_PATH}/${groupId}/locations/${locationId}`);

/**
 * Get group permissions
 * @link https://api.posveloce.com/docs/#operation/getGroupPermissions
 */
const getGroupPermissions = (token, groupId) => get(token, `${GROUPS_PATH}/${groupId}/permissions`);

/**
 * Get products attached to a group
 * @link https://api.posveloce.com/docs/#operation/getProductsByGroupId
 */
const getProductsByGroupId = (token, groupId) => get(token, `${GROUPS_PATH}/${groupId}/products`);

/**
 * Attach products to a group
 * @link https://api.posveloce.com/docs/#operation/addProductsToGroup
 */
const addProductsToGroup = (token, groupId, locationIds) =>
  patch(token, `${GROUPS_PATH}/${groupId}/products`, locationIds);

/**
 * Detach a product from a group
 * @link https://api.posveloce.com/docs/#operation/removeProductsFromGroup
 */
const removeProductsFromGroup = (token, groupId, locationId) =>
  remove(token, `${GROUPS_PATH}/${groupId}/products/${locationId}`);

export default {
  addGroupLocations,
  getGroup,
  createGroup,
  deleteGroup,
  deleteGroupLocation,
  getGroups,
  getGroupLocations,
  updateGroup,
  getGroupPermissions,
  getProductsByGroupId,
  addProductsToGroup,
  removeProductsFromGroup
};
