<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="600px"
    right
    id="mealPlanForm-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarMealPlanForm"
    @hidden="mealPlan = null"
  >
    <template #header>
      <h6 class="bg-gray-200 bg-light w-100 mb-0 text-center">
        {{ $t(mealPlan && mealPlan.id ? 'employeeCreate.mealPlan.title' : 'employeeCreate.mealPlanEdit.title') }}
      </h6>
    </template>
    <template #footer="{ hide }">
      <div
        class="d-flex bg-light text-dark align-items-center justify-content-between px-3 py-2"
        style="border-top: 2px solid #d6d6d8;"
      >
        <b-button
          :disabled="!($refs.form && $refs.form.enableSaveChanges)"
          variant="primary"
          f
          @click="updateMealPlan()"
        >
          {{ $t(mealPlan && mealPlan.id ? 'employeeCreate.btns.saveChanges' : 'employeeCreate.btns.save') }}
        </b-button>
        <b-button
          variant="outline-primary"
          f
          @click="
            hide();
            $refs.form.reset(null);
            $emit('update:mealPlan', null);
          "
        >
          {{ $t('employeeCreate.btns.cancel') }}
        </b-button>
      </div>
    </template>
    <b-container fluid class="p-4" v-loading="pending">
      <meal-plan-form :licenceNumber="employee.licenceNumber" :meal-plan.sync="mealPlan" ref="form"></meal-plan-form>
    </b-container>
  </b-sidebar>
</template>
<script>
import { patchEmployeeMealPlan, postEmployeeMealPlan } from '@/services/employees.service';
import MealPlanForm from '@/private/components/meal-plan/components/MealPlanForm';

export default {
  name: 'employee-sidebar-form-time-attendance',
  components: {
    MealPlanForm
  },
  props: {
    mealPlan: {
      type: Object,
      default: () => ({
        name: '',
        excludeBenefit: false,
        licenceNumber: 0,
        eligibility: [
          {
            duration: null,
            amount: null
          }
        ]
      })
    },
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    licenceNumber: {
      type: Number,
      default: 0
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    employeeMealPlan() {
      return {
        ...this.$refs.form.form,
        eligibility: this.$refs.form.form.eligibility.map(e => ({
          duration: Number(e.duration),
          amount: Number(Number(e.amount).toFixed(2))
        })),
        licenceNumber: this.licenceNumber || this.employee.licenceNumber || null
      };
    }
  },
  methods: {
    async updateMealPlan() {
      try {
        this.$emit('update:pending', true);
        if (this.employeeMealPlan.id) {
          await patchEmployeeMealPlan(this.employeeMealPlan.id, this.employeeMealPlan);
        } else {
          await postEmployeeMealPlan(this.employeeMealPlan);
        }
        this.$emit('update:pending', false);
        this.$emit('success', this.employeeMealPlan);
        this.$refs.sidebarMealPlanForm.hide();
        this.$refs.form.reset();
      } catch (e) {
        this.$emit('update:error', true);
        this.$emit('update:pending', false);
        this.$emit('failure', this.employeeMealPlan);
      }
    }
  }
};
</script>
