<template>
  <vel-modal class="coupon-delete__modal" dismissable @dismiss="handleCancel">
    <template v-slot:header>
      {{ $t('coupons.modals.batchDelete.title') }}
    </template>
    <template v-slot:default>
      <span
        class="coupon-delete__confirmation"
        v-html="$t('coupons.modals.batchDelete.textHtmlConfirm', { count: count, group: getSelectedGroup.label })"
      />
    </template>
    <template v-slot:footer>
      <vel-button class="coupon-delete__close-button" type="secondary" @click="handleCancel">
        {{ $t('defaults.actions.cancel') }}
      </vel-button>
      <vel-button class="coupon-delete__confirm-button" type="critical" @click="handleDelete">
        {{ $t('coupons.modals.delete.button.text') }}
      </vel-button>
    </template>
  </vel-modal>
</template>

<script>
import VelButton from '@/components/button/VelButton';
import VelModal from '@/components/modal/VelModal';
import { mapState as mapPiniaState } from 'pinia';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';

export default {
  name: 'batch-delete-coupons-confirm-modal',
  components: {
    VelButton,
    VelModal
  },
  props: {
    count: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapPiniaState(useUIGroupSelector, {
      getSelectedGroup: 'getSelectedGroup'
    })
  },
  methods: {
    handleCancel() {
      this.$emit('dismiss');
    },
    handleDelete() {
      this.$emit('confirm');
    }
  }
};
</script>
<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.delete-coupon__error {
  margin-bottom: rem-calc(16);
}
</style>
