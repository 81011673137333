<template>
  <div>
    <b-modal
      v-model="showDeleteModal"
      :title="title"
      :static="true"
      :centered="true"
      :hide-header-close="true"
      :footer-bg-variant="'light'"
      :footer-text-variant="'dark'"
      @hidden="
        showList = false;
        $emit('onCancel', true);
      "
    >
      <div v-html="body"></div>
      <template #modal-footer>
        <b-container fluid>
          <div class="w-100">
            <b-button variant="primary" class="float-right ml-2" @click="download">
              {{ $t('itemsMixMatchList.deleteModal.error.download') }}
            </b-button>
            <b-button variant="link" class="float-right" @click="showDeleteModal = null">
              {{ $t('itemsMixMatchList.deleteModal.error.close') }}
            </b-button>
          </div>
        </b-container>
      </template>
    </b-modal>
  </div>
</template>
<script>
import ExportXlsMixin, { formatRow } from '@/mixins/export-xls-mixin';
import { DateTime } from 'luxon';
import XLSX from 'xlsx';
import { getDateFormatFromUserConfig, getTimeFormatFromUserConfig } from '@/helpers/date.helpers';

export default {
  name: 'EmployeeDeleteErrorModal',
  components: {},
  mixins: [ExportXlsMixin],
  props: {
    title: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: ''
    },
    fileIdentifier: {
      type: String,
      default: 'data'
    },
    fileName: {
      type: String,
      default: 'export'
    },
    titleLabel: {
      type: String,
      default: ''
    },
    locationName: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    show(value) {
      this.showDeleteModal = value;
    }
  },
  data() {
    return {
      showDeleteModal: false,
      showList: false
    };
  },
  computed: {},
  methods: {
    download() {
      const columns = [
        {
          key: 'name',
          title: 'Item',
          width: 160
        }
      ];
      const rows = (this.list || []).map(e => [{ key: 'name', value: e.name }]);

      const totalRow = [];
      const fileName = this.$t('itemsMixMatchList.deleteModal.exportFileName', {
        fileName: this.fileName,
        name: this.fileIdentifier
      });

      const extension = 'xlsx';
      const header = columns.map(a => ({ key: a.key, value: a.title }));

      const metasSpace = [[], [], [], [], [], [], [], [], [], []];
      const locationTitle = this.locationName;
      const data = [...metasSpace, [...header], ...rows, ...totalRow].map(row => formatRow(columns, row));

      const workBook = XLSX.utils.book_new();
      const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: true });

      workBook.Props = {
        Title: fileName,
        CreatedDate: new Date()
      };

      const ws_data = [
        [],
        [],
        [this.title.toUpperCase()],
        [],
        [this.$t('employees.header.locationName'), locationTitle],
        [],
        [
          this.$t('mealPlan.deleteModal.date'),
          DateTime.fromJSDate(new Date()).toFormat(getDateFormatFromUserConfig()),
          this.$t('mealPlan.deleteModal.time'),
          DateTime.fromJSDate(new Date()).toFormat(getTimeFormatFromUserConfig())
        ],
        [],
        [this.titleLabel]
      ];

      XLSX.utils.sheet_add_aoa(workSheet, ws_data);

      workSheet['!merges'] = [
        { s: { c: 0, r: 2 }, e: { c: 8, r: 2 } },
        { s: { c: 1, r: 4 }, e: { c: 8, r: 4 } },
        { s: { c: 0, r: 8 }, e: { c: 8, r: 8 } }
      ];

      XLSX.utils.book_append_sheet(workBook, workSheet, this.$t('itemsMixMatchList.title'));
      workSheet['!cols'] = this.fitToColumn(
        columns.map(col => col.title),
        data
      ); // Will only work on XLSX

      XLSX.writeFile(workBook, `${fileName}.${extension}`);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ {
  .modal-header {
    border: none;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
  }

  .modal-body {
    padding: 0 1.5rem 1.5rem;
  }
}
</style>
