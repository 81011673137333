<template>
  <span class="vel-form-error">{{ error }}</span>
</template>

<script>
export default {
  name: 'vel-form-error',
  props: {
    error: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

.vel-form-error {
  align-self: flex-start;
  color: red;
  font-size: rem-calc(15);
  font-weight: $font-light;
  @include breakpoint(medium) {
    font-size: rem-calc(13);
  }
}
</style>
