<template>
  <vel-page
    :multiple-locations="locationsList && locationsList.length > 1"
    :enable-date-range="true"
    class="items__report"
    :show-selector-button="true"
    v-bind:class="{ modal: showConfig }"
    :showCrumbs="false"
    :enable-toolbar="true"
    :enable-currency="true"
    :generatedTime="generatedTime"
  >
    <template v-slot:export>
      <vel-button
        :disabled="areSalesLoading"
        :loading="generating"
        type="primary"
        @click="exportXLSX"
        :icon="isMediumUp ? 'file-excel' : undefined"
        class="vel-button"
      >
        <vel-icon v-if="isMediumDown" name="file-download" />
        {{ isMediumUp ? $t('table.tableExport.button.value') : '' }}
      </vel-button>
    </template>
    <template v-slot:title>
      {{ $t('pages.reports.items') }}
    </template>
    <vel-modal v-if="showConfig" dismissable @dismiss="showConfig = false">
      <template v-slot:header>
        {{ $t('editors.customize_link') }}
      </template>
      <config-items-report-display @update="$forceUpdate()"></config-items-report-display>
    </vel-modal>
    <template v-slot:toolbarMenu>
      <vel-elink type="info" @click="showConfig = true">{{ $t('editors.customize_link') }}</vel-elink>
    </template>
    <vel-card class="report__items">
      <item-sales-report
        :sales="getSalesTree"
        :days="daysInRange"
        :areSalesLoading="areSalesLoading"
        :multipleLocation="multipleLocation"
        :hidden-columns="hiddenColumns"
        @input="handleInput"
      />
    </vel-card>
  </vel-page>
</template>

<script>
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import ConfigItemsReportDisplay from '@/private/components/config/appearance/items-report/ConfigItemsReportDisplay';
import GeneratedTime from '@/mixins/generated-time-mixin';
import ItemSalesReport from '@/private/components/sales/items/ItemSalesReport.vue';
import SelectableDateRangeMixin from '@/mixins/selectable-date-range-mixin';
import SelectableLocationMixin from '@/mixins/selectable-location-mixin';
import VelCard from '@/components/card/VelCard';
import VelElink from '@/components/elink/VelElink';
import VelModal from '@/components/modal/VelModal';
import VelPage from '@/components/page/VelPage';
import pickBy from 'lodash.pickby';
import { useUICurrencySelector } from '@/stores/ui/currency-selector.module';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { useDataConfig } from '@/stores/data/config.module';
import { useDataGroups } from '@/stores/data/groups.module';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataSales } from '@/stores/data/sales.module';
import { useDataSalesIndicators } from '@/stores/data/sales/indicators.module';
import { useDataUsersUserPermissions } from '@/stores/data/users/user-permissions.module';
import router from '@/router';
import salesService from '@/services/sales.service';
import { DateTime } from 'luxon';
import { useUIDateRangeSelector } from '@/stores/ui/date-range-selector.module';
import VelIcon from '@/components/icon/VelIcon.vue';
import VelButton from '@/components/button/VelButton.vue';
import DeviceMixin from '@/mixins/device-mixin';

export default {
  name: 'ItemReportPage',
  components: {
    VelButton,
    VelIcon,
    ConfigItemsReportDisplay,
    ItemSalesReport,
    VelCard,
    VelPage,
    VelElink,
    VelModal
  },
  mixins: [SelectableDateRangeMixin, SelectableLocationMixin, GeneratedTime, DeviceMixin],
  data() {
    return {
      showConfig: false,
      generating: false
    };
  },
  updater: {
    interval: 5 * 60 * 1000
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, { locationsList: 'locations' }),
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useDataSalesIndicators, ['indicators']),
    ...mapPiniaState(useUILocationSelector, {
      locations: 'locations',
      hasLocationsError: 'hasError',
      locationsLoading: 'isLoading',
      getLocationSelectorSelected: 'getLocationSelectorSelected'
    }),
    ...mapPiniaState(useUICurrencySelector, ['getCurrencySelectorSelected']),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsById']),
    ...mapPiniaState(useDataSales, {
      getSalesTreeByCurrency: 'getSalesTreeByCurrency',
      areSalesLoading: 'areSalesLoading',
      salesLocation: 'location',
      filter: 'getFilter'
    }),
    ...mapPiniaState(useDataUsersUserPermissions, ['isUserAdmin']),
    ...mapPiniaState(useUIGroupSelector, ['getGroupSelectorSelected']),
    ...mapPiniaState(useDataGroups, ['getGroupsRows']),
    ...mapPiniaState(useUIDateRangeSelector, ['getDateSelectorSelectedRange']),
    ...mapPiniaState(useUILocationSelector, {
      locationCount: 'getLocationSelectorSelectedMultipleLength',
      locationListSelected: 'getLocationSelectorSelectedMultipleEntities',
      locationIds: 'getLocationSelectorSelectedMultipleIds'
    }),
    ...mapPiniaState(useUIGroupSelector, ['getGroupSelectorSelected']),
    multipleLocation() {
      return !this.mutableLocationId;
    },
    getSalesTree() {
      return this.getSalesTreeByCurrency(this.getCurrencySelectorSelected);
    },
    displayConfig() {
      return this.get;
    },
    hiddenColumns() {
      return {
        quantity: this.displayConfig.items_column_quantity,
        salesPercent: this.displayConfig.items_column_sales_percent,
        salesPercentOverall: this.displayConfig.items_column_sales_percent_overall,
        salesAmount: this.displayConfig.items_column_sales_amount,
        averageVolumeUnit: this.displayConfig.items_column_average_unit
      };
    },
    daysInRange() {
      return (
        Math.round((new Date(this.mutableRange[1]).getTime() - new Date(this.mutableRange[0]).getTime()) / 0x5265c00) ||
        0
      );
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    ...mapPiniaActions(useDataSalesIndicators, ['getIndicators']),
    ...mapPiniaActions(useDataSales, ['getLocation', 'getSalesByItemReportWithLevels', 'setFilter']),
    ...mapPiniaActions(useDataSales, ['reset']),
    async fetchIndicators() {
      await this.getIndicators({
        includeDiscounts: true
      });
    },
    async exportXLSX() {
      this.generating = true;

      const from = this.getDateSelectorSelectedRange[0];
      const to = this.getDateSelectorSelectedRange[1];

      const routeQuery = {
        locationIds: this.locationIds,
        groupId: this.getGroupSelectorSelected,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO()
      };
      await salesService.downloadItemSalesXlsx(routeQuery).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    },
    fetchSales() {
      this.getSalesByItemReportWithLevels(
        pickBy({ from: this.mutableRange[0], to: this.mutableRange[1], locationId: this.mutableLocationId }, a => !!a)
      );
    },
    refresh() {
      this.fetchSales();
    },
    handlePeriodicUpdate() {
      if (!this.isUserAdmin) {
        this.updater = setTimeout(this.refresh, this.$options.updater.interval);
      }
    },
    handleInput(value) {
      this.setFilter(value);
    },
    getCurrentGroupName() {
      const selectedGroup = (this.getGroupsRows || []).filter(group => group.id === this.getGroupSelectorSelected);
      if (!selectedGroup.length) {
        return null;
      }
      return `${this.$t('exports.groupLabel')} ${selectedGroup[0].name}`;
    }
  },
  mounted() {
    this.fetchIndicators();
    this.fetchSales();
    this.handlePeriodicUpdate();
    // this.generateTimeAt('data/sales/products/getItemsSalesSuccess');
  },
  beforeDestroy() {
    this.gen(false);
    clearTimeout(this.updater);
    this.reset();
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

.report__items {
  overflow-x: scroll;

  @include breakpoint(large) {
    overflow-x: hidden;
  }
}

/deep/ .vel-page__toolbar {
  margin-bottom: 1.25rem;
}

// hack - fix stacking order on safari.
.modal /deep/ .vel-page__content {
  overflow-x: visible;
  overflow-y: visible;
}
.vel-button {
  @include noPrint;
  background-color: $mountain-meadow;
  border-color: $mountain-meadow;
  direction: rtl;

  &:hover {
    background-color: mix(black, $mountain-meadow, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}
</style>
