import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { createCollectionState, getSelectors, removeAll, setOne } from '@/helpers/stores.helpers';
import divisionsService from '@/services/divisions.service';
import router from '@/router';

const { selectTotal } = getSelectors();

export const useDataDivisionsEdit = defineStore('divisionsEdit', {
  state: () =>
    createCollectionState({
      loading: true,
      pending: true,
      error: null
    }),
  getters: {
    selectTotal
  },
  actions: {
    async updateDivision(division) {
      try {
        this.updateDivisionRequest();
        const res = await divisionsService.updateDivision(getToken(), [
          {
            ...division,
            remoteId: division.remoteId - 1
          }
        ]);

        await router.replace({
          name: 'divisions'
        });

        this.updateDivisionSuccess(res);
      } catch (error) {
        this.updateDivisionFailure(error);
      }
    },
    updateDivisionRequest() {
      this.loading = true;
      this.error = null;
    },
    updateDivisionSuccess(division) {
      this.data = setOne(division, this.data);
      this.error = null;
      this.loading = false;
    },
    updateDivisionFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    clearMessage() {
      this.data = removeAll(this.data);
    }
  }
});
