<template>
  <vel-datepicker
    align="center"
    class="date-range-selector"
    type="daterange"
    v-model="editedRange"
    :clearable="clearable"
    :editable="isEditable"
    :disabled="disabled"
    :format="format"
    :picker-options="options"
    :unlink-panels="false"
    @change="handleChange"
  />
</template>

<script>
import { DateTime } from 'luxon';
import DeviceMixin from '@/mixins/device-mixin';
import VelDatepicker from '@/components/datepicker/VelDatepicker';

export default {
  name: 'date-range-selector',
  components: {
    VelDatepicker
  },
  mixins: [DeviceMixin],
  props: {
    range: {
      type: Array
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editedRange: this.range
    };
  },
  watch: {
    range() {
      this.editedRange = this.range;
    }
  },
  computed: {
    isEditable() {
      return !this.isMediumDown;
    },
    options() {
      const self = this;
      return {
        disabledDate: this.isDisabledDate,
        shortcuts: [
          {
            text: this.$t('datePicking.shortCut.currentWeek'),
            onClick(picker) {
              picker.$emit('pick', self.getCurrentWeekRange());
            }
          },
          {
            text: this.$t('datePicking.shortCut.currentMonth'),
            onClick(picker) {
              picker.$emit('pick', self.getCurrentMonthRange());
            }
          },
          {
            text: this.$t('datePicking.shortCut.currentYear'),
            onClick(picker) {
              picker.$emit('pick', self.getCurrentYearRange());
            }
          },
          {
            text: this.$t('datePicking.shortCut.oneWeek'),
            onClick(picker) {
              picker.$emit('pick', self.getRangeOf1WeekBeforeNow());
            }
          },
          {
            text: this.$t('datePicking.shortCut.oneMonth'),
            onClick(picker) {
              picker.$emit('pick', self.getRangeOf1MonthBeforeNow());
            }
          },
          {
            text: this.$t('datePicking.shortCut.threeMonths'),
            onClick(picker) {
              picker.$emit('pick', self.getRangeOf3MonthsBeforeNow());
            }
          }
        ]
      };
    }
  },
  methods: {
    isDisabledDate(date) {
      return DateTime.fromJSDate(date) >= DateTime.local().plus({ day: 1 });
    },
    getCurrentWeekRange() {
      return [DateTime.local().set({ weekday: 1 }).toJSDate(), DateTime.local().toJSDate()];
    },
    getCurrentMonthRange() {
      return [DateTime.local().set({ day: 1 }).toJSDate(), DateTime.local().toJSDate()];
    },
    getCurrentYearRange() {
      return [DateTime.local().set({ day: 1, month: 1 }).toJSDate(), DateTime.local().toJSDate()];
    },
    getRangeOf1WeekBeforeNow() {
      return [DateTime.local().minus({ week: 1 }).toJSDate(), DateTime.local().toJSDate()];
    },
    getRangeOf1MonthBeforeNow() {
      return [DateTime.local().minus({ month: 1 }).toJSDate(), DateTime.local().toJSDate()];
    },
    getRangeOf3MonthsBeforeNow() {
      return [DateTime.local().minus({ months: 3 }).toJSDate(), DateTime.local().toJSDate()];
    },
    handleChange() {
      this.$emit('change', this.editedRange);
    }
  }
};
</script>

<style lang="scss">
@import 'mixins.scss';

.date-range-selector {
  @include noPrint;
}
</style>
