<template>
  <div class="vel-announce" id="int-announcement"></div>
</template>

<script>
import { oc } from 'ts-optchain';
import postscribe from 'postscribe';
import { mapState, storeToRefs } from 'pinia';
import { useDataAccount } from '@/stores/data/account.module';

export const PRODUCT_ID = 'zjomVWQb15655';

/* eslint-disable no-useless-escape */
export default {
  name: 'vel-announce',
  props: {
    selector: {
      type: String,
      default: '.announcements__action'
    }
  },
  setup() {
    const dataccount = useDataAccount();
    const { account } = storeToRefs(dataccount);

    return { account };
  },
  watch: {
    account(newAccount) {
      window.beamer_config = {
        ...window.beamer_config,
        user_firstname: oc(newAccount).firstName(),
        user_lastname: oc(newAccount).lastName(),
        user_email: oc(newAccount).email()
      };
    }
  },
  mounted() {
    window.beamer_config = {
      product_id: PRODUCT_ID,
      selector: this.selector,
      user_firstname: oc(this).account.firstName(),
      user_lastname: oc(this).account.lastName(),
      user_email: oc(this).account.email()
    };

    postscribe(
      '#int-announcement',
      `<script type="text/javascript" src="https://app.getbeamer.com/js/beamer-embed.js" defer="defer"><\/script>`
    );
  }
};
/* eslint-enable no-useless-escape */
</script>

<style lang="scss">
@import 'constants.scss';

/* stylelint-disable */
.beamer_icon {
  background-color: $ebony-clay !important;
}
/* stylelint-enable */
</style>
