<script>
import { Divider } from 'element-ui';

export default {
  name: 'vel-divider',
  extends: Divider
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';
</style>
