<script>
import { Checkbox } from 'element-ui';

export default {
  name: 'vel-checkbox',
  extends: Checkbox
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #444;
}
</style>
