import { defineStore } from 'pinia';

export const useUIAppStore = defineStore('app', {
  state: () => ({
    status: { isReady: true },
    popover: null,
    expanded: true,
    generatedTime: null
  }),
  getters: {
    isPopoverActive: state => name => state.popover === name,
    hasActivePopover: state => state.popover !== null,
    isExpanded: state => state.expanded,
    isReady: state => state.status.isReady
  },
  actions: {
    setReady() {
      this.status = { isReady: true };
    },
    setActivePopover(name) {
      this.popover = name;
    },
    resetPopover() {
      this.popover = null;
    },
    setExpanded(isExpanded) {
      this.expanded = isExpanded;
    },
    setGeneratedTime(dateTime) {
      this.generatedTime = dateTime;
    }
  }
});
