<template>
  <pie-line-chart
    :pending="areSalesLoading"
    :options="chartOptions"
    :series="series"
    :distributed="true"
    :legend="false"
    :labels="labels"
    :currency="currencyId"
    :title="$t('sales.employeesSalesChart.title', { limit: get.charts_size_limitation.value })"
    :type="get.default_chart_items_sales.value"
    class="items-sales-graph"
  />
</template>

<script>
import PieLineChart from '@/components/pie-line-chart/PieLineChart';
import { mapState as mapPiniaState } from 'pinia';
import { useDataSalesEmployees } from '@/stores/data/sales/employees.module';
import { mapActions as mapPiniaActions } from 'pinia';
import { useDataConfig } from '@/stores/data/config.module';

export default {
  name: 'employee-graph',
  components: {
    PieLineChart
  },
  props: {
    currencyId: {
      type: String,
      default: null
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),

    ...mapPiniaState(useDataSalesEmployees, ['areSalesLoading', 'chartData']),

    chartOptions() {
      return {
        xaxis: {
          title: {
            text: this.$t('sales.employeesSalesChart.xaxis')
          }
        },
        yaxis: {
          title: {
            text: this.$t('sales.employeesSalesChart.yaxis')
          }
        }
      };
    },

    employeesSalesChartCategories() {
      const count = this.topCount || this.get.charts_size_limitation.value;

      return this.chartData(count).map(sale => sale.label);
    },
    employeesSalesChartData() {
      const count = this.topCount || this.get.charts_size_limitation.value;

      return this.chartData(count).map(sale => sale.salesAmount);
    },
    labels() {
      return this.employeesSalesChartCategories.slice(0, this.get.charts_size_limitation.value);
    },
    series() {
      const employeesSales = {
        name: this.$t('sales.top10EmployeesChart.sales'),
        data: this.employeesSalesChartData.slice(0, this.get.charts_size_limitation.value)
      };

      return [employeesSales];
    }
  },
  methods: {
    ...mapPiniaActions(useDataSalesEmployees, ['getSalesByEmployeeWithLevels', 'setFilter', 'reset'])
  },
  mounted() {
    this.getSalesByEmployeeWithLevels();
  }
};
</script>
