<template>
  <pie-line-chart
    :pending="isPending"
    :options="chartOptions"
    :series="series"
    :labels="labels"
    :currency="currencyId"
    :title="$t('sales.bigDivisionChart.title', { limit })"
    :type="get.default_chart_sales_per_big_division.value"
    class="hourly-sales__chart"
  />
</template>

<script>
import PieLineChart from '@/components/pie-line-chart/PieLineChart';
import { mapActions as mapPiniaActions } from 'pinia/dist/pinia';
import { useDataSalesBigDivisions } from '@/stores/data/sales/big-divisions.module';
import { mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataConfig } from '@/stores/data/config.module';
export default {
  name: 'big-division-sales-graph',
  components: {
    PieLineChart
  },
  props: {
    currencyId: {
      type: String,
      default: null
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useDataSalesBigDivisions, ['isPending', 'getSeries', 'getLabels']),
    series() {
      return [
        {
          name: this.$t('sales.bigDivisionChart.series.title', { currency: this.currencyId }),
          data: this.getSeries(this.currencyId).slice(0, this.limit)
        }
      ];
    },
    labels() {
      return this.getLabels(this.currencyId).slice(0, this.limit);
    },
    chartOptions() {
      return {
        xaxis: {
          title: {
            text: this.$t('sales.bigDivisionChart.xaxis')
          }
        },
        yaxis: {
          title: {
            text: this.$t('sales.bigDivisionChart.yaxis')
          }
        }
      };
    }
  },
  methods: {
    ...mapPiniaActions(useDataSalesBigDivisions, ['getSalesByBigDivision'])
  },
  mounted() {
    this.getSalesByBigDivision();
  }
};
</script>
