<template>
  <indicator :loading="loading" :amount="amount" :previous-amount="previousAmount" iconName="users-solid" color="blue">
    <template v-slot:title>
      {{ $t('dashboard.customersCount.title') }}
    </template>
    <template v-slot:tooltip>
      {{ $t('dashboard.customersCount.description') }}
    </template>
    <template v-slot:value>
      {{ amount }}
    </template>
  </indicator>
</template>

<script>
import Indicator from '@/components/indicator/Indicator.vue';

export default {
  name: 'customer-amount',
  components: {
    Indicator
  },
  props: {
    amount: {
      default: 0
    },
    previousAmount: {
      default: null
    },
    loading: {
      default: true
    }
  }
};
</script>
