<template>
  <vel-page :show-crumbs="false" :generation="false">
    <template slot="title">{{ $t('setupMenuPage.title') }}</template>

    <template slot="export">
      <span class="toolbar" v-if="isMediumUp">
        <template>{{ $t('setupMenuPage.toolbarLabel') }}</template>
        <toolbar-selectors :enable-group="false" :enable-all-locations="false" :enable-date-picker="false" />
      </span>
    </template>
    <span class="toolbar mobile" v-if="isMediumDown">
      <toolbar-selectors :enable-group="false" :enable-all-locations="false" :enable-date-picker="false" />
    </span>

    <div class="submenu">
      <div class="submenu-content">
        <vel-button
          v-if="isUserAdmin"
          :class="selected === 'discounts' ? 'selected' : ''"
          @click="handleSelect('discounts')"
        >
          {{ $t('setupMenuPage.screenSelector.discounts') }}
        </vel-button>
        <!-- <vel-button @click="handleSelect('timezones')">{{ $t('setupMenuPage.screenSelector.timezones') }}</vel-button> -->
        <!-- <vel-button @click="handleSelect('department')">{{ $t('setupMenuPage.screenSelector.department') }}</vel-button> -->
        <!-- <vel-button @click="handleSelect('section')">{{ $t('setupMenuPage.screenSelector.section') }}</vel-button> -->
        <vel-button v-if="isUserAdmin" :class="selected === 'menu' ? 'selected' : ''" @click="handleSelect('menu')">
          {{ $t('setupMenuPage.screenSelector.menu') }}
        </vel-button>
        <!-- <vel-button @click="handleSelect('hardware')">{{ $t('setupMenuPage.screenSelector.hardware') }}</vel-button> -->
        <vel-button
          v-if="isUserAdmin"
          :class="selected === 'business-settings' ? 'selected' : ''"
          @click="handleSelect('business-settings')"
        >
          {{ $t('setupMenuPage.screenSelector.business') }}
        </vel-button>
        <vel-button v-if="isUserAdmin" :class="selected === 'taxes' ? 'selected' : ''" @click="handleSelect('taxes')">
          {{ $t('setupMenuPage.screenSelector.taxes') }}
        </vel-button>
        <!-- <vel-button @click="handleSelect('payments')">{{ $t('setupMenuPage.screenSelector.payments') }}</vel-button> -->
        <vel-button :class="selected === 'settings' ? 'selected' : ''" @click="handleSelect('settings')">
          {{ $t('setupMenuPage.screenSelector.settings') }}
        </vel-button>
        <vel-button :class="selected === 'admin' ? 'selected' : ''" @click="handleSelect('admin')">
          {{ $t('setupMenuPage.screenSelector.admin') }}
        </vel-button>
        <vel-button
          v-if="isUserAdmin"
          :class="selected === 'language-setup' ? 'selected' : ''"
          @click="handleSelect('language-setup')"
        >
          {{ $t('setupMenuPage.screenSelector.language-setup') }}
        </vel-button>
        <vel-button :class="selected === 'system-setup' ? 'selected' : ''" @click="handleSelect('system-setup')">
          {{ $t('setupMenuPage.screenSelector.system') }}
        </vel-button>
      </div>
    </div>
    <div class="content">
      <div v-if="selected === 'discounts'" class="discounts">
        <h4>{{ $t('setupMenuPage.screens.titles.discounts') }}</h4>
        <router-link :to="{ name: 'discounts' }">
          {{ $t('setupMenuPage.screens.links.discounts.promos') }}
        </router-link>
        <!-- <router-link to="#">
          {{ $t('setupMenuPage.screens.links.discounts.qsa') }}
        </router-link> -->
      </div>
      <!-- <div v-if="selected === 'timezones'" class="timezones">
        <h4>{{ $t('setupMenuPage.screens.titles.timezones') }}</h4>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.timezones.breakfast') }}
        </router-link>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.timezones.lunch') }}
        </router-link>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.timezones.dinner') }}
        </router-link>
      </div> -->
      <!-- <div v-if="selected === 'department'" class="department">
        <h4>{{ $t('setupMenuPage.screens.titles.department') }}</h4>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.department.dining') }}
        </router-link>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.department.takeouts') }}
        </router-link>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.department.bar') }}
        </router-link>
      </div> -->
      <!-- <div v-if="selected === 'section'" class="section">
        <h4>{{ $t('setupMenuPage.screens.titles.sections') }}</h4>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.sections.tables') }}
        </router-link>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.sections.tables-states') }}
        </router-link>
      </div> -->
      <div v-if="selected === 'menu'" class="menu">
        <h4>{{ $t('setupMenuPage.screens.titles.menu') }}</h4>
        <router-link :to="{ name: 'bigdivisions' }">
          {{ $t('setupMenuPage.screens.links.menu.categories') }}
        </router-link>
        <router-link :to="{ name: 'divisions' }">
          {{ $t('setupMenuPage.screens.links.menu.sub-categories') }}
        </router-link>
        <router-link :to="{ name: 'salesItems' }">
          {{ $t('setupMenuPage.screens.links.menu.items') }}
        </router-link>
        <router-link :to="{ name: 'item-profile' }">
          {{ $t('setupMenuPage.screens.links.menu.itemProfile') }}
        </router-link>
        <router-link :to="{ name: 'popups' }">
          {{ $t('setupMenuPage.screens.links.menu.popups') }}
        </router-link>
        <router-link :to="{ name: 'combos' }">
          {{ $t('setupMenuPage.screens.links.menu.combos') }}
        </router-link>
        <router-link :to="{ name: 'mix-match' }">
          {{ $t('setupMenuPage.screens.links.menu.mix-match') }}
        </router-link>
        <router-link :to="{ name: 'coursing' }">
          {{ $t('setupMenuPage.screens.links.menu.coursing') }}
        </router-link>
        <router-link :to="{ name: 'big-departments-setup' }">
          {{ $t('setupMenuPage.screens.links.menu.big-departments') }}
        </router-link>
        <router-link :to="{ name: 'counters' }">
          {{ $t('setupMenuPage.screens.links.menu.counters') }}
        </router-link>
        <!-- <router-link to="#">
          {{ $t('setupMenuPage.screens.links.menu.additive') }}
        </router-link>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.menu.addons') }}
        </router-link>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.menu.modifiers') }}
        </router-link> -->
      </div>
      <!-- <div v-if="selected === 'hardware'" class="hardware">
        <h4>{{ $t('setupMenuPage.screens.titles.hardware') }}</h4>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.hardware.terminals') }}
        </router-link>
        <div class="sub-router-link">
          <router-link to="#">{{ $t('setupMenuPage.screens.links.hardware.workstations') }}</router-link>
          <router-link to="#">{{ $t('setupMenuPage.screens.links.hardware.tablets') }}</router-link>
          <router-link to="#">{{ $t('setupMenuPage.screens.links.hardware.mobile') }}</router-link>
        </div>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.hardware.scales') }}
        </router-link>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.hardware.bar') }}
        </router-link>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.hardware.kds') }}
        </router-link>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.hardware.printers') }}
        </router-link>
        <div class="sub-router-link">
          <router-link to="#">{{ $t('setupMenuPage.screens.links.hardware.checks') }}</router-link>
          <router-link to="#">{{ $t('setupMenuPage.screens.links.hardware.requisitions') }}</router-link>
          <router-link to="#">{{ $t('setupMenuPage.screens.links.hardware.labels') }}</router-link>
        </div>
      </div> -->
      <div v-if="selected === 'business-settings'" class="business-settings">
        <h4>{{ $t('setupMenuPage.screens.titles.business') }}</h4>
        <router-link :to="{ name: 'location-configuration' }">
          {{ $t('setupMenuPage.screens.links.business.business') }}
        </router-link>
      </div>
      <div v-if="selected === 'taxes' && isUserAdmin" class="taxes">
        <h4>{{ $t('setupMenuPage.screens.titles.taxes') }}</h4>
        <router-link :to="{ name: 'taxes-setup' }">
          {{ $t('setupMenuPage.screens.links.taxes.taxes') }}
        </router-link>
        <router-link :to="{ name: 'tax-categories-setup' }">
          {{ $t('setupMenuPage.screens.links.taxes.tax-categories') }}
        </router-link>
      </div>
      <!-- <div v-if="selected === 'payments'" class="payments">
        <h4>{{ $t('setupMenuPage.screens.titles.payments') }}</h4>
        <router-link to="#">
          {{ $t('setupMenuPage.screens.links.payments.payments') }}
        </router-link>
      </div> -->
      <div v-if="selected === 'settings'" class="settings">
        <h4>{{ $t('setupMenuPage.screens.titles.settings') }}</h4>
        <router-link :to="{ name: 'globalSettings' }">
          {{ $t('setupMenuPage.screens.links.settings.customization') }}
        </router-link>
      </div>
      <div v-if="selected === 'admin' && isUserAdmin" class="admin">
        <h4>{{ $t('setupMenuPage.screens.titles.admin') }}</h4>
        <router-link :to="{ name: 'locations-default' }">
          {{ $t('setupMenuPage.screens.links.admin.locations') }}
        </router-link>
        <router-link :to="{ name: 'groups-default' }">
          {{ $t('setupMenuPage.screens.links.admin.groups') }}
        </router-link>
        <router-link :to="{ name: 'resellers-default' }">
          {{ $t('setupMenuPage.screens.links.admin.resellers') }}
        </router-link>
        <router-link :to="{ name: 'coupons-default' }">
          {{ $t('setupMenuPage.screens.links.admin.coupons') }}
        </router-link>
        <router-link :to="{ name: 'analytics' }">
          {{ $t('setupMenuPage.screens.links.admin.analytics') }}
        </router-link>
      </div>
      <div v-if="selected === 'admin' && !isUserAdmin" class="admin">
        <h4>{{ $t('setupMenuPage.screens.titles.admin') }}</h4>
        <router-link :to="{ name: 'locations-default' }">
          {{ $t('setupMenuPage.screens.links.admin.locations') }}
        </router-link>
        <router-link :to="{ name: 'coupons-default' }" v-if="hasCouponsAccess">
          {{ $t('setupMenuPage.screens.links.admin.coupons') }}
        </router-link>
      </div>
      <div v-if="selected === 'language-setup'" class="language-setup">
        <h4>{{ $t('setupMenuPage.screens.titles.language-setup') }}</h4>
        <router-link :to="{ name: 'language-setup' }">
          {{ $t('setupMenuPage.screens.links.menu.language-setup') }}
        </router-link>
      </div>
      <div v-if="selected === 'system-setup'" class="system-setup">
        <h4>{{ $t('setupMenuPage.screens.titles.system') }}</h4>
        <router-link :to="{ name: 'locations-status' }">
          {{ $t('setupMenuPage.screens.links.system.siteStatus') }}
        </router-link>
      </div>
      <vel-alert class="alert" level="success" @dismiss="clearMessage" v-if="isSuccess">{{ successMessage }}</vel-alert>
    </div>
  </vel-page>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import ToolbarSelectors from '@/private/shared/toolbar/ToolbarSelectors';
import VelAlert from '@/components/alert/VelAlert';
import VelButton from '@/components/button/VelButton';
import VelIcon from '@/components/icon/VelIcon';
import VelPage from '@/components/page/VelPage';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataLocationsSettingsLocation } from '@/stores/data/locations/settings-location.module';
import { useDataUsersUserPermissions } from '@/stores/data/users/user-permissions.module';

export default {
  name: 'setup-menu',
  mixins: [DeviceMixin],
  components: {
    ToolbarSelectors,
    VelAlert,
    VelButton,
    VelIcon,
    VelPage
  },
  data() {
    return {
      selected: 'discounts'
    };
  },
  computed: {
    ...mapPiniaState(useDataUsersUserPermissions, ['isUserAdmin', 'hasCouponsAccess']),
    ...mapPiniaState(useDataLocationsSettingsLocation, ['isSuccess']),
    successMessage() {
      return this.$t(`setupMenuPage.success.${this.$router.currentRoute.query.successMessage}`);
    }
  },
  methods: {
    ...mapPiniaActions(useDataLocationsSettingsLocation, ['clearMessage']),
    handleSelect(selected) {
      this.selected = selected;
    }
  },
  created() {
    if (this.$route.query.groupId) {
      this.$router.replace({ query: { ...this.$route.query, groupId: null } });
    }
    if (this.$router.currentRoute.query.successMessage) {
      this.selected = this.$router.currentRoute.query.successMessage;
    } else if (this.$router.currentRoute.query.previous) {
      this.selected = this.$router.currentRoute.query.previous;
    } else if (this.isUserAdmin) {
      this.selected = 'discounts';
    } else {
      this.selected = 'settings';
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

/deep/ .vel-page {
  .toolbar {
    align-items: center;
    display: flex;
    flex-direction: row;

    .align_left {
      margin-left: rem-calc(20);

      div {
        margin: 0;
      }
    }

    &.mobile {
      .align_left {
        margin: 0 rem-calc(20);
      }

      margin-bottom: rem-calc(25);
      width: 100%;

      input {
        margin: 0;
      }
    }
  }

  .submenu {
    background: #eee;
    padding: rem-calc(24);

    &-content {
      display: flex;
      -ms-overflow-style: none;
      overflow-x: scroll;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    button {
      background: #2a3142;
      border: none;
      border-radius: rem-calc(2.5);
      box-shadow: 0 0 0 1px rgba(#505f79, 0.05), 0 3px 3px 0 rgba(#505f79, 0.15);
      font-family: 'Poppins', sans-serif;
      font-size: 0.85rem;
      font-weight: 500;
      min-width: fit-content;
      padding: rem-calc(20);

      &:not(:first-child) {
        margin-left: rem-calc(15);
      }

      &:hover,
      &:focus,
      &.selected {
        background: #fff;
        color: #2a3142;
      }
    }
  }

  &__header {
    box-shadow: none;
  }

  &__content {
    background: #fff;
    padding: 0;

    .footer {
      padding: 0 rem-calc(24) rem-calc(24) 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    padding: rem-calc(24) rem-calc(24) 0 rem-calc(24);

    h4 {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      margin-bottom: rem-calc(25);
    }

    a {
      align-items: center;
      color: #0c68bf;
      cursor: pointer;
      display: flex;
      list-style: none;
      margin-bottom: rem-calc(15);
      text-decoration: none;

      .vel-icon {
        color: #2a3142;
        margin-right: rem-calc(5);
      }
    }

    .sub-router-link {
      margin-left: rem-calc(50);
    }

    .vel-alert {
      align-self: center;
      max-width: fit-content;
    }
  }
}
</style>
