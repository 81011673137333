<template>
  <div aria-hidden="true" class="vel-avatar" :class="classes">
    <img class="vel-avatar__picture" v-if="src" :src="src" :alt="fullname" />
    <div class="vel-avatar__identicon" v-else v-html="identicon" />
  </div>
</template>

<script>
import { toSvg } from 'jdenticon';

const sizes = {
  medium: 32,
  large: 64
};

export default {
  name: 'vel-avatar',
  props: {
    src: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'medium',
      validate(value) {
        return ['medium', 'large'].includes(value);
      }
    },
    fullname: {
      type: String,
      required: true,
      validate(value) {
        return value.length > 0;
      }
    }
  },
  computed: {
    classes() {
      const { name } = this.$options;
      return [`${name}_${this.size}`];
    },
    identicon() {
      return toSvg(this.fullname, sizes[this.size], 0);
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$avatar-size_medium: rem-calc(36);
$avatar-size_large: rem-calc(58);

// identicon
$avatar__identicon-border-color: white;
$avatar__identicon-shadow-color: $athens-gray;

.vel-avatar {
  align-items: center;
  background-color: white;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  user-select: none;

  &_medium,
  &_medium &__identicon {
    height: $avatar-size_medium;
    width: $avatar-size_medium;
  }

  &_large,
  &_large &__identicon {
    height: $avatar-size_large;
    width: $avatar-size_large;
  }

  &_medium &__picture {
    width: $avatar-size_medium;
  }

  &_large &__picture {
    width: $avatar-size_large;
  }

  &__identicon {
    align-items: center;
    border: 2px solid $avatar__identicon-border-color;
    border-radius: 50%;
    box-shadow: 0 0 0 3px $avatar__identicon-shadow-color;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
}
</style>
