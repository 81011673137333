<template>
  <div class="config_big_division_display bootstrap">
    <div>
      <div class="w-100 text-right">
        <span style="cursor: pointer;" @click="showConfirmDialog = true">
          {{ $t('customizer.dashboard.reset.linkText') }}
        </span>
      </div>
      <div v-for="(opt, i) in list" :key="opt.text">
        <vel-divider content-position="left">{{ opt.title }}</vel-divider>
        <draggable @change="update" v-model="opt.elements" :group="i" :options="{ delayOnTouchOnly: true, delay: 800 }">
          <transition-group type="transition" name="flip-list">
            <div
              class="row my-2 mx-0 py-1 item"
              style="border: 1px solid #dddddd; border-radius: 5px;"
              v-for="el in opt.elements"
              :key="el.text"
            >
              <div class="col-10 d-flex align-items-center" v-if="isAllowed(el.model)">
                <span class="material-icons">drag_indicator</span>
                {{ $t(el.text) }}
              </div>
              <div class="col-2 text-right" v-if="isAllowed(el.model)">
                <b-form-checkbox
                  v-model="config[el.model].value"
                  @change="update"
                  name="check-button"
                  switch
                ></b-form-checkbox>
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
      <employee-delete-modal
        :title="$t('customizer.dashboard.reset.title')"
        :body="$t('customizer.dashboard.reset.body')"
        :yes-action-label="'customizer.dashboard.reset.yes'"
        :no-action-label="'customizer.dashboard.reset.no'"
        :show="showConfirmDialog"
        @onDelete="reset()"
        @onCancel="showConfirmDialog = false"
      ></employee-delete-modal>
    </div>
  </div>
</template>
<script>
import VelDivider from '@/components/divider/VelDivider';
import Draggable from 'vuedraggable';
import { environment } from '@/configs';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataConfig } from '@/stores/data/config.module';
import EmployeeDeleteModal from '@/private/components/employees/components/EmployeeDeleteModal';

export default {
  name: 'config-big-division-report-display',
  components: {
    VelDivider,
    Draggable,
    EmployeeDeleteModal
  },
  data() {
    return {
      options: [],
      showConfirmDialog: false
    };
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get', 'getPending']),
    config: {
      get() {
        return this.get;
      },
      set() {
        return true;
      }
    },
    options() {
      return [
        {
          title: this.$t('editors.groups.big_divisions_report_table'),
          elements: [
            {
              model: 'big_divisions_column_quantity',
              text: this.$t('editors.elements.big_divisions_column_quantity')
            },
            {
              model: 'big_divisions_column_sales_percent_overall',
              text: this.$t('editors.elements.big_divisions_column_sales_percent_overall')
            },
            {
              model: 'big_divisions_column_sales_percent',
              text: this.$t('editors.elements.big_divisions_column_sales_percent')
            },
            {
              model: 'big_divisions_column_sales_amount',
              text: this.$t('editors.elements.big_divisions_column_sales_amount')
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapPiniaActions(useDataConfig, ['patchConfig']),
    update() {
      this.$emit('update', this.config);
      this.patchConfig({ config: this.config });
    },
    isAllowed(value) {
      return environment[value] !== false;
    }
  }
};
</script>

<style lang="scss">
@import 'mixins.scss';
@import 'constants.scss';

$page__header-shadow-color: rgba(black, 0.03);

.item {
  box-shadow: 0 2px 2px $page__header-shadow-color, 0 1px 0 $page__header-shadow-color;
}

.config_big_division_display {
  display: block;

  .el-checkbox {
    display: block;
  }

  .el-divider__text {
    background-color: $wild-sand;
    padding: 0 10px 0 0;

    &.is-left {
      left: 0;
    }
  }
}
</style>
