<template>
  <vel-table
    :rows="rows"
    :columns="columns"
    :loading="loading"
    :hoverable="false"
    defaultDirection="asc"
    defaultSort="user"
  >
    <template v-slot:empty>
      {{ $t('users.errors.noData') }}
    </template>

    <template v-slot:name-column="scope">
      <div class="user__name">
        <vel-avatar class="user__name-avatar" :fullname="scope.cell.value" />
        <router-link class="user__name-text" :to="{ name: 'user', params: { userId: scope.row.id } }">
          {{ scope.cell.value }}
        </router-link>
      </div>
    </template>

    <template v-slot:sortedPermissions-column="scope">
      <ul class="user__permissions">
        <li class="permissions__permission" v-for="permission in scope.cell.value" :key="permission.id">
          <i18n
            v-if="permission.originType"
            :path="`location.permissions.permissionName.${permission.originType}`"
            tag="span"
          >
            <span slot="permissionName">{{ permission.name }}</span>
            <span slot="origin">
              <router-link
                slot="origin"
                :to="{ name: permission.originType, params: { groupId: permission.originId } }"
                v-html="permission.originName"
              ></router-link>
            </span>
          </i18n>
          <div v-else>{{ permission.name }}</div>
        </li>
      </ul>
    </template>
  </vel-table>
</template>

<script>
import VelAvatar from '@/components/avatar/VelAvatar';
import VelTable from '@/components/table/VelTable';

export default {
  name: 'permissions-list',
  components: {
    VelAvatar,
    VelTable
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    permissions: {
      type: Array
    },
    translationPrefix: {
      type: String
    }
  },
  computed: {
    rows() {
      return this.permissions;
    },
    columns() {
      return {
        name: {
          key: 'name',
          title: this.$t(`${this.translationPrefix}.permissions.table.columns.user`),
          sortable: true
        },
        sortedPermissions: {
          key: 'sortedPermissions',
          title: this.$t(`${this.translationPrefix}.permissions.table.columns.permission`),
          sortable: true
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

$users__name-font-weight: $font-medium;

.user {
  @include breakpoint(small) {
    &__name-avatar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    &__name {
      align-items: center;
      display: inline-flex;
    }

    &__name-text {
      font-weight: $users__name-font-weight;
      @include text-ellipsis;
    }

    &__name-avatar {
      display: inline;
      margin-right: rem-calc(8);
    }
  }
}

.user__permissions {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
