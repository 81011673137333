<template>
  <vel-page :loading="loading" :title="$t('discountInfo.title')">
    <vel-card>
      <discount-form @submit="handleSubmit" :discount="selectSelected" />
    </vel-card>
  </vel-page>
</template>
<script>
import DiscountForm from './DiscountForm';
import VelCard from '@/components/card/VelCard';
import VelPage from '@/components/page/VelPage';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataDiscounts } from '@/stores/data/discounts.module';

export default {
  name: 'DiscountPage',
  components: {
    DiscountForm,
    VelCard,
    VelPage
  },
  computed: {
    ...mapPiniaState(useDataDiscounts, ['selectSelected', 'loading'])
  },
  methods: {
    ...mapPiniaActions(useDataDiscounts, ['getDiscountById', 'setDiscountById']),
    handleSubmit(discount) {
      this.setDiscountById(discount);
    }
  },
  mounted() {
    this.getDiscountById();
  }
};
</script>
