import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import { createCollectionState, getSelectors, removeAll, setAll, setOne } from '@/helpers/stores.helpers';
import resellersService from '@/services/resellers.service';
import router from '@/router';

const { selectAll, selectAllFiltered, selectTotal, selectSelected } = getSelectors();

export const useDataResellers = defineStore('resellers', {
  state: () =>
    createCollectionState({
      loading: true,
      pending: true,
      error: null,
      filter: ''
    }),
  getters: {
    resellers: state => selectAllFiltered(['name'])(state),
    selectAll,
    selectTotal,
    selectSelected
  },
  actions: {
    async getResellers() {
      try {
        this.getResellersRequest();
        const resellers = await resellersService.getResellers(getToken());
        this.getResellersSuccess(resellers);
      } catch (error) {
        this.getResellersFailure(error);
      }
    },
    async getResellerByID() {
      try {
        const routeQuery = {
          ...router.currentRoute.query
        };
        this.getResellerByIdRequest();
        const reseller = await resellersService.getResellerByID(getToken(), routeQuery);
        this.getResellerByIdSuccess(reseller);
      } catch (error) {
        this.getResellerByIdFailure(error);
      }
    },
    async getLocationsByResellerID() {
      try {
        const routeQuery = {
          ...router.currentRoute.params
        };
        this.getLocationsByResellerIDRequest();
        const data = [];
        let locations = await resellersService.getLocationsByResellerID(getToken(), routeQuery);
        if (locations.length === 250) {
          let offset = 0;
          while (locations.length >= 250) {
            locations = await resellersService.getLocationsByResellerID(getToken(), {
              ...routeQuery,
              offset
            });
            data.push(...locations);
            offset += 250;
          }
        } else {
          data.push(...locations);
        }

        this.getLocationsByResellerIDSuccess(data);
      } catch (error) {
        this.getLocationsByResellerIDFailure(error);
      }
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    setSelected(value) {
      this.setSelectedValue(value);
    },
    getResellersRequest() {
      this.loading = true;
      this.error = null;
    },
    getResellersSuccess(resellers) {
      this.data = setAll(resellers, this.data);
      this.error = null;
      this.loading = false;
    },
    getResellersFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    getResellerByIdRequest() {
      this.error = null;
      this.loading = true;
    },
    getResellerByIdSuccess(reseller) {
      this.data = setOne(reseller, this.data);
      this.data.selected = reseller.id;
      this.loading = false;
      this.error = null;
    },
    getResellerByIdFailure(error) {
      this.data = removeAll(this.data);
      this.loading = false;
      this.error = error;
    },
    getLocationsByResellerIDRequest() {
      this.error = null;
      this.loading = true;
    },
    getLocationsByResellerIDSuccess(resellerLocations) {
      this.data = setAll(resellerLocations, this.data);
      this.data.selected = resellerLocations.id;
      this.loading = false;
      this.error = null;
    },
    getLocationsByResellerIDFailure(error) {
      this.data = removeAll(this.data);
      this.loading = false;
      this.error = error;
    },
    setFilterValue(value) {
      this.filter = value;
    },
    setSelectedValue(value) {
      this.data.selected = value;
    }
  }
});
