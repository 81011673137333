<template>
  <div class="partners-list">
    <vel-table
      :rows="data"
      :columns="columns"
      filterable
      :filterValue="filterValue"
      :filterPlaceholder="$t('defaults.placeholder.filter')"
      @filter-change="filterCallback"
      :showRowsLength="true"
      :initialLength="initialLength"
      class="items-list"
    >
      <template v-slot:actions-column="scope">
        <vel-button type="link" icon="edit" @click="handleGoToPartners(scope.row.id)" />
      </template>
    </vel-table>
  </div>
</template>

<script>
import VelButton from '@/components/button/VelButton';
import VelTable from '@/components/table/VelTable';
import { mapActions as mapPiniaActions } from 'pinia/dist/pinia';
import { useDataPartners } from '@/stores/data/partners.module';

export default {
  name: 'partners-list',
  components: { VelButton, VelTable },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    filterValue: {
      type: String,
      default: ''
    },
    initialLength: {
      type: Number,
      default: 0
    },
    filterCallback: {
      type: Function,
      default: () => null
    }
  },
  computed: {
    columns() {
      return {
        name: {
          title: this.$t('partnersPage.table.columns.name'),
          width: '320px',
          sortable: true
        }
      };
    }
  },
  methods: {
    ...mapPiniaActions(useDataPartners, ['setSelected']),

    handleGoToPartners(salesItemId) {
      this.setSelected(salesItemId);
      this.$router.push({ path: '/partners' });
    }
  }
};
</script>
<style scoped lang="scss">
.partners-list {
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  .items-list {
    height: 100%;
    overflow: auto;
    width: 100%;
  }
}
</style>
