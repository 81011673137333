import Vue from 'vue';
import mediaQuery from 'vue-mq';

Vue.use(mediaQuery, {
  breakpoints: {
    small: 0,
    medium: 640,
    large: 1024,
    xlarge: 1200,
    xxlarge: Infinity
  }
});
