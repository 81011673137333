import { getKeyByValue } from '@/helpers/object.helpers';
import groupKind from '@/models/groups/group-kind';

export default {
  methods: {
    getTranslatedKindName(kindValue) {
      return this.$t(`group.groupType.${this.getKindName(kindValue)}`);
    },
    getKindName(kindValue) {
      return getKeyByValue(groupKind, kindValue);
    }
  }
};
