<template>
  <div>
    <b-row class="d-flex" style="max-width: 640px;">
      <b-col sm="8">
        <b-form-group
          :label="$t('employeeCreate.availability.timeOff.form.description')"
          :description="$t('employeeCreate.required')"
          label-for="address"
          class="mb-4 w-100"
        >
          <b-form-input
            :placeholder="$t('employeeCreate.availability.timeOff.form.descriptionPlaceholder')"
            id="name"
            :class="{ required: !form.description || form.description === '' }"
            v-model="form.description"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="d-flex" style="max-width: 640px;">
      <b-col sm="8">
        <b-form-group
          :label="$t('employeeCreate.availability.timeOff.form.startDate')"
          label-for="address"
          class="mb-4 w-100"
        >
          <b-input-group class="datepicker">
            <b-form-input
              id="example-input"
              type="text"
              :placeholder="$t('employeeCreate.datePlaceholder')"
              v-mask="'99/99/9999'"
              :value="startDate"
              autocomplete="off"
              @change="setStartDate"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                button-only
                :locale="$i18n.locale"
                right
                button-variant="white"
                @input="form.endDate = form.startDate"
                :label-no-date-selected="$t('datePicker.noDate')"
                :label-calendar="$t('datePicker.calendar')"
                :label-close-button="$t('datePicker.close')"
                :label-current-month="$t('datePicker.currentMonth')"
                :label-help="$t('datePicker.help')"
                :label-nav="$t('datePicker.nav')"
                :label-next-decade="$t('datePicker.nextDecade')"
                :label-next-month="$t('datePicker.nextMonth')"
                :label-next-year="$t('datePicker.nextYear')"
                :label-prev-decade="$t('datePicker.previousDecade')"
                :label-prev-month="$t('datePicker.previousMonth')"
                :label-prev-year="$t('datePicker.previousYear')"
                :label-reset-button="$t('datePicker.reset')"
                :label-today="$t('datePicker.today')"
                :label-today-button="$t('datePicker.selectToday')"
                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                aria-controls="example-input"
                v-model="form.startDate"
                @context="onSelectStartDate"
              >
                <template #button-content>
                  <font-awesome-icon icon="calendar-alt" />
                </template>
              </b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="8">
        <b-form-group
          :label="$t('employeeCreate.availability.timeOff.form.endDate')"
          label-for="address"
          class="mb-4 w-100"
        >
          <b-input-group class="datepicker">
            <b-form-input
              id="example-input"
              type="text"
              :placeholder="$t('employeeCreate.datePlaceholder')"
              v-mask="'99/99/9999'"
              :value="endDate"
              autocomplete="off"
              @change="setEndDate"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                button-only
                :locale="$i18n.locale"
                right
                button-variant="white"
                :label-no-date-selected="$t('datePicker.noDate')"
                :label-calendar="$t('datePicker.calendar')"
                :label-close-button="$t('datePicker.close')"
                :label-current-month="$t('datePicker.currentMonth')"
                :label-help="$t('datePicker.help')"
                :label-nav="$t('datePicker.nav')"
                :label-next-decade="$t('datePicker.nextDecade')"
                :label-next-month="$t('datePicker.nextMonth')"
                :label-next-year="$t('datePicker.nextYear')"
                :label-prev-decade="$t('datePicker.previousDecade')"
                :label-prev-month="$t('datePicker.previousMonth')"
                :label-prev-year="$t('datePicker.previousYear')"
                :label-reset-button="$t('datePicker.reset')"
                :label-today="$t('datePicker.today')"
                :label-today-button="$t('datePicker.selectToday')"
                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                :min="new Date(new Date(form.startDate).setDate(new Date(form.startDate).getDate() + 1))"
                aria-controls="example-input"
                v-model="form.endDate"
                @context="onSelectEndDate"
              >
                <template #button-content>
                  <font-awesome-icon icon="calendar-alt" />
                </template>
              </b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

export default {
  name: 'time-off-form',
  model: {},
  props: {
    licenceNumber: {
      type: Number,
      default: null
    },
    timeOff: {
      type: Object,
      default: () => ({
        description: '',
        startDate: null,
        endDate: null,
        id: null
      })
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      form: {
        description: '',
        startDate: null,
        endDate: null,
        id: null
      }
    };
  },
  watch: {
    timeOff(value) {
      if (!value) {
        this.reset();
      } else {
        this.form = cloneDeep({
          ...this.form,
          ...(value || {}),
          startDate: DateTime.fromFormat(value.startDate, 'D').toJSDate(),
          endDate: DateTime.fromFormat(value.endDate, 'D').toJSDate()
        });
      }
    }
  },
  computed: {
    enableSaveChanges() {
      if (!this.timeOff) {
        return this.form.description === '' ? null : true;
      }
      return isEqual(
        {
          ...this.timeOff,
          startDate: DateTime.fromFormat(this.timeOff.startDate, 'D').toJSDate(),
          endDate: DateTime.fromFormat(this.timeOff.endDate, 'D').toJSDate()
        },
        this.form
      )
        ? null
        : this.form.description === '' || !this.form.startDate || !this.form.endDate
        ? null
        : true;
    }
  },
  methods: {
    setStartDate(val) {
      if (val.length === 10) {
        this.form.startDate = DateTime.fromFormat(val, 'D').toISO();
        if (!this.timeOff.endDate) {
          this.form.endDate = DateTime.fromFormat(val, 'D').toISO();
        }
      } else if (val.length === 0) {
        this.form.startDate = null;
      }
    },
    onSelectStartDate(ctx) {
      this.startDate = ctx.selectedFormatted;
    },
    setEndDate(val) {
      if (val.length === 10) {
        this.form.endDate = DateTime.fromFormat(val, 'D').toISO();
      } else if (val.length === 0) {
        this.form.endDate = null;
      }
    },
    onSelectEndDate(ctx) {
      this.endDate = ctx.selectedFormatted;
    },
    onSelectDate(ctx) {
      /* eslint-disable-next-line no-console */
      console.log(ctx);
    },
    reset(value = null) {
      this.form = cloneDeep(
        value || {
          description: '',
          startDate: null,
          endDate: null,
          id: null
        }
      );
    }
  }
};
</script>
<style land="scss" scoped>
.datepicker /deep/ button {
  background-color: white;
  border-bottom: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
}

/deep/ label,
/deep/ .form-text {
  margin-bottom: 0;
  padding-left: 1rem;
}

/deep/ small {
  font-size: 0.875em;
  font-weight: 400;
  min-height: 18px;
}
</style>
