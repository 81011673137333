import Analytics from './private/analytics/AnalyticsPage.vue';
import AuditReportPage from './private/reports/AuditReportPage.vue';
import BackOffice from './back-office/BackOfficeHomePage.vue';
import BigDepartmentsListPage from './private/big-departments/BigDepartmentsListPage.vue';
import BigDivisionPage from './private/components/bigDivision/BigDivisionPage.vue';
import BigDivisionReportPage from './private/reports/BigDivisionReportPage.vue';
import BigDivisionsPage from './private/components/bigDivisions/BigDivisionsPage.vue';
import ChangePasswordPage from './private/change-password/ChangePasswordPage.vue';
import Changelog from './private/Changelog.vue';
import CheckSummary from './private/check/CheckSummary.vue';
import CheckDetails from './private/check/CheckDetails.vue';
import ComboCreatePage from './private/combos/ComboCreatePage.vue';
import ComboListPage from './private/combos/ComboListPage.vue';
import ConfigurationMenu from './private/components/sidebar-menus/ConfigurationMenu.vue';
import CountersListPage from './private/counters/CountersListPage.vue';
import CouponsPage from './private/coupons/CouponsPage.vue';
import CoursingListPage from './private/coursing/CoursingListPage.vue';
import CreateMixMatchPage from './private/mix-match/CreateMixMatchPage.vue';
import CreateUserPage from './private/users/CreateUserPage.vue';
import DashboardPage from './private/dashboard/DashboardPage.vue';
import DiscountCreatePage from './private/discounts/DiscountCreatePage.vue';
import DiscountPage from './private/discounts/DiscountPage.vue';
import DiscountReportPage from './private/reports/DiscountReportPage.vue';
import DiscountsPage from './private/components/discounts/DiscountsPage.vue';
import DivisionPage from './private/divisions/DivisionPage.vue';
import DivisionsList from './private/divisions/DivisionsList.vue';
import EditMixMatchPage from './private/mix-match/EditMixMatchPage.vue';
import EmployeeCreatePage from './private/employees/EmployeeCreatePage.vue';
import EmployeeSalesReportPage from './private/reports/EmployeeSalesReportPage.vue';
import EmployeesListPage from './private/employees/EmployeesListPage.vue';
import GiftCardReportPage from './private/reports/GiftCardReportPage.vue';
import GroupPage from './private/groups/GroupPage.vue';
import GroupsPage from './private/groups/GroupsPage.vue';
import ItemProfileCreatePage from './private/item-profile/ItemProfileCreatePage.vue';
import ItemProfileListPage from './private/item-profile/ItemProfileListPage.vue';
import ItemReportPage from './private/reports/ItemReportPage.vue';
import ItemsByCategoryPage from './private/reports/ItemsSalesByCategoryReportPage.vue';
import ItemsMixMatchList from './private/mix-match/ItemsMixMatchList.vue';
import LanguageSetupPage from './private/language/LanguageSetupPage.vue';
import LocationConfigurationPage from './private/locations/LocationConfigurationPage.vue';
import LocationPage from './private/locations/LocationPage.vue';
import LocationReportPage from './private/reports/LocationReportPage.vue';
import LocationsPage from './private/locations/LocationsPage.vue';
import LocationsStatusPage from './private/locations/LocationsStatus.vue';
import LoginPage from './public/login/LoginPage.vue';
import MaintenancePage from './public/MaintenancePage.vue';
import NetSalesReportPage from './private/reports/NetSalesReportPage.vue';
import NotFoundPage from './public/NotFoundPage.vue';
import PartnersPage from './private/partners/PartnersPage.vue';
import PopupListPage from './private/popups/PopupListPage.vue';
import PrivatePage from './private/PrivatePage.vue';
import PublicPage from './public/PublicPage.vue';
import PublicResetPasswordPage from './public/users/PublicResetPasswordPage.vue';
import ReportsMenu from './private/components/sidebar-menus/ReportsMenu.vue';
import ResellerPage from './private/resellers/ResellerPage.vue';
import ResellersPage from './private/resellers/ResellersPage.vue';
import ResetPasswordPage from './public/users/ResetPasswordPage.vue';
import Router from 'vue-router';
import SalesItemsEditPage from './private/items/SalesItemsEditPage.vue';
import SalesItemsPage from './private/items/SalesItemsPage.vue';
import SalesPerHourReportPage from '@/private/reports/SalesPerHourReportPage.vue';
import SalesReportPage from './private/reports/SalesReportPage.vue';
import SalesRevenuePage from './private/reports/SalesRevenueReportPage.vue';
import SettingsPage from '@/private/settings/SettingsPage.vue';
import SetupMenu from './private/components/sidebar-menus/SetupMenu.vue';
import SummaryReportPage from './private/reports/SummaryReportPage.vue';
import TaxCategoriesList from './private/taxes/categories/TaxCategoriesList.vue';
import TaxCreate from './private/taxes/TaxCreate.vue';
import TaxEdit from './private/taxes/TaxEdit.vue';
import TaxesList from './private/taxes/TaxesList.vue';
import UserPage from './private/users/UserPage.vue';
import UsersPage from './private/users/UsersPage.vue';
import VelNested from './components/nested/VelNested.vue';
import VelTest from './private/Veltest.vue';
import VoidsReportPage from './private/reports/VoidsReportPage.vue';
import Vue from 'vue';
import { environment } from '@/configs';
import { pinia } from '@/pinia';
import { useUIBreadcrumbsStore } from '@/stores/ui/breadcrumbs.module';
import { useUIAppStore } from '@/stores/ui/app.module';
import { useDataUsersUserPermissions } from '@/stores/data/users/user-permissions.module';
import { useDataAccount } from '@/stores/data/account.module';

export const PATH_HOME = '/';
export const PATH_MAINTENANCE = '/maintenance';

// Public pages
export const PATH_LOGIN = '/login';
export const PATH_LOGOUT = '/logout';
export const PATH_PUBLIC = '/pu';
export const PATH_RESET_PASSWORD = '/reset-password';
export const PATH_PUBLIC_RESET_PASSWORD = '/password-reset';
export const PATH_NOTFOUND = '*';

// Private pages
export const PATH_ANALYTICS = '/analytics';
export const PATH_CHANGELOG = '/changelog';
export const PATH_VELTEST = '/veltest';
export const PATH_CREATE_USER = '/users/create';
export const PATH_COUPONS = '/coupons';
export const PATH_DASHBOARD = '/dashboard';
export const PATH_GROUPS = '/groups';
export const PATH_GROUP = ':groupId';
export const PATH_LOCATIONS = '/locations';
export const PATH_LOCATION = ':locationId';
export const PATH_REPORT = '/report';
export const PATH_CONFIG = '/config';
export const PATH_CONFIG_SETTINGS = `${PATH_CONFIG}/settings`;
export const PATH_REPORT_LOCATION = `${PATH_REPORT}/locations`;
export const PATH_REPORT_SALES = `${PATH_REPORT}/sales`;
export const PATH_REPORT_EMPLOYEE_SALES = `${PATH_REPORT}/employee-sales`;
export const PATH_REPORT_SALES_PER_HOUR = `${PATH_REPORT}/sales-per-hour`;
export const PATH_REPORT_NET_SALES = `${PATH_REPORT}/net-sales`;
export const PATH_REPORT_CHECKS_SUMMARY = `checkSummary`;
export const PATH_USERS = '/users';
export const PATH_USER = ':userId';
export const PATH_BACK_OFFICE = '/backoffice';
export const PATH_BACK_OFFICE_HOME = `${PATH_BACK_OFFICE}/home`;
export const PATH_BIG_DIVISIONS = '/bigdivisions';
export const PATH_BIG_DIVISION = '/bigdivision';
export const PATH_DISCOUNTS = '/discounts';
export const PATH_DISCOUNT = '/discount';
export const PATH_DIVISION = '/division';
export const PATH_DIVISIONS = '/divisions';
export const PATH_PARTNER = ':partnerId';
export const PATH_PARTNERS = '/parners';
export const PATH_SALES_ITEMS = '/salesItems';
export const PATH_SALES_ITEMS_EDIT = '/salesItems/:id';
export const PATH_LOCATION_CONFIG = '/location-configuration';
export const PATH_CHANGE_PASSWORD = '/change-password';
export const PATH_RESELLERS = '/resellers';
export const PATH_RESELLER = ':resellerID';
export const PATH_EMPLOYEE_CREATE = '/employee';
export const PATH_EMPLOYEE_EDIT = '/employee/:id';
export const PATH_EMPLOYEES_LIST = '/employees';
export const PATH_ITEM_PROFILE_LIST = '/item-profiles';
export const PATH_ITEM_COMBO_LIST = '/combos';
export const PATH_ITEM_POPUP_LIST = '/popups';
export const PATH_ITEM_PROFILE_CREATE = '/item-profile';
export const PATH_DISCOUNT_CREATE = '/discounts/create';
export const PATH_DISCOUNT_EDIT = '/discounts/edit/:id';
export const PATH_COMBO_CREATE = '/combos/create';
export const PATH_COMBO_EDIT = '/combos/edit/:id';
export const PATH_ITEM_PROFILE_EDIT = '/item-profile/:id';
export const PATH_COURSING_LIST = '/coursing';
export const PATH_CHECK_LIST = '/check';
export const PATH_CHECK_DETAILS = '/check/:id';
export const PATH_CHECK_SEARCH = '/checkSearch';
export const PATH_COUNTERS_LIST = '/counters';
export const PATH_LANGUAGE_SETUP = '/navigation/setup/language-setup';
export const PATH_TAX_CATEGORIES_LIST = '/navigation/setup/tax-categories-setup';
export const PATH_ITEMS_MIX_MATCH = '/mix-match';
export const PATH_MIX_MATCH_EDIT = `${PATH_ITEMS_MIX_MATCH}/:id`;
export const PATH_TAXES_LIST = '/navigation/setup/taxes-setup';
export const PATH_TAX_CREATE = '/navigation/setup/taxes-setup/create';
export const PATH_TAX_EDIT = '/navigation/setup/taxes-setup/:id';
export const PATH_BIG_DEPARTMENTS_LIST = '/navigation/setup/big-departments-setup';
export const PATH_LOCATIONS_STATUS = '/setup/locations-status';

const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => console.warn(err));
};

Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => console.warn(err));
};

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(Router);
}

export const checkMaintenance = (to, _from, next) => {
  if (environment.enable_maintenance && to.path !== PATH_MAINTENANCE) {
    return next(PATH_MAINTENANCE);
  }
  return next();
};
export const requireAuthenticationMiddleware = (to, _from, next) => {
  const publicPages = [PATH_LOGIN, PATH_NOTFOUND, PATH_PUBLIC, PATH_RESET_PASSWORD, PATH_PUBLIC_RESET_PASSWORD];
  const isAuthenticationRequired = !publicPages.includes(to.path);
  const isLoggedIn = localStorage.getItem('user');

  if (isAuthenticationRequired && !isLoggedIn) {
    return next(PATH_LOGIN);
  }
  return next();
};

export const passQueryAsProps = route => Object.assign({}, route.query);

export const beforeEach = async (to, from, next) => {
  requireAuthenticationMiddleware(to, from, next);
};

export const usePreviousSelectors = (to, from, next) => {
  const isMissingQuery = Object.keys(from.query).length > 0 && Object.keys(to.query).length === 0;

  if (from.path !== to.path && isMissingQuery) {
    return next({
      path: to.path,
      query: {
        ...to.query,
        locationIds: from.query.locationIds || '',
        from: from.query.from || null,
        to: (to.path !== '/dashboard' && from.query.to) || from.query.from || null,
        currencyId: from.query.currencyId || null,
        groupId: from.query.groupId || null,
        dateCompare: from.query.dateCompare || null
      }
    });
  }
  return next();
};
export const waitForPermission = (to, from, next) => {
  const isLoggedIn = localStorage.getItem('user');

  const userPermissions = useDataUsersUserPermissions(pinia);

  if (!isLoggedIn || userPermissions.isPermissionsLoaded) {
    return next();
  }
  userPermissions.getCurrentUserPermissions();

  return userPermissions.$subscribe(state => {
    if (userPermissions.isPermissionsLoaded) {
      next();
    }
  });
};
export const protectAdminAgainstAllLocations = (to, from, next) => {
  const userPermissions = useDataUsersUserPermissions(pinia);
  if (to.query.locationIds === undefined && to.query.groupId === undefined && userPermissions.isUserAdmin) {
    return next({
      ...to,
      query: { ...to.query, locationIds: '11e9a96a-a942-3014-9885-12f74f5db6f5', currencyId: 'CAD' }
    });
  }
  return next();
};
export const updateGoogleAnalyticsAfterEach = to => {
  if (!window.ga) {
    return;
  }
  window.ga('set', 'page', to.path);
  window.ga('send', 'pageview');
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: PATH_PUBLIC,
      name: 'public',
      redirect: 'login',
      component: PublicPage,
      children: [
        {
          path: PATH_LOGIN,
          name: 'login',
          component: LoginPage
        },
        {
          path: PATH_RESET_PASSWORD,
          name: 'resetPassword',
          component: ResetPasswordPage,
          props: passQueryAsProps
        },
        {
          path: PATH_PUBLIC_RESET_PASSWORD,
          name: 'publicPasswordResetPage',
          component: PublicResetPasswordPage,
          props: passQueryAsProps
        },
        {
          path: PATH_LOGOUT,
          name: 'logout',
          beforeEnter(_to, _from, next) {
            const accountStore = useDataAccount(pinia);
            accountStore.logout();

            next({ name: 'login' });
          }
        }
      ]
    },
    {
      path: PATH_HOME,
      name: 'home',
      component: PrivatePage,
      redirect: PATH_DASHBOARD,
      beforeEnter: waitForPermission,
      children: [
        {
          path: PATH_DASHBOARD,
          name: 'dashboard',
          component: DashboardPage,
          meta: {
            menu: 'dashboard'
          },
          props: passQueryAsProps,
          beforeEnter: protectAdminAgainstAllLocations
        },
        {
          path: PATH_CONFIG_SETTINGS,
          name: 'globalSettings',
          component: SettingsPage,
          meta: {
            menu: 'setup'
          }
        },
        {
          path: PATH_ANALYTICS,
          name: 'analytics',
          meta: {
            menu: 'analytics'
          },
          component: Analytics
        },
        {
          path: PATH_CHANGELOG,
          name: 'changelog',
          component: Changelog
        },
        {
          path: PATH_VELTEST,
          name: 'veltest',
          component: VelTest
        },
        {
          path: PATH_LOCATIONS,
          name: 'locations',
          meta: {
            menu: 'setup'
          },
          component: VelNested,
          redirect: { name: 'locations-default', replace: true, append: false },
          children: [
            {
              name: 'locations-default',
              path: '',
              meta: { isDefault: true, menu: 'setup' },
              component: LocationsPage
            },
            {
              path: PATH_LOCATION,
              name: 'location',
              component: LocationPage,
              meta: {
                menu: 'setup'
              },
              props: route => ({ locationId: route.params.locationId })
            }
          ]
        },
        {
          path: PATH_CREATE_USER,
          name: 'createUser',
          component: CreateUserPage,
          meta: {
            menu: 'configuration'
          }
        },
        {
          path: PATH_USERS,
          component: VelNested,
          name: 'users',
          redirect: { name: 'users-default', replace: true, append: false },
          children: [
            {
              name: 'users-default',
              path: '',
              meta: { isDefault: true, menu: 'configuration' },
              component: UsersPage
            },
            {
              path: PATH_USER,
              name: 'user',
              component: UserPage,
              meta: {
                menu: 'configuration'
              },
              props: route => ({ userId: route.params.userId })
            }
          ]
        },
        {
          path: PATH_GROUPS,
          component: VelNested,
          meta: {
            menu: 'setup'
          },
          name: 'groups',
          redirect: { name: 'groups-default', replace: true, append: false },
          children: [
            {
              name: 'groups-default',
              path: '',
              meta: { isDefault: true, menu: 'setup' },
              component: GroupsPage
            },
            {
              path: PATH_GROUP,
              name: 'group',
              component: GroupPage,
              meta: {
                menu: 'setup'
              },
              props: route => ({ groupId: route.params.groupId })
            }
          ]
        },
        {
          name: 'partners',
          path: PATH_PARTNERS,
          component: PartnersPage,
          meta: {
            menu: 'setup'
          },
          props: passQueryAsProps,
          beforeEnter: protectAdminAgainstAllLocations
        },
        {
          path: PATH_COUPONS,
          component: VelNested,
          meta: {
            menu: 'setup'
          },
          name: 'coupons',
          redirect: { name: 'coupons-default', replace: true, append: false },
          children: [
            {
              name: 'coupons-default',
              path: '',
              meta: { isDefault: true, menu: 'setup' },
              component: CouponsPage,
              props: passQueryAsProps
            }
          ]
        },
        {
          path: PATH_REPORT,
          component: VelNested,
          name: 'reports',
          redirect: { name: 'locationReport', replace: true, append: false },
          children: [
            {
              name: 'summaryReport',
              path: 'summary',
              meta: { isDefault: true, menu: 'report' },
              component: SummaryReportPage,
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            },
            {
              name: 'auditReport',
              path: 'audit',
              meta: { isDefault: true, menu: 'report' },
              component: AuditReportPage,
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            },
            {
              name: 'voidsReport',
              path: 'voids',
              meta: { isDefault: true, menu: 'report' },
              component: VoidsReportPage,
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            },
            {
              name: 'discountReport',
              path: 'discounts',
              meta: { isDefault: true, menu: 'report' },
              component: DiscountReportPage,
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            },
            {
              name: 'giftCardReport',
              path: 'giftCard',
              meta: { isDefault: true, menu: 'report' },
              component: GiftCardReportPage,
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            },
            {
              name: 'itemsByCategoryPageReport',
              path: 'itemsByCategory',
              meta: { isDefault: true, menu: 'report' },
              component: ItemsByCategoryPage,
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            },
            {
              name: 'salesRevenuePageReport',
              path: 'salesRevenue',
              meta: { isDefault: true, menu: 'report' },
              component: SalesRevenuePage,
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            },
            {
              name: 'locationReport',
              path: 'locations',
              meta: { isDefault: true, menu: 'report' },
              component: LocationReportPage,
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            },
            {
              name: 'salesReport',
              path: 'sales',
              component: SalesReportPage,
              meta: {
                menu: 'report'
              },
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            },
            {
              name: 'bigDivision',
              path: 'bigDivision',
              component: BigDivisionReportPage,
              meta: {
                menu: 'report'
              },
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            },
            {
              name: 'itemsReport',
              path: 'itemsReport',
              component: ItemReportPage,
              meta: {
                menu: 'report'
              },
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            },
            {
              name: 'employeesSalesReport',
              path: 'employeesSalesReport',
              component: EmployeeSalesReportPage,
              meta: {
                menu: 'report'
              },
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            },
            {
              name: 'salesPerHourReport',
              path: PATH_REPORT_SALES_PER_HOUR,
              component: SalesPerHourReportPage,
              meta: {
                menu: 'report'
              },
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            },
            {
              name: 'netSalesReport',
              path: PATH_REPORT_NET_SALES,
              component: NetSalesReportPage,
              meta: {
                menu: 'report'
              },
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            },
            {
              name: 'checksSummary',
              path: PATH_REPORT_CHECKS_SUMMARY,
              component: CheckSummary,
              meta: {
                menu: 'report'
              },
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations
            }
          ]
        },
        {
          path: PATH_BACK_OFFICE,
          component: VelNested,
          redirect: { name: 'backOfficeHome' },
          name: 'backOffice',
          children: [
            {
              name: 'backOfficeHome',
              path: PATH_BACK_OFFICE_HOME,
              component: BackOffice
            },
            {
              path: PATH_BIG_DIVISIONS,
              component: VelNested,
              meta: {
                menu: 'setup'
              },
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations,
              children: [
                {
                  name: 'bigdivisions',
                  path: '',
                  meta: { isDefault: true, menu: 'setup' },
                  component: BigDivisionsPage,
                  props: passQueryAsProps,
                  beforeEnter: protectAdminAgainstAllLocations
                },
                {
                  name: 'bigDivisionInfo',
                  path: PATH_BIG_DIVISION,
                  component: BigDivisionPage,
                  meta: {
                    menu: 'setup'
                  },
                  props: passQueryAsProps,
                  beforeEnter: protectAdminAgainstAllLocations
                }
              ]
            },
            {
              path: PATH_DISCOUNTS,
              component: VelNested,
              meta: {
                menu: 'setup'
              },
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations,
              children: [
                {
                  name: 'discounts',
                  path: '',
                  meta: { isDefault: true, menu: 'setup' },
                  component: DiscountsPage,
                  props: passQueryAsProps,
                  beforeEnter: protectAdminAgainstAllLocations
                },
                {
                  name: 'discount-create',
                  path: PATH_DISCOUNT_CREATE,
                  meta: { isDefault: true, menu: 'setup' },
                  component: DiscountCreatePage,
                  props: passQueryAsProps,
                  beforeEnter: protectAdminAgainstAllLocations
                },
                {
                  name: 'discount-edit',
                  path: PATH_DISCOUNT_EDIT,
                  meta: { isDefault: true, menu: 'setup' },
                  component: DiscountCreatePage,
                  props: passQueryAsProps,
                  beforeEnter: protectAdminAgainstAllLocations
                },
                {
                  name: 'discountInfo',
                  path: PATH_DISCOUNT,
                  component: DiscountPage,
                  meta: {
                    menu: 'setup'
                  },
                  props: passQueryAsProps
                }
              ]
            },
            {
              path: PATH_DIVISIONS,
              component: VelNested,
              props: passQueryAsProps,
              beforeEnter: protectAdminAgainstAllLocations,
              children: [
                {
                  name: 'divisions',
                  path: '',
                  meta: { isDefault: true, menu: 'setup' },
                  component: DivisionsList,
                  props: passQueryAsProps,
                  beforeEnter: protectAdminAgainstAllLocations
                },
                {
                  name: 'divisionInfo',
                  path: PATH_DIVISION,
                  component: DivisionPage,
                  meta: {
                    menu: 'setup'
                  },
                  props: passQueryAsProps
                }
              ]
            },
            {
              name: 'salesItems',
              path: PATH_SALES_ITEMS,
              component: SalesItemsPage,
              meta: {
                menu: 'setup'
              },
              props: passQueryAsProps
            },
            {
              name: 'employee-edit',
              path: PATH_EMPLOYEE_EDIT,
              meta: {
                menu: 'setup'
              },
              component: EmployeeCreatePage
            },
            {
              name: 'employee-create',
              path: PATH_EMPLOYEE_CREATE,
              meta: {
                menu: 'setup'
              },
              component: EmployeeCreatePage
            },
            {
              name: 'employees',
              path: PATH_EMPLOYEES_LIST,
              meta: {
                menu: 'setup'
              },
              component: EmployeesListPage
            },
            {
              name: 'item-profile-create',
              path: PATH_ITEM_PROFILE_CREATE,
              meta: {
                menu: 'setup'
              },
              component: ItemProfileCreatePage
            },
            {
              name: 'item-profile-edit',
              path: PATH_ITEM_PROFILE_EDIT,
              meta: {
                menu: 'setup'
              },
              component: ItemProfileCreatePage
            },
            {
              name: 'item-profile',
              path: PATH_ITEM_PROFILE_LIST,
              meta: {
                menu: 'setup'
              },
              component: ItemProfileListPage
            },
            {
              name: 'combos',
              path: PATH_ITEM_COMBO_LIST,
              meta: {
                menu: 'setup'
              },
              component: ComboListPage
            },
            {
              name: 'combo-create',
              path: PATH_COMBO_CREATE,
              meta: { isDefault: true, menu: 'setup' },
              component: ComboCreatePage
            },
            {
              name: 'combo-edit',
              path: PATH_COMBO_EDIT,
              meta: { isDefault: true, menu: 'setup' },
              component: ComboCreatePage
            },
            {
              name: 'popups',
              path: PATH_ITEM_POPUP_LIST,
              meta: {
                menu: 'setup'
              },
              component: PopupListPage
            },
            {
              name: 'checkDetails',
              path: PATH_CHECK_DETAILS,
              meta: {
                menu: 'report'
              },
              component: CheckDetails
            },
            {
              name: 'checkSearch',
              path: PATH_CHECK_SEARCH,
              meta: {
                menu: 'report'
              },
              component: CheckDetails
            },
            {
              name: 'coursing',
              path: PATH_COURSING_LIST,
              meta: {
                menu: 'setup'
              },
              component: CoursingListPage
            },
            {
              name: 'location-configuration',
              path: PATH_LOCATION_CONFIG,
              meta: {
                menu: 'setup'
              },
              component: LocationConfigurationPage
            },
            {
              name: 'salesItemsEdit',
              path: PATH_SALES_ITEMS_EDIT,
              meta: {
                menu: 'setup'
              },
              component: SalesItemsEditPage
            },
            {
              name: 'counters',
              path: PATH_COUNTERS_LIST,
              meta: {
                menu: 'setup'
              },
              component: CountersListPage
            },
            {
              name: 'locations-status',
              path: PATH_LOCATIONS_STATUS,
              meta: { menu: 'setup' },
              component: LocationsStatusPage
            }
          ]
        },
        {
          name: 'change-password',
          path: PATH_CHANGE_PASSWORD,
          component: ChangePasswordPage,
          props: passQueryAsProps
        },
        {
          name: 'resellers',
          path: PATH_RESELLERS,
          component: VelNested,
          meta: {
            menu: 'setup'
          },
          redirect: { name: 'resellers-default' },
          children: [
            {
              name: 'resellers-default',
              path: '',
              meta: { isDefault: true, menu: 'setup' },
              component: ResellersPage
            },
            {
              name: 'reseller',
              path: PATH_RESELLER,
              component: ResellerPage,
              meta: {
                menu: 'setup'
              }
            }
          ]
        },
        {
          name: 'navigation',
          path: '/navigation',
          component: VelNested,
          redirect: { name: 'dashboard' },
          children: [
            {
              name: 'reports-menu',
              path: '/navigation/reports',
              meta: {
                menu: 'report'
              },
              component: ReportsMenu
            },
            {
              name: 'configuration-menu',
              path: '/navigation/config',
              meta: {
                menu: 'configuration'
              },
              component: ConfigurationMenu
            },
            {
              name: 'setup-menu',
              path: '/navigation/setup',
              component: VelNested,
              meta: {
                menu: 'setup'
              },
              redirect: { name: 'setup-menu-default' },
              children: [
                {
                  name: 'setup-menu-default',
                  path: '/navigation/setup',
                  component: SetupMenu,
                  meta: {
                    menu: 'setup'
                  }
                },
                {
                  name: 'language-setup',
                  path: PATH_LANGUAGE_SETUP,
                  component: LanguageSetupPage,
                  meta: {
                    menu: 'setup'
                  },
                  beforeEnter: (from, to, next) => {
                    const userPermissions = useDataUsersUserPermissions(pinia);

                    if (!userPermissions.isUserAdmin) {
                      next('/navigation/setup');
                    }

                    next();
                  }
                },
                {
                  name: 'taxes-setup',
                  path: PATH_TAXES_LIST,
                  component: TaxesList,
                  meta: {
                    menu: 'setup'
                  },
                  beforeEnter: (from, to, next) => {
                    const userPermissions = useDataUsersUserPermissions(pinia);
                    if (!userPermissions.isUserAdmin) {
                      next('/navigation/setup');
                    }

                    next();
                  }
                },
                {
                  name: 'tax-create',
                  path: PATH_TAX_CREATE,
                  component: TaxCreate,
                  meta: {
                    menu: 'setup'
                  },
                  beforeEnter: (from, to, next) => {
                    const userPermissions = useDataUsersUserPermissions(pinia);
                    if (!userPermissions.isUserAdmin) {
                      next('/navigation/setup');
                    }

                    next();
                  }
                },
                {
                  name: 'tax-edit',
                  path: PATH_TAX_EDIT,
                  component: TaxEdit,
                  meta: {
                    menu: 'setup'
                  },
                  beforeEnter: (from, to, next) => {
                    const userPermissions = useDataUsersUserPermissions(pinia);
                    if (!userPermissions.isUserAdmin) {
                      next('/navigation/setup');
                    }

                    next();
                  }
                },
                {
                  name: 'tax-categories-setup',
                  path: PATH_TAX_CATEGORIES_LIST,
                  component: TaxCategoriesList,
                  meta: {
                    menu: 'setup'
                  },
                  beforeEnter: (from, to, next) => {
                    const userPermissions = useDataUsersUserPermissions(pinia);
                    if (!userPermissions.isUserAdmin) {
                      next('/navigation/setup');
                    }

                    next();
                  }
                },
                {
                  name: 'big-departments-setup',
                  path: PATH_BIG_DEPARTMENTS_LIST,
                  component: BigDepartmentsListPage,
                  beforeEnter: (from, to, next) => {
                    const userPermissions = useDataUsersUserPermissions(pinia);
                    if (!userPermissions.isUserAdmin) {
                      next('/navigation/setup');
                    }

                    next();
                  }
                }
              ]
            }
          ]
        },
        {
          name: 'mix-match',
          path: PATH_ITEMS_MIX_MATCH,
          component: VelNested,
          redirect: { name: 'mix-match-default' },
          children: [
            {
              name: 'mix-match-default',
              component: ItemsMixMatchList,
              path: ''
            },
            {
              name: 'create-mix-match-page',
              component: CreateMixMatchPage,
              path: `${PATH_ITEMS_MIX_MATCH}/create`
            },
            {
              name: 'edit-mix-match-page',
              component: EditMixMatchPage,
              path: `${PATH_ITEMS_MIX_MATCH}/:id`
            }
          ]
        }
      ]
    },
    {
      path: PATH_NOTFOUND,
      name: 'notfound',
      component: NotFoundPage
    },
    {
      path: PATH_MAINTENANCE,
      name: 'underMaintenance',
      component: MaintenancePage
    }
  ]
});

export const updateBreadcrumbsAfterEach = to => {
  const breadcrumbsStore = useUIBreadcrumbsStore();
  breadcrumbsStore.updateFromCurrentRoute(to);
};

router.beforeEach(checkMaintenance);
router.beforeEach(beforeEach);
router.beforeEach(usePreviousSelectors);
router.afterEach(updateGoogleAnalyticsAfterEach);

export default router;
