<template>
  <vel-form
    ref="permissionForm"
    class="permission-form"
    :model="permissionForm"
    :rules="permissionFormRules"
    label-width="150px"
  >
    <vel-form-item
      class="permission-form__type"
      prop="permissionType"
      :label="$t('user.permissions.form.type.label')"
      v-if="isAddMode"
    >
      <vel-radio-group v-model="permissionType" @change="handlePermissionTypeChange">
        <vel-radio-button label="global">
          {{ $t('user.permissions.form.type.global') }}
        </vel-radio-button>
        <vel-radio-button label="group">
          {{ $t('user.permissions.form.type.group') }}
        </vel-radio-button>
        <vel-radio-button label="location">
          {{ $t('user.permissions.form.type.location') }}
        </vel-radio-button>
      </vel-radio-group>
    </vel-form-item>

    <template v-if="permissionType">
      <template v-if="isLocationPermissionType">
        <vel-form-item
          class="permission-form__location"
          prop="locationId"
          :label="$t('user.permissions.form.location.label')"
          required
        >
          <vel-location-selector
            :locationId="permissionForm.locationId"
            :locationsOptions="locationsOptions"
            :multiple="allowMultipleLocations"
            :selectFirst="false"
            autofocus
            filterable
            @change="handleLocationChange"
          />
        </vel-form-item>
      </template>

      <template v-if="isGroupPermissionType">
        <vel-form-item
          class="permission-form__group"
          prop="groupId"
          :label="$t('user.permissions.form.group.label')"
          required
        >
          <vel-group-selector
            :groupId="permissionForm.groupId"
            :groupsOptions="groupsOptions"
            :multiple="allowMultipleGroups"
            autofocus
            filterable
            @change="handleGroupChange"
          />
        </vel-form-item>
      </template>

      <vel-form-item
        class="permission-form__permission"
        prop="permission"
        :label="$t('user.permissions.form.permission.label')"
        required
      >
        <vel-select
          v-model="permissionForm.permission"
          :placeholder="$t('user.permissions.form.permission.placeholder')"
        >
          <vel-option v-for="option in permissionsOptions" :label="option" :value="option" :key="option"></vel-option>
        </vel-select>
      </vel-form-item>
    </template>

    <vel-form-item class="permission-form__actions">
      <vel-button type="primary" class="actions__submit" :disabled="isSubmitDisabled" @click="handleSubmit()">
        {{ $t('defaults.actions.save') }}
      </vel-button>
      <vel-button type="secondary" class="actions__cancel" @click="handleCancel()">
        {{ $t('defaults.actions.cancel') }}
      </vel-button>
    </vel-form-item>
  </vel-form>
</template>

<script>
import VelButton from '@/components/button/VelButton';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelGroupSelector from '@/components/group-selector/VelGroupSelector';
import VelLocationSelector from '@/components/location-selector/VelLocationSelector';
import VelOption from '@/components/x-option/VelOption';
import VelRadioButton from '@/components/radio-button/VelRadioButton.vue';
import VelRadioGroup from '@/components/radio-group/VelRadioGroup.vue';
import VelSelect from '@/components/x-select/VelSelect';

export default {
  name: 'permission-form',
  components: {
    VelButton,
    VelForm,
    VelFormItem,
    VelGroupSelector,
    VelLocationSelector,
    VelOption,
    VelRadioButton,
    VelRadioGroup,
    VelSelect
  },
  props: {
    allowMultipleGroups: {
      type: Boolean,
      default: false
    },
    allowMultipleLocations: {
      type: Boolean,
      default: false
    },
    groupsOptions: {
      type: Array,
      required: true
    },
    locationsOptions: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: null
    },
    permission: {
      type: Object,
      default: () => ({
        permission: null,
        groupId: null,
        locationId: null
      })
    },
    permissionsOptions: {
      type: Array,
      default: () => ['fullAccess']
    }
  },
  data() {
    return {
      permissionType: this.getInitialPermissionType(),
      permissionForm: {
        id: this.permission.id,
        permission: this.permission.permission,
        locationId: this.permission.locationId || null,
        groupId: this.permission.groupId || null
      }
    };
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleGroupChange(groupId) {
      this.permissionForm.groupId = groupId;
    },
    handleLocationChange(locationId) {
      this.permissionForm.locationId = locationId;
    },
    handleSubmit() {
      this.$refs.permissionForm.validate(valid => {
        if (valid) {
          this.$emit('submit', this.permissionForm);
        }
      });
    },
    getInitialPermissionType() {
      if (!!this.permission && this.permission.locationId) {
        return 'location';
      } else if (!!this.permission && this.permission.groupId) {
        return 'group';
      }
      return this.type || 'global';
    },
    handlePermissionTypeChange() {
      this.$refs.permissionForm.clearValidate();
    }
  },
  computed: {
    isAddMode() {
      return !this.permission || !this.permission.permission;
    },
    isLocationPermissionType() {
      return this.permissionType === 'location';
    },
    isGroupPermissionType() {
      return this.permissionType === 'group';
    },
    isSubmitDisabled() {
      return !this.permissionType;
    },
    permissionFormRules() {
      return {
        permission: [
          {
            required: true,
            message: this.$t('user.permissions.form.permission.isRequired')
          }
        ],
        groupId: this.isGroupPermissionType
          ? [
              {
                required: true,
                message: this.$t('user.permissions.form.group.isRequired')
              }
            ]
          : undefined,
        locationId: this.isLocationPermissionType
          ? [
              {
                required: true,
                message: this.$t('user.permissions.form.location.isRequired')
              }
            ]
          : undefined
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.permission-form__actions {
  .actions__submit {
    margin-right: 1.25em;
  }
}
</style>
