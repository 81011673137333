<template>
  <router-link
    class="vel-link"
    active-class="vel-link_active"
    exact-active-class="vel-link_active_exact"
    :to="to"
    :tag="tag"
    :target="target"
    :class="classes"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  name: 'vel-link',
  props: {
    to: {
      type: Object,
      required: true
    },
    tag: {
      type: String,
      default: 'a'
    },
    target: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'primary',
      validate(value) {
        return ['primary', 'secondary', 'link'].includes(value);
      }
    },
    ripple: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classes() {
      const { name } = this.$options;
      return [
        `${name}_${this.type}`,
        {
          [`${name}_ripple`]: this.ripple
        }
      ];
    }
  }
};
</script>

<style lang="scss">
@import '@/components/button/VelButton.scss';

$link-color_hover: white;
$link-color_focus: $link-color_hover;
$link-color_visited: $link-color_hover;
$link-color_active: $link-color_hover;

.vel-link {
  @extend .vel-button;

  &:hover {
    color: $link-color_hover;
  }

  &:focus {
    color: $link-color_focus;
  }

  &:visited {
    color: $link-color_visited;
  }

  &:active {
    color: $link-color_active;
  }

  &_primary {
    @extend .vel-button_primary;
  }

  &_secondary {
    @extend .vel-button_secondary;
  }

  &_link {
    @extend .vel-button_link;
  }

  &_ripple {
    @extend .vel-button_ripple;
  }
}
</style>
