<template>
  <div>
    <b-input-group>
      <template #append>
        <b-input-group-text @click="$refs.input.$el.focus()">
          <b-icon icon="chevron-down"></b-icon>
        </b-input-group-text>
      </template>
      <b-form-input
        ref="input"
        @focus="inputFocusIn"
        v-model="currentText"
        :disabled="disabled"
        :readonly="!filterable"
        @input="onInput"
      ></b-form-input>
    </b-input-group>
    <VelList
      v-if="openList"
      v-model="modelValue"
      :value-options.sync="options"
      :multiple="multiple"
      @cancel="cancel"
      @ok="validate"
      ref="list"
    />
  </div>
</template>

<script>
import VelList from './VelList';
import { bus } from '@/bus';

export default {
  name: 'vel-multiple-selector',
  components: {
    VelList
  },
  props: {
    value: {
      type: [String, Array, Number],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: true
    },
    valueOptions: {
      type: Array,
      required: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    noDataLabel: {
      type: String,
      default: null
    },
    allResultsLabel: {
      type: String,
      default: null,
      required: false
    },
    multiplesResultsLabel: {
      type: String,
      default: null,
      required: true
    }
  },
  data() {
    return {
      filter: '',
      openList: false,
      inputFocus: false,
      listFocus: false,
      currentSelection: []
    };
  },
  computed: {
    options() {
      if (this.valueOptions && this.filterable && this.filter !== '') {
        return this.valueOptions.filter(item => item.value.toLowerCase().includes(this.filter.toLowerCase()));
      }

      return this.valueOptions || [];
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    currentText: {
      get() {
        if (this.filterable && this.openList) {
          return '';
        }

        if (!this.value && this.value !== 0) {
          return this.$t(this.allResultsLabel);
        }

        if (this.multiple) {
          if (this.value.length === 0) {
            return this.$t(this.allResultsLabel);
          }

          if (this.value.length === 1) {
            return this.getLabelFromKey(this.value[0])[0];
          }

          return this.$t(this.multiplesResultsLabel, { count: this.value.length });
        }

        return this.getLabelFromKey(this.value)[0] || '';
      }
    }
  },
  methods: {
    getLabelFromKey(key) {
      return this.valueOptions.filter(v => v.key === key).map(m => m.label);
    },
    inputFocusIn() {
      this.openList = true;
    },
    validate() {
      this.filter = '';
      this.openList = false;
    },
    cancel() {
      this.filter = '';
      this.openList = false;
    },
    onInput(value) {
      this.filter = value;
    }
  },
  mounted() {
    bus.$on('document:click', evt => {
      if (!this.openList) {
        return;
      }

      if (!(this.$el === evt.target || this.$el.contains(evt.target))) {
        this.$refs.list.validate();
        this.cancel();
      }
    });
  },
  beforeDestroy() {
    bus.$off('document:click', this.cancel);
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

$contrast-color: #00a9e1;

/deep/ .form-control[readonly]:not(.form-control[disabled]) {
  background: white;
  cursor: pointer;
}
.input-group {
  display: inline-flex;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.input-group-text {
  border: none;
}

/deep/ input {
  border: 0;
  height: 32px !important;
}
/deep/ .b-icon.bi {
  width: 12px;
  color: $contrast-color;
}

.form-control {
  padding: 0.5rem 0.2rem;
  border: none;
}

input:disabled + .input-group-append {
  background-color: #e9ecef;
}

.input-group-text {
  padding: 0.5rem 0.2rem;
  background: none !important;
  cursor: text;
}

.selectList {
  background: white;
  border-radius: 1em;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 12px #00000029;
  position: absolute;
  z-index: 100;
  padding: 15px;
  margin-top: 10px;
  font-size: 14px;
  color: $contrast-color;

  font-family: inherit;

  &__header,
  &__footer {
    display: flex;
    flex-direction: row;
    gap: 10px;

    & > a {
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__header {
    margin-bottom: 1em;
  }

  &__footer {
    justify-content: end;
    margin-top: 1em;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 14em;
    overflow-y: scroll;

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    li {
      height: 32px;
      line-height: 32px;
      padding: 0.1em 0.5em;
      color: #1a1a1a;
      cursor: pointer;

      label {
        padding-left: 1em;
        color: inherit;
      }

      & .b-icon.bi {
        color: #e6e6e6;
      }

      &.selected,
      &:hover {
        background: $contrast-color;
        color: white;
        width: 100%;
        border-radius: 0.5em;
      }

      &.selected .b-icon.bi {
        color: inherit;
      }
    }
  }
}
</style>
