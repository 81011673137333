<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="600px"
    right
    id="jobRolesEdit-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarJobRoleEdit"
  >
    <template #header>
      <h6 class="bg-gray-200 bg-light w-100 mb-0 text-center font-weight-bold">
        {{ $t('employeeCreate.jobRoleEdit.title') }}
      </h6>
    </template>

    <template #footer="{ hide }">
      <div
        class="d-flex bg-light text-dark align-items-center justify-content-between px-3 py-2"
        style="border-top: 2px solid #d6d6d8;"
      >
        <b-button
          :disabled="!($refs.form && $refs.form.enableSaveChanges)"
          variant="primary"
          f
          @click="updateJobRole()"
        >
          {{ $t('employeeCreate.btns.saveChanges') }}
        </b-button>
        <b-button variant="outline-primary" @click="hide">{{ $t('employeeCreate.btns.cancel') }}</b-button>
      </div>
    </template>
    <b-container fluid class="p-4" v-loading="pending">
      <job-role-form :licenceNumber="employee.licenceNumber" :job-role.sync="jobRole" ref="form"></job-role-form>
    </b-container>
  </b-sidebar>
</template>
<script>
import JobRoleForm from '@/private/components/job-roles/components/JobRoleForm';
import { patchEmployeeTask } from '@/services/employees.service';

export default {
  name: 'employee-sidebar-edit-job-role',
  components: {
    JobRoleForm
  },
  props: {
    jobRole: {
      type: Object,
      default: () => ({ name: '' })
    },
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    licenceNumber: {
      type: Number,
      default: 0
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    employeeTask() {
      return {
        name: this.$refs.form.roleName,
        licenceNumber: this.licenceNumber || this.employee.licenceNumber || null,
        remoteId: this.jobRole && this.jobRole.remoteId
      };
    }
  },
  methods: {
    async updateJobRole() {
      try {
        this.$emit('update:pending', true);
        await patchEmployeeTask(this.jobRole.id, this.employeeTask);
        this.$emit('update:pending', false);
        this.$emit('success', this.employeeTask);
        this.$refs.sidebarJobRoleEdit.hide();
      } catch (e) {
        this.$emit('update:error', true);
        this.$emit('update:pending', false);
        this.$emit('failure', this.employeeTask);
      }
    }
  }
};
</script>
