import getUserLocale from 'get-user-locale';

export default (amount, minimumFractionDigits = 0) => {
  // @debt hard-coded to 2 digits because of an API bug.
  const formattedAmount = new Intl.NumberFormat(getUserLocale(), {
    maximumFractionDigits: 2,
    minimumFractionDigits,
    useGrouping: true
    // we don't pass the currency here because it can be weird ($ for example)
  }).format(Number(amount) || 0);
  return `${formattedAmount}`;
};
