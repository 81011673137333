<template>
  <vel-sales-report
    :displayedColumns="displayedColumns"
    :data="formatedTree"
    :areSalesLoading="areSalesLoading"
    :filterable="true"
    :external-export="true"
    :is-external-generating="generating"
    @exportXls="exportXLSX"
    class="item-sales-report w-100"
    @input="handleInput"
    style="width: 100%;"
  />
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelSalesReport from '@/components/report/VelSalesReport';
import router from '@/router';
import salesService from '@/services/sales.service';

export default {
  name: 'item-sales-report',
  components: { VelSalesReport },
  mixins: [DeviceMixin],
  props: {
    sales: {
      type: Array,
      default() {
        return [];
      }
    },
    hiddenColumns: {
      type: Object,
      default() {
        return {};
      }
    },
    days: {
      type: Number,
      default: 0
    },
    areSalesLoading: {
      type: Boolean,
      default: true
    },
    multipleLocation: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      generating: false
    };
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    getSalesWithPercent(sales) {
      const totalSales = (sales || []).reduce((result, s) => result + s.salesAmount, 0);
      return (sales || []).map(s => ({
        ...s,
        salesPercent: ((s.salesAmount / totalSales) * 100 || 0).toFixed(2),
        salesOverallPercent:
          s.salesAmount && this.salesOverall ? ((s.salesAmount / this.salesOverall) * 100).toFixed(2) : '0.00',
        averageVolumeUnit: (((s.quantity ? s.quantity / (this.days + 1) : 0) * 100) / 100).toFixed(2),
        children: (s.children && this.getSalesWithPercent(s.children)) || null
      }));
    },
    async exportXLSX() {
      this.generating = true;
      const routeQuery = {
        ...router.currentRoute.query
      };
      await salesService.downloadItemSalesXlsx(routeQuery).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    }
  },
  computed: {
    salesOverall() {
      return (this.sales || []).reduce((cur, i) => cur + i.salesAmount, 0);
    },
    defaultEmptyMessage() {
      return this.$t('defaults.empty');
    },
    displayedColumns() {
      const columns = [];
      if (this.multipleLocation) {
        columns.push(this.$t('items.header.multiLocation.name'));
      } else {
        columns.push(this.$t('items.header.singleLocation.name'));
      }
      if (this.hiddenColumns.quantity) {
        columns.push(this.$t('items.header.quantity'));
      } else {
        columns.push(null);
      }
      if (this.hiddenColumns.salesAmount) {
        columns.push(
          this.$t('items.header.salesAmount', {
            currency: (this.sales && this.sales[0] && this.sales[0].currency) || '$'
          })
        );
      } else {
        columns.push(null);
      }
      if (this.hiddenColumns.salesPercent) {
        columns.push(this.$t('items.header.salesPercent'));
      } else {
        columns.push(null);
      }
      if (this.hiddenColumns.salesPercentOverall) {
        columns.push(this.$t('items.header.salesOverallPercent'));
      } else {
        columns.push(null);
      }
      if (this.hiddenColumns.averageVolumeUnit) {
        columns.push(this.$t('items.header.averageVolumeUnit'));
      } else {
        columns.push(null);
      }
      return columns;
    },
    /**
     * This function removes a level when there's only one location
     */
    formatedTree() {
      if (this.multipleLocation || this.areSalesLoading) {
        return this.getSalesWithPercent(this.sales);
      }
      const tree = JSON.parse(JSON.stringify(this.getSalesWithPercent(this.sales)));
      tree.forEach(div => {
        div.children.forEach(item => {
          delete item.children;
        });
      });
      return tree;
    }
  }
};
</script>
<style>
.item-sales-report {
  min-width: 800px;
}
</style>
