<template>
  <div class="sales-items-avaibility-per-mode">
    <h5 style="margin-top: 2rem;">{{ $t('salesItemsEdit.form.pricesModeTitle') }}</h5>

    <el-table size="mini" :show-header="true" :data="pricesData" style="max-width: 660px; width: 100%;">
      <el-table-column class-name="modeName" label="Mode" prop="mode.name"></el-table-column>
      <el-table-column
        v-for="priceIndex in Object.keys(pricesData.length && pricesData[0].prices)"
        :key="priceIndex"
        width="120px"
        class-name="priceName"
        prop="priceLevel.name"
      >
        <template v-slot:header="scope">
          {{ pricesData[scope.$index - 1].prices[priceIndex].name }}
        </template>
        <template v-slot="scope">
          <el-input-number
            :controls="false"
            size="small"
            :precision="2"
            :max="100000"
            :min="-9999.99"
            v-model="pricesData[scope.$index].prices[priceIndex].price"
            :value="pricesData[scope.$index].prices[priceIndex].price"
          ></el-input-number>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { Table, TableColumn } from 'element-ui';

export default {
  name: 'sales-items-avaibility-per-mode-form',
  components: {
    'el-table': Table,
    'el-table-column': TableColumn
  },

  props: {
    pricesData: {
      type: Array,
      default: () => []
    }
  }
};
</script>
