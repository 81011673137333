<template>
  <vel-card class="create-user">
    <template>
      <vel-alert @dismiss="init" class="alert create-user__error" level="error" v-if="error" :autoDismissTime="0">
        {{ $t((error && error.text) || error) }}
      </vel-alert>
      <vel-form
        ref="userForm"
        class="user-form"
        :model="userForm"
        :rules="userFormRules"
        :submit="handleSubmit"
        label-width="150px"
      >
        <vel-form-item prop="email" class="user-form-email" :label="$t('user.form.email.label')">
          <vel-input name="email" class="email-input" type="email" v-model="userForm.email"></vel-input>
        </vel-form-item>
        <vel-form-item prop="firstName" class="user-form-firstName" :label="$t('user.form.firstName.label')">
          <vel-input name="firstName" class="firstname-input" type="text" v-model="userForm.firstName"></vel-input>
        </vel-form-item>
        <vel-form-item prop="lastName" class="user-form-lastName" :label="$t('user.form.lastName.label')">
          <vel-input name="lastName" class="lastname-input" type="text" v-model="userForm.lastName"></vel-input>
        </vel-form-item>
        <vel-form-item class="user-form-enabled" prop="enabled" :label="$t('user.form.enabled.label')">
          <vel-select
            v-model="userForm.enabled"
            class="enabled-input"
            :placeholder="$t('user.form.enabled.placeholder')"
          >
            <vel-option label="true" :value="true" :key="true"></vel-option>
            <vel-option label="false" :value="false" :key="false"></vel-option>
          </vel-select>
        </vel-form-item>
        <vel-form-item class="user-form__actions">
          <vel-button type="primary" class="actions__submit" :loading="pending" @click="handleSubmit()">
            {{ $t('defaults.actions.save') }}
          </vel-button>
          <vel-button type="secondary" class="actions__cancel" @click="handleCancel()">
            {{ $t('defaults.actions.cancel') }}
          </vel-button>
        </vel-form-item>
      </vel-form>
    </template>
  </vel-card>
</template>

<script>
import VelAlert from '@/components/alert/VelAlert';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelInput from '@/components/input/VelInput';
import VelOption from '@/components/x-option/VelOption';
import VelSelect from '@/components/x-select/VelSelect';
import { mapActions as mapPiniaActions, storeToRefs } from 'pinia';
import { useDataAccount } from '@/stores/data/account.module';

export default {
  name: 'create-user-form',
  components: {
    VelAlert,
    VelButton,
    VelForm,
    VelFormItem,
    VelInput,
    VelOption,
    VelSelect,
    VelCard
  },
  data() {
    return {
      userForm: {
        email: '',
        firstName: '',
        lastName: '',
        enabled: true
      }
    };
  },

  setup() {
    const dataAccount = useDataAccount();
    const { newUser, status, error, pending } = storeToRefs(dataAccount);
    return {
      newUser,
      status,
      error,
      pending,
      createUser: dataAccount.createUser,
      init: dataAccount.init,
      clearUserError: dataAccount.clearUserError
    };
  },
  computed: {
    userFormRules() {
      return {
        email: [
          {
            required: true,
            message: this.$t('user.form.email.isRequired')
          }
        ],
        firstName: [
          {
            required: true,
            message: this.$t('user.form.firstName.isRequired')
          }
        ],
        lastName: [
          {
            required: true,
            message: this.$t('user.form.lastName.isRequired')
          }
        ],
        enabled: [
          {
            required: true,
            message: this.$t('user.form.enabled.isRequired')
          }
        ],
        id: [
          {
            required: false
          }
        ]
      };
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.userForm.validate(valid => {
        if (valid) {
          this.createUser(this.userForm);
        }
      });
    },
    handleCancel() {
      this.$router.push({ path: '/users' });
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';
.create-user .actions__submit {
  margin-right: 1.25em;
}

.create-user__error {
  margin-bottom: rem-calc(16);
  margin-left: 150px;
  width: fit-content;
}
</style>
