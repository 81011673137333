<template>
  <div class="public-reset-password">
    <div class="logo-wrapper">
      <logo class="login-logo" />
    </div>
    <h3 class="public-reset-password-title" v-html="$t('publicResetPassword.title')" />
    <vel-form ref="publicResetPassword" :model="formInline" :rules="rules" @submit.native.prevent="handleSubmit">
      <vel-form-item prop="email" class="form-group-email" :label="$t('publicResetPassword.form.email.label')">
        <vel-input type="email" class="email-input" autofocus v-model="formInline.email">
          <vel-icon slot="prepend" name="email-login" size="small" />
        </vel-input>
      </vel-form-item>
      <vel-alert class="alert" level="error" v-if="error">{{ $t('publicResetPassword.form.error.text') }}</vel-alert>
      <vel-form-item class="public-reset-password-row">
        <vel-button type="primary" class="link-btn" @click="$router.push('login')">
          {{ $t('publicResetPassword.form.loginPageLink.text') }}
        </vel-button>
        <vel-button type="primary" class="submit-btn" @click.native.prevent="handleSubmit()">
          {{ $t('publicResetPassword.form.submitButton.text') }}
        </vel-button>
      </vel-form-item>
    </vel-form>
    <vel-alert
      :autoDismissTime="60000"
      @dismiss="dismissAlert"
      class="alert"
      level="success"
      v-if="formInline.submitted"
    >
      {{ $t('publicResetPassword.modal.success.text') }}
    </vel-alert>
  </div>
</template>

<script>
import Logo from '@/assets/svg/logo.svg';
import VelAlert from '@/components/alert/VelAlert';
import VelButton from '@/components/button/VelButton';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelIcon from '@/components/icon/VelIcon';
import VelInput from '@/components/x-input/VelInput';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataUsersResetPassword } from '@/stores/data/users/reset-password.module';

export default {
  name: 'PublicResetPasswordForm',
  components: { Logo, VelForm, VelFormItem, VelInput, VelIcon, VelButton, VelAlert },
  data() {
    return {
      formInline: {
        email: '',
        submitted: false,
        startedTyping: false,
        errors: []
      },
      rules: {
        email: [
          {
            type: 'email',
            validator: this.validateEmail,
            trigger: 'blur',
            required: true
          }
        ]
      }
    };
  },
  methods: {
    ...mapPiniaActions(useDataUsersResetPassword, ['resetTokenUnauthenticated']),
    handleSubmit() {
      const { email } = this.formInline;

      this.formInline.startedTyping = false;
      this.formInline.errors = [];

      this.$refs.publicResetPassword.validate(isValid => {
        if (isValid) {
          this.resetTokenUnauthenticated(email);
          this.formInline.submitted = true;
          this.formInline.email = '';
        }
      });
    },
    validateEmail(rule, value, cb) {
      if (!value || !value.length) {
        cb(new Error(this.$t('publicResetPassword.form.email.isRequired')));
      } else if (
        !value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        cb(new Error(this.$t('publicResetPassword.form.email.isInvalid')));
      } else {
        cb();
      }
    },
    dismissAlert() {
      this.formInline.submitted = !this.formInline.submitted;
    }
  },
  computed: {
    ...mapPiniaState(useDataUsersResetPassword, ['error'])
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

.public-reset-password {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  width: rem-calc(300);

  @include breakpoint(xsmall) {
    padding-left: rem-calc(30);
    padding-right: rem-calc(30);
  }

  @include breakpoint(small) {
    padding-left: 0;
    padding-right: 0;
  }

  .logo-wrapper {
    justify-content: center;
  }

  .login-logo {
    width: rem-calc(120);

    path {
      fill: $primary-color;
    }
    @media screen and (max-width: map-get($breakpoints, 'medium')) {
      max-height: rem-calc(80);
    }
  }

  .public-reset-password-title {
    font-size: 1.25rem;
    margin: 1rem auto;
  }

  .form-group-email {
    /* stylelint-disable */
    input.el-input__inner,
    .el-range-input:focus {
      @media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
        font-size: $input-font-size !important;
      }
    }
    /* stylelint-enable */
  }

  /deep/ .el-input-group__prepend {
    padding: rem-calc(8);

    /* stylelint-disable */
    path {
      fill: $fjord;
    }
    /* stylelint-enable */
  }

  .public-reset-password-row {
    display: flex;
    justify-content: center;

    .submit-btn,
    .link-btn {
      height: rem-calc(40);
      margin-top: rem-calc(15);
    }

    .link-btn {
      margin-right: rem-calc(10);
    }
  }
}
</style>
