import isEqual from 'lodash.isequal';
import isObject from 'lodash.isobject';
import transform from 'lodash.transform';

export const accentFold = string => string.normalize('NFD').replace(/[\u0300-\u036f]/gu, '');

export const slugify = string =>
  accentFold(string)
    .toLowerCase()
    .replace(/ /gu, '_')
    .replace(/[^a-z0-9_]/gu, '');

export const difference = (object, base) => {
  const changes = (o, b) =>
    transform(o, (result, value, key) => {
      if (!isEqual(value, b[key])) {
        result[key] = isObject(value) && isObject(b[key]) ? changes(value, b[key]) : value;
      }
    });
  return changes(object, base);
};
