import { defineStore } from 'pinia';
import locationsService from '@/services/locations.service';
import { getToken } from '@/helpers/token.helper';

export const useDataLocationsLocation = defineStore('locationsLocation', {
  state: () => ({ location: null, status: {}, errors: {} }),
  getters: {
    getLocationError() {
      return this.errors.getLocationError || {};
    },
    isLocationNotFound() {
      return [400, 404].includes(this.getLocationError.statusCode) || false;
    },
    isLoadingLocation: (state: any) => !!state.status.isLoadingLocation
  },
  actions: {
    getLocation(locationId) {
      this.getLocationRequest();

      locationsService.getLocation(getToken(), locationId).then(
        location => {
          this.getLocationSuccess(location);
        },
        error => {
          this.getLocationFailure(error);
        }
      );
    },
    resetLocation() {
      this.reset();
    },
    updateSelectedLocation(payload) {
      this.selected = payload.locationId;
    },
    hydrateLocation(location) {
      this.location = location;
    },
    getLocationRequest() {
      this.status = { ...this.status, isLoadingLocation: true };
      this.location = null;
      this.errors = { ...this.errors, getLocationError: null };
    },
    getLocationSuccess(location) {
      this.status = { ...this.status, isLoadingLocation: false };
      this.location = location && { ...location, version: location.version.replace('0.0.0', '-') };
      this.errors = { ...this.errors, getLocationError: null };
    },
    getLocationFailure(error) {
      this.status = { ...this.status, isLoadingLocation: false };
      this.location = null;
      this.errors = { ...this.errors, getLocationError: error };
    },
    reset() {
      this.location = null;
      this.status = {};
      this.errors = {};
    }
  }
});
