import { get, resolveCommonQuery } from '@/helpers/service.helper';
import { getToken } from '@/helpers/token.helper';

/**
 * Get suppliers
 * @link https://api.posveloce.com/docs/private#tag/suppliers
 */
export const getSuppliers = (params: {}) => {
  const url = resolveCommonQuery('/suppliers', params).toString();
  return get(getToken(), url);
};
