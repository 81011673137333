<template>
  <div class="login-card" shadowed>
    <div class="login-header">
      <div class="logo-wrapper">
        <logo class="login-logo" />
      </div>

      <h3 class="login-title" v-html="$t('login.form.title')" />
    </div>

    <vel-form ref="formInline" class="form-login" :model="formInline" :rules="ruleInline">
      <vel-form-item prop="email" class="form-group-email" :label="$t('login.form.emailInput.text')">
        <vel-input type="email" autofocus v-model="formInline.email" @keyup.native="userStartedTyping">
          <vel-icon slot="prepend" name="email-login" size="small" />
        </vel-input>
      </vel-form-item>

      <vel-form-item prop="password" class="form-group-password" :label="$t('login.form.passwordInput.text')">
        <vel-input type="password" v-model="formInline.password" @keyup.native="userStartedTypingPassword">
          <vel-icon slot="prepend" name="password-login" size="small" />
        </vel-input>
      </vel-form-item>

      <vel-alert :dismissable="false" level="error" class="error" v-if="!!error && !formInline.startedTyping">
        {{ $t('login.form.invalidLogin.text') }}
      </vel-alert>

      <vel-form-item class="form-group-login">
        <vel-button type="primary" class="btn-login" :loading="status.loggingIn" @click.native.prevent="handleSubmit()">
          {{ $t('login.form.submitButton.text') }}
        </vel-button>
      </vel-form-item>

      <div class="login__reset-password">
        <vel-link :to="{ path: `password-reset` }" type="link" target="_self" class="vel-link__link">
          {{ $t('login.form.forgotPassword.text') }}
        </vel-link>
      </div>
    </vel-form>
  </div>
</template>

<script>
import { mapState, storeToRefs } from 'pinia';
import Logo from '@/assets/svg/logo.svg';
import VelAlert from '@/components/alert/VelAlert';
import VelButton from '@/components/button/VelButton';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelIcon from '@/components/icon/VelIcon';
import VelInput from '@/components/x-input/VelInput';
import VelLink from '@/components/link/VelLink';
import { mapActions as mapPiniaActions } from 'pinia';
import { useDataAccount } from '@/stores/data/account.module';

export default {
  name: 'LoginForm',
  components: {
    Logo,
    VelAlert,
    VelButton,
    VelForm,
    VelFormItem,
    VelIcon,
    VelInput,
    VelLink
  },
  data() {
    return {
      formInline: {
        email: '',
        password: '',
        submitted: false,
        startedTyping: false
      },
      ruleInline: {
        email: [
          {
            required: true,
            message: this.$t('login.form.emailInput.isRequired'),
            trigger: 'blur'
          },
          {
            type: 'email',
            message: this.$t('login.form.emailInput.invalidFormat'),
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('login.form.passwordInput.isRequired'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  computed: {},
  setup() {
    const accountStore = useDataAccount();
    const { error, status } = storeToRefs(accountStore);

    return { error, status, login: accountStore.login };
  },
  methods: {
    handleSubmit() {
      const { email, password } = this.formInline;

      this.$refs.formInline.validate(isValid => {
        if (isValid) {
          this.formInline.startedTyping = false;
          this.formInline.submitted = true;
          this.login({ email, password });
        }
      });
    },
    userStartedTyping(event) {
      this.formInline.startedTyping = true;
      if (event.code === 'Enter') {
        this.handleSubmit();
      }
    },
    userStartedTypingPassword(event) {
      this.userStartedTyping({});

      if (event.code === 'Enter') {
        this.handleSubmit();
      }
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

.login-card {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  width: rem-calc(300);

  @include breakpoint(xsmall) {
    padding-left: rem-calc(30);
    padding-right: rem-calc(30);
  }

  @include breakpoint(small) {
    padding-left: 0;
    padding-right: 0;
  }

  .login-header {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .logo-wrapper {
    justify-content: center;
  }

  .login-logo {
    width: rem-calc(120);

    path {
      fill: $primary-color;
    }
    @media screen and (max-width: map-get($breakpoints, 'medium')) {
      max-height: rem-calc(80);
    }
  }

  .login-title {
    margin: 1em auto;
  }

  .form-group-email,
  .form-group-password {
    /* stylelint-disable */
    input.el-input__inner,
    .el-range-input:focus {
      @media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
        font-size: $input-font-size !important;
      }
    }
    /* stylelint-enable */
  }

  /deep/ .el-input-group__prepend {
    padding: rem-calc(8);

    /* stylelint-disable */
    path {
      fill: $fjord;
    }
    /* stylelint-enable */
  }

  .btn-login {
    height: rem-calc(40);
    margin-top: rem-calc(15);
    width: 100%;
  }

  h3 {
    font-size: rem-calc(20);
    text-align: center;
  }

  .error {
    font-size: rem-calc(12);
    min-height: rem-calc(5);
    padding: rem-calc(5);
  }

  .form-login {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .login-access,
  .login__reset-password {
    text-align: center;
    width: 100%;

    .login-questionAccess,
    .login__reset-password__question {
      font-size: rem-calc(13);
      @media screen and (max-width: map-get($breakpoints, 'small')) {
        font-size: rem-calc(10);
      }
    }

    .vel-link {
      color: $iris-blue;
      font-size: rem-calc(13);
      height: auto;
      line-height: rem-calc(10);
      padding-left: rem-calc(5);
      @media screen and (max-width: map-get($breakpoints, 'small')) {
        font-size: rem-calc(10);
      }

      &:hover {
        background-color: $wild-sand;
      }
    }
  }
}
</style>
