import { accentFold, difference } from '@/helpers/string.helpers';
import cloneDeep from 'lodash.clonedeep';
import { objectToSearchableString } from '@/helpers/object.helpers';
import omitBy from 'lodash.omit';
import pickBy from 'lodash.pick';

export const defaultCollectionState = {
  entities: {},
  ids: [],
  selected: null
};

export const createCollectionState = opts => ({ data: defaultCollectionState, ...opts });

export const normalizeCollectionState = (collection, field = 'id') =>
  (collection || []).reduce(
    (state, item) => ({
      ...state,
      entities: {
        ...state.entities,
        [item[field]]: item
      },
      ids: [...state.ids, item[field]]
    }),
    { ...defaultCollectionState, filter: '' }
  );

export const addOne = (entity, state, field = 'id') => {
  if (entity[field] in state.entities) {
    return state;
  }
  state.ids.push(entity[field]);
  state.entities[entity[field]] = entity;
  return state;
};

export const addMany = (entities: any[], state, field = 'id') => {
  entities.forEach(entity => addOne(entity, state, field));
  return state;
};

export const setAll = (entities: any[], state, field = 'id') =>
  addMany(entities, { ...state, entities: {}, ids: [] }, field);

export const setOne = (entity, state, field = 'id') => {
  if (entity[field] in state.entities) {
    state.entities[entity[field]] = entity;
    return state;
  }
  state.ids.push(entity[field]);
  state.entities[entity[field]] = entity;

  return state;
};

export const removeOne = (id, state) => {
  delete state.entities[id];
  state.ids = state.ids.filter(i => i !== id);
  return state;
};

export const removeMany = (ids: any[], state) => {
  ids.forEach(id => {
    delete state.entities[id];
    state.ids = Object.keys(state.entities);
  });
  return state;
};

export const removeAll = state => {
  state.entities = {};
  state.ids = [];
  return state;
};

export const updateOne = (entity, state, field = 'id') => {
  const newState = removeOne(entity[field], state);
  return addOne(entity, newState, field);
};

export const updateMany = (entities: any[], state, field = 'id') => {
  entities.forEach(entity => {
    if (entity[field] in state.entities) {
      state.entities[entity[field]] = entity;
    }
  });
  return state;
};

export const upsertOne = (entity, state, field = 'id') => {
  if (entity[field] in state.entities) {
    updateOne(entity, state, field);
  } else {
    addOne(entity, state, field);
  }
  return state;
};

export const upsertMany = (entities: any[], state, field = 'id') => {
  entities.forEach(entity => {
    upsertOne(entity, state, field);
  });
  return state;
};

export const getFilter = state => accentFold(state.filter.trim().toLowerCase());
export const mapFilter = filter => obj => objectToSearchableString(obj).indexOf(filter) >= 0;

const getFilterableDataFromState = (pick = null, omit = null) => state =>
  ((state.data && state.data.ids) || [])
    .map(id => ({
      ...((pick && pickBy(state.data.entities[id], pick)) ||
        (omit && omitBy(state.data.entities[id], omit)) ||
        state.data.entities[id]),
      _id: id
    }))
    .filter(mapFilter(getFilter(state)))
    .map(r => state.data.entities[r._id]);

export const getSelectors = () => ({
  selectById: state => id => state.data.entities[id],
  selectAll: state => (state.data.ids || []).map(id => state.data.entities[id]) || [],
  selectAllFiltered: getFilterableDataFromState,
  selectSelected: state => state.data.entities[state.data.selected],
  selectTotal: state => state.data.ids.length,
  selectIds: state => state.data.ids,
  selectEntities: state => state.data.entities
});

export default ({
  collapsed = true,
  // eslint-disable-next-line no-unused-vars
  filter = (mutation, stateBefore, stateAfter) => true,
  transformer = state => state,
  mutationTransformer = mutation => mutation
} = {}) => store => {
  let prevState = cloneDeep(store.state);

  store.subscribe((mutation, state) => {
    const nextState = cloneDeep(state);
    const diffState = difference(nextState, prevState);

    if (filter(mutation, prevState, nextState)) {
      const time = new Date().toISOString().split('T')[1].slice(0, 8);
      const transformedMutation = mutationTransformer(mutation);
      const body = `${mutation.type} ( ${time} )`;

      /* eslint-disable no-console */
      if (collapsed) {
        console.groupCollapsed(body);
      }

      console.log('%c previous state', 'color: gray; front-weight: bold', transformer(prevState));
      console.log('%c mutation', 'color: orange; front-weight: bold', transformedMutation);
      console.log('%c diff state', 'color: blue; front-weight: bold', transformer(diffState));
      console.log('%c next state', 'color: green; front-weight: bold', transformer(nextState));

      if (collapsed) {
        console.groupEnd();
      }
      /* eslint-enable no-console */
    }

    prevState = nextState;
  });
};
