import MsgboxConfirm from '@/components/msgbox-confirm/MsgboxConfirm.vue';
import Vue from 'vue';

export const Confirm = {
  bind: (el, binding, a) => {
    let isConfirmed = false;
    el.addEventListener('click', () => {
      if (isConfirmed) {
        return;
      }
      const ConfirmationBox = Vue.extend(MsgboxConfirm);

      const instance = new ConfirmationBox({
        i18n: a.context.$i18n,
        propsData: {
          message: binding.value,
          title: 'Confirmation'
        }
      });

      instance.$on('accept', () => {
        isConfirmed = true;
        el.dispatchEvent(new Event('accept'));
        instance.$destroy();
        instance.$el.remove();
      });

      instance.$on('cancel', () => {
        instance.$destroy();
        instance.$el.remove();
      });

      instance.$mount();

      el.parentNode.appendChild(instance.$el);
    });
  }
};
