<template>
  <div v-loading="isPending" class="paymode-sales__chart">
    <h5>{{ $t('sales.paymodeChart.title', { limit }) }}</h5>
    <div v-if="!isPending">
      <div class="chart__toolbar" v-if="getTotal">
        <apexchart type="pie" :height="height" :options="chartOptions" :series="getSeries" v-if="getTotal" />
      </div>
      <div class="chart__empty-state" v-else>
        <slot name="empty">{{ $t('sales.paymodeChart.empty') }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import VueApexCharts from 'vue-apexcharts';
import formatMoney from '../../../filters/format-money';
import { showChartTooltipOnLegendEvent } from '@/helpers/charts.helpers';
import { useDataSalesPaymodes } from '@/stores/data/sales/paymodes.module';
import { storeToRefs } from 'pinia';
export default {
  name: 'paymode-sales__chart',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    locationId: {
      type: String,
      default: null
    },
    currencyId: {
      type: String,
      default: null
    },
    from: {
      type: String,
      default: null
    },
    to: {
      type: String,
      default: null
    },
    height: {
      type: Number,
      default: 300
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  setup() {
    const salesPaymodesStore = useDataSalesPaymodes();

    const { isPending } = storeToRefs(salesPaymodesStore);

    return { isPending, salesPaymodesStore };
  },
  computed: {
    getSeries() {
      return this.salesPaymodesStore.getSeries(this.currencyId).slice(0, this.limit);
    },
    getLabels() {
      return this.salesPaymodesStore.getLabels(this.currencyId).slice(0, this.limit);
    },
    getTotal() {
      return this.salesPaymodesStore.getTotal(this.currencyId);
    },
    chartOptions() {
      return {
        chart: {
          events: {
            legendClick: showChartTooltipOnLegendEvent(this.$el)
          },
          toolbar: {
            show: false
          }
        },
        yaxis: {
          labels: {
            formatter: val => formatMoney(val, this.currencyId)
          }
        },
        responsive: [
          {
            breakpoint: 1024,
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }
        ],
        labels: this.getLabels
      };
    }
  },
  methods: {},
  mounted() {
    this.salesPaymodesStore.getSalesByPaymode({
      from: DateTime.fromISO(this.from).toJSDate(),
      to: DateTime.fromISO(this.to).toJSDate(),
      locationId: this.locationId
    });
  }
};
</script>
<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$charts-empty-text-color: $shuttle-gray;
$charts-title-text-color: $river-bed;

.paymode-sales__chart {
  font-family: $base-font-family;

  .apexcharts-pie-label {
    fill: white;
  }

  .chart__empty-state {
    color: $charts-empty-text-color;
  }

  .apexcharts-xaxis-texts-g text:nth-child(2n):not(:last-child) {
    visibility: hidden;
  }

  h5 {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
}
</style>
