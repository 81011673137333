<template>
  <vel-form
    :rules="formRules"
    class="discount-form"
    ref="discountForm"
    :model="discountForm"
    :submit="handleSubmit"
    label-width="150px"
    :label-position="labelPosition"
  >
    <vel-form-item prop="remoteId" class="discount-form-remote-id" :label="$t('discountInfo.form.remoteId')">
      <vel-input disabled class="name-input" type="text" v-model="discountForm.remoteId"></vel-input>
    </vel-form-item>
    <vel-form-item prop="name" class="discount-form-name" :label="$t('discountInfo.form.name.label')">
      <vel-input class="name-input" type="text" v-model="discountForm.name" maxlength="23"></vel-input>
    </vel-form-item>
    <vel-form-item
      prop="alternativeName"
      class="discount-form-alternative-name"
      :label="$t('discountInfo.form.alternativeName.label')"
    >
      <vel-input class="name-input" type="text" v-model="discountForm.alternativeName" maxlength="23"></vel-input>
    </vel-form-item>
    <vel-form-item prop="type" class="discount-form-discount-type" :label="$t('discountInfo.form.discountType.label')">
      <vel-select @change="clearInvalidInputs" class="name-input" type="text" v-model="discountForm.type">
        <vel-option v-for="type in types" :key="type.typeId" :label="type.name" :value="type.typeId" />
      </vel-select>
    </vel-form-item>
    <vel-form-item
      prop="amount"
      class="discount-form-amount"
      :label="$t('discountInfo.form.fixedAmount.label', { currency: `${getCurrencySelectorSelected}` })"
    >
      <vel-input
        :disabled="discountForm.type !== 0"
        class="name-input"
        type="text"
        @blur="formatInputs"
        v-model="discountForm.amount"
      ></vel-input>
    </vel-form-item>
    <vel-form-item prop="rate" class="discount-form-rate" :label="$t('discountInfo.form.fixedRate.label')">
      <vel-input
        :disabled="discountForm.type !== 3"
        class="name-input"
        type="text"
        @blur="formatInputs"
        v-model="discountForm.rate"
      ></vel-input>
    </vel-form-item>
    <vel-form-item
      prop="couponValue"
      class="discount-form-coupon-value"
      :label="$t('discountInfo.form.couponValue.label')"
    >
      <vel-input
        :disabled="discountForm.type !== 2"
        class="name-input"
        type="text"
        @blur="formatInputs"
        v-model="discountForm.couponValue"
      ></vel-input>
    </vel-form-item>
    <vel-form-item
      prop="availability"
      class="discount-form-availability"
      :label="$t('discountInfo.form.availability.label')"
    >
      <discount-availability :schedule-by-modes="scheduleByModes" />
    </vel-form-item>
    <vel-form-item class="discount-form__actions">
      <vel-button type="primary" class="actions__submit" @click="handleSubmit()">
        {{ $t('defaults.actions.save') }}
      </vel-button>
      <vel-button type="secondary" class="actions__cancel" @click="navigatePrevious()">
        {{ $t('defaults.actions.cancel') }}
      </vel-button>
    </vel-form-item>
  </vel-form>
</template>

<script>
import DiscountAvailability from './DiscountAvailability';
import VelButton from '@/components/button/VelButton';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelInput from '@/components/x-input/VelInput';
import VelOption from '@/components/x-option/VelOption';
import VelSelect from '@/components/x-select/VelSelect';
import { mapState as mapPiniaState } from 'pinia';
import { useUICurrencySelector } from '@/stores/ui/currency-selector.module';
export default {
  name: 'discount-form',
  components: {
    DiscountAvailability,
    VelButton,
    VelForm,
    VelFormItem,
    VelInput,
    VelOption,
    VelSelect
  },
  props: {
    discount: {
      type: Object,
      default: () => ({
        remoteId: '',
        name: '',
        alternativeName: '',
        type: '',
        amount: '',
        rate: '',
        coupon: '',
        licenceNumber: '',
        isActive: false,
        id: ''
      })
    },
    scheduleByModes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      discountForm: {
        remoteId: this.discount.remoteId,
        name: this.discount.name,
        alternativeName: this.discount.alternativeName,
        type: this.discount.type,
        amount: this.discount.amount ? this.discount.amount.toFixed(2) : null,
        rate: this.discount.rate ? this.discount.rate.toFixed(4) : null,
        couponValue: this.discount.coupon ? this.discount.coupon : null,
        licenceNumber: this.discount.licenceNumber,
        isActive: this.discount.isActive,
        id: this.discount.id
      }
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.discountForm.validate(isValid => {
        if (isValid) {
          this.$emit('submit', {
            ...this.discountForm,
            amount: Number(this.discountForm.amount),
            rate: Number(this.discountForm.rate),
            coupon: Number(this.discountForm.couponValue)
          });
          this.navigatePrevious();
        }
      });
    },
    clearInvalidInputs(e) {
      ['amount', 'rate', 'couponValue'].forEach(s => {
        if (this.discountForm.type !== e.value) {
          this.discountForm[s] = '';
        }
        this.$refs.discountForm.clearValidate();
      });
    },
    formatInputs() {
      this.discountForm.amount =
        this.discountForm.amount && Number(!isNaN(this.discountForm.amount))
          ? Number(this.discountForm.amount).toFixed(2)
          : null;
      this.discountForm.rate =
        this.discountForm.rate && Number(!isNaN(this.discountForm.rate))
          ? Number(this.discountForm.rate).toFixed(4)
          : null;
    },
    navigatePrevious() {
      this.$router.push('/discounts');
    },
    validateAmount(rule, value, cb) {
      if (this.discountForm.type === 0) {
        if (value > 9999.99 || value < 0 || Number.isNaN(Number(value))) {
          cb(new Error(this.$t('discountInfo.form.fixedAmount.value')));
        } else {
          cb();
        }
      } else {
        cb();
      }
    },
    validateRate(rule, value, cb) {
      if (this.discountForm.type === 3) {
        if (value > 100 || value < 0 || Number.isNaN(Number(value))) {
          cb(new Error(this.$t('discountInfo.form.fixedRate.value')));
        } else {
          cb();
        }
      } else {
        cb();
      }
    },
    validateCouponValue(rule, value, cb) {
      if (this.discountForm.type === 2) {
        if (value > 999 || value < 0 || Number.isNaN(Number(value))) {
          cb(new Error(this.$t('discountInfo.form.couponValue.value')));
        } else {
          cb();
        }
      } else {
        cb();
      }
    }
  },
  computed: {
    ...mapPiniaState(useUICurrencySelector, ['getCurrencySelectorSelected']),
    formRules() {
      return {
        amount: [
          {
            validator: this.validateAmount,
            trigger: ['change', 'blur']
          }
        ],
        rate: [
          {
            validator: this.validateRate,
            trigger: ['change', 'blur']
          }
        ],
        couponValue: [
          {
            validator: this.validateCouponValue,
            trigger: ['change', 'blur']
          }
        ]
      };
    },
    types() {
      return [
        { typeId: 0, name: this.$t('discountInfo.form.type.fixedAmount') },
        { typeId: 1, name: this.$t('discountInfo.form.type.openAmount') },
        { typeId: 2, name: this.$t('discountInfo.form.type.couponValue') },
        { typeId: 3, name: this.$t('discountInfo.form.type.fixedPercentage') },
        { typeId: 4, name: this.$t('discountInfo.form.type.openPercentage') },
        { typeId: 5, name: this.$t('discountInfo.form.type.fixedPercentageDivision') },
        { typeId: 6, name: this.$t('discountInfo.form.type.fixedPercentageItem') },
        { typeId: 7, name: this.$t('discountInfo.form.type.fixedPercentageItemDivision') },
        { typeId: 8, name: this.$t('discountInfo.form.type.openCappedPercentageDivision') },
        { typeId: 9, name: this.$t('discountInfo.form.type.fixedCappedPercentageDivision') }
      ];
    },
    labelPosition() {
      return this.isMediumDown ? 'top' : 'left';
    },
    getCurrencySelectorSelected() {
      return this.getCurrencySelectorSelected;
    }
  },
  mounted() {
    //@todo there has to be a better way. cannot use !important (pre-push hooks block it)
    const arr = document.querySelectorAll('.el-form-item__content');
    if (arr.length) {
      arr[arr.length - 1].style.marginLeft = '0px';
      arr[arr.length - 2].style.marginLeft = '0px';
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

.discount-form {
  input:disabled {
    background-color: $athens-gray;
  }

  &-name,
  &-alternative-name {
    max-width: rem-calc(500);
  }

  &-discount-type {
    .name-input {
      width: rem-calc(145);

      @include breakpoint(large) {
        width: rem-calc(350);
      }
    }
  }

  &-amount,
  &-rate,
  &-coupon-value {
    .el-input__inner {
      max-width: rem-calc(150);
      padding-right: rem-calc(10);
      text-align: right;
    }
  }

  &-remote-id {
    max-width: rem-calc(300);
  }

  &-availability {
    display: flex;
    flex-direction: column;
    max-width: rem-calc(600);

    .actions {
      display: flex;
    }
  }

  &__actions {
    .el-form-item__content {
      .actions {
        &__cancel {
          margin-left: rem-calc(10);
        }
      }
    }
  }
}
</style>
