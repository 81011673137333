<template>
  <b-container style="border-bottom: 8px solid #f5f5f5;" fluid>
    <b-row style="border-bottom: 1px solid #4c566733;" class="py-3">
      <b-col md="3" style="display: flex; flex-direction: row;">
        <div class="title-icon d-none">
          <b-icon icon="pencil-fill"></b-icon>
        </div>
        <div style="padding-left: 1rem;">
          <h4 class="title-right mb-2 my-0">{{ employee.name || '--' }}</h4>
          <div class="title-right" style="font-size: 1rem;">
            {{
              (employee.jobRoles || [])
                .filter(x => x.delete !== true && x.functionId !== null)
                .map(b => (accessTypes || []).filter(x => x.id === b.functionId)[0])
                .map(b => (b && b.name) || '')
                .join(', ') || '--'
            }}
          </div>
        </div>
      </b-col>

      <b-col style="align-items: center; display: flex; justify-content: flex-start;">
        <label for="terminated" class="mb-0">{{ $t('employeeCreate.deactivateEmployee') }}</label>
        <b-form-checkbox
          switch
          id="terminated"
          name="terminated"
          v-model="employee.terminated"
          style="font-size: 13px;"
          class="ml-2 mr-n2"
        >
          <!--<b-icon icon="question-circle-fill" variant="secondary"></b-icon>-->
        </b-form-checkbox>
        <!--
        <b-button v-if="employee.id" variant="outline-primary" style="margin-left: 1rem;" v-b-toggle.history-sidebar>
          {{ $t('employeeCreate.history.title') }}
        </b-button> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'employee-header',
  components: {},
  props: {
    accessTypes: {
      type: Array,
      default: () => []
    },
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    submited: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {},
  mounted() {
    /* eslint-disable-next-line no-console */
  }
};
</script>
<style lang="scss" scoped>
.title-icon {
  align-items: center;
  background-color: #ccc;
  border-radius: 1.6rem;
  display: flex;
  height: 3.2rem;
  justify-content: center;
  width: 3.2rem;
}
</style>
