<template>
  <vel-page class="analytics" v-bind="pageAttrs">
    <div class="graph">
      <vel-card>
        <locations-per-month />
      </vel-card>
      <vel-card>
        <gross-sales-per-month />
      </vel-card>
      <vel-card>
        <locations-per-version />
      </vel-card>
      <vel-card>
        <locations-per-currency />
      </vel-card>
      <vel-card>
        <users-per-month />
      </vel-card>
      <vel-card>
        <users-cummulative-month />
      </vel-card>
    </div>
  </vel-page>
</template>

<script>
import GrossSalesPerMonth from '@/private/components/sales/GrossSalesPerMonth';
import LocationsPerCurrency from '@/private/components/locations/LocationsPerCurrency';
import LocationsPerMonth from '@/private/components/locations/LocationsPerMonth';
import LocationsPerVersion from '@/private/components/locations/LocationsPerVersion';
import UsersCummulativeMonth from '@/private/components/users/UsersCummulativeMonth';
import UsersPerMonth from '@/private/components/users/UsersPerMonth';
import VelCard from '@/components/card/VelCard';
import VelPage from '@/components/page/VelPage';

export default {
  name: 'analytics-page',
  components: {
    GrossSalesPerMonth,
    LocationsPerMonth,
    LocationsPerCurrency,
    LocationsPerVersion,
    UsersCummulativeMonth,
    UsersPerMonth,
    VelPage,
    VelCard
  },
  computed: {
    pageAttrs() {
      return {
        enableToolbar: false,
        title: this.$t('pages.analytics'),
        showCrumbs: false
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

$indicator-grid-column-gap: rem-calc(20);

.graph {
  display: grid;
  grid-template-columns: 1fr;

  @include breakpoint(medium) {
    grid-gap: $indicator-grid-column-gap;
    grid-template-columns: 1fr;
    margin-bottom: rem-calc(20);
  }
  @include breakpoint(large) {
    grid-template-columns: 1fr 1fr;
  }

  > * {
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}
</style>
