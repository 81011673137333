<template>
  <vel-backoffice-page
    class="backoffice-page bootstrap"
    :enableToolbar="false"
    :enable-date-range="false"
    :enable-date-picker="false"
    :enable-groups="false"
    :show-date-compare="false"
    :enable-all-locations="false"
    :scroll="false"
  >
    <template v-slot:title>
      {{ $t('pages.location-configuration') }}
    </template>
    <template v-slot:header>
      <b-container fluid>
        <b-row class="d-flex flex-column flex-md-row pt-3">
          <b-col class="d-flex align-items-center">
            <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
          </b-col>
          <b-col class="d-flex justify-content-end align-items-center"></b-col>
        </b-row>
        <b-row class="my-4">
          <b-col cols="12" md="6" class="d-flex flex-row justify-content-between">
            <h4>{{ $t('popup.list.title') }}</h4>
            <b-button variant="primary" class="ml-4 d-flex align-items-center d-md-none" :to="{ name: 'combo-create' }">
              <b-icon icon="plus" aria-hidden="true" scale="1.4"></b-icon>
              <span class="d-none d-md-block">{{ $t('popup.list.buttons.new') }}</span>
            </b-button>
          </b-col>
          <b-col cols="12" md="6" class="d-flex mt-4 mt-md-0 flex-row justify-content-md-end flex-md-row">
            <b-input-group style="max-width: 250px;" class="align-content-end align-self-end">
              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="filter"
                debounce="1"
                type="text"
                :placeholder="$t('popup.list.searchPlaceholder')"
              ></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <b-container fluid class="h-100 pt-4 pb-4" id="table-container">
      <b-table
        striped
        bordered
        hover
        show-empty
        fixed
        :filter="filter"
        primary-key="id"
        :empty-text="$t('popup.empty')"
        :empty-filtered-text="$t('popup.empty')"
        :filter-included-fields="filterOn"
        :per-page="perPage"
        :current-page="currentPage"
        :filter-debounce="800"
        :busy="loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :fields="fields"
        head-variant="info"
        :items="myProvider"
        responsive="sm"
        ref="table"
        sticky-header
      >
        <template #empty="scope">
          <div class="p-2">{{ scope.emptyText }}</div>
        </template>
        <template #emptyfiltered="scope">
          <div class="p-2">{{ scope.emptyFilteredText }}</div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner variant="primary" class="align-middle p-2 m-4"></b-spinner>
          </div>
        </template>
        <template #cell(edit)="scope">
          <b-button variant="link" class="p-0" :to="{ name: 'item-profile-edit', params: { id: scope.item.id } }">
            <b-icon icon="pencil-fill" aria-label="Edit" class="row-action text-primary"></b-icon>
            {{ $t('employee.list.table.edit') }}
          </b-button>
        </template>
        <template #cell(delete)="scope">
          <b-button variant="link text-danger" @click="deleteEntity = scope.item">
            <b-icon icon="trash-fill" aria-label="Delete" class="row-action text-danger"></b-icon>
            {{ $t('mealPlan.delete') }}
          </b-button>
        </template>
      </b-table>
    </b-container>
    <template v-slot:footer>
      <b-container fluid>
        <div class="d-flex justify-content-end align-items-center">
          <b-form-group class="d-none d-md-block">
            {{ $t('popup.list.rowsPerPage') }}
            <b-form-select
              size="sm"
              v-model="perPage"
              :options="[10, 25, 50, 100]"
              style="width: 80px;"
              class="mx-3"
            ></b-form-select>
          </b-form-group>
          <span class="d-none d-md-flex align-self-center mr-6">
            {{ currentPage * perPage - perPage + 1 }} - {{ Math.min(currentPage * perPage, totalRows) }} of
            {{ totalRows }}
          </span>
          <b-pagination
            size="sm"
            pills
            class="m-0 ml-2"
            v-model="currentPage"
            :total-rows="totalRows"
            @filtered="onFiltered"
            :per-page="perPage"
            :limit="10"
            align="right"
          ></b-pagination>
        </div>
      </b-container>
    </template>
  </vel-backoffice-page>
</template>

<script>
import * as JsSearch from 'js-search';
import { deleteItemProfile, getItemPopups } from '@/services/items.service';
import VelBackofficePage from '@/components/page/VelBackofficePage';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { useDataLocationsStore } from '@/stores/data/locations.module';

export default {
  name: 'PopupListPage',
  components: {
    VelBackofficePage
  },
  data() {
    return {
      totalRows: 0,
      filter: null,
      filterOn: ['name'],
      perPage: window.localStorage.getItem('PopupRowsPerPage') || 25,
      currentPage: 1,
      results: [],
      loading: false,
      clearMessage: true,
      isError: false,
      itemProfiles: [],
      sortBy: 'name',
      deleteEntity: null,
      sortDesc: false
    };
  },
  props: {},
  computed: {
    ...mapPiniaState(useUILocationSelector, {
      locationId: 'getLocationSelectorSelected'
    }),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsIsLoading', 'locationsBySelectedGroup']),
    ...mapPiniaState(useDataLocationsStore, ['getLocationsAll']),
    breadcrumbs() {
      return [
        {
          text: this.$t('setupMenuPage.title'),
          active: false,
          to: { name: 'setup-menu', query: { previous: 'menu', locationIds: this.locationId } }
        },
        {
          text: this.$t('popup.list.title'),
          active: true
        }
      ];
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('popup.list.table.headers.name'),
          class: 'name',
          sortable: true
        }
      ];
    }
  },
  watch: {
    perPage(val) {
      window.localStorage.setItem('PopupRowsPerPage', val);
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['onChange']),
    toast(msg = 'toast.update', value = {}) {
      this.$toast.open({
        message: `${this.$t(msg, value)}`,
        position: 'bottom',
        type: 'success',
        queue: false,
        duration: 6000,
        dismissible: true
      });
    },
    async deleteItemProfile(profile) {
      try {
        await deleteItemProfile(profile.id);
        await this.$refs.table.refresh();

        this.toast('toast.itemProfileDelete', profile);
      } catch (e) {
        console.error(e);
      } finally {
        this.deleteEntity = null;
      }
    },
    onLocationChange(locationId) {
      this.onChange({
        locationId
      });
    },
    async myProvider(ctx) {
      try {
        this.loading = true;
        const startAt = ctx.currentPage === 1 ? 0 : (ctx.currentPage - 1) * ctx.perPage;

        if (!this.results.length || !ctx.filter || ctx.filter === '') {
          this.results = ((await getItemPopups({ locationId: this.locationId }).then(r => r.content || [])) || []).map(
            r => ({
              ...r,
              name: r.name.trim() === '' ? '---' : r.name
            })
          );
        }

        let results = this.results;

        if (ctx.filter) {
          const search = new JsSearch.Search('id');
          search.addIndex('name');
          search.addDocuments(results);
          results = search.search(ctx.filter);
        }

        if (ctx.sortBy !== '') {
          results = results.sort((a, b) =>
            ctx.sortDesc ? b[ctx.sortBy].localeCompare(a[ctx.sortBy]) : a[ctx.sortBy].localeCompare(b[ctx.sortBy])
          );
        }

        this.totalRows = results.length;
        this.itemProfiles = results.slice(startAt, startAt + ctx.perPage);
        this.loading = false;
        return this.itemProfiles || [];
      } catch (e) {
        console.error(e);
        this.loading = false;
        this.isError = true;
        return [];
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.bootstrap .container-fluid {
  padding-left: 24px;
  padding-right: 24px;
}

.edit {
  width: 115px;

  .btn-sm {
    padding: 0;
  }

  div {
    display: none;
  }
}

/* stylelint-disable */
/deep/ {
  .input-group > .form-control {
    border-left-color: #fff;
    padding-left: 0;
  }
  .input-group-text {
    background-color: #fff !important;
    border-right: 1px solid #fff !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  th {
    background-color: #eff9fd;
  }
  .page,
  .page .vel-page__content {
    overflow: hidden;
  }

  .content,
  .vel-card__body,
  .b-table-sticky-header {
    height: 100%;
    max-height: 100%;
    padding-top: 0;
  }

  .btn-group {
    button {
      text-transform: uppercase;
      width: 90px;
    }
  }
  .pagination {
    height: calc(1.5em + 0.5rem + 2px);
    display: flex;
    align-items: center;
  }

  .thead-info {
    background-color: #eff9fd;
    font-weight: bold;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f6fbfe !important;
  }

  .table-hover tbody tr:hover {
    background-color: transparent !important;
  }

  .table-hover tbody tr:nth-of-type(odd):hover {
    background-color: #f6fbfe !important;
  }

  .edit {
    width: 115px;

    .btn-sm {
      padding: 0;
    }

    div {
      display: none;
    }
  }

  .more {
    width: 50px;

    .btn-sm {
      padding: 0;
    }

    div {
      display: none;
    }
  }

  .content {
    margin-bottom: 0;
  }

  .row-action {
    color: #444444;
  }
  .page-item.active .page-link {
    background-color: #000 !important;
  }
  .page-link {
    border: none !important;
    color: #444444;
  }

  .toolbar__location-selector {
    max-width: 100%;

    @include breakpoint(medium) {
      max-width: 360px;
    }
  }

  .breadcrumbs {
    padding-top: 16px;
  }

  /deep/ .breadcrumb-item {
    font-size: 0.9rem;
  }

  /deep/ .input-group-sm > .form-control:not(textarea) {
    height: calc(1.5em + 0.5rem + 7px);
  }

  /deep/ table td {
    border-color: #2a31420d !important;
    border-top: none !important;
    padding: 8px 16px !important;
  }

  /deep/ table th {
    padding: 8px 16px !important;
  }

  /deep/ .vel-icon_small {
    height: 1.15rem;
    width: 1.15rem;
  }
}
</style>
