<template>
  <div v-loading="loading" class="users-commulative__chart">
    <h5>{{ $t('analytics.grossSalesPerMonth.title') }}</h5>
    <div v-if="!loading">
      <apexchart v-if="!loading" type="area" height="330" :options="chartOptions" :series="series" />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import VueApexCharts from 'vue-apexcharts';
import formatAmounts from '@/filters/format-amounts';
import { getGrossSalesMonthly } from '@/services/analytics';

export default {
  name: 'gross-sales-per-month',
  components: {
    apexchart: VueApexCharts
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          zoom: {
            enabled: false
          },
          selection: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        colors: [
          '#ADD8C7',
          '#F7B844',
          '#008ffb',
          '#EC3C65',
          '#ADD8C7',
          '#F7B844',
          '#008ffb',
          '#EC3C65',
          '#CDD7B6',
          '#C1F666',
          '#D43F97',
          '#1E5D8C',
          '#421243',
          '#7F94B0',
          '#EF6537',
          '#C0ADDB'
        ],
        dataLabels: {
          formatter(a) {
            return formatAmounts(a);
          },
          enabled: false,
          enabledOnSeries: [0, 1, 2],
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },
        stroke: {
          width: [2, 2, 2, 2, 2, 2, 2, 2],
          curve: 'straight',
          dashArray: [0, 0, 0, 0, 2, 2, 2, 2]
        },
        legend: {
          onItemClick: {
            toggleDataSeries: true
          },
          position: 'bottom',
          horizontalAlign: 'right',
          floating: true,
          offsetY: 8,
          offsetX: 5,
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          }
        },
        fill: {
          type: ['fill', 'fill', 'fill', 'fill', 'fill', 'fill', 'fill']
        },
        xaxis: {
          formatter(a) {
            return formatAmounts(a);
          },
          categories: this.labels(),
          position: 'top',
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          }
        },
        yaxis: {
          title: {
            text: this.$t('analytics.grossSalesPerMonth.yaxis')
          },
          forceNiceScale: true,
          labels: {
            formatter: a => formatAmounts(a)
          }
        },
        tooltip: {
          y: {
            formatter: a => formatAmounts(a, 2)
          }
        },
        title: {
          text: this.$t('analytics.grossSalesPerMonth.xaxis'),
          floating: true,
          offsetY: 305,
          position: 'bottom',
          align: 'center',
          fontSize: '12px',
          style: {
            color: '#444',
            'font-size': '20px'
          }
        }
      };
    },
    series() {
      const data = {};

      (this.values || []).forEach(d => {
        const year = new Date(`${d.year}-01-02`).getUTCFullYear();

        if (!data[year]) {
          data[year] = {
            name: year.toString(),
            type: 'line',
            data: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 }
          };
        }
        d.grossSales.forEach(x => {
          data[year].data[x.month] = x.total;
        });

        if (year === new Date().getUTCFullYear()) {
          for (let i = new Date().getUTCMonth() + 2; i <= 12; i++) {
            data[year].data[i] = null;
          }
        }

        if (!data[year].data[2]) {
          data[year].data[2] = data[year].data[1];
        }
      });

      (this.values || []).reverse().forEach(d => {
        const year = new Date(`${d.year}-01-02`).getUTCFullYear();

        if (!data[`${year}avg`]) {
          data[`${year}avg`] = {
            name: this.$t('analytics.grossSalesPerMonth.avg', { year }),
            type: 'line',
            data: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 }
          };
        }
        d.grossSales.forEach(x => {
          data[`${year}avg`].data[x.month] = x.total / x.locationCount;
        });

        if (year === new Date().getUTCFullYear()) {
          for (let i = new Date().getUTCMonth() + 2; i <= 12; i++) {
            data[`${year}avg`].data[i] = null;
          }
        }
      });
      console.log(
        'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
        Object.values(data).map(d => ({
          ...d,
          data: Object.values(d.data).map(x => Number(x && x.toFixed(2)) || null)
        }))
      );
      return Object.values(data).map(d => ({
        ...d,
        data: Object.values(d.data).map(x => Number(x && x.toFixed(2)) || null)
      }));
    },
    values() {
      return (this.data || []).sort((a, b) => b.year - a.year);
    }
  },
  data() {
    return {
      loading: true,
      data: [],
      labels() {
        return [
          '2021-01-01',
          '2021-02-01',
          '2021-03-01',
          '2021-04-01',
          '2021-05-01',
          '2021-06-01',
          '2027-07-01',
          '2021-08-01',
          '2021-09-01',
          '2021-10-01',
          '2021-11-01',
          '2021-12-01'
        ].map(d => DateTime.fromISO(d).toFormat('LLL'));
      }
    };
  },
  async mounted() {
    try {
      this.data = await getGrossSalesMonthly().then(l => (l || []).filter(d => d.year <= new Date().getUTCFullYear()));
    } finally {
      this.loading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
h5 {
  margin-bottom: 1rem;
  text-transform: uppercase;
}

/deep/ .apexcharts-legend-text,
/deep/ .apexcharts-tooltip-text-label {
  display: inline-block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

/deep/ .apexcharts-title-text {
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 12px !important;
  font-weight: 900 !important;
  text-transform: capitalize !important;
}

/deep/ div[seriesname^='Averagex'] {
  display: none !important;
}

/deep/ div[seriesname^='Moyennex'] {
  display: none !important;
}
</style>
