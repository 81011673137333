import { defineStore } from 'pinia';
import { useUIAlertStore } from '@/stores/ui/alert.module';
import locationsService from '@/services/locations.service';
import { getToken } from '@/helpers/token.helper';
import { createCollectionState, getSelectors, removeAll, setAll } from '@/helpers/stores.helpers';
import { filterLocationsVersionByLocationsIds, isValidVersion } from '@/helpers/versions.module.helper';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import cloneDeep from 'lodash.clonedeep';
import router from '@/router';
import groupsService from '@/services/groups.service';

const { selectAll } = getSelectors();

export const useDataLocationsGroupsLocation = defineStore('locationsGroupsLocation', {
  state: () =>
    createCollectionState({
      status: {},
      errors: {}
    }),
  getters: {
    getLocationGroupsError(state) {
      return this.errors.getLocationGroupsError || null;
    },
    addLocationToGroupError(state) {
      return this.errors.addLocationToGroupError || null;
    },
    deleteLocationFromGroupError(state) {
      return this.errors.deleteLocationFromGroupError || null;
    },
    isSavingGroups: state => !!state.status.isSavingGroups,
    isLoadingLocationGroups: state => !!state.status.isLoadingLocationGroups,
    latestAddedLocationToGroup: state => (state.status && state.status.latestAddedLocationToGroup) || null,
    latestDeletedLocationFromGroup: state => (state.status && state.status.latestDeletedLocationFromGroup) || null,
    groups: selectAll
  },
  actions: {
    getLocationGroups(locationId) {
      this.getLocationGroupsRequest();

      locationsService.getLocationGroups(getToken(), locationId).then(
        groups => {
          this.getLocationGroupsSuccess(groups);
        },
        error => {
          this.getLocationGroupsFailure(error);
        }
      );
    },
    addLocationToGroup({ locationId, groupId }) {
      this.addLocationToGroupRequest();

      const locationIds = { locationIds: [locationId] };

      groupsService.addGroupLocations(getToken(), groupId, locationIds).then(
        groups => {
          this.addLocationToGroupSuccess(groups.length);
          this.getLocationGroups(locationId);
        },
        error => {
          this.addLocationToGroupFailure(error);
        }
      );
    },
    deleteLocationFromGroup({ locationId, group }) {
      this.deleteLocationFromGroupRequest();

      const { value: groupId } = group;

      groupsService.deleteGroupLocation(getToken(), groupId, locationId).then(
        () => {
          this.deleteLocationFromGroupSuccess(group);
          this.getLocationGroups(locationId);
        },
        error => {
          this.deleteLocationFromGroupFailure(error);
        }
      );
    },
    clearGetLocationGroupsError() {
      this.errors = { ...this.errors, getLocationGroupsError: null };
    },
    clearAddLocationToGroupError() {
      this.errors = { ...this.errors, addLocationToGroupError: null };
    },
    clearDeleteLocationFromGroupError() {
      this.errors = { ...this.errors, deleteLocationFromGroupError: null };
    },
    clearLatestAddedLocationToGroup() {
      this.status = { ...this.status, latestAddedLocationToGroup: null };
    },
    clearLatestDeletedLocationFromGroup() {
      this.status = { ...this.status, latestDeletedLocationFromGroup: null };
    },
    resetLocationGroups() {
      this.reset();
    },
    getLocationGroupsRequest() {
      this.status = { ...this.status, isLoadingLocationGroups: true };
      this.data = removeAll(this.data);
      this.errors = { ...this.errors, getLocationGroupsError: null };
    },
    getLocationGroupsSuccess(groups) {
      this.status = { ...this.status, isLoadingLocationGroups: false };
      this.data = setAll(groups, this.data);
      this.errors = { ...this.errors, getLocationGroupsError: null };
    },
    getLocationGroupsFailure(error) {
      this.status = { ...this.status, isLoadingLocationGroups: false };
      this.data = removeAll(this.data);
      this.errors = { ...this.errors, getLocationGroupsError: error };
    },
    addLocationToGroupRequest() {
      this.status = { ...this.status, latestAddedLocationToGroup: null, isSavingGroups: true };
      this.errors = { ...this.errors, addLocationToGroupError: null };
    },
    addLocationToGroupSuccess(addedLocationsCount) {
      this.status = { ...this.status, latestAddedLocationToGroup: addedLocationsCount, isSavingGroups: false };
      this.errors = { ...this.errors, addLocationToGroupError: null };
    },
    addLocationToGroupFailure(error) {
      this.status = { ...this.status, latestAddedLocationToGroup: null, isSavingGroups: false };
      this.errors = { ...this.errors, addLocationToGroupError: error };
    },
    deleteLocationFromGroupRequest() {
      this.status = { ...this.status, latestDeletedLocationFromGroup: null, isSavingGroups: true };
      this.errors = { ...this.errors, deleteLocationFromGroupError: null };
    },
    deleteLocationFromGroupSuccess(deletedLocation) {
      this.status = { ...this.status, latestDeletedLocationFromGroup: deletedLocation, isSavingGroups: false };
      this.errors = { ...this.errors, deleteLocationFromGroupError: null };
    },
    deleteLocationFromGroupFailure(error) {
      this.status = { ...this.status, latestDeletedLocationFromGroup: null, isSavingGroups: false };
      this.errors = { ...this.errors, deleteLocationFromGroupError: error };
    },
    reset() {
      this.data = removeAll(this.data);
      this.status = {};
      this.errors = {};
    }
  }
});
