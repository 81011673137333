<template>
  <b-table
    thead-class="bg-info"
    striped
    small
    hover
    show-empty
    fixed
    primary-key="id"
    :items="accessTypes"
    :fields="fields"
    :filter="filter"
    :filter-included-fields="filterOn"
    responsive="sm"
    ref="table"
    :empty-text="$t('mealPlan.empty')"
    :empty-filtered-text="$t('mealPlan.empty')"
    class="mt-4"
  >
    <template #empty="scope">
      <div class="p-2">{{ scope && scope.emptyText }}</div>
    </template>
    <template #emptyfiltered="scope">
      <div class="p-2">{{ scope && scope.emptyFilteredText }}</div>
    </template>
    <template #cell(edit)="scope">
      <b-button variant="link" @click="$emit('onModify', scope)" v-b-toggle.accessTypeForm-sidebar>
        <b-icon icon="pencil-fill" aria-label="Edit" class="row-action text-primary"></b-icon>
        {{ $t('accessType.edit') }}
      </b-button>
    </template>
    <template #cell(delete)="scope">
      <b-button variant="link text-danger" @click="$emit('onDelete', scope.item)">
        <b-icon icon="trash-fill" aria-label="Delete" class="row-action text-danger"></b-icon>
        {{ $t('accessType.delete') }}
      </b-button>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'access-type-list',
  model: {},
  props: {
    accessTypes: {
      type: Array,
      default: () => []
    },
    pending: {
      type: Boolean,
      default: false
    },
    filter: {
      type: String,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filterOn: ['name']
    };
  },
  watch: {},
  computed: {
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('jobRoles.list.table.headers.name'),
          class: 'name vertical-align-center'
        },
        {
          key: 'edit',
          label: '',
          class: 'edit'
        },
        {
          key: 'delete',
          label: '',
          class: 'edit'
        }
      ];
    },
    mealPlan() {
      return { name: this.mealPlanName, licenceNumber: this.licenceNumber };
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
/deep/ .edit {
  width: 115px;
}

/deep/ .table {
  border: 1px solid #2a31420d;
}

/deep/ .table thead th {
  border-bottom: 1px solid #2a31420d;
  border-top: none;
}
</style>
