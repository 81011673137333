<template>
  <div class="indicator-grid">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'indicator-grid'
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$indicator-grid-column-gap: rem-calc(20);

.indicator-grid {
  display: grid;
  grid-template-columns: 1fr;

  @include breakpoint(medium) {
    grid-gap: $indicator-grid-column-gap;
    grid-template-columns: 1fr 1fr;
    margin-bottom: rem-calc(20);
  }
  @include breakpoint(large) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include breakpoint(xlarge) {
    grid-template-columns: repeat(4, 1fr);
  }

  .vel-card {
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}
</style>
