<template>
  <indicator
    color="blue"
    :amount="amount"
    :previous-amount="previousAmount"
    :loading="loading"
    iconName="label-percent"
  >
    <template v-slot:title>
      {{ $t('dashboard.discountsPercentage.title') }}
    </template>
    <template v-slot:value>
      <vel-amount :amount="amount" />
      %
    </template>
    <template v-slot:tooltip>
      {{ $t('dashboard.discountsPercentage.description') }}
    </template>
  </indicator>
</template>

<script>
import Indicator from '@/components/indicator/Indicator.vue';
import VelAmount from '@/components/amount/VelAmount';

export default {
  name: 'discounts-percentage',
  components: {
    Indicator,
    VelAmount
  },
  props: {
    amount: {
      default: 0
    },
    previousAmount: {
      default: null
    },
    loading: {
      default: true
    }
  }
};
</script>
