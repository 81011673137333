<template>
  <vel-card class="entity__info">
    <template v-slot:title>
      {{ $t(translationPrefix + '.title') }}
    </template>

    <div v-if="!isLoading && entity && properties">
      <div class="entity__info-avatar" v-if="avatar">
        <vel-avatar :fullname="entity[avatar]" />
      </div>

      <div class="entity__info-details">
        <p class="property" v-for="property in filteredProperties" v-bind:key="property">
          <span class="property__label">{{ $t(translationPrefix + '.' + property) }}</span>
          <span class="property__value">{{ transformValueByKey(property, entity[property]) }}</span>
        </p>

        <p class="property" v-for="property in explicitProperties" v-bind:key="property.name">
          <span class="property__label">{{ $t(translationPrefix + '.' + property.name) }}</span>
          <span class="property__value">{{ property.value }}</span>
        </p>
      </div>
    </div>

    <vel-spinner v-else />
  </vel-card>
</template>

<script>
import VelAvatar from '@/components/avatar/VelAvatar';
import VelCard from '@/components/card/VelCard';
import VelSpinner from '@/components/spinner/VelSpinner';

export default {
  name: 'vel-entity-information-card',
  props: {
    entity: {
      type: Object
    },
    translationPrefix: {
      type: String
    },
    properties: {
      type: Array
    },
    explicitProperties: {
      type: Array,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    avatar: {
      type: String,
      default: null
    }
  },
  components: {
    VelAvatar,
    VelCard,
    VelSpinner
  },
  computed: {
    filteredProperties() {
      return this.properties.filter(property => this.entity[property] !== undefined);
    }
  },
  methods: {
    transformValueByKey(locationKey, locationValue) {
      if (locationKey === 'reseller' && locationValue) {
        return locationValue.name;
      }
      return locationValue;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.entity__info-avatar {
  text-align: center;

  .vel-avatar {
    float: none;
    height: 64px;
    text-align: center;
    width: 64px;

    @include breakpoint(medium) {
      float: right;
    }
  }
}

.property {
  margin-bottom: 8px;

  .property__label {
    color: $property__label-color;
  }

  .property__value {
    margin-left: 8px;
  }
}
</style>
