import { render, staticRenderFns } from "./PrivatePage.vue?vue&type=template&id=67877cae&"
import script from "./PrivatePage.vue?vue&type=script&lang=js&"
export * from "./PrivatePage.vue?vue&type=script&lang=js&"
import style0 from "./PrivatePage.vue?vue&type=style&index=0&id=67877cae&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports