<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="600px"
    right
    id="itemForm-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarCoursingForm"
    v-model="isSidebarVisible"
    :no-close-on-backdrop="$refs.form && $refs.form.haveChanges"
    :no-close-on-esc="$refs.form && $refs.form.haveChanges"
    @hidden="onHide"
  >
    <template #header>
      <h6 class="bg-gray-200 bg-light w-100 mb-0 text-center py-2">
        {{
          $t(
            discountCoupon && (discountCoupon.id || discountCoupon.editIndex >= 0)
              ? 'discount.edit.list.edit.title'
              : 'discount.edit.list.create.title'
          )
        }}
      </h6>
    </template>
    <template #footer="{ hide }">
      <div
        class="d-flex bg-light text-dark align-items-center justify-content-between px-3 py-2"
        style="border-top: 2px solid #d6d6d8;"
      >
        <b-button :disabled="!($refs.form && $refs.form.enableSaveChanges)" variant="primary" @click="updateCoursing()">
          {{
            $t(
              discountCoupon && (discountCoupon.id || discountCoupon.editIndex >= 0)
                ? 'discount.edit.list.edit.save'
                : 'discount.edit.list.create.save'
            )
          }}
        </b-button>
        <b-button variant="outline-primary" @click="onCancel(hide)">
          {{ $t('discount.edit.list.edit.cancel') }}
        </b-button>
      </div>
    </template>
    <b-container style="background-color: #f4f4f4;" fluid class="py-4 px-0" v-loading="pending">
      <discount-item-form :items="items" :discount-coupon.sync="discountCoupon" ref="form"></discount-item-form>
    </b-container>
  </b-sidebar>
</template>
<script>
import { patchItemService, postItemService } from '@/services/items.service';
import DiscountItemForm from './DiscountItemForm';
import { bus } from '@/bus';

export default {
  name: 'discount-item-sidebar',
  components: {
    DiscountItemForm
  },
  props: {
    discountCoupon: {
      type: Object,
      default: () => ({
        name: ''
      })
    },
    items: {
      type: Array,
      default: () => []
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSidebarVisible: false
    };
  },
  computed: {},
  methods: {
    onCancel(hide) {
      if (this.$refs.form.haveChanges) {
        this.$emit('onCancel', null);
      } else {
        hide();
      }
    },
    onHide() {
      this.discountCoupon = null;
      this.$emit('update:discountCoupon', null);
      this.$forceUpdate();
      return true;
    },
    updateCoursing() {
      const item = this.items.filter(x => x.itemId === this.discountCoupon.itemId)[0];
      this.$emit('onAdd', { ...this.discountCoupon, item });
    }
  },
  mounted() {
    bus.$on('document:click', evt => {
      if (evt.target.className.includes('b-sidebar-backdrop') && this.$refs.form.haveChanges) {
        this.$emit('onCancel', null);
      }
    });
  },
  destroyed() {
    bus.$off('document:click');
  }
};
</script>
<style scoped lang="scss">
/deep/ .b-sidebar-header {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

/deep/ .b-sidebar-body {
  background-color: #f4f4f4;
}
</style>
