<template>
  <div>
    <h5 class="px-4">{{ $t('counters.form.title') }}</h5>
    <div class="bg-white p-4 mt-2">
      <b-form-group
        :label="$t('counters.form.name.label')"
        :description="$t('counters.form.required')"
        label-for="name"
        class="mr-5 w-100 mb-2 mb-md-0"
      >
        <b-form-input
          id="name"
          v-model.trim="counter.name"
          maxlength="50"
          aria-describedby="input-live-help input-live-feedback"
          :class="{ required: !counter || !counter.name || counter.name === '' }"
        ></b-form-input>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'counter-form',
  model: {},
  props: {
    licenceNumber: {
      type: Number,
      default: null
    },
    counter: {
      type: Object,
      default: () => ({
        name: ''
      })
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      counterOrig: null
    };
  },
  computed: {
    enableSaveChanges() {
      return (
        this.counter &&
        this.counter.name &&
        this.counter.name !== '' &&
        JSON.stringify(this.counterOrig) !== JSON.stringify(this.counter)
      );
    },
    haveChanges() {
      return JSON.stringify(this.counterOrig) !== JSON.stringify(this.counter);
    }
  },
  methods: {
    reset(value = null) {
      this.form = cloneDeep(
        value || {
          name: ''
        }
      );
    }
  },
  mounted() {
    this.counterOrig = cloneDeep(this.counter);
  }
};
</script>
<style lang="scss" scoped>
/deep/ {
  label,
  .form-text {
    margin-bottom: 0;
    padding-left: 1rem;
  }
}

/deep/ .form-text {
  min-height: 18px;
}

.form-group {
  max-width: 328px;
}
</style>
