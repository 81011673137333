<template>
  <div class="vel-input-group">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'vel-input-group'
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

.vel-input-group {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: rem-calc(0);
  padding: 0 rem-calc(0);
}
</style>
