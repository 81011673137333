<template>
  <b-table
    thead-class="bg-info"
    striped
    small
    hover
    bordered
    show-empty
    fixed
    primary-key="id"
    :items="mealPlans"
    :fields="fields"
    responsive="sm"
    ref="table"
    :empty-text="$t('mealPlan.empty')"
    :empty-filtered-text="$t('mealPlan.empty')"
    class="mt-4"
    style="min-width: 500px;"
  >
    <template #empty="scope">
      <div class="p-2">{{ scope && scope.emptyText }}</div>
    </template>
    <template #emptyfiltered="scope">
      <div class="p-2">{{ scope && scope.emptyFilteredText }}</div>
    </template>
    <template #cell(edit)="scope">
      <b-button variant="link" @click="$emit('onModify', scope)" v-b-toggle.mealPlanForm-sidebar>
        <b-icon icon="pencil-fill" aria-label="Edit" class="row-action text-primary"></b-icon>
        {{ $t('mealPlan.edit') }}
      </b-button>
    </template>
    <template #cell(delete)="scope">
      <b-button variant="link text-danger" @click="$emit('onDelete', scope.item)">
        <b-icon icon="trash-fill" aria-label="Delete" class="row-action text-danger"></b-icon>
        {{ $t('mealPlan.delete') }}
      </b-button>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'meal-plan-list',
  model: {},
  props: {
    mealPlans: {
      type: Array,
      default: () => []
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      roleName: ''
    };
  },
  watch: {
    mealPlan(value) {
      this.roleName = (value && value.name) || '';
    },
    mealPlanName(value) {
      this.$emit('change', value);
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('mealPlan.list.table.headers.name'),
          class: 'name vertical-align-center'
        },
        {
          key: 'edit',
          label: '',
          class: 'edit'
        },
        {
          key: 'delete',
          label: '',
          class: 'edit'
        }
      ];
    },
    mealPlan() {
      return { name: this.mealPlanName, licenceNumber: this.licenceNumber };
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
/deep/ .edit {
  width: 115px;
}
</style>
