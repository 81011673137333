import { mapState, mapStores } from 'pinia';
import { useUIAppStore } from '@/stores/ui/app.module';
import { storeToRefs } from 'pinia/dist/pinia';

export default {
  computed: {
    ...mapStores(useUIAppStore)
  },
  data() {
    return {
      subscription: false
    };
  },
  setup() {
    const appStore = useUIAppStore();
    const { generatedTime } = storeToRefs(appStore);
    return { generatedTime };
  },
  beforeUpdate() {
    this.appStore.setGeneratedTime(new Date().toISOString());
  }
};
