<template>
  <div class="sales-items-avaibility-per-mode">
    <h5>{{ $t('salesItemsEdit.form.availabilityModeTitle') }}</h5>
    <el-table size="mini" :show-header="true" :data="schedulesValue" style="max-width: 660px; width: 100%;">
      <el-table-column class-name="modeName" label="Mode" prop="mode.name"></el-table-column>
      <el-table-column
        :width="isMediumDown ? '40px' : '60px'"
        class-name="weekDay"
        v-for="i in [1, 2, 3, 4, 5, 6, 7]"
        :key="i"
        :label="
          isMediumDown ? $t('salesItemsEdit.form.weekDays.' + i).substr(0, 1) : $t('salesItemsEdit.form.weekDays.' + i)
        "
      >
        <template slot-scope="scope">
          <input
            type="checkbox"
            @change="handleSchedule(scope.row.schedule, scope.$index, i)"
            :checked="hasWeekdayEnabled(schedulesValue[scope.$index].schedule, i)"
          />
        </template>
      </el-table-column>
    </el-table>

    <div class="select-all-actions">
      <a href="javascript://" @click="handleSelectAll(true)">{{ $t('salesItemsEdit.form.actions.selectAll') }}</a>
      <a href="javascript://" @click="handleSelectAll(false)">{{ $t('salesItemsEdit.form.actions.deselectAll') }}</a>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn } from 'element-ui';
import DeviceMixin from '@/mixins/device-mixin';
import { hasWeekdayEnabled } from '@/helpers/date.helpers';

export default {
  name: 'sales-items-avaibility-per-mode-form',
  components: {
    'el-table': Table,
    'el-table-column': TableColumn
  },
  mixins: [DeviceMixin],

  props: {
    schedulesValue: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    hasWeekdayEnabled,
    handleSchedule(schedule, index, weekDay) {
      this.$emit('updateSchedule', schedule, index, weekDay);
    },
    handleSelectAll(isSelectAll) {
      this.$emit('selectAll', isSelectAll);
    }
  }
};
</script>
