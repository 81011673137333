import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import salesService from '@/services/sales.service';

export const useDataSalesPaymodes = defineStore('salesPaymodes', {
  state: () => ({
    paymodeSales: null,
    pending: true,
    success: null,
    error: null
  }),
  getters: {
    isPending: state => state.pending,
    getPaymodeSales: state => state.paymodeSales,
    getPaymodeSalesByCurrency(state) {
      return currencyId =>
        (state.paymodeSales || []).filter(zs => zs.salesAmount > 0).sort((a, b) => b.salesAmount - a.salesAmount);
    },
    isError: state => !!state.error,
    getError: state => state.error,
    isSuccess: state => state.success,
    getSeries(state) {
      return currencyId => this.getPaymodeSalesByCurrency(currencyId).map(zs => zs.salesAmount);
    },
    getLabels(state) {
      return currencyId => this.getPaymodeSalesByCurrency(currencyId).map(zs => zs.nameMain);
    },
    getTotal(state) {
      return currencyId => this.getPaymodeSalesByCurrency(currencyId).length;
    }
  },
  actions: {
    async getSalesByPaymode(query) {
      if (!requestAllocated(query)) {
        this.getPaymodeSalesRequest();

        try {
          const data = await salesService.getPaymodeSales(getToken(), query);
          this.getPaymodeSalesSuccess(data);
          deallocateRequest(query);
        } catch (error) {
          this.getPaymodeSalesFailure(error);
        }
      }
    },
    getPaymodeSalesRequest() {
      this.pending = true;
      this.success = null;
      this.paymodeSales = null;
      this.error = null;
    },
    getPaymodeSalesSuccess(paymodeSales) {
      this.pending = false;
      this.success = true;
      this.paymodeSales = paymodeSales;
      this.error = null;
    },
    getPaymodeSalesFailure(error) {
      this.pending = false;
      this.success = false;
      this.paymodeSales = null;
      this.error = error;
    }
  }
});
