<template>
  <indicator
    color="blue"
    :amount="amount"
    :previous-amount="previousAmount"
    iconName="calculator"
    iconColor="pink"
    :loading="loading"
  >
    <template v-slot:title>
      {{ $t('dashboard.taxesVolume.title') }}
    </template>
    <template v-slot:tooltip>
      {{ $t('dashboard.taxesVolume.description') }}
    </template>
    <template v-slot:value>
      <vel-amount :amount="amount" :currency="currency" />
    </template>
  </indicator>
</template>

<script>
import Indicator from '@/components/indicator/Indicator.vue';
import VelAmount from '@/components/amount/VelAmount';

export default {
  name: 'taxes-volume',
  components: {
    Indicator,
    VelAmount
  },
  props: {
    amount: {
      default: 0
    },
    previousAmount: {
      default: null
    },
    currency: {
      default: null
    },
    loading: {
      default: true
    }
  }
};
</script>
