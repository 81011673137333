<script>
import { RadioButton } from 'element-ui';

export default {
  name: 'vel-radio-button',
  extends: RadioButton
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: $iris-blue;
  border-color: $iris-blue;
}
</style>
