import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import salesService from '@/services/sales.service';

export const useDataSalesTenderTypes = defineStore('salesTenderTypes', {
  state: () => ({
    tenderTypesSales: null,
    pending: true,
    success: null,
    error: null
  }),
  getters: {
    isPending: state => state.pending,
    getTenderTypesSales: state => state.tenderTypesSales,
    getTenderTypesSalesByCurrency(state) {
      return currencyId =>
        (state.tenderTypesSales || [])
          .filter(zs => zs.currency === currencyId)
          .sort((a, b) => b.salesAmount - a.salesAmount);
    },
    isError: state => !!state.error,
    getError: state => state.error,
    isSuccess: state => state.success,
    getSeries(state) {
      return currencyId => this.getTenderTypesSalesByCurrency(currencyId).map(zs => zs.salesAmount);
    },
    getLabels(state) {
      return currencyId => this.getTenderTypesSalesByCurrency(currencyId).map(zs => zs.nameMain);
    },
    getTotal(state) {
      return currencyId => this.getTenderTypesSalesByCurrency(currencyId).length;
    }
  },
  actions: {
    async getSalesByTenderTypes(payload) {
      const toDay = today().startOf('day').toString();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;

      const routeQuery = {
        ...router.currentRoute.query,
        from,
        to,
        ...payload
      };

      if (!requestAllocated(routeQuery)) {
        this.getTenderTypesSalesRequest();

        try {
          const tenderTypesSales = await salesService.getSalesTenderTypes(getToken(), routeQuery);
          this.getTenderTypesSalesSuccess({ tenderTypesSales });
          deallocateRequest(routeQuery);
        } catch (error) {
          this.getTenderTypesSalesFailure(error);
        }
      }
    },
    getTenderTypesSalesRequest() {
      this.pending = true;
      this.success = null;
      this.tenderTypesSales = null;
      this.error = null;
    },
    getTenderTypesSalesSuccess({ tenderTypesSales }) {
      this.pending = false;
      this.success = true;
      this.tenderTypesSales = tenderTypesSales;
      this.error = null;
    },
    getTenderTypesSalesFailure(error) {
      this.pending = false;
      this.success = false;
      this.tenderTypesSales = null;
      this.error = error;
    }
  }
});
