<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="600px"
    right
    id="accessTypes-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarAccessType"
  >
    <template #header="{ hide }">
      <div
        class="bg-gray-200 bg-light w-100 d-flex flex-row align-items-center justify-content-center position-relative"
      >
        <h5 class="font-weight-bold">{{ $t('jobRoles.list.title') }}</h5>
        <b-button class="position-absolute" style="left: 0;" size="sm" variant="link" @click="hide">
          <b-icon icon="chevron-compact-left" aria-hidden="true"></b-icon>
          {{ $t('accessType.back') }}
        </b-button>
      </div>
    </template>

    <template #footer="{ hide }">
      <div class="d-flex bg-light text-dark align-items-center px-3 py-2" style="border-top: 2px solid #d6d6d8;">
        <b-button variant="primary" @click="hide">{{ $t('accessType.close') }}</b-button>
      </div>
    </template>
    <b-container fluid class="p-4" v-loading="pending">
      <div class="d-flex justify-content-end align-content-end">
        <b-input-group style="max-width: 180px;" class="align-content-end align-self-end mr-4">
          <b-input-group-prepend>
            <b-input-group-text>
              <b-icon icon="search" />
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            debounce="1"
            type="text"
            :placeholder="$t('employee.list.searchPlaceholder')"
          ></b-form-input>
        </b-input-group>
        <b-button variant="primary" v-b-toggle.accessTypeForm-sidebar>
          <b-icon icon="plus"></b-icon>
          {{ $t('accessType.add.btn') }}
        </b-button>
        <!--
        <b-button variant="primary" v-b-toggle.accessTypeForm-sidebar>
          <b-icon icon="plus"></b-icon>
          {{ $t('accessType.add.btn') }}
        </b-button>-->
      </div>
      <access-type-list
        :access-types="accessTypes"
        :filter="filter"
        @onModify="openEditAccessType($event)"
        @onDelete="$emit('onDelete', $event)"
      ></access-type-list>
    </b-container>
  </b-sidebar>
</template>
<script>
import AccessTypeList from '@/private/components/access-type/components/AccessTypeList';

export default {
  name: 'employee-sidebar-access-type',
  components: {
    AccessTypeList
  },
  props: {
    accessType: {
      type: Object,
      default: () => ({
        name: ''
      })
    },
    accessTypes: {
      type: Array,
      default: () => []
    },
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  computed: {},
  data() {
    return {
      filter: null,
      filterOn: ['name']
    };
  },
  methods: {
    openEditAccessType(accessType) {
      this.$emit('update:accessType', accessType.item);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ {
  .input-group > .form-control {
    border-left-color: #fff;
    padding-left: 0;
  }
  .input-group-text {
    background-color: #fff !important;
    border-right: 1px solid #fff !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
</style>
