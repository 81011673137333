<template>
  <vel-table
    :rows="rows"
    :columns="columns"
    :loading="loading"
    :hoverable="true"
    defaultDirection="asc"
    defaultSort="location"
  >
    <template v-slot:empty>
      {{ $t('users.errors.noData') }}
    </template>

    <template v-slot:location-column="scope">
      <div class="location__name" v-if="scope.cell.value">
        <vel-avatar class="location__name-avatar" :fullname="scope.cell.value.name" />
        <span class="location__name-text">{{ scope.cell.value.name }}</span>
      </div>
      <div v-else>
        {{ $t('user.permissions.table.values.allLocations') }}
      </div>
    </template>

    <template v-slot:group-column="scope">
      <div class="group__name" v-if="scope.cell.value">
        <vel-avatar class="group__name-avatar" :fullname="scope.cell.value.name" />
        <span class="group__name-text">{{ scope.cell.value.name }}</span>
      </div>
      <div v-else>
        {{ $t('user.permissions.table.values.allGroups') }}
      </div>
    </template>

    <template v-slot:actions-column="scope">
      <div class="actions">
        <vel-button
          type="link"
          :disabled="disableActions"
          icon="edit"
          @click="handleEdit(permissions[scope.rowIndex])"
        />
        <vel-button
          type="link"
          :disabled="disableActions"
          icon="delete"
          @click="handleDelete(permissions[scope.rowIndex])"
        />
      </div>
    </template>
  </vel-table>
</template>

<script>
import VelAvatar from '@/components/avatar/VelAvatar';
import VelButton from '@/components/button/VelButton';
import VelTable from '@/components/table/VelTable';
import compareStringsInsensitive from '@/comparators/compare-strings-insensitive';
import { oc } from 'ts-optchain';

export default {
  name: 'permissions-matrix',
  components: {
    VelAvatar,
    VelButton,
    VelTable
  },
  props: {
    disableActions: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    permissionTitle: {
      type: String
    },
    permissions: {
      type: Array
    },
    showActions: {
      type: Boolean,
      default: true
    },
    showLocation: {
      type: Boolean,
      default: false
    },
    showGroup: {
      type: Boolean,
      default: false
    },
    showUser: {
      type: Boolean,
      default: false
    },
    translationPrefix: {
      type: String,
      default: 'user'
    },
    multiplePermissions: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rows() {
      return this.permissions;
    },
    columns() {
      return {
        location: {
          key: 'location',
          title: `${this.$t(`user.permissions.table.columns.location`)}  ${
            this.multiplePermissions ? `(${this.permissions.length})` : ''
          }`,
          sort: (p1, p2) => compareStringsInsensitive(oc(p1).location.name(), oc(p2).location.name()),
          sortable: true,
          visible: this.showLocation,
          width: '60%'
        },
        group: {
          key: 'group',
          title: `${this.$t(`user.permissions.table.columns.group`)} ${
            this.multiplePermissions ? `(${this.permissions.length})` : ''
          }`,
          sort: (p1, p2) => compareStringsInsensitive(oc(p1).group.name(), oc(p2).group.name()),
          sortable: true,
          visible: this.showGroup,
          width: '60%'
        },
        user: {
          key: 'user',
          title: this.$t(`user.permissions.table.columns.user`),
          sort: (p1, p2) => compareStringsInsensitive(oc(p1).user.fullName(), oc(p2).user.fullName()),
          sortable: true,
          visible: this.showUser,
          width: '60%'
        },
        permission: {
          key: 'permission',
          title: this.permissionTitle || this.$t(`${this.translationPrefix}.permissions.table.columns.permission`),
          sortable: true,
          width: this.showLocation || this.showGroup || this.showUser ? '30%' : '90%'
        },
        actions: {
          key: 'actions',
          title: 'Actions',
          sortable: false,
          visible: this.showActions,
          width: '10%'
        }
      };
    }
  },
  methods: {
    handleEdit(permission) {
      this.$emit('edit', permission);
    },
    handleDelete(permission) {
      this.$emit('delete', permission);
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

// name
$locations__name-font-weight: $font-medium;
$groups__name-font-weight: $font-medium;

.location,
.group {
  @include breakpoint(small) {
    &__name-avatar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    &__name {
      align-items: center;
      display: inline-flex;
    }

    &__name-text {
      font-weight: $locations__name-font-weight;
      @include text-ellipsis;
    }

    &__name-avatar {
      display: inline;
      margin-right: rem-calc(8);
    }
  }
}

.actions {
  .vel-button {
    .vel-button__icon {
      padding: 0.25rem;
    }
  }
}
</style>
