<template>
  <pie-treemap-chart
    v-if="!loading"
    :pending="getLocationsIsLoading"
    :options="chartOptions"
    :series="series"
    :limit="1000"
    :line="true"
    :multiple="true"
    :title="$t('analytics.locationsPerVersion.title')"
    class="locations-per-month__chart"
  />
</template>

<script>
import PieTreemapChart from '@/components/pie-treemap-chart/PieTreemapChart';
import { formatVersion } from '@/helpers/versions.module.helper';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataLocationsStore } from '@/stores/data/locations.module';

export default {
  name: 'locations-per-version',
  components: {
    PieTreemapChart
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapPiniaState(useDataLocationsStore, ['getLocationsIsLoading', 'getLocationsAll']),
    chartOptions() {
      return {
        legend: {
          show: false
        },
        chart: {
          height: 350,
          type: 'treemap'
        },
        colors: [
          '#3B93A5',
          '#F7B844',
          '#ADD8C7',
          '#EC3C65',
          '#CDD7B6',
          '#C1F666',
          '#D43F97',
          '#1E5D8C',
          '#421243',
          '#7F94B0',
          '#EF6537',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB',
          '#C0ADDB'
        ],
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false
          }
        }
      };
    },
    series() {
      const currentDaySales = {
        data: Object.keys(this.values)
          .map(k => ({ x: k, y: this.values[k] }))
          .sort((a, b) => b.y - a.y)
      };

      return [currentDaySales];
    },
    values() {
      return (this.getLocationsAll || [])
        .map(l => ({ ...l, version: l.version.substr(0, l.version.lastIndexOf('.')) }))
        .sort((a, b) => formatVersion(a.version) - formatVersion(b.version))
        .reduce((a, b) => {
          const label = b.version === '0.0' ? '<9.53' : b.version;
          a[label] = (a[label] || 0) + 1;
          return a;
        }, {});
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .apexcharts-legend-text,
/deep/ .apexcharts-tooltip-text-label {
  display: inline-block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}
</style>
