<script>
import { oc } from 'ts-optchain';

const tagName = 'vel-icon';

export function loadIcon(name) {
  // @note we can't use `await import()` here because we can't async render.
  // @todo: babel-jest bug does not allow us to use backquotes here :shrug:
  const icon = require(`@/assets/svg/icons/${name}.svg`);
  return oc(icon).default(icon);
}

export default {
  name: tagName,
  functional: true,
  props: {
    name: {
      type: String,
      required: true
    },
    modifier: {
      type: String,
      default: 'on-bright',
      validate(value) {
        return ['on-dark', 'on-bright'].includes(value);
      }
    },
    size: {
      type: String,
      default: 'small',
      validate(value) {
        return ['xsmall', 'small', 'medium', 'large'].includes(value);
      }
    }
  },
  render(h, vm) {
    const { name, modifier, size } = vm.props;
    if (!loadIcon(name)) {
      return '';
    }
    const vnode = loadIcon(name).render(h, vm);

    oc(vnode).data.class([]).unshift(tagName, `${tagName}_${modifier}`, `${tagName}_${size}`);
    return vnode;
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$icon-color_on-bright: $storm-gray;
$icon-color_on-dark: white;
$icon-size_xsmall: rem-calc(12);
$icon-size_xsmall_medium-up: rem-calc(10);
$icon-size_small: rem-calc(18);
$icon-size_small_medium-up: rem-calc(16);
$icon-size_medium: rem-calc(30);
$icon-size_medium_medium-up: rem-calc(24);
$icon-size_large: 2.5rem;

.vel-icon {
  &_on-dark {
    @include icon-path-fill($icon-color_on-dark);
  }

  &_on-bright {
    @include icon-path-fill($icon-color_on-bright);
  }

  &_xsmall {
    @include icon-size($icon-size_xsmall);
  }

  &_small {
    @include icon-size($icon-size_small);
  }

  &_medium {
    @include icon-size($icon-size_medium);
  }

  &_large {
    @include icon-size($icon-size_large);
  }

  @include breakpoint(medium) {
    &_xsmall {
      @include icon-size($icon-size_xsmall_medium-up);
    }

    &_small {
      @include icon-size($icon-size_small_medium-up);
    }

    &_medium {
      @include icon-size($icon-size_medium_medium-up);
    }
  }
}
</style>
