<template>
  <vel-page
    :multiple-locations="locations && locations.length > 1"
    :enable-currency="true"
    :show-selector-button="true"
    class="report"
    v-bind="pageAttrs"
  >
    <template v-slot:export>
      <vel-export
        :disabled="loading"
        :generating="generating"
        :enable-pdf="true"
        :enable-xls="true"
        @exportPDF="onExportPDF"
        @exportXLS="onExportXLS"
      ></vel-export>
    </template>
    <vel-spinner class="spinner" v-if="loading" />
    <div
      v-if="invalidLocations && !isVersionLoading"
      style="background-color: #fff2cc; margin-bottom: 1rem; padding: 1rem;"
    >
      <template v-if="getLocationSelectorSelected">
        {{ $t('invalidVersions.requiredVersion') }}
      </template>
      <template v-else>
        {{ $t('invalidVersions.requiredVersionMultiLocations') }}
        <a href="javascript://" @click="showLocationsList = !showLocationsList">{{ $t('invalidVersions.collapse') }}</a>
        <ul v-if="showLocationsList" style="margin-top: 1rem;">
          <li v-for="location in invalidLocations" :key="location.id">
            {{ location.reportingDisplayName || location.name }}
          </li>
        </ul>
      </template>
    </div>

    <div class="no-data" v-if="!Object.values(filteredData).length && !loading">
      {{ $t('itemSalesCategory.noData') }}
    </div>
    <template v-if="!loading">
      <vel-card v-for="sales in filteredData" :key="sales.locationId">
        <h5>{{ sales.location.name }}</h5>
        <section class="data_section" v-if="sales.salesByBigDivision">
          <h6>{{ $t('salesAndRevenue.grossSales') }}</h6>
          <div class="content">
            <table>
              <thead>
                <tr>
                  <th>{{ $t('salesAndRevenue.category') }}</th>
                  <th>{{ $t('salesAndRevenue.subCategory') }}</th>
                  <th></th>
                  <th class="align-right">{{ $t('salesAndRevenue.amount') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(salesByBigDivision, salesByBigDivisionIdx) in sales.salesByBigDivision">
                  <tr
                    v-for="(salesByDivision, idx) in salesByBigDivision.divisions"
                    :key="`${salesByBigDivisionIdx}_${idx}`"
                  >
                    <td>
                      <div v-if="idx === 0">
                        {{ (salesByBigDivision.bigDivision && salesByBigDivision.bigDivision.name) || '--' }}
                      </div>
                    </td>
                    <td>
                      {{ (salesByDivision.division && salesByDivision.division.name) || '--' }}
                    </td>
                    <td></td>
                    <td class="align-right">
                      <vel-amount
                        :currency="
                          (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                        "
                        :amount="salesByDivision.amount"
                      />
                    </td>
                  </tr>
                  <tr class="summary">
                    <td></td>
                    <td></td>
                    <td class="align-right">
                      <div class="total">
                        <div>{{ $t('salesAndRevenue.averagePerCustomer') }}</div>
                        <div>
                          <vel-amount
                            :currency="
                              (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                            "
                            :amount="calcAverage(sales.salesIndicator.customers, salesByBigDivision.salesAmount)"
                          />
                        </div>
                      </div>
                    </td>
                    <td class="align-right">
                      <div class="total">
                        <div>{{ $t('salesAndRevenue.categoryTotal') }}</div>
                        <div>
                          <vel-amount
                            :currency="
                              (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                            "
                            :amount="salesByBigDivision.salesAmount"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="sales.salesIndicator.surchargeAmount || sales.salesIndicator.gratuityAmount">
                  <tr v-if="sales.salesIndicator.gratuityAmount">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="align-right">
                      <div class="subtotal">
                        <div>{{ $t('salesAndRevenue.serviceCharge') }}</div>
                        <div>
                          <vel-amount
                            :currency="
                              (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                            "
                            :amount="sales.salesIndicator.gratuityAmount"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="sales.salesIndicator.surchargeAmount">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="align-right">
                      <div class="subtotal">
                        <div>{{ $t('salesAndRevenue.surcharge') }}</div>
                        <div>
                          <vel-amount
                            :currency="
                              (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                            "
                            :amount="sales.salesIndicator.surchargeAmount"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="summary">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="align-right">
                      <div class="total">
                        <div>{{ $t('salesAndRevenue.total') }}</div>
                        <div>
                          <vel-amount
                            :currency="
                              (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                            "
                            :amount="sales.totalAdditionalFees"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <tr class="align-right">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="bigtotal">
                    <div>{{ $t('salesAndRevenue.totalGrossSales') }}</div>
                    <div>
                      <vel-amount
                        :currency="
                          (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                        "
                        :amount="sales.salesIndicator.grossSalesAmount"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <section class="data_section" v-if="sales.salesByDiscount">
          <h6>{{ $t('salesAndRevenue.netSales') }}</h6>
          <div class="content">
            <table>
              <thead>
                <tr>
                  <th>{{ $t('salesAndRevenue.discounts') }}</th>
                  <th></th>
                  <th class="align-right">{{ $t('salesAndRevenue.quantity') }}</th>
                  <th class="align-right">{{ $t('salesAndRevenue.amount') }}</th>
                </tr>
              </thead>
              <tbody>
                <template>
                  <tr v-for="(salesByDiscount, idx) in sales.salesByDiscount" :key="idx">
                    <td>{{ salesByDiscount.name || '--' }}</td>
                    <td></td>
                    <td class="align-right">{{ salesByDiscount.quantity }}</td>
                    <td class="align-right">
                      <vel-amount
                        :currency="
                          (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                        "
                        :amount="salesByDiscount.discountsAmount"
                      />
                    </td>
                  </tr>
                  <tr class="summary">
                    <td></td>
                    <td></td>
                    <td class="align-right">{{ sales.totalDiscountQuantity }}</td>
                    <td class="align-right">
                      <div class="subtotal">
                        <div>{{ $t('salesAndRevenue.discountTotal') }}</div>
                        <div>
                          <vel-amount
                            :currency="
                              (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                            "
                            :amount="sales.totalDiscountsAmount"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="align-right">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="bigtotal">
                      <div>{{ $t('salesAndRevenue.totalNetSales') }}</div>
                      <div>
                        <vel-amount
                          :currency="
                            (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                          "
                          :amount="sales.salesIndicator.netSalesAmount"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </section>
        <section class="data_section" v-if="sales.salesByTax.taxes">
          <h6>{{ $t('salesAndRevenue.subTotal') }}</h6>
          <div class="content">
            <table>
              <thead>
                <tr>
                  <th>{{ $t('salesAndRevenue.taxes') }}</th>
                  <th></th>
                  <th class="align-right">{{ $t('salesAndRevenue.taxableSales') }}</th>
                  <th class="align-right">{{ $t('salesAndRevenue.amount') }}</th>
                </tr>
              </thead>
              <tbody>
                <template>
                  <tr v-for="(salesByTax, idx) in sales.salesByTax.taxes" :key="idx">
                    <td>{{ salesByTax.name || '--' }}</td>
                    <td></td>
                    <td class="align-right">
                      <vel-amount
                        :currency="
                          (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                        "
                        :amount="salesByTax.taxableAmount"
                      />
                    </td>
                    <td class="align-right">
                      <vel-amount
                        :currency="
                          (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                        "
                        :amount="salesByTax.taxesAmount"
                      />
                    </td>
                  </tr>
                  <tr class="summary">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="align-right">
                      <div class="subtotal">
                        <div>{{ $t('salesAndRevenue.taxesTotal') }}</div>
                        <div>
                          <vel-amount
                            :currency="
                              (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                            "
                            :amount="sales.salesIndicator.taxesAmount"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="align-right">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="bigtotal">
                      <div>{{ $t('salesAndRevenue.subTotalExplained') }}</div>
                      <div>
                        <vel-amount
                          :currency="
                            (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                          "
                          :amount="sales.subTotal"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </section>
        <section class="data_section" v-if="sales.salesByTenderType">
          <h6>{{ $t('salesAndRevenue.totalRevenue') }}</h6>
          <div class="content">
            <table>
              <thead>
                <tr>
                  <th>{{ $t('salesAndRevenue.tenderTypes') }}</th>
                  <th class="align-right">{{ $t('salesAndRevenue.quantity') }}</th>
                  <th class="align-right">{{ $t('salesAndRevenue.tips') }}</th>
                  <th class="align-right">{{ $t('salesAndRevenue.amount') }}</th>
                </tr>
              </thead>
              <tbody>
                <template>
                  <tr v-for="(salesByTenderType, idx) in sales.salesByTenderType" :key="idx">
                    <td>{{ salesByTenderType.tenderType.name || '--' }}</td>
                    <td class="align-right">{{ salesByTenderType.count }}</td>
                    <td class="align-right">
                      <vel-amount
                        :currency="
                          (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                        "
                        :amount="salesByTenderType.transferredTip"
                      />
                    </td>
                    <td class="align-right">
                      <vel-amount
                        :currency="
                          (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                        "
                        :amount="salesByTenderType.amount"
                      />
                    </td>
                  </tr>
                  <tr class="summary">
                    <td></td>
                    <td class="align-right">{{ sales.totalTenderQuantity }}</td>
                    <td class="align-right">
                      <vel-amount
                        :currency="
                          (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                        "
                        :amount="sales.salesIndicator.tipsTotalAmount"
                      />
                    </td>
                    <td class="align-right">
                      <div class="subtotal">
                        <div>{{ $t('salesAndRevenue.tenderTotal') }}</div>
                        <div>
                          <vel-amount
                            :currency="
                              (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                            "
                            :amount="sales.totalTenderAmount"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="align-right">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="bigtotal">
                      <div>{{ $t('salesAndRevenue.totalRevenue') }}</div>
                      <div>
                        <vel-amount
                          :currency="
                            (sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''
                          "
                          :amount="sales.totalRevenue"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </section>
        <div style="display: flex; justify-content: space-between;">
          <div class="bigtotal align-right">
            <div>{{ $t('salesAndRevenue.totalCustomerCount') }}</div>
            <div>{{ sales.salesIndicator.customers }}</div>
          </div>
          <div class="bigtotal align-right">
            <div>{{ $t('salesAndRevenue.outOfBalanceExplained') }}</div>
            <div style="color: #ef4244;">
              <vel-amount
                :currency="(sales.location.detailedCurrency && sales.location.detailedCurrency.currencySymbol) || ''"
                :amount="sales.outOfBalance"
              />
            </div>
          </div>
        </div>
      </vel-card>
    </template>
  </vel-page>
</template>

<script>
import { mapActions as mapPiniaActions, mapState as mapPiniaState, storeToRefs } from 'pinia/dist/pinia';

import { DateTime } from 'luxon';
import DeviceMixin from '@/mixins/device-mixin';
import LanguageMixin from '@/mixins/language-mixin';
import VelAmount from '@/components/amount/VelAmount';
import VelCard from '@/components/card/VelCard';
import VelExport from '@/components/export/VelExport';
import VelPage from '@/components/page/VelPage';
import VelSpinner from '@/components/spinner/VelSpinner';
import { environment } from '@/configs';
import { formatVersion } from '@/helpers/versions.module.helper';
import router from '@/router';
import salesService from '@/services/sales.service';
import { today } from '@/helpers/date.helpers';

import { useDataConfig } from '@/stores/data/config.module';
import { useDataGroups } from '@/stores/data/groups.module';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import { useDataLocationsVersionsStore } from '@/stores/data/locations/versions.module';
import { useDataSalesLocations } from '@/stores/data/sales/locations.module';
import { useUICurrencySelector } from '@/stores/ui/currency-selector.module';
import { useUIDateRangeSelector } from '@/stores/ui/date-range-selector.module';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';

export default {
  name: 'SalesRevenueRepo',
  components: {
    VelPage,
    VelAmount,
    VelCard,
    VelSpinner,
    VelExport
  },
  mixins: [DeviceMixin, LanguageMixin],
  data() {
    return {
      loading: true,
      generating: false,
      data: []
    };
  },
  setup() {
    const locationsSalesStore = useDataSalesLocations();

    const { getLocationsSales } = storeToRefs(locationsSalesStore);

    return { getSales: getLocationsSales, locationsSalesStore };
  },
  computed: {
    ...mapPiniaState(useUILocationSelector, {
      locationCount: 'getLocationSelectorSelectedMultipleLength',
      locationListSelected: 'getLocationSelectorSelectedMultipleEntities',
      locationIds: 'getLocationSelectorSelectedMultipleIds'
    }),
    ...mapPiniaState(useUIGroupSelector, ['getGroupSelectorSelected']),
    ...mapPiniaState(useUILocationSelector, [
      'getLocationSelectorSelected',
      'getLocationById',
      'getLocationSelectorSelectedMultiple'
    ]),
    ...mapPiniaState(useUICurrencySelector, ['getCurrencySelectorSelected']),
    ...mapPiniaState(useUIDateRangeSelector, ['getDateSelectorSelectedRange']),
    ...mapPiniaState(useDataGroups, ['getGroupsRows']),
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useDataLocationsVersionsStore, {
      isVersionValid: 'isVersionValid',
      isVersionLoading: 'loading'
    }),
    ...mapPiniaState(useDataLocationsStore, [
      'locationsBySelectedGroup',
      'locations',
      'getLocationsById',
      'getLocationsIsLoading'
    ]),
    isMultiLocations() {
      return !this.getLocationSelectorSelected;
    },
    chartAttrs() {
      return {
        locationId: this.getLocationSelectorSelected,
        from: this.getDateSelectorSelectedRange[0],
        to: this.getDateSelectorSelectedRange[1],
        limit: this.get.charts_size_limitation.value
      };
    },
    config() {
      return environment;
    },
    pageAttrs() {
      return {
        enableToolbar: true,
        enableDateRange: true,
        enableCurrency: true,
        title: this.$t('pages.reports.salesRevenue'),
        showCrumbs: false,
        showDateCompare: this.showLocationsSales
      };
    },
    isAllInvalid() {
      const location = this.getLocationsById(this.getLocationSelectorSelected);
      if (this.getLocationSelectorSelected && formatVersion((location && location.version) || 0) < 9620) {
        return true;
      }
      const locations = (this.getLocationSelectorSelectedMultiple || [])
        .map(k => {
          const l = this.getLocationsById(k.value) || {};
          return { ...l, version: formatVersion(l.version) };
        })
        .filter(l => l.version < 9620);
      return locations.length === this.getLocationSelectorSelectedMultiple.length;
    },
    invalidLocations() {
      const location = this.getLocationsById(this.getLocationSelectorSelected);
      if (this.getLocationSelectorSelected && formatVersion((location && location.version) || 0) >= 9620) {
        return false;
      }
      const locations = (this.getLocationSelectorSelectedMultiple || [])
        .map(k => {
          const l = this.getLocationsById(k.value) || {};
          return { ...l, version: formatVersion(l.version) };
        })
        .filter(l => l.version < 9620);
      if (!locations.length) {
        return null;
      }
      return locations;
    },
    showNotification() {
      const isUpdated = this.getLocationSelectorSelected
        ? formatVersion((this.getLocationsById(this.getLocationSelectorSelected) || {}).version) >= 9620
        : !this.invalidLocations;

      const hasInvalidData = this.getLocationSelectorSelected
        ? !this.versionIsValid(this.getLocationSelectorSelected)
        : !!(this.getLocationSelectorSelectedMultiple || []).filter(x => !this.versionIsValid(x.value)).length;

      if (!this.getLocationSelectorSelected && !isUpdated) {
        const locations = (this.getLocationSelectorSelectedMultiple || [])
          .map(k => {
            const l = this.getLocationsById(k.value) || {};
            return { ...l, version: formatVersion(l.version) };
          })
          .filter(l => l.version >= 9620)
          .filter(l => !this.versionIsValid(l.id));

        if (locations.length) {
          return true;
        }
      }
      return isUpdated && hasInvalidData;
    },
    filteredData() {
      return this.data.filter(
        s => s.salesByBigDivision || s.salesByTax.taxes || s.salesByTenderType || s.salesByDiscount
      );
    }
  },
  methods: {
    ...mapPiniaActions(useUILocationSelector, ['gen']),
    ...mapPiniaActions(useDataLocationsVersionsStore, ['getVersions']),
    versionIsValid(locationId = null) {
      // @todo refactor so that we no longer have to pass getters as arguments
      return (
        !this.isVersionLoading &&
        this.isVersionValid(
          '9.62.0',
          locationId ? locationId : this.getLocationSelectorSelected,
          locationId ? null : this.getGroupSelectorSelected,
          this.getDateSelectorSelectedRange[0]
        )
      );
    },
    getExportParams() {
      const toDay = today().startOf('day').toISO();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;
      return {
        ...router.currentRoute.query,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO(),
        lang: this.getLanguage(),
        currency: this.getCurrencySelectorSelected
      };
    },
    async onExportPDF() {
      this.generating = true;
      this.$ga.event('report', 'download', this.$route.name);

      const from = this.getDateSelectorSelectedRange[0];
      const to = this.getDateSelectorSelectedRange[1];

      const routeQuery = {
        ...router.currentRoute.query,
        locationIds: this.locationIds,
        groupId: this.getGroupSelectorSelected,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO(),
        lang: this.getLanguage(),
        currency: this.getCurrencySelectorSelected
      };


      await salesService.downloadSalesAndRevenuePdf(routeQuery).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    },
    async onExportXLS() {
      this.generating = true;
      this.$ga.event('report', 'download', this.$route.name);


      const from = this.getDateSelectorSelectedRange[0];
      const to = this.getDateSelectorSelectedRange[1];

      const routeQuery = {
        ...router.currentRoute.query,
        locationIds: this.locationIds,
        groupId: this.getGroupSelectorSelected,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO(),
        lang: this.getLanguage(),
        currency: this.getCurrencySelectorSelected
      };

      await salesService.downloadSalesAndRevenueXlsx(routeQuery).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    },
    async fetchData() {
      this.data = [];
      this.loading = true;
      const toDay = today().startOf('day').toISO();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;
      const routeQuery = {
        ...router.currentRoute.query,
        from: DateTime.fromISO(from).toISO(),
        to: DateTime.fromISO(to).toISO(),
        currency: this.getCurrencySelectorSelected
      };

      await salesService
        .getSalesAndRevenue(routeQuery)
        .then(res => {
          this.data = res;
        })
        .catch(e => {
          console.error(e);
          this.loading = false;
        });
      this.loading = false;
    },
    calcAverage(a, b) {
      return a / b;
    }
  },
  beforeDestroy() {
    this.gen(false);
  },
  async mounted() {
    await this.getVersions();
    await this.fetchData();
  }
};
</script>
<style lang="scss" scoped>
@import 'mixins.scss';
@import 'constants.scss';

/deep/ .vel-card__body {
  overflow: auto;
}

.no-data {
  font-size: 2rem;
  color: #4f4f4f;
  text-align: center;
  margin: 2rem;
}

.vel-card {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;

  &__body {
    h5 {
      color: #00a9e1;
      font: normal normal bold 12px/14px Roboto;
    }

    .data_section {
      & > h6 {
        font: normal normal normal 12px/14px Roboto;
        color: #808080;
        margin-top: 1em;
        margin-bottom: 0.5em;
      }

      & > .content {
        border-radius: 12px;
        border: 1px solid #00000014;
        padding: 0.5em 1em;

        & table {
          table-layout: fixed;
          width: 100%;
          border-collapse: collapse;
        }

        td:first-child,
        th:first-child {
          padding-left: 9px;
        }

        td:last-child,
        th:last-child {
          padding-right: 9px;
        }

        & th {
          font: normal normal normal 12px/14px Roboto;
          color: #808080;
          text-align: left;
          padding-top: 8px;
          padding-bottom: 5px;
        }

        & tr {
          &:not(:first-child):not(.summary) {
            border-top: 0.5px solid #cccccc;
          }

          &.summary + tr {
            border-top: none !important;
          }

          &.summary {
            & td {
              background: #e6e6e6;
            }

            & td:first-child {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }

            & td:last-child {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }

          & td {
            font: normal normal normal 14px/16px Roboto;
            color: #1a1a1a;
            padding: 5px 0px;
          }
        }
      }
    }
  }
}

.align-right {
  text-align: right !important;
}

.bold {
  font-weight: bold;
}

.total,
.subtotal,
.bigtotal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.total div:nth-child(1),
.subtotal div:nth-child(1),
.bigtotal div:nth-child(1) {
  color: #808080;
  font: normal normal normal 12px/14px Roboto;
  margin-right: 1em;
  white-space: nowrap;
}

.total div:nth-child(2),
.subtotal div:nth-child(2) {
  min-width: 60px;
  font-weight: bold;
  font: normal normal normal 14px/16px Roboto;
  white-space: nowrap;
}

.subtotal div:nth-child(2) {
  font-weight: normal;
  white-space: nowrap;
}

.bigtotal div:nth-child(2),
.bigtotal div:nth-child(2) span {
  font: normal normal bold 20px/24px Roboto;
  white-space: nowrap;
}

.bigtotal {
  padding-right: 9px;
}

.vel-button {
  @include noPrint;
  background-color: #c40b0a;
  border-color: #c40b0a;
  direction: rtl;

  &:hover {
    background-color: mix(black, #c40b0a, 5%);
  }

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }

  &.vel-button_loading {
    border-color: unset;
  }

  /deep/ {
    .vel-button__text {
      padding: 0 0.625rem;
    }

    .vel-button__icon {
      padding-left: 0;
    }
  }
}
</style>
