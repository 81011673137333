import { defineStore } from 'pinia';
import router from '@/router';
import { useUIAlertStore } from '@/stores/ui/alert.module';
import { parameterizeDate, today } from '@/helpers/date.helpers';
import { DateTime } from 'luxon';

export const useUIDateCompare = defineStore('dateCompare', {
  state: () => ({
    selected: 'CAD'
  }),
  getters: {
    getDateCompareSelectorSelected: state => state.selected || 'week'
    // state.selected || (rootGetters['data/config/get'] && rootGetters['data/config/get'].date_compare_value) || 'week'
  },
  actions: {
    async onChange(query: any = {}) {
      this.selected = query.dateCompare;

      if (!(query && query.disableRouteChange)) {
        try {
          await router.replace({
            name: router.currentRoute.name,
            query: {
              ...router.currentRoute.query,
              dateCompare: query.dateCompare
            }
          });
        } catch (error) {
          const alertStore = useUIAlertStore();
          alertStore.newError(error);
        }
      }
    },
    routeChanged(ctx) {
      this.updateDateComparator(ctx.rootState.router.query.dateCompare);
    },
    updateDateComparator(date) {
      this.selected = date;
    }
  }
});
