import { defineStore } from 'pinia';
import { useUIAlertStore } from '@/stores/ui/alert.module';
import locationsService from '@/services/locations.service';
import { getToken } from '@/helpers/token.helper';
import { getSelectors, removeAll, setAll } from '@/helpers/stores.helpers';
import { filterLocationsVersionByLocationsIds, isValidVersion } from '@/helpers/versions.module.helper';
import { useDataLocationsStore } from '@/stores/data/locations.module';
import cloneDeep from 'lodash.clonedeep';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import salesService from '@/services/sales.service';
import transactionsService from '@/services/transactions.service';

const { selectAll } = getSelectors();

export const getIndicatorsByCurrency = (indicators, currency) => indicators[0];

export const useDataSalesIndicators = defineStore('salesIndicators', {
  state: () => ({
    data: {},
    loading: false,
    pending: true,
    error: null
  }),
  getters: {
    hasIndicators: (state: any) => state.data !== null,
    hasIndicatorsError: (state: any) => !!state.error,
    isIndicatorsLoading: (state: any) => state.loading || false,
    getIndicatorsData: (state: any) => state.data,
    getIndicatorsError: (state: any) => state.error,
    getIndicatorsCurrency: (state: any) => state?.data?.currency || '',
    getIndicatorsSalesVolume(state: any) {
      return (state.data && state.data.grossSalesAmount) || 0;
    },
    getIndicatorsSalesPerOrder: (state: any) => (state.data?.grossSalesAmount || 0) / (state.data?.orders || 0) || 0,
    getIndicatorsSalesPerCustomer: (state: any) =>
      (state.data?.grossSalesAmount || 0) / (state.data?.customers || 0) || 0,
    getIndicatorsNetSalesVolume: (state: any) => state.data?.netSalesAmount || 0,
    getIndicatorsCustomers: (state: any) => state.data?.customers || 0,
    getIndicatorsTaxesVolume: (state: any) => state.data?.taxesAmount || 0,
    getIndicatorsRevenuePerCustomer: (state: any) =>
      (state.data?.grossSalesAmount || 0) / (state.data?.customers || 0) || 0,
    getIndicatorsRevenuePerOrder: (state: any) => (state.data?.netSalesAmount || 0) / (state.data?.orders || 0) || 0,
    getIndicatorsIncomePerCustomer: (state: any) =>
      (state.data?.netSalesAmount || 0) / (state.data?.customers || 0) || 0,
    getIndicatorsDiscountsVolume: (state: any) => state.data?.discountsAmount || 0,
    getIndicatorsCashDiscounts: (state: any) => state.data?.cashDiscountAmount || 0,
    getIndicatorsSurchargeAmount: (state: any) => state.data?.surchargeAmount || 0,
    getIndicatorsDiscountsPercent(state: any) {
      return (this.getIndicatorsDiscountsVolume / this.getIndicatorsSalesVolume) * 100 || 0;
    },
    getIndicatorsNetSalesVolumeTaxes(state: any) {
      return this.getIndicatorsTaxesVolume + this.getIndicatorsNetSalesVolume;
    },
    getIndicatorsMeals: (state: any) => Number(Math.round(state.data?.meals || 0)),
    getIndicatorsNoSales: (state: any) => Number(Math.round(state.data?.drawerOpenings || 0)),
    getIndicatorsPaidOuts: (state: any) => state.data?.paidOutsAmount || 0,
    getIndicatorsCashDeposit: (state: any) => state.data?.cashDepositAmount || 0,
    getIndicatorsPaidInsAmount: (state: any) => state.data?.paidInsAmount || 0,
    getIndicatorsNetCashAmount: (state: any) => state.data?.netCashAmount || 0,
    getIndicatorsAverageSales: (state: any) =>
      Number(((state.data?.grossSalesAmount || 0) - (state.data?.discountsAmount || 0)) / (state.data?.orders || 1)),
    getIndicatorsOrders: (state: any) => Number(Math.round(state.data?.orders || 0)),
    getIndicatorTipsTotalAmount: (state: any) => state.data?.tipsTotalAmount || 0,
    getIndicatorsTotalWithTipsAmount(state: any) {
      return this.getIndicatorTipsTotalAmount + this.getIndicatorsNetSalesVolumeTaxes;
    },
    getIndicatorsItemCorrections: (state: any) => state.data?.itemCorrections || 0,
    getIndicatorsItemCorrectionsAmount: (state: any) => state.data?.itemCorrectionsAmount || 0,
    getIndicatorsVoids: (state: any) => state.data?.voids || 0,
    getIndicatorsVoidsAmount: (state: any) => state.data?.voidsAmount || 0,
    getIndicatorsRefundsAmount: (state: any) => state.data?.refundsAmount || 0,
    getIndicatorsRefunds: (state: any) => state.data?.refunds || 0,
    getIndicatorsRefundsPercent: (state: any) =>
      ((state.data?.refundsAmount || 0) / (state.data?.grossSalesAmount || 0) + (state.data?.refundsAmount || 1) || 1) *
      100,
    getIndicatorsVoidsPercent: (state: any) =>
      ((state.data?.voidsAmount || 0) / ((state.data?.grossSalesAmount || 0) + (state.data?.voidsAmount || 0)) || 1) *
      100,
    getIndicatorsRefundsTotalAmount: (state: any) => state.data?.refundsTotalAmount || 0,
    getIndicatorsNetIncomePerMeal: (state: any) => state.data?.netSalesPerMeal || 0,
    getIndicatorsGrossIncomePerMeal: (state: any) => state.data?.grossSalesPerMeal || 0
  },
  actions: {
    async getIndicators() {
      try {
        const toDay = today().startOf('day').toISO();
        const from = router.currentRoute.query.from || toDay;
        const to = router.currentRoute.query.to || from || toDay;

        const routeQuery: any = {
          ...router.currentRoute.query,
          from,
          to
        };
        if (!requestAllocated(routeQuery)) {
          this.getIndicatorsRequest();

          const indicators = await salesService
            .getIndicators(getToken(), routeQuery)
            .then(res => (routeQuery.currencyId ? getIndicatorsByCurrency(res, routeQuery.currencyId) : res[0]));
          const indicators2 = await transactionsService
            .getTransactionsIndicators(getToken(), routeQuery)
            .then(res => (routeQuery.currencyId ? getIndicatorsByCurrency(res, routeQuery.currencyId) : res[0]));
          this.getIndicatorsSuccess({ indicators: { ...indicators, ...indicators2 } });
          deallocateRequest(routeQuery);
        }
      } catch (error) {
        this.getIndicatorsFailure(error);
        const alertStore = useUIAlertStore();
        alertStore.newError(error);
      }
    },
    getIndicatorsRequest() {
      this.loading = true;
      this.data = {};
      this.error = null;
    },
    getIndicatorsSuccess({ indicators }) {
      this.loading = false;
      this.data = indicators || {};
      this.error = null;
    },
    getIndicatorsFailure(error) {
      this.loading = false;
      this.data = {};
      this.error = error;
    },
    reset() {
      this.loading = true;
      this.data = {};
    }
  }
});
