<template>
  <vel-card class="location__edit" :v-if="!isLoadingLocation && location">
    <template v-slot:title>
      {{ $t('location.info.title') }}
    </template>
    <vel-alert @dismiss="clearMessage" class="alert" level="error" v-if="isError">
      {{ $t('location.errors.patchLocation') }}
    </vel-alert>
    <vel-alert @dismiss="clearMessage" class="success" level="success" v-if="isSuccess">
      {{ $t('location.success.patchLocation') }}
    </vel-alert>
    <location-form
      :location="location"
      :countries="countries"
      :currencies="currencies"
      :resellers="resellers"
      :pending="isPending"
      @cancel="cancel"
      @submit="submit"
    ></location-form>
  </vel-card>
</template>

<script>
import LocationForm from '../components/form/LocationForm';
import VelAlert from '../../../components/alert/VelAlert';
import VelCard from '@/components/card/VelCard';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataLocationsLocation } from '@/stores/data/locations/location.module';
import { useDataLocationsLocationsEdit } from '@/stores/data/locations/locations-edit.module';
import { useDataResellers } from '@/stores/data/resellers.module';

export default {
  name: 'location-edit-container',
  components: {
    LocationForm,
    VelAlert,
    VelCard
  },
  props: {
    countries: {
      type: Array,
      default: () => []
    },
    currencies: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    isSuccess(val) {
      if (val) {
        this.$emit('success');
      }
    }
  },
  methods: {
    ...mapPiniaActions(useDataResellers, ['getResellers']),
    ...mapPiniaActions(useDataLocationsLocation, ['getLocation', 'resetLocation']),
    ...mapPiniaActions(useDataLocationsLocationsEdit, ['patchLocation', 'clearMessage']),
    cancel() {
      this.$emit('cancel');
    },
    submit(locationEntity) {
      this.patchLocation(locationEntity);
    }
  },
  computed: {
    ...mapPiniaState(useDataResellers, ['resellers']),
    ...mapPiniaState(useDataLocationsLocation, ['isLocationNotFound', 'location', 'isLoadingLocation']),
    ...mapPiniaState(useDataLocationsLocationsEdit, ['isPending', 'isError', 'isSuccess'])
  },
  mounted() {
    this.getResellers();
  },
  beforeMount() {
    this.clearMessage();
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.location-form__actions {
  .actions__submit {
    margin-right: 1.25em;
  }
}

.location-form {
  max-width: 800px;
}
</style>
