<template>
  <pie-line-chart
    :pending="isPending"
    :options="chartOptions"
    :series="series"
    :labels="labels"
    :currency="currencyId"
    :show-table="true"
    :title="$t('sales.workstationsSalesChart.title', { limit })"
    :type="get.default_chart_sales_per_workstation.value"
    class="hourly-sales__chart"
  />
</template>

<script>
import PieLineChart from '@/components/pie-line-chart/PieLineChart';
import { useDataSalesWorkstations } from '@/stores/data/sales/workstations.module';
import { storeToRefs } from 'pinia';
import { mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataConfig } from '@/stores/data/config.module';

export default {
  name: 'workstations-sales',
  components: {
    PieLineChart
  },
  props: {
    currencyId: {
      type: String,
      default: null
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  setup() {
    const workstationSalesStore = useDataSalesWorkstations();
    const { isPending, getTotal } = storeToRefs(workstationSalesStore);

    return { isPending, getTotal, workstationSalesStore };
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),

    chartOptions() {
      return {
        xaxis: {
          title: {
            text: this.$t('sales.workstationsSalesChart.xaxis')
          }
        },
        yaxis: {
          title: {
            text: this.$t('sales.workstationsSalesChart.yaxis')
          }
        }
      };
    },
    labels() {
      return this.workstationSalesStore.getLabels(this.currencyId) || [];
    },
    currentSeries() {
      return {
        name: this.$t('sales.workstationsSalesChart.serieName', { currency: this.currencyId }),
        data: this.workstationSalesStore.getWorkstationSalesSeries(this.currencyId) || []
      };
    },
    series() {
      return [this.currentSeries];
    }
  },
  methods: {},
  mounted() {
    this.workstationSalesStore.getSalesByWorkstation();
  }
};
</script>
