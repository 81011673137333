<template>
  <vel-page class="partners-page" :loading="loading">
    <template v-slot:title>
      {{ $t('partnersPage.title') }}
    </template>
    <vel-card>
      <partners-list :data="partners" :filter-callback="setFilter" :initial-length="selectTotal"></partners-list>
    </vel-card>
  </vel-page>
</template>
<script>
import PartnersList from '@/private/partners/components/PartnersList';
import VelCard from '@/components/card/VelCard';
import VelPage from '@/components/page/VelPage';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataPartners } from '@/stores/data/partners.module';

export default {
  name: 'PartnersPage',
  components: {
    PartnersList,
    VelCard,
    VelPage
  },
  computed: {
    ...mapPiniaState(useDataPartners, ['loading', 'partners', 'selectTotal'])
  },
  methods: {
    ...mapPiniaActions(useDataPartners, ['getPartners', 'setFilter'])
  },
  mounted() {
    this.getPartners();
  }
};
</script>
