import { get, put } from '@/helpers/service.helper';
import { getObjectStringValues, getObjectTypeValues } from '@/helpers/object.helpers';

const getConfig = async (token, userId) => {
  try {
    const serverData = (await get(token, `/users/settings?userIDs=${userId}`))[0].settings || {};
    return getObjectTypeValues(serverData);
  } catch (e) {
    return {};
  }
};

export const setConfig = async (token, userId, config) => {
  let currentConfig = await getConfig(token, userId);

  currentConfig = { ...currentConfig, ...config };

  await put(token, `/users/settings`, [
    {
      userId,
      settings: getObjectStringValues(config)
    }
  ]);

  return currentConfig;
};

export default {
  getConfig,
  setConfig
};
