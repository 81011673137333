import 'whatwg-fetch';
import { get, patch, post, put, remove, resolveCommonQuery } from '@/helpers/service.helper';
import pick from 'lodash.pick';
import { getToken } from '@/helpers/token.helper';

/**
 * Get discounts
 * @link https://api.posveloce.com/docs/#operation/getDiscounts
 */
export const getDiscounts = (token, query) => {
  const params = pick(query, ['locationId', 'locationIds', 'search', 'order', 'sort']);
  const url = resolveCommonQuery('/discounts', params).toString();

  return get(token, url);
};

/**
 * Get discount by its id
 * @link https://api.posveloce.com/docs#operation/getDiscountById
 */

const getDiscountById = (token, discountId) => {
  return get(token, `/discounts/${discountId}?includeSchedule=true`);
};

/**
 * Update a discount by its id
 * @link https://api.posveloce.com/docs/private#operation/updateDiscount
 */

const setDiscountById = (token, discount, query) => {
  const params = pick(query, ['discountId']);
  const url = resolveCommonQuery(`/discounts/${params.discountId}`, params).toString();
  return put(token, url, discount);
};

/**
 * Get a discount schedule by its id
 * @link https://api.posveloce.com/docs/private#tag/discounts/paths/~1discounts~1{discountID}~1schedule/get
 */

const getDiscountSchedule = (token, query) => {
  const params = pick(query, ['discountId']);
  const url = resolveCommonQuery(`/discounts/${params.discountId}/schedule`, params).toString();
  return get(token, url);
};

/**
 * Update a discount schedule by its id
 * @link https://api.posveloce.com/docs/private#tag/discounts/paths/~1discounts~1{discountID}~1schedule/put
 */

const setDiscountSchedule = (token, schedule, query) => {
  const params = pick(query, ['discountId']);
  const url = resolveCommonQuery(`/discounts/${params.discountId}/schedule`, params).toString();
  return put(token, url, schedule);
};

/**
 * Delete a discount by id
 * @link https://api.posveloce.com/docs/private#operation/deleteDiscount
 */

export const deleteDiscount = discountId => remove(getToken(), `/discounts/${discountId}`);

/**
 * Create a discount
 * @link https://api.posveloce.com/docs/private#operation/postDiscount
 */
export const postDiscount = (body: {}) => post(getToken(), `/discounts`, body);

/**
 * Partially update discount by id
 * @link https://api.posveloce.com/docs/private#operation/patchDiscount
 */
export const patchDiscount = (discountId, body: {}) => patch(getToken(), `/discounts/${discountId}`, body);

export default {
  getDiscounts,
  getDiscountById,
  setDiscountById,
  getDiscountSchedule,
  setDiscountSchedule,
  deleteDiscount,
  postDiscount,
  patchDiscount
};
