<template>
  <div v-loading="status.loading" class="discounts-table">
    <h5 class="discounts-table__title">{{ $t('discountsTable.title') }}</h5>
    <div class="bootstrap" style="width: 100%; overflow: auto;">
      <div style="min-width: 660px;">
        <b-table
          :show-empty="!discountsData.length"
          primary-key="id"
          :fields="fields"
          head-variant="info"
          :items="discountsData.filter(x => x.salesDiscountsOrigin !== '2.00')"
          sticky-header
          ref="table"
          class="mb-0"
        >
          <template #table-colgroup="scope">
            <col v-for="field in scope.fields" :key="field.key" :class="'col_' + field.key" />
          </template>
          <template #cell(quantity)="scope">
            <div class="text-right">{{ scope.item.quantity }}</div>
          </template>
          <template #cell(discountsAmount)="scope">
            <div class="text-right">
              <vel-amount :currency="scope.item.currency" :amount="scope.item.discountsAmount" />
            </div>
          </template>
          <template #cell(grossSalesPercent)="scope">
            <div class="text-right">{{ scope.item.percentage }} %</div>
          </template>
          <template v-slot:custom-foot="">
            <b-tr
              v-if="discountsData.length > 0"
              style="background-color: #f5f7fa; font-weight: bold; table-layout: fixed;"
            >
              <b-td>Total</b-td>
              <b-td class="text-right">{{ totalQuantity() }}</b-td>
              <b-td class="text-right">
                <vel-amount
                  :currency="discountsData && discountsData[0] && discountsData[0].currency"
                  :amount="totalDiscount()"
                />
              </b-td>
              <b-td class="text-right">{{ totalPercent() }} %</b-td>
            </b-tr>
          </template>
        </b-table>
        <b-table
          v-if="haveCashDiscounts"
          :empty-text="$t('discountsTable.empty')"
          primary-key="id"
          :fields="fields"
          head-variant="info"
          :items="discountsData.filter(x => x.salesDiscountsOrigin === '2.00')"
          ref="table"
          sticky-header
          class="mb-0 cash_discount"
        >
          <template #table-colgroup="scope">
            <col v-for="field in scope.fields" :key="field.key" :class="'col_' + field.key" />
          </template>
          <template #cell(quantity)="scope">
            <div class="text-right">{{ scope.item.quantity }}</div>
          </template>
          <template #cell(discountsAmount)="scope">
            <div class="text-right">
              <vel-amount :currency="scope.item.currency" :amount="scope.item.discountsAmount" />
            </div>
          </template>
          <template #cell(grossSalesPercent)="scope">
            <div class="text-right">{{ scope.item.percentage }} %</div>
          </template>
          <template v-slot:custom-foot="">
            <b-tr
              v-if="discountsData.length > 0"
              style="background-color: #f5f7fa; font-weight: bold; table-layout: fixed;"
            >
              <b-td>Total</b-td>
              <b-td class="text-right">{{ totalQuantity(true) }}</b-td>
              <b-td class="text-right">
                <vel-amount
                  :currency="discountsData && discountsData[0] && discountsData[0].currency"
                  :amount="totalDiscount(true)"
                />
              </b-td>
              <b-td class="text-right">{{ totalPercent(true) }} %</b-td>
            </b-tr>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import formatMoney from '@/filters/format-money';
import VelAmount from '@/components/amount/VelAmount';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia';
import { useUICurrencySelector } from '@/stores/ui/currency-selector.module';
import { useDataSalesIndicators } from '@/stores/data/sales/indicators.module';
import { useDataSalesDiscounts } from '@/stores/data/sales/discounts.module';

export default {
  name: 'DiscountsTable',
  components: {
    VelAmount
  },
  mixins: [DeviceMixin],
  methods: {
    ...mapPiniaActions(useDataSalesDiscounts, ['getDiscounts']),
    sortDiscountsAmounts(a, b) {
      return Number(a.discountsAmount) - Number(b.discountsAmount);
    },
    sortPercentageAmounts(a, b) {
      return Number(a.percentage) - Number(b.percentage);
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total';
          return;
        }
        const salesAmountValues = data.map(item => Number(item.discountsAmount));
        const salesCashDiscountsValues = data.map(item => Number(item.salesDiscountsOrigin));
        const [currency] = data.map(item => item.currency);
        const totalSalesAmount = salesAmountValues.reduce((prev, curr) => {
          if (!Number.isNaN(curr)) {
            return prev + curr;
          }
          return prev;
        }, 0);
        const totalCashDiscounts = salesCashDiscountsValues.reduce((prev, curr) => {
          if (!Number.isNaN(curr)) {
            return prev + curr;
          }
          return prev;
        }, 0);
        const percentageValues = data.map(item => Number(item.percentage));
        const totalPercentage = percentageValues.reduce((prev, curr) => {
          if (!Number.isNaN(curr)) {
            return prev + curr;
          }
          return prev;
        }, 0);

        const values = data.map(item => Number(item[column.property]));
        const totalValues = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          }
          return prev;
        }, 0);
        switch (index) {
          case 1: {
            sums[index] = totalValues || '-';
            break;
          }
          case 2: {
            sums[index] = `${formatMoney(totalSalesAmount, currency)}`;
            break;
          }
          case 3: {
            sums[index] = `${formatMoney(totalPercentage, '')} %`;
            break;
          }
          case 4: {
            sums[index] = `${formatMoney(totalCashDiscounts, currency)}`;
            break;
          }
          default: {
            break;
          }
        }
      });

      return sums;
    },
    totalDiscount(includeCashDiscount = false) {
      return Number(
        this.discountsData
          .filter(x => x.salesDiscountsOrigin !== '2.00' || includeCashDiscount)
          .reduce((prev, curr) => prev + Number(curr.discountsAmount || 0), 0)
          .toFixed(2)
      );
    },
    totalPercent(includeCashDiscount = false) {
      return this.discountsData
        .filter(x => x.salesDiscountsOrigin !== '2.00' || includeCashDiscount)
        .reduce((prev, curr) => prev + Number(curr.percentage || 0), 0)
        .toFixed(2);
    },
    totalQuantity(includeCashDiscount = false) {
      return this.discountsData
        .filter(x => x.salesDiscountsOrigin !== '2.00' || includeCashDiscount)
        .reduce((prev, curr) => prev + Number(curr.quantity || 0), 0);
    }
  },
  computed: {
    ...mapPiniaState(useDataSalesDiscounts, ['getDiscountsByCurrency', 'status']),
    ...mapPiniaState(useUICurrencySelector, ['getCurrencySelectorSelected']),
    ...mapPiniaState(useDataSalesIndicators, ['loading']),
    isLoadingDiscounts() {
      return this.getIsLoadingDiscounts;
    },
    discountsData() {
      return this.getDiscountsByCurrency(this.getCurrencySelectorSelected) || [];
    },
    haveCashDiscounts() {
      return (this.discountsData || []).filter(x => x.salesDiscountsOrigin === '2.00').length;
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('discountsTable.columns.name.title'),
          class: 'name',
          width: '300px',
          sortable: true
        },
        {
          key: 'quantity',
          label: this.$t('discountsTable.columns.quantity.title'),
          class: 'title',
          sortable: true,
          width: '90px'
        },
        {
          key: 'discountsAmount',
          label: this.$t('discountsTable.columns.formattedDiscountsAmount.title'),
          class: 'title',
          sortable: true
        },
        {
          key: 'grossSalesPercent',
          label: this.$t('discountsTable.columns.percentage.title'),
          class: 'title',
          sortable: true
        }
      ];
    }
  },
  watch: {
    loading(isLoading) {
      if (!isLoading) {
        this.getDiscounts();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

/deep/ th div {
  font-size: 0.875rem;
}

/deep/ .thead-info tr th {
  text-align: right;
}

/deep/ .thead-info tr th:first-child {
  text-align: left;
}

/deep/ .name {
  min-width: 300px;
}

/deep/ .title {
  width: 120px;
}

.discounts-table {
  font-family: $base-font-family;
  max-height: 500px;

  /deep/ th {
    background: white;
  }

  &__title {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
}

.cash_discount /deep/ thead {
  display: none;
}
</style>
