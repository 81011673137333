<template>
  <div class="filters">
    <div class="filters__fields">
      <b-form-group>
        <label>{{ $t('checkDetails.filters.dateRange.label') }}</label>
        <date-range-selector
          class="report__date-range-selector"
          :data-invalid="dateRangeInvalid"
          :range.sync="selectedDateRange"
          :disabled="disabled || loading"
          :format="getDateFormat"
          :clearable="true"
          @change="onDateRangeChange"
        ></date-range-selector>
      </b-form-group>
      <b-form-group :invalid-feedback="$t('checkDetails.filters.check.invalid')" :state="invoiceNumberValueValid">
        <label>{{ $t('checkDetails.filters.check.label') }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <span class="input-group-text">
              <b-icon icon="search" />
            </span>
          </b-input-group-prepend>
          <b-form-input
            :disabled="disabled || loading"
            size="sm"
            :placeholder="$t('checkDetails.filters.check.placeholder')"
            :state="invoiceNumberValueValid ? null : false"
            v-model="selectedFilters.invoiceNumberValue"
            @input="invoiceNumberRangeChanged"
            debounce="500"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group>
        <label>{{ $t('checkDetails.filters.tenderTypes.label') }}</label>
        <vel-multiple-selector
          :value-options="this.getFilterKeys(this.filters.tenderTypes)"
          :multiple="true"
          :filterable="true"
          :disabled="disabled || loading"
          v-model="selectedFilters.tenderTypes"
          :all-results-label="'checkDetails.filters.tenderTypes.all'"
          :multiples-results-label="'checkDetails.filters.tenderTypes.filtered'"
        ></vel-multiple-selector>
      </b-form-group>
      <b-form-group>
        <label>{{ $t('checkDetails.filters.departments.label') }}</label>
        <vel-multiple-selector
          :value-options="this.getFilterKeys(this.filters.departments)"
          :multiple="true"
          :filterable="true"
          :disabled="disabled || loading"
          v-model="selectedFilters.departments"
          :all-results-label="'checkDetails.filters.departments.all'"
          :multiples-results-label="'checkDetails.filters.departments.filtered'"
        ></vel-multiple-selector>
      </b-form-group>
      <b-form-group>
        <label>{{ $t('checkDetails.filters.workstations.label') }}</label>
        <vel-multiple-selector
          :value-options="this.getFilterKeys(this.filters.workstations)"
          :multiple="true"
          :filterable="true"
          :disabled="disabled || loading"
          v-model="selectedFilters.workstations"
          :all-results-label="'checkDetails.filters.workstations.all'"
          :multiples-results-label="'checkDetails.filters.workstations.filtered'"
        ></vel-multiple-selector>
      </b-form-group>
      <b-form-group>
        <label>{{ $t('checkDetails.filters.discounts.label') }}</label>
        <vel-multiple-selector
          :value-options="this.getFilterKeys(this.filters.discounts)"
          :multiple="true"
          :filterable="true"
          :disabled="disabled || loading"
          v-model="selectedFilters.discounts"
          :all-results-label="'checkDetails.filters.discounts.all'"
          :multiples-results-label="'checkDetails.filters.discounts.filtered'"
        ></vel-multiple-selector>
      </b-form-group>
      <b-form-group>
        <label>{{ $t('checkDetails.filters.status.label') }}</label>
        <vel-multiple-selector
          :value-options="this.statusList"
          :multiple="false"
          :filterable="false"
          :disabled="disabled || loading"
          v-model="selectedFilters.status"
          :all-results-label="'checkDetails.filters.status.all'"
          :multiples-results-label="'checkDetails.filters.status.filtered'"
        ></vel-multiple-selector>
      </b-form-group>
      <b-form-group>
        <label>{{ $t('checkDetails.filters.employee.label') }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <span class="input-group-text">
              <b-icon icon="search" />
            </span>
          </b-input-group-prepend>
          <b-form-input
            v-model="selectedFilters.employeeName"
            :disabled="disabled || loading"
            size="sm"
            max="10"
            :placeholder="$t('checkDetails.filters.employee.placeholder')"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group>
        <label>{{ $t('checkDetails.filters.employeeNumber.label') }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <span class="input-group-text">
              <b-icon icon="search" />
            </span>
          </b-input-group-prepend>
          <b-form-input
            v-model="selectedFilters.employeeNumber"
            :formatter="formatNumber"
            :disabled="disabled || loading"
            size="sm"
            max="10"
            number
            step="1"
            min="0"
            :placeholder="$t('checkDetails.filters.employeeNumber.placeholder')"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
    </div>
    <div class="filters__buttons">
      <span v-b-tooltip.top.hover :title="this.searchPopupLabel" class="generate d-flex">
        <b-button variant="primary" :disabled="disableApply" @click="applyFilters">
          {{ $t('checkDetails.filters.search') }}
        </b-button>
      </span>
      <b-button variant="secondary" :disabled="disabled || loading" @click="clearFilters">
        {{ $t('checkDetails.filters.clear') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import DateRangeSelector from '@/private/components/date-range-selector/DateRangeSelector.vue';
import { DateTime } from 'luxon';
import VelMultipleSelector from '@/components/list/VelMultipleSelector';
import cloneDeep from 'lodash.clonedeep';
import { getDateFormatFromUserConfig } from '@/helpers/date.helpers';
import locationsService from '@/services/locations.service';
import { mapActions as mapPiniaActions } from 'pinia/dist/pinia';
import { storeToRefs } from 'pinia';
import { useUIDateRangeSelector } from '@/stores/ui/date-range-selector.module';

export default {
  name: 'CheckDetailsFilters',
  components: {
    VelMultipleSelector,
    DateRangeSelector
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    enableSearchButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      filters: {
        tenderTypes: {},
        departments: {},
        workstations: {},
        discounts: {}
      },
      selectedFilters: {
        tenderTypes: [],
        departments: [],
        workstations: [],
        discounts: [],
        invoiceNumber: [],
        employeeName: '',
        employeeNumber: '',
        invoiceNumberValue: '',
        from: null,
        to: null,
        status: null
      },
      invoiceNumberValueValid: true,
      previousFilters: '',
      loading: false
    };
  },
  setup() {
    const uiDateRangeSelectorStore = useUIDateRangeSelector();
    const { getDateSelectorSelectedRange } = storeToRefs(uiDateRangeSelectorStore);

    return {
      dateRange: getDateSelectorSelectedRange
    };
  },
  computed: {
    dateRangeInvalid() {
      return (
        this.selectedFilters.from === null &&
        this.selectedFilters.to === null &&
        (this.selectedFilters.tenderTypes.length > 0 ||
          this.selectedFilters.departments.length > 0 ||
          this.selectedFilters.workstations.length > 0 ||
          this.selectedFilters.discounts.length > 0 ||
          this.selectedFilters.employeeName.length > 0 ||
          this.selectedFilters.employeeNumber > 0)
      );
    },
    haveFilters() {
      return (
        (this.selectedFilters.from !== null && this.selectedFilters.to !== null) ||
        this.selectedFilters.tenderTypes.length > 0 ||
        this.selectedFilters.departments.length > 0 ||
        this.selectedFilters.workstations.length > 0 ||
        this.selectedFilters.discounts.length > 0 ||
        this.selectedFilters.employeeName.length > 0 ||
        this.selectedFilters.employeeNumber > 0 ||
        this.selectedFilters.invoiceNumberValue.length > 0
      );
    },
    hasFiltersChanged() {
      return this.enableSearchButton || JSON.stringify(this.selectedFilters) !== this.previousFilters;
    },
    disableApply() {
      return this.disabled || this.loading || !this.hasFiltersChanged || !this.haveFilters || this.dateRangeInvalid;
    },
    getDateFormat() {
      return getDateFormatFromUserConfig();
    },
    selectedDateRange: {
      get() {
        return this.selectedFilters.from !== null && this.selectedFilters.to !== null
          ? [this.selectedFilters.from, this.selectedFilters.to]
          : [];
      },
      set(value) {
        this.onDateRangeChange(value);
      }
    },
    searchPopupLabel() {
      let title = '';

      if (this.disableApply) {
        if (!this.haveFilters) {
          title = this.$t('checkDetails.filters.filterRequired');
        } else if (this.dateRangeInvalid) {
          title = this.$t('checkDetails.filters.dateRangeRequired');
        } else {
          title = this.$t('toolbar.selectors.generateLabelTooltip');
        }
      }

      return title;
    },
    statusList() {
      return [
        { value: null, key: null, label: this.$t('checkStatus.states.all') },
        { value: 0, key: 0, label: this.$t('checkStatus.states.0') },
        { value: 1, key: 1, label: this.$t('checkStatus.states.1') },
        { value: 2, key: 2, label: this.$t('checkStatus.states.2') }
      ];
    }
  },
  methods: {
    ...mapPiniaActions(useUIDateRangeSelector, {
      onDateRangSelectoreChange: 'onChange'
    }),
    reset() {
      this.selectedFilters = {
        tenderTypes: [],
        departments: [],
        workstations: [],
        discounts: [],
        invoiceNumber: [],
        invoiceNumberRange: [],
        employeeName: '',
        employeeNumber: '',
        invoiceNumberValue: '',
        from: null,
        to: null,
        status: null
      };

      this.invoiceNumberValueValid = true;
      this.previousFilters = '';
    },
    invoiceNumberRangeChanged(v) {
      this.invoiceNumberValueValid = true;
    },
    getFilterKeys(fields) {
      return Object.keys(fields)
        .sort()
        .map(r => ({ value: r, key: r, label: r }));
    },
    isValidInvoiceNumber(n) {
      if (isNaN(n)) {
        return false;
      }

      return n >= 0 && n.toString().length >= 4;
    },
    extractInvoiceRange() {
      this.selectedFilters.invoiceNumber = [];
      this.selectedFilters.invoiceNumberRange = [];

      if (this.selectedFilters.invoiceNumberValue.length === 0) {
        return true;
      }

      const chunks = this.selectedFilters.invoiceNumberValue.replaceAll(' ', '').split(',');

      for (const chunk of chunks) {
        if (isNaN(chunk)) {
          const potentialRange = chunk.split('-');
          if (potentialRange.length !== 2) {
            return false;
          }

          if (!this.isValidInvoiceNumber(potentialRange[0]) || !this.isValidInvoiceNumber(potentialRange[1])) {
            return false;
          }

          this.selectedFilters.invoiceNumberRange.push(chunk);
        } else {
          if (!this.isValidInvoiceNumber(chunk)) {
            return false;
          }

          this.selectedFilters.invoiceNumber.push(chunk);
        }
      }

      return this.selectedFilters.invoiceNumber.length <= 10 && this.selectedFilters.invoiceNumberRange.length <= 10;
    },
    getIDsFromKeys(filter, keys) {
      const ids = [];
      keys.forEach(k => ids.push(...this.filters[filter][k]));
      return ids;
    },
    getFilters() {
      let filters = {
        tenderTypeIDs: this.getIDsFromKeys('tenderTypes', this.selectedFilters.tenderTypes),
        departmentIDs: this.getIDsFromKeys('departments', this.selectedFilters.departments),
        workstationIDs: this.getIDsFromKeys('workstations', this.selectedFilters.workstations),
        discountIDs: this.getIDsFromKeys('discounts', this.selectedFilters.discounts),
        employeeName: this.selectedFilters.employeeName,
        employeeNumber: this.selectedFilters.employeeNumber,
        invoiceNumber: this.selectedFilters.invoiceNumber,
        invoiceNumberRange: this.selectedFilters.invoiceNumberRange,
        from: this.selectedFilters.from,
        to: this.selectedFilters.to
      };

      if (this.selectedFilters.status !== null) {
        filters = { ...filters, status: this.selectedFilters.status };
      }

      return filters;
    },
    clearFilters() {
      this.reset();
      this.$emit('filtersCleared');
    },
    applyFilters(emit = true) {
      this.invoiceNumberValueValid = true;
      if (this.extractInvoiceRange()) {
        this.previousFilters = JSON.stringify(cloneDeep(this.selectedFilters));
        if(emit) {
          this.$emit('filtersChanged');
        }
      } else {
        this.invoiceNumberValueValid = false;
      }
    },
    async fetchFiltersData() {
      this.loading = true;
      await locationsService.getLocationFilters(this.$route.query).then(data => {
        ['tenderTypes', 'departments', 'workstations', 'discounts'].forEach(filter => {
          if (data[filter]) {
            data[filter].forEach(f => {
              this.$set(this.filters[filter], f.name, f.ids);
            });
          }
        });
      });
      this.loading = false;
    },
    onDateRangeChange(date) {
      if (!date) {
        this.selectedFilters.from = this.selectedFilters.to = null;
        return;
      }

      const startDateTime = DateTime.fromJSDate(date[0]);
      const endDateTime = DateTime.fromJSDate(date[1]);
      if (this.selectedFilters.from === startDateTime.toISO() && this.selectedFilters.to === endDateTime.toISO()) {
        return;
      }

      this.selectedFilters.from = startDateTime.toISO();
      this.selectedFilters.to = endDateTime.toISO();
    },
    formatNumber(value) {
      value = value.replace(/[^\d]/g, '');
      if (!value) {
        return null;
      }
      return Number(value).toFixed(0);
    }
  },
  async mounted() {
    this.previousFilters = JSON.stringify(cloneDeep(this.selectedFilters));
    await this.fetchFiltersData();
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

$contrast-color: #00a9e1;

* {
  font-size: rem-calc(12) !important;
}

.filters {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  padding-bottom: 1em;
  gap: 10px;
  height: 100%;

  .btn-secondary {
    background: #f5f5f5;
    color: $contrast-color;
    border: none;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;
    padding-right: 5px;

    &::-webkit-scrollbar-thumb {
      background-color: #00a9e1;
      border-radius: 20px;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent; /* or add it to the track */
    }

    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #00a9e1 transparent; /* scroll thumb and track */

    .el-select,
    /deep/ .el-select__tags,
    /deep/ .el-input,
    /deep/ .el-select__input,
    /deep/ .el-input__inner,
    /deep/ .el-input__suffix {
      max-height: 32px;
      height: 32px;
    }

    /deep/ .el-input__icon {
      line-height: 32px;
      color: $contrast-color;
    }

    input.form-control {
      height: 34px;
    }

    .form-group {
      max-width: 230px;

      & > div > label {
        color: #808080;
        min-height: unset;
        margin: 0;
      }

      .input-group-text {
        width: 24px;
        border-right: none;
        color: #d9d9d9;
        padding: 0;

        svg {
          margin-left: 4px;
        }
      }
    }

    .report__date-range-selector {
      &[data-invalid='true'] {
        border: 1px solid #dc3545 !important;
      }
      :hover {
        cursor: pointer;
      }

      /deep/ & .el-range-separator {
        line-height: 26px !important;
      }

      /deep/ & .el-range__close-icon {
        display: inline-block !important;
        line-height: 26px;
      }

      /deep/ &.el-range-editor {
        border: 1px solid $link-water;
        margin-top: 4px;
        max-width: 100%;
        width: 100%;

        @include breakpoint(medium) {
          margin-top: 0;
          width: initial;
        }
      }
    }
  }

  .input-group-prepend {
    height: inherit !important;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    max-width: 230px;
  }
}

/deep/ .tooltip-inner {
  background-color: #fff;
  border: 1px solid #6f6f6f;
  border-radius: 0.25rem;
  color: #666;
  font-size: 0.8rem;
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  text-align: center;
}

/deep/ .input-group-append .input-group-text > svg {
  color: #c0c4cc !important;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
