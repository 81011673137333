import { DateTime } from 'luxon';
import formatHour from '@/filters/format-hour-utc';
import uniq from 'lodash.uniq';

export function today() {
  return DateTime.local().startOf('day');
}

export function tomorrow() {
  return today().plus({ day: 1 });
}

export function parameterizeDate(date, toEndDay = false) {
  let dt;
  if (date instanceof Date) {
    dt = DateTime.fromJSDate(date);
  } else if (typeof date === 'string') {
    dt = DateTime.fromISO(date);
  }

  if (toEndDay) {
    dt = dt.endOf('day');
  }
  return dt.toString();
}

export function pruneToUTC(dt, toEndDay = false) {
  const d = dt instanceof Date ? DateTime.fromJSDate(dt) : DateTime.fromISO(dt);
  const utdDate = d.startOf('day').setZone('utc', { keepLocalTime: true });
  return toEndDay ? utdDate.endOf('day').toString() : utdDate.startOf('day').toString();
}

export function pruneToUTCRange(range) {
  return {
    from: DateTime.fromJSDate(range[0])
      .startOf('day')
      .setZone('utc', { keepLocalTime: true })
      .startOf('day')
      .toString(),
    to: DateTime.fromJSDate(range[1]).startOf('day').setZone('utc', { keepLocalTime: true }).endOf('day').toString()
  };
}

export function parseISO(dateAsString) {
  return DateTime.fromISO(dateAsString).toJSDate();
}

export function parseRange(from, to) {
  const range = [today().toJSDate(), tomorrow().toJSDate()];

  const parsedFrom = DateTime.fromISO(from);
  const parsedTo = DateTime.fromISO(to);

  if (parsedFrom.isValid) {
    range[0] = parsedFrom.toJSDate();
  }

  if (parsedTo.isValid) {
    range[1] = parsedTo.toJSDate();
  }

  return range;
}

export function getUTCHourWithWeekDay(date) {
  const dt = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
  return dt.setZone('UTC').toFormat(`E-HH'h'`);
}

// @todo: Should be deleted later
// Previously the day of the week was used to group sales by hours,
// this is problematic when we don't want to compare our sales with the previous week.
// This function was introduced to correct this problem without rewriting
// any part of the code. So, the weekday is used for sorting, but first day
// is used to group the results. I'm not sure there's not a problem with that approach.
export function getUTCHourWithFirstWeekDay(date) {
  const dt = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
  return dt.setZone('UTC').toFormat(`1-HH'h'`);
}

function getUTCHourWithWeekDayWithZeroForCrossingWeek(date) {
  return getUTCHourWithWeekDay(date).replace('7-', '0-');
}

export function getUniqHoursWithWeekdayFromArrays(...datesArrays) {
  const hoursArray = [];

  for (const datesArray of datesArrays) {
    const dates = datesArray.map(date => DateTime.fromISO(date));
    const minDate = DateTime.min(...dates);
    const maxDate = DateTime.max(...dates);

    let currentDate = minDate;
    const datesWithNoGap = [];

    while (currentDate <= maxDate) {
      datesWithNoGap.push(currentDate);
      currentDate = currentDate.plus({ hours: 1 });
    }

    hoursArray.push(...datesWithNoGap);
  }

  // 💥 WTF: When we're crossing weeks, from example we have both a sunday and a monday in the same date array...
  // We have to choose a different way to sort the dates to make sure Sunday comes before Monday.
  // This won't work at all anymore if -for some reason- we start having intervals that cover more than two days.
  const isCrossingWeeks = datesArrays
    .map(dateArray => uniq(dateArray.map(date => DateTime.fromISO(date).setZone('UTC').weekNumber)))
    .some(dateArray => dateArray.length > 1);

  const formatMethodForSorting = isCrossingWeeks ? getUTCHourWithWeekDayWithZeroForCrossingWeek : getUTCHourWithWeekDay;
  const byHour = (a, b) => formatMethodForSorting(a).localeCompare(formatMethodForSorting(b));
  return uniq(hoursArray.sort(byHour).map(getUTCHourWithFirstWeekDay));
}

export function getHoursRangeWithUnitAndNoGap(hoursArray) {
  const sortedHours = hoursArray
    .map(formatHour)
    .map(hour => Number(hour.replace('h', '')))
    .sort((a, b) => a - b);

  const [min] = sortedHours;
  const max = sortedHours[sortedHours.length - 1];
  const hoursWithFilledBlank = [];

  for (let i = min; i <= max; i++) {
    hoursWithFilledBlank.push(`${i < 10 ? `0${i}` : i}h`);
  }

  return hoursWithFilledBlank;
}

export function convertMilitaryTimeLabelToMeridiem(hoursArray) {
  return hoursArray.map(hf => {
    const h = hf.slice(0, -1);

    const meridiem = h >= 12 ? 'PM' : 'AM';

    return (((h <= 12 && h) || h - 12).toString() + meridiem).padStart(4, '0');
  });
}

export const getDateTimeDurationById = durationId => {
  let duration: any = { week: 1 };

  switch (durationId) {
    case 'day':
      duration = { day: 1 };
      break;
    case 'month':
      duration = { month: 1 };
      break;
    case 'year':
      duration = { year: 1 };
      break;
  }
  return duration;
};

const weekDays: { [x: number]: number } = {
  1: 1, // Sunday
  2: 2, // Monday
  3: 4, // Tuesday
  4: 8, // Wednesday
  5: 16, // Thursday
  6: 32, // Friday
  7: 64 // Saturday
};

export const toggleWeekDayFromSchedule = (scheduleValue: number, weekDay: number) => scheduleValue ^ weekDays[weekDay];

export const hasWeekdayEnabled = (scheduleValue: number, weekDay: number) => !!(scheduleValue & weekDays[weekDay]);

export const getDateFormatFromUserConfig = () => {
  const config: any = JSON.parse(localStorage.getItem('config') || JSON.stringify('{}'));
  return (config && config.global_date_format && config.global_date_format.value) || 'MM/dd/yyyy';
};

export const getTimeFormatFromUserConfig = () => (getUse24Hour() ? 't' : 'T');

export const getCurrentTimeForReport = () => DateTime.local().toFormat('yyyyMMddHHmmss');

export const getUse24Hour = () => {
  const config: any = JSON.parse(localStorage.getItem('config') || JSON.stringify('{}'));
  return config && config.enable_military_time_format ? config.enable_military_time_format.value : true;
};

export default {
  parameterizeDate,
  parseRange,
  pruneToUTC,
  pruneToUTCRange,
  today,
  tomorrow,
  getUTCHourWithWeekDay,
  getHoursRangeWithUnitAndNoGap,
  getUniqHoursWithWeekdayFromArrays,
  convertTimeLabelToMilitary: convertMilitaryTimeLabelToMeridiem,
  getCurrentTimeForReport,
  getUse24Hour
};
