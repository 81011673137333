import { oc } from 'ts-optchain';
import { mapStores } from 'pinia';
import { useUIAppStore } from '@/stores/ui/app.module';

export default {
  computed: {
    ...mapStores(useUIAppStore)
  },
  methods: {
    togglePopover(name) {
      if (this.appStore.isPopoverActive(name)) {
        this.appStore.resetPopover();
        return;
      }
      this.appStore.setActivePopover(name);
    },
    dismissPopover(name, { target, popover, trigger }) {
      if (!this.appStore.hasActivePopover) {
        return;
      }

      if (
        this.appStore.isPopoverActive(name) &&
        (oc(trigger).$el([]).contains(target) || oc(popover).$el([]).contains(target))
      ) {
        return;
      }

      this.appStore.resetPopover();
    }
  }
};
