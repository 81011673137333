<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="600px"
    right
    id="coursingForm-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarCoursingForm"
    v-model="isSidebarVisible"
    :no-close-on-backdrop="$refs.form && $refs.form.haveChanges"
    :no-close-on-esc="$refs.form && $refs.form.haveChanges"
    @hidden="onHide"
  >
    <template #header>
      <h6 class="bg-gray-200 bg-light w-100 mb-0 text-center py-2">
        {{ $t(coursing && coursing.id ? 'coursing.edit.title' : 'coursing.create.title') }}
      </h6>
    </template>
    <template #footer="{ hide }">
      <div
        class="d-flex bg-light text-dark align-items-center justify-content-between px-3 py-2"
        style="border-top: 2px solid #d6d6d8;"
      >
        <b-button :disabled="!($refs.form && $refs.form.enableSaveChanges)" variant="primary" @click="updateCoursing()">
          {{ $t(coursing && coursing.id ? 'coursing.edit.save' : 'coursing.create.save') }}
        </b-button>
        <b-button variant="outline-primary" @click="onCancel(hide)">
          {{ $t('coursing.cancel') }}
        </b-button>
      </div>
    </template>
    <b-container style="background-color: #f4f4f4;" fluid class="py-4 px-0" v-loading="pending">
      <coursing-form :licenceNumber="licenceNumber" :coursing.sync="coursing" ref="form"></coursing-form>
    </b-container>
  </b-sidebar>
</template>
<script>
import { patchItemService, postItemService } from '@/services/items.service';
import CoursingForm from '@/private/components/coursing/CoursingForm';
import { bus } from '@/bus';

export default {
  name: 'coursing-sidebar-form-coursing',
  components: {
    CoursingForm
  },
  props: {
    coursing: {
      type: Object,
      default: () => ({
        name: ''
      })
    },
    pending: {
      type: Boolean,
      default: false
    },
    licenceNumber: {
      type: Number,
      default: 0
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSidebarVisible: false
    };
  },
  computed: {
    employeeMealPlan() {
      return {
        ...this.$refs.form.form,
        eligibility: this.$refs.form.form.eligibility.map(e => ({
          duration: Number(e.duration),
          amount: Number(Number(e.amount).toFixed(2))
        })),
        licenceNumber: this.licenceNumber || this.employee.licenceNumber || null
      };
    }
  },
  methods: {
    toast(msg = 'toast.update', value = {}) {
      this.$toast.open({
        message: `${this.$t(msg, value)}`,
        position: 'bottom',
        type: 'success',
        queue: false,
        duration: 6000,
        dismissible: true
      });
    },
    onCancel(hide) {
      if (this.$refs.form.haveChanges) {
        this.$emit('onCancel', null);
      } else {
        hide();
      }
    },
    onHide() {
      this.coursing = null;
      this.$emit('update:coursing', null);
      this.$forceUpdate();
      return true;
    },
    async updateCoursing() {
      try {
        this.$emit('update:pending', true);
        if (this.coursing.id) {
          await patchItemService(this.coursing.id, this.coursing);
          this.toast('toast.update');
        } else {
          await postItemService({ ...this.coursing, licenceNumber: this.licenceNumber });
          this.toast('toast.CoursingCreate', this.coursing);
        }
        this.$emit('update:pending', false);
        this.$emit('success', this.coursing);
        this.$refs.sidebarCoursingForm.hide();
      } catch (e) {
        this.$emit('update:error', true);
        this.$emit('update:pending', false);
        this.$emit('failure', this.coursing);
        console.error(e);
      }
    }
  },
  mounted() {
    bus.$on('document:click', evt => {
      if (evt.target.className.includes('b-sidebar-backdrop') && this.$refs.form.haveChanges) {
        this.$emit('onCancel', null);
      }
    });
  },
  destroyed() {
    bus.$off('document:click');
  }
};
</script>
<style scoped lang="scss">
/deep/ .b-sidebar-header {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

/deep/ .b-sidebar-body {
  background-color: #f4f4f4;
}
</style>
