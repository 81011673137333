<template>
  <vel-page :show-crumbs="false" :loading="loading" :title="$t('languageSetupPage.title')">
    <vel-card>
      <h4>{{ $t('languageSetupPage.supported') }}</h4>
      <table class="supported-languages">
        <thead>
          <th></th>
          <th>{{ $t('languageSetupPage.table.activate') }}</th>
          <th>{{ $t('languageSetupPage.table.default') }}</th>
        </thead>
        <tbody>
          <tr v-for="(val, language) in supportedLanguages" :key="language">
            <td>
              {{ $t(`languageSetupPage.table.${language}`) }}
            </td>
            <td>
              <el-checkbox-group :min="1" v-model="availableLanguages">
                <el-checkbox @change="handleAvailableLangChange" :label="language" />
              </el-checkbox-group>
            </td>
            <td>
              <el-radio
                @change="handleDefaultLangChange"
                v-model="mainLanguage"
                :disabled="!availableLanguages.includes(language)"
                :label="language"
              ></el-radio>
            </td>
          </tr>
        </tbody>
      </table>
      <h4 class="preferred-languages-title">{{ $t('languageSetupPage.preferred') }}</h4>
      <table class="preferred-languages">
        <thead>
          <th></th>
          <th
            v-bind:class="{ 'default-language': mainLanguage === language }"
            v-for="language in availableLanguages"
            :key="language"
          >
            {{
              `${
                mainLanguage === language
                  ? `${$t('languageSetupPage.table.default')} (${$t(`languageSetupPage.table.${language}`)})`
                  : `${$t(`languageSetupPage.table.${language}`)}`
              }`
            }}
          </th>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ $t('languageSetupPage.table.backOffice') }}
            </td>
            <td v-for="language in availableLanguages" :key="language">
              <el-radio v-model="preferredSettings.backOffice" :label="language"></el-radio>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('languageSetupPage.table.pos') }}
            </td>
            <td v-for="language in availableLanguages" :key="language">
              <el-radio v-model="preferredSettings.pos" :label="language"></el-radio>
            </td>
          </tr>
          <tr>
            <td>
              {{
                `${$t('languageSetupPage.table.requisition')} ${
                  hasMultipleLanguages ? `(${$t('languageSetupPage.table.print-order')})` : ''
                }`
              }}
            </td>
            <td v-for="language in availableLanguages" :key="language">
              <el-checkbox v-model="preferredSettings.requisition[language].enabled" />
              <vel-select
                @change="handleReqPriorityChange(preferredSettings.requisition[language].priority, language)"
                v-if="hasMultipleLanguages && Object.values(preferredSettings.requisition).every(p => p.enabled)"
                v-model="preferredSettings.requisition[language].priority"
              >
                <vel-option :value="1" />
                <vel-option :value="2" />
              </vel-select>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('languageSetupPage.table.customerCheck') }}
            </td>
            <td v-for="language in availableLanguages" :key="language">
              <el-radio v-model="preferredSettings.customerCheck" :label="language"></el-radio>
            </td>
          </tr>
          <tr>
            <td>
              {{
                `${$t('languageSetupPage.table.kds')} ${
                  hasMultipleLanguages ? `(${$t('languageSetupPage.table.print-order')})` : ''
                }`
              }}
            </td>
            <td v-for="language in availableLanguages" :key="language">
              <el-checkbox v-model="preferredSettings.kds[language].enabled" />
              <vel-select
                @change="handleKdsPriorityChange(preferredSettings.kds[language].priority, language)"
                v-if="hasMultipleLanguages && Object.values(preferredSettings.kds).every(p => p.enabled)"
                v-model="preferredSettings.kds[language].priority"
              >
                <vel-option label="1" :value="1" />
                <vel-option label="2" :value="2" />
              </vel-select>
            </td>
          </tr>
        </tbody>
      </table>
    </vel-card>
    <div class="actions">
      <vel-button @click="handleSaveSettings">{{ $t('defaults.actions.save') }}</vel-button>
      <vel-button type="link" @click="handleCancel">{{ $t('defaults.actions.cancel') }}</vel-button>
    </div>
  </vel-page>
</template>

<script>
import { Checkbox, CheckboxGroup, Radio } from 'element-ui';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelOption from '@/components/x-option/VelOption';
import VelPage from '@/components/page/VelPage';
import VelSelect from '@/components/x-select/VelSelect';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataLocationsSettingsLocation } from '@/stores/data/locations/settings-location.module';

export default {
  name: 'language-setup-page',
  components: {
    'el-checkbox': Checkbox,
    'el-checkbox-group': CheckboxGroup,
    'el-radio': Radio,
    VelButton,
    VelCard,
    VelOption,
    VelPage,
    VelSelect
  },
  data() {
    return {
      availableLanguages: ['english'],
      supportedLanguages: {
        english: true,
        french: false
      },
      mainLanguage: 'english',
      loadingSettings: true,
      preferredSettings: {
        backOffice: 'english',
        pos: 'english',
        requisition: { french: { enabled: false, priority: 2 }, english: { enabled: true, priority: 1 } },
        customerCheck: 'english',
        kds: { french: { enabled: false, priority: 2 }, english: { enabled: true, priority: 1 } }
      }
    };
  },
  computed: {
    ...mapPiniaState(useDataLocationsSettingsLocation, ['settings', 'loading']),
    isPageLoading() {
      return this.loading && this.loadingSettings;
    },
    hasMultipleLanguages() {
      return this.availableLanguages.length > 1;
    }
  },
  methods: {
    ...mapPiniaActions(useDataLocationsSettingsLocation, ['getSettings', 'putSettings']),
    handleCancel() {
      this.$router.push({
        name: 'setup-menu',
        query: { ...this.$router.currentRoute.query, previous: 'language-setup' }
      });
    },
    handleSaveSettings() {
      this.putSettings({
        locationId: this.$router.currentRoute.query.locationId,
        settings: {
          ...this.settings,
          mainLanguage: this.mainLanguage,
          supportedLanguages: {
            english: this.availableLanguages.includes('english'),
            french: this.availableLanguages.includes('french')
          },
          preferredSettings: {
            backOffice: this.preferredSettings.backOffice,
            pos: this.preferredSettings.pos,
            requisition: this.preferredSettings.requisition,
            customerCheck: this.preferredSettings.customerCheck,
            kds: this.preferredSettings.kds
          }
        }
      });
      this.$router.push({
        name: 'setup-menu',
        query: { ...this.$router.currentRoute.query, successMessage: 'language-setup' }
      });
    },
    handleDefaultLangChange(lang) {
      if (this.availableLanguages[0] !== lang) {
        this.availableLanguages.pop();
        this.availableLanguages.push(lang);
      }

      const hasPriority = this.hasMultipleLanguages;

      this.preferredSettings = {
        backOffice: lang,
        pos: lang,
        requisition: {
          french: {
            enabled: hasPriority || this.preferredSettings.requisition.french.enabled,
            priority: lang === 'french' ? 1 : 2
          },
          english: {
            enabled: hasPriority || this.preferredSettings.requisition.english.enabled,
            priority: lang === 'english' ? 1 : 2
          }
        },
        customerCheck: lang,
        kds: {
          french: {
            enabled: hasPriority || this.preferredSettings.kds.french.enabled,
            priority: lang === 'french' ? 1 : 2
          },
          english: {
            enabled: hasPriority || this.preferredSettings.kds.english.enabled,
            priority: lang === 'english' ? 1 : 2
          }
        }
      };
    },
    handleAvailableLangChange() {
      if (this.availableLanguages[0] !== this.mainLanguage) {
        this.mainLanguage = this.availableLanguages[0];
        this.handleDefaultLangChange(this.mainLanguage);
      }
    },
    handleReqPriorityChange(val, lang) {
      if (val === 1 && lang === 'french') {
        this.preferredSettings.requisition.english.priority = 2;
      } else if (val === 2 && lang === 'french') {
        this.preferredSettings.requisition.english.priority = 1;
      } else if (val === 1 && lang === 'english') {
        this.preferredSettings.requisition.french.priority = 2;
      } else if (val === 2 && lang === 'english') {
        this.preferredSettings.requisition.french.priority = 1;
      }
    },
    handleKdsPriorityChange(val, lang) {
      if (val === 1 && lang === 'french') {
        this.preferredSettings.kds.english.priority = 2;
      } else if (val === 2 && lang === 'french') {
        this.preferredSettings.kds.english.priority = 1;
      } else if (val === 1 && lang === 'english') {
        this.preferredSettings.kds.french.priority = 2;
      } else if (val === 2 && lang === 'english') {
        this.preferredSettings.kds.french.priority = 1;
      }
    }
  },
  async mounted() {
    await this.getSettings(this.$router.currentRoute.query.locationId);
  },
  watch: {
    settings(newVal) {
      if (newVal) {
        this.loadingSettings = true;
        this.supportedLanguages = newVal.supportedLanguages || this.supportedLanguages;
        this.mainLanguage = newVal.mainLanguage || this.mainLanguage;
        this.preferredSettings = newVal.preferredSettings || this.preferredSettings;
        for (const val in newVal.supportedLanguages) {
          if (newVal.supportedLanguages[val] && !this.availableLanguages.includes(val)) {
            this.availableLanguages.push(val);
          }
        }
        this.loadingSettings = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';

/deep/ .vel-page {
  .el-radio__label,
  .el-checkbox__label {
    display: none;
  }

  .vel-card {
    box-shadow: 0 2px 0 0 rgba(#000, 0.1);
    height: 100%;
    margin-bottom: 0;
    -ms-overflow-style: none;
    overflow-x: scroll;
    padding: rem-calc(24);
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__content {
    color: #2a3142;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    .footer {
      display: none;
    }

    h4 {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      margin-bottom: rem-calc(15);

      &.preferred-languages-title {
        margin-top: rem-calc(40);
      }
    }

    table {
      border-collapse: collapse;

      th {
        &:nth-child(1) {
          min-width: rem-calc(300);
        }

        font-size: rem-calc(14);
        font-weight: bold;
        padding: rem-calc(4);
      }

      tr {
        background: rgba(#00a9e1, 0.05);

        &:nth-child(1) {
          margin-top: rem-calc(5);
        }

        &:nth-child(2n) {
          background: #fff;
        }
      }

      &.supported-languages {
        td {
          &:nth-child(1) {
            text-transform: capitalize;
          }
        }
      }

      &.preferred-languages {
        th {
          &:not(.default-language) {
            text-transform: capitalize;
          }
        }
      }

      td {
        font-size: rem-calc(14);
        min-width: rem-calc(150);
        padding: rem-calc(15);

        &:not(:first-child) {
          text-align: center;
        }
      }

      .el-checkbox {
        &.is-checked {
          .el-checkbox__inner {
            background: #2a3142;
            border: 2px solid #2a3142;
          }
        }

        &__inner {
          border: 2px solid #2a3142;
          padding: rem-calc(8);

          &::after {
            border-width: 0 1px 1px 0;
            left: rem-calc(6);
            top: rem-calc(2);
            transform: rotate(45deg) scale(1.5);
          }
        }
      }

      .el-radio {
        &__inner {
          background: #fff;
          border: 2px solid #2a3142;
          padding: rem-calc(8);

          &::after {
            background: #2a3142;
            height: rem-calc(10);
            width: rem-calc(10);
          }
        }
      }

      .el-select {
        margin-left: rem-calc(5);
        position: absolute;
        width: rem-calc(45);

        .el-input {
          &__icon {
            line-height: rem-calc(25);
          }

          &__inner {
            height: rem-calc(25);
            padding: 0 0 0 rem-calc(10);
          }
        }
      }
    }
  }
}

.actions {
  align-items: center;
  display: flex;
  height: rem-calc(64);
  justify-content: space-between;
  margin: rem-calc(8) 0;
  padding-left: rem-calc(24);
  padding-right: rem-calc(24);

  @include breakpoint(large) {
    margin: unset;
  }

  button {
    padding: 0 rem-calc(20);
    text-transform: uppercase;

    &.vel-button_link {
      background: transparent;
      border: 1px solid rgba(#000, 0.1);
      color: #00a9e1;
    }
  }
}
</style>
