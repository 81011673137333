<template>
  <vel-page
    class="sales-items-edit-page"
    :loading="loading"
    v-loading="pending || isLoadingSchedules || isLoadingPrices"
  >
    <template v-slot:title>
      {{ $t('salesItemsEdit.title') }}
    </template>
    <vel-card>
      <vel-form
        ref="ruleForm"
        :model="formValue"
        :disabled="loading || pending"
        :label-position="labelPosition"
        label-width="175px"
      >
        <h5>Configuration</h5>
        <vel-form-item :label="$t('salesItemsEdit.form.labels.id')" prop="id">
          <vel-input
            style="max-width: 120px;"
            v-model="formValue.remoteId"
            readonly
            aria-readonly="true"
            disabled
          ></vel-input>
        </vel-form-item>
        <vel-form-item :label="$t('salesItemsEdit.form.labels.name')" prop="name">
          <vel-input maxlength="34" v-model="formValue.name"></vel-input>
        </vel-form-item>
        <vel-form-item :label="$t('salesItemsEdit.form.labels.alternativeName')" prop="alternativeName">
          <vel-input maxlength="34" v-model="formValue.alternativeName"></vel-input>
        </vel-form-item>
        <vel-form-item prop="divisionId" :label="$t('salesItemsEdit.form.labels.divisionId')">
          <vel-select v-model="formValue.divisionId" :disabled="false">
            <vel-option
              v-for="division in divisions"
              :label="division.name"
              :value="division.id"
              :key="division.id"
            ></vel-option>
          </vel-select>
        </vel-form-item>
        <vel-form-item prop="itemType" :label="$t('salesItemsEdit.form.labels.itemType')">
          <vel-select v-model="formValue.itemType" :disabled="false">
            <vel-option
              v-for="(division, index) in $t('items.types')"
              :label="division"
              :value="Number(index)"
              :key="Number(index)"
            ></vel-option>
          </vel-select>
        </vel-form-item>
        <vel-form-item prop="regularPrice" :label="$t('salesItemsEdit.form.labels.regularPrice')">
          <el-input-number
            style="max-width: 120px;"
            :controls="false"
            :precision="2"
            :max="100000"
            :min="-9999.99"
            v-model="formValue.regularPrice"
            @change="onPriceChange()"
          ></el-input-number>
          <vel-checkbox
            style="margin-left: 10px;"
            :label="$t('salesItemsEdit.form.labels.copyIfChange')"
            v-model="formValue.copyIfChange"
            @change="enableCopyChange = false"
          ></vel-checkbox>
        </vel-form-item>

        <vel-form-item prop="isAvailable" :label="$t('salesItemsEdit.form.labels.isAvailable')">
          <vel-switch v-model="formValue.isAvailable" />
        </vel-form-item>
        <sales-items-avaibility-per-mode-form
          :schedulesValue="schedulesValue"
          @selectAll="selectAll"
          @updateSchedule="updateSchedule"
        ></sales-items-avaibility-per-mode-form>

        <sales-items-prices-per-mode-form :pricesData="pricesData"></sales-items-prices-per-mode-form>
        <div style="margin-top: 1rem;">
          <vel-button type="primary" class="actions__submit" :loading="pending" @click="handleSubmit()">
            {{ $t('defaults.actions.save') }}
          </vel-button>

          <vel-button
            style="margin-left: 1rem;"
            type="secondary"
            class="actions__cancel"
            :loading="pending"
            @click="handleCancel()"
          >
            {{ $t('defaults.actions.cancel') }}
          </vel-button>
        </div>
      </vel-form>
    </vel-card>
  </vel-page>
</template>

<script>
import { hasWeekdayEnabled, toggleWeekDayFromSchedule } from '@/helpers/date.helpers';
import DeviceMixin from '@/mixins/device-mixin';
import { InputNumber } from 'element-ui';
import SalesItemsAvaibilityPerModeForm from './components/SalesItemsAvaibilityPerModeForm';
import SalesItemsPricesPerModeForm from './components/SalesItemsPricesPerModeForm';
import VelButton from '@/components/button/VelButton';
import VelCard from '@/components/card/VelCard';
import VelCheckbox from '@/components/checkbox/VelCheckbox';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelInput from '@/components/x-input/VelInput';
import VelOption from '@/components/x-option/VelOption';
import VelPage from '@/components/page/VelPage';
import VelSelect from '@/components/x-select/VelSelect';
import VelSwitch from '@/components/switch/VelSwitch';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataItemsPricesItem } from '@/stores/data/items/prices-item.module';
import { useDataItemsSchedulesItem } from '@/stores/data/items/schedules-item.module';
import { useDataDivisions } from '@/stores/data/divisions.module';
import { useDataItems } from '@/stores/data/items.module';

export default {
  name: 'sales-items-edit-page',
  components: {
    VelCard,
    VelButton,
    VelPage,
    VelForm,
    VelFormItem,
    VelSelect,
    VelInput,
    VelOption,
    VelSwitch,
    VelCheckbox,
    SalesItemsAvaibilityPerModeForm,
    SalesItemsPricesPerModeForm,
    'el-input-number': InputNumber
  },
  mixins: [DeviceMixin],
  methods: {
    ...mapPiniaActions(useDataItems, ['setSelected', 'getItemById', 'updateItemById']),
    ...mapPiniaActions(useDataItemsSchedulesItem, ['getSchedulesItem', 'updateItemScheduleById', 'selectAll']),
    ...mapPiniaActions(useDataItemsPricesItem, ['getPricesItem', 'updatePricesItem']),
    ...mapPiniaActions(useDataDivisions, ['getDivisions']),
    async handleSubmit() {
      const prices = [];
      this.pricesData.forEach(p => {
        p.prices.forEach(price => {
          prices.push({
            id: !isNaN(p.id) ? null : price.itemPriceId,
            price: price.price,
            modeId: p.mode.id,
            priceLevelId: price.id,
            licenceNumber: this.formValue.licenceNumber
          });
        });
      });

      await this.updateItemScheduleById({
        itemId: this.formValue.id,
        schedules: (this.schedulesValue || []).map(s => ({
          id: s.id,
          licenceNumber: this.formValue.licenceNumber,
          modeId: s.mode.id,
          schedule: s.schedule
        }))
      });
      await this.updatePricesItem({
        itemId: this.formValue.id,
        prices
      });
      await this.updateItemById(this.formValue);
      await this.$router.replace({ name: 'salesItems', query: this.$route.query });
    },
    handleCancel() {
      this.$router.replace({ name: 'salesItems', query: this.$route.query });
    },
    hasWeekdayEnabled,
    isChecked(schedule, weekDay) {
      return !!hasWeekdayEnabled(schedule, weekDay);
    },
    onPriceChange() {
      if (this.formValue.copyIfChange) {
        this.enableCopyChange = true;

        (this.pricesData || []).forEach((v, k) => {
          this.pricesData[k].prices.forEach((v2, k2) => {
            this.pricesData[k].prices[k2].price = Number(this.formValue.regularPrice);
          });
        });
      }
    },
    updateSchedule(schedule, index, weekDay) {
      this.schedulesValue[index] = {
        ...this.schedulesValue[index],
        schedule: toggleWeekDayFromSchedule(this.schedulesValue[index].schedule, weekDay)
      };
    }
  },
  computed: {
    ...mapPiniaState(useDataItems, ['selectSelected', 'loading', 'pending']),
    ...mapPiniaState(useDataItemsSchedulesItem, {
      schedules: 'schedules',
      totalSchedules: 'total',
      isLoadingSchedules: 'loading'
    }),
    ...mapPiniaState(useDataItemsPricesItem, {
      prices: 'prices',
      totalPrices: 'total',
      isLoadingPrices: 'loading'
    }),
    ...mapPiniaState(useDataDivisions, ['divisions']),
    labelPosition() {
      return this.isMediumDown ? 'top' : 'left';
    },
    pricesData() {
      return Object.values(
        (this.prices || []).reduce((a, b) => {
          if (b && b.mode) {
            if (!a[b.mode.id]) {
              a[b.mode.id] = { ...b, prices: [{ ...b.priceLevel, price: b.price, itemPriceId: b.id }] };
            } else {
              a[b.mode.id].prices.push({ ...b.priceLevel, price: b.price, itemPriceId: b.id });
            }
          }
          return a;
        }, {})
      );
    }
  },
  data() {
    return {
      isSelectAll: null,
      formValue: {
        id: '',
        remoteId: '',
        name: '',
        alternativeName: '',
        itemType: '',
        divisionId: '',
        regularPrice: 0,
        copyIfChange: false,
        plu1: '',
        plu2: '',
        kind: null,
        licenceNumber: 0,
        isActive: false,
        isAvailable: false
      },
      schedulesValue: [],
      globalPrice: 10,
      enableCopyChange: false,
      priceValues: {}
    };
  },
  watch: {
    schedules() {
      this.schedulesValue = this.schedules;
    },
    selectSelected(newValue) {
      if (newValue) {
        this.formValue = {
          id: newValue.id,
          schedule: newValue.schedule,
          remoteId: newValue.remoteId - 1,
          name: newValue.name,
          alternativeName: newValue.alternativeName,
          itemType: newValue.itemType,
          divisionId: newValue.division.id,
          regularPrice: newValue.regularPrice,
          copyIfChange: !!newValue.copyIfChange,
          plu1: newValue.plu1,
          plu2: newValue.plu2,
          kind: newValue.kind,
          licenceNumber: newValue.licenceNumber,
          isActive: !!newValue.isActive,
          isAvailable: !!newValue.isAvailable
        };
      }
    }
  },
  async mounted() {
    await this.getDivisions();
    await this.getItemById(this.$route.params.id);
    await this.getSchedulesItem(this.$route.params.id);
    await this.getPricesItem(this.$route.params.id);
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

.sales-items-edit-page {
  .select-all-actions {
    max-width: 660px;
    text-align: right;

    a {
      display: inline-block;
      font-size: 12px;

      &:last-child {
        margin-left: 1rem;
      }
    }
  }

  .el-form-item {
    margin-bottom: 5px;
    @include breakpoint(medium) {
      margin-bottom: 20px;
    }
  }

  input[type='text'] {
    max-width: 360px;
  }

  h5 {
    margin-bottom: 2rem;
  }

  /deep/ {
    .el-input-number .el-input__inner {
      text-align: right;
    }

    .el-input-number--small {
      width: 100%;
      @include breakpoint(medium) {
        width: 100%;
      }
    }

    .el-form-item__content {
      max-width: 660px;
    }

    .el-form--label-top .el-form-item__label {
      padding: 0 0.3125rem;
    }
  }
}
</style>
11eb17af-72b3-5ae4-bd85-0242ac13000 11eb17af-72b3-5ac4-bd85-0242ac13000
