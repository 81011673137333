import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { today } from '@/helpers/date.helpers';
import router from '@/router';
import { deallocateRequest, requestAllocated } from '@/helpers/service.helper';
import salesService from '@/services/sales.service';
import uniq from 'lodash.uniq';
const uniqueCurrencies = ({ itemsSales, previousItemsSales }) =>
  uniq([...itemsSales.map(ls => ls.currency), ...previousItemsSales.map(ls => ls.currency)]);

export const useDataSalesProducts = defineStore('salesProducts', {
  state: () => ({
    itemsSales: [],
    previousItemsSales: [],
    status: { isLoading: true },
    error: null
  }),
  getters: {
    isItemSalesLoading: state => state.status.isLoading,
    getItemsSalesCurrencies: state => uniqueCurrencies(state),
    getPreviousItemsSales: state => state.previousItemsSales,
    hasItemsSales: state => state.itemsSales.length,
    uniqueCurrencies: state => uniqueCurrencies(state)
  },
  actions: {
    async getItemsSales(payload = {}) {
      const toDay = today().startOf('day').toJSDate();
      const from = router.currentRoute.query.from || toDay;
      const to = router.currentRoute.query.to || from || toDay;

      const routeQuery = {
        ...router.currentRoute.query,
        from,
        to,
        groupByName: true,
        ...payload
      };

      if (!requestAllocated(routeQuery)) {
        this.getItemsSalesRequest();

        try {
          const currentItemsSales = await salesService.getTop10ItemsSales(getToken(), routeQuery);

          this.getItemsSalesSuccess({ currentItemsSales, previousItemsSales: [] });
          deallocateRequest(routeQuery);
        } catch (error) {
          this.getItemsSalesFailure(error);
        }
      }
    },
    getItemsSalesRequest() {
      this.status = { isLoading: true };
      this.itemsSales = [];
      this.previousItemsSales = [];
      this.error = null;
    },
    getItemsSalesSuccess({ currentItemsSales, previousItemsSales }) {
      this.itemsSales = currentItemsSales || [];
      this.previousItemsSales = previousItemsSales || [];
      this.error = null;
      this.status = { isLoading: false };
    },
    getItemsSalesFailure(error) {
      this.status = { isLoading: false };
      this.itemsSales = [];
      this.previousItemsSales = [];
      this.error = error;
    }
  }
});
