const formatDiscounts = (discounts, salesAmount) =>
  (discounts || []).map(discount => ({
    discountsAmount: Number(discount.discountsAmount.toFixed(2)),
    salesDiscountsOrigin: Number(discount.salesDiscountsOrigin.toFixed(2)),
    quantity: discount.quantity,
    name: discount.name,
    percentage: `${((discount.discountsAmount / salesAmount) * 100).toFixed(4)}`,
    currency: discount.currency
  }));

export default {
  formatDiscounts
};
