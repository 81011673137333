<template>
  <vel-sales-report
    :displayedColumns="displayedColumns"
    :data="formatedTree"
    :consolidated="consolidated"
    :locationCount="locationCount"
    :locationTotal="locationTotal"
    :areSalesLoading="areSalesLoading"
    :filterable="false"
    :expandable="multipleLocation && !consolidated && locationCount > 1"
    :external-export="true"
    :is-external-generating="generating"
    @exportXls="exportXLSX"
    class="big-division-sales-report"
  />
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelSalesReport from '@/components/report/VelSalesReport';
import router from '@/router';
import salesService from '@/services/sales.service';

export default {
  name: 'big-division-sales-report',
  components: { VelSalesReport },
  mixins: [DeviceMixin],
  props: {
    sales: {
      type: Array,
      default() {
        return [];
      }
    },
    hiddenColumns: {
      type: Object,
      default() {
        return {};
      }
    },
    areSalesLoading: {
      type: Boolean,
      default: true
    },
    multipleLocation: {
      type: Boolean,
      default: true
    },
    consolidated: {
      type: Boolean,
      default: false
    },
    locationCount: {
      type: Number,
      default: 0
    },
    locationTotal: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      generating: false
    };
  },
  methods: {
    getSalesWithPercent(sales) {
      const totalSales = (sales || []).reduce((result, s) => result + s.salesAmount, 0);
      return (sales || []).map(s => ({
        ...s,
        salesPercent: ((s.salesAmount / totalSales) * 100 || 0).toFixed(2),
        salesOverallPercent: ((s.salesAmount / this.salesOverall) * 100).toFixed(2),
        children: (s.children && this.getSalesWithPercent(s.children)) || null
      }));
    },
    async exportXLSX() {
      this.generating = true;
      const routeQuery = {
        ...router.currentRoute.query
      };
      await salesService.downloadBigDivisionSalesXlsx(routeQuery).catch(() => {
        this.generating = false;
      });

      this.generating = false;
    }
  },
  computed: {
    salesOverall() {
      return (this.sales || []).reduce((cur, i) => cur + i.salesAmount, 0);
    },
    defaultEmptyMessage() {
      return this.$t('defaults.empty');
    },
    displayedColumns() {
      const columns = [];
      if (!this.consolidated && (this.locationCount > 1 || !this.locationCount)) {
        columns.push(this.$t('bigDivision.header.multiLocation.name'));
      } else {
        columns.push(this.$t('bigDivision.header.singleLocation.name'));
      }

      if (this.hiddenColumns.quantity) {
        columns.push(this.$t('bigDivision.header.quantity'));
      } else {
        columns.push(null);
      }

      if (this.hiddenColumns.salesAmount) {
        columns.push(
          this.$t('bigDivision.header.salesAmount', {
            currency: (this.sales && this.sales[0] && this.sales[0].currency) || '$'
          })
        );
      } else {
        columns.push(null);
      }
      if (this.hiddenColumns.salesPercent) {
        columns.push(this.$t('bigDivision.header.salesPercent'));
      } else {
        columns.push(null);
      }
      if (this.hiddenColumns.salesPercentOverall) {
        columns.push(this.$t('bigDivision.header.salesOverallPercent'));
      }

      return columns;
    },
    /**
     * This function removes a level when there's only one location
     */
    formatedTree() {
      if (this.locationCount === 1 || !((this.multipleLocation || this.areSalesLoading) && !this.consolidated)) {
        const tree = JSON.parse(JSON.stringify(this.sales));
        tree.forEach(bigDivision => {
          delete bigDivision.children;
        });
        return this.getSalesWithPercent(tree);
      }

      return this.getSalesWithPercent(this.sales);
    }
  }
};
</script>

<style>
.big-division-sales-report {
  min-width: 800px;
}
</style>
