<template>
  <div v-loading="pending" class="pie-line-chart">
    <h5>{{ formattedTitle }}</h5>
    <div v-if="multiple" class="chart-toolbar">
      <vel-icon
        v-bind:class="{ active: type === 'pie' }"
        @click="type = 'pie'"
        class="chart-toolbar__icon"
        name="pie"
      />
      <vel-icon
        v-bind:class="{ active: type === 'bar' }"
        @click="type = 'bar'"
        class="chart-toolbar__icon"
        name="bar"
      />
      <vel-icon
        v-if="showTable"
        v-bind:class="{ active: type === 'list' }"
        @click="type = 'list'"
        class="chart-toolbar__icon"
        name="table"
      />
    </div>
    <div>
      <div class="chart__toolbar" v-if="getTotal">
        <apexchart
          ref="apex"
          v-if="type === 'bar'"
          type="bar"
          :height="height"
          :options="chartOptions"
          :series="barSeries"
          class="bar"
        />
        <apexchart
          ref="apex"
          v-if="type === 'pie'"
          type="pie"
          :height="height"
          :options="chartOptions"
          :series="pieSeries"
          class="pie"
        />
        <div v-if="type === 'list' && showTable" class="table">
          <el-table width="auto" :data="tableData">
            <el-table-column prop="name" :label="options.xaxis.title.text" sortable />
            <el-table-column
              v-for="(serie, index) in series"
              :prop="index.toString()"
              :label="serie.name"
              width="160"
              :key="serie.name"
              class="right"
              style="text-align: right;"
              :sortable="true"
            >
              <template slot-scope="scope">
                <vel-amount :amount="scope.row[index]" :currency="currency"></vel-amount>
                <!--
                <span class="up">&#11014;</span>
                <span class="down">&#11015;</span>-->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="chart__empty-state" v-else>
        <slot name="empty">{{ textEmpty || $t('pieLineChart.empty') }}</slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import { showChartTooltipOnLegendEvent, showChartTooltipOnLegendHover } from '@/helpers/charts.helpers';
import VelAmount from '@/components/amount/VelAmount';
import VelIcon from '@/components/icon/VelIcon';
import VueApexCharts from 'vue-apexcharts';
import formatMoney from '../../filters/format-money';
import { oc } from 'ts-optchain';
import { mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataConfig } from '@/stores/data/config.module';

export default {
  name: 'pie-line-chart',
  components: {
    apexchart: VueApexCharts,
    VelIcon,
    ElTable: Table,
    ElTableColumn: TableColumn,
    VelAmount
  },
  props: {
    pending: {
      type: Boolean,
      default: true
    },
    force: {
      type: Boolean,
      default: false
    },
    line: {
      type: Boolean,
      default: false
    },
    legend: {
      type: Boolean,
      default: true
    },
    distributed: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    textEmpty: {
      type: String,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    series: {
      type: Array,
      default: null
    },
    labels: {
      type: Array,
      default: null
    },
    height: {
      type: Number,
      default: 330
    },
    limit: {
      type: Number,
      default: 15
    },
    type: {
      type: String,
      default: 'bar'
    },
    currency: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: true
    },
    showTable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getTotal() {
      return (
        !!(this.labels || []).length &&
        !!(this.series && this.series[0] && this.series[0].data && this.series[0].data.length)
      );
    },
    tableData() {
      return (this.labels || []).map((l, i) => {
        const row = {
          name: l
        };
        this.series.forEach((x, b) => {
          row[b] = Number((x.data || [])[i] || 0);
        });
        return row;
      });
    },
    pieSeries() {
      if (typeof this.series[0] !== 'object') {
        return this.series.map(s => ({ ...s, data: s.data.slice(0, this.limit) }));
      }
      return (this.series[0].data || []).map(x => x || 0).slice(0, this.limit);
    },
    barSeries() {
      return this.series.map(s => ({ ...s, data: s.data.slice(0, this.limit) }));
    },
    strokeOptions() {
      if (this.line && this.type !== 'pie') {
        return {
          curve: 'smooth',
          width: [[1]]
        };
      }
      return {};
    },
    fillOptions() {
      if (this.line && this.type !== 'pie') {
        return {
          type: ['fill', 'gradient', 'gradient', 'gradient'],
          opacity: [0.85, 0.65, 0.65, 0.65],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: [1, 0.65],
            opacityTo: [1, 0.25],
            stops: [0, 100]
          }
        };
      }
      return {};
    },
    xaxisTitleOptions() {
      return oc(this.options).xaxis.title({
        text: ''
      });
    },
    yaxisTitleOptions() {
      return oc(this.options).yaxis.title({
        text: ''
      });
    },
    chartOptions() {
      if (this.force) {
        return this.options;
      }
      return {
        chart: {
          events: {
            legendClick: showChartTooltipOnLegendEvent(this.$el)
          },
          toolbar: {
            show: false
          }
        },
        stroke: this.strokeOptions,
        fill: this.fillOptions,
        dataLabels: {
          enabled: this.type === 'pie'
        },
        plotOptions: {
          bar: {
            distributed: this.type === 'pie' ? false : this.distributed
          }
        },
        legend: {
          show: this.type === 'pie' || this.legend,
          onItemClick: {
            toggleDataSeries: true
          },
          onItemHover: {
            toggleDataSeries: true,
            highlightDataSeries: true
          },
          formatter: legendName => {
            showChartTooltipOnLegendHover(this.$el)(this.$refs.apex.chart, this.$refs.apex.chart.w);
            return legendName;
          }
        },
        labels: this.labels.slice(0, this.limit),
        xaxis: {
          title: this.xaxisTitleOptions,
          labels: {
            rotate: -45,
            rotateAlways: true,
            hideOverlappingLabels: false,
            maxHeight: 120,
            trim: true
          }
        },
        yaxis: {
          labels: {
            formatter: val => (!this.currency || this.currency === '' ? val : formatMoney(val, this.currency))
          },
          opposite: false,
          title: this.yaxisTitleOptions
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: true,
          shared: true,
          followCursor: false
        },
        responsive: [
          {
            breakpoint: 1024,
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      };
    },
    ...mapPiniaState(useDataConfig, ['get']),
    formattedTitle() {
      if (
        this.series &&
        this.series[0] &&
        this.series[0].data &&
        this.series[0].data.length >= this.get.charts_size_limitation.value
      ) {
        return this.title;
      }
      return this.title.replace(/\(TOP [0-9]+\)/gi, '');
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$charts-empty-text-color: $shuttle-gray;
$charts-title-text-color: $river-bed;

.pie-line-chart {
  font-family: $base-font-family;

  .chart__empty-state {
    color: $charts-empty-text-color;
  }

  h5 {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
}

.apexcharts-title-text {
  color: $charts-title-text-color;
  font-size: rem-calc(13);
  font-weight: 700;
  text-transform: uppercase;
  @include breakpoint(medium) {
    font-size: rem-calc(15);
  }
}

.apexcharts-xaxistooltip {
  overflow: visible;
}

.apexcharts-xaxistooltip-text {
  /* stylelint-disable */
  min-width: 30px !important;
}

.chart-toolbar {
  position: relative;
  margin-top: -13px;

  @include breakpoint(medium) {
    margin-top: 0;
    position: absolute;
    right: 20px;
    top: 15px;
  }

  &__icon {
    color: rgba(0, 0, 0, 0.3);
    width: 18px !important;
    margin-left: 5px;

    &.active {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.pie-line-chart {
  .table {
    margin-left: -0.75rem;
    margin-right: -0.75rem;

    tr > th {
      text-align: right !important;

      div {
        word-break: break-word !important;
      }
    }

    tr > th:first-child {
      text-align: left !important;
    }
  }
}

.up {
  color: green;
}

.down {
  color: red;
}

.el-table__body {
  table-layout: auto;
}

.el-table__body-wrapper {
  max-height: 240px;
  overflow-y: auto;

  table {
    width: 100% !important;
  }
}

.el-table__row > td {
  text-align: right;
}

.el-table__row > td:first-child {
  text-align: left;
}
</style>
