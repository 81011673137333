<script>
import { DatePicker } from 'element-ui';

export default {
  name: 'vel-datepicker',
  extends: DatePicker
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$picker-border-color: $link-water;
$picker-border-color_focus: $iris-blue;
$picker-cell-line-height: 28px;
$picker-cell-line-height-xsmall: 17px;
$picker-cell-height: 28px;
$picker-cell-height-xsmall: 17px;
$picker-cell-width: 34px;
$picker-cell-width-xsmall: 17px;
$picker-font-color: rgba(black, 0.6);
$picker-day-available-color: $iris-blue;
$picker-day-in-range-background-color: rgba($iris-blue, 0.1);
$picker-day-selected-background-color: $iris-blue;
$picker-day-disabled-background-color: rgba($river-bed, 0.1);
$picker-shadow-color_focus: rgba($maya-blue, 0.5);
$picker-header-font-size-xsmall: 13px;
$picker-header-font-size: 16px;
$picker-shortcut-font-size-xsmall: 10px;
$picker-shortcut-font-size: 14px;

/* stylelint-disable */
.el-date-editor {
  color: $picker-font-color;
  border: 1px solid $picker-border-color;
  height: 38px !important;
  line-height: 38px;

  &.is-active,
  &.is-active:hover {
    border: 1px solid $picker-border-color_focus;
    box-shadow: 0 0 0 3px $picker-shadow-color_focus;
    @include animate;
  }

  .el-range-separator {
    padding: 0 8px 0 5px !important;
  }
}

.el-range__icon,
.el-icon-date,
.el-range__close-icon,
.el-input__icon::after {
  display: none !important;
}

.el-range-input,
.el-range-input:focus {
  width: 45% !important;

  @media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
    font-size: $input-font-size !important;
  }
}

.el-date-range-picker__header,
.el-date-picker__header {
  div {
    font-size: $picker-header-font-size-xsmall !important;
    @include breakpoint(small) {
      font-size: $picker-header-font-size !important;
    }
  }
}

.el-picker-panel__shortcut {
  font-size: $picker-shortcut-font-size-xsmall !important;
  @include breakpoint(small) {
    font-size: $picker-shortcut-font-size !important;
  }
}

.el-picker-panel__icon-btn {
  font-size: $picker-shortcut-font-size !important;
  line-height: 7px !important;
  padding: 5px;
}

:root .el-picker-panel {
  &.el-date-range-picker.has-sidebar {
    width: 300px;

    @include breakpoint(small) {
      width: 368px;
    }

    @include breakpoint(medium) {
      width: 763px;
    }
  }

  .el-picker-panel__sidebar {
    font-size: 14px;
    width: 90px;

    @include breakpoint(small) {
      font-size: 14px;
      width: 110px;
    }
  }

  .el-date-range-picker__content {
    padding: 0;

    &.is-left {
      clear: both;
      float: none;
      padding: 0;

      @include breakpoint(medium) {
        clear: initial;
        float: left;
      }
    }

    .el-date-table th {
      padding: 0;
    }

    .el-date-table td {
      line-height: $picker-cell-height-xsmall;
      height: $picker-cell-height-xsmall;
      padding: 0;
      width: $picker-cell-width-xsmall;

      @include breakpoint(small) {
        line-height: $picker-cell-line-height;
        height: $picker-cell-height;
        width: $picker-cell-width;
      }

      div {
        height: $picker-cell-height-xsmall;
        line-height: $picker-cell-height-xsmall;

        @include breakpoint(small) {
          height: $picker-cell-height;
          line-height: $picker-cell-line-height;
        }

        span {
          height: $picker-cell-height-xsmall;
          line-height: 11px;
          padding: 0;
          width: $picker-cell-width-xsmall;

          @include breakpoint(small) {
            height: $picker-cell-height;
            line-height: 22px;
            width: $picker-cell-width;
          }
        }
      }

      &.available {
        &:hover {
          color: $picker-day-available-color;
        }
      }

      &.in-range {
        div {
          background-color: $picker-day-in-range-background-color;
        }
      }

      &.disabled {
        div {
          background-color: $picker-day-disabled-background-color;
        }
      }

      &.end-date,
      &.start-date {
        div {
          padding: 0;

          span {
            background-color: $picker-day-selected-background-color;
            border-radius: 0;
            height: $picker-cell-height-xsmall;
            line-height: $picker-cell-line-height-xsmall;
            padding: 0;
            width: $picker-cell-width-xsmall;

            @include breakpoint(small) {
              height: $picker-cell-height;
              line-height: $picker-cell-line-height;
              width: $picker-cell-width;
            }
          }
        }
      }
    }
  }

  .el-date-range-picker .el-picker-panel__body {
    min-width: 415px;
    @include breakpoint(small) {
      min-width: 513px;
    }
  }

  .el-picker-panel__sidebar + .el-picker-panel__body {
    margin-left: 90px;
    @include breakpoint(small) {
      margin-left: 110px;
    }
  }
}
/* stylelint-enable */
</style>
