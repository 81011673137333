import * as Sentry from '@sentry/vue';
import URI from 'urijs';
import fetchIntercept from 'fetch-intercept';
import { getToken } from '@/helpers/token.helper';
import md5 from 'md5';
import { pruneToUTC } from '@/helpers/date.helpers';
import { SpanStatus } from '@sentry/tracing';
import * as pascalCase from 'pascalcase';
import { getDateFormatFromUserConfig, getUse24Hour } from '@/helpers/date.helpers';

// const CACHING_TIME_MS = 120 * 10000;

const jsonContentType = `application/json`;
const xlsxContentType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
const pdfContentType = `application/pdf`;
const rawResponses = [xlsxContentType, pdfContentType];

const apiUrl = () => process.env.VUE_APP_API_URL;

const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('config');
};

const pad = (number, size) => number.toString().padStart(size, '0');

const timezoneOffset = offset => {
  if (offset === 0) {
    return 'Z';
  }
  const sign = offset > 0 ? '-' : '+';
  const absOffset = Math.abs(offset);
  return `${sign + pad(Math.floor(absOffset / 60), 2)}:${pad(absOffset % 60, 2)}`;
};

const getClientTime = () => {
  const d = new Date();
  const year = d.getFullYear();
  const month = pad(d.getMonth() + 1, 2);
  const day = pad(d.getDate(), 2);
  const hours = pad(d.getHours(), 2);
  const minutes = pad(d.getMinutes(), 2);
  const seconds = pad(d.getSeconds(), 2);

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneOffset(d.getTimezoneOffset())}`;
};

const handleResponse = async response => {
  if ([202, 204].includes(response.status)) {
    return { status: response.status };
  }
  if (!response.ok) {
    if (response.status === 401 && localStorage.getItem('user')) {
      // auto logout if 401 response returned from api
      logout();
      window.location.reload();
    }
    if (response.status === 409) {
      let error = await response.text();
      try {
        error = JSON.parse(error);
      } catch {}
      return Promise.reject(error);
    }

    if (!response) {
      return {};
    }
    console.log('Aaaaa', response.text, response);
    const text = await response.text();

    return Promise.reject({
      statusCode: response.status,
      statusText: response.statusText,
      text: text || ''
    });
  }

  return response;
};

const get = async (token, url, { handleCustomErrors = x => x } = {}, contentType = jsonContentType) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${token}`
    }
  };

  if (contentType === xlsxContentType || contentType === pdfContentType) {
    requestOptions.headers['Velcloud-ClientTime'] = getClientTime();
    requestOptions.headers['Velcloud-DateFormat'] = getDateFormatFromUserConfig();
    requestOptions.headers['Velcloud-Use24Hour'] = getUse24Hour();
  }

  const urlInfo = new URL(`${apiUrl()}${url}`);
  const name = `get${pascalCase(urlInfo.pathname)}`;
  const transaction = Sentry.startTransaction({ name, op: 'http.client' });

  try {
    const result = await fetch(`${apiUrl()}${url}`, requestOptions)
      .then(r => {
        transaction.setTag('http.status_code', r.status);
        transaction.setTag('http.method', 'get');
        return r;
      })
      .then(handleCustomErrors)
      .then(handleResponse)
      .then(response => {
        if (rawResponses.includes(contentType)) {
          return response.blob();
        }
        if (!response || !response.text) {
          return {};
        }

        return response.json();
      });

    transaction.setStatus(SpanStatus.Ok);

    return result;
  } catch (e) {
    transaction.setStatus(SpanStatus.UnknownError);

    throw e;
  } finally {
    transaction.finish();
  }
};

const post = (token, url, body, { handleCustomErrors = x => x } = {}) => {
  const headers: any = {
    'Content-Type': jsonContentType
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': jsonContentType,
      Authorization: `Bearer ${token}`
    },
    body: (body && JSON.stringify(body)) || null
  };

  return fetch(`${apiUrl()}${url}`, requestOptions)
    .then(handleCustomErrors)
    .then(handleResponse)
    .then(response => {
      if (!response || !response.text) {
        return {};
      }

      return response.json();
    });
};

const upload = (token, url, body, { handleCustomErrors = x => x } = {}) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    },
    body
  };

  return fetch(`${apiUrl()}${url}`, requestOptions)
    .then(handleCustomErrors)
    .then(handleResponse)
    .then(response => {
      if (!response || !response.text) {
        return {};
      }

      return response.json();
    });
};

const downloadXlsxFile = (token, url, filename) =>
  get(token, url, {}, xlsxContentType).then(data => {
    if (data.type !== xlsxContentType) {
      throw new Error('Invalid content type');
    }

    const xlsxUrl = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = xlsxUrl;
    a.download = filename || 'export.xlsx';
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();
  });

const downloadPdfFile = (token, url, filename) =>
  get(token, url, {}, pdfContentType).then(data => {
    if (data.type !== pdfContentType) {
      throw new Error('Invalid content type');
    }

    const xlsxUrl = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = xlsxUrl;
    a.download = filename || 'export.pdf';
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();
  });

const put = (token, url, body, { handleCustomErrors = x => x } = {}) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': jsonContentType,
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  };

  return fetch(`${apiUrl()}${url}`, requestOptions)
    .then(handleCustomErrors)
    .then(handleResponse)
    .then(response => {
      if (!response || !response.text) {
        return {};
      }

      return response.json();
    });
};

const patch = (token, url, body, { handleCustomErrors = x => x } = {}) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': jsonContentType,
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  };

  return fetch(`${apiUrl()}${url}`, requestOptions)
    .then(handleCustomErrors)
    .then(handleResponse)
    .then(response => {
      if (!response || !response.text) {
        return {};
      }

      return response.json();
    });
};

const head = url => {
  const requestOptions = {
    method: 'HEAD',
    headers: {
      'Content-Type': jsonContentType
    }
  };

  return fetch(`${apiUrl()}${url}`, requestOptions)
    .then(handleResponse)
    .then(response => {
      if (!response || !response.text) {
        return {};
      }

      return response.json();
    });
};

const remove = (token, url, body = {}, { handleCustomErrors = x => x } = {}) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': jsonContentType,
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  };

  return fetch(`${apiUrl()}${url}`, requestOptions)
    .then(handleCustomErrors)
    .then(handleResponse)
    .then(response => {
      if (!response || !response.text) {
        return {};
      }

      return response.json();
    });
};
// @todo to be refactored to reduce cognitive complexity
const resolveCommonQuery = (queryPath, query) => {
  const uri = URI(queryPath);

  if (query) {
    if (query.from) {
      uri.addQuery({ from: pruneToUTC(query.from) });
    }

    if (query.currencyId) {
      uri.addQuery({ currency: query.currencyId });
    }

    if (query.day) {
      uri.addQuery({ day: pruneToUTC(query.day) });
    }

    if (query.to) {
      uri.addQuery({ to: pruneToUTC(query.to, true) });
    }

    if (
      query.locationIds &&
      query.locationIds !== '' &&
      (typeof query.locationIds === 'string' || (query.locationIds || []).filter(x => x !== '').length !== 0)
    ) {
      uri.addQuery({ locationIDs: query.locationIds });
    } else {
      if (query.groupId && (!query.locationId || query.locationId === '')) {
        uri.addQuery({ groupIDs: query.groupId, groupID: query.groupId });
      }
      if (query.locationId) {
        uri.addQuery({ locationIDs: query.locationId, locationID: query.locationId });
      }
    }

    [
      'offset',
      'isGlobal',
      'currency',
      'limit',
      'pagination',
      'sort',
      'order',
      'search',
      'include',
      'userIDs',
      'useAccountingTime',
      'timeAggregation',
      'groupByName',
      'licenceNumber',
      'detailedTaxes',
      'invoiceNumber',
      'isActive',
      'kind',
      'tenderIDs',
      'departmentIDs',
      'workstationIDs',
      'discountIDs',
      'tenderTypeIDs',
      'employeeName',
      'employeeNumber',
      'invoiceNumberRange',
      'lang',
      'name',
      'version',
      'status',
      'posType',
      'consolidated',
      'groupByDate'
    ].forEach(v => {
      if (query[v] || query[v] === 0) {
        uri.addQuery({ [v]: query[v] });
      }
    });
  }
  return uri;
};

const cacheCollection = {};

export const getCacheKeyByQuery = query => md5(JSON.stringify(query)) + getToken();

// eslint-disable-next-line no-unused-vars
export const requestAllocated = (query, collection = cacheCollection) =>
  // @todo: To be fixed. Reponse is not refresh when a previous cache exists
  false;
// const checksum = getCacheKeyByQuery(query);
// return Boolean(collection[checksum] && collection[checksum] + CACHING_TIME_MS > Date.now());

export const deallocateRequest = (query, collection = cacheCollection) => {
  const checksum = getCacheKeyByQuery(query);
  collection[checksum] = Date.now();
};

// @todo add ability to test fetchIntercept
fetchIntercept.register({
  /* istanbul ignore next */
  request(url, config) {
    return [url, config];
  },
  /* istanbul ignore next */
  requestError(error) {
    return Promise.reject(error);
  },
  /* istanbul ignore next */
  response(response) {
    // eslint-disable-next-line no-unused-vars
    // const uri = URI(response.url);

    // @todo: To be fixed. Reponse is not refresh when a previous cache exists
    // if (uri.hasQuery('cache')) {
    //  cacheCollection[(uri.query(true) as any).cache] = Date.now();
    // }
    return response;
  },
  /* istanbul ignore next */
  responseError(error) {
    return Promise.reject(error);
  }
});

export {
  apiUrl,
  downloadPdfFile,
  downloadXlsxFile,
  get,
  head,
  post,
  upload,
  patch,
  put,
  remove,
  logout,
  handleResponse,
  resolveCommonQuery
};
