import { render, staticRenderFns } from "./VelTableExport.vue?vue&type=template&id=4141d860&scoped=true&"
import script from "./VelTableExport.vue?vue&type=script&lang=js&"
export * from "./VelTableExport.vue?vue&type=script&lang=js&"
import style0 from "./VelTableExport.vue?vue&type=style&index=0&id=4141d860&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4141d860",
  null
  
)

export default component.exports