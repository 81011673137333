<template>
  <button
    class="vel-button"
    :type="htmlType"
    :disabled="isDisabled"
    :aria-disabled="isDisabled"
    :class="classes"
    @click="handleClick"
  >
    <span class="vel-button__loader" v-if="isLoading">
      <vel-spinner size="small" :modifier="modifier" />
    </span>
    <span class="vel-button__icon" :class="{ 'vel-button__icon_with-text': hasText }" v-if="hasIcon">
      <vel-icon class="vel-button__picto" :modifier="modifier" :name="icon" />
    </span>
    <span class="vel-button__text" v-if="hasText">
      <slot />
    </span>
  </button>
</template>

<script>
import VelIcon from '../icon/VelIcon';
import VelSpinner from '../spinner/VelSpinner';

export default {
  name: 'vel-button',
  components: {
    VelIcon,
    VelSpinner
  },
  props: {
    type: {
      type: String,
      default: 'primary',
      validate(value) {
        return ['primary', 'secondary', 'critical', 'link'].includes(value);
      }
    },
    htmlType: {
      type: String,
      default: 'button',
      validate(value) {
        return ['button', 'submit', 'reset'].includes(value);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    wide: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    ripple: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasText() {
      return Boolean(this.$slots.default);
    },
    hasIcon() {
      return this.icon && this.icon.length > 0;
    },
    isLoading() {
      return this.loading && !this.disabled;
    },
    isDisabled() {
      return this.disabled || this.loading;
    },
    hasRipple() {
      return !this.disabled && this.ripple;
    },
    classes() {
      const { name } = this.$options;
      return [
        `${name}_${this.type}`,
        {
          [`${name}_wide`]: this.wide,
          [`${name}_loading`]: this.isLoading,
          [`${name}_disabled`]: this.disabled,
          [`${name}_icon`]: this.hasIcon,
          [`${name}_ripple`]: this.hasRipple,
          [`${name}_ripple_${this.type}`]: this.hasRipple
        }
      ];
    },
    modifier() {
      return ['link'].includes(this.type) ? 'on-bright' : 'on-dark';
    }
  },
  methods: {
    handleClick(event) {
      this.$emit('click', event);
    }
  }
};
</script>

<style lang="scss" src="./VelButton.scss" />
