<template>
  <div class="reset-password-page">
    <div class="reset-password-content__wrapper">
      <div class="reset-password-form-container" v-if="!status.isLoading">
        <reset-password-form class="reset-password-form" v-if="status.isValid" :token="token" />
        <div v-else>
          <Alert type="error">{{ $t('resetPassword.form.tokenValidityError.text') }}</Alert>
          <router-link to="/">{{ $t('resetPassword.form.linkToLoginPage.text') }}</router-link>
        </div>
        <div class="reset-password-footer">
          <vel-button type="link" @click="toggleLanguage">
            <span>
              <vel-icon name="globe-solid" size="small" />
              {{ $t('resetPassword.switchLanguage') }}
            </span>
          </vel-button>
          <h6>{{ $t('rightsReserved', { currentYear }) }}</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { Alert } from 'iview';
import LanguageMixin from '@/mixins/language-mixin';
import Navigate from '../../navigation';
import ResetPasswordForm from './components/ResetPasswordForm.vue';
import VelButton from '@/components/button/VelButton';
import VelIcon from '@/components/icon/VelIcon';
import { useDataUsersResetPassword } from '@/stores/data/users/reset-password.module';

export default {
  name: 'ResetPasswordPage',
  mixins: [LanguageMixin],
  components: {
    'reset-password-form': ResetPasswordForm,
    Alert,
    VelButton,
    VelIcon
  },
  props: {
    token: {
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapActions(useDataUsersResetPassword, ['resetPassword', 'verifyToken'])
  },
  computed: {
    ...mapState(useDataUsersResetPassword, ['status']),
    currentYear() {
      return new Date().getFullYear();
    }
  },
  created() {
    if (!this.token) {
      Navigate.toLogin();
    }
    this.verifyToken(this.token);
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.reset-password-page {
  @include breakpoint(large) {
    background-attachment: fixed;
    background-image: url('./login-backgroung.jpg');
    background-position: right 0% bottom 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
  }

  .reset-password-content__wrapper {
    align-items: center;
    background-color: $wild-sand;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    padding: rem-calc(100);
    @include breakpoint(large) {
      opacity: 0.96;
      width: 40%;
    }

    .reset-password-footer {
      margin-top: rem-calc(50);
      text-align: center;
    }

    .vel-button {
      font-size: rem-calc(13);
      height: rem-calc(25);
      width: 100%;
      @include noPrint;

      &:hover {
        background-color: $wild-sand;
      }

      /* stylelint-disable */
      .vel-icon_small {
        height: rem-calc(10);
        width: rem-calc(10);
      }
      /* stylelint-enable */
    }

    .vel-icon_small {
      height: rem-calc(10);
      width: rem-calc(10);
    }

    h6 {
      padding-top: rem-calc(100);
      @include breakpoint(xsmall) {
        margin: 0;
        padding-top: 0;
      }
    }

    @mixin breakpoint {
      height: 100vh;
      justify-content: flex-start;
    }

    @media screen and (max-width: map-get($breakpoints, 'medium')) {
      @include breakpoint;
    }

    @media (min-width: map-get($breakpoints, 'medium')) and (max-width: map-get($breakpoints, 'large')) {
      @include breakpoint;
    }

    @media screen and (orientation: landscape) {
      height: 180vh;
    }

    @media (min-width: map-get($breakpoints, 'large')) {
      height: 100vh;
    }
  }

  @media (min-width: map-get($breakpoints, 'medium')), screen and (orientation: landscape) {
    overflow-y: scroll;
  }

  @media (min-width: map-get($breakpoints, 'large')) {
    overflow-y: hidden;
  }
}
</style>
