<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="600px"
    right
    id="timeAttendance-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarTimeAttendance"
  >
    <template #header="{ hide }">
      <div class="bg-gray-200 bg-light w-100">
        <b-button variant="outline-primary" @click="hide">
          <b-icon icon="chevron-compact-left" aria-hidden="true"></b-icon>
          {{ $t('timeAttendances.back') }}
        </b-button>
      </div>
    </template>

    <template #footer="{ hide }">
      <div class="d-flex bg-light text-dark align-items-center px-3 py-2" style="border-top: 2px solid #d6d6d8;">
        <b-button variant="primary" @click="hide">
          {{ $t('timeAttendances.close') }}
        </b-button>
      </div>
    </template>
    <b-container fluid class="p-4" v-loading="pending">
      <div class="d-flex justify-content-between align-content-between">
        <h5>{{ $t('timeAttendances.list.title') }}</h5>
        <b-button variant="primary" v-b-toggle.timeAttendanceForm-sidebar>
          <b-icon icon="plus"></b-icon>
          {{ $t('timeAttendances.add.btn') }}
        </b-button>
      </div>
      <time-attendance-list
        :time-attendances="timeAttendances"
        @onModify="openEditTimeAttendance($event)"
        @onDelete="$emit('onDelete', $event)"
      ></time-attendance-list>
    </b-container>
  </b-sidebar>
</template>
<script>
import TimeAttendanceList from '@/private/components/TimeAttendance/components/TimeAttendanceList';

export default {
  name: 'employee-sidebar-time-attendance',
  components: {
    TimeAttendanceList
  },
  props: {
    timeAttendance: {
      type: Object,
      default: () => ({ name: '' })
    },
    timeAttendances: {
      type: Array,
      default: () => []
    },
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  computed: {},
  methods: {
    openEditTimeAttendance(timeAttendance) {
      this.$emit('update:timeAttendance', timeAttendance.item);
    }
  }
};
</script>
