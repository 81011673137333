<template>
  <b-container style="border-bottom: 8px solid #f5f5f5;" fluid>
    <b-row style="border-bottom: 1px solid #4c566733;" class="py-3">
      <b-col md="3" style="display: flex; flex-direction: row;">
        <div class="title-icon d-none">
          <b-icon icon="pencil-fill"></b-icon>
        </div>
        <div style="padding-left: 1rem;">
          <h4 class="title-right my-0">
            {{ (disocunt.name !== '' && disocunt.name) || '--' }}
          </h4>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'discount-header',
  components: {},
  props: {
    disocunt: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    submited: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {}
};
</script>
<style lang="scss" scoped></style>
