<template>
  <b-sidebar
    header-class="bg-light"
    bg-variant="white"
    width="600px"
    right
    id="tipDistributionEdit-sidebar"
    title="Sidebar"
    shadow
    backdrop
    ref="sidebarTipDistributionEdit"
  >
    <template #header>
      <h6 class="bg-gray-200 bg-light w-100 mb-0 text-center font-weight-bold">
        {{ $t('employeeCreate.tipDistribution.title') }}
      </h6>
    </template>

    <template #footer="{ hide }">
      <div
        class="d-flex bg-light text-dark align-items-center justify-content-between px-3 py-2"
        style="border-top: 2px solid #d6d6d8;"
      >
        <b-button
          :disabled="!($refs.form && $refs.form.enableSaveChanges)"
          variant="primary"
          @click="updateTipDistribution()"
        >
          {{ $t('employeeCreate.btns.saveChanges') }}
        </b-button>
        <b-button variant="outline-primary" @click="hide">{{ $t('employeeCreate.btns.cancel') }}</b-button>
      </div>
    </template>
    <b-container fluid class="p-4" v-loading="pending">
      <tip-distribution-form
        :licenceNumber="employee.licenceNumber"
        :tip-distribution.sync="tipDistribution"
        ref="form"
      ></tip-distribution-form>
    </b-container>
  </b-sidebar>
</template>
<script>
import TipDistributionForm from '@/private/components/tip-distribution/components/TipDistributionForm';
import { patchEmployeeTipDistribution } from '@/services/employees.service';

export default {
  name: 'employee-sidebar-edit-tip-distribution',
  components: {
    TipDistributionForm
  },
  props: {
    tipDistribution: {
      type: Object,
      default: () => ({ name: '' })
    },
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    licenceNumber: {
      type: Number,
      default: 0
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    employeeTipDistribution() {
      return {
        id: this.tipDistribution && this.tipDistribution.id,
        name: this.$refs.form.name,
        licenceNumber: this.licenceNumber || this.employee.licenceNumber || null,
        remoteId: this.tipDistribution && this.tipDistribution.remoteId
      };
    }
  },
  methods: {
    async updateTipDistribution() {
      try {
        this.$emit('update:pending', true);
        await patchEmployeeTipDistribution(this.employeeTipDistribution.id, this.employeeTipDistribution);
        this.$emit('update:pending', false);
        this.$emit('success', this.employeeTipDistribution);
        this.$refs.sidebarTipDistributionEdit.hide();
      } catch (e) {
        this.$emit('update:error', true);
        this.$emit('update:pending', false);
        this.$emit('failure', this.employeeTipDistribution);
      }
    }
  }
};
</script>
