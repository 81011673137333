<template>
  <pie-line-chart
    :pending="areSalesLoading"
    :options="chartOptions"
    :series="series"
    :labels="labels"
    :currency="currencyId"
    :line="true"
    :multiple="false"
    :title="$t('dashboard.salesPerHourChart.title')"
    class="hourly-sales__chart"
  />
</template>

<script>
import { DateTime } from 'luxon';
import PieLineChart from '@/components/pie-line-chart/PieLineChart';
import { convertMilitaryTimeLabelToMeridiem, getDateFormatFromUserConfig } from '@/helpers/date.helpers';
import { oc } from 'ts-optchain';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useUIDateCompare } from '@/stores/ui/date-compare.module';
import { useDataSalesHourly } from '@/stores/data/sales/hourly-sales.module';
import { useDataConfig } from '@/stores/data/config.module';

export default {
  name: 'hourly-sales__chart',
  components: {
    PieLineChart
  },
  props: {
    currencyId: {
      type: String,
      default: null
    },
    from: {
      type: String,
      default: null
    },
    to: {
      type: String,
      default: null
    },
    height: {
      type: Number,
      default: 300
    }
  },
  computed: {
    ...mapPiniaState(useDataSalesHourly, {
      areSalesLoading: 'areSalesLoading',
      hs: 'hourlySales',
      hourlySales: 'hourlySalesGroupedByCurrencyAndLocalTime',
      previousWeekHourlySales: 'previousWeekHourlySalesGrouped',
      uniqueHours: 'uniqueHours',
      uniqueCurrencies: 'uniqueCurrencies'
    }),
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useUIDateCompare, ['getDateCompareSelectorSelected']),
    areSalesAvailable() {
      return this.hs[0] && this.hs[0].length;
    },
    chartOptions() {
      return {
        fill: {
          type: [['gradient', 'gradient', 'gradient', 'gradient']],
          opacity: [[0.85, 0.65, 0.65, 0.65]],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: [[0.0, 0, 0, 0]],
            opacityTo: [[0.25, 0.25, 0.25, 0.25]],
            stops: [[100, 100, 100, 100]]
          }
        },
        xaxis: {
          title: {
            text: this.$t('dashboard.salesPerHourChart.xaxis')
          }
        },
        yaxis: {
          title: {
            text: this.$t('dashboard.salesPerHourChart.yaxis')
          }
        }
      };
    },
    labels() {
      return this.extractHourfromHourWeekdayString();
    },
    series() {
      const currentDaySales = {
        name: this.$t(`dashboard.salesPerHourChart.sales`, {
          currency: this.currencyId,
          period: this.$t(`graphs.series.single.current`, {
            from: DateTime.fromISO(this.from).toFormat(getDateFormatFromUserConfig())
          })
        }),
        type: 'column',
        data: this.getCurrentDaySales(0)
      };

      if (!this.areSalesAvailable) {
        return [{ ...currentDaySales, data: [] }];
      }

      return [currentDaySales, this.getPreviousDaySales(1), this.getPreviousDaySales(2), this.getPreviousDaySales(3)];
    },
    periods() {
      const startDate = DateTime.fromISO(this.from);

      return [
        {
          day: startDate.minus({ days: 7 }).toJSDate()
        },
        {
          day: startDate.minus({ days: 28 }).toJSDate()
        },
        {
          day: startDate.minus({ days: 364 }).toJSDate()
        }
      ];
    }
  },
  methods: {
    ...mapPiniaActions(useDataSalesHourly, ['getSalesHourly']),
    extractHourfromHourWeekdayString() {
      const uniqueHours = this.uniqueHours.map(hour => hour.split('-')[1]);

      return uniqueHours.map(x =>
        DateTime.now()
          .set({ hour: Number(x.substr(0, 2)), minute: 0 })
          .toFormat(this.get.enable_military_time_format.value ? 'T' : 't')
      );
    },
    getPreviousDaySales(index = 1) {
      const comparedPeriod = {
        1: 'week',
        2: 'month',
        3: 'year'
      };

      return {
        name: this.$t('dashboard.salesPerHourChart.previousSales', {
          currency: this.currencyId,
          period: this.$t(`graphs.series.single.${comparedPeriod[index]}`, {
            from: DateTime.fromJSDate(this.periods[index - 1].day).toFormat(getDateFormatFromUserConfig())
          })
        }),
        type: 'area',
        data: this.getCurrentDaySales(index)
      };
    },
    getCurrentDaySales(index = 0) {
      return Array.from(this.hourlySales[index].values())
        .map(sale => oc(Object.values(sale)[0]).salesAmount())
        .filter(x => x !== undefined);
    }
  },
  mounted() {
    this.getSalesHourly({
      periods: this.periods
    });
  }
};
</script>
<style lang="scss" scoped>
/deep/ .apexcharts-legend-text,
/deep/ .apexcharts-tooltip-text-label {
  display: inline-block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

/deep/ .apexcharts-xaxis-texts-g text:nth-child(2n):not(:last-child) {
  visibility: hidden;
}
</style>
