<template>
  <vel-input
    class="vel-table-filter"
    :placeholder="placeholder"
    :value="value"
    v-model="input"
    @input="handleInput"
    prefix-icon="el-icon-search"
    clearable
  />
</template>

<script>
import VelInput from '@/components/x-input/VelInput';

export default {
  name: 'vel-table-filter',
  components: {
    VelInput
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.input);
    }
  },
  data() {
    return {
      input: this.value
    };
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';
@import 'breakpoint.scss';

.vel-table-filter {
  @include noPrint;
}
</style>
