<template>
  <div class="big-divisions-list">
    <vel-table
      :rows="data"
      :columns="columns"
      filterable
      :filterValue="filterValue"
      :filterPlaceholder="$t('defaults.placeholder.filter')"
      @filter-change="filterCallback"
      :showRowsLength="true"
      :initialLength="initialLength"
    >
      <template v-slot:title>{{ $('bigDivisionsPage.table.title') }}</template>

      <template v-slot:actions-column="scope">
        <vel-button type="link" icon="edit" @click="handleGoToBigDivision(scope.row.id)" />
      </template>
    </vel-table>
  </div>
</template>

<script>
import DeviceMixin from '@/mixins/device-mixin';
import VelButton from '@/components/button/VelButton';
import VelTable from '@/components/table/VelTable';
import { mapActions as mapPiniaActions } from 'pinia/dist/pinia';
import { useDataBigDivisions } from '@/stores/data/big-divisions.module';

export default {
  name: 'big-divisions-list',
  components: { VelButton, VelTable },
  mixins: [DeviceMixin],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    filterValue: {
      type: String,
      default: ''
    },
    initialLength: {
      type: Number,
      default: 0
    },
    filterCallback: {
      type: Function,
      default: () => null
    }
  },
  computed: {
    columns() {
      return {
        remoteId: {
          title: this.$t('bigDivisionsPage.table.columns.remoteID'),
          width: '80px',
          sortable: true
        },
        name: {
          title: this.$t('bigDivisionsPage.table.columns.name'),
          sortable: true,
          width: this.isMediumDown ? '240px' : 'auto'
        },
        actions: {
          title: 'Action',
          width: '90px'
        }
      };
    }
  },
  methods: {
    ...mapPiniaActions(useDataBigDivisions, ['setSelected']),
    handleGoToBigDivision(bigDivisionId) {
      this.setSelected(bigDivisionId);
      this.$router.push({ path: '/bigdivision', query: { ...this.$router.currentRoute.query, bigDivisionId } });
    }
  }
};
</script>
