<template>
  <thead class="vel-table-header" role="rowgroup" :class="classes">
    <tr class="vel-table-header__row" role="row">
      <th
        class="vel-table-header__cell"
        role="columnheader"
        scope="col"
        v-for="(column, index) in computedColumns"
        :key="index"
        :aria-colindex="index + 1"
        :style="getCellStyles(column)"
        :class="{ 'vel-table-header__cell_sortable': isSortable(column) }"
        @click="handleClick(column)"
      >
        {{ column.title }}
        <div class="vel-table-header__sort-indicator" v-if="isSortable(column)">
          <vel-icon
            :class="`vel-table-header__sort-icon vel-table-header__sort${
              hasSort(column.key) && hasDirection('asc') ? '-icon_up' : ''
            }`"
            name="order-asc"
          />
          <vel-icon
            :class="`
              vel-table-header__sort-icon vel-table-header__sort${
                hasSort(column.key) && hasDirection('desc') ? '-icon_down' : ''
              }`"
            name="order-desc"
          />
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
import VelIcon from '@/components/icon/VelIcon';

export default {
  name: 'vel-table-header',
  components: {
    VelIcon
  },
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    sortBy: {
      type: String
    },
    direction: {
      type: String
    },
    bordered: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedColumns() {
      return this.columns.filter(column => column.visible);
    },
    classes() {
      const { name } = this.$options;
      return {
        [`${name}_bordered`]: this.bordered
      };
    }
  },
  methods: {
    handleClick(column) {
      if (!column.sortable) {
        return;
      }
      this.$emit('click', column);
    },
    getCellStyles(column) {
      const styles = {};
      if (column.width) {
        styles.width = column.width;
      }
      return styles;
    },
    hasSort(key) {
      return this.sortBy === key;
    },
    hasDirection(direction) {
      return this.direction === direction;
    },
    isSortable(column) {
      return column.sortable || false;
    }
  }
};
</script>

<style lang="scss">
@import 'constants.scss';
@import 'mixins.scss';

$table-header-background-color: white;
$table-font-size_medium: rem-calc(15);
$table-font-size_small: rem-calc(13);

// cell
$table-header__cell-border-color: $concrete;
$table-header__cell-height: rem-calc(48);
$table-header__cell-font-color: $river-bed;
$table-header__cell-font-weight: $font-bold;
$table-font-size_small: rem-calc(13);

// sort-icon
$table-header__sort-icon-color: $table-header__cell-font-color;

.vel-table-header {
  background-color: $table-header-background-color;
  overflow: hidden;

  &_bordered &__cell {
    border-right: 1px solid $table-header__cell-border-color;
  }

  &__cell {
    border-bottom: 2px solid $table-header__cell-border-color;
    box-sizing: border-box;
    color: $table-header__cell-font-color;
    font-size: $table-font-size_small;
    font-weight: $table-header__cell-font-weight;
    height: $table-header__cell-height;
    min-width: 0;
    overflow: hidden;
    padding: rem-calc(10);
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    @include breakpoint(medium) {
      font-size: $table-font-size_medium;
      padding: rem-calc(12);
    }

    &_sortable {
      cursor: pointer;
    }
  }

  &__sort-indicator {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: rem-calc(14);
  }

  &__sort-icon {
    color: $table-header__sort-icon-color;
    height: rem-calc(9);
    width: rem-calc(9);

    &_up,
    &_down {
      color: #00a9e1;
    }
  }
}
</style>
