import { get, patch, post, remove, resolveCommonQuery } from '@/helpers/service.helper';
import { getToken } from '@/helpers/token.helper';

/**
 * Get employees
 * @link https://api.posveloce.com/docs/private#operation/getEmployees
 */
export const getEmployees = (params: {}) => {
  const url = resolveCommonQuery('/employees', params).toString();
  return get(getToken(), url);
};

/**
 * Update employee
 * @link https://api.posveloce.com/docs/private#operation/patchEmployee
 */
export const patchEmployee = (id, body: {}) => patch(getToken(), `/employees/${id}`, body);

/**
 * Create an employee
 * @link https://api.posveloce.com/docs/private#operation/postEmployee
 */
export const postEmployee = (body: {}) => post(getToken(), `/employees`, body);

/**
 * Get employee by id
 * @link https://api.posveloce.com/docs/private#operation/GetEmployeesByID
 */
export const getEmployeesByID = id => get(getToken(), `/employees/${id}`);

/**
 * Get employees' tasks
 * @link https://api.posveloce.com/docs/private#operation/getEmployeesTasks
 */
export const getEmployeesTasks = (params: {}) => {
  const url = resolveCommonQuery('/employees/tasks', params).toString();
  return get(getToken(), url);
};

/**
 * Get employee meal plans
 * @link https://api.posveloce.com/docs/private#operation/getEmployeeMealPlans
 */
export const getEmployeeMealPlans = (params: {}) => {
  const url = resolveCommonQuery('/employees/mealPlans', params).toString();
  return get(getToken(), url);
};

/**
 * Get employee history
 * @link https://api.posveloce.com/docs/private#operation/getEmployeeHistory
 */
export const getEmployeeHistory = (id, params: {}) => {
  const url = resolveCommonQuery(`/employees/${id}/history`, params).toString();
  return get(getToken(), url);
};

/**
 * Get employee tip distributions
 * @link https://api.posveloce.com/docs/private#operation/getEmployeeTipDistributions
 */
export const getEmployeeTipDistributions = (params: {}) => {
  const url = resolveCommonQuery('/employees/tipDistributions', params).toString();
  return get(getToken(), url);
};
/**
 * Create an employee task
 * @link https://api.posveloce.com/docs/private#operation/postEmployeeTask
 */
export const postEmployeeTask = (body: {}) => post(getToken(), `/employees/tasks`, body);

/**
 * Create employee tip distribution
 * @link https://api.posveloce.com/docs/private#operation/postEmployeeTipDistribution
 */
export const postEmployeeTipDistribution = (body: {}) => post(getToken(), `/employees/tipDistributions`, body);

/**
 * Update an employee task
 * @link https://api.posveloce.com/docs/private#operation/patchEmployeeTask
 */
export const patchEmployeeTask = (taskId, body: {}) => patch(getToken(), `/employees/tasks/${taskId}`, body);

/**
 * Partially update employee tip distribution by id
 * @link https://api.posveloce.com/docs/private#operation/patchEmployeeTipDistribution
 */
export const patchEmployeeTipDistribution = (id, body: {}) =>
  patch(getToken(), `/employees/tipDistributions/${id}`, body);

/**
 * Create an employee meal plan
 * @link https://api.posveloce.com/docs/private#operation/postEmployeeMealPlan
 */
export const postEmployeeMealPlan = (body: {}) => post(getToken(), `/employees/mealPlans`, body);

/**
 * Update an employee meal plan
 * @link https://api.posveloce.com/docs/private#operation/patchEmployeeMealPlan
 */
export const patchEmployeeMealPlan = (id, body: {}) => patch(getToken(), `/employees/mealPlans/${id}`, body);

/**
 * Delete an employee meal plan
 * @link https://api.posveloce.com/docs/private#operation/deleteEmployeeMealPlan
 */
export const deleteEmployeeMealPlan = id => remove(getToken(), `/employees/mealPlans/${id}`);

/**
 * Delete an employee function
 * @link https://api.posveloce.com/docs/private#operation/deleteEmployeeFunction
 */
export const deleteEmployeeAccessType = id => remove(getToken(), `/employees/functions/${id}`);

/**
 * Delete an employee tip distribution
 * @link https://api.posveloce.com/docs/private#operation/deleteEmployeeTipDistribution
 */
export const deleteEmployeeTipDistribution = id => remove(getToken(), `/employees/tipDistributions/${id}`);

/**
 * Get employees' functions
 * @link https://api.posveloce.com/docs/private#operation/GetEmployeesFunctions
 */
export const getEmployeeAccessTypes = (params: {}) => {
  const url = resolveCommonQuery('/employees/functions', params).toString();
  return get(getToken(), url);
};

/**
 * Delete an employee tip distribution
 * @link https://api.posveloce.com/docs/private#operation/deleteEmployeeTipDistribution
 */
export const deleteEmployeeTimeOffs = ids => remove(getToken(), `/employees/timeOffs`, ids);

/**
 * Create a new employee time off
 * @link https://api.posveloce.com/docs/private#operation/postEmployeeTimeOff
 */
export const postEmployeeTimeOff = (employeeId, body: {}) =>
  post(getToken(), `/employees/${employeeId}/timeOffs`, body);

/**
 * Get employee time off
 * @link https://api.posveloce.com/docs/private#operation/getEmployeeTimeOff
 */
export const getEmployeeTimeOff = (employeeId, params: {} = {}) => {
  const url = resolveCommonQuery(`/employees/${employeeId}/timeOffs`, params).toString();
  return get(getToken(), url);
};

/**
 * Update an employee time off
 * @link https://api.posveloce.com/docs/private#operation/patchEmployeeTimeOff
 */
export const patchEmployeeTimeOff = (timeOffID, body: {}) => patch(getToken(), `/employees/timeOffs`, body);

/**
 * Delete an employee tip distribution
 * @link https://api.posveloce.com/docs/private#operation/deleteEmployeeTipDistribution
 */
export const deleteEmployeeTask = id => remove(getToken(), `/employees/tasks/${id}`);

/**
 * Create a new employee function
 * @link https://api.posveloce.com/docs/private#operation/postEmployeeFunction
 */
export const postEmployeeFunction = (body: {}) => post(getToken(), `/employees/functions`, body);

/**
 * Update a new employee function
 * @link https://api.posveloce.com/docs/private#operation/patchEmployeeFunction
 */
export const patchEmployeeFunction = (functionId, body: {}) =>
  patch(getToken(), `/employees/functions/${functionId}`, body);
