<template>
  <div>
    <b-row class="mb-4 mt-4">
      <b-col cols="12" md="4" class="d-flex align-items-center mb-2 mb-md-0 pb-4">
        {{ $t('timeAttendances.description.label') }}
      </b-col>
      <b-col>
        <b-form-input
          id="name"
          :state="!enableSaveChanges ? null : form.name !== '' && null"
          :placeholder="$t('timeAttendances.description.placeholder')"
          :class="{ required: !form.name || form.name === '' }"
          v-model="form.name"
          aria-describedby="passwd-feedback"
        ></b-form-input>
        <b-form-text id="input-live-help">{{ $t('employeeCreate.required') }}</b-form-text>
        <b-form-invalid-feedback id="passwd-feedback">
          {{ $t('employeeCreate.required') }}
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="mb-4 mt-4">
      <b-col cols="12" md="4" class="d-flex align-items-center mb-2 mb-md-0">
        {{ $t('timeAttendances.code.label') }}
      </b-col>
      <b-col>
        <b-form-input
          id="code"
          :placeholder="$t('timeAttendances.code.placeholder')"
          v-model="form.code"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mb-4 mt-4">
      <b-col cols="12" md="4" class="d-flex align-items-center mb-2 mb-md-0">
        {{ $t('timeAttendances.payroll.label') }}
      </b-col>
      <b-col>
        <b-form-input
          id="payrollNumber"
          type="text"
          :placeholder="$t('timeAttendances.payroll.placeholder')"
          v-model="form.payrollNumber"
        ></b-form-input>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'time-attendance-form',
  model: {},
  props: {
    licenceNumber: {
      type: Number,
      default: null
    },
    timeAttendance: {
      type: Object,
      default: () => ({ code: null, name: '', payrollNumber: '' })
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        code: null,
        name: '',
        payrollNumber: ''
      }
    };
  },
  watch: {
    timeAttendance(value) {
      this.form = Object.assign({}, value);
    }
  },
  computed: {
    enableSaveChanges() {
      return JSON.stringify(this.form) !== JSON.stringify(this.timeAttendance) && this.form.name !== '';
    }
  },
  methods: {
    reset(value = null) {
      this.$emit(
        'update:timeAttendance',
        Object.assign(
          {},
          {
            id: null,
            code: null,
            name: '',
            payrollNumber: '',
            ...(value || {})
          }
        )
      );
      this.form = Object.assign(
        {},
        {
          id: null,
          code: null,
          name: '',
          payrollNumber: '',
          ...(value || {})
        }
      );
    }
  }
};
</script>
<style land="scss" scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
