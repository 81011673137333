<template>
  <vel-form
    ref="locationForm"
    class="location-form bootstrap"
    :model="locationForm"
    :rules="locationFormRules"
    :submit="handleSubmit"
    label-width="250px"
  >
    <vel-input name="id" class="name-input" type="hidden" v-model="locationForm.id"></vel-input>
    <vel-form-item prop="name" class="location-form-name" :label="$t('location.form.name.label')">
      <div v-confirm="$t('location.form.confirmNameEditMessage')" @accept="accept">
        <vel-input
          name="name"
          class="name-input"
          type="text"
          v-model="locationForm.name"
          :disabled="!isAllowedAlias"
        ></vel-input>
      </div>
    </vel-form-item>
    <vel-form-item
      prop="name"
      class="location-form-reporting-display-name"
      :label="$t('location.form.reportingDisplayName.label')"
    >
      <div>
        <vel-input
          name="reportingDisplayName"
          class="name-input"
          type="text"
          v-model="locationForm.reportingDisplayName"
        ></vel-input>
      </div>
    </vel-form-item>

    <vel-form-item prop="address" class="location-form-address" :label="$t('location.form.address.label')">
      <vel-input name="address" class="name-input" type="text" v-model="locationForm.address"></vel-input>
    </vel-form-item>
    <vel-form-item
      v-show="false"
      prop="licenceNumber"
      class="location-form-licenceNumber"
      :label="$t('location.form.licenceNumber.label')"
    >
      <vel-input
        name="licenceNumber"
        :disabled="true"
        class="name-input"
        type="text"
        v-model="locationForm.licenceNumber"
      ></vel-input>
    </vel-form-item>
    <vel-form-item prop="currencyCode" class="location-form-currency" :label="$t('location.form.currency.label')">
      <vel-select :filterable="true" v-model="locationForm.currencyCode">
        <vel-option v-for="currency in currencies" :key="currency.code" :label="currency.name" :value="currency.code">
          <span>
            <span style="display: inline-block; width: 34px;">{{ currency.name.substring(0, 3) }}</span>
            {{ currency.name.substring(3) }}
          </span>
        </vel-option>
        <vel-option :value="null" :label="$t('location.form.country.none')"></vel-option>
      </vel-select>
    </vel-form-item>
    <vel-form-item prop="isDemo" class="is-demo" :label="$t('location.form.isDemo.label')">
      <vel-switch v-model="locationForm.isDemo" />
    </vel-form-item>
    <!--
    // Temporary disabled
    <vel-form-item class="reseller" prop="resellerId" :label="$t('location.form.resellerId.label')">
      <vel-select v-model="locationForm.resellerId" :placeholder="$t('location.form.resellerId.placeholder')">
        <vel-option :label="$t('location.form.resellerNone')" :value="null"></vel-option>
        <vel-option
          v-for="reseller in resellers"
          :label="reseller.name"
          :value="reseller.id"
          :key="reseller.id"
        ></vel-option>
      </vel-select>
    </vel-form-item>-->
    <vel-form-item v-show="false" prop="logo" class="location-form-logo" :label="$t('location.form.logo.label')">
      <vel-file-upload
        name="logo"
        ref="velUpload"
        accept="image/jpeg image/png image/webp image/gif"
        v-model="locationForm.logo"
      ></vel-file-upload>
    </vel-form-item>
    <!--
    <vel-form-item prop="countryId" :label="$t('location.form.country.label')">
      <vel-select v-model="locationForm.countryId">
        <vel-option
          v-for="country in sortedCountries"
          :key="country.id"
          :label="(country.translation[`desc${$i18n.locale.replace(/^\w/, c => c.toUpperCase())}`])"
          :value="country.id"
        ></vel-option>
        <vel-option :value="null" :label="$t('location.form.country.none')"></vel-option>
      </vel-select>
    </vel-form-item>
-->
    <vel-form-item class="location-form__actions">
      <vel-button type="primary" class="actions__submit" :loading="pending" @click="handleSubmit()">
        {{ $t('defaults.actions.save') }}
      </vel-button>
    </vel-form-item>
  </vel-form>
</template>

<script>
import { Confirm } from '@/directives/confirm';
import VelButton from '@/components/button/VelButton';
import VelFileUpload from '@/components/file-upload/VelFileUpload';
import VelForm from '@/components/x-form/VelForm';
import VelFormItem from '@/components/x-form-item/VelFormItem';
import VelInput from '@/components/input/VelInput';
import VelOption from '@/components/x-option/VelOption';
import VelSelect from '@/components/x-select/VelSelect';
import VelSwitch from '@/components/switch/VelSwitch';
import compareStringsInsensitive from '@/comparators/compare-strings-insensitive';

export default {
  name: 'location-form',
  components: {
    VelButton,
    VelForm,
    VelFormItem,
    VelInput,
    VelFileUpload,
    VelOption,
    VelSelect,
    VelSwitch
  },
  directives: {
    Confirm
  },
  props: {
    location: {
      type: Object,
      default: () => ({
        name: null,
        id: null,
        address: null,
        licenceNumber: null,
        currency: null,
        logo: null,
        country: null,
        countryId: null
      })
    },
    isSavingLocation: {
      type: Boolean,
      default: false
    },
    pending: {
      type: Boolean,
      default: false
    },
    countries: {
      type: Array,
      default: () => []
    },
    currencies: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      locationForm: {
        name: this.location.name,
        reportingDisplayName: this.location.reportingDisplayName,
        countryId: this.location.countryId || null,
        currencyCode: this.location.detailedCurrency && this.location.detailedCurrency.currencyCode,
        isDemo: this.location.isDemo,
        address: this.location.address,
        logo: this.location.logo,
        id: this.location.id,
        licenceNumber: this.location.licenceNumber,
        resellerId: (this.location.reseller && this.location.reseller.id) || null
      },
      isAllowedAlias: false
    };
  },
  methods: {
    accept() {
      this.isAllowedAlias = true;
    },
    handleCancel() {
      this.$emit('cancel');
    },
    handleSubmit() {
      this.$refs.locationForm.validate(valid => {
        if (valid) {
          this.$emit('submit', this.locationForm);
        }
      });
    }
  },

  computed: {
    locationFormRules() {
      return {
        name: [
          {
            required: false,
            message: this.$t('location.form.name.isRequired')
          }
        ],
        reportingDisplayName: [
          {
            required: false
          }
        ],
        id: [
          {
            required: false
          }
        ],
        address: [
          {
            required: false,
            message: this.$t('location.form.address.isRequired')
          }
        ],
        licenceNumber: [
          {
            required: false,
            message: this.$t('location.form.licenceNumber.isRequired')
          }
        ],
        logo: [
          {
            required: false,
            message: this.$t('location.form.logo.isRequired')
          }
        ],
        currencyCode: [
          {
            required: true
            // validator: CurrencyValidator,
            // message: this.$t('location.form.currency.isRequired')
          }
        ],
        isDemo: [
          {
            required: false
          }
        ]
      };
    },
    sortedCountries() {
      // eslint-disable-next-line
      return this.countries.sort((a, b) =>
        compareStringsInsensitive(
          a.translation[`desc${this.$i18n.locale.replace(/^\w/, c => c.toUpperCase())}`],
          b.translation[`desc${this.$i18n.locale.replace(/^\w/, c => c.toUpperCase())}`]
        )
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.el-select-dropdown__item {
  font-family: 'Roboto', 'Courier', sans-serif;
}

.location-form {
  /deep/ {
    .vel-input-group {
      padding-left: 0;
    }
  }
}

.location-form__actions {
  .actions__submit {
    margin-right: 1.25em;
  }
}

.name-input /deep/ input:disabled {
  background-color: #fffce0;
}
</style>
