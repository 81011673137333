import { defineStore } from 'pinia';
import locationsService from '@/services/locations.service';
import { getToken } from '@/helpers/token.helper';

export const useDataLocationsSettingsLocation = defineStore('locationsSettingsLocation', {
  state: () => ({ loading: true, pending: false, error: null, success: null, settings: {} }),
  getters: {
    isError: state => !!state.error,
    isSuccess: state => !!state.success
  },
  actions: {
    getSettings(locationId) {
      this.getSettingsRequest();

      locationsService.getLocationSettings(getToken(), { locationId }).then(
        settings => {
          this.getSettingsSuccess(settings);
        },
        error => {
          this.getSettingsFailure(error);
        }
      );
    },
    putSettings({ locationId, settings }) {
      this.putSettingsRequest();

      locationsService.putLocationSettings(getToken(), locationId, settings).then(
        () => {
          this.putSettingsRequestSuccess(settings);
        },
        error => {
          this.putSettingsRequestFailure(error);
        }
      );
    },
    resetLocationPermissions() {
      this.reset();
    },
    clearMessage() {
      this.clearMessages();
    },
    getSettingsRequest() {
      this.loading = true;
      this.settings = {};
      this.error = null;
      this.success = null;
    },
    getSettingsSuccess(settings) {
      this.loading = false;
      this.settings = settings || {};
      this.error = null;
      this.success = null;
    },
    getSettingsFailure(error) {
      this.loading = false;
      this.settings = {};
      this.error = error;
      this.success = true;
    },
    putSettingsRequest() {
      this.loading = true;
      this.error = null;
      this.success = null;
    },
    putSettingsRequestSuccess(settings) {
      this.loading = false;
      this.settings = settings || {};
      this.error = null;
      this.success = true;
    },
    putSettingsRequestFailure(error) {
      this.loading = false;
      this.error = error;
      this.success = null;
    },
    clearMessages() {
      this.error = null;
      this.success = null;
    },
    reset() {
      this.loading = false;
      this.settings = {};
      this.error = null;
      this.success = null;
    }
  }
});
