<template>
  <portal to="app">
    <vel-modal class="location-delete__modal" dismissable @dismiss="handleCancel">
      <template v-slot:header>
        {{ $t('group.locations.modals.delete.title') }}
      </template>
      <template v-slot:default>
        <vel-alert
          @dismiss="clearDeleteGroupLocationError"
          class="alert delete-location__error"
          level="error"
          v-if="deleteGroupLocationError"
        >
          {{ $t('group.errors.deleteLocation') }}
        </vel-alert>

        <span
          class="location-delete__confirmation"
          v-html="$t('group.locations.modals.delete.textHtml', { name: location.label })"
        />
      </template>
      <template v-slot:footer>
        <vel-button class="location-delete__close-button" type="secondary" @click="handleCancel">
          {{ $t('defaults.actions.close') }}
        </vel-button>
        <vel-button
          class="location-delete__confirm-button"
          type="critical"
          :loading="isSavingLocations"
          @click="handleDelete"
        >
          {{ $t('group.locations.modals.delete.button.text') }}
        </vel-button>
      </template>
    </vel-modal>
  </portal>
</template>

<script>
import VelAlert from '@/components/alert/VelAlert';
import VelButton from '@/components/button/VelButton';
import VelModal from '@/components/modal/VelModal';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useDataGroupsLocationGroup } from '@/stores/data/groups/location-group.module';

export default {
  name: 'delete-group-modal',
  components: {
    VelButton,
    VelModal,
    VelAlert
  },
  props: {
    groupId: {
      type: String,
      required: true
    },
    location: {
      type: Object,
      default: () => ({
        value: null,
        label: null
      })
    }
  },
  data() {
    return {
      currentLocation: { ...this.location }
    };
  },
  methods: {
    ...mapPiniaActions(useDataGroupsLocationGroup, [
      'clearLatestDeletedGroupLocation',
      'deleteGroupLocation',
      'clearDeleteGroupLocationError'
    ]),
    handleCancel() {
      this.$emit('dismiss');
    },
    handleDelete() {
      this.deleteGroupLocation({ groupId: this.groupId, location: this.currentLocation });
    }
  },
  watch: {
    latestDeletedGroupLocation(deletedLocation) {
      if (deletedLocation && this.currentLocation.value === deletedLocation.value) {
        this.$emit('dismiss');
      }
    }
  },
  computed: {
    ...mapPiniaState(useDataGroupsLocationGroup, [
      'deleteGroupLocationError',
      'latestDeletedGroupLocation',
      'isSavingLocations'
    ])
  },
  created() {
    this.clearLatestDeletedGroupLocation();
    this.clearDeleteGroupLocationError();
  }
};
</script>
<style lang="scss" scoped>
@import 'constants.scss';
@import 'mixins.scss';

.delete-location__error {
  margin-bottom: rem-calc(16);
}
</style>
