<template>
  <div>
    <b-alert :show="error" variant="danger" class="mb-4">
      {{ $t('employeeCreate.updateError') }}
    </b-alert>
    <h5 class="pt-4 px-4 pb-2">{{ $t('employeeCreate.links.workstation') }}</h5>
    <b-container fluid class="content p-4">
      <div class="d-flex flex-column flex-md-row mb-md-4 justify-content-between">
        <b-form-group
          :label="$t('employeeCreate.links.department')"
          label-for="revenueCenterId"
          class="mr-5 w-100 mb-2 mb-md-0"
        >
          <b-form-select
            :options="[...departments, { remoteId: null, name: $t('employeeCreate.emptyValue') }]"
            id="revenueCenterId"
            value-field="id"
            text-field="name"
            aria-describedby="input-live-help input-live-feedback"
            class="form-text"
            v-model="employee.revenueCenterId"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          :label="$t('employeeCreate.links.cashDrawer')"
          label-for="cashDrawer"
          class="mr-5 ml-md-5 w-100 mb-2 mb-md-0"
        >
          <b-form-select
            :options="[
              { id: 1, name: $t('employeeCreate.links.cashDrawerItem', { number: 1 }) },
              { id: 2, name: $t('employeeCreate.links.cashDrawerItem', { number: 2 }) },
              { id: 0, name: $t('employeeCreate.emptyValue') }
            ]"
            id="cashDrawer"
            value-field="id"
            text-field="name"
            aria-describedby="input-live-help input-live-feedback"
            class="form-text"
            v-model="employee.cashDrawer"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          :label="$t('employeeCreate.links.redirectionTable')"
          label-for="redirectionTableId"
          class="mr-5 ml-md-5 w-100 mb-2 mb-md-0"
        >
          <b-form-select
            :options="[...redirectionsTables, { id: null, name: $t('employeeCreate.emptyValue') }]"
            id="redirectionTableId"
            value-field="id"
            text-field="name"
            aria-describedby="input-live-help input-live-feedback"
            class="form-text"
            v-model="employee.redirectionTableId"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          :label="$t('employeeCreate.links.pageNumber.label')"
          label-for="pagerNumber"
          class="mr-5 ml-md-5 w-100 mb-2 mb-md-0"
        >
          <b-form-input
            id="pagerNumber"
            v-model="employee.pagerNumber"
            maxlength="49"
            :placeholder="$t('employeeCreate.links.pageNumber.placeHolder')"
            aria-describedby="input-live-help input-live-feedback"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="d-flex flex-column flex-md-row mb-4 justify-content-between">
        <b-form-group :label="$t('employeeCreate.links.owedFees')" label-for="owedFees" class="mr-5 w-100 mb-2 mb-md-0">
          <b-input-group prepend="$">
            <b-form-input
              f
              id="owedFees"
              v-model.number="employee.owedFeesDelivery"
              no-wheel
              lazy-formatter
              :formatter="formatAmount"
              step="0.01"
              type="number"
              aria-describedby="input-live-help input-live-feedback"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group
          :label="$t('employeeCreate.links.alternateWorkstation')"
          label-for="alternateWorkstationId"
          class="mr-5 ml-md-5 w-100 mb-2 mb-md-0"
        >
          <b-form-select
            f
            :options="[...workstations, { id: null, name: $t('employeeCreate.emptyValue') }]"
            id="alternateWorkstationId"
            value-field="id"
            text-field="name"
            aria-describedby="input-live-help input-live-feedback"
            class="form-text"
            v-model="employee.alternateWorkstationId"
          ></b-form-select>
        </b-form-group>
        <div class="mr-5 ml-md-5 w-100"></div>
        <div class="mr-5 ml-md-5 w-100"></div>
      </div>
    </b-container>
    <h5 class="pt-4 px-4 pb-2">{{ $t('employeeCreate.links.tableSection') }}</h5>
    <b-container fluid class="content p-4">
      <div class="d-flex flex-column flex-md-row mb-4 justify-content-between">
        <b-form-group
          :label="$t('employeeCreate.links.tableSection')"
          label-for="tableSectionId"
          class="mr-5 w-100 mb-2 mb-md-0"
        >
          <b-form-select
            f
            :options="[...tablesSections, { id: null, name: $t('employeeCreate.emptyValue') }]"
            id="tableSectionId"
            value-field="id"
            text-field="name"
            aria-describedby="input-live-help input-live-feedback"
            class="form-text"
            v-model="employee.tableSectionId"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          :label="$t('employeeCreate.links.floorPlans')"
          label-for="floorPlans"
          class="mr-5 ml-md-5 w-100 mb-2 mb-md-0"
        >
          <b-form-select
            f
            :options="[...floorPlans, { id: null, name: $t('employeeCreate.emptyValue') }]"
            id="floorPlans"
            value-field="id"
            text-field="name"
            aria-describedby="input-live-help input-live-feedback"
            class="form-text"
            v-model="employee.floorPlanId"
          ></b-form-select>
        </b-form-group>
        <div class="mr-5 ml-md-5 w-100"></div>
        <div class="mr-5 ml-md-5 w-100"></div>
      </div>
    </b-container>
    <h5 class="pt-4 px-4 pb-2">{{ $t('employeeCreate.links.electronicBar') }}</h5>
    <b-container fluid class="content p-4">
      <div class="d-flex flex-column flex-md-row mb-md-4 justify-content-between">
        <b-form-group
          :label="$t('employeeCreate.links.electronicBarKey.label')"
          label-for="electronicBarKey"
          class="mr-5 w-100 mb-2 mb-md-0"
        >
          <b-form-input
            f
            id="electronicBarKey"
            v-model="employee.electronicBarKey"
            maxlength="40"
            :placeholder="$t('employeeCreate.links.electronicBarKey.placeHolder')"
            aria-describedby="input-live-help input-live-feedback"
          ></b-form-input>
        </b-form-group>
        <div class="mr-5 ml-md-5 w-100"></div>
        <div class="mr-5 ml-md-5 w-100"></div>
        <div class="mr-5 ml-md-5 w-100"></div>
      </div>
      <div class="d-flex flex-column flex-md-row mb-md-4 justify-content-between">
        <b-button-group class="mb-2" :class="{ 'w-100': isMediumDown }" :vertical="isMediumDown">
          <b-button
            class="d-sm-block border-input"
            @click="employee.electronicBarType = 0"
            :variant="employee.electronicBarType === 0 ? 'primary' : 'white'"
          >
            {{ $t('employeeCreate.links.fixedSection') }}
          </b-button>
          <b-button
            class="d-sm-block border-input"
            @click="employee.electronicBarType = 1"
            :variant="employee.electronicBarType === 1 ? 'primary' : 'white'"
          >
            {{ $t('employeeCreate.links.asPerWorkstation') }}
          </b-button>
          <b-button
            class="d-sm-block border-input"
            @click="employee.electronicBarType = 2"
            :variant="employee.electronicBarType === 2 ? 'primary' : 'white'"
          >
            {{ $t('employeeCreate.links.asPerRedirectionTable') }}
          </b-button>
        </b-button-group>
      </div>
      <div class="d-flex flex-column flex-md-row mb-4 justify-content-between">
        <b-form-group
          v-if="employee.electronicBarType == 0"
          :label="$t('employeeCreate.links.electronicBarSectionNumber.label')"
          label-for="electronicBarSectionNumber"
          class="mr-5 w-100 mb-2 mb-md-0"
        >
          <b-form-input
            f
            id="electronicBarSectionNumber"
            v-model="employee.electronicBarSectionNumber"
            maxlength="8"
            :placeholder="$t('employeeCreate.links.electronicBarSectionNumber.placeHolder')"
            number
            aria-describedby="input-live-help input-live-feedback"
          ></b-form-input>
        </b-form-group>
        <div class="mr-5 ml-md-5 w-100"></div>
        <div class="mr-5 ml-md-5 w-100"></div>
        <div class="mr-5 ml-md-5 w-100"></div>
      </div>
    </b-container>

    <h5 class="pt-4 px-4 pb-2">{{ $t('employeeCreate.links.salesRecordingModule') }}</h5>
    <b-container fluid class="content p-4">
      <div class="d-flex flex-column flex-md-row mb-4 justify-content-between">
        <b-form-group
          :label="$t('employeeCreate.links.username.label')"
          label-for="srmUsername"
          class="mr-5 w-100 mb-2 mb-md-0"
        >
          <b-form-input
            f
            id="srmUsername"
            v-model="employee.srmUsername"
            maxlength="40"
            :placeholder="$t('employeeCreate.links.username.placeHolder')"
            aria-describedby="input-live-help input-live-feedback"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('employeeCreate.links.password.label')"
          label-for="srmPassword"
          class="mr-5 ml-md-5 w-100 mb-2 mb-md-0"
        >
          <b-form-input
            f
            id="srmPassword"
            v-model="employee.srmPassword"
            maxlength="40"
            :placeholder="$t('employeeCreate.links.password.placeHolder')"
            aria-describedby="input-live-help input-live-feedback"
          ></b-form-input>
        </b-form-group>
        <div class="mr-5 ml-md-5 w-100"></div>
        <div class="mr-5 ml-md-5 w-100"></div>
      </div>
    </b-container>

    <h5 class="pt-4 px-4 pb-2">{{ $t('employeeCreate.links.inventory') }}</h5>
    <b-container fluid class="content p-4">
      <div class="d-flex flex-column flex-md-row mb-md-4 justify-content-between">
        <b-form-group
          :label="$t('employeeCreate.links.functionLocation')"
          label-for="inventoryLocalisationId"
          class="mr-5 w-100 mb-2 mb-md-0"
        >
          <b-form-select
            f
            :options="[...inventoryLocalisations, { id: null, name: $t('employeeCreate.emptyValue') }]"
            id="inventoryLocalisationId"
            value-field="id"
            text-field="name"
            aria-describedby="input-live-help input-live-feedback"
            class="form-text"
            v-model="employee.inventoryLocalisationId"
          ></b-form-select>
        </b-form-group>
        <div class="mr-5 ml-md-5 w-100"></div>
        <div class="mr-5 ml-md-5 w-100"></div>
        <div class="mr-5 ml-md-5 w-100"></div>
      </div>
      <div class="d-flex flex-column flex-md-row mb-4 justify-content-between">
        <b-form-group
          :label="$t('employeeCreate.links.supplier')"
          label-for="supplierId"
          class="mr-5 w-100 mb-2 mb-md-0"
        >
          <b-form-select
            f
            :options="[...suppliers, { id: null, name: $t('employeeCreate.emptyValue') }]"
            id="supplierId"
            value-field="id"
            text-field="name"
            aria-describedby="input-live-help input-live-feedback"
            class="form-text"
            v-model="employee.supplierId"
          ></b-form-select>
        </b-form-group>
        <b-checkbox
          class="mr-md-5 ml-md-5 w-100 mb-2 mb-md-0 pt-md-4 justify-content-center align-self-center"
          v-model="employee.taxableFees"
        >
          {{ $t('employeeCreate.links.taxables') }}
        </b-checkbox>
        <div class="mr-5 ml-md-5 w-100"></div>
        <div class="mr-5 ml-md-5 w-100"></div>
      </div>
    </b-container>
  </div>
</template>
<script>
import DeviceMixin from '@/mixins/device-mixin';

export default {
  name: 'employee-form-links',
  components: {},
  mixins: [DeviceMixin],
  props: {
    employee: {
      type: Object,
      default: () => ({ name: '' })
    },
    inventoryLocalisations: {
      type: Array,
      default: () => []
    },
    departments: {
      type: Array,
      default: () => []
    },
    floorPlans: {
      type: Array,
      default: () => []
    },
    suppliers: {
      type: Array,
      default: () => []
    },
    workstations: {
      type: Array,
      default: () => []
    },
    tablesSections: {
      type: Array,
      default: () => []
    },
    redirectionsTables: {
      type: Array,
      default: () => []
    },
    pending: {
      type: Boolean,
      default: false
    },
    submited: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {
    formatAmount(value) {
      return Number(value).toFixed(2);
    }
  }
};
</script>
<style scoped lang="scss">
h5 {
  background-color: #f5f5f5;
}

/deep/ {
  label,
  .form-text {
    margin-bottom: 0;
    padding-left: 1rem;
  }
}

/deep/ .form-text {
  min-height: 18px;
}

.datepicker .input-group-append {
  margin-left: -35px;
}

.btn-light {
  background-color: #fff;
}

.custom-control {
  margin-bottom: 8px;
}

/deep/ .custom-control-label {
  line-height: 1.5rem;
}
</style>
