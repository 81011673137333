import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import { createCollectionState, getSelectors, removeAll, setAll, setOne } from '@/helpers/stores.helpers';
import router from '@/router';
import partnersService from '@/services/partners.service';

const { selectAllFiltered, selectTotal, selectSelected } = getSelectors();

export const useDataPartners = defineStore('partners', {
  state: () =>
    createCollectionState({
      loading: true,
      pending: true,
      error: null,
      filter: ''
    }),
  getters: {
    partners: selectAllFiltered(['name']),
    selectTotal,
    selectSelected
  },
  actions: {
    async getPartners() {
      try {
        const routeQuery = {
          ...router.currentRoute.query
        };
        this.getPartnersRequest();
        const partners = await partnersService.getPartners(getToken(), routeQuery);
        this.getPartnersSuccess(partners);
      } catch (error) {
        this.getPartnersFailure(error);
      }
    },
    async getPartnerById() {
      try {
        const routeQuery = {
          ...router.currentRoute.query
        };
        this.getPartnerByIdRequest();
        const partner = await partnersService.getPartner(getToken(), routeQuery);
        this.getPartnerByIdSuccess(partner);
        this.setSelected(partner.id);
      } catch (error) {
        this.getPartnerByIdFailure(error);
      }
    },
    setFilter(value) {
      this.setFilterValue(value);
    },
    setSelected(value) {
      this.setSelectedValue(value);
    },
    getPartnersRequest() {
      this.loading = true;
      this.error = null;
    },
    getPartnersSuccess(partners) {
      this.data = setAll(partners, this.data);
      this.error = null;
      this.loading = false;
    },
    getPartnersFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    getPartnerByIdRequest() {
      this.loading = true;
      this.error = null;
    },
    getPartnerByIdSuccess(partner) {
      this.data = removeAll(this.data);
      this.data = setOne(partner, this.data);
      this.error = null;
      this.loading = false;
    },
    getPartnerByIdFailure(error) {
      this.data = removeAll(this.data);
      this.error = error;
      this.loading = false;
    },
    setFilterValue(value) {
      this.filter = value;
    },
    setSelectedValue(value) {
      this.data.selected = value;
    }
  }
});
