<template>
  <div class="export-item-container">
    <div class="export-item" :class="{ selected: subMenuOpen }" @click="itemClicked">
      <vel-icon v-if="icon && icon.length" :name="icon" :size="iconSize" />
      <div class="export-item-title">
        <label>{{ $t(title) }}</label>
        <span v-if="description && description.length">{{ $t(description) }}</span>
      </div>
      <div v-if="subMenu.length" class="item-arrow">
        <RightArrow />
      </div>
    </div>
    <div v-if="subMenuOpen" class="submenu">
      <ul>
        <li v-for="(item, idx) in subMenu" :key="idx">
          <vel-export-item
            icon-size="medium"
            :title="item.title"
            :description="item.description"
            :icon="item.icon"
            @itemSelected="subMenuItemClicked(item.id)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import RightArrow from '@/assets/svg/icons/right-arrow.svg';
import VelIcon from '@/components/icon/VelIcon';

export default {
  name: 'vel-export-item',
  components: {
    RightArrow,
    VelIcon
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    iconSize: {
      type: String,
      default: 'small'
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    subMenu: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      subMenuOpen: false,
      subPanelItems: []
    };
  },
  methods: {
    itemClicked() {
      if (this.subMenu.length) {
        this.subMenuOpen = !this.subMenuOpen;
      } else {
        this.$emit('itemSelected', null);
      }
    },
    subMenuItemClicked(idx) {
      this.$emit('itemSelected', idx);
    }
  }
};
</script>

<style lang="scss" scoped>
$dark-blue-color: #00a9e1;
$light-blue-color: #e5f6fc;
$light-gray-color: #c7c7c7;
.export-item {
  display: flex;
  gap: 1em;
  align-items: center;
  padding: 0.2em 1em;
  cursor: pointer;

  &:hover,
  &.selected {
    background: $light-blue-color;
    color: $dark-blue-color;
    border-radius: 0.5em;

    & label {
      color: $dark-blue-color;
    }

    & > .item-arrow > svg {
      fill: $dark-blue-color;
    }
  }
}

.export-item-title {
  & > label,
  & > span {
    white-space: nowrap;
    cursor: pointer;
    margin: 0;
  }

  & > span {
    font-size: 12px;
  }

  display: flex;
  flex-direction: column;
}

.item-arrow {
  flex-grow: 1;
  text-align: right;

  & > svg {
    fill: $light-gray-color;
  }
}

.submenu {
  right: 10px;
  top: 20px;
  background: white;
  border-radius: 1em;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 12px #00000029;
  position: absolute;
  z-index: 100;
  padding: 15px;
  margin-top: 10px;
  font-size: 14px;
  color: $light-gray-color;
  font-family: inherit;

  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  & label {
    color: black;
    margin: 0;
    width: max-content;
  }
}
</style>
