import 'whatwg-fetch';
import { get, head, patch, post, put, resolveCommonQuery } from '@/helpers/service.helper';
import URI from 'urijs';
import pick from 'lodash.pick';
import { getObjectStringValues, getObjectTypeValues } from '@/helpers/object.helpers';
import { getToken } from '@/helpers/token.helper';

/**
 * Get all users
 * @link https://api.posveloce.com/docs/#operation/getUsers
 */
const getUsers = token => get(token, `/users`);

/**
 * Create a new user
 * @link https://api.posveloce.com/docs/#operation/createUser
 */
export const createUser = (token, user) => {
  const body = { ...user, enabled: true };
  return post(token, `/users?clientUrl=${location.origin}`, body);
};

/**
 * Get a user by its id
 * @link https://api.posveloce.com/docs/#operation/getUserById
 */
const getUser = (token, userId) => get(token, `/users/${userId}`);

/**
 * Update user
 * @link https://api.posveloce.com/docs/#operation/updateUser
 */
export const patchUser = (token, user) => patch(token, `/users/${user.id}`, user);

/**
 * Get permissions of a user
 * @link https://api.posveloce.com/docs/#operation/getUserPermissions
 */
const getUserPermissions = (token, query) => {
  const params = pick(query, ['include']);
  const url = resolveCommonQuery(`/users/${query.userId}/permissions`, params).toString();
  return get(token, url);
};

/**
 * Update (override) permissions of a user
 * @link https://api.posveloce.com/docs/#operation/updateUserPermissions
 */
const updateUserPermissions = (token, userId, permissions) => {
  const url = resolveCommonQuery(`/users/${userId}/permissions`, permissions).toString();
  return put(token, url, permissions);
};

/**
 * Generate user password reset token
 * @url https://api.posveloce.com/docs/#operation/resetPasswordTokenUnauthenticated
 */
export const resetPasswordTokenUnauthenticated = email => {
  const url = URI(`/users/unauthenticated/${email}/resetToken?clientUrl=${location.origin}`).toString();
  return post(null, url, null);
};

/**
 * Reset a user's password with a reset token and a new password
 * @link https://api.posveloce.com/docs/#operation/resetPassword
 */
export const resetPassword = (newPassword, resetToken) => {
  const url = URI(`/users/resetPassword`).toString();
  return post(null, url, { password: newPassword, resetToken });
};

/**
 * Change a user's password
 * @link https://api.posveloce.com/docs/#operation/changePassword
 */
export const changePassword = (token, password, newPassword, userID) => {
  const url = URI(`/users/${userID}/changePassword`).toString();
  return post(token, url, { password, newPassword });
};

/**
 * Checks the validity of a password reset token
 * @link https://api.posveloce.com/docs/#operation/isPasswordResetTokenValid
 */
export const verifyResetToken = resetToken => head(`/users/resetToken/${resetToken}`);

/**
 * Missing doc
 */
export const getPasswordResetToken = (token, email) => {
  const url = `/users/${email}/resetToken?clientUrl=${location.origin}`;
  return post(token, url, null);
};

/**
 * Get user settings
 */
export const getUserSettings = async query => {
  const params = pick(query, ['userIDs', 'limit', 'offset']);

  const url = resolveCommonQuery(`/users/settings`, params).toString();
  return getObjectTypeValues(await get(getToken(), url).then(x => (x && x[0] && x[0].settings) || {}));
};

/**
 * Update or create user settings
 */
export const putUserSettings = (userId, settings) => {
  const url = `/users/settings`;
  return put(getToken(), url, [{ userId, settings: getObjectStringValues(settings) }]);
};

export default {
  createUser,
  changePassword,
  getUser,
  getUserPermissions,
  getPasswordResetToken,
  getUsers,
  patchUser,
  updateUserPermissions,
  resetPassword,
  resetPasswordTokenUnauthenticated,
  verifyResetToken,
  getUserSettings,
  putUserSettings
};
