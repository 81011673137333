<template>
  <div style="background-color: #f5f5f5; margin: 0 -15px;">
    <h4
      class="p-3 mb-2 bg-white w-100"
      style="border-bottom: 1px solid rgba(76, 86, 103, 0.2); border-top: 1px solid rgba(76, 86, 103, 0.2);"
    >
      {{ form.id ? form.name : $t('accessType.title', { description: form.name }) }}
    </h4>
    <b-tabs v-model="selectedTab" class="bg-white">
      <b-tab :title="$t('accessType.tabs.general.title')" title-item-class="col-sm-12 col-md-auto p-0 m-0" v-if="form">
        <h5 class="pt-4 px-4 pb-2">{{ $t('accessType.tabs.general.employeeAccessSetup') }}</h5>
        <b-container fluid class="content p-4">
          <b-row>
            <b-col cols="12" md="3">
              <b-form-group
                :label="$t('accessType.tabs.general.description.label')"
                :description="$t('employeeCreate.required')"
              >
                <b-form-input
                  id="name"
                  :state="!enableSaveChanges ? null : form.name !== '' && null"
                  :placeholder="$t('accessType.tabs.general.description.placeholder')"
                  :class="{ required: !form.name || form.name === '' }"
                  v-model="form.name"
                  aria-describedby="passwd-feedback"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <b-form-group
                :label="$t('accessType.tabs.general.employeeType.label')"
                :description="$t('employeeCreate.required')"
              >
                <b-form-select
                  :options="[
                    { id: 2, name: 'Bar Waiter' },
                    { id: 4, name: 'Cash clerk' },
                    { id: 9, name: 'Cash clerk-Manager' },
                    { id: 6, name: 'Driver' },
                    { id: 3, name: 'Head waiter' },
                    { id: 8, name: 'Hostess' },
                    { id: 0, name: 'In training' },
                    { id: 5, name: 'Manager' },
                    { id: 7, name: 'None' },
                    { id: 1, name: 'Waiter' },
                    { id: null, name: $t('employeeCreate.emptyValue') }
                  ]"
                  id="test"
                  value-field="id"
                  text-field="name"
                  aria-describedby="input-live-help input-live-feedback"
                  class="form-text"
                  :class="{ required: !form.employeeType }"
                  v-model="form.employeeType"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <!--
            <b-col cols="12" md="4" class="d-flex align-items-center mb-2 mb-md-0 pb-4">
              {{ $t('accessType.tabs.general.jobRole.label') }}
            </b-col>
            <b-col cols="12" md="8">
              <b-form-select
                :options="[...jobRoles, { id: null, name: $t('employeeCreate.emptyValue') }]"
                id="test"
                value-field="id"
                text-field="name"
                aria-describedby="input-live-help input-live-feedback"
                class="form-text"
                :class="{ required: !form.taskId }"
                v-model="form.taskId"
              ></b-form-select>
              <b-form-text id="input-live-help">{{ $t('employeeCreate.required') }}</b-form-text>
              <b-form-invalid-feedback id="passwd-feedback">
                {{ $t('employeeCreate.required') }}
              </b-form-invalid-feedback>
            </b-col>
            -->
          </b-row>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.general.generalAccess') }}
        </h5>
        <b-container fluid class="content p-4">
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="onlyOneWorkstationAtTime"
                v-model="form.accessTypes.general.onlyOneWorkstationAtTime"
                name="onlyOneWorkstationAtTime"
              >
                {{ $t('accessType.tabs.general.onlyOneWorkstationAtTime') }}
              </b-form-checkbox>
              <b-form-checkbox id="useTables" v-model="form.accessTypes.general.useTables" name="useTables">
                {{ $t('accessType.tabs.general.useTables') }}
              </b-form-checkbox>
              <b-form-checkbox id="qsr" v-model="form.accessTypes.general.qsr" name="qsr">
                {{ $t('accessType.tabs.general.qsr') }}
              </b-form-checkbox>
              <b-form-checkbox id="delivery" v-model="form.accessTypes.general.delivery" name="delivery">
                {{ $t('accessType.tabs.general.delivery') }}
              </b-form-checkbox>
              <b-form-checkbox id="takeout" v-model="form.accessTypes.general.takeout" name="takeout">
                {{ $t('accessType.tabs.general.takeout') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="driveThruLane"
                @change="check"
                v-model="form.accessTypes.general.driveThruLane"
                name="driveThruLane"
              >
                {{ $t('accessType.tabs.general.driveThruLane') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="driveThruLaneIsDriveThruLane"
                v-model="form.accessTypes.general.driveThruLaneIsDriveThruLane"
                name="driveThruLaneIsDriveThruLane"
                class="ml-4"
                :disabled="!form.accessTypes.general.driveThruLane"
              >
                {{ $t('accessType.tabs.general.driveThruLaneIsDriveThruLane') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="runningTotals"
                v-model="form.accessTypes.general.runningTotals"
                name="runningTotals"
              >
                {{ $t('accessType.tabs.general.runningTotals') }}
              </b-form-checkbox>
              <b-form-group class="pl-4">
                <b-form-checkbox
                  id="runningTotalsOrderOnHold"
                  inline
                  v-model="form.accessTypes.general.runningTotalsOrderOnHold"
                  name="runningTotalsOrderOnHold"
                  :disabled="!form.accessTypes.general.runningTotals"
                >
                  {{ $t('accessType.tabs.general.runningTotalsOrderOnHold') }}
                </b-form-checkbox>
                <b-form-checkbox
                  id="runningTotalsSearchName"
                  inline
                  v-model="form.accessTypes.general.runningTotalsSearchName"
                  name="runningTotalsSearchName"
                  :disabled="!form.accessTypes.general.runningTotals"
                >
                  {{ $t('accessType.tabs.general.runningTotalsSearchName') }}
                </b-form-checkbox>
              </b-form-group>
              <b-form-checkbox id="voids" v-model="form.accessTypes.general.voids" name="voids">
                {{ $t('accessType.tabs.general.voids') }}
              </b-form-checkbox>
              <b-form-checkbox id="refunds" v-model="form.accessTypes.general.refunds" name="refunds">
                {{ $t('accessType.tabs.general.refunds') }}
              </b-form-checkbox>
              <b-form-checkbox id="paymentAccess" v-model="form.accessTypes.general.paymentAccess" name="paymentAccess">
                {{ $t('accessType.tabs.general.paymentAccess') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="modifyClosedPayments"
                v-model="form.accessTypes.general.modifyClosedPayments"
                name="modifyClosedPayments"
              >
                {{ $t('accessType.tabs.general.notifyClosedPayments') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="unrestrictedScreenProgramming"
                v-model="form.accessTypes.general.unrestrictedScreenProgramming"
                name="unrestrictedScreenProgramming"
              >
                {{ $t('accessType.tabs.general.unrestrictedScreenProgramming') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="openDrawer"
                v-model="form.accessTypes.general.openDrawer"
                name="openDrawer"
              >
                {{ $t('accessType.tabs.general.openDrawer') }}
              </b-form-checkbox>
              <b-form-group class="pl-4">
                <b-form-checkbox
                  id="disableOpenDrawerDuringOrder"
                  inline
                  v-model="form.accessTypes.general.disableOpenDrawerDuringOrder"
                  name="disableOpenDrawerDuringOrder"
                  :disabled="!form.accessTypes.general.openDrawer"
                >
                  {{ $t('accessType.tabs.general.disableOpenDrawerDuringOrder') }}
                </b-form-checkbox>
              </b-form-group>
              <b-form-checkbox id="cancel" v-model="form.accessTypes.general.cancel" name="cancel">
                {{ $t('accessType.tabs.general.cancel') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="viewPaymentBalances"
                v-model="form.accessTypes.general.viewPaymentBalances"
                name="viewPaymentBalances"
              >
                {{ $t('accessType.tabs.general.viewPaymentBalances') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">{{ $t('accessType.tabs.general.tipsGeneralConfiguration') }}</h5>
        <b-container fluid class="content p-4">
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox id="tips" v-model="form.accessTypes.general.tips" name="tips">
                {{ $t('accessType.tabs.general.tips') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="tipDeclaration"
                v-model="form.accessTypes.general.tipDeclaration"
                name="tipDeclaration"
              >
                {{ $t('accessType.tabs.general.tipDeclaration') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="tipDeclarationPriorCashout"
                v-model="form.accessTypes.general.tipDeclarationPriorCashout"
                name="tipDeclarationPriorCashout"
                class="ml-4"
                :disabled="!form.accessTypes.general.tipDeclaration"
              >
                {{ $t('accessType.tabs.general.tipDeclarationPriorCashout') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="xxxxxxxx"
                v-model="form.accessTypes.general.tipDeclarationShowTipDeclaration"
                name="xxxxxxxx"
                class="ml-4"
                :disabled="!form.accessTypes.general.tipDeclaration"
              >
                {{ $t('accessType.tabs.general.tipDeclarationShowTipDeclaration') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="tipDeclarationSaveTipDeclaration"
                v-model="form.accessTypes.general.tipDeclarationSaveTipDeclaration"
                name="tipDeclarationSaveTipDeclaration"
                class="ml-4"
                :disabled="!form.accessTypes.general.tipDeclaration"
              >
                {{ $t('accessType.tabs.general.tipDeclarationSaveTipDeclaration') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="tipDistributionTeams"
                v-model="form.accessTypes.general.tipDistributionTeams"
                name="tipDistributionTeams"
              >
                {{ $t('accessType.tabs.general.tipDistributionTeams') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="tipDistributionTeamsRemoveUponDeclaration"
                v-model="form.accessTypes.general.tipDistributionTeamsRemoveUponDeclaration"
                name="tipDistributionTeamsRemoveUponDeclaration"
                class="ml-4"
                :disabled="!form.accessTypes.general.tipDistributionTeams"
              >
                {{ $t('accessType.tabs.general.tipDistributionTeamsRemoveUponDeclaration') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="tipTransfer"
                v-model="form.accessTypes.general.tipTransfer"
                name="tipTransfer"
              >
                {{ $t('accessType.tabs.general.tipTransfer') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="tipTransferEmployeeSelection"
                v-model="form.accessTypes.general.tipTransferEmployeeSelection"
                name="tipTransferEmployeeSelection"
                class="ml-4"
                :disabled="!form.accessTypes.general.tipTransfer"
              >
                {{ $t('accessType.tabs.general.tipTransferEmployeeSelection') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4"></b-col>
          </b-row>
          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.general.tipsOptionalConfiguration') }}
          </h6>
          <b-row>
            <b-col>
              <b-row class="mb-3" style="max-width: 500px;">
                <b-col cols="12" md="3" class="d-flex align-items-center mb-md-0">
                  {{ $t('accessType.tabs.general.bussers.label') }}
                </b-col>
                <b-col cols="12" md="9">
                  <b-input-group prepend="%" class="align-items-center">
                    <b-form-input
                      id="name"
                      no-wheel
                      type="number"
                      number
                      :min="0"
                      :max="100"
                      v-model="form.accessTypes.general.tipDistributionBussersPercentage"
                      :state="!enableSaveChanges ? null : form.accessTypes.name !== '' && null"
                      :placeholder="$t('accessType.tabs.general.bussers.placeholder')"
                      aria-describedby="passwd-feedback"
                    ></b-form-input>
                    <b-form-checkbox
                      class="ml-5 my-0"
                      id="tipDistributionIncludeServers"
                      v-model="form.accessTypes.general.tipDistributionIncludeServers"
                      name="tipDistributionIncludeServers"
                    >
                      {{ $t('accessType.tabs.general.tipDistributionIncludeServers') }}
                    </b-form-checkbox>

                    <b-form-checkbox
                      class="ml-5 my-0"
                      id="tipDistributionIncludeCooks"
                      v-model="form.accessTypes.general.tipDistributionIncludeCooks"
                      name="tipDistributionIncludeCooks"
                    >
                      {{ $t('accessType.tabs.general.tipDistributionIncludeCooks') }}
                    </b-form-checkbox>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.general.accessMode') }}
        </h5>

        <b-container fluid class="content p-4">
          <b-input-group class="align-items-md-center flex-md-row flex-column">
            <b-form-checkbox
              class="mr-5"
              @change="onModeChange($event, mode.id)"
              v-for="mode of modes"
              :key="mode.id"
              name="accessModes"
              v-model="modesForm[mode.id]"
            >
              {{ mode.name }}
            </b-form-checkbox>
            <b-form-checkbox
              class="mr-md-5"
              id="accessPromptMode"
              v-model="form.accessTypes.general.accessPromptMode"
              name="accessPromptMode"
            >
              {{ $t('accessType.tabs.general.accessPromptMode') }}
            </b-form-checkbox>
          </b-input-group>
          <b-row>
            <b-col cols="12" md="3">
              <b-form-group :label="$t('accessType.tabs.general.forcedMode.label')" class="mt-3">
                <b-form-select
                  :options="[...modes, { id: null, name: $t('employeeCreate.emptyValue') }]"
                  id="test"
                  value-field="id"
                  text-field="name"
                  aria-describedby="input-live-help input-live-feedback"
                  class="form-text"
                  v-model="form.accessTypes.general.accessForcedModeId"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.general.clientAccount') }}
        </h5>

        <b-container fluid class="content p-4">
          <b-row>
            <b-col>
              <b-form-checkbox
                @change="check"
                id="accessClientParams"
                v-model="form.accessTypes.general.accessClientParams"
                name="accessClientParams"
              >
                {{ $t('accessType.tabs.general.accessClientParams') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="accessClientParamsAccountPayments"
                v-model="form.accessTypes.general.accessClientParamsAccountPayments"
                name="accessClientParamsAccountPayments"
                class="ml-4"
                :disabled="!form.accessTypes.general.accessClientParams"
              >
                {{ $t('accessType.tabs.general.accessClientParamsAccountPayments') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="accessClientParamsClientRec"
                v-model="form.accessTypes.general.accessClientParamsClientRec"
                name="accessClientParamsClientRec"
                class="ml-4"
                :disabled="!form.accessTypes.general.accessClientParams"
              >
                {{ $t('accessType.tabs.general.accessClientParamsClientRec') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="accessClientParamsClientRecCreateNewClient"
                v-model="form.accessTypes.general.accessClientParamsClientRecCreateNewClient"
                name="accessClientParamsClientRecCreateNewClient"
                class="ml-5"
                :disabled="
                  !form.accessTypes.general.accessClientParams || !form.accessTypes.general.accessClientParamsClientRec
                "
              >
                {{ $t('accessType.tabs.general.accessClientParamsClientRecCreateNewClient') }}
              </b-form-checkbox>

              <b-form-checkbox
                @change="check"
                id="accessClientParamsClientRecEditPin"
                v-model="form.accessTypes.general.accessClientParamsClientRecEditPin"
                name="accessClientParamsClientRecEditPin"
                class="ml-5"
                :disabled="
                  !form.accessTypes.general.accessClientParams || !form.accessTypes.general.accessClientParamsClientRec
                "
              >
                {{ $t('accessType.tabs.general.accessClientParamsClientRecEditPin') }}
              </b-form-checkbox>
              <div class="ml-4">
                <b-form-checkbox
                  id="accessClientParamsClientRecEditPinEventNoPreviousInfo"
                  v-model="form.accessTypes.general.accessClientParamsClientRecEditPinEventNoPreviousInfo"
                  name="accessClientParamsClientRecEditPinEventNoPreviousInfo"
                  class="ml-5"
                  :disabled="
                    !form.accessTypes.general.accessClientParams ||
                    !form.accessTypes.general.accessClientParamsClientRec ||
                    !form.accessTypes.general.accessClientParamsClientRecEditPin
                  "
                >
                  {{ $t('accessType.tabs.general.accessClientParamsClientRecEditPinEventNoPreviousInfo') }}
                </b-form-checkbox>
                <b-form-checkbox
                  id="accessClientParamsReports"
                  v-model="form.accessTypes.general.accessClientParamsReports"
                  name="accessClientParamsReports"
                  :disabled="!form.accessTypes.general.accessClientParams"
                >
                  {{ $t('accessType.tabs.general.accessClientParamsReports') }}
                </b-form-checkbox>
                <b-form-checkbox
                  id="accessClientParamsBypassCreditLimit"
                  v-model="form.accessTypes.general.accessClientParamsBypassCreditLimit"
                  name="accessClientParamsBypassCreditLimit"
                  :disabled="!form.accessTypes.general.accessClientParams"
                >
                  {{ $t('accessType.tabs.general.accessClientParamsBypassCreditLimit') }}
                </b-form-checkbox>
                <b-form-checkbox
                  id="accessClientParamsAccountBalance"
                  v-model="form.accessTypes.general.accessClientParamsAccountBalance"
                  name="accessClientParamsAccountBalance"
                  :disabled="!form.accessTypes.general.accessClientParams"
                >
                  {{ $t('accessType.tabs.general.accessClientParamsAccountBalance') }}
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col>
              <b-form-checkbox
                id="clientAccountRentals"
                v-model="form.accessTypes.general.clientAccountRentals"
                name="clientAccountRentals"
                class="ml-4"
              >
                {{ $t('accessType.tabs.general.clientAccountRentals') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="clientAccountRoomService"
                v-model="form.accessTypes.general.clientAccountRoomService"
                name="clientAccountRoomService"
                class="ml-4"
              >
                {{ $t('accessType.tabs.general.clientAccountRoomService') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="clientAccountSelfServiceKiosk"
                v-model="form.accessTypes.general.clientAccountSelfServiceKiosk"
                name="clientAccountSelfServiceKiosk"
                class="ml-4"
              >
                {{ $t('accessType.tabs.general.clientAccountSelfServiceKiosk') }}
              </b-form-checkbox>
            </b-col>
            <b-col></b-col>
          </b-row>
        </b-container>
      </b-tab>
      <b-tab :title="$t('accessType.tabs.dining.title')" title-item-class="col-sm-12 col-md-auto p-0 m-0">
        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.dining.tableManagement') }}
        </h5>

        <b-container fluid class="content p-4">
          <h6 class="mb-2">
            {{ $t('accessType.tabs.dining.section') }}
          </h6>
          <b-row>
            <b-col>
              <b-form-checkbox
                id="sectionDeleteAssigned"
                v-model="form.accessTypes.dining.sectionDeleteAssigned"
                name="sectionDeleteAssigned"
              >
                {{ $t('accessType.tabs.dining.sectionDeleteAssigned') }}
              </b-form-checkbox>
              <b-form-checkbox id="useTables" v-model="form.accessTypes.dining.sectionEdit" name="useTables">
                {{ $t('accessType.tabs.dining.sectionEdit') }}
              </b-form-checkbox>
              <h6 class="mt-3 mb-3">{{ $t('accessType.tabs.dining.misc') }}</h6>
              <b-row>
                <b-col md="3">
                  <b-form-checkbox
                    id="miscAutoTableNumber"
                    v-model="form.accessTypes.dining.miscAutoTableNumber"
                    name="miscAutoTableNumber"
                  >
                    {{ $t('accessType.tabs.dining.miscAutoTableNumber') }}
                  </b-form-checkbox>
                </b-col>
                <b-col md="3">
                  <b-form-select
                    :options="[...tablesSections, { id: null, name: $t('employeeCreate.emptyValue') }]"
                    id="test"
                    value-field="id"
                    text-field="name"
                    aria-describedby="input-live-help input-live-feedback"
                    class="form-text"
                    v-model="form.accessTypes.dining.miscAutoTableSectionId"
                  ></b-form-select>
                </b-col>
              </b-row>

              <b-form-checkbox
                id="miscDisableTapMap"
                v-model="form.accessTypes.dining.miscDisableTapMap"
                name="miscDisableTapMap"
              >
                {{ $t('accessType.tabs.dining.miscDisableTapMap') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="miscCallEmployeeTable"
                v-model="form.accessTypes.dining.miscCallEmployeeTable"
                name="miscCallEmployeeTable"
              >
                {{ $t('accessType.tabs.dining.miscCallEmployeeTable') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="miscWarnWrongTable"
                v-model="form.accessTypes.dining.miscWarnWrongTable"
                name="miscWarnWrongTable"
              >
                {{ $t('accessType.tabs.dining.miscWarnWrongTable') }}
              </b-form-checkbox>

              <h6 class="mt-3 mb-3">{{ $t('accessType.tabs.dining.hostess') }}</h6>

              <b-form-checkbox
                id="hostessClearTableMapSections"
                v-model="form.accessTypes.dining.hostessClearTableMapSections"
                name="hostessClearTableMapSections"
              >
                {{ $t('accessType.tabs.dining.hostessClearTableMapSections') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="hostessAutoAssignTableMap"
                v-model="form.accessTypes.dining.hostessAutoAssignTableMap"
                name="hostessAutoAssignTableMap"
              >
                {{ $t('accessType.tabs.dining.hostessAutoAssignTableMap') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="hostessConfirmFunctions"
                v-model="form.accessTypes.dining.hostessConfirmFunctions"
                name="hostessConfirmFunctions"
              >
                {{ $t('accessType.tabs.dining.hostessConfirmFunctions') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="hostessManualAssignEmployeeTable"
                v-model="form.accessTypes.dining.hostessManualAssignEmployeeTable"
                name="hostessManualAssignEmployeeTable"
              >
                {{ $t('accessType.tabs.dining.hostessManualAssignEmployeeTable') }}
              </b-form-checkbox>

              <h6 class="mt-3 mb-3">{{ $t('accessType.tabs.dining.operations') }}</h6>

              <b-form-checkbox
                id="operationAutoExit"
                v-model="form.accessTypes.dining.operationAutoExit"
                name="operationAutoExit"
              >
                {{ $t('accessType.tabs.dining.operationAutoExit') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="operationAutoSeating"
                v-model="form.accessTypes.dining.operationAutoSeating"
                name="operationAutoSeating"
              >
                {{ $t('accessType.tabs.dining.operationAutoSeating') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="operationReturnGroupAfterOrder"
                v-model="form.accessTypes.dining.operationReturnGroupAfterOrder"
                name="operationReturnGroupAfterOrder"
              >
                {{ $t('accessType.tabs.dining.operationReturnGroupAfterOrder') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="operationOrderReview"
                v-model="form.accessTypes.dining.operationOrderReview"
                name="operationOrderReview"
              >
                {{ $t('accessType.tabs.dining.operationOrderReview') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="operationConfirmTransaction"
                v-model="form.accessTypes.dining.operationConfirmTransaction"
                name="operationConfirmTransaction"
              >
                {{ $t('accessType.tabs.dining.operationConfirmTransaction') }}
              </b-form-checkbox>
              <b-row>
                <b-col md="3" class="pr-md-6">
                  <b-form-group :label="$t('accessType.tabs.dining.operationCommonReading.label')" class="mt-3">
                    <b-form-select
                      :options="commonReadings"
                      id="test"
                      value-field="id"
                      text-field="name"
                      aria-describedby="input-live-help input-live-feedback"
                      class="form-text"
                      v-model="form.accessTypes.dining.operationCommonReading"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="3" class="pl-md-6">
                  <b-form-group :label="$t('accessType.tabs.dining.operationOperatingMode.label')" class="mt-3">
                    <b-form-select
                      :options="operatingModes"
                      id="test"
                      value-field="id"
                      text-field="name"
                      aria-describedby="input-live-help input-live-feedback"
                      class="form-text"
                      v-model.number="form.accessTypes.dining.operationOperatingMode"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">{{ $t('accessType.tabs.dining.reservation') }}</h5>

        <b-container fluid class="content p-4">
          <b-row>
            <b-col>
              <b-form-checkbox
                id="reservationTableAgendaAccess"
                v-model="form.accessTypes.dining.reservationTableAgendaAccess"
                name="reservationTableAgendaAccess"
              >
                {{ $t('accessType.tabs.dining.reservationTableAgendaAccess') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="reservationStartAgendaAccess"
                v-model="form.accessTypes.dining.reservationStartAgendaAccess"
                name="reservationStartAgendaAccess"
              >
                {{ $t('accessType.tabs.dining.reservationStartAgendaAccess') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="reservationRoomAgendaAccess"
                v-model="form.accessTypes.dining.reservationRoomAgendaAccess"
                name="reservationRoomAgendaAccess"
              >
                {{ $t('accessType.tabs.dining.reservationRoomAgendaAccess') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="reservationDisplayOrderSummaryKitchen"
                v-model="form.accessTypes.dining.reservationDisplayOrderSummaryKitchen"
                name="reservationDisplayOrderSummaryKitchen"
              >
                {{ $t('accessType.tabs.dining.reservationDisplayOrderSummaryKitchen') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">{{ $t('accessType.tabs.dining.invoicing') }}</h5>
        <b-container fluid class="content p-4">
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="invoicingCashClerk"
                v-model="form.accessTypes.dining.invoicingCashClerk"
                name="invoicingCashClerk"
              >
                {{ $t('accessType.tabs.dining.invoicingCashClerk') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="invoicingCustomerRedistributionCheck"
                v-model="form.accessTypes.dining.invoicingCustomerRedistributionCheck"
                name="invoicingCustomerRedistributionCheck"
              >
                {{ $t('accessType.tabs.dining.invoicingCustomerRedistributionCheck') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="invoicingCustomerRedistributionCheckWithoutDiscount"
                v-model="form.accessTypes.dining.invoicingCustomerRedistributionCheckWithoutDiscount"
                name="invoicingCustomerRedistributionCheckWithoutDiscount"
                class="ml-4"
                :disabled="!form.accessTypes.dining.invoicingCustomerRedistributionCheck"
              >
                {{ $t('accessType.tabs.dining.invoicingCustomerRedistributionCheckWithoutDiscount') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="invoicingDiscounts"
                @change="check"
                v-model="form.accessTypes.dining.invoicingDiscounts"
                name="invoicingDiscounts"
              >
                {{ $t('accessType.tabs.dining.invoicingDiscounts') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="invoicingDiscountsAfterCheck"
                v-model="form.accessTypes.dining.invoicingDiscountsAfterCheck"
                name="invoicingDiscountsAfterCheck"
                class="ml-4"
                :disabled="!form.accessTypes.dining.invoicingDiscounts"
              >
                {{ $t('accessType.tabs.dining.invoicingDiscountsAfterCheck') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="invoicingOrderAfterCheck"
                v-model="form.accessTypes.dining.invoicingOrderAfterCheck"
                name="invoicingOrderAfterCheck"
              >
                {{ $t('accessType.tabs.dining.invoicingOrderAfterCheck') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="invoicingTransferPaymentDifferentCheck"
                v-model="form.accessTypes.dining.invoicingTransferPaymentDifferentCheck"
                name="invoicingTransferPaymentDifferentCheck"
              >
                {{ $t('accessType.tabs.dining.invoicingTransferPaymentDifferentCheck') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="invoicingTransferPaymentDifferentCheckSelectDestination"
                v-model="form.accessTypes.dining.invoicingTransferPaymentDifferentCheckSelectDestination"
                name="invoicingTransferPaymentDifferentCheckSelectDestination"
                class="ml-4"
                :disabled="!form.accessTypes.dining.invoicingTransferPaymentDifferentCheck"
              >
                {{ $t('accessType.tabs.dining.invoicingTransferPaymentDifferentCheckSelectDestination') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="invoicingAllCashButton"
                v-model="form.accessTypes.dining.invoicingAllCashButton"
                name="invoicingAllCashButton"
              >
                {{ $t('accessType.tabs.dining.invoicingAllCashButton') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="invoicingReturnOrderScreenAfterPayment"
                v-model="form.accessTypes.dining.invoicingReturnOrderScreenAfterPayment"
                name="invoicingReturnOrderScreenAfterPayment"
              >
                {{ $t('accessType.tabs.dining.invoicingReturnOrderScreenAfterPayment') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="invoicingEnforceDrawClosePriorNextOrder"
                v-model="form.accessTypes.dining.invoicingEnforceDrawClosePriorNextOrder"
                name="invoicingEnforceDrawClosePriorNextOrder"
              >
                {{ $t('accessType.tabs.dining.invoicingEnforceDrawClosePriorNextOrder') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="invoicingPaymentDeleteClosedCheck"
                v-model="form.accessTypes.dining.invoicingPaymentDeleteClosedCheck"
                name="invoicingPaymentDeleteClosedCheck"
              >
                {{ $t('accessType.tabs.dining.invoicingPaymentDeleteClosedCheck') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="invoicingPaymentDeleteClosedCheckEdit"
                v-model="form.accessTypes.dining.invoicingPaymentDeleteClosedCheckEdit"
                name="invoicingPaymentDeleteClosedCheckEdit"
                class="ml-4"
                :disabled="!form.accessTypes.dining.invoicingPaymentDeleteClosedCheck"
              >
                {{ $t('accessType.tabs.dining.invoicingPaymentDeleteClosedCheckEdit') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="invoicingNoCheckButton"
                v-model="form.accessTypes.dining.invoicingNoCheckButton"
                name="invoicingNoCheckButton"
              >
                {{ $t('accessType.tabs.dining.invoicingNoCheckButton') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="invoicingChoosePrinter"
                v-model="form.accessTypes.dining.invoicingChoosePrinter"
                name="invoicingChoosePrinter"
              >
                {{ $t('accessType.tabs.dining.invoicingChoosePrinter') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="invoicingPrintDuplicates"
                v-model="form.accessTypes.dining.invoicingPrintDuplicates"
                name="invoicingPrintDuplicates"
              >
                {{ $t('accessType.tabs.dining.invoicingPrintDuplicates') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="invoicingPrintInternalCopies"
                v-model="form.accessTypes.dining.invoicingPrintInternalCopies"
                name="invoicingPrintInternalCopies"
              >
                {{ $t('accessType.tabs.dining.invoicingPrintInternalCopies') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="invoicingPrintPricelessCopies"
                v-model="form.accessTypes.dining.invoicingPrintPricelessCopies"
                name="invoicingPrintPricelessCopies"
              >
                {{ $t('accessType.tabs.dining.invoicingPrintPricelessCopies') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">{{ $t('accessType.tabs.dining.cashDrawer') }}</h5>

        <b-container fluid class="content p-4">
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="cashDrawerManagement"
                v-model="form.accessTypes.dining.cashDrawerManagement"
                name="cashDrawerManagement"
              >
                {{ $t('accessType.tabs.dining.cashDrawerManagement') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="cashDrawerEnforceAssign"
                v-model="form.accessTypes.dining.cashDrawerEnforceAssign"
                name="cashDrawerEnforceAssign"
                class="ml-4"
                :disabled="!form.accessTypes.dining.cashDrawerManagement"
              >
                {{ $t('accessType.tabs.dining.cashDrawerEnforceAssign') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="cashDrawerCashInDeposit"
                v-model="form.accessTypes.dining.cashDrawerCashInDeposit"
                name="cashDrawerCashInDeposit"
                class="ml-4"
                :disabled="!form.accessTypes.dining.cashDrawerManagement"
              >
                {{ $t('accessType.tabs.dining.cashDrawerCashInDeposit') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="cashDrawerOpenUnassignDrawer"
                v-model="form.accessTypes.dining.cashDrawerOpenUnassignDrawer"
                name="cashDrawerOpenUnassignDrawer"
                class="ml-4"
                :disabled="!form.accessTypes.dining.cashDrawerManagement"
              >
                {{ $t('accessType.tabs.dining.cashDrawerOpenUnassignDrawer') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="cashDrawerBankDeposit"
                v-model="form.accessTypes.dining.cashDrawerBankDeposit"
                name="cashDrawerBankDeposit"
                class="ml-4"
                :disabled="!form.accessTypes.dining.cashDrawerManagement"
              >
                {{ $t('accessType.tabs.dining.cashDrawerBankDeposit') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="cashDrawerDepositCashedIn"
                v-model="form.accessTypes.dining.cashDrawerDepositCashedIn"
                name="cashDrawerDepositCashedIn"
              >
                {{ $t('accessType.tabs.dining.cashDrawerDepositCashedIn') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="cashDrawerEnforceCashedIn"
                v-model="form.accessTypes.dining.cashDrawerEnforceCashedIn"
                name="cashDrawerEnforceCashedIn"
              >
                {{ $t('accessType.tabs.dining.cashDrawerEnforceCashedIn') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="cashDrawerEnforceCashedInBlockOperations"
                v-model="form.accessTypes.dining.cashDrawerEnforceCashedInBlockOperations"
                name="cashDrawerEnforceCashedInBlockOperations"
                class="ml-4"
                :disabled="!form.accessTypes.dining.cashDrawerEnforceCashedIn"
              >
                {{ $t('accessType.tabs.dining.cashDrawerEnforceCashedInBlockOperations') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4"></b-col>
          </b-row>
        </b-container>

        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.dining.visualConfiguration') }}
        </h5>

        <b-container fluid class="content p-4">
          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.dining.onScreenButtons') }}
          </h6>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="visualScreenSend"
                v-model="form.accessTypes.dining.visualScreenSend"
                name="visualScreenSend"
              >
                {{ $t('accessType.tabs.dining.visualScreenSend') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenPrint"
                v-model="form.accessTypes.dining.visualScreenPrint"
                name="visualScreenPrint"
              >
                {{ $t('accessType.tabs.dining.visualScreenPrint') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenClose"
                v-model="form.accessTypes.dining.visualScreenClose"
                name="visualScreenClose"
              >
                {{ $t('accessType.tabs.dining.visualScreenClose') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenMenu"
                v-model="form.accessTypes.dining.visualScreenMenu"
                name="visualScreenMenu"
              >
                {{ $t('accessType.tabs.dining.visualScreenMenu') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenTakeout"
                v-model="form.accessTypes.dining.visualScreenTakeout"
                name="visualScreenTakeout"
              >
                {{ $t('accessType.tabs.dining.visualScreenTakeout') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenTableTakeout"
                v-model="form.accessTypes.dining.visualScreenTableTakeout"
                name="visualScreenTableTakeout"
              >
                {{ $t('accessType.tabs.dining.visualScreenTableTakeout') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="visualScreenRecipe"
                v-model="form.accessTypes.dining.visualScreenRecipe"
                name="visualScreenRecipe"
              >
                {{ $t('accessType.tabs.dining.visualScreenRecipe') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenRecipePrint"
                v-model="form.accessTypes.dining.visualScreenRecipePrint"
                name="visualScreenRecipePrint"
                class="ml-4"
                :disabled="!form.accessTypes.dining.visualScreenRecipe"
              >
                {{ $t('accessType.tabs.dining.visualScreenRecipePrint') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenTransferTable"
                v-model="form.accessTypes.dining.visualScreenTransferTable"
                name="visualScreenTransferTable"
              >
                {{ $t('accessType.tabs.dining.visualScreenTransferTable') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="visualScreenTransferCustomer"
                v-model="form.accessTypes.dining.visualScreenTransferCustomer"
                name="visualScreenTransferCustomer"
              >
                {{ $t('accessType.tabs.dining.visualScreenTransferCustomer') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="visualScreenTransferCustomerPromptPassword"
                v-model="form.accessTypes.dining.visualScreenTransferCustomerPromptPassword"
                name="visualScreenTransferCustomerPromptPassword"
                class="ml-4"
                :disabled="!form.accessTypes.dining.visualScreenTransferCustomer"
              >
                {{ $t('accessType.tabs.dining.visualScreenTransferCustomerPromptPassword') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenTransferCustomerOnlyNoChecks"
                v-model="form.accessTypes.dining.visualScreenTransferCustomerOnlyNoChecks"
                name="visualScreenTransferCustomerOnlyNoChecks"
                class="ml-4"
                :disabled="!form.accessTypes.dining.visualScreenTransferCustomer"
              >
                {{ $t('accessType.tabs.dining.visualScreenTransferCustomerOnlyNoChecks') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="visualScreenTransferItem"
                v-model="form.accessTypes.dining.visualScreenTransferItem"
                name="visualScreenTransferItem"
              >
                {{ $t('accessType.tabs.dining.visualScreenTransferItem') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="visualScreenTransferItemSplit"
                v-model="form.accessTypes.dining.visualScreenTransferItemSplit"
                name="visualScreenTransferItemSplit"
                class="ml-4"
                :disabled="!form.accessTypes.dining.visualScreenTransferItem"
              >
                {{ $t('accessType.tabs.dining.visualScreenTransferItemSplit') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenTransferItemOnlyNoChecks"
                v-model="form.accessTypes.dining.visualScreenTransferItemOnlyNoChecks"
                name="visualScreenTransferItemOnlyNoChecks"
                class="ml-4"
                :disabled="!form.accessTypes.dining.visualScreenTransferItem"
              >
                {{ $t('accessType.tabs.dining.visualScreenTransferItemOnlyNoChecks') }}
              </b-form-checkbox>

              <b-form-checkbox
                @change="check"
                id="visualScreenTransferEmployee"
                v-model="form.accessTypes.dining.visualScreenTransferEmployee"
                name="visualScreenTransferEmployee"
              >
                {{ $t('accessType.tabs.dining.visualScreenTransferEmployee') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="visualScreenTransferEmployeeNoPromptPassword"
                v-model="form.accessTypes.dining.visualScreenTransferEmployeeNoPromptPassword"
                name="visualScreenTransferEmployeeNoPromptPassword"
                class="ml-4"
                :disabled="!form.accessTypes.dining.visualScreenTransferEmployee"
              >
                {{ $t('accessType.tabs.dining.visualScreenTransferEmployeeNoPromptPassword') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="visualScreenOrderCombinations"
                v-model="form.accessTypes.dining.visualScreenOrderCombinations"
                name="visualScreenOrderCombinations"
              >
                {{ $t('accessType.tabs.dining.visualScreenOrderCombinations') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="visualScreenOrderCombinationsPromptPasswordDiffEmployee"
                v-model="form.accessTypes.dining.visualScreenOrderCombinationsPromptPasswordDiffEmployee"
                name="visualScreenOrderCombinationsPromptPasswordDiffEmployee"
                class="ml-4"
                :disabled="!form.accessTypes.dining.visualScreenOrderCombinations"
              >
                {{ $t('accessType.tabs.dining.visualScreenOrderCombinationsPromptPasswordDiffEmployee') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="visualScreenResendOrder"
                v-model="form.accessTypes.dining.visualScreenResendOrder"
                name="visualScreenResendOrder"
              >
                {{ $t('accessType.tabs.dining.visualScreenResendOrder') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenRound"
                v-model="form.accessTypes.dining.visualScreenRound"
                name="visualScreenRound"
              >
                {{ $t('accessType.tabs.dining.visualScreenRound') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenPreOrder"
                v-model="form.accessTypes.dining.visualScreenPreOrder"
                name="visualScreenPreOrder"
              >
                {{ $t('accessType.tabs.dining.visualScreenPreOrder') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenTransferOrder"
                v-model="form.accessTypes.dining.visualScreenTransferOrder"
                name="visualScreenTransferOrder"
              >
                {{ $t('accessType.tabs.dining.visualScreenTransferOrder') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="visualScreenGroupTableOrderBatch"
                v-model="form.accessTypes.dining.visualScreenGroupTableOrderBatch"
                name="visualScreenGroupTableOrderBatch"
              >
                {{ $t('accessType.tabs.dining.visualScreenGroupTableOrderBatch') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="visualScreenGroupTableOrderBatchSeparateReceipts"
                v-model="form.accessTypes.dining.visualScreenGroupTableOrderBatchSeparateReceipts"
                name="visualScreenGroupTableOrderBatchSeparateReceipts"
                class="ml-4"
                :disabled="!form.accessTypes.dining.visualScreenGroupTableOrderBatch"
              >
                {{ $t('accessType.tabs.dining.visualScreenGroupTableOrderBatchSeparateReceipts') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenAssignVisitorMembers"
                v-model="form.accessTypes.dining.visualScreenAssignVisitorMembers"
                name="visualScreenAssignVisitorMembers"
              >
                {{ $t('accessType.tabs.dining.visualScreenAssignVisitorMembers') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="visualScreenAllowTableOrderOnHold"
                v-model="form.accessTypes.dining.visualScreenAllowTableOrderOnHold"
                name="visualScreenAllowTableOrderOnHold"
              >
                {{ $t('accessType.tabs.dining.visualScreenAllowTableOrderOnHold') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenAllowTableOrderOnHoldDefault"
                v-model="form.accessTypes.dining.visualScreenAllowTableOrderOnHoldDefault"
                name="visualScreenAllowTableOrderOnHoldDefault"
                class="ml-4"
                :disabled="!form.accessTypes.dining.visualScreenAllowTableOrderOnHold"
              >
                {{ $t('accessType.tabs.dining.visualScreenAllowTableOrderOnHoldDefault') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4"></b-col>
          </b-row>
          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.dining.menuButton') }}
          </h6>
          <b-row>
            <b-col
              md="4"
              v-for="(btn, index) in (form.accessTypes.diningBtn || [{ keyGroupId: null, isDefault: false }])"
              :key="btn.value"
            >
              <b-form-group :label="$t('accessType.tabs.dining.menuButtonLabel', { id: index + 1 })" class="mb-3">
                <div class="d-flex flex-row">
                  <b-form-select
                    :options="[{ id: null, name: $t('employeeCreate.emptyValue') }, ...keyGroups]"
                    id="test"
                    value-field="id"
                    text-field="name"
                    aria-describedby="input-live-help input-live-feedback"
                    class="form-text"
                    v-model="form.accessTypes.diningBtn[index].keyGroupId"
                    @change="
                      $event => {
                        if (!$event) form.accessTypes.diningBtn[index].isDefault = false;
                      }
                    "
                    style="max-width: 200px;"
                  ></b-form-select>
                  <b-form-checkbox
                    :disabled="!form.accessTypes.diningBtn[index].keyGroupId"
                    v-model="form.accessTypes.diningBtn[index].isDefault"
                    @change="setAsDefault(index, $event)"
                    class="ml-4"
                  >
                    {{ $t('employeeCreate.setAsDefault') }}
                  </b-form-checkbox>
                  <b-button
                    v-if="form.accessTypes.diningBtn.length < 5"
                    :style="{ visibility: form.accessTypes.diningBtn.length === index + 1 ? 'visible' : 'hidden' }"
                    @click="onAddDiningBtn"
                    variant="outline-primary"
                    size="sm"
                    class="rounded-circle p-1 ml-3 align-self-center"
                  >
                    <b-icon icon="plus" scale="1.4"></b-icon>
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.dining.specialKeys') }}
          </h6>
          <b-row>
            <b-col>
              <b-form-checkbox
                id="specialKeysEmployeeSelectionKeyDefaultEmployee"
                v-model="form.accessTypes.dining.specialKeysEmployeeSelectionKeyDefaultEmployee"
                name="specialKeysEmployeeSelectionKeyDefaultEmployee"
              >
                {{ $t('accessType.tabs.dining.specialKeysEmployeeSelectionKeyDefaultEmployee') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="specialKeysExemptionFromPaymentKeys"
                v-model="form.accessTypes.dining.specialKeysExemptionFromPaymentKeys"
                name="specialKeysExemptionFromPaymentKeys"
              >
                {{ $t('accessType.tabs.dining.specialKeysExemptionFromPaymentKeys') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="specialKeysPriceChange"
                v-model="form.accessTypes.dining.specialKeysPriceChange"
                name="specialKeysPriceChange"
              >
                {{ $t('accessType.tabs.dining.specialKeysPriceChange') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="specialKeysMiscItems"
                v-model="form.accessTypes.dining.specialKeysMiscItems"
                name="specialKeysMiscItems"
              >
                {{ $t('accessType.tabs.dining.specialKeysMiscItems') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="specialKeysNegativeMiscItems"
                v-model="form.accessTypes.dining.specialKeysNegativeMiscItems"
                name="specialKeysNegativeMiscItems"
              >
                {{ $t('accessType.tabs.dining.specialKeysNegativeMiscItems') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
      <b-tab :title="$t('accessType.tabs.quickService.title')" title-item-class="col-sm-12 col-md-auto p-0 m-0">
        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.quickService.visualConfiguration') }}
        </h5>
        <b-container fluid class="content p-4">
          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.quickService.onScreenButtons') }}
          </h6>
          <b-row>
            <b-col>
              <b-form-checkbox
                id="visualScreenRetrieveStoredOrder"
                v-model="form.accessTypes.quickService.visualScreenRetrieveStoredOrder"
                name="visualScreenRetrieveStoredOrder"
              >
                {{ $t('accessType.tabs.quickService.visualScreenRetrieveStoredOrder') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="visualScreenStoredOrder"
                v-model="form.accessTypes.quickService.visualScreenStoredOrder"
                name="visualScreenStoredOrder"
              >
                {{ $t('accessType.tabs.quickService.visualScreenStoredOrder') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="visualScreenStoredOrderPrintPrices"
                v-model="form.accessTypes.quickService.visualScreenStoredOrderPrintPrices"
                name="visualScreenStoredOrderPrintPrices"
                class="ml-4"
                :disabled="!form.accessTypes.quickService.visualScreenStoredOrder"
              >
                {{ $t('accessType.tabs.quickService.visualScreenStoredOrderPrintPrices') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenStoredOrderPrintBarcode"
                v-model="form.accessTypes.quickService.visualScreenStoredOrderPrintBarcode"
                name="visualScreenStoredOrderPrintBarcode"
                class="ml-4"
                :disabled="!form.accessTypes.quickService.visualScreenStoredOrder"
              >
                {{ $t('accessType.tabs.quickService.visualScreenStoredOrderPrintBarcode') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenStoredOrderExpectedDate"
                v-model="form.accessTypes.quickService.visualScreenStoredOrderExpectedDate"
                name="visualScreenStoredOrderExpectedDate"
                class="ml-4"
                :disabled="!form.accessTypes.quickService.visualScreenStoredOrder"
              >
                {{ $t('accessType.tabs.quickService.visualScreenStoredOrderExpectedDate') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenStoredOrderMemberAssociation"
                v-model="form.accessTypes.quickService.visualScreenStoredOrderMemberAssociation"
                name="visualScreenStoredOrderMemberAssociation"
                class="ml-4"
                :disabled="!form.accessTypes.quickService.visualScreenStoredOrder"
              >
                {{ $t('accessType.tabs.quickService.visualScreenStoredOrderMemberAssociation') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenViewItemPricesWithoutOrdering"
                v-model="form.accessTypes.quickService.visualScreenViewItemPricesWithoutOrdering"
                name="visualScreenViewItemPricesWithoutOrdering"
              >
                {{ $t('accessType.tabs.quickService.visualScreenViewItemPricesWithoutOrdering') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="visualScreenAllowQuickServiceOrderOnHold"
                v-model="form.accessTypes.quickService.visualScreenAllowQuickServiceOrderOnHold"
                name="visualScreenAllowQuickServiceOrderOnHold"
              >
                {{ $t('accessType.tabs.quickService.visualScreenAllowQuickServiceOrderOnHold') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="visualScreenAllowQuickServiceOrderOnHoldDefault"
                v-model="form.accessTypes.quickService.visualScreenAllowQuickServiceOrderOnHoldDefault"
                name="visualScreenAllowQuickServiceOrderOnHold"
                class="ml-4"
                :disabled="!form.accessTypes.quickService.visualScreenAllowQuickServiceOrderOnHold"
              >
                {{ $t('accessType.tabs.quickService.visualScreenAllowQuickServiceOrderOnHoldDefault') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
      <b-tab :title="$t('accessType.tabs.bar.title')" title-item-class="col-sm-12 col-md-auto p-0 m-0">
        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.bar.barInterface') }}
        </h5>

        <b-container fluid class="content p-4">
          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.bar.activeInterface') }}
          </h6>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="activeInterfaceClearBarOrderMemory"
                v-model="form.accessTypes.bar.activeInterfaceClearBarOrderMemory"
                name="activeInterfaceClearBarOrderMemory"
              >
                {{ $t('accessType.tabs.bar.activeInterfaceClearBarOrderMemory') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="activeInterfaceCoinFree"
                v-model="form.accessTypes.bar.activeInterfaceCoinFree"
                name="activeInterfaceCoinFree"
              >
                {{ $t('accessType.tabs.bar.activeInterfaceCoinFree') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="activeInterfaceReplacementProduct"
                v-model="form.accessTypes.bar.activeInterfaceReplacementProduct"
                name="activeInterfaceReplacementProduct"
              >
                {{ $t('accessType.tabs.bar.activeInterfaceReplacementProduct') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="activeInterfaceShowBarOrderFirst"
                v-model="form.accessTypes.bar.activeInterfaceShowBarOrderFirst"
                name="activeInterfaceShowBarOrderFirst"
              >
                {{ $t('accessType.tabs.bar.activeInterfaceShowBarOrderFirst') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="activeInterfaceShowAlternativeBarSection"
                v-model="form.accessTypes.bar.activeInterfaceShowAlternativeBarSection"
                name="activeInterfaceShowAlternativeBarSection"
              >
                {{ $t('accessType.tabs.bar.activeInterfaceShowAlternativeBarSection') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="activeInterfaceRemoveOrderWithoutInterface"
                v-model="form.accessTypes.bar.activeInterfaceRemoveOrderWithoutInterface"
                name="activeInterfaceRemoveOrderWithoutInterface"
              >
                {{ $t('accessType.tabs.bar.activeInterfaceRemoveOrderWithoutInterface') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="activeInterfaceCombineBarOrders"
                v-model="form.accessTypes.bar.activeInterfaceCombineBarOrders"
                name="activeInterfaceCombineBarOrders"
              >
                {{ $t('accessType.tabs.bar.activeInterfaceCombineBarOrders') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="activeInterfaceReactivateExpiredOrder"
                v-model="form.accessTypes.bar.activeInterfaceReactivateExpiredOrder"
                name="activeInterfaceReactivateExpiredOrder"
              >
                {{ $t('accessType.tabs.bar.activeInterfaceReactivateExpiredOrder') }}
              </b-form-checkbox>
            </b-col>
          </b-row>

          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.bar.passiveInterface') }}
          </h6>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="passiveInterfaceActualPouredQuantityReport"
                v-model="form.accessTypes.bar.passiveInterfaceActualPouredQuantityReport"
                name="passiveInterfaceActualPouredQuantityReport"
              >
                {{ $t('accessType.tabs.bar.passiveInterfaceActualPouredQuantityReport') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="passiveInterfaceActualPouredQuantityReportCloseBarShift"
                v-model="form.accessTypes.bar.passiveInterfaceActualPouredQuantityReportCloseBarShift"
                name="passiveInterfaceActualPouredQuantityReportCloseBarShift"
                class="ml-4"
                :disabled="!form.accessTypes.bar.passiveInterfaceActualPouredQuantityReport"
              >
                {{ $t('accessType.tabs.bar.passiveInterfaceActualPouredQuantityReportCloseBarShift') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="passiveInterfaceBarOperator"
                v-model="form.accessTypes.bar.passiveInterfaceBarOperator"
                name="passiveInterfaceBarOperator"
              >
                {{ $t('accessType.tabs.bar.passiveInterfaceBarOperator') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="passiveInterfaceChangeBarSection"
                v-model="form.accessTypes.bar.passiveInterfaceChangeBarSection"
                name="passiveInterfaceChangeBarSection"
              >
                {{ $t('accessType.tabs.bar.passiveInterfaceChangeBarSection') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="passiveInterfaceAutomaticSectionReports"
                v-model="form.accessTypes.bar.passiveInterfaceAutomaticSectionReports"
                name="passiveInterfaceAutomaticSectionReports"
              >
                {{ $t('accessType.tabs.bar.passiveInterfaceAutomaticSectionReports') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="passiveInterfaceAutomaticallyOrderOutOfBalance"
                v-model="form.accessTypes.bar.passiveInterfaceAutomaticallyOrderOutOfBalance"
                name="passiveInterfaceAutomaticallyOrderOutOfBalance"
              >
                {{ $t('accessType.tabs.bar.passiveInterfaceAutomaticallyOrderOutOfBalance') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="passiveInterfaceManualAdjustment"
                v-model="form.accessTypes.bar.passiveInterfaceManualAdjustment"
                name="passiveInterfaceManualAdjustment"
              >
                {{ $t('accessType.tabs.bar.passiveInterfaceManualAdjustment') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="passiveInterfaceManualAdjustmentSync"
                v-model="form.accessTypes.bar.passiveInterfaceManualAdjustmentSync"
                name="passiveInterfaceManualAdjustmentSync"
                class="ml-4"
                :disabled="!form.accessTypes.bar.passiveInterfaceManualAdjustment"
              >
                {{ $t('accessType.tabs.bar.passiveInterfaceManualAdjustmentSync') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="passiveInterfaceManualAdjustmentAddBottle"
                v-model="form.accessTypes.bar.passiveInterfaceManualAdjustmentAddBottle"
                name="passiveInterfaceManualAdjustmentAddBottle"
                class="ml-4"
                :disabled="!form.accessTypes.bar.passiveInterfaceManualAdjustment"
              >
                {{ $t('accessType.tabs.bar.passiveInterfaceManualAdjustmentAddBottle') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="passiveInterfaceManualAdjustmentAutoSection"
                v-model="form.accessTypes.bar.passiveInterfaceManualAdjustmentAutoSection"
                name="passiveInterfaceManualAdjustmentAutoSection"
                class="ml-4"
                :disabled="!form.accessTypes.bar.passiveInterfaceManualAdjustment"
              >
                {{ $t('accessType.tabs.bar.passiveInterfaceManualAdjustmentAutoSection') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="passiveInterfaceManualAdjustmentCalibration"
                v-model="form.accessTypes.bar.passiveInterfaceManualAdjustmentCalibration"
                name="passiveInterfaceManualAdjustmentCalibration"
                class="ml-4"
                :disabled="!form.accessTypes.bar.passiveInterfaceManualAdjustment"
              >
                {{ $t('accessType.tabs.bar.passiveInterfaceManualAdjustmentCalibration') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="passiveInterfaceManualAdjustmentPrintAdjustedValues"
                v-model="form.accessTypes.bar.passiveInterfaceManualAdjustmentPrintAdjustedValues"
                name="passiveInterfaceManualAdjustmentPrintAdjustedValues"
                class="ml-4"
                :disabled="!form.accessTypes.bar.passiveInterfaceManualAdjustment"
              >
                {{ $t('accessType.tabs.bar.passiveInterfaceManualAdjustmentPrintAdjustedValues') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4"></b-col>
          </b-row>

          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.bar.common') }}
          </h6>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="commonAutoOrderingControl"
                v-model="form.accessTypes.bar.commonAutoOrderingControl"
                name="commonAutoOrderingControl"
              >
                {{ $t('accessType.tabs.bar.commonAutoOrderingControl') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="commonAutoOrderingControlVoidAutoOrder"
                v-model="form.accessTypes.bar.commonAutoOrderingControlVoidAutoOrder"
                name="commonAutoOrderingControlVoidAutoOrder"
                class="ml-4"
                :disabled="!form.accessTypes.bar.commonAutoOrderingControl"
              >
                {{ $t('accessType.tabs.bar.commonAutoOrderingControlVoidAutoOrder') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="commonSpoutAssignment"
                v-model="form.accessTypes.bar.commonSpoutAssignment"
                name="commonSpoutAssignment"
              >
                {{ $t('accessType.tabs.bar.commonSpoutAssignment') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="commonProductCalibration"
                v-model="form.accessTypes.bar.commonProductCalibration"
                name="commonProductCalibration"
              >
                {{ $t('accessType.tabs.bar.commonProductCalibration') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="commonVarConsoleMemAssignment"
                v-model="form.accessTypes.bar.commonVarConsoleMemAssignment"
                name="commonVarConsoleMemAssignment"
              >
                {{ $t('accessType.tabs.bar.commonVarConsoleMemAssignment') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4"></b-col>
          </b-row>
        </b-container>
      </b-tab>
      <b-tab :title="$t('accessType.tabs.operation.title')" title-item-class="col-sm-12 col-md-auto p-0 m-0">
        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.operation.timeAttendance') }}
        </h5>
        <b-container fluid class="content p-4">
          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.operation.general') }}
          </h6>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="generalEnforcePunchIn"
                v-model="form.accessTypes.operation.generalEnforcePunchIn"
                name="generalEnforcePunchIn"
              >
                {{ $t('accessType.tabs.operation.generalEnforcePunchIn') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="generalReadingAfterPunchOut"
                v-model="form.accessTypes.operation.generalReadingAfterPunchOut"
                name="generalReadingAfterPunchOut"
              >
                {{ $t('accessType.tabs.operation.generalReadingAfterPunchOut') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="generalTimeAttendanceManager"
                v-model="form.accessTypes.operation.generalTimeAttendanceManager"
                name="generalTimeAttendanceManager"
              >
                {{ $t('accessType.tabs.operation.generalTimeAttendanceManager') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="generalCantPunchOutOpenTables"
                v-model="form.accessTypes.operation.generalCantPunchOutOpenTables"
                name="generalCantPunchOutOpenTables"
              >
                {{ $t('accessType.tabs.operation.generalCantPunchOutOpenTables') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="generalPerformCashAdvances"
                v-model="form.accessTypes.operation.generalPerformCashAdvances"
                name="generalPerformCashAdvances"
              >
                {{ $t('accessType.tabs.operation.generalPerformCashAdvances') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="generalPerformCashAdvancesCashingOutEmployeeSelection"
                v-model="form.accessTypes.operation.generalPerformCashAdvancesCashingOutEmployeeSelection"
                name="generalPerformCashAdvancesCashingOutEmployeeSelection"
                class="ml-4"
                :disabled="!form.accessTypes.operation.generalPerformCashAdvances"
              >
                {{ $t('accessType.tabs.operation.generalPerformCashAdvancesCashingOutEmployeeSelection') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="generalChangeEmployeeMeals"
                v-model="form.accessTypes.operation.generalChangeEmployeeMeals"
                name="generalChangeEmployeeMeals"
              >
                {{ $t('accessType.tabs.operation.generalChangeEmployeeMeals') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="generalChangeEmployeeMealsPayingEmployeeSelection"
                v-model="form.accessTypes.operation.generalChangeEmployeeMealsPayingEmployeeSelection"
                name="generalChangeEmployeeMealsPayingEmployeeSelection"
                class="ml-4"
                :disabled="!form.accessTypes.operation.generalChangeEmployeeMeals"
              >
                {{ $t('accessType.tabs.operation.generalChangeEmployeeMealsPayingEmployeeSelection') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="generalResolveCashAdvances"
                v-model="form.accessTypes.operation.generalResolveCashAdvances"
                name="generalResolveCashAdvances"
              >
                {{ $t('accessType.tabs.operation.generalResolveCashAdvances') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="generalReceiveEmployeeMealCharge"
                v-model="form.accessTypes.operation.generalReceiveEmployeeMealCharge"
                name="generalReceiveEmployeeMealCharge"
              >
                {{ $t('accessType.tabs.operation.generalReceiveEmployeeMealCharge') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="generalForceTaskSelection"
                v-model="form.accessTypes.operation.generalForceTaskSelection"
                name="generalForceTaskSelection"
              >
                {{ $t('accessType.tabs.operation.generalForceTaskSelection') }}
              </b-form-checkbox>
            </b-col>
          </b-row>

          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.operation.timeAttendanceReports') }}
          </h6>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="timeAttendanceReportNoEmployeeReport"
                v-model="form.accessTypes.operation.timeAttendanceReportNoEmployeeReport"
                name="timeAttendanceReportNoEmployeeReport"
              >
                {{ $t('accessType.tabs.operation.timeAttendanceReportNoEmployeeReport') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="timeAttendanceReportActiveEmployeeReport"
                v-model="form.accessTypes.operation.timeAttendanceReportActiveEmployeeReport"
                name="timeAttendanceReportActiveEmployeeReport"
              >
                {{ $t('accessType.tabs.operation.timeAttendanceReportActiveEmployeeReport') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="timeAttendanceReportScheduledEmployeeReport"
                v-model="form.accessTypes.operation.timeAttendanceReportScheduledEmployeeReport"
                name="timeAttendanceReportScheduledEmployeeReport"
              >
                {{ $t('accessType.tabs.operation.timeAttendanceReportScheduledEmployeeReport') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="timeAttendanceReportEmployeesBreakReport"
                v-model="form.accessTypes.operation.timeAttendanceReportEmployeesBreakReport"
                name="timeAttendanceReportEmployeesBreakReport"
              >
                {{ $t('accessType.tabs.operation.timeAttendanceReportEmployeesBreakReport') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="timeAttendanceReportDailyPunch"
                v-model="form.accessTypes.operation.timeAttendanceReportDailyPunch"
                name="timeAttendanceReportDailyPunch"
              >
                {{ $t('accessType.tabs.operation.timeAttendanceReportDailyPunch') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="timeAttendanceReportDailySchedule"
                v-model="form.accessTypes.operation.timeAttendanceReportDailySchedule"
                name="timeAttendanceReportDailySchedule"
              >
                {{ $t('accessType.tabs.operation.timeAttendanceReportDailySchedule') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="timeAttendanceReportDailyNoShow"
                v-model="form.accessTypes.operation.timeAttendanceReportDailyNoShow"
                name="timeAttendanceReportDailyNoShow"
              >
                {{ $t('accessType.tabs.operation.timeAttendanceReportDailyNoShow') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4"></b-col>
          </b-row>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.operation.reports') }}
        </h5>

        <b-container fluid class="content p-4">
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="reportsPerformEndOfDayProcedure"
                v-model="form.accessTypes.operation.reportsPerformEndOfDayProcedure"
                name="reportsPerformEndOfDayProcedure"
              >
                {{ $t('accessType.tabs.operation.reportsPerformEndOfDayProcedure') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="reportsEndOfDay"
                v-model="form.accessTypes.operation.reportsEndOfDay"
                name="reportsEndOfDay"
              >
                {{ $t('accessType.tabs.operation.reportsEndOfDay') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="reportsWeekly"
                v-model="form.accessTypes.operation.reportsWeekly"
                name="reportsWeekly"
              >
                {{ $t('accessType.tabs.operation.reportsWeekly') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="reportsMonthly"
                v-model="form.accessTypes.operation.reportsMonthly"
                name="reportsMonthly"
              >
                {{ $t('accessType.tabs.operation.reportsMonthly') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="reportsYearly"
                v-model="form.accessTypes.operation.reportsYearly"
                name="reportsYearly"
              >
                {{ $t('accessType.tabs.operation.reportsYearly') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="reportsPeriodic"
                v-model="form.accessTypes.operation.reportsPeriodic"
                name="reportsPeriodic"
              >
                {{ $t('accessType.tabs.operation.reportsPeriodic') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="reportsTimeGap"
                v-model="form.accessTypes.operation.reportsTimeGap"
                name="reportsTimeGap"
              >
                {{ $t('accessType.tabs.operation.reportsTimeGap') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="reportsDepartmentSelection"
                v-model="form.accessTypes.operation.reportsDepartmentSelection"
                name="reportsDepartmentSelection"
              >
                {{ $t('accessType.tabs.operation.reportsDepartmentSelection') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="reportsEmployeeSelection"
                v-model="form.accessTypes.operation.reportsEmployeeSelection"
                name="reportsEmployeeSelection"
              >
                {{ $t('accessType.tabs.operation.reportsEmployeeSelection') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4"></b-col>
          </b-row>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.operation.readings') }}
        </h5>

        <b-container fluid class="content p-4">
          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.operation.salesReading') }}
          </h6>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="salesReadingCloseShift"
                v-model="form.accessTypes.operation.salesReadingCloseShift"
                name="salesReadingCloseShift"
              >
                {{ $t('accessType.tabs.operation.salesReadingCloseShift') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="salesReadingCloseShiftPromptAmountBeforeClose"
                v-model="form.accessTypes.operation.salesReadingCloseShiftPromptAmountBeforeClose"
                name="salesReadingCloseShiftPromptAmountBeforeClose"
                class="ml-4"
                :disabled="!form.accessTypes.operation.salesReadingCloseShift"
              >
                {{ $t('accessType.tabs.operation.salesReadingCloseShiftPromptAmountBeforeClose') }}
              </b-form-checkbox>
              <div class="ml-4">
                <b-form-checkbox
                  id="salesReadingCloseShiftPromptAmountBeforeCloseOverShort"
                  v-model="form.accessTypes.operation.salesReadingCloseShiftPromptAmountBeforeCloseOverShort"
                  name="salesReadingCloseShiftPromptAmountBeforeCloseOverShort"
                  class="ml-4"
                  :disabled="
                    !form.accessTypes.operation.salesReadingCloseShift ||
                    !form.accessTypes.operation.salesReadingCloseShiftPromptAmountBeforeClose
                  "
                >
                  {{ $t('accessType.tabs.operation.salesReadingCloseShiftPromptAmountBeforeCloseOverShort') }}
                </b-form-checkbox>
                <b-form-checkbox
                  id="salesReadingCloseShiftPromptAmountBeforeCloseDepositForm"
                  v-model="form.accessTypes.operation.salesReadingCloseShiftPromptAmountBeforeCloseDepositForm"
                  name="salesReadingCloseShiftPromptAmountBeforeCloseDepositForm"
                  class="ml-4"
                  :disabled="
                    !form.accessTypes.operation.salesReadingCloseShift ||
                    !form.accessTypes.operation.salesReadingCloseShiftPromptAmountBeforeClose
                  "
                >
                  {{ $t('accessType.tabs.operation.salesReadingCloseShiftPromptAmountBeforeCloseDepositForm') }}
                </b-form-checkbox>
              </div>
              <b-form-checkbox
                id="salesReadingCloseShiftOnScreenOnly"
                v-model="form.accessTypes.operation.salesReadingCloseShiftOnScreenOnly"
                class="ml-4"
                name="salesReadingCloseShiftOnScreenOnly"
                :disabled="!form.accessTypes.operation.salesReadingCloseShift"
              >
                {{ $t('accessType.tabs.operation.salesReadingCloseShiftOnScreenOnly') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="reportsMonthly"
                v-model="form.accessTypes.operation.salesReadingSalesConfirmation"
                name="reportsMonthly"
              >
                {{ $t('accessType.tabs.operation.salesReadingSalesConfirmation') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="salesReadingSalesConfirmationNoOpenTables"
                v-model="form.accessTypes.operation.salesReadingSalesConfirmationNoOpenTables"
                class="ml-4"
                name="salesReadingSalesConfirmationNoOpenTables"
                :disabled="!form.accessTypes.operation.salesReadingSalesConfirmation"
              >
                {{ $t('accessType.tabs.operation.salesReadingSalesConfirmationNoOpenTables') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="salesReadingSalesConfirmationOnScreenOnly"
                v-model="form.accessTypes.operation.salesReadingSalesConfirmationOnScreenOnly"
                class="ml-4"
                name="salesReadingSalesConfirmationOnScreenOnly"
                :disabled="!form.accessTypes.operation.salesReadingSalesConfirmation"
              >
                {{ $t('accessType.tabs.operation.salesReadingSalesConfirmationOnScreenOnly') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="salesReadingOnScreenReadings"
                v-model="form.accessTypes.operation.salesReadingOnScreenReadings"
                name="salesReadingOnScreenReadings"
              >
                {{ $t('accessType.tabs.operation.salesReadingOnScreenReadings') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="salesReadingCumulativeReadings"
                v-model="form.accessTypes.operation.salesReadingCumulativeReadings"
                name="salesReadingCumulativeReadings"
              >
                {{ $t('accessType.tabs.operation.salesReadingCumulativeReadings') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="salesReadingSalesEmployee"
                v-model="form.accessTypes.operation.salesReadingSalesEmployee"
                name="salesReadingSalesEmployee"
              >
                {{ $t('accessType.tabs.operation.salesReadingSalesEmployee') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4"></b-col>
          </b-row>

          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.operation.globalReading') }}
          </h6>
          <b-row>
            <b-col>
              <b-form-checkbox
                id="globalReading"
                v-model="form.accessTypes.operation.globalReading"
                name="globalReading"
              >
                {{ $t('accessType.tabs.operation.globalReading') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="globalReadingByPatch"
                v-model="form.accessTypes.operation.globalReadingByPatch"
                name="globalReadingByPatch"
              >
                {{ $t('accessType.tabs.operation.globalReadingByPatch') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="globalReadingByPatchCloseBatch"
                v-model="form.accessTypes.operation.globalReadingByPatchCloseBatch"
                name="globalReadingByPatchCloseBatch"
                class="ml-4"
                :disabled="!form.accessTypes.operation.globalReadingByPatch"
              >
                {{ $t('accessType.tabs.operation.globalReadingByPatchCloseBatch') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="globalReadingByPatchByDepartment"
                v-model="form.accessTypes.operation.globalReadingByPatchByDepartment"
                name="globalReadingByPatchByDepartment"
                class="ml-4"
                :disabled="!form.accessTypes.operation.globalReadingByPatch"
              >
                {{ $t('accessType.tabs.operation.globalReadingByPatchByDepartment') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.operation.readingFormat') }}
          </h6>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="generalEnforcePunchIn"
                v-model="form.accessTypes.operation.readingFormatCountersReport"
                name="generalEnforcePunchIn"
              >
                {{ $t('accessType.tabs.operation.readingFormatCountersReport') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatSalesByCategory"
                v-model="form.accessTypes.operation.readingFormatSalesByCategory"
                name="readingFormatSalesByCategory"
              >
                {{ $t('accessType.tabs.operation.readingFormatSalesByCategory') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="readingFormatSalesBySubCategory"
                v-model="form.accessTypes.operation.readingFormatSalesBySubCategory"
                name="readingFormatSalesBySubCategory"
              >
                {{ $t('accessType.tabs.operation.readingFormatSalesBySubCategory') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatCheckListing"
                v-model="form.accessTypes.operation.readingFormatCheckListing"
                name="readingFormatCheckListing"
              >
                {{ $t('accessType.tabs.operation.readingFormatCheckListing') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="readingFormatTransactionsPaymentType"
                v-model="form.accessTypes.operation.readingFormatTransactionsPaymentType"
                name="readingFormatTransactionsPaymentType"
              >
                {{ $t('accessType.tabs.operation.readingFormatTransactionsPaymentType') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatDrawerDepositReport"
                v-model="form.accessTypes.operation.readingFormatDrawerDepositReport"
                name="readingFormatDrawerDepositReport"
              >
                {{ $t('accessType.tabs.operation.readingFormatDrawerDepositReport') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatSalesByMemberCategories"
                v-model="form.accessTypes.operation.readingFormatSalesByMemberCategories"
                name="readingFormatSalesByMemberCategories"
              >
                {{ $t('accessType.tabs.operation.readingFormatSalesByMemberCategories') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatCumulativeTotalShiftReadings"
                v-model="form.accessTypes.operation.readingFormatCumulativeTotalShiftReadings"
                name="readingFormatCumulativeTotalShiftReadings"
              >
                {{ $t('accessType.tabs.operation.readingFormatCumulativeTotalShiftReadings') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatTimeFirstLastOrder"
                v-model="form.accessTypes.operation.readingFormatTimeFirstLastOrder"
                name="readingFormatTimeFirstLastOrder"
              >
                {{ $t('accessType.tabs.operation.readingFormatTimeFirstLastOrder') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatNumberCashDrawerNoSales"
                v-model="form.accessTypes.operation.readingFormatNumberCashDrawerNoSales"
                name="readingFormatNumberCashDrawerNoSales"
              >
                {{ $t('accessType.tabs.operation.readingFormatNumberCashDrawerNoSales') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="readingFormatVoidTotalQty"
                v-model="form.accessTypes.operation.readingFormatVoidTotalQty"
                name="readingFormatVoidTotalQty"
              >
                {{ $t('accessType.tabs.operation.readingFormatVoidTotalQty') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatVoidTotalQtyItemVentilated"
                v-model="form.accessTypes.operation.readingFormatVoidTotalQtyItemVentilated"
                class="ml-4"
                name="readingFormatVoidTotalQtyItemVentilated"
                :disabled="!form.accessTypes.operation.readingFormatVoidTotalQty"
              >
                {{ $t('accessType.tabs.operation.readingFormatVoidTotalQtyItemVentilated') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatNumberPromoItems"
                v-model="form.accessTypes.operation.readingFormatNumberPromoItems"
                name="readingFormatNumberPromoItems"
              >
                {{ $t('accessType.tabs.operation.readingFormatNumberPromoItems') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="readingFormatMealCount"
                v-model="form.accessTypes.operation.readingFormatMealCount"
                name="readingFormatMealCount"
              >
                {{ $t('accessType.tabs.operation.readingFormatMealCount') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatCustomerCount"
                v-model="form.accessTypes.operation.readingFormatCustomerCount"
                name="readingFormatCustomerCount"
              >
                {{ $t('accessType.tabs.operation.readingFormatCustomerCount') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatAverageMeal"
                v-model="form.accessTypes.operation.readingFormatAverageMeal"
                name="readingFormatAverageMeal"
              >
                {{ $t('accessType.tabs.operation.readingFormatAverageMeal') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatAverageCustomer"
                v-model="form.accessTypes.operation.readingFormatAverageCustomer"
                name="readingFormatAverageCustomer"
              >
                {{ $t('accessType.tabs.operation.readingFormatAverageCustomer') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatValuePerMeal"
                v-model="form.accessTypes.operation.readingFormatValuePerMeal"
                name="readingFormatValuePerMeal"
              >
                {{ $t('accessType.tabs.operation.readingFormatValuePerMeal') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatEmployeeTips"
                v-model="form.accessTypes.operation.readingFormatEmployeeTips"
                name="readingFormatEmployeeTips"
              >
                {{ $t('accessType.tabs.operation.readingFormatEmployeeTips') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatIncludedTipFromOverPayment"
                v-model="form.accessTypes.operation.readingFormatIncludedTipFromOverPayment"
                name="readingFormatIncludedTipFromOverPayment"
              >
                {{ $t('accessType.tabs.operation.readingFormatIncludedTipFromOverPayment') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatTaxes"
                v-model="form.accessTypes.operation.readingFormatTaxes"
                name="readingFormatTaxes"
              >
                {{ $t('accessType.tabs.operation.readingFormatTaxes') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="readingFormatCancelledTransactions"
                v-model="form.accessTypes.operation.readingFormatCancelledTransactions"
                name="readingFormatCancelledTransactions"
              >
                {{ $t('accessType.tabs.operation.readingFormatCancelledTransactions') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatCancelledTransactionsVentilated"
                v-model="form.accessTypes.operation.readingFormatCancelledTransactionsVentilated"
                name="readingFormatCancelledTransactionsVentilated"
                class="ml-4"
                :disabled="!form.accessTypes.operation.readingFormatCancelledTransactions"
              >
                {{ $t('accessType.tabs.operation.readingFormatCancelledTransactionsVentilated') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatReportsPriceLevel"
                v-model="form.accessTypes.operation.readingFormatReportsPriceLevel"
                name="readingFormatReportsPriceLevel"
              >
                {{ $t('accessType.tabs.operation.readingFormatReportsPriceLevel') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatNumberDuplicate"
                v-model="form.accessTypes.operation.readingFormatNumberDuplicate"
                name="readingFormatNumberDuplicate"
              >
                {{ $t('accessType.tabs.operation.readingFormatNumberDuplicate') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="readingFormatReportsByDepartment"
                v-model="form.accessTypes.operation.readingFormatReportsByDepartment"
                name="readingFormatReportsByDepartment"
              >
                {{ $t('accessType.tabs.operation.readingFormatReportsByDepartment') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatTipDeclarationDataResume"
                v-model="form.accessTypes.operation.readingFormatTipDeclarationDataResume"
                name="readingFormatTipDeclarationDataResume"
              >
                {{ $t('accessType.tabs.operation.readingFormatTipDeclarationDataResume') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatTimezoneReport"
                v-model="form.accessTypes.operation.readingFormatTimezoneReport"
                name="readingFormatTimezoneReport"
              >
                {{ $t('accessType.tabs.operation.readingFormatTimezoneReport') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatNumberItemsSoldReturns"
                v-model="form.accessTypes.operation.readingFormatNumberItemsSoldReturns"
                name="readingFormatNumberItemsSoldReturns"
              >
                {{ $t('accessType.tabs.operation.readingFormatNumberItemsSoldReturns') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatDiscountItemIncludedDiscountedItem"
                v-model="form.accessTypes.operation.readingFormatDiscountItemIncludedDiscountedItem"
                name="readingFormatDiscountItemIncludedDiscountedItem"
              >
                {{ $t('accessType.tabs.operation.readingFormatDiscountItemIncludedDiscountedItem') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatTotalAndSubtotal"
                v-model="form.accessTypes.operation.readingFormatTotalAndSubtotal"
                name="readingFormatTotalAndSubtotal"
              >
                {{ $t('accessType.tabs.operation.readingFormatTotalAndSubtotal') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatDiscounts"
                v-model="form.accessTypes.operation.readingFormatDiscounts"
                name="readingFormatDiscounts"
              >
                {{ $t('accessType.tabs.operation.readingFormatDiscounts') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="readingFormatAllItemsVentilated"
                v-model="form.accessTypes.operation.readingFormatAllItemsVentilated"
                name="readingFormatAllItemsVentilated"
              >
                {{ $t('accessType.tabs.operation.readingFormatAllItemsVentilated') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.operation.operator') }}
        </h5>

        <b-container fluid class="content p-4">
          <b-row>
            <b-col>
              <b-form-checkbox
                id="operatorWorkstationsDepartment"
                v-model="form.accessTypes.operation.operatorWorkstationsDepartment"
                name="operatorWorkstationsDepartment"
              >
                {{ $t('accessType.tabs.operation.operatorWorkstationsDepartment') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="operatorEmployeesDepartment"
                v-model="form.accessTypes.operation.operatorEmployeesDepartment"
                name="operatorEmployeesDepartment"
              >
                {{ $t('accessType.tabs.operation.operatorEmployeesDepartment') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="operatorEmployeesRedirectionTable"
                v-model="form.accessTypes.operation.operatorEmployeesRedirectionTable"
                name="operatorEmployeesRedirectionTable"
              >
                {{ $t('accessType.tabs.operation.globalReadingByPatchCloseBatch') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="operatorEmployeesRequisitionLocation"
                v-model="form.accessTypes.operation.operatorEmployeesRequisitionLocation"
                name="operatorEmployeesRequisitionLocation"
              >
                {{ $t('accessType.tabs.operation.operatorEmployeesRequisitionLocation') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="operatorStopReassignAssignedEmployee"
                v-model="form.accessTypes.operation.operatorStopReassignAssignedEmployee"
                name="operatorStopReassignAssignedEmployee"
              >
                {{ $t('accessType.tabs.operation.operatorStopReassignAssignedEmployee') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="operatorDesignationRequired"
                v-model="form.accessTypes.operation.operatorDesignationRequired"
                name="operatorDesignationRequired"
              >
                {{ $t('accessType.tabs.operation.operatorDesignationRequired') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.operation.delivery') }}
        </h5>

        <b-container fluid class="content p-4">
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox id="deliveryCost" v-model="form.accessTypes.operation.deliveryCost" name="deliveryCost">
                {{ $t('accessType.tabs.operation.deliveryCost') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="deliveryManager"
                v-model="form.accessTypes.operation.deliveryManager"
                name="deliveryManager"
              >
                {{ $t('accessType.tabs.operation.deliveryManager') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="deliveryTagFunction"
                v-model="form.accessTypes.operation.deliveryTagFunction"
                name="deliveryTagFunction"
              >
                {{ $t('accessType.tabs.operation.deliveryTagFunction') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="deliveryAllowUpdateCustomersAvailableCredit"
                v-model="form.accessTypes.operation.deliveryAllowUpdateCustomersAvailableCredit"
                name="deliveryAllowUpdateCustomersAvailableCredit"
              >
                {{ $t('accessType.tabs.operation.deliveryAllowUpdateCustomersAvailableCredit') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="deliveryCreditBatch1"
                v-model="form.accessTypes.operation.deliveryCreditBatch1"
                name="deliveryCreditBatch1"
              >
                {{ $t('accessType.tabs.operation.deliveryCreditBatch1') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="deliverySingleDispatch"
                v-model="form.accessTypes.operation.deliverySingleDispatch"
                name="deliverySingleDispatch"
              >
                {{ $t('accessType.tabs.operation.deliverySingleDispatch') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4"></b-col>
          </b-row>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.operation.inventoryManagement') }}
        </h5>

        <b-container fluid class="content p-4">
          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.operation.inventoryGeneral') }}
          </h6>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="inventoryBalance"
                v-model="form.accessTypes.operation.inventoryBalance"
                name="inventoryBalance"
              >
                {{ $t('accessType.tabs.operation.inventoryBalance') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="inventoryBalanceIntegerQty"
                v-model="form.accessTypes.operation.inventoryBalanceIntegerQty"
                name="inventoryBalanceIntegerQty"
                class="ml-4"
                :disabled="!form.accessTypes.operation.inventoryBalance"
              >
                {{ $t('accessType.tabs.operation.inventoryBalanceIntegerQty') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="inventoryReceipt"
                v-model="form.accessTypes.operation.inventoryReceipt"
                name="inventoryReceipt"
              >
                {{ $t('accessType.tabs.operation.inventoryReceipt') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="inventoryInterLocationTransfer"
                v-model="form.accessTypes.operation.inventoryInterLocationTransfer"
                name="inventoryInterLocationTransfer"
              >
                {{ $t('accessType.tabs.operation.inventoryInterLocationTransfer') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="inventoryProductFiles"
                v-model="form.accessTypes.operation.inventoryProductFiles"
                name="inventoryProductFiles"
              >
                {{ $t('accessType.tabs.operation.inventoryProductFiles') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="inventoryAccessMultipleLocations"
                v-model="form.accessTypes.operation.inventoryAccessMultipleLocations"
                name="inventoryAccessMultipleLocations"
              >
                {{ $t('accessType.tabs.operation.inventoryAccessMultipleLocations') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="inventoryPurchaseManagement"
                v-model="form.accessTypes.operation.inventoryPurchaseManagement"
                name="inventoryPurchaseManagement"
              >
                {{ $t('accessType.tabs.operation.inventoryPurchaseManagement') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="inventoryShiftBalanceReport"
                v-model="form.accessTypes.operation.inventoryShiftBalanceReport"
                name="inventoryShiftBalanceReport"
              >
                {{ $t('accessType.tabs.operation.inventoryShiftBalanceReport') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="inventoryShiftBalanceReportBackSelection"
                v-model="form.accessTypes.operation.inventoryShiftBalanceReportBackSelection"
                name="inventoryShiftBalanceReportBackSelection"
                class="ml-4"
                :disabled="!form.accessTypes.operation.inventoryShiftBalanceReport"
              >
                {{ $t('accessType.tabs.operation.inventoryShiftBalanceReportBackSelection') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="inventoryShiftClosure"
                v-model="form.accessTypes.operation.inventoryShiftClosure"
                name="inventoryShiftClosure"
              >
                {{ $t('accessType.tabs.operation.inventoryShiftClosure') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="inventoryUnitChoice"
                v-model="form.accessTypes.operation.inventoryUnitChoice"
                name="inventoryUnitChoice"
              >
                {{ $t('accessType.tabs.operation.inventoryUnitChoice') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="inventoryReturnItemInventoryButton"
                v-model="form.accessTypes.operation.inventoryReturnItemInventoryButton"
                name="inventoryReturnItemInventoryButton"
              >
                {{ $t('accessType.tabs.operation.inventoryReturnItemInventoryButton') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="inventoryReturnItemInventoryButtonReceiptProductOnly"
                v-model="form.accessTypes.operation.inventoryReturnItemInventoryButtonReceiptProductOnly"
                name="inventoryReturnItemInventoryButtonReceiptProductOnly"
                class="ml-4"
                :disabled="!form.accessTypes.operation.inventoryReturnItemInventoryButton"
              >
                {{ $t('accessType.tabs.operation.inventoryReturnItemInventoryButtonReceiptProductOnly') }}
              </b-form-checkbox>

              <b-form-checkbox
                id="inventoryReturnItemInventoryButtonUseOnlyVersion"
                v-model="form.accessTypes.operation.inventoryReturnItemInventoryButtonUseOnlyVersion"
                name="inventoryReturnItemInventoryButtonUseOnlyVersion"
                class="ml-4"
                :disabled="!form.accessTypes.operation.inventoryReturnItemInventoryButton"
              >
                {{ $t('accessType.tabs.operation.inventoryReturnItemInventoryButtonUseOnlyVersion') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="inventoryPersistentCategoryInventoryManagement"
                v-model="form.accessTypes.operation.inventoryPersistentCategoryInventoryManagement"
                name="inventoryPersistentCategoryInventoryManagement"
              >
                {{ $t('accessType.tabs.operation.inventoryPersistentCategoryInventoryManagement') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="inventoryNegativeQtyButtonInventoryManagement"
                v-model="form.accessTypes.operation.inventoryNegativeQtyButtonInventoryManagement"
                name="inventoryNegativeQtyButtonInventoryManagement"
              >
                {{ $t('accessType.tabs.operation.inventoryNegativeQtyButtonInventoryManagement') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.operation.inventoryRequisitionTitle') }}
          </h6>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="inventoryRequisition"
                v-model="form.accessTypes.operation.inventoryRequisition"
                name="inventoryRequisition"
              >
                {{ $t('accessType.tabs.operation.inventoryRequisition') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="inventoryRequisitionProductTransfer"
                v-model="form.accessTypes.operation.inventoryRequisitionProductTransfer"
                name="inventoryRequisitionProductTransfer"
                class="ml-4"
                :disabled="!form.accessTypes.operation.inventoryRequisition"
              >
                {{ $t('accessType.tabs.operation.inventoryRequisitionProductTransfer') }}
              </b-form-checkbox>
              <div class="ml-4">
                <b-form-checkbox
                  id="inventoryRequisitionProductTransferLimitFunctionsLocation"
                  v-model="form.accessTypes.operation.inventoryRequisitionProductTransferLimitFunctionsLocation"
                  name="inventoryRequisitionProductTransferLimitFunctionsLocation"
                  class="ml-4"
                  :disabled="
                    !form.accessTypes.operation.inventoryRequisition ||
                    !form.accessTypes.operation.inventoryRequisitionProductTransfer
                  "
                >
                  {{ $t('accessType.tabs.operation.inventoryRequisitionProductTransferLimitFunctionsLocation') }}
                </b-form-checkbox>
              </div>
              <b-form-checkbox
                id="inventoryRequisitionChangeLocationNotAllowed"
                v-model="form.accessTypes.operation.inventoryRequisitionChangeLocationNotAllowed"
                name="inventoryRequisitionChangeLocationNotAllowed"
                class="ml-4"
                :disabled="!form.accessTypes.operation.inventoryRequisition"
              >
                {{ $t('accessType.tabs.operation.inventoryRequisitionChangeLocationNotAllowed') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="inventoryRequisitionWholeNumberQuantitiesOnly"
                v-model="form.accessTypes.operation.inventoryRequisitionWholeNumberQuantitiesOnly"
                name="inventoryRequisitionWholeNumberQuantitiesOnly"
                class="ml-4"
                :disabled="!form.accessTypes.operation.inventoryRequisition"
              >
                {{ $t('accessType.tabs.operation.inventoryRequisitionWholeNumberQuantitiesOnly') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="inventoryRequisitionRequiredForDate"
                v-model="form.accessTypes.operation.inventoryRequisitionRequiredForDate"
                name="inventoryRequisitionRequiredForDate"
                class="ml-4"
                :disabled="!form.accessTypes.operation.inventoryRequisition"
              >
                {{ $t('accessType.tabs.operation.inventoryRequisitionRequiredForDate') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="inventoryRequisitionNoStockCheck"
                v-model="form.accessTypes.operation.inventoryRequisitionNoStockCheck"
                name="inventoryRequisitionNoStockCheck"
                class="ml-4"
                :disabled="!form.accessTypes.operation.inventoryRequisition"
              >
                {{ $t('accessType.tabs.operation.inventoryRequisitionNoStockCheck') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="inventoryRequisitionByEmployeeSelection"
                v-model="form.accessTypes.operation.inventoryRequisitionByEmployeeSelection"
                name="inventoryRequisitionByEmployeeSelection"
                class="ml-4"
                :disabled="!form.accessTypes.operation.inventoryRequisition"
              >
                {{ $t('accessType.tabs.operation.inventoryRequisitionByEmployeeSelection') }}
              </b-form-checkbox>
              <b-row class="mt-2 ml-1">
                <b-col cols="12" md="8">
                  <b-form-group :label="$t('accessType.tabs.operation.inventoryRequisitionIndexId.label')" class="mt-3">
                    <b-form-select
                      :options="[...requisitionIndexes, { id: null, name: $t('employeeCreate.emptyValue') }]"
                      id="test"
                      value-field="id"
                      text-field="name"
                      aria-describedby="input-live-help input-live-feedback"
                      class="form-text"
                      v-model="form.accessTypes.operation.inventoryRequisitionIndexId"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="4"></b-col>
            <b-col cols="12" md="4"></b-col>
          </b-row>
        </b-container>
      </b-tab>

      <b-tab :title="$t('accessType.tabs.misc.title')" title-item-class="col-sm-12 col-md-auto p-0 m-0">
        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.misc.electronicFoundTransfer') }}
        </h5>

        <b-container fluid class="content p-4">
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="voidTransaction"
                v-model="form.accessTypes.misc.voidTransaction"
                name="voidTransaction"
              >
                {{ $t('accessType.tabs.misc.voidTransaction') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="transactionReport"
                v-model="form.accessTypes.misc.transactionReport"
                name="transactionReport"
              >
                {{ $t('accessType.tabs.misc.transactionReport') }}
              </b-form-checkbox>
              <b-form-checkbox id="batchClose" v-model="form.accessTypes.misc.batchClose" name="batchClose">
                {{ $t('accessType.tabs.misc.batchClose') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="clearPendingTransactions"
                v-model="form.accessTypes.misc.clearPendingTransactions"
                name="clearPendingTransactions"
              >
                {{ $t('accessType.tabs.misc.clearPendingTransactions') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox id="accountBalance" v-model="form.accessTypes.misc.accountBalance" name="accountBalance">
                {{ $t('accessType.tabs.misc.accountBalance') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="mobileTerminalAccess"
                v-model="form.accessTypes.misc.mobileTerminalAccess"
                name="mobileTerminalAccess"
              >
                {{ $t('accessType.tabs.misc.mobileTerminalAccess') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="accessEftFunctions"
                v-model="form.accessTypes.misc.accessEftFunctions"
                name="accessEftFunctions"
              >
                {{ $t('accessType.tabs.misc.accessEftFunctions') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4"></b-col>
          </b-row>
          <h6 class="mt-3 mb-3">{{ $t('accessType.tabs.misc.smartCard') }}</h6>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox id="smartCardSale" v-model="form.accessTypes.misc.smartCardSale" name="smartCardSale">
                {{ $t('accessType.tabs.misc.smartCardSale') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="smartCardVoidTransaction"
                v-model="form.accessTypes.misc.smartCardVoidTransaction"
                name="smartCardVoidTransaction"
              >
                {{ $t('accessType.tabs.misc.smartCardVoidTransaction') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="smartCardActivation"
                v-model="form.accessTypes.misc.smartCardActivation"
                name="smartCardActivation"
              >
                {{ $t('accessType.tabs.misc.smartCardActivation') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="smartCartBalanceInquiry"
                v-model="form.accessTypes.misc.smartCartBalanceInquiry"
                name="smartCartBalanceInquiry"
              >
                {{ $t('accessType.tabs.misc.smartCartBalanceInquiry') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="smartCartClearBalance"
                v-model="form.accessTypes.misc.smartCartClearBalance"
                name="smartCartClearBalance"
              >
                {{ $t('accessType.tabs.misc.smartCartClearBalance') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4"></b-col>
          </b-row>
          <h6 class="mt-3 mb-3">{{ $t('accessType.tabs.misc.smartCardFidelity') }}</h6>
          <b-row>
            <b-col>
              <b-form-checkbox
                id="smartCardFidelityActivate"
                v-model="form.accessTypes.misc.smartCardFidelityActivate"
                name="smartCardFidelityActivate"
              >
                {{ $t('accessType.tabs.misc.smartCardFidelityActivate') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="smartCardFidelityBalanceInquiry"
                v-model="form.accessTypes.misc.smartCardFidelityBalanceInquiry"
                name="smartCardFidelityBalanceInquiry"
              >
                {{ $t('accessType.tabs.misc.smartCardFidelityBalanceInquiry') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="smartCardFidelityVoidTransaction"
                v-model="form.accessTypes.misc.smartCardFidelityVoidTransaction"
                name="smartCardFidelityVoidTransaction"
              >
                {{ $t('accessType.tabs.misc.smartCardFidelityVoidTransaction') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-container>
        <h5 class="pt-4 px-4 pb-2">
          {{ $t('accessType.tabs.misc.systemFunction') }}
        </h5>
        <b-container fluid class="content p-4">
          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.misc.general') }}
          </h6>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="systemTableState"
                v-model="form.accessTypes.misc.systemTableState"
                name="systemTableState"
              >
                {{ $t('accessType.tabs.misc.systemTableState') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="systemMenuConfig"
                v-model="form.accessTypes.misc.systemMenuConfig"
                name="systemMenuConfig"
              >
                {{ $t('accessType.tabs.misc.systemMenuConfig') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="systemGroupKeyConfig"
                v-model="form.accessTypes.misc.systemGroupKeyConfig"
                name="systemGroupKeyConfig"
              >
                {{ $t('accessType.tabs.misc.systemGroupKeyConfig') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="systemEmployeeRecord"
                v-model="form.accessTypes.misc.systemEmployeeRecord"
                name="systemEmployeeRecord"
              >
                {{ $t('accessType.tabs.misc.systemEmployeeRecord') }}
              </b-form-checkbox>

              <b-form-checkbox
                @change="check"
                id="systemFunctions"
                v-model="form.accessTypes.misc.systemFunctions"
                name="systemFunctions"
              >
                {{ $t('accessType.tabs.misc.systemFunctions') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="systemFunctionsConfiguration"
                v-model="form.accessTypes.misc.systemFunctionsConfiguration"
                name="systemFunctionsConfiguration"
                class="ml-4"
                :disabled="!form.accessTypes.misc.systemFunctions"
              >
                {{ $t('accessType.tabs.misc.systemFunctionsConfiguration') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="systemEditItemCountdown"
                v-model="form.accessTypes.misc.systemEditItemCountdown"
                name="systemEditItemCountdown"
              >
                {{ $t('accessType.tabs.misc.systemEditItemCountdown') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="systemAdmissionTicketGenerator"
                v-model="form.accessTypes.misc.systemAdmissionTicketGenerator"
                name="systemAdmissionTicketGenerator"
              >
                {{ $t('accessType.tabs.misc.systemAdmissionTicketGenerator') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="systemAdmissionTicketGeneratorCheckCreation"
                v-model="form.accessTypes.misc.systemAdmissionTicketGeneratorCheckCreation"
                name="systemAdmissionTicketGeneratorCheckCreation"
                class="ml-4"
                :disabled="!form.accessTypes.misc.systemAdmissionTicketGenerator"
              >
                {{ $t('accessType.tabs.misc.systemAdmissionTicketGeneratorCheckCreation') }}
              </b-form-checkbox>
              <div class="ml-4">
                <b-form-checkbox
                  id="systemAdmissionTicketGeneratorCheckCreationLayawayFunc"
                  v-model="form.accessTypes.misc.systemAdmissionTicketGeneratorCheckCreationLayawayFunc"
                  name="systemAdmissionTicketGeneratorCheckCreationLayawayFunc"
                  class="ml-4"
                  :disabled="!form.accessTypes.misc.systemAdmissionTicketGeneratorCheckCreation"
                >
                  {{ $t('accessType.tabs.misc.systemAdmissionTicketGeneratorCheckCreationLayawayFunc') }}
                </b-form-checkbox>
              </div>
              <b-form-checkbox
                id="systemDailyTemperatureAdjustment"
                v-model="form.accessTypes.misc.systemDailyTemperatureAdjustment"
                name="systemDailyTemperatureAdjustment"
              >
                {{ $t('accessType.tabs.misc.systemDailyTemperatureAdjustment') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="systemAddClosedTablesOrderBatchList"
                v-model="form.accessTypes.misc.systemAddClosedTablesOrderBatchList"
                name="systemAddClosedTablesOrderBatchList"
              >
                {{ $t('accessType.tabs.misc.systemAddClosedTablesOrderBatchList') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="systemEnableDisableForcedPriceBatches"
                v-model="form.accessTypes.misc.systemEnableDisableForcedPriceBatches"
                name="systemEnableDisableForcedPriceBatches"
                class="ml-4"
                :disabled="!form.accessTypes.misc.systemAddClosedTablesOrderBatchList"
              >
                {{ $t('accessType.tabs.misc.systemFunctionsConfiguration') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="systemPrintPromoCardBalance"
                v-model="form.accessTypes.misc.systemPrintPromoCardBalance"
                name="systemPrintPromoCardBalance"
              >
                {{ $t('accessType.tabs.misc.systemPrintPromoCardBalance') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="systemSearchChecks"
                v-model="form.accessTypes.misc.systemSearchChecks"
                name="systemSearchChecks"
              >
                {{ $t('accessType.tabs.misc.systemSearchChecks') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="systemReviewEditClosedChecks"
                v-model="form.accessTypes.misc.systemReviewEditClosedChecks"
                name="systemReviewEditClosedChecks"
              >
                {{ $t('accessType.tabs.misc.systemReviewEditClosedChecks') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="systemModifyRedirectionTable"
                v-model="form.accessTypes.misc.systemModifyRedirectionTable"
                name="systemModifyRedirectionTable"
              >
                {{ $t('accessType.tabs.misc.systemModifyRedirectionTable') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="systemModifyPagerNumber"
                v-model="form.accessTypes.misc.systemModifyPagerNumber"
                name="systemModifyPagerNumber"
              >
                {{ $t('accessType.tabs.misc.systemModifyPagerNumber') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.misc.transfers') }}
          </h6>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-checkbox
                id="transfersPayment"
                v-model="form.accessTypes.misc.transfersPayment"
                name="transfersPayment"
              >
                {{ $t('accessType.tabs.misc.transfersPayment') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="transfersPaidOut"
                v-model="form.accessTypes.misc.transfersPaidOut"
                name="transfersPaidOut"
              >
                {{ $t('accessType.tabs.misc.transfersPaidOut') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="transfersPaidOutEmployee"
                v-model="form.accessTypes.misc.transfersPaidOutEmployee"
                name="transfersPaidOutEmployee"
                class="ml-4"
                :disabled="!form.accessTypes.misc.transfersPaidOut"
              >
                {{ $t('accessType.tabs.misc.transfersPaidOutEmployee') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="transfersEmployeeDeposit"
                v-model="form.accessTypes.misc.transfersEmployeeDeposit"
                name="transfersEmployeeDeposit"
              >
                {{ $t('accessType.tabs.misc.transfersEmployeeDeposit') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="transfersEmployeeDepositCounterbalanceExecOnly"
                v-model="form.accessTypes.misc.transfersEmployeeDepositCounterbalanceExecOnly"
                name="transfersEmployeeDepositCounterbalanceExecOnly"
                class="ml-4"
                :disabled="!form.accessTypes.misc.transfersEmployeeDeposit"
              >
                {{ $t('accessType.tabs.misc.transfersEmployeeDepositCounterbalanceExecOnly') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="transfersWarnAmountBeforeDeposit"
                v-model="form.accessTypes.misc.transfersWarnAmountBeforeDeposit"
                name="transfersWarnAmountBeforeDeposit"
              >
                {{ $t('accessType.tabs.misc.transfersWarnAmountBeforeDeposit') }}
              </b-form-checkbox>
              <b-input-group prepend="$" class="ml-4" style="max-width: 160px;">
                <b-form-input
                  id="name"
                  prefix="$"
                  no-wheel
                  lazy-formatter
                  :formatter="formatAmount"
                  step="0.01"
                  type="number"
                  v-model.number="form.accessTypes.misc.transfersWarnAmountBeforeDepositAmount"
                  :placeholder="$t('accessType.tabs.misc.transfersWarnAmountBeforeDepositAmount.placeholder')"
                  aria-describedby="passwd-feedback"
                ></b-form-input>
              </b-input-group>
              <b-form-checkbox
                id="transfersWarnAmountBeforeDepositAmount"
                v-model="form.accessTypes.misc.transfersMaxAmountBeforeForcedDeposit"
                name="transfersWarnAmountBeforeDepositAmount"
              >
                {{ $t('accessType.tabs.misc.transfersMaxAmountBeforeForcedDeposit') }}
              </b-form-checkbox>
              <b-input-group prepend="$" class="ml-4" style="max-width: 160px;">
                <b-form-input
                  id="name"
                  prefix="$"
                  no-wheel
                  lazy-formatter
                  :formatter="formatAmount"
                  step="0.01"
                  type="number"
                  v-model.number="form.accessTypes.misc.transfersMaxAmountBeforeForcedDepositAmount"
                  :placeholder="$t('accessType.tabs.misc.transfersMaxAmountBeforeForcedDepositAmount.placeholder')"
                  aria-describedby="passwd-feedback"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-checkbox
                @change="check"
                id="transfersPaidIn"
                v-model="form.accessTypes.misc.transfersPaidIn"
                name="transfersPaidIn"
              >
                {{ $t('accessType.tabs.misc.transfersPaidIn') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="transfersPaidInEmployee"
                v-model="form.accessTypes.misc.transfersPaidInEmployee"
                name="transfersPaidInEmployee"
                class="ml-4"
                :disabled="!form.accessTypes.misc.transfersPaidIn"
              >
                {{ $t('accessType.tabs.misc.transfersPaidInEmployee') }}
              </b-form-checkbox>
              <b-form-checkbox
                @change="check"
                id="transfersFinalDeposits"
                v-model="form.accessTypes.misc.transfersFinalDeposits"
                name="transfersFinalDeposits"
              >
                {{ $t('accessType.tabs.misc.transfersFinalDeposits') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="transfersFinalDepositsDepartment"
                v-model="form.accessTypes.misc.transfersFinalDepositsDepartment"
                name="transfersFinalDepositsDepartment"
                class="ml-4"
                :disabled="!form.accessTypes.misc.transfersFinalDeposits"
              >
                {{ $t('accessType.tabs.misc.transfersFinalDepositsDepartment') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4"></b-col>
          </b-row>

          <h6 class="mt-3 mb-3">
            {{ $t('accessType.tabs.misc.recordingModule') }}
          </h6>
          <b-row>
            <b-col>
              <b-form-checkbox
                id="recordingModulesGroupEvent"
                v-model="form.accessTypes.misc.recordingModulesGroupEvent"
                name="recordingModulesGroupEvent"
              >
                {{ $t('accessType.tabs.misc.recordingModulesGroupEvent') }}
              </b-form-checkbox>
              <b-form-checkbox
                id="recordingModulesPeriodicSalesSummary"
                v-model="form.accessTypes.misc.recordingModulesPeriodicSalesSummary"
                name="recordingModulesPeriodicSalesSummary"
              >
                {{ $t('accessType.tabs.misc.recordingModulesPeriodicSalesSummary') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';

export const accessTypeDefaultFormValue = {
  name: '',
  taskId: null,
  licenceNumber: 0,
  employeeType: null,
  accessTypes: {
    bar: {
      activeInterfaceClearBarOrderMemory: false,
      activeInterfaceCoinFree: false,
      activeInterfaceReplacementProduct: false,
      activeInterfaceShowBarOrderFirst: false,
      activeInterfaceShowAlternativeBarSection: false,
      activeInterfaceRemoveOrderWithoutInterface: false,
      activeInterfaceCombineBarOrders: false,
      activeInterfaceReactivateExpiredOrder: false,
      passiveInterfaceActualPouredQuantityReport: false,
      passiveInterfaceActualPouredQuantityReportCloseBarShift: false,
      passiveInterfaceBarOperator: false,
      passiveInterfaceChangeBarSection: false,
      passiveInterfaceAutomaticSectionReports: false,
      passiveInterfaceAutomaticallyOrderOutOfBalance: false,
      passiveInterfaceManualAdjustment: false,
      passiveInterfaceManualAdjustmentSync: false,
      passiveInterfaceManualAdjustmentAddBottle: false,
      passiveInterfaceManualAdjustmentAutoSection: false,
      passiveInterfaceManualAdjustmentCalibration: false,
      passiveInterfaceManualAdjustmentPrintAdjustedValues: false,
      commonAutoOrderingControl: false,
      commonAutoOrderingControlVoidAutoOrder: false,
      commonSpoutAssignment: false,
      commonProductCalibration: false,
      commonVarConsoleMemAssignment: false
    },
    dining: {
      sectionDeleteAssigned: false,
      sectionEdit: false,
      miscDisableTapMap: false,
      miscAutoTableNumber: false,
      miscAutoTableSectionId: null,
      miscWarnWrongTable: false,
      miscCallEmployeeTable: false,
      hostessClearTableMapSections: true,
      hostessAutoAssignTableMap: false,
      hostessManualAssignEmployeeTable: false,
      hostessConfirmFunctions: false,
      operationAutoExit: true,
      operationAutoSeating: false,
      operationReturnGroupAfterOrder: false,
      operationOrderReview: false,
      operationConfirmTransaction: false,
      operationCommonReading: null,
      operationOperatingMode: null,
      reservationTableAgendaAccess: false,
      reservationStartAgendaAccess: false,
      reservationRoomAgendaAccess: false,
      reservationDisplayOrderSummaryKitchen: false,
      invoicingCashClerk: true,
      invoicingCustomerRedistributionCheck: true,
      invoicingCustomerRedistributionCheckWithoutDiscount: false,
      invoicingDiscounts: true,
      invoicingDiscountsAfterCheck: false,
      invoicingOrderAfterCheck: false,
      invoicingTransferPaymentDifferentCheck: false,
      invoicingTransferPaymentDifferentCheckSelectDestination: false,
      invoicingAllCashButton: true,
      invoicingReturnOrderScreenAfterPayment: false,
      invoicingEnforceDrawClosePriorNextOrder: false,
      invoicingPaymentDeleteClosedCheck: true,
      invoicingPaymentDeleteClosedCheckEdit: false,
      invoicingNoCheckButton: false,
      invoicingChoosePrinter: false,
      invoicingPrintDuplicates: true,
      invoicingPrintInternalCopies: false,
      invoicingPrintPricelessCopies: false,
      cashDrawerManagement: false,
      cashDrawerEnforceAssign: false,
      cashDrawerCashInDeposit: false,
      cashDrawerOpenUnassignDrawer: false,
      cashDrawerBankDeposit: false,
      cashDrawerDepositCashedIn: true,
      cashDrawerEnforceCashedIn: true,
      cashDrawerEnforceCashedInBlockOperations: false,
      visualScreenSend: true,
      visualScreenPrint: true,
      visualScreenClose: true,
      visualScreenMenu: true,
      visualScreenTakeout: false,
      visualScreenTableTakeout: false,
      visualScreenRecipe: true,
      visualScreenRecipePrint: true,
      visualScreenTransferTable: true,
      visualScreenTransferCustomer: true,
      visualScreenTransferCustomerPromptPassword: true,
      visualScreenTransferCustomerOnlyNoChecks: false,
      visualScreenTransferItem: true,
      visualScreenTransferItemSplit: true,
      visualScreenTransferItemOnlyNoChecks: false,
      visualScreenTransferEmployee: true,
      visualScreenTransferEmployeeNoPromptPassword: true,
      visualScreenOrderCombinations: false,
      visualScreenOrderCombinationsPromptPasswordDiffEmployee: false,
      visualScreenResendOrder: false,
      visualScreenRound: false,
      visualScreenPreOrder: false,
      visualScreenTransferOrder: false,
      visualScreenGroupTableOrderBatch: false,
      visualScreenGroupTableOrderBatchSeparateReceipts: false,
      visualScreenAssignVisitorMembers: false,
      visualScreenAllowTableOrderOnHold: false,
      visualScreenAllowTableOrderOnHoldDefault: false,
      specialKeysEmployeeSelectionKeyDefaultEmployee: false,
      specialKeysExemptionFromPaymentKeys: false,
      specialKeysPriceChange: false,
      specialKeysMiscItems: false,
      specialKeysNegativeMiscItems: false
    },
    diningBtn: [{ keyGroupId: null, isDefault: false }],
    general: {
      accessModes: [],
      accessClientParams: true,
      accessClientParamsAccountPayments: true,
      accessClientParamsAccountBalance: true,
      accessClientParamsBypassCreditLimit: false,
      accessClientParamsClientRec: true,
      accessClientParamsClientRecCreateNewClient: true,
      accessClientParamsClientRecEditPin: false,
      accessClientParamsClientRecEditPinEventNoPreviousInfo: false,
      accessClientParamsReports: true,
      accessDay: false,
      accessEvening: false,
      accessHappyHour: false,
      accessPm: false,
      accessPromptMode: false,
      accessForcedModeId: null,
      cancel: true,
      clientAccountRentals: true,
      clientAccountRoomService: true,
      clientAccountSelfServiceKiosk: false,
      delivery: true,
      disableOpenDrawerDuringOrder: false,
      driveThruLane: false,
      driveThruLaneIsDriveThruLane: false,
      modifyClosedPayments: false,
      onlyOneWorkstationAtTime: false,
      openDrawer: true,
      paymentAccess: false,
      qsr: true,
      refunds: true,
      tipDistributionTeamsRemoveUponDeclaration: false,
      runningTotals: false,
      runningTotalsOrderOnHold: false,
      runningTotalsSearchName: false,
      takeout: true,
      tipDeclaration: false,
      tipDeclarationPriorCashout: false,
      tipDeclarationSaveTipDeclaration: false,
      tipDeclarationShowTipDeclaration: false,
      tipDistributionBussersPercentage: 0,
      tipDistributionIncludeCooks: false,
      tipDistributionIncludeServers: false,
      tipDistributionTeams: false,
      tipTransfer: false,
      tipTransferEmployeeSelection: false,
      tips: false,
      unrestrictedScreenProgramming: false,
      useTables: true,
      viewPaymentBalances: false,
      voids: true
    },
    misc: {
      voidTransaction: true,
      transactionReport: true,
      batchClose: true,
      clearPendingTransactions: false,
      accountBalance: false,
      mobileTerminalAccess: true,
      accessEftFunctions: true,
      smartCardSale: false,
      smartCardVoidTransaction: false,
      smartCardActivation: false,
      smartCartBalanceInquiry: false,
      smartCartClearBalance: false,
      smartCardFidelityActivate: false,
      smartCardFidelityBalanceInquiry: false,
      smartCardFidelityVoidTransaction: false,
      systemTableState: false,
      systemMenuConfig: true,
      systemGroupKeyConfig: false,
      systemEmployeeRecord: false,
      systemFunctions: false,
      systemFunctionsConfiguration: false,
      systemEditItemCountdown: true,
      systemAdmissionTicketGenerator: false,
      systemAdmissionTicketGeneratorCheckCreation: false,
      systemAdmissionTicketGeneratorCheckCreationLayawayFunc: false,
      systemDailyTemperatureAdjustment: false,
      systemAddClosedTablesOrderBatchList: false,
      systemEnableDisableForcedPriceBatches: false,
      systemPrintPromoCardBalance: false,
      systemSearchChecks: false,
      systemReviewEditClosedChecks: true,
      systemModifyRedirectionTable: false,
      systemModifyPagerNumber: false,
      transfersPayment: true,
      transfersPaidOut: true,
      transfersPaidOutEmployee: false,
      transfersEmployeeDeposit: false,
      transfersEmployeeDepositCounterbalanceExecOnly: false,
      transfersWarnAmountBeforeDeposit: false,
      transfersWarnAmountBeforeDepositAmount: '0.00',
      transfersMaxAmountBeforeForcedDeposit: false,
      transfersMaxAmountBeforeForcedDepositAmount: '0.00',
      transfersPaidIn: true,
      transfersPaidInEmployee: false,
      transfersFinalDeposits: false,
      transfersFinalDepositsDepartment: false,
      recordingModulesGroupEvent: false,
      recordingModulesPeriodicSalesSummary: false,
      statusScreen: false,
      statusScreenRefreshInterval: 0
    },
    operation: {
      generalEnforcePunchIn: true,
      generalReadingAfterPunchOut: false,
      generalTimeAttendanceManager: false,
      generalCantPunchOutOpenTables: false,
      generalPerformCashAdvances: false,
      generalPerformCashAdvancesCashingOutEmployeeSelection: false,
      generalChangeEmployeeMeals: false,
      generalChangeEmployeeMealsPayingEmployeeSelection: false,
      generalResolveCashAdvances: true,
      generalReceiveEmployeeMealCharge: true,
      generalForceTaskSelection: false,
      timeAttendanceReportNoEmployeeReport: false,
      timeAttendanceReportActiveEmployeeReport: false,
      timeAttendanceReportScheduledEmployeeReport: false,
      timeAttendanceReportEmployeesBreakReport: false,
      timeAttendanceReportDailyPunch: false,
      timeAttendanceReportDailySchedule: false,
      timeAttendanceReportDailyNoShow: false,
      reportsPerformEndOfDayProcedure: false,
      reportsEndOfDay: false,
      reportsWeekly: false,
      reportsMonthly: false,
      reportsYearly: false,
      reportsPeriodic: false,
      reportsTimeGap: false,
      reportsDepartmentSelection: false,
      reportsEmployeeSelection: false,
      salesReadingCloseShift: true,
      salesReadingCloseShiftPromptAmountBeforeClose: false,
      salesReadingCloseShiftPromptAmountBeforeCloseOverShort: false,
      salesReadingCloseShiftPromptAmountBeforeCloseDepositForm: false,
      salesReadingCloseShiftOnScreenOnly: false,
      salesReadingSalesConfirmation: true,
      salesReadingSalesConfirmationNoOpenTables: false,
      salesReadingSalesConfirmationOnScreenOnly: false,
      salesReadingOnScreenReadings: true,
      salesReadingCumulativeReadings: true,
      salesReadingSalesEmployee: true,
      globalReading: false,
      globalReadingByPatch: false,
      globalReadingByPatchCloseBatch: false,
      globalReadingByPatchByDepartment: false,
      readingFormatCountersReport: false,
      readingFormatSalesByCategory: true,
      readingFormatSalesBySubCategory: true,
      readingFormatCheckListing: false,
      readingFormatTransactionsPaymentType: false,
      readingFormatDrawerDepositReport: false,
      readingFormatSalesByMemberCategories: false,
      readingFormatCumulativeTotalShiftReadings: false,
      readingFormatTimeFirstLastOrder: true,
      readingFormatNumberCashDrawerNoSales: true,
      readingFormatVoidTotalQty: true,
      readingFormatVoidTotalQtyItemVentilated: false,
      readingFormatNumberPromoItems: true,
      readingFormatMealCount: true,
      readingFormatCustomerCount: true,
      readingFormatAverageMeal: true,
      readingFormatAverageCustomer: true,
      readingFormatValuePerMeal: false,
      readingFormatEmployeeTips: true,
      readingFormatIncludedTipFromOverPayment: false,
      readingFormatTaxes: true,
      readingFormatCancelledTransactions: true,
      readingFormatCancelledTransactionsVentilated: false,
      readingFormatReportsPriceLevel: false,
      readingFormatNumberDuplicate: false,
      readingFormatReportsByDepartment: false,
      readingFormatTipDeclarationDataResume: false,
      readingFormatTimezoneReport: false,
      readingFormatNumberItemsSoldReturns: false,
      readingFormatDiscountItemIncludedDiscountedItem: false,
      readingFormatTotalAndSubtotal: true,
      readingFormatDiscounts: true,
      readingFormatAllItemsVentilated: false,
      operatorWorkstationsDepartment: false,
      operatorEmployeesDepartment: false,
      operatorEmployeesRequisitionLocation: false,
      operatorEmployeesRedirectionTable: false,
      operatorStopReassignAssignedEmployee: false,
      operatorDesignationRequired: false,
      deliveryCost: false,
      deliveryManager: false,
      deliveryTagFunction: false,
      deliveryAllowUpdateCustomersAvailableCredit: true,
      deliveryCreditBatch1: false,
      deliverySingleDispatch: false,
      inventoryBalance: false,
      inventoryBalanceIntegerQty: false,
      inventoryReceipt: false,
      inventoryInterLocationTransfer: false,
      inventoryProductFiles: false,
      inventoryAccessMultipleLocations: false,
      inventoryPurchaseManagement: false,
      inventoryShiftBalanceReport: false,
      inventoryShiftBalanceReportBackSelection: false,
      inventoryShiftClosure: false,
      inventoryUnitChoice: true,
      inventoryReturnItemInventoryButton: false,
      inventoryReturnItemInventoryButtonReceiptProductOnly: false,
      inventoryReturnItemInventoryButtonUseOnlyVersion: false,
      inventoryPersistentCategoryInventoryManagement: false,
      inventoryNegativeQtyButtonInventoryManagement: true,
      inventoryRequisition: false,
      inventoryRequisitionProductTransfer: false,
      inventoryRequisitionProductTransferLimitFunctionsLocation: false,
      inventoryRequisitionChangeLocationNotAllowed: false,
      inventoryRequisitionWholeNumberQuantitiesOnly: false,
      inventoryRequisitionRequiredForDate: false,
      inventoryRequisitionNoStockCheck: false,
      inventoryRequisitionByEmployeeSelection: false,
      inventoryRequisitionIndexId: null
    },
    quickService: {
      visualScreenRetrieveStoredOrder: false,
      visualScreenStoredOrder: false,
      visualScreenStoredOrderPrintPrices: false,
      visualScreenStoredOrderPrintBarcode: false,
      visualScreenStoredOrderExpectedDate: false,
      visualScreenStoredOrderMemberAssociation: false,
      visualScreenViewItemPricesWithoutOrdering: false,
      visualScreenAllowQuickServiceOrderOnHold: false,
      visualScreenAllowQuickServiceOrderOnHoldDefault: false
    }
  }
};
export default {
  name: 'access-type-form',
  model: {},
  props: {
    licenceNumber: {
      type: Number,
      default: null
    },
    accessType: {
      type: Object,
      default: () => ({ ...accessTypeDefaultFormValue })
    },
    modes: {
      type: Array,
      default: () => []
    },
    keyGroups: {
      type: Array,
      default: () => []
    },
    requisitionIndexes: {
      type: Array,
      default: () => []
    },
    tablesSections: {
      type: Array,
      default: () => []
    },
    jobRoles: {
      type: Array,
      default: () => []
    },
    pending: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      form: { ...accessTypeDefaultFormValue },
      formOrig: null,
      selectedTab: 0,
      modesForm: {}
    };
  },
  watch: {
    accessType(value) {
      this.selectedTab = 0;
      const data = value || {};

      const misc = data.accessTypes?.misc || accessTypeDefaultFormValue.accessTypes.misc;

      (data.accessTypes?.general.accessModes || []).forEach(m => {
        this.modesForm[m] = true;
      });

      this.form = cloneDeep({
        ...this.form,
        licenceNumber: this.licenceNumber,
        ...data,
        accessTypes: {
          ...data.accessTypes,
          dining: {
            ...(data.accessTypes?.dining || accessTypeDefaultFormValue.accessTypes.dining)
          },
          misc: {
            ...misc,
            transfersWarnAmountBeforeDepositAmount: Number(misc.transfersWarnAmountBeforeDepositAmount).toFixed(2),
            transfersMaxAmountBeforeForcedDepositAmount: Number(
              misc.transfersMaxAmountBeforeForcedDepositAmount
            ).toFixed(2)
          }
        }
      });

      if (!this.formOrig) {
        this.formOrig = cloneDeep(this.form);
      }
    }
  },
  computed: {
    operatingModes() {
      return [
        { id: null, name: 'None' },
        { id: 0, name: 'Table number' },
        { id: 1, name: 'Delivery' },
        { id: 2, name: 'Take-Out' },
        { id: 3, name: 'Tables' },
        { id: 4, name: 'Quick Service' },
        { id: 5, name: 'Group Event (SRM)' }
      ];
    },
    commonReadings() {
      const modes = [{ id: null, name: this.$t('employeeCreate.emptyValue') }];

      for (let i = 0; i < 100; i++) {
        modes.push({ id: i, name: `Common cash#${i}` });
      }
      return modes;
    },
    enableSaveChanges() {
      if (this.form.name === '' || this.form.employeeType === null || this.form.employeeType === '') {
        return false;
      }

      if (JSON.stringify(this.form) === JSON.stringify(this.formOrig)) {
        return false;
      }
      return true;
    },
    enableContinue() {
      if (
        this.selectedTab === 0 &&
        (this.form.name === '' || this.form.employeeType === null || this.form.employeeType === '')
      ) {
        return false;
      }

      return true;
    }
  },
  renderTriggered() {
    this.selectedTab = 0;
  },
  methods: {
    setAsDefault(index, value) {
      if (value) {
        this.form.accessTypes.diningBtn.forEach((_, i) => {
          if (i !== index) {
            this.form.accessTypes.diningBtn[i].isDefault = false;
          }
        });
      }
      this.form = cloneDeep(this.form);
    },
    onAddDiningBtn() {
      this.form.accessTypes.diningBtn.push({
        keyGroupId: null,
        isDefault: false
      });
    },
    check() {
      this.checkGeneral();
      this.checkDining();
      this.checkQuickService();
      this.checkBar();
      this.checkOperation();
      this.checkMisc();
    },
    checkGeneral() {
      this.form.accessTypes.general.driveThruLaneIsDriveThruLane = this.form.accessTypes.general.driveThruLane;

      this.form.accessTypes.general.runningTotalsOrderOnHold = this.form.accessTypes.general.runningTotals;
      this.form.accessTypes.general.runningTotalsSearchName = this.form.accessTypes.general.runningTotals;

      this.form.accessTypes.general.disableOpenDrawerDuringOrder = this.form.accessTypes.general.openDrawer;

      this.form.accessTypes.general.tipDeclarationPriorCashout = this.form.accessTypes.general.tipDeclaration;
      this.form.accessTypes.general.tipDeclarationShowTipDeclaration = this.form.accessTypes.general.tipDeclaration;
      this.form.accessTypes.general.tipDeclarationSaveTipDeclaration = this.form.accessTypes.general.tipDeclaration;

      this.form.accessTypes.general.tipDistributionTeamsRemoveUponDeclaration = this.form.accessTypes.general.tipDistributionTeams;

      this.form.accessTypes.general.tipTransferEmployeeSelection = this.form.accessTypes.general.tipTransfer;

      this.form.accessTypes.general.accessClientParamsAccountPayments = this.form.accessTypes.general.accessClientParams;
      this.form.accessTypes.general.accessClientParamsClientRec = this.form.accessTypes.general.accessClientParams;
      this.form.accessTypes.general.accessClientParamsClientRecCreateNewClient = this.form.accessTypes.general.accessClientParams;
      this.form.accessTypes.general.accessClientParamsClientRecEditPin = this.form.accessTypes.general.accessClientParams;
      this.form.accessTypes.general.accessClientParamsClientRecEditPinEventNoPreviousInfo = this.form.accessTypes.general.accessClientParams;
      this.form.accessTypes.general.accessClientParamsReports = this.form.accessTypes.general.accessClientParams;
      this.form.accessTypes.general.accessClientParamsBypassCreditLimit = this.form.accessTypes.general.accessClientParams;
      this.form.accessTypes.general.accessClientParamsAccountBalance = this.form.accessTypes.general.accessClientParams;

      this.form.accessTypes.general.accessClientParamsClientRecCreateNewClient = this.form.accessTypes.general.accessClientParamsClientRec;
      this.form.accessTypes.general.accessClientParamsClientRecEditPin = this.form.accessTypes.general.accessClientParamsClientRec;
      this.form.accessTypes.general.accessClientParamsClientRecEditPinEventNoPreviousInfo = this.form.accessTypes.general.accessClientParamsClientRec;

      this.form.accessTypes.general.accessClientParamsClientRecEditPinEventNoPreviousInfo = this.form.accessTypes.general.accessClientParamsClientRecEditPin;
    },
    checkDining() {
      this.form.accessTypes.dining.invoicingCustomerRedistributionCheckWithoutDiscount = this.form.accessTypes.dining.invoicingCustomerRedistributionCheck;

      this.form.accessTypes.dining.invoicingDiscountsAfterCheck = this.form.accessTypes.dining.invoicingDiscounts;

      this.form.accessTypes.dining.invoicingTransferPaymentDifferentCheckSelectDestination = this.form.accessTypes.dining.invoicingTransferPaymentDifferentCheck;

      this.form.accessTypes.dining.invoicingPaymentDeleteClosedCheckEdit = this.form.accessTypes.dining.invoicingPaymentDeleteClosedCheck;
      this.form.accessTypes.dining.cashDrawerEnforceAssign = this.form.accessTypes.dining.cashDrawerManagement;
      this.form.accessTypes.dining.cashDrawerCashInDeposit = this.form.accessTypes.dining.cashDrawerManagement;
      this.form.accessTypes.dining.cashDrawerOpenUnassignDrawer = this.form.accessTypes.dining.cashDrawerManagement;
      this.form.accessTypes.dining.cashDrawerBankDeposit = this.form.accessTypes.dining.cashDrawerManagement;

      this.form.accessTypes.dining.cashDrawerEnforceCashedInBlockOperations = this.form.accessTypes.dining.cashDrawerEnforceCashedIn;
      this.form.accessTypes.dining.visualScreenRecipePrint = this.form.accessTypes.dining.visualScreenRecipe;
      this.form.accessTypes.dining.visualScreenTransferCustomerPromptPassword = this.form.accessTypes.dining.visualScreenTransferCustomer;
      this.form.accessTypes.dining.visualScreenTransferCustomerOnlyNoChecks = this.form.accessTypes.dining.visualScreenTransferCustomer;
      this.form.accessTypes.dining.visualScreenTransferItemSplit = this.form.accessTypes.dining.visualScreenTransferItem;
      this.form.accessTypes.dining.visualScreenTransferItemOnlyNoChecks = this.form.accessTypes.dining.visualScreenTransferItem;
      this.form.accessTypes.dining.visualScreenTransferEmployeeNoPromptPassword = this.form.accessTypes.dining.visualScreenTransferEmployee;
      this.form.accessTypes.dining.visualScreenOrderCombinationsPromptPasswordDiffEmployee = this.form.accessTypes.dining.visualScreenOrderCombinations;
      this.form.accessTypes.dining.visualScreenGroupTableOrderBatchSeparateReceipts = this.form.accessTypes.dining.visualScreenGroupTableOrderBatch;
      this.form.accessTypes.dining.visualScreenAllowTableOrderOnHoldDefault = this.form.accessTypes.dining.visualScreenAllowTableOrderOnHold;
    },
    checkQuickService() {
      this.form.accessTypes.quickService.visualScreenStoredOrderExpectedDate = this.form.accessTypes.quickService.visualScreenStoredOrder;
      this.form.accessTypes.quickService.visualScreenStoredOrderPrintPrices = this.form.accessTypes.quickService.visualScreenStoredOrder;
      this.form.accessTypes.quickService.visualScreenStoredOrderPrintBarcode = this.form.accessTypes.quickService.visualScreenStoredOrder;
      this.form.accessTypes.quickService.visualScreenStoredOrderMemberAssociation = this.form.accessTypes.quickService.visualScreenStoredOrder;
    },
    checkBar() {
      this.form.accessTypes.quickService.visualScreenAllowQuickServiceOrderOnHoldDefault = this.form.accessTypes.quickService.visualScreenAllowQuickServiceOrderOnHold;

      this.form.accessTypes.bar.passiveInterfaceActualPouredQuantityReportCloseBarShift = this.form.accessTypes.bar.passiveInterfaceActualPouredQuantityReport;

      this.form.accessTypes.bar.passiveInterfaceManualAdjustmentSync = this.form.accessTypes.bar.passiveInterfaceManualAdjustment;
      this.form.accessTypes.bar.passiveInterfaceManualAdjustmentAddBottle = this.form.accessTypes.bar.passiveInterfaceManualAdjustment;
      this.form.accessTypes.bar.passiveInterfaceManualAdjustmentAutoSection = this.form.accessTypes.bar.passiveInterfaceManualAdjustment;
      this.form.accessTypes.bar.passiveInterfaceManualAdjustmentCalibration = this.form.accessTypes.bar.passiveInterfaceManualAdjustment;
      this.form.accessTypes.bar.passiveInterfaceManualAdjustmentPrintAdjustedValues = this.form.accessTypes.bar.passiveInterfaceManualAdjustment;

      this.form.accessTypes.bar.commonAutoOrderingControlVoidAutoOrder = this.form.accessTypes.bar.commonAutoOrderingControl;
    },
    checkOperation() {
      this.form.accessTypes.operation.generalPerformCashAdvancesCashingOutEmployeeSelection = this.form.accessTypes.operation.generalPerformCashAdvances;

      this.form.accessTypes.operation.generalChangeEmployeeMealsPayingEmployeeSelection = this.form.accessTypes.operation.generalChangeEmployeeMeals;

      this.form.accessTypes.operation.salesReadingCloseShiftPromptAmountBeforeCloseOverShort = this.form.accessTypes.operation.salesReadingCloseShiftPromptAmountBeforeClose;
      this.form.accessTypes.operation.salesReadingCloseShiftPromptAmountBeforeCloseDepositForm = this.form.accessTypes.operation.salesReadingCloseShiftPromptAmountBeforeClose;

      this.form.accessTypes.operation.salesReadingSalesConfirmationNoOpenTables = this.form.accessTypes.operation.salesReadingSalesConfirmation;
      this.form.accessTypes.operation.salesReadingSalesConfirmationOnScreenOnly = this.form.accessTypes.operation.salesReadingSalesConfirmation;

      this.form.accessTypes.operation.globalReadingByPatchCloseBatch = this.form.accessTypes.operation.globalReadingByPatch;
      this.form.accessTypes.operation.globalReadingByPatchByDepartment = this.form.accessTypes.operation.globalReadingByPatch;

      this.form.accessTypes.operation.readingFormatVoidTotalQtyItemVentilated = this.form.accessTypes.operation.readingFormatVoidTotalQty;

      this.form.accessTypes.operation.readingFormatCancelledTransactionsVentilated = this.form.accessTypes.operation.readingFormatCancelledTransactions;

      this.form.accessTypes.operation.inventoryBalanceIntegerQty = this.form.accessTypes.operation.inventoryBalance;

      this.form.accessTypes.operation.inventoryShiftBalanceReportBackSelection = this.form.accessTypes.operation.inventoryShiftBalanceReport;

      this.form.accessTypes.operation.inventoryReturnItemInventoryButtonReceiptProductOnly = this.form.accessTypes.operation.inventoryReturnItemInventoryButton;
      this.form.accessTypes.operation.inventoryReturnItemInventoryButtonUseOnlyVersion = this.form.accessTypes.operation.inventoryReturnItemInventoryButton;

      this.form.accessTypes.operation.inventoryRequisitionProductTransfer = this.form.accessTypes.operation.inventoryRequisition;
      this.form.accessTypes.operation.inventoryRequisitionProductTransferLimitFunctionsLocation = this.form.accessTypes.operation.inventoryRequisition;
      this.form.accessTypes.operation.inventoryRequisitionChangeLocationNotAllowed = this.form.accessTypes.operation.inventoryRequisition;
      this.form.accessTypes.operation.inventoryRequisitionWholeNumberQuantitiesOnly = this.form.accessTypes.operation.inventoryRequisition;
      this.form.accessTypes.operation.inventoryRequisitionRequiredForDate = this.form.accessTypes.operation.inventoryRequisition;
      this.form.accessTypes.operation.inventoryRequisitionNoStockCheck = this.form.accessTypes.operation.inventoryRequisition;
      this.form.accessTypes.operation.inventoryRequisitionByEmployeeSelection = this.form.accessTypes.operation.inventoryRequisition;

      this.form.accessTypes.operation.inventoryRequisitionProductTransferLimitFunctionsLocation = this.form.accessTypes.operation.inventoryRequisitionProductTransfer;
    },
    checkMisc() {
      this.form.accessTypes.misc.systemFunctionsConfiguration = this.form.accessTypes.misc.systemFunctions;

      this.form.accessTypes.misc.systemAdmissionTicketGeneratorCheckCreation = this.form.accessTypes.misc.systemAdmissionTicketGenerator;
      this.form.accessTypes.misc.systemAdmissionTicketGeneratorCheckCreationLayawayFunc = this.form.accessTypes.misc.systemAdmissionTicketGenerator;

      this.form.accessTypes.misc.systemAdmissionTicketGeneratorCheckCreationLayawayFunc = this.form.accessTypes.misc.systemAdmissionTicketGeneratorCheckCreation;

      this.form.accessTypes.misc.systemEnableDisableForcedPriceBatches = this.form.accessTypes.misc.systemAddClosedTablesOrderBatchList;

      this.form.accessTypes.misc.transfersPaidOutEmployee = this.form.accessTypes.misc.transfersPaidOut;

      this.form.accessTypes.misc.transfersEmployeeDepositCounterbalanceExecOnly = this.form.accessTypes.misc.transfersEmployeeDeposit;

      this.form.accessTypes.misc.transfersPaidInEmployee = this.form.accessTypes.misc.transfersPaidIn;

      this.form.accessTypes.misc.transfersFinalDepositsDepartment = this.form.accessTypes.misc.transfersFinalDeposits;
    },
    onModeChange(x, y) {
      if (!x) {
        this.form.accessTypes.general.accessModes = this.form.accessTypes.general.accessModes.filter(n => n !== y);
      } else {
        this.form.accessTypes.general.accessModes.push(y);
      }
      this.reset(this.form);
    },
    reset(value = null) {
      this.form = cloneDeep(value || accessTypeDefaultFormValue);
      this.formOrig = null;
      this.accessType = null;
    },
    formatAmount(value) {
      return isNaN(value) ? value : Number(value).toFixed(2);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ {
  label,
  legend,
  .form-text {
    margin-bottom: 0;
    padding-left: 1rem;
  }

  .col-form-label {
    padding: 0 0 0 1rem;
  }
}

/deep/ .form-text {
  min-height: 18px;
}

.datepicker .input-group-append {
  margin-left: -35px;
}

/deep/ .input-group-text {
  background-color: #c9cacf;
}

.active .input-group-text {
  background-color: black;
}

h5,
h6 {
  font-weight: 500;
}

/deep/ .tab-content {
  padding: 0;
}

/deep/ .custom-checkbox {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

h5 {
  background-color: #f5f5f5;
}
</style>
