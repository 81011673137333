<template>
  <div>
    <vel-card v-if="hasIndicatorsError">
      <vel-alert level="error" :dismissable="false">{{ $t('dashboard.errors.other') }}</vel-alert>
    </vel-card>
    <indicator-grid v-if="!hasIndicatorsError" :class="{ 'hide-icon': !showIcon, secondary: secondary }">
      <gross-sales-volume
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsSalesVolume"
        :previous-amount="previousIndicators.grossSalesAmount"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_gross_sales_volume'"
        :style="getOrder(configKey + '_show_gross_sales_volume')"
      />
      <discounts-volume
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsDiscountsVolume"
        :previous-amount="previousIndicators.discountsAmount"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_discounts_volume'"
        :style="getOrder(configKey + '_show_discounts_volume')"
      />
      <net-sales-volume
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsNetSalesVolume"
        :previous-amount="previousIndicators.netSalesAmount"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_net_sales_volume'"
        :style="getOrder(configKey + '_show_net_sales_volume')"
      />
      <taxes-volume
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsTaxesVolume"
        :previous-amount="previousIndicators.taxesAmount"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_taxe_volume'"
        :style="getOrder(configKey + '_show_taxe_volume')"
      />
      <net-sales-volume-taxes
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsNetSalesVolumeTaxes"
        :previous-amount="previousIndicators.netSalesAmount + previousIndicators.taxesAmount"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_net_sales_volume_taxes'"
        :style="getOrder(configKey + '_show_net_sales_volume_taxes')"
      />
      <discounts-percentage
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsDiscountsPercent"
        :previous-amount="(previousIndicators.discountsAmount / previousIndicators.grossSalesAmount) * 100 || 0"
        v-can:show="configKey + '_show_discounts_percentage'"
        :style="getOrder(configKey + '_show_discounts_percentage')"
      />
      <meals-amount
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsMeals"
        :previous-amount="previousIndicators.meals"
        v-can:show="configKey + '_show_meal_count'"
        :style="getOrder(configKey + '_show_meal_count')"
      />
      <orders-amount
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsOrders"
        :previous-amount="previousIndicators.orders"
        v-can:show="configKey + '_show_order_count'"
        :style="getOrder(configKey + '_show_order_count')"
      />
      <customer-amount
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsCustomers"
        :previous-amount="previousIndicators.customers"
        v-can:show="configKey + '_show_customer_count'"
        :style="getOrder(configKey + '_show_customer_count')"
      />
      <no-sales
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsNoSales"
        :previous-amount="previousIndicators.drawerOpenings"
        v-can:show="configKey + '_show_no_sales'"
        :style="getOrder(configKey + '_show_no_sales')"
      />
      <cash-deposit
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsCashDeposit || 0"
        :previous-amount="previousIndicators.cashDepositAmount || 0"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_cash_deposit'"
        :style="getOrder(configKey + '_show_cash_deposit')"
      />

      <paid-outs
        v-loading="isIndicatorsLoading"
        :loading="loading"
        :amount="getIndicatorsPaidOuts"
        :previous-amount="previousIndicators.paidOuts"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_paid_outs'"
        :style="getOrder(configKey + '_show_paid_outs')"
        :versionIsValid="versionIsValid('9.67.0')"
      />
      <paid-ins-amount
        v-loading="isIndicatorsLoading"
        :loading="loading"
        :amount="getIndicatorsPaidInsAmount || 0"
        :previous-amount="previousIndicators.paidInsAmount || 0"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_paid_ins'"
        :style="getOrder(configKey + '_show_paid_ins')"
        :versionIsValid="versionIsValid('9.67.0')"
      />
      <net-cash-amount
        v-loading="isIndicatorsLoading"
        :loading="loading"
        :amount="getIndicatorsNetCashAmount || 0"
        :previous-amount="previousIndicators.netCashAmount || 0"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_net_cash'"
        :style="getOrder(configKey + '_show_net_cash')"
        :versionIsValid="versionIsValid('9.67.0')"
      />

      <tips-total-amount
        v-loading="isIndicatorsLoading"
        :loading="loading"
        :amount="getIndicatorTipsTotalAmount"
        :previous-amount="previousIndicators.tipsTotalAmount"
        :currency="getCurrencySelectorSelectedSymbol"
        :versionIsValid="versionIsValid()"
        v-can:show="configKey + '_show_tips_total_amount'"
        :style="getOrder(configKey + '_show_tips_total_amount')"
      />
      <tips-with-total
        v-loading="isIndicatorsLoading"
        :loading="loading"
        :amount="getIndicatorsTotalWithTipsAmount"
        :previous-amount="
          previousIndicators.tipsTotalAmount + previousIndicators.netSalesAmount + previousIndicators.taxesAmount
        "
        :currency="getCurrencySelectorSelectedSymbol"
        :versionIsValid="versionIsValid()"
        v-can:show="configKey + '_show_total_with_tips_amount'"
        :style="getOrder(configKey + '_show_total_with_tips_amount')"
      />
      <cash-discounts
        v-if="getIndicatorsCashDiscounts !== 0"
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsCashDiscounts"
        :previous-amount="previousIndicators.cashDiscountAmount"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_cash_discounts'"
        :style="getOrder(configKey + '_show_cash_discounts')"
      />
      <surcharge
        v-if="getIndicatorsSurchargeAmount !== 0"
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsSurchargeAmount"
        :previous-amount="previousIndicators.surchargeAmount"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_surcharge'"
        :style="getOrder(configKey + '_show_surcharge')"
      />
      <gross-sales-per-order
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsSalesPerOrder"
        :previous-amount="previousIndicators.grossSalesAmount / previousIndicators.orders || 0"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_gross_sales_per_order'"
        :style="getOrder(configKey + '_show_gross_sales_per_order')"
      />
      <gross-income-per-customer
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsSalesPerCustomer"
        :previous-amount="previousIndicators.grossSalesAmount / previousIndicators.customers || 0"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_gross_sales_per_customer'"
        :style="getOrder(configKey + '_show_gross_sales_per_customer')"
      />
      <net-income-per-customer
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsIncomePerCustomer"
        :previous-amount="previousIndicators.netSalesAmount / previousIndicators.customers || 0"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_net_income_per_customer'"
        :style="getOrder(configKey + '_show_net_income_per_customer')"
      />
      <income-per-order
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsRevenuePerOrder"
        :previous-amount="previousIndicators.netSalesAmount / previousIndicators.orders || 0"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_income_per_order'"
        :style="getOrder(configKey + '_show_income_per_order')"
      />
      <gross-income-per-meal
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsGrossIncomePerMeal"
        :previous-amount="previousIndicators.grossSalesPerMeal || 0"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_gross_income_meal'"
        :style="getOrder(configKey + '_show_gross_income_meal')"
      />
      <net-income-per-meal
        v-loading="isIndicatorsLoading"
        :amount="getIndicatorsNetIncomePerMeal"
        :previous-amount="previousIndicators.netSalesPerMeal || 0"
        :currency="getCurrencySelectorSelectedSymbol"
        v-can:show="configKey + '_show_net_income_meal'"
        :style="getOrder(configKey + '_show_net_income_meal')"
      />
    </indicator-grid>
  </div>
</template>

<script>
import CashDeposit from '@/private/shared/indicators/indicator/CashDeposit';
import CashDiscounts from '@/private/shared/indicators/indicator/CashDiscounts.vue';
import CustomerAmount from '@/private/shared/indicators/indicator/CustomerAmount';
import { DateTime } from 'luxon';
import DiscountsPercentage from '@/private/shared/indicators/indicator/DiscountsPercentage';
import DiscountsVolume from '@/private/shared/indicators/indicator/DiscountsVolume';
import GrossIncomePerCustomer from '@/private/shared/indicators/indicator/GrossIncomePerCustomer';
import GrossIncomePerMeal from '@/private/shared/indicators/indicator/GrossIncomePerMeal';
import GrossSalesPerOrder from '@/private/shared/indicators/indicator/GrossSalesPerOrder';
import GrossSalesVolume from '@/private/shared/indicators/indicator/GrossSalesVolume';
import IncomePerOrder from '@/private/shared/indicators/indicator/IncomePerOrder';
import IndicatorGrid from '@/components/indicator/IndicatorGrid.vue';
import MealsAmount from '@/private/shared/indicators/indicator/MealsAmount';
import NetCashAmount from '@/private/shared/indicators/indicator/NetCashAmount';
import NetIncomePerCustomer from '@/private/shared/indicators/indicator/NetIncomePerCustomer';
import NetIncomePerMeal from '@/private/shared/indicators/indicator/NetIncomePerMeal';
import NetSalesVolume from '@/private/shared/indicators/indicator/NetSalesVolume';
import NetSalesVolumeTaxes from '@/private/shared/indicators/indicator/NetSalesVolumeTaxes';
import NoSales from '@/private/shared/indicators/indicator/NoSales';
import OrdersAmount from '@/private/shared/indicators/indicator/OrdersAmount';
import PaidInsAmount from '@/private/shared/indicators/indicator/PaidInsAmount';
import PaidOuts from '@/private/shared/indicators/indicator/PaidOuts';
import Surcharge from '@/private/shared/indicators/indicator/Surcharge.vue';
import TaxesVolume from '@/private/shared/indicators/indicator/TaxesVolume';
import TipsTotalAmount from '@/private/shared/indicators/indicator/TipsTotalAmount';
import TipsWithTotal from '@/private/shared/indicators/indicator/TipsWithTotal';
import VelAlert from '@/components/alert/VelAlert';
import VelCard from '@/components/card/VelCard';
import { getToken } from '@/helpers/token.helper';
import router from '@/router';
import salesServices from '@/services/sales.service';
import { today } from '@/helpers/date.helpers';
import transactionsService from '@/services/transactions.service';
import { mapState as mapPiniaState } from 'pinia/dist/pinia';
import { useUIGroupSelector } from '@/stores/ui/group-selector.module';
import { useUICurrencySelector } from '@/stores/ui/currency-selector.module';
import { useUIDateSelector } from '@/stores/ui/date-selector.module';
import { useUILocationSelector } from '@/stores/ui/location-selector.module';
import { useDataLocationsVersionsStore } from '@/stores/data/locations/versions.module';
import { getIndicatorsByCurrency, useDataSalesIndicators } from '@/stores/data/sales/indicators.module';
import { storeToRefs } from 'pinia';
import { reactive, ref, onMounted, setup } from '@vue/composition-api';
import { useDataConfig } from '@/stores/data/config.module';

export default {
  name: 'indicators',
  props: {
    disableRange: {
      type: Boolean,
      default: false
    },
    configKey: {
      type: String,
      default: 'dashboard'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    secondary: {
      type: Boolean,
      default: null
    }
  },
  components: {
    CashDiscounts,
    Surcharge,
    MealsAmount,
    DiscountsPercentage,
    CustomerAmount,
    GrossSalesVolume,
    DiscountsVolume,
    NetSalesVolume,
    NetSalesVolumeTaxes,
    CashDeposit,
    PaidInsAmount,
    NetCashAmount,
    OrdersAmount,
    PaidOuts,
    NoSales,
    TipsTotalAmount,
    TipsWithTotal,
    TaxesVolume,
    IndicatorGrid,
    VelAlert,
    VelCard,
    GrossSalesPerOrder,
    IncomePerOrder,
    GrossIncomePerCustomer,
    NetIncomePerCustomer,
    NetIncomePerMeal,
    GrossIncomePerMeal
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),
    ...mapPiniaState(useDataSalesIndicators, ['hasIndicatorsError', 'getIndicatorsSalesVolume']),
    config: {
      get() {
        return this.get;
      },
      set() {
        return true;
      }
    }
  },
  setup() {
    const uiCurrencyStore = useUICurrencySelector();
    const uiLocationSelector = useUILocationSelector();
    const uiGroupSelector = useUIGroupSelector();
    const uiDateSelector = useUIDateSelector();

    const dataLocationsVersionsStore = useDataLocationsVersionsStore();

    const dataSalesIndicators = useDataSalesIndicators();

    const {
      isIndicatorsLoading,
      getIndicatorsSalesPerOrder,
      getIndicatorsNetSalesVolume,
      getIndicatorsCustomers,
      getIndicatorsTaxesVolume,
      getIndicatorsNetSalesVolumeTaxes,
      getIndicatorsRevenuePerCustomer,
      getIndicatorsDiscountsVolume,
      getIndicatorsCashDiscounts,
      getIndicatorsSurchargeAmount,
      getIndicatorsDiscountsPercent,
      getIndicatorsMeals,
      getIndicatorsOrders,
      getIndicatorTipsTotalAmount,
      getIndicatorsNoSales,
      getIndicatorsPaidOuts,
      getIndicatorsCashDeposit,
      getIndicatorsPaidInsAmount,
      getIndicatorsNetCashAmount,
      getIndicatorsTotalWithTipsAmount,
      getIndicatorsRevenuePerOrder,
      getIndicatorsSalesPerCustomer,
      getIndicatorsIncomePerCustomer,
      getIndicatorsNetIncomePerMeal,
      getIndicatorsGrossIncomePerMeal
    } = storeToRefs(dataSalesIndicators);

    const { isVersionValid, loading } = storeToRefs(dataLocationsVersionsStore);

    onMounted(() => {
      console.log('AAAAAAAAAAAAAAAAAAAAAAAAA 222');
    });

    console.log('AAAAAAAAAAAAAAAAAAAAAAAAA 1');
    return {
      previousIndicators: {},
      getCurrencySelectorSelectedSymbol: uiCurrencyStore.getCurrencySelectorSelectedSymbol,
      getLocationSelectorSelected: uiLocationSelector.getLocationSelectorSelected,
      getLocationSelectorSelectedMultipleIds: uiLocationSelector.getLocationSelectorSelectedMultipleIds,
      getGroupSelectorSelected: uiGroupSelector.getGroupSelectorSelected,
      getDateSelectorSelected: uiDateSelector.getDateSelectorSelected,
      gerVersions: dataLocationsVersionsStore.getVersions,
      isVersionValid,
      loading,
      isIndicatorsLoading,
      getIndicatorsSalesPerOrder,
      getIndicatorsNetSalesVolume,
      getIndicatorsCustomers,
      getIndicatorsTaxesVolume,
      getIndicatorsNetSalesVolumeTaxes,
      getIndicatorsRevenuePerCustomer,
      getIndicatorsDiscountsVolume,
      getIndicatorsCashDiscounts,
      getIndicatorsSurchargeAmount,
      getIndicatorsDiscountsPercent,
      getIndicatorsMeals,
      getIndicatorsOrders,
      getIndicatorTipsTotalAmount,
      getIndicatorsNoSales,
      getIndicatorsPaidOuts,
      getIndicatorsCashDeposit,
      getIndicatorsPaidInsAmount,
      getIndicatorsNetCashAmount,
      getIndicatorsTotalWithTipsAmount,
      getIndicatorsRevenuePerOrder,
      getIndicatorsSalesPerCustomer,
      getIndicatorsIncomePerCustomer,
      getIndicatorsNetIncomePerMeal,
      getIndicatorsGrossIncomePerMeal,
      dataSalesIndicators
    };
  },
  methods: {
    getOrder(key) {
      const order = (this.config[key] && this.config[key].order) || 0;
      return { order };
    },
    versionIsValid(minimalVersion = '9.53.1') {
      // @todo refactor so that we no longer have to pass getters as arguments
      return (
        !this.loading &&
        this.isVersionValid(
          minimalVersion,
          [...(this.getLocationSelectorSelectedMultipleIds || []), this.getLocationSelectorSelected],
          this.getGroupSelectorSelected,
          this.getDateSelectorSelected
        )
      );
    }
  },
  data() {
    return {
      isLoaded: false,
      previousIndicators: {
        cashDiscountAmount: 0,
        grossSalesAmount: 0,
        grossSalesPerOrder: 0,
        drawerOpenings: 0,
        netCashAmount: 0,
        paidInsAmount: 0,
        paidOuts: 0,
        cashDepositAmount: 0,
        netSalesAmount: 0,
        taxesAmount: 0,
        discountsAmount: 0,
        customers: 0,
        tipsTotalAmount: 0,
        orders: 0,
        meals: 0,
        surchargeAmount: 0,
        grossSalesPerMeal: 0,
        netSalesPerMeal: 0
      }
    };
  },
  async mounted() {
    await this.dataSalesIndicators.getIndicators();
    const toDay = today().startOf('day').toISO();
    const from = router.currentRoute.query.from || toDay;
    const to = router.currentRoute.query.to || from || toDay;

    const routeQuery = {
      ...router.currentRoute.query,
      from: DateTime.fromISO(from).minus({ years: 1 }).toISO(),
      to: DateTime.fromISO(to).minus({ years: 1 }).toISO()
    };

    const salesIndicators = await salesServices
      .getIndicators(getToken(), routeQuery)
      .then(res => (routeQuery.currencyId ? getIndicatorsByCurrency(res, routeQuery.currencyId) : res[0]));
    const transactionsIndicators = await transactionsService
      .getTransactionsIndicators(getToken(), routeQuery)
      .then(res => (routeQuery.currencyId ? getIndicatorsByCurrency(res, routeQuery.currencyId) : res[0]));

    this.previousIndicators = { ...salesIndicators, ...transactionsIndicators };
    console.log(`${this.previousIndicators.netIncomePerMeal}`);
  }
};
</script>
<style lang="scss" scoped>
.hide-icon {
  /deep/ .indicator__icon {
    display: none;
  }
}

/deep/ .indicator__percent {
  display: none;
}

.secondary {
  /deep/ {
    .indicator__percent {
      display: block;
    }

    .indicator {
      flex-direction: column-reverse;
      justify-content: flex-start;
    }

    .indicator__content {
      margin-top: 8px;
    }

    .indicator__title {
      justify-content: flex-start;
    }

    .vel-card {
      padding-bottom: 0.75rem;
    }

    .vel-card__body {
      padding: 0;
    }
  }
}
</style>
