import { defineStore } from 'pinia';
import { getToken } from '@/helpers/token.helper';
import usersService from '@/services/users.service';
import { createCollectionState, getSelectors, removeAll, setOne } from '@/helpers/stores.helpers';

const { selectTotal } = getSelectors();

export const useDataUsersPasswordReset = defineStore('usersPasswordReset', {
  state: () => ({
    status: { loggedIn: true },
    error: null,
    passwordResetToken: null,
    passwordResetUrl: null
  }),
  getters: {},
  actions: {
    getPasswordResetToken(userEmail) {
      this.getPasswordResetUrlRequest(userEmail);

      usersService.getPasswordResetToken(getToken(), userEmail).then(
        passwordResetToken => {
          this.getPasswordResetUrlSuccess(passwordResetToken);
        },
        error => {
          this.getPasswordResetUrlFailure(error);
        }
      );
    },
    clearPasswordResetToken() {
      this.passwordResetToken = null;
      this.passwordResetUrl = null;
    },
    getPasswordResetUrlRequest() {
      this.status = { isLoading: true };
      this.passwordResetToken = null;
      this.passwordResetUrl = null;
      this.error = null;
    },
    getPasswordResetUrlSuccess(token) {
      this.status = {};
      this.passwordResetToken = token;
      this.passwordResetUrl = `${window.location.protocol}//${window.location.host}/reset-password?token=${token.token}`;
      this.error = null;
    },
    getPasswordResetUrlFailure(error) {
      this.status = {};
      this.passwordResetToken = null;
      this.passwordResetUrl = null;
      this.error = error;
    }
  }
});
