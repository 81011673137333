<template>
  <pie-line-chart
    :pending="isPending"
    :options="chartOptions"
    :series="series"
    :labels="labels"
    :currency="currencyId"
    :title="$t('sales.modeSalesPerCustomersChart.title', { limit })"
    :type="get.default_chart_income_per_customers_by_mode.value"
    class="hourly-sales__chart"
  />
</template>

<script>
import PieLineChart from '@/components/pie-line-chart/PieLineChart';
import { useDataSalesModes } from '@/stores/data/sales/modes.module';
import { mapState as mapPiniaState, mapActions } from 'pinia';
import { useDataConfig } from '@/stores/data/config.module';
import pinia from '@/pinia';
import { storeToRefs } from 'pinia/dist/pinia';

export default {
  name: 'mode-sales-per-customers',
  components: {
    PieLineChart
  },
  props: {
    currencyId: {
      type: String,
      default: null
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  setup() {
    const storeModeSales = useDataSalesModes(pinia);

    const { isPending, getLabels, getSalesPerCustomersSeries } = storeToRefs(storeModeSales);
    return { isPending, getLabels, getSalesPerCustomersSeries, getSalesByMode: storeModeSales.getSalesByMode };
  },
  computed: {
    ...mapPiniaState(useDataConfig, ['get']),

    chartOptions() {
      return {
        xaxis: {
          title: {
            text: this.$t('sales.modeSalesPerCustomersChart.xaxis')
          }
        },
        yaxis: {
          title: {
            text: this.$t('sales.modeSalesPerCustomersChart.yaxis')
          }
        }
      };
    },
    labels() {
      return this.getLabels.slice(0, this.limit);
    },
    currentSeries() {
      return {
        name: this.$t('sales.modeSalesPerCustomersChart.series.title', { currency: this.currencyId }),
        data: this.getSalesPerCustomersSeries.slice(0, this.limit)
      };
    },
    series() {
      return [this.currentSeries];
    }
  },
  mounted() {
    this.getSalesByMode();
  }
};
</script>
