<template>
  <div class="bootstrap export-container">
    <b-button
      variant="primary"
      class="icon-container"
      @click="open = !open"
      :class="{ open: open }"
      :disabled="disabled || generating || (!enablePdf && !enableXls && !enableEmail)"
    >
      <ExportIcon class="icon" v-if="!generating" />
      <b-spinner small v-else></b-spinner>
    </b-button>
    <div class="popup" v-if="open">
      <ul>
        <li class="popup-item" v-if="enablePdf">
          <vel-export-item
            icon="export-pdf"
            icon-size="small"
            title="exportPopup.pdf"
            :subMenu="pdfSubLevel"
            @itemSelected="onClickPDF"
          />
        </li>
        <li class="popup-item" v-if="enableXls">
          <vel-export-item
            icon="export-excel"
            icon-size="small"
            title="exportPopup.xls"
            :subMenu="xlsSubLevel"
            @itemSelected="onClickXLS"
          />
        </li>
        <li class="popup-item" v-if="enableEmail">
          <vel-export-item
            icon="export-mail"
            icon-size="small"
            title="exportPopup.email"
            :subMenu="emailSubLevel"
            @itemSelected="onClickEmail"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ExportIcon from '@/assets/svg/icons/export.svg';
import VelExportItem from './VelExportItem.vue';
import { bus } from '@/bus';

const EXCEL_EXPORT_ID = 'exportXLS';
const PDF_EXPORT_ID = 'exportPDF';
const EMAIL_EXPORT_ID = 'exportEmail';

export default {
  name: 'vel-export',
  components: {
    ExportIcon,
    VelExportItem
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    enablePdf: {
      type: Boolean,
      default: false
    },
    enableXls: {
      type: Boolean,
      default: false
    },
    enableEmail: {
      type: Boolean,
      default: false
    },
    generating: {
      type: Boolean,
      default: false
    },
    xlsSubLevel: {
      type: Array,
      default: () => []
    },
    pdfSubLevel: {
      type: Array,
      default: () => []
    },
    emailSubLevel: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      open: false
    };
  },
  methods: {
    close() {
      this.open = false;
    },
    onClickPDF(param = null) {
      this.emit(PDF_EXPORT_ID, param);
    },
    onClickXLS(param = null) {
      this.emit(EXCEL_EXPORT_ID, param);
    },
    onClickEmail(param = null) {
      this.emit(EMAIL_EXPORT_ID, param);
    },
    emit(id, param) {
      if (!param) {
        this.$emit(id);
      } else {
        this.$emit(id, param);
      }

      this.close();
    },
    handleClick(evt) {
      if (!(this.$el === evt.target || this.$el.contains(evt.target))) {
        this.close();
      }
    }
  },
  mounted() {
    bus.$on('document:click', this.handleClick);
  },
  beforeDestroy() {
    bus.$off('document:click', this.handleClick);
  }
};
</script>

<style lang="scss" scoped>
@import 'mixins.scss';

$dark-blue-color: #00a9e1;
$light-blue-color: #e5f6fc;
$light-gray-color: #c7c7c7;

.export-container {
  position: relative;

  &::before {
    @include breakpoint(medium) {
      content: ' ';
      border-left: 1px solid #dbdbdb;
      margin-right: 0.55em;
    }
  }
}
.icon-container {
  background: transparent;
  color: $dark-blue-color;
  height: 32px;
  width: 32px;
  line-height: 16px;
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
  padding: 0 !important;
  border: none;

  &.open {
    background: $dark-blue-color;
    color: white;
  }
}

.popup {
  right: 0;
  background: white;
  border-radius: 1em;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 12px #00000029;
  position: absolute;
  z-index: 100;
  padding: 15px;
  margin-top: 10px;
  font-size: 14px;
  color: $light-gray-color;
  font-family: inherit;

  & ul {
    margin: 0;
    padding: 0;
    list-style: none;

    & li.popup-item:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  & label {
    color: black;
    margin: 0;
    width: max-content;
  }
}

</style>
